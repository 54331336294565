import React from 'react';
import {useLocation} from '@reach/router';

ScrollToTop.propTypes = {};

// Used to force a page to scroll to the top each time it loads.
// Thanks to https://github.com/reach/router/issues/242#issuecomment-644991279
function ScrollToTop (props) {
  const location = useLocation();
  const {state} = location;

  React.useEffect(() => {
    if (state?.key) {
      window?.scrollTo(0, 0);
    }
  }, [state]);

  // eslint-disable-next-line
  return <>{props.children}</>;
}

export default ScrollToTop;
