import PropTypes from 'prop-types';

Hubspot.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
  }),
};

function Hubspot ({user}) {
  const isLoggedIn = user && !!user.email;

  if (!isLoggedIn) {
    return;
  }

  // We use this as a trigger for loading the
  // tracking cookie and hubspot scripts in
  // Google Tag Manager
  window.dataLayer.push({
    event: 'logged_in_view',
  });

  // identify user by email
  const _hsq = window._hsq = window._hsq || [];
  _hsq.push(['identify', {
    email: user.email,
  }]);
}

export default Hubspot;
