import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Popover as AntdPopover} from 'antd';
import useOnClickOutside from 'hooks/useOnClickOutside';
import PopoverProvider from './providers/PopoverProvider';

Popover.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  content: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  title: PropTypes.string,
  setTriggerPopoverOpen: PropTypes.func,
  triggerPopoverOpen: PropTypes.bool,
};

Popover.defaultProps = {
};

function Popover (props) {
  const {
    children,
    content,
    title,
    setTriggerPopoverOpen,
    triggerPopoverOpen,
  } = props;

  const [open, setOpen] = useState(false);

  const ref = useRef();
  useOnClickOutside(ref, () => setOpen(false));

  useEffect(() => {
    const handleKeyDown = (event) => event.key === 'Escape' && setOpen(false);
    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  useEffect(() => {
    if (triggerPopoverOpen) {
      setOpen(true);
      setTriggerPopoverOpen(false);
    }
  }, [triggerPopoverOpen]);

  const wrappedContent = (
    <PopoverProvider setPopoverOpen={setOpen}>
      <div ref={ref}>
        {content}
      </div>
    </PopoverProvider>
  );

  return (
    <AntdPopover
      content={wrappedContent}
      title={title}
      placement="bottom"
      overlayClassName='popover'
      autoAdjustOverflow={false}
      destroyTooltipOnHide
      onClick={() => setOpen(true)}
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
      open={open}>
      {children}
    </AntdPopover>
  );
}

export default Popover;
