import PropTypes from 'prop-types';

PageUrl.propTypes = {
  children: PropTypes.string.isRequired,
};

function PageUrl (props) {
  const {children} = props;

  if (children === window.location.pathname) {
    return '';
  }

  window.history.replaceState(null, null, children);
  return '';
}

export default PageUrl;
