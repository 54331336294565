import React from 'react';
import PropTypes from 'prop-types';
import Document from 'containers/Document';
import DocumentFormPage from './DocumentFormPage';

DocumentForm.propTypes = {
  content: PropTypes.array.isRequired,
  onReactivate: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  printMode: PropTypes.bool.isRequired,
  responseData: PropTypes.shape({}).isRequired,
  submittedAt: PropTypes.number,
  submittedByUser: PropTypes.bool.isRequired,
};

function DocumentForm (props) {
  const {
    content,
    onReactivate,
    onSubmit,
    printMode,
    responseData,
    submittedAt,
    submittedByUser,
  } = props;

  return (
    <DocumentFormPage
      printMode={printMode}
      onSubmit={onSubmit}
      onReactivate={onReactivate}
      submittedAt={submittedAt}
      submittedByUser={submittedByUser}
    >
      <Document
        content={content}
        mode="use"
        readOnly
        responseData={responseData}
        submitted={!!submittedAt}
      />
    </DocumentFormPage>
  );
}

export default React.memo(DocumentForm);
