// Returns the first and last initial from a name
function getInitials (name) {
  const parts = name.trim().toUpperCase().split(' ');

  if (parts.length === 1) {
    return parts[0].substr(0, 1);
  }

  return parts[0].substr(0, 1) + parts.slice(-1)[0].substr(0, 1);
}

export default getInitials;
