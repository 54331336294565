import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled, css} from 'twin.macro';

GridCellGroupHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

GridCellGroupHeader.defaultProps = {
};

function GridCellGroupHeader (props) {
  const {
    children,
  } = props;

  if (!children) {
    return <></>;
  }

  return (
    <Wrapper>
      <Bar />
      <Label>
        {children}
        {/* <AiFillInfoCircle tw="text-base text-blue-500 ml-1.5" /> */}
      </Label>
    </Wrapper>
  );
}

const Wrapper = styled.div((props) => [
  tw`
    absolute
    w-full
    h-full
    flex
    items-center
    justify-center
  `,
  css`
    left: 0;
  `,
]);

const Bar = styled.div((props) => [
  tw`
    absolute
    w-full
    h-2
    mt-px
    left-0
    border-t
    border-l
    border-r
    border-gray-300
    border-dotted
  `,
  css`
    top: 18px;
  `,
]);

const Label = styled.div((props) => [
  tw`
    bg-gray-50
    flex
    items-center
    z-0
    px-2
    text-xs
    font-medium
    text-gray-800
  `,
]);

export default GridCellGroupHeader;
