import {Editor, Transforms} from 'slate';

// Handle the user starting the select from inside a selectable element's title
// or content, and then dragging it outside of that title or content.
function onSelectFromRatingScaleLabel (editor, selectionMeta) {
  const {
    isAnchorBeforeFocus,
    isSelectionInSingleElement,
    selectionAnchorAncestors,
    selectionAnchorElement,
    selectionAnchorElementPath,
  } = selectionMeta;

  if (
    selectionAnchorElement.type !== 'choice' ||
    !selectionAnchorAncestors.some((node) => node.type === 'choice-group' && node.format === 'rating') ||
    isSelectionInSingleElement
  ) {
    return false;
  }

  if (isAnchorBeforeFocus) {
    const endOfElement = Editor.end(editor, selectionAnchorElementPath);
    Transforms.setSelection(editor, {
      anchor: editor.selection.anchor,
      focus: endOfElement,
    });

    return true;
  }

  const startOfElement = Editor.start(editor, selectionAnchorElementPath);
  Transforms.setSelection(editor, {
    anchor: editor.selection.anchor,
    focus: startOfElement,
  });

  return true;
}

export default onSelectFromRatingScaleLabel;
