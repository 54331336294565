import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled, css} from 'twin.macro';
import {tailwind} from 'am-config';
const {colors} = tailwind.theme;

Toolbar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  arrow: PropTypes.bool,
};

Toolbar.defaultProps = {
  arrow: false,
};

function Toolbar (props) {
  const {children, arrow, ...rest} = props;

  return (
    <Container {...rest} arrow={arrow}>
      {children}
    </Container>
  );
}

const Container = styled.div((props) => [
  tw`
    block
    flex
    m-auto
    h-11
    bg-gray-800
    text-white
    rounded
    pointer-events-auto
  `,
  props.arrow && tw`
    px-1
    mb-4
  `,
  props.arrow && css`
    &::after {
      top: 100%;
      left: 50%;
      margin-left: -6px;
      border: solid transparent;
      content: '';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-top-color: ${colors.gray[800]};
      border-width: 6px;
    }
  `,
]);

export const ToolbarItemDivider = styled.div((props) => [
  tw`
    w-px
    bg-gray-600
    h-6
    mt-2.5
    mx-1
  `,
  props.full && tw`
    mt-0
    bg-gray-700
    h-full
  `,
]);

export default React.memo(Toolbar);
