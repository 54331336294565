import React, {useState} from 'react';
import PropTypes from 'prop-types';
import tw, {styled, css} from 'twin.macro';
import DatePicker from 'components/DatePicker';
import Button from 'components/Button';
import Label from 'components/Label';
import Input from 'components/Input';
import Select, {Option} from 'components/Select';
import {
  DAYS_OF_WEEK,
  FREQUENCY,
  INSTANCE_OF_DAY_IN_MONTH,
} from 'am-constants';
import Pluralize from 'react-pluralize';
import FormGroup from 'components/FormGroup';
import {usePopover} from 'components/Popover/providers/PopoverProvider';
import dayjs from 'dayjs';

NextDueCellPopover.propTypes = {
  nextDueAt: PropTypes.string,
  nextDueInterval: PropTypes.number,
  nextDueFrequency: PropTypes.oneOf([
    FREQUENCY.DAILY,
    FREQUENCY.WEEKLY,
    FREQUENCY.MONTHLY,
    FREQUENCY.YEARLY,
  ]),
  nextDueDaysOfWeek: PropTypes.arrayOf(PropTypes.oneOf([
    DAYS_OF_WEEK.MON,
    DAYS_OF_WEEK.TUE,
    DAYS_OF_WEEK.WED,
    DAYS_OF_WEEK.THU,
    DAYS_OF_WEEK.FRI,
    DAYS_OF_WEEK.SAT,
    DAYS_OF_WEEK.SUN,
  ])),
  nextDueInstanceOfDayInMonth: PropTypes.oneOf([
    INSTANCE_OF_DAY_IN_MONTH.DAY_NUMBER,
    INSTANCE_OF_DAY_IN_MONTH.FIRST,
    INSTANCE_OF_DAY_IN_MONTH.SECOND,
    INSTANCE_OF_DAY_IN_MONTH.THIRD,
    INSTANCE_OF_DAY_IN_MONTH.FOURTH,
    INSTANCE_OF_DAY_IN_MONTH.LAST,
  ]),
  onUpdateItem: PropTypes.func.isRequired,
};

const nextDueDefaults = {
  interval: 1,
  frequency: FREQUENCY.WEEKLY,
  daysOfWeek: [],
  instanceOfDayInMonth: INSTANCE_OF_DAY_IN_MONTH.DAY_NUMBER,
};

const daysOfWeek = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

function NextDueCellPopover (props) {
  const {
    nextDueAt: initialNextDueAt,
    nextDueInterval: initialNextDueInterval,
    nextDueFrequency: initialNextDueFrequency,
    nextDueDaysOfWeek: initialNextDueDaysOfWeek,
    nextDueInstanceOfDayInMonth: initialNextDueInstanceOfDayInMonth,
    onUpdateItem,
  } = props;

  const {setPopoverOpen} = usePopover();

  const [nextDueAt, setNextDueAt] = useState(initialNextDueAt);
  const [nextDueInterval, setNextDueInterval] = useState(initialNextDueInterval || nextDueDefaults.interval);
  const [nextDueFrequency, setNextDueFrequency] = useState(initialNextDueFrequency || nextDueDefaults.frequency);
  const [nextDueDaysOfWeek, setNextDueDaysOfWeek] = useState(initialNextDueDaysOfWeek || nextDueDefaults.daysOfWeek);
  const [nextDueInstanceOfDayInMonth, setNextDueInstanceOfDayInMonth] = useState(initialNextDueInstanceOfDayInMonth || nextDueDefaults.instanceOfDayInMonth);

  const nextDueAtDate = nextDueAt ? new Date(parseInt(nextDueAt, 10)) : undefined;
  const nextDueAtDateObj = nextDueAtDate ? dayjs(nextDueAtDate) : undefined;
  const nextDueAtDateNumber = nextDueAtDateObj ? nextDueAtDateObj.date() : undefined;
  const nextDueAtDayName = nextDueAtDateObj ? nextDueAtDateObj.format('dddd') : undefined;

  const handleUpdateDate = (value) => {
    const dateStr = `${value.valueOf()}`;
    const instanceOfDayInMonth = INSTANCE_OF_DAY_IN_MONTH.DAY_NUMBER;

    setNextDueAt(dateStr);
    setNextDueInstanceOfDayInMonth(instanceOfDayInMonth);

    const isFirstSelectedDueDate = nextDueDaysOfWeek.length === 0;
    if (isFirstSelectedDueDate) {
      const nextDueAtDayOfWeek = dayjs(value).format('ddd').toLowerCase();
      setNextDueDaysOfWeek([nextDueAtDayOfWeek]);
      onUpdateItem({
        nextDueAt: dateStr,
        nextDueDaysOfWeek: [nextDueAtDayOfWeek],
        nextDueInstanceOfDayInMonth: instanceOfDayInMonth,
      });

      return;
    }

    onUpdateItem({
      nextDueAt: dateStr,
      nextDueInstanceOfDayInMonth: instanceOfDayInMonth,
    });
  };

  const handleUpdateInterval = (value) => {
    // substr(0, 2) stops the value going over 99, as this leads to some digits
    // getting hidden which is confusing.
    const interval = parseInt(value.substr(0, 2).trim(), 10);

    setNextDueInterval(interval);

    if (Number.isNaN(interval)) {
      return;
    }

    onUpdateItem({
      nextDueInterval: interval,
    });
  };

  const handleUpdateFrequency = (value) => {
    setNextDueFrequency(value);

    onUpdateItem({
      nextDueFrequency: value,
    });
  };

  const toggleDayOfWeek = (day, selected) => {
    const value = selected
      ? [...new Set([...nextDueDaysOfWeek, DAYS_OF_WEEK[day]])]
      : nextDueDaysOfWeek.filter((d) => d !== DAYS_OF_WEEK[day]);

    setNextDueDaysOfWeek(value);

    onUpdateItem({
      nextDueDaysOfWeek: value,
    });
  };

  const handleUpdateInstanceOfDayInMonth = (value) => {
    setNextDueInstanceOfDayInMonth(value);

    onUpdateItem({
      nextDueInstanceOfDayInMonth: value,
    });
  };

  const handleRemove = () => {
    setNextDueAt(null);
    onUpdateItem({
      nextDueAt: null,
      nextDueInterval: nextDueDefaults.interval,
      nextDueFrequency: nextDueDefaults.frequency,
      nextDueDaysOfWeek: nextDueDefaults.daysOfWeek,
      nextDueInstanceOfDayInMonth: nextDueDefaults.instanceOfDayInMonth,
    });
    setPopoverOpen(false);
  };

  const nextDueAtDayOfMonth = nextDueAtDateObj ? nextDueAtDateObj.date() : undefined;
  const nextDueAtDaysInMonth = nextDueAtDateObj ? nextDueAtDateObj.daysInMonth() : undefined;

  return (
    <Wrapper>
      <LHS>
        <DatePicker
          selected={nextDueAtDate}
          onChange={handleUpdateDate}
          dayClassName={(date) => checkIfRecurringEventHappensOnDate(date, {
            nextDueAtDate,
            nextDueInterval,
            nextDueFrequency,
            nextDueDaysOfWeek,
            nextDueInstanceOfDayInMonth,
          }) && 'react-datepicker__highlighted'}
          inline
        />

        {nextDueAt && <>
          <Button
            fullwidth
            sm
            tw="mt-2 text-xs"
            link
            onClick={handleRemove}>
              Remove due date
          </Button>
        </>}
      </LHS>
      <RHS>
        <FormGroup top>
          <Label sm>Repeat every</Label>
          <RepeatInputWrapper>
            <Input
              sm
              number
              min={1}
              max={99}
              value={nextDueInterval}
              onChange={(e) => handleUpdateInterval(e.target.value)}
              tw="w-8!"
            />
            <Select
              sm
              value={nextDueFrequency}
              onChange={handleUpdateFrequency}
              options={[{
                label: <Pluralize singular={'week'} count={nextDueInterval} showCount={false} />,
                value: FREQUENCY.WEEKLY,
              }, {
                label: <Pluralize singular={'month'} count={nextDueInterval} showCount={false} />,
                value: FREQUENCY.MONTHLY,
              }, {
                label: <Pluralize singular={'year'} count={nextDueInterval} showCount={false} />,
                value: FREQUENCY.YEARLY,
              }]}
            />
          </RepeatInputWrapper>
        </FormGroup>
        {nextDueFrequency === FREQUENCY.WEEKLY && (
          <FormGroup>
            <Label sm>Repeat on</Label>
            <DaysOfWeek>
              {daysOfWeek.map((day) => (
                <DayOfWeek
                  key={day}
                  selected={nextDueDaysOfWeek.includes(DAYS_OF_WEEK[day])}
                  onClick={() => toggleDayOfWeek(day, !nextDueDaysOfWeek.includes(DAYS_OF_WEEK[day]))}
                >
                  {day.substr(0, 1)}
                </DayOfWeek>
              ))}
            </DaysOfWeek>
          </FormGroup>
        )}
        {nextDueFrequency === FREQUENCY.MONTHLY && (
          <FormGroup>
            <Label sm>Repeat on</Label>
            <Select sm value={nextDueInstanceOfDayInMonth} onChange={handleUpdateInstanceOfDayInMonth}>
              <Option value={INSTANCE_OF_DAY_IN_MONTH.DAY_NUMBER}>day {nextDueAtDateNumber}</Option>
              {nextDueAtDayOfMonth <= 7 && <Option value={INSTANCE_OF_DAY_IN_MONTH.FIRST}>first {nextDueAtDayName}</Option>}
              {nextDueAtDayOfMonth > 7 && nextDueAtDayOfMonth <= 14 && <Option value={INSTANCE_OF_DAY_IN_MONTH.SECOND}>second {nextDueAtDayName}</Option>}
              {nextDueAtDayOfMonth > 14 && nextDueAtDayOfMonth <= 21 && <Option value={INSTANCE_OF_DAY_IN_MONTH.THIRD}>third {nextDueAtDayName}</Option>}
              {nextDueAtDayOfMonth > 21 && nextDueAtDayOfMonth <= 28 && <Option value={INSTANCE_OF_DAY_IN_MONTH.FOURTH}>fourth {nextDueAtDayName}</Option>}
              {nextDueAtDayOfMonth > nextDueAtDaysInMonth - 7 && <Option value={INSTANCE_OF_DAY_IN_MONTH.LAST}>last {nextDueAtDayName}</Option>}
            </Select>
          </FormGroup>
        )}
      </RHS>
    </Wrapper>
  );
}

function checkIfRecurringEventHappensOnDate (date, {
  nextDueAtDate,
  nextDueInterval,
  nextDueFrequency,
  nextDueDaysOfWeek,
  nextDueInstanceOfDayInMonth,
}) {
  if (date <= nextDueAtDate) {
    return false;
  }

  const dateObj = dayjs(date);
  const nextDueAtObj = dayjs(new Date(nextDueAtDate));

  if (nextDueFrequency === FREQUENCY.DAILY) {
    // Not currently supported
  }

  if (
    nextDueFrequency === FREQUENCY.WEEKLY &&
    nextDueDaysOfWeek.includes(dateObj.format('ddd').toLowerCase()) &&
    dateObj.diff(nextDueAtObj.subtract(nextDueAtObj.day() - 1, 'day'), 'week') % nextDueInterval === 0
  ) {
    return true;
  }

  if (
    nextDueFrequency === FREQUENCY.MONTHLY &&
    nextDueInstanceOfDayInMonth === INSTANCE_OF_DAY_IN_MONTH.DAY_NUMBER &&
    dateObj.date() === nextDueAtObj.date() &&
    dateObj.diff(nextDueAtObj, 'month') % nextDueInterval === 0
  ) {
    return true;
  }

  if (
    nextDueFrequency === FREQUENCY.MONTHLY &&
    dateObj.day() === nextDueAtObj.day() &&
    (
      (nextDueInstanceOfDayInMonth === INSTANCE_OF_DAY_IN_MONTH.FIRST && dateObj.date() <= 7) ||
      (nextDueInstanceOfDayInMonth === INSTANCE_OF_DAY_IN_MONTH.SECOND && dateObj.date() > 7 && dateObj.date() <= 14) ||
      (nextDueInstanceOfDayInMonth === INSTANCE_OF_DAY_IN_MONTH.THIRD && dateObj.date() > 14 && dateObj.date() <= 21) ||
      (nextDueInstanceOfDayInMonth === INSTANCE_OF_DAY_IN_MONTH.FOURTH && dateObj.date() > 21 && dateObj.date() <= 28) ||
      (nextDueInstanceOfDayInMonth === INSTANCE_OF_DAY_IN_MONTH.LAST && dateObj.date() > dateObj.daysInMonth() - 7)
    ) &&
    dateObj.startOf('month').diff(nextDueAtObj.startOf('month'), 'month') % nextDueInterval === 0
  ) {
    return true;
  }

  if (
    nextDueFrequency === FREQUENCY.YEARLY &&
    dateObj.month() === nextDueAtObj.month() &&
    dateObj.date() === nextDueAtObj.date() &&
    dateObj.diff(nextDueAtObj, 'year') % nextDueInterval === 0
  ) {
    return true;
  }

  return false;
}

const Wrapper = styled.div((props) => [
  tw`
    flex
    m--3
  `,
]);

const LHS = styled.div((props) => [
  tw`
    flex-none
    pb-2
    px-4
    py-4
  `,
  css`
    width: min-content;
  `,
]);

const RHS = styled.div((props) => [
  tw`
    w-48
    px-4
    py-4
    bg-gray-50
  `,
]);

const RepeatInputWrapper = styled.div((props) => [
  tw`
    flex
    gap-2
  `,
]);

const DaysOfWeek = styled.div((props) => [
  tw`
    flex
    gap-1
    flex-wrap
  `,
]);

const DayOfWeek = styled.div((props) => [
  tw`
    w-7
    h-7
    bg-white
    flex
    items-center
    justify-center
    text-2xs
    rounded-full
    cursor-pointer
    select-none
  `,
  props.selected && tw`
    bg-blue-500
    text-white
  `,
]);

export default React.memo(NextDueCellPopover);
