import React from 'react';
import PropTypes from 'prop-types';
import {useDocument} from 'containers/Document/providers/DocumentProvider';
import {useSlateStatic} from 'slate-react';
import useDocumentFormInput from 'containers/DocumentForm/hooks/useDocumentFormInput';
import {Transforms} from 'slate';
import Checkbox from './Checkbox';

CheckboxContainer.propTypes = {
  element: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

function CheckboxContainer (props) {
  const {element} = props;
  const {id} = element;

  const documentState = useDocument();
  const {mode} = documentState;

  // When editing, the checkbox state is managed within the SlateEditor.
  if (mode === 'edit') {
    return renderCheckboxForEditor({id, props});
  }

  // When using, the state is managed by the DocumentProvider. This means the
  // state is specific to a response (loaded from the API) when responding to a
  // form.
  if (mode === 'use') {
    return renderCheckboxForForm({id, documentState, props});
  }

  // When viewing, the checkbox is readonly and cannot be clicked.
  return renderCheckboxForViewer({id, props});
}

function renderCheckboxForForm ({id, documentState, props}) {
  const {
    checkboxes,
    checkCheckbox,
    uncheckCheckbox,
  } = documentState;
  const {checked} = checkboxes[id];

  const [onChange, {
    readOnly,
  }] = useDocumentFormInput({id});

  const toggleCheckbox = () => {
    if (readOnly) {
      return;
    }

    if (checked) {
      uncheckCheckbox(id);
    }
    else {
      checkCheckbox(id);
    }

    onChange(!checked);
  };

  return (
    <Checkbox
      checked={checked}
      onClick={toggleCheckbox}
      readOnly={readOnly}
      {...props} />
  );
}

function renderCheckboxForEditor ({id, props}) {
  const {checked} = props.element;

  const editor = useSlateStatic();
  const toggleCheckbox = () => {
    Transforms.setNodes(
      editor,
      {
        checked: !checked,
      },
      {
        at: [],
        match: (n) => n.id === id,
      }
    );
  };

  return (
    <Checkbox
      checked={checked}
      editable
      onClick={toggleCheckbox}
      {...props} />
  );
}

function renderCheckboxForViewer ({id, props}) {
  const {checked} = props.element;

  return (
    <Checkbox
      checked={checked}
      onClick={() => {}}
      readOnly
      {...props} />
  );
}

export default CheckboxContainer;
