import {Editor} from 'slate';
import makeParagraph from '../factory/makeParagraph';
import checkCanElementTypeBeTransformed from './checkCanElementTypeBeTransformed';
import convertSelectedNodes from './convertSelectedNodes';

function toggleSelectedNodes (editor, {makeNode}) {
  const firstNodeToToggle = getFirstNodeToToggle(editor);
  if (!firstNodeToToggle) {
    return;
  }

  const nodeType = makeNode().type;

  if (firstNodeToToggle.type === nodeType) {
    convertSelectedNodes(editor, {makeNode: makeParagraph});
  }
  else {
    convertSelectedNodes(editor, {makeNode});
  }
}

function getFirstNodeToToggle (editor) {
  const nodes = Editor.nodes(editor, {
    match: (n) => checkCanElementTypeBeTransformed(n.type),
  });

  const firstEntry = nodes.next().value;
  if (!firstEntry) {
    return undefined;
  }

  const [firstNode] = firstEntry;
  return firstNode;
}

export default toggleSelectedNodes;
