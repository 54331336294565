import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {ReactEditor, useSlateStatic} from 'slate-react';
import {useDocument} from 'containers/Document/providers/DocumentProvider';
import insertProcessStep from 'containers/SlateEditor/utils/insertProcessStep';
import closeProcessSteps from 'containers/SlateEditor/utils/closeProcessSteps';
import {usePrintMode} from 'containers/PrintMode/providers/PrintModeProvider';
import Process from './Process';
import openNodeWithSlate from '../../utils/openNodeWithSlate';
import getNumStepsInProcess from './getNumStepsInProcess';

ProcessContainer.propTypes = {
  element: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

function ProcessContainer (props) {
  const printMode = usePrintMode();
  const {element} = props;
  const {id} = element;

  const documentState = useDocument();
  const {mode} = documentState;

  // When editing, the process state is managed within the SlateEditor.
  if (mode === 'edit') {
    return renderProcessForEditor({
      id, documentState, props, printMode,
    });
  }

  // When viewing and using, the state is managed by the DocumentProvider.
  // This means:
  // - The state is not shared by different users viewing the document.
  // - The state is specific to a response (loaded from the API) when responding
  //   to a form.
  return renderProcessForFormOrViewer({
    id, documentState, props, printMode,
  });
}

function renderProcessForFormOrViewer ({
  id, documentState, props, printMode,
}) {
  const {
    closeProcess,
    openProcess,
    processes,
    processSteps,
  } = documentState;

  const process = processes[id];
  const {open, progress} = process;

  const setOpen = (isOpen) => (isOpen ? openProcess(id) : closeProcess(id));
  const numSteps = Object.values(processSteps).filter((processStep) =>
    processStep.processId === id && !processStep.done).length;

  const context = {
    open,
    processId: id,
    processSelected: false,
    progress,
    setOpen,
    numSteps,
  };

  return (
    <ProcessContext.Provider value={context}>
      <Process
        {...props}
        open={open}
        printMode={!!printMode}
        progress={progress}
        setOpen={setOpen}/>
    </ProcessContext.Provider>
  );
}

function renderProcessForEditor ({
  id, props, documentState, printMode,
}) {
  const {selectedElements} = documentState;
  const selected = selectedElements.ids.includes(id);

  const {element} = props;
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element);

  const open = !!element.open;
  const done = !!element.done;
  const setOpen = useCallback((isOpen) => {
    openNodeWithSlate(editor, id, isOpen);

    if (!isOpen) {
      closeProcessSteps(editor, path);
    }
  }, []);

  const numSteps = getNumStepsInProcess(editor, id);
  const progress = [...Array(numSteps)].map(() => false);

  const processStepList = element.children[1];
  const addProcessStep = () => {
    insertProcessStep(editor, [...path, 1, processStepList.children.length]);
  };

  const context = {
    done,
    open,
    processId: id,
    processSelected: selected,
    setOpen,
    numSteps,
  };

  return (
    <ProcessContext.Provider value={context}>
      <Process
        addProcessStep={addProcessStep}
        editable
        open={open}
        printMode={!!printMode}
        progress={progress}
        selected={selected}
        {...props} />
    </ProcessContext.Provider>
  );
}

export const ProcessContext = React.createContext();

export default ProcessContainer;
