const makeOrderedListItem = ({text, textStyles = {}, ...attrs} = {}) => ({
  type: 'ordered-list-item',
  number: 1,
  ...attrs,
  children: [{
    text: text || '',
    ...textStyles,
  }],
});

export default makeOrderedListItem;
