import makeParagraph from '../factory/makeParagraph';
import insertNodeAtSelection from '../utils/insertNodeAtSelection';

const headingTypes = [
  'heading1',
  'heading2',
  'heading3',
  'heading4',
];

function onEnterAtStartOrEndOfHeading (editor, event, selectionMeta) {
  const {
    isSelectionAtEndOfElement,
    isSelectionAtStartOfElement,
    selectionStart,
    selectionStartElement,
  } = selectionMeta;

  if (
    event.key !== 'Enter' ||
    !selectionStart ||
    (!isSelectionAtStartOfElement && !isSelectionAtEndOfElement) ||
    !headingTypes.includes(selectionStartElement.type)
  ) {
    return false;
  }

  event.preventDefault();
  insertNodeAtSelection(editor, {makeNode: makeParagraph});

  return true;
}

export default onEnterAtStartOrEndOfHeading;
