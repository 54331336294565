function processStepsReducer (processSteps, action) {
  const {
    id: processStepId,
    type,
  } = action;

  const processStep = processSteps[processStepId];

  switch (type) {
    case 'open':
      return {
        ...processSteps,
        [processStepId]: {
          ...processStep,
          open: true,
        },
      };
    case 'close':
      return {
        ...processSteps,
        [processStepId]: {
          ...processStep,
          open: false,
        },
      };
    case 'markDone':
      // eslint-disable-next-line no-case-declarations
      const nextIncompleteProcessStep = getNextIncompleteProcessStep(processSteps, processStepId);

      if (nextIncompleteProcessStep && nextIncompleteProcessStep.processId === processStep.processId) {
        return {
          ...processSteps,
          [processStepId]: {
            ...processStep,
            done: true,
            open: false,
          },
          [nextIncompleteProcessStep.id]: {
            ...nextIncompleteProcessStep,
            open: true,
          },
        };
      }

      return {
        ...processSteps,
        [processStepId]: {
          ...processStep,
          done: true,
          open: false,
        },
      };
    case 'openNext':
      // eslint-disable-next-line no-case-declarations
      const nextProcessStep = getNextProcessStep(processSteps, processStepId);

      if (nextProcessStep && nextProcessStep.processId === processStep.processId) {
        return {
          ...processSteps,
          [processStepId]: {
            ...processStep,
            open: false,
          },
          [nextProcessStep.id]: {
            ...nextProcessStep,
            open: true,
          },
        };
      }

      return {
        ...processSteps,
        [processStepId]: {
          ...processStep,
          open: false,
        },
      };
    case 'markNotDone':
      return {
        ...processSteps,
        [processStepId]: {
          ...processStep,
          done: false,
        },
      };
    default:
      throw new Error();
  }
}

function getNextIncompleteProcessStep (processSteps, processStepId) {
  let nextProcessStep = getNextProcessStep(processSteps, processStepId);

  while (nextProcessStep !== undefined) {
    if (!nextProcessStep.done) {
      return nextProcessStep;
    }

    nextProcessStep = nextProcessStep.nextProcessStep ? processSteps[nextProcessStep.nextProcessStep.id] : undefined;
  }

  return undefined;
}

function getNextProcessStep (processSteps, processStepId) {
  const processStep = processSteps[processStepId];

  if (!processStep.nextProcessStep) {
    return undefined;
  }

  return processSteps[processStep.nextProcessStep.id];
}

export default processStepsReducer;
