import {useState} from 'react';
import getRootAPIUri from '../../../utils/getRootAPIUri';

/**
 * Here we are still using the REST endpoint because it was already implemented.
 * Migrating to GraphQL and adapting passport to it requires more effort than we were willing to put
 */
function useSignupMutation (props) {
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const signup = async ({variables: vars}) => {
    setLoading(true);
    setData(false);
    setError(null);

    const url = `${getRootAPIUri()}/signup`;

    try {
      const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(vars),
      });
      if (response.ok) {
        setData(true);
        setError(null);
      }
      else {
        setError({
          type: 'server',
          code: response.status,
          message: 'Something went wrong, please try again.',
        });
      }
    }
    catch (err) {
      setError({
        type: 'server',
        message: 'Could not connect to server. Please try again',
      });
    }
    setLoading(false);
  };

  return [
    signup,
    {
      data,
      loading,
      error,
    },
  ];
}

export default useSignupMutation;
