import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line
import tw, {styled, css} from 'twin.macro';
import {useLocation} from '@reach/router';
import Button from 'components/Button';
import Link from 'components/Link';
import {getItemUrlName} from 'am-utils';
import Option from 'components/Option';

TemplatesScreen.propTypes = {
  setTemplateRefAndKey: PropTypes.func.isRequired,
  templates: PropTypes.array,
};

function TemplatesScreen ({
  setTemplateRefAndKey,
  templates,
}) {
  const templateComponents = getExampleWorkspaceTemplateComponents({templates, setTemplateRefAndKey});

  return (<List>
    {templateComponents}
  </List>);
}

function getExampleWorkspaceTemplateComponents ({templates, setTemplateRefAndKey}) {
  const location = useLocation();

  return templates.map(({name, itemRef: ref, itemKey: key}) => (
    <Option
      key={name}
      description={name}
      primaryAction={
        <Button
          onClick={() => {
            setTemplateRefAndKey({ref, key});
          }}
          primary
          smText
          type="button"
        >
          Use template
        </Button>
      }
      secondaryAction={
        <Link
          to={`${location.protocol}//${location.host}/example/${getItemUrlName({
            ref,
            key,
            name,
          })}`}
        >
          <Button gray smText type="button">
            Preview
          </Button>
        </Link>
      }
    />
  ));
}

const List = styled.div((props) => [
  tw`
    flex
    flex-col
    gap-4
    mb-12
  `,
]);

export default TemplatesScreen;
