import {Transforms} from 'slate';

function alignImage (editor, align) {
  Transforms.setNodes(
    editor,
    {align},
    {match: (n) => n.type === 'image'}
  );
}

export default alignImage;
