import React from 'react';
import {Helmet} from 'react-helmet';
import FormGroup from 'components/FormGroup';
import Label from 'components/Label';
import Input from 'components/Input';
import Button from 'components/Button';
import SimpleFormContainer from 'components/SimpleFormContainer';
import SimpleFormHeading from 'components/SimpleFormHeading';
import SimpleFormPage from 'components/SimpleFormPage';
import tw, {styled} from 'twin.macro'; // eslint-disable-line no-unused-vars
import Tip from 'components/Tip';
import PropTypes from 'prop-types';
import Error from 'components/Error';
import useURLSearchParam from 'hooks/useURLSearchParam';

WorkspaceCreatorPage.propTypes = {
  createWorkspace: PropTypes.func.isRequired,
  error: PropTypes.object,
  loading: PropTypes.bool.isRequired,
};

function WorkspaceCreatorPage (props) {
  const {
    createWorkspace,
    error,
    loading,
  } = props;

  const allowedWorkspaceUrlCharacters = 'a-z0-9-';
  const defaultOrganizationName = useURLSearchParam('organizationName') || '';
  const defaultWorkspaceUrl = defaultOrganizationName
    .trim()
    .toLowerCase()
    .replaceAll(new RegExp(`[^${allowedWorkspaceUrlCharacters}]+`, 'g'), ' ')
    .replaceAll(/ +/g, '-');

  const onSubmit = (e) => {
    e.preventDefault();
    createWorkspace({
      name: e.target.elements[0].value,
      urlName: e.target.elements[1].value,
    });
  };

  return <>
    <Helmet>
      <title>Create your workspace</title>
    </Helmet>
    <SimpleFormPage >
      <SimpleFormContainer>
        <form onSubmit={onSubmit}>
          <SimpleFormHeading>Create your workspace</SimpleFormHeading>
          <FormGroup>
            <Label>
              Workspace name
            </Label>
            <Input placeholder="Company X" required defaultValue={defaultOrganizationName} maxLength="100" />
            <Tip>Your company or organization name</Tip>
          </FormGroup>
          <FormGroup>
            <Label>
              Workspace URL
            </Label>
            <Input
              prefix="app.airmanual.co/"
              placeholder="companyx"
              data-test-id="workspace-url-input"
              defaultValue={defaultWorkspaceUrl}
              required maxLength="30"
              pattern={`[${allowedWorkspaceUrlCharacters}]+`}
              title="Please insert only lower case letters, numbers and dashes"
            />
          </FormGroup>
          <FormGroup buttons>
            <Button primary lg fullwidth disabled={loading}>Create</Button>
          </FormGroup>
          {error && !loading && (
            <Error arrow>{error.message}</Error>
          )}
        </form>
      </SimpleFormContainer>
    </SimpleFormPage>

  </>;
}

export default WorkspaceCreatorPage;
