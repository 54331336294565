import tw, {styled, css} from 'twin.macro';
import Button from 'components/Button';

const PageHeader = styled.div((props) => [
  tw`
    px-4
    py-3
    flex
    bg-white
  `,
]);

export const PageHeaderSearch = styled.div((props) => [
  tw`
    flex-auto
    max-w-lg
  `,
]);

export const PageHeaderRight = styled.div((props) => [
  tw`
    flex-auto
    flex
    justify-end
    items-center
  `,
]);

export const PageHeaderAvatarButton = styled.button((props) => [
  css`
    width: 44px; /* Same width as height */
    height: 44px; /* Same height as search input */
    background: url(${props.img});
  `,
  tw`
    block
    bg-contain
    bg-no-repeat
    bg-center
    bg-gray-100
    text-gray-400
    text-xs
    font-bold
    flex
    justify-center
    items-center
    pt-1
    rounded
    focus:border-2
    focus:border-blue-400!
  `,
]);

export const LogoButton = styled.button((props) => [
  css`
    margin: 6px 0;
    width: 32px; /* Same width as height */
    height: 32px; /* Same height as search input (when combined with padding) */
    background: url(${props.img});
  `,
  tw`
    block
    bg-contain
    bg-no-repeat
    bg-center
    focus:outline-none
  `,
]);

export const PageHeaderSpacer = styled.div((props) => [
  tw`
    w-3
  `,
]);

export const PageHeaderButton = styled(Button)((props) => [
  tw`
    h-full
    rounded
    text-xs
    focus:border-2
    focus:border-blue-400
    focus:m--0.5
  `,
]);

export default PageHeader;
