import {Transforms} from 'slate';
import makeParagraph from '../factory/makeParagraph';

function makeSureLastElementIsParagraph (editor, [entryNode, entryNodePath]) {
  if (editor.children[editor.children.length - 1].type === 'paragraph') {
    return false;
  }

  Transforms.insertNodes(editor, makeParagraph(), {
    at: [editor.children.length],
  });

  return true;
}

export default makeSureLastElementIsParagraph;
