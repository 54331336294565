import {
  Editor, Transforms,
} from 'slate';
import getElementById from './getElementById';

function openNodeWithSlate (editor, nodeId, open) {
  Transforms.setNodes(
    editor,
    {
      open,
    },
    {
      at: [],
      match: (n) => n.id === nodeId,
    }
  );

  // eslint-disable-next-line
  const [node, nodePath] = getElementById(editor, nodeId);
  const start = Editor.start(editor, nodePath);
  const endOfTitle = Editor.end(editor, start.path);
  const location = endOfTitle;

  Transforms.setSelection(editor, {
    anchor: location,
    focus: location,
  });
}

export default openNodeWithSlate;
