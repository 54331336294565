import React from 'react';
import ItemProvider from 'providers/ItemProvider';
import TeamMembersPageErrorBoundary from './components/TeamMembersPageErrorBoundary';
import TeamMemberViewerLoader from './TeamMemberViewerLoader';

function TeamMemberViewer () {
  return (
    // Currently item provider requires a null item as it's wrapped in a WorkspacePage component.
    // Workspace pages use the item in order to check if the topbar containing the search component
    // should  be shown, as it should not in the case of a public checklist without any public ancestors.
    <ItemProvider item={null}>
      <TeamMembersPageErrorBoundary>
        <TeamMemberViewerLoader />
      </TeamMembersPageErrorBoundary>
    </ItemProvider>
  );
}

export default TeamMemberViewer;
