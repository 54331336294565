import React from 'react';
import Modal from 'components/Modal';
import Button, {ButtonSpacer} from 'components/Button';
import PropTypes from 'prop-types';
import Input from 'components/Input';
import FormGroup from 'components/FormGroup';
import Label from 'components/Label';

LinkCreatorModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onNameChange: PropTypes.func.isRequired,
  onUrlChange: PropTypes.func.isRequired,
  nameValue: PropTypes.string.isRequired,
  urlValue: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  renderError: PropTypes.func,
};

function LinkCreatorModal (props) {
  const {
    isVisible,
    onNameChange,
    onUrlChange,
    nameValue,
    urlValue,
    closeModal,
    onSubmit,
    renderError,
  } = props;

  return (
    <Modal
      errorTitle={'Error adding'}
      footer={<>
        <Button type="button" link onClick={closeModal}>
          Cancel
        </Button>
        <ButtonSpacer />
        <Button type="submit" primary disabled={!nameValue.trim() || !urlValue.trim()} data-test-id="modal_create-button">
          Create
        </Button>
      </>}
      renderError={renderError}
      onClose={closeModal}
      onSubmit={onSubmit}
      title="Create link"
      visible={isVisible}
    >
      <FormGroup top>
        <Label>Name</Label>
        <Input maxLength={50} data-test-id="modal_name_input" value={nameValue} onChange={onNameChange} className="modal-focus"></Input>
      </FormGroup>
      <FormGroup bottom>
        <Label>Web address (URL)</Label>
        <Input maxLength={1000} data-test-id="modal_url_input" value={urlValue} onChange={onUrlChange} placeholder="http://www.example.com/"></Input>
      </FormGroup>
    </Modal>
  );
}

export default LinkCreatorModal;
