import React from 'react';
import PropTypes from 'prop-types';

TextAreaIcon.propTypes = {
  size: PropTypes.number,
};

TextAreaIcon.defaultProps = {
  size: 16,
};

function TextAreaIcon (props) {
  return (
    <svg
      enableBackground="new 0 0 2447.6 2452.5"
      viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      fill="currentColor"
      strokeWidth={0}
      width={props.size}
      height={props.size}
    >
      <g transform="matrix(1,0,0,1,-17.8937,362.769)">
        <path d="M987.875,-262.851L71.913,-262.851C54.213,-262.851 39.913,-248.551 39.913,-230.851L39.913,529.313C39.913,547.013 54.213,561.313 71.913,561.313L987.875,561.313C1005.58,561.313 1019.88,547.013 1019.88,529.313L1019.88,-230.851C1019.88,-248.551 1005.58,-262.851 987.875,-262.851ZM947.875,489.313L111.913,489.313L111.913,-190.851L947.875,-190.851L947.875,489.313Z"/>
      </g>
      <g transform="matrix(-1.29585,1.29585,-1.22449,-1.22449,2795.97,649.74)">
        <path d="M801.891,728.423L888.814,821.037L714.968,821.037L801.891,728.423Z"/>
      </g>
    </svg>
  );
}

export default TextAreaIcon;
