import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import CustomPropTypes from 'utils/CustomPropTypes';
import tw, {styled, css} from 'twin.macro';
import {motion} from 'framer-motion';
import {AiOutlineClose} from 'react-icons/ai';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';
import ItemSharer from 'containers/ItemSharer';
import ItemDeleter from 'containers/ItemDeleter';
import FolderRenamer from 'containers/FolderRenamer';
import getNameForItem from 'utils/getNameForItem';
import ItemAutomationCreator from 'containers/ItemAutomationCreator';
import ItemAutomationEditor from 'containers/ItemAutomationEditor';
import getUrlForItem from 'utils/getUrlForItem';
import SidePanelContent from './SidePanelContent';

SidePanel.propTypes = {
  automations: PropTypes.array,
  contentDescription: PropTypes.array,
  inputs: PropTypes.array,
  item: CustomPropTypes.Item,
  onClose: PropTypes.func.isRequired,
  onUpdateItem: PropTypes.func.isRequired,
  responses: PropTypes.array,
  responseStats: PropTypes.shape({}),
  savingAttrs: PropTypes.array,
};

SidePanel.defaultProps = {
  savingAttrs: [],
};

function SidePanel (props) {
  const {
    automations,
    contentDescription,
    onClose,
    onUpdateItem,
    inputs,
    item,
    responses,
    responseStats,
    savingAttrs,
  } = props;

  const workspace = useWorkspace();

  const [open, setOpen] = useState();
  const [isAutomationModalVisible, setIsAutomationModalVisible] = useState(false);
  const [isShareItemModalVisible, setIsShareItemModalVisible] = useState(false);
  const [isDeleteItemModalVisible, setIsDeleteItemModalVisible] = useState(false);
  const [isRenameFolderModalVisible, setIsRenameFolderModalVisible] = useState(false);
  const [isAutomationEditorModalVisible, setIsAutomationEditorModalVisible] = useState(false);

  const [automationEditorData, setAutomationEditorData] = useState({});

  useEffect(() => {
    const handleKeyDown = (event) => event.key === 'Escape' && setOpen(false);
    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  const sidePanelVariants = {
    open: {
      right: 0,
    },
    closed: {
      right: -512,
    },
  };

  function onClickClose () {
    setOpen(false);

    // Calling onClose too quickly would lead to item being set to undefined,
    // and the side panel content disappearing before the slide out animation
    // has completed.
    setTimeout(onClose, 300);
  }

  useEffect(() => {
    if (item && !open) {
      setOpen(true);
    }

    if (!item && open) {
      setOpen(false);
    }
  }, [item]);

  return (
    <>
      {item && <>
        <FolderRenamer
          isVisible={isRenameFolderModalVisible}
          setIsVisible={setIsRenameFolderModalVisible}
          itemRef={item.ref}
          itemKey={item.key}
          currentFolderName={item.name}
        />
        <ItemDeleter
          isVisible={isDeleteItemModalVisible}
          itemRef={item.ref}
          itemKey={item.key}
          itemTypeId={item.itemTypeId}
          setIsVisible={setIsDeleteItemModalVisible}
          closeSidePanel={onClickClose}
          workspaceUrlName={workspace.urlName}
        />
        <ItemSharer
          key={item.key}
          isVisible={isShareItemModalVisible}
          isItemPublic={item.public}
          itemKey={item.key}
          itemName={getNameForItem(item)}
          itemRef={item.ref}
          itemTypeId={item.itemTypeId}
          itemUrl={getUrlForItem(workspace.urlName, item)}
          setIsVisible={setIsShareItemModalVisible}
          userRoleIds={item.userRoleIds}
          loggedInUserRoleIds={item.loggedInUserRoleIds}
          workspaceMembersRoleIds={item.workspaceMembersRoleIds}
        />
        <ItemAutomationCreator
          inputs={inputs}
          itemRef={item.ref}
          itemKey={item.key}
          itemName={getNameForItem(item)}
          itemTypeId={item.itemTypeId}
          itemUrl={item.urlName}
          isVisible={isAutomationModalVisible}
          loggedInUserRoleIds={item.loggedInUserRoleIds}
          setIsVisible={setIsAutomationModalVisible}
        />
        <ItemAutomationEditor
          inputs={inputs}
          itemRef={item.ref}
          itemKey={item.key}
          automationEditorData={automationEditorData}
          itemName={getNameForItem(item)}
          itemTypeId={item.itemTypeId}
          itemUrl={item.urlName}
          isVisible={isAutomationEditorModalVisible}
          loggedInUserRoleIds={item.loggedInUserRoleIds}
          setIsVisible={setIsAutomationEditorModalVisible}
        />
      </>}
      <SidePanelContainer data-test-id="side-panel">
        <motion.div
          animate={open ? 'open' : 'closed'}
          variants={sidePanelVariants}
          initial={false}
          transition={{
            duration: item ? 0.3 : 0.2,
          }}
          tw="absolute right-0 overflow-hidden pl-4">
          <SidePanelWrapper>
            <CloseButton onClick={onClickClose}>
              <AiOutlineClose/>
            </CloseButton>
            {item &&
            <SidePanelContent
              automations={automations}
              contentDescription={contentDescription}
              item={item}
              onUpdateItem={onUpdateItem}
              responses={responses}
              responseStats={responseStats}
              savingAttrs={savingAttrs}
              setIsAutomationModalVisible={setIsAutomationModalVisible}
              setIsShareItemModalVisible={setIsShareItemModalVisible}
              setIsDeleteItemModalVisible={setIsDeleteItemModalVisible}
              setIsRenameFolderModalVisible={setIsRenameFolderModalVisible}
              setIsAutomationEditorModalVisible={setIsAutomationEditorModalVisible}
              setAutomationEditorData={setAutomationEditorData}
            />}
          </SidePanelWrapper>
        </motion.div>
      </SidePanelContainer>
    </>

  );
}

const SidePanelContainer = styled.div((props) => [
  tw`
    sticky
    top-0
    mt-px
    z-20
    h-full
  `,
]);

const SidePanelWrapper = styled.div((props) => [
  tw`
    bg-white
    sm:w-128
    overflow-scroll
  `,
  css`
    width: 100vw;
    height: 100vh;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 8px 12px;
  `,
]);

const CloseButton = styled.div((props) => [
  tw`
    absolute
    top-4
    right-3
    p-4
    cursor-pointer
    text-gray-400
  `,
]);

export default SidePanel;
