import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line
import tw, {styled} from 'twin.macro';
import {useLocation} from '@reach/router';
import Button from 'components/Button';
import Link from 'components/Link';
import {getItemUrlName} from 'am-utils';
import Option from 'components/Option';
import Card from 'components/Card';
import {AiOutlineFile, AiOutlineRobot} from 'react-icons/ai';
import {FINAL_PAGE_TYPE} from '../wizardConstants';
import UseATemplateCard from './UseATemplateCard';

ChooseStartScreen.propTypes = {
  closeAndInsertProcess: PropTypes.func.isRequired,
  getTemplates: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  isFormChecklist: PropTypes.bool.isRequired,
  isOnboardingChecklist: PropTypes.bool.isRequired,
  isTrainingChecklist: PropTypes.bool.isRequired,
  isGetTemplateError: PropTypes.bool.isRequired,
  setTemplateRefAndKey: PropTypes.func.isRequired,
  setFinalPageType: PropTypes.func.isRequired,
  templates: PropTypes.array,
};

const onboardingTemplates = [
  {
    name: 'Company onboarding',
    relevance: 5,
    itemRef: '9HizpBH7nZqwbFiKPjvsNnCM',
    itemKey: 'EWYUQkXV9K6R',
  },
  {
    name: 'Team onboarding',
    relevance: 4,
    itemRef: '4PCekmxk4pVXNEzn7sg6uxBK',
    itemKey: 'oCrcvvScesrv',
  },
  {
    name: 'Role onboarding',
    relevance: 3,
    itemRef: 'NteBXiXHtx9CH7Jm65QUxN9I',
    itemKey: 'nwwjxKxSjl7w',
  },
  {
    name: 'Prepare for a new employee',
    relevance: 2,
    itemRef: '3u8n0NiTdOIlubGg2KdwxLv8',
    itemKey: 'ZOqGFKSqn43z',
  },
  {
    name: 'Prepare for a new team member',
    relevance: 1,
    itemRef: 'JcJ2YgPgCLnjU42EgtjFf3Y8',
    itemKey: 'voQpVLW1dzlw',
  },
];

const trainingTemplates = [
  {
    name: 'Simple training checklist',
    relevance: 5,
    itemRef: '42G7iGpSTpGvOsmHr40HijBR',
    itemKey: 'PLFhE7N0aTKr',
  },
];

const formTemplates = [
  {
    name: 'Employee NPS survey',
    relevance: 5,
    itemRef: 'x9bMH3LvVJRuGkupDnfSJEgM',
    itemKey: 'qPpKhtGJ52Rk',
  },
  {
    name: 'Design feedback checklist',
    relevance: 4,
    itemRef: '7g2MHSuAxfKPmSNHEucBuv3U',
    itemKey: 'AbaieN38s8U4',
  },
  {
    name: 'Gift preference form',
    relevance: 3,
    itemRef: 'swUPhM7qYKC6qkwBs3SPBfr7',
    itemKey: '9ufQxAGCDDuJ',
  },
];

function ChooseStartScreen ({
  closeAndInsertProcess,
  getTemplates,
  setStep,
  isFormChecklist,
  isOnboardingChecklist,
  isTrainingChecklist,
  isGetTemplateError,
  setTemplateRefAndKey,
  setFinalPageType,
  templates,
}) {
  if (isOnboardingChecklist) {
    const onboardingTemplateComponents = getTemplateWorkspaceTemplateComponents({
      templates: onboardingTemplates,
      setTemplateRefAndKey,
    });

    return (
      <List>
        {onboardingTemplateComponents}
      </List>
    );
  }

  if (isTrainingChecklist) {
    const trainingTemplateComponents = getTemplateWorkspaceTemplateComponents({
      templates: trainingTemplates,
      setTemplateRefAndKey,
    });

    return (
      <List>
        {trainingTemplateComponents}
      </List>
    );
  }

  if (isFormChecklist) {
    const formTemplateComponents = getTemplateWorkspaceTemplateComponents({
      templates: formTemplates,
      setTemplateRefAndKey,
    });

    return (
      <List>
        {formTemplateComponents}
      </List>
    );
  }

  useEffect(() => {
    if (!templates) {
      getTemplates();
    }
  }, [templates]);

  return (
    <List>
      <Card
        icon={<AiOutlineRobot size={40} />}
        heading="Generate using AI"
        onClick={() => {
          setFinalPageType(FINAL_PAGE_TYPE.AI_GENERATION);
          setStep((prev) => prev + 1);
        }}
      >
        Generate checklist using the title and context you provide.
      </Card>
      <Card
        icon={<AiOutlineFile size={40} />}
        heading="Empty checklist"
        onClick={closeAndInsertProcess}
      >
        Start with an empty checklist.
      </Card>
      <UseATemplateCard
        templates={templates}
        isGetTemplateError={isGetTemplateError}
        setFinalPageType={setFinalPageType}
        setStep={setStep}
      />
    </List>
  );
}

function getTemplateWorkspaceTemplateComponents ({templates, setTemplateRefAndKey}) {
  const location = useLocation();

  return templates.map(({name, itemRef: ref, itemKey: key}) => (
    <Option
      key={name}
      description={name}
      primaryAction={
        <Button
          onClick={() => {
            setTemplateRefAndKey({ref, key});
          }}
          primary
          smText
          type="button"
        >
          Use template
        </Button>
      }
      secondaryAction={
        <Link
          to={`${location.protocol}//${location.host}/templates/${getItemUrlName({
            ref,
            key,
            name,
          })}`}
        >
          <Button gray smText type="button">
            Preview
          </Button>
        </Link>
      }
    />
  ));
}

const List = styled.div((props) => [
  tw`
    flex
    flex-col
    gap-4
    mb-12
  `,
]);

export default ChooseStartScreen;
