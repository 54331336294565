import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled} from 'twin.macro';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';
import DropdownButton from 'components/DropdownButton';
import {ITEM_TYPE} from 'am-constants';
import BreadcrumbItem, {BreadcrumbItemWrapper} from './BreadcrumbItem';
import BreadcrumbSeparator from './BreadcrumbSeparator';

Breadcrumbs.propTypes = {
  currentFolder: PropTypes.shape({
    ref: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    urlName: PropTypes.string.isRequired,
  }),
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
  })).isRequired,
  isPageDisplayingSearchResults: PropTypes.bool,
  sm: PropTypes.bool,
};

Breadcrumbs.defaultProps = {
  currentFolder: undefined,
  isPageDisplayingSearchResults: false,
};

function Breadcrumbs (props) {
  const {
    currentFolder,
    items,
    isPageDisplayingSearchResults,
    sm,
    ...rest
  } = props;

  const {urlName: workspaceUrlName, featureFlags} = useWorkspace();
  const featureFlag = featureFlags['2022_09_dashboard'];

  const itemsWithoutSections = items.filter((item) => item.itemTypeId !== ITEM_TYPE.SECTION);
  const allItems = currentFolder ? [...itemsWithoutSections, currentFolder] : itemsWithoutSections;
  const firstItem = allItems[0];
  const midItems = allItems.length > 2 ? allItems.slice(1, -1) : [];
  const menu = [midItems.map((item) => ({
    label: item.name,
    to: `/${workspaceUrlName}/folders/${item.urlName}`,
  }))];
  const lastItem = allItems.length > 1 && allItems[allItems.length - 1];

  return (
    <BreadcrumbsContainer legacyStyle={!featureFlag} sm={sm} {...rest}>
      {firstItem && (
        <BreadcrumbItem
          home={featureFlags['2022_09_dashboard']}
          item={firstItem}
          makeLink={!!lastItem || midItems.length === 0}
          sm={sm} />
      )}
      {midItems.length > 0 && <>
        <BreadcrumbSeparator />
        <BreadcrumbItemWrapper featureFlag={featureFlag} sm={sm}>
          <DropdownButton menu={menu} xDirection='right' xOffset={-4} doNotWrapInButton>
            <ThreeDotsButton data-test-id='breadcrumb'>...</ThreeDotsButton>
          </DropdownButton>
        </BreadcrumbItemWrapper>
      </>}
      {lastItem && <>
        <BreadcrumbSeparator />
        <BreadcrumbItem
          item={lastItem}
          makeLink={!isPageDisplayingSearchResults} />
      </>}
      {isPageDisplayingSearchResults && <>
        <BreadcrumbSeparator />
        <span data-test-id={'breadcrumb'}>
          Search results
        </span>
      </>}
      {!currentFolder && allItems.length > 0 && <BreadcrumbSeparator />}
    </BreadcrumbsContainer>
  );
}

const BreadcrumbsContainer = styled.div.attrs({
  'data-test-id': 'breadcrumbs',
})((props) => [
  tw`
    flex
    flex-wrap
    text-base
    text-gray-500
    items-center
    mb--0.5
    h-8
  `,
  props.sm && tw`
    text-sm
  `,
  props.legacyStyle && tw`
    h-auto
    text-gray-400
    text-xs
    font-bold
    mb-2
  `,
]);

const ThreeDotsButton = styled.div((props) => [
  tw`
    cursor-pointer
  `,
]);

export default Breadcrumbs;
