import React from 'react';
import PropTypes from 'prop-types';
import P from 'components/P';
import {SUBSCRIPTION_TYPE} from 'am-constants';
import Link from 'components/Link';
import Button from 'components/Button';
import {getIsSubscriptionExpired} from 'am-utils';

BillingPageDetails.propTypes = {
  endsAt: PropTypes.string,
  isFreeTrial: PropTypes.bool.isRequired,
  subscriptionTypeId: PropTypes.number.isRequired,
};

function BillingPageDetails (props) {
  const {
    endsAt,
    isFreeTrial,
    subscriptionTypeId,
  } = props;

  const isSubscriptionExpired = getIsSubscriptionExpired(endsAt);
  const planType = Object.keys(SUBSCRIPTION_TYPE)
    .find((s) => SUBSCRIPTION_TYPE[s] === subscriptionTypeId)
    .toLowerCase();
  const planTypeName = planType[0].toUpperCase() + planType.slice(1);
  const expiryDate = new Intl.DateTimeFormat('en-UK', {
    dateStyle: 'long',
  }).format(endsAt);

  return <>
    {!isFreeTrial && !isSubscriptionExpired && <>
      <P>You are currently on the {planTypeName} plan.</P>
      <Link to="https://app.airmanual.co/air/How-do-I-change-my-pricing-plan--WzaRnjQY9Uw5UQ1FfA8vDEXP-yBGIRQGHbcIl"><Button primary>Change plan</Button></Link>
    </>}

    {isFreeTrial && !isSubscriptionExpired && endsAt && <P>Your free trial ends on {expiryDate}.</P>}
    {isFreeTrial && !isSubscriptionExpired && !endsAt && <P>You are currently on a free trial.</P>}

    {isSubscriptionExpired &&
      <P>Your {isFreeTrial ? 'free trial' : 'subscription'} has ended. Please choose a plan to continue using AirManual.</P>}

    {(isFreeTrial || isSubscriptionExpired) &&
      <Link to="https://app.airmanual.co/air/How-do-I-set-up-my-pricing-plan--qyThOS2mFBJN24LCAwQ4dbo3-MLcrgYqVzOPJ"><Button primary>Choose plan</Button></Link>}
  </>;
}

export default BillingPageDetails;
