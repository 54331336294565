import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {nanoid} from 'nanoid';
import List from './List'; // eslint-disable-line import/no-cycle

SelectableList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChange: PropTypes.func.isRequired,
};

SelectableList.defaultProps = {
  compact: false,
};

function SelectableList (props) {
  const {
    items,
    onChange,
    ...rest
  } = props;

  const [selectedItemId, setSelectedItemId] = useState(undefined);

  const itemsWithIds = useMemo(() => addIdsToItems(items), [items]);

  return (
    <List
      items={itemsWithIds}
      onClickItem={(itemId, name) => {
        setSelectedItemId(itemId);
        onChange(itemId, name);
      }}
      selectedItemId={selectedItemId}
      {...rest}
    />
  );
}

function addIdsToItems (items) {
  return items.map((item) => {
    if (item.id) {
      return item;
    }

    const childrenAttr = item.children ? {
      children: addIdsToItems(item.children),
    } : {};

    return {
      ...item,
      ...childrenAttr,
      id: nanoid(),
    };
  });
}

export default SelectableList;
