import React from 'react';
import PropTypes from 'prop-types';
import GridCell from './GridCell';

DataGridCell.propTypes = {
  column: PropTypes.shape({
    linkTo: PropTypes.func,
    onClick: PropTypes.func,
    emoji: PropTypes.func,
    render: PropTypes.func,
    width: PropTypes.string,
  }).isRequired,
  first: PropTypes.bool.isRequired,
  item: PropTypes.shape({
    expandable: PropTypes.bool,
    expanded: PropTypes.bool,
    id: PropTypes.number,
    indentLevel: PropTypes.number,
    saving: PropTypes.bool,
    section: PropTypes.bool,
    selected: PropTypes.bool,
  }).isRequired,
};

DataGridCell.defaultProps = {
  first: false,
};

function DataGridCell (props) {
  const {
    column,
    first,
    item,
  } = props;

  return <>
    <GridCell
      width={column.width}
      emoji={column.emoji ? column.emoji(item) : undefined}
      first={first}
      item={item}
      linkTo={column.linkTo ? column.linkTo(item) : undefined}
      onClick={column.onClick}
      saving={first && item.saving}>
      {column.render && column.render(item)}
    </GridCell>
  </>;
}

export default DataGridCell;
