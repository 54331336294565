import {Transforms} from 'slate';
import makeParagraph from 'containers/SlateEditor/factory/makeParagraph';
import getSelectionMeta from '../utils/getSelectionMeta';

function insertFragment (editor, data, selectionMeta) {
  const fragment = data.getData('application/x-slate-fragment');

  if (!fragment) {
    return false;
  }

  const decoded = decodeURIComponent(window.atob(fragment));
  const parsed = JSON.parse(decoded);

  // form-group elements need to be pasted into an empty paragraph
  const {selectionAnchorElement} = getSelectionMeta(editor);
  const isSelectionInEmptyParagraph =
    selectionAnchorElement.type === 'paragraph' &&
    selectionAnchorElement.children[0].text.trim() === '';

  if (
    parsed.length > 0 &&
    parsed[0].type === 'form-group' &&
    !isSelectionInEmptyParagraph
  ) {
    Transforms.insertNodes(editor, makeParagraph());
  }

  editor.insertFragment(parsed);

  return true;
}

export default insertFragment;
