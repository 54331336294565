import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled} from 'twin.macro';
import {Router} from '@reach/router';
import Helmet from 'react-helmet';
import * as Sentry from '@sentry/react';
import {ApolloProvider} from '@apollo/client';
import AppErrorBoundary from 'AppErrorBoundary';
import Heap from 'components/Heap';
import ResetPassword from 'containers/ResetPassword';
import ResetPasswordChooser from 'containers/ResetPasswordChooser';
import SimpleReactLightbox from 'simple-react-lightbox';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import QueryClientProviderWithErrorHandling from 'QueryClientProviderWithErrorHandling';
import RedirectWithWildcard from 'components/RedirectWithWildcard';
import {DndProvider} from 'react-dnd-multi-backend';
import {HTML5toTouch} from 'rdndmb-html5-to-touch'; // or any other pipeline
import Logout from 'containers/Logout';
import GlobalStyles from './GlobalStyles';
import UserApp from './containers/UserApp';
import Login from './containers/Login';
import Signup from './containers/Signup';
import getEnvironmentName from './utils/getEnvironmentName';
import apolloClient from './apolloClient';

const showReactQueryDevTools = false;

const environment = getEnvironmentName();

if (environment !== 'development') {
  Sentry.init({
    dsn: 'https://506fef0deee9432ab298d5dd6538da9d@o214292.ingest.sentry.io/5689798',
    integrations: [
      Sentry.replayIntegration(),
      Sentry.browserTracingIntegration(),
    ],
    environment,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,

    // Session Replay
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 1.0,
  });
}

function App () {
  return (<>
    <GlobalStyles />
    <Helmet titleTemplate="%s &ndash; AirManual">
      <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
    </Helmet>
    <Heap />
    <AppErrorBoundary>
      <DndProvider options={HTML5toTouch}>
        <SimpleReactLightbox>
          <QueryClientProviderWithErrorHandling>
            {showReactQueryDevTools && <ReactQueryDevtools initialIsOpen={false}/>}
            <ApolloProvider client={apolloClient}>
              <AppWrapper>
                <Router>
                  {/* Public user account pages */}
                  <Redirect path="/" to="/login" />
                  <Logout path="/logout" />
                  <Login path="/login" method="password" />
                  <Login path="/login-sso" method="sso" />
                  <Signup path="/signup" />
                  <ResetPassword path="/reset" />
                  <ResetPasswordChooser path="/reset/:resetKey" />

                  {/* Shortcuts */}
                  <Redirect path="/community" to="/help/Our-community-on-Slack--91UeH5JyrUF2Fm5FPLaMyHHb-EIa1mfLOEnhI" />
                  <Redirect path="/subprocessors" to="/help/Subprocessors-used-by-AirManual--cGK3a6RIVY4TJWxrB1Psq7ls-j8P9h8reTfsi" />
                  <Redirect path="/support" to="/help/How-to-contact-support--A82Gf8GoncdRuSY9HZ4nmfLQ-uOBCs9PTfBTA" />
                  <Redirect path="/vendor" to="/help/Information-for-vendor-registration-documents--eqwwGel5d4ELlZ3G9h2Kxbmw-fM4SJydPI9JF" />

                  {/* Redirects after renaming workspaces */}
                  <Redirect path="/air/folders/AirManual-Help-Center--F4acFlUKtVUGG8zNOQhmXeOF-e4SXmeVsTMB8" to="/help" />
                  <RedirectWithWildcard path="/air/*" to="/help/*" />

                  {/* Private app */}
                  <UserApp path="/*" />
                </Router>
              </AppWrapper>
            </ApolloProvider>
          </QueryClientProviderWithErrorHandling>
        </SimpleReactLightbox>
      </DndProvider>
    </AppErrorBoundary>
  </>);
}

const AppWrapper = styled.div((props) => [
  tw`
    text-gray-700
  `,
]);

Redirect.propTypes = {
  to: PropTypes.string.isRequired,
};

function Redirect (props) {
  window.location.href = props.to;
  return null;
}

NextRedirect.propTypes = {
  path: PropTypes.string.isRequired,
};

function NextRedirect (props) {
  const hostname = window.location.host.indexOf('localhost') !== -1 ? 'http://a.m.localhost:3005' : `https://${window.location.host}`;
  const hostnameWithoutApp = hostname.replace(
    'app.',
    hostname.indexOf('localhost') > -1 || hostname.indexOf('test.') > -1 ? '' : 'www.'
  );

  window.location.href = `${hostnameWithoutApp}${props.path}`;
  return null;
}

export default App;
