import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import DropdownButton from 'components/DropdownButton';
import {PERMISSIONS, ITEM_TYPE} from 'am-constants';
import {getPermissionsForRoles} from 'am-utils';

PublishedPageMenu.propTypes = {
  item: PropTypes.shape({
    itemTypeId: PropTypes.oneOf([
      ITEM_TYPE.DOCUMENT,
      ITEM_TYPE.CHECKLIST,
      ITEM_TYPE.FORM,
    ]),
    key: PropTypes.string.isRequired,
    loggedInUserRoleIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    name: PropTypes.string.isRequired,
    ref: PropTypes.string.isRequired,
    urlName: PropTypes.string.isRequired,
  }),
  setIsDeleteItemModalVisible: PropTypes.func.isRequired,
  setIsMoveFolderModalVisible: PropTypes.func.isRequired,
  setIsShareItemModalVisible: PropTypes.func.isRequired,
  setFocusedItem: PropTypes.func.isRequired,
  workspaceUrlName: PropTypes.string.isRequired,
};

function PublishedPageMenu (props) {
  const {
    item,
    setFocusedItem,
    setIsDeleteItemModalVisible,
    setIsMoveFolderModalVisible,
    setIsShareItemModalVisible,
    workspaceUrlName,
  } = props;

  useEffect(() => {
    setFocusedItem(item);
  });

  const {itemTypeId, loggedInUserRoleIds} = item;
  const loggedInUserItemPermissions = getPermissionsForRoles(loggedInUserRoleIds);

  let useMenuItems = [];
  if (itemTypeId === ITEM_TYPE.CHECKLIST) {
    useMenuItems = [
      {
        disabled: !loggedInUserItemPermissions.includes(PERMISSIONS.USE_FORM_OR_CHECKLIST),
        label: 'Use checklist',
        to: `/${workspaceUrlName}/${item.urlName}/use`,
      },
    ];
  }
  else if (itemTypeId === ITEM_TYPE.FORM) {
    useMenuItems = [
      {
        disabled: !loggedInUserItemPermissions.includes(PERMISSIONS.USE_FORM_OR_CHECKLIST),
        label: 'Use form',
        to: `/${workspaceUrlName}/${item.urlName}/use`,
      },
    ];
  }

  const viewSubmissionsItems = [ITEM_TYPE.CHECKLIST, ITEM_TYPE.FORM].includes(item.itemTypeId) ? [
    {
      disabled: ![PERMISSIONS.VIEW_RESPONSES, PERMISSIONS.VIEW_OWN_RESPONSES]
        .some((p) => loggedInUserItemPermissions.includes(p)),
      label: 'View data',
      to: `/${workspaceUrlName}/${item.urlName}/data`,
    },
  ] : [];

  const menu = [[
    ...useMenuItems,
    {
      onClick: () => setIsShareItemModalVisible(true),
      label: 'Share',
    },
    ...viewSubmissionsItems,
    {
      disabled: !loggedInUserItemPermissions.includes(PERMISSIONS.EDIT_PAGE),
      label: 'Edit',
      to: `/${workspaceUrlName}/${item.urlName}/edit`,
    },
    {
      disabled: !loggedInUserItemPermissions.includes(PERMISSIONS.MOVE_ITEM),
      onClick: () => setIsMoveFolderModalVisible(true),
      label: 'Move',
    },
    {
      disabled: !loggedInUserItemPermissions.includes(PERMISSIONS.DELETE_ITEM),
      onClick: () => setIsDeleteItemModalVisible(true),
      label: 'Delete',
    },
  ]];

  return (
    <>
      <DropdownButton menu={menu} sm data-test-id="options-menu">Options</DropdownButton>
    </>);
}

export default PublishedPageMenu;
