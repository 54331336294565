const makeHeading3 = ({text} = {}) => ({
  type: 'heading3',
  children: [
    {
      text: text || '',
    },
  ],
});

export default makeHeading3;
