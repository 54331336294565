import tw, {styled} from 'twin.macro';

const OL = styled.ol((props) => [
  tw`
    list-decimal
    ml-6
    mb-5
  `,
  props.indent % 3 === 1 &&
    `
    list-style-type: lower-alpha;
  `,
  props.indent % 3 === 2 &&
    `
    list-style-type: lower-roman;
  `,
]);

export default OL;
