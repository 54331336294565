import React from 'react';
import {Redirect} from '@reach/router';
import SignupPage from './components/SignupPage';
import useSignupMutation from './hooks/useSignupMutation';

function Signup () {
  const [signup, {
    data,
    error,
    loading,
  }] = useSignupMutation();

  const onSubmit = async ({
    name, email, password, areTermsOfServiceAccepted, wantsToHearAboutUs,
  }) => signup({
    variables: {
      name, email, password, areTermsOfServiceAccepted, wantsToHearAboutUs,
    },
  });

  if (data && !loading && !error) {
    return <Redirect noThrow={true} to='/welcome' />;
  }

  return <SignupPage
    signup={onSubmit}
    error={error}
    loading={loading}
  />;
}

export default Signup;
