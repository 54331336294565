import makeParagraph from '../factory/makeParagraph';
import convertSelectedNodes from '../utils/convertSelectedNodes';

const elementTypesToConvertToParagraph = [
  'checkbox',
  'list-item',
  'ordered-list-item',
];

function onEnterInElementWithNoText (editor, event, selectionMeta) {
  const {
    selectionStart,
    selectionStartElement,
    selectionStartTextNode,
    isSelectionAtStartOfElement,
  } = selectionMeta;

  if (
    event.key !== 'Enter' ||
    !selectionStart ||
    !elementTypesToConvertToParagraph.includes(selectionStartElement.type) ||
    selectionStartTextNode.text.trim().length !== 0 ||
    !isSelectionAtStartOfElement
  ) {
    return false;
  }

  event.preventDefault();

  convertSelectedNodes(editor, {makeNode: makeParagraph});

  return true;
}

export default onEnterInElementWithNoText;
