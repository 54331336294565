import React from 'react';
import PropTypes from 'prop-types';
import {tailwind} from 'am-config';
import {Tooltip as AntdTooltip} from 'antd';

const {theme: {colors}} = tailwind;
Tooltip.propTypes = {
  arrow: PropTypes.bool,
  color: PropTypes.string,
  overlayInnerStyle: PropTypes.object,
  overlayStyle: PropTypes.object,
  sm: PropTypes.bool,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
};

function Tooltip (props) {
  const {
    color, arrow, overlayInnerStyle, overlayStyle, sm, title, ...rest
  } = props;

  const updatedOverlayStyle = arrow
    ? {overlayStyle}
    : {paddingBottom: 0, ...overlayStyle};

  const updatedOverlayInnerStyle = sm
    ? {
      padding: '4px 8px',
      minHeight: 28,
      fontSize: 13,
      ...overlayInnerStyle,
    } : {overlayInnerStyle};

  return (
    <AntdTooltip
      color={color || colors.gray['900']}
      arrow={arrow}
      {...rest}
      overlayInnerStyle={updatedOverlayInnerStyle}
      overlayStyle={updatedOverlayStyle}
      title={title}
    />
  );
}

export default Tooltip;
