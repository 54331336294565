import {gql} from '@apollo/client';
import useMutation from 'hooks/useMutation';

const UPDATE_TEAM_MEMBER = gql`
  mutation UpdateTeamMember($workspaceId: Int!, $email: String!, $role: Int!) {
    teamMemberUpdate(workspaceId: $workspaceId, email: $email, role: $role) {
      personId
    }
  }
`;

function useUpdateTeamMemberMutation (props) {
  const [updateTeamMember, {
    loading, error, data, reset,
  }] = useMutation(UPDATE_TEAM_MEMBER, {
    fetchPolicy: 'no-cache',
    refetchQueries: [
      'GetTeamMembersWithRoles',
    ],
  });

  return [updateTeamMember, {
    data,
    loading,
    error,
    reset,
  }];
}

export default useUpdateTeamMemberMutation;
