import React from 'react';
import PropTypes from 'prop-types';
import Card from 'components/Card';
import {AiOutlineFileText} from 'react-icons/ai';
import A from 'components/A';
import Em from 'components/Em';
import {FINAL_PAGE_TYPE} from '../wizardConstants';

UseATemplateCard.propTypes = {
  isGetTemplateError: PropTypes.bool.isRequired,
  setFinalPageType: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  templates: PropTypes.array,
};

export default function UseATemplateCard ({
  isGetTemplateError,
  setFinalPageType,
  setStep,
  templates,
}) {
  const loadingTemplates = !templates && !isGetTemplateError;
  const isClickDisabled = loadingTemplates || templates?.length === 0;
  const noTemplatesFound = templates && templates.length === 0;

  return <Card
    icon={<AiOutlineFileText size={40} />}
    heading="Use a template"
    onClick={() => {
      if (isClickDisabled) {
        return;
      }
      setFinalPageType(FINAL_PAGE_TYPE.TEMPLATES);
      setStep((prev) => prev + 1);
    }}
    disabled={isClickDisabled}
  >
    {loadingTemplates && 'Searching for templates...'}
    {(noTemplatesFound || isGetTemplateError) && (<>
      We didn't find a relevant template.{' '}
      <A href="/example" target="_blank">
        Browse templates
      </A>
    </>)}
    {templates?.length > 0 && (<>
      Use our <Em tw="font-semibold">{templates[0].name}</Em> template or browse
      alternatives.
    </>)}
  </Card>;
}
