import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {DRAGGABLE_TYPES} from 'am-constants';
import {useDrop} from 'react-dnd';

DroppableForGridRow.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  index: PropTypes.number.isRequired,
  item: PropTypes.shape({}),
  onDragStart: PropTypes.func,
  onDragHover: PropTypes.func,
  onDrop: PropTypes.func,
};

function DroppableForGridRow (props) {
  const {
    children,
    index,
    item,
    onDragStart,
    onDragHover,
    onDrop,
  } = props;

  const ref = useRef();
  // eslint-disable-next-line no-unused-vars
  const [{isOver, handlerId}, drop] = useDrop(() => ({
    accept: DRAGGABLE_TYPES.GRID_ROW,
    drop: (draggedItem, monitor) => {
      const dragDirection = getDragDirection(ref, monitor);
      onDrop({
        draggedItem,
        droppedItem: item,
        droppedIndex: index,
        dragDirection,
      });
    },
    hover: (draggedItem, monitor) => {
      const dragDirection = getDragDirection(ref, monitor);

      onDragHover({
        draggedItem,
        hoverItem: item,
        hoverIndex: index,
        dragDirection,
      });
    },
    canDrop: (draggedItem, monitor) => true,
    collect: (monitor) => ({
      isOver: monitor.isOver(),

      handlerId: monitor.getHandlerId(),
    }),
    // More options: https://react-dnd.github.io/react-dnd/docs/api/use-drop
  }), [index, item, onDragStart, onDragHover, onDrop]);

  const dropRef = drop(ref);

  return <div ref={dropRef}>{children}</div>;
}

function getDragDirection (ref, monitor) {
  const {y: differenceFromOriginalY} = monitor.getDifferenceFromInitialOffset();

  return differenceFromOriginalY < 0 ? 'above' : 'below';
}

export default DroppableForGridRow;
