import PropTypes from 'prop-types';
import {useQueries} from '@tanstack/react-query';
import {gql} from 'graphql-request';
import getItemUrlName from 'am-utils/utils/getItemUrlName';
import graphQLClient from 'utils/graphQLClient';
import {ITEM_DETAILED_FIELDS} from 'fragments/ItemDetailed';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';

const query = gql`
  query GetItemsForFolder($folderId: Int, $doNotThrow: Boolean) {
    itemsForFolderId(folderId: $folderId, doNotThrow: $doNotThrow) {
      ${ITEM_DETAILED_FIELDS}
    }
  }
`;

useItemsQueries.propTypes = {
  itemId: PropTypes.number,
};

function useItemsQueries (variablesForQueries) {
  const {urlName: workspaceUrlName} = useWorkspace();

  const queries = variablesForQueries.map(({folderId}) => {
    const queryKey = [workspaceUrlName, 'items', {folderId}, 'detailed'];

    const variables = {
      folderId,
    };

    // We support doNotThrow so that the UI can request all of the expanded
    // folders in the URL, which might include folders that have been
    // deleted. This flag should only be set to true for expanded folders.
    const queryFn = async ({doNotThrow}) => {
      const {itemsForFolderId} = await graphQLClient.request(query, {
        ...variables,
        doNotThrow,
      });

      return itemsForFolderId;
    };

    const options = {
      select: (items) =>
        items.map((item) => ({
          ...item,
          // Save requesting from the server
          // eslint-disable-next-line no-param-reassign
          urlName: getItemUrlName(item),
        })),
      staleTime: 3 * 60 * 1000, // 3
    };

    return {
      queryKey,
      queryFn,
      ...options,
    };
  });

  return useQueries({queries});
}

export default useItemsQueries;
