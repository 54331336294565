import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import Loader from 'components/Loader';
import {PERMISSIONS, STATUS_CODE} from 'am-constants';
import getApolloErrorStatusCode from 'utils/getApolloErrorStatusCode';
import {useItem} from 'providers/ItemProvider';
import getNameRefAndKeyParts from 'utils/getNameRefAndKeyParts';
import {useTopbar} from 'providers/TopbarProvider';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';
import useResponseWithDocumentSnapshotAndResponseSubmissionQuery from './hooks/useResponseWithDocumentSnapshotAndResponseSubmissionQuery';
import useResponseReactivateMutation from './hooks/useResponseReactivateMutation';
import useResponseSubmitMutation from './hooks/useResponseSubmitMutation';
import DocumentForm from './components/DocumentForm';
import DocumentFormProvider from './providers/DocumentFormProvider';
import getInitialDocumentFormState from './utils/getInitialDocumentFormState';
import DocumentVersionErrorModal from './components/DocumentVersionErrorModal';

DocumentFormContainer.propTypes = {
  'documentTitle--:responseRef-:responseKey': PropTypes.string,
  printMode: PropTypes.bool,
};

DocumentFormContainer.defaultProps = {
  printMode: false,
};

function DocumentFormContainer (props) {
  const {
    'documentTitle--:responseRef-:responseKey': titleResponseRefResponseKey,
    printMode,
  } = props;

  const [submitted, setSubmitted] = useState(false);
  const [responseLoaded, setResponseLoaded] = useState(false);
  const [updateCausedDocumentVersionError, setUpdateCausedDocumentVersionError] = useState(false);

  const {
    ref: responseRef,
    key: responseKey,
  } = useMemo(() => getNameRefAndKeyParts(titleResponseRefResponseKey), [titleResponseRefResponseKey]);

  const {setPrintMode} = useTopbar();
  useEffect(() => {
    setPrintMode(printMode);
  });

  const {setItem} = useItem();

  const {
    response,
    error,
  } = useResponseWithDocumentSnapshotAndResponseSubmissionQuery({
    responseRef,
    responseKey,
  });

  useEffect(() => {
    if (response) {
      setItem(response?.item);
      setResponseLoaded(true);
      setSubmitted(!!response.responseSubmission);
    }
  }, [response]);

  const {workspacePermissions} = useWorkspace();
  const isWorkspaceMember = workspacePermissions.includes(PERMISSIONS.VIEW_WORKSPACE);
  const {hasPublicAncestor, public: isPublic} = response?.item || {hasPublicAncestor: false, public: false};
  const isStandalonePublicPage = isPublic && !hasPublicAncestor;
  const {setHideSearch, setPrintModeSupport} = useTopbar();

  useEffect(() => {
    if (response?.item) {
      setHideSearch(!isWorkspaceMember && isStandalonePublicPage);
      setPrintModeSupport(true);
    }
  }, [response?.item]);

  const [onResponseSubmit,
    {
      submitting,
      submitError,
      responseSubmission,
    },
  ] = useResponseSubmitMutation({
    responseRef,
    responseKey,
    onSubmitted: () => setSubmitted(true),
  });
  const isDocumentVersionError = useMemo(() => {
    const errStatusCode = getApolloErrorStatusCode(submitError);
    return errStatusCode === STATUS_CODE.COULD_NOT_PROCESS;
  }, [submitError]);

  const [onResponseReactivate,
    {
      reactivating,
    },
  ] = useResponseReactivateMutation({
    responseRef,
    responseKey,
    onReactivated: () => setSubmitted(false),
  });

  if (!responseLoaded) {
    return <Loader />;
  }

  if (error) {
    return <p>Could not load page</p>;
  }

  const {
    documentSnapshot: {content, version},
    data: responseData,
  } = response;

  const submittedAt = submitted ? getSubmittedAt({response, responseSubmission}) : undefined;
  const submittedByUser = !!responseSubmission;

  const onSubmit = () => {
    onResponseSubmit({
      variables: {
        documentVersion: version,
      },
    })
    // TODO: Not sure why we're catching the error and doing nothing here? Needs
    // a comment to explain.
      .catch((err) => {});
  };

  return <>
    <DocumentFormProvider
      documentVersion={version}
      initialState={getInitialDocumentFormState({
        content,
        responseData,
      })}
      onDocumentVersionError={() => setUpdateCausedDocumentVersionError(true)}
      reactivating={reactivating}
      responseRef={responseRef}
      responseKey={responseKey}
      submitted={submitted}
      submitting={submitting}
      submitError={!isDocumentVersionError && submitError ? submitError : undefined}>
      <DocumentVersionErrorModal
        visible={isDocumentVersionError || updateCausedDocumentVersionError} />
      <DocumentForm
        content={content}
        onReactivate={onResponseReactivate}
        onSubmit={onSubmit}
        printMode={printMode}
        reactivating={reactivating}
        responseData={responseData}
        response={response}
        responseKey={responseKey}
        responseRef={responseRef}
        submitError={submitError}
        submitted={submitted}
        submittedAt={submittedAt}
        submittedByUser={submittedByUser}
        submitting={submitting}
      />
    </DocumentFormProvider>
  </>;
}

function getSubmittedAt ({response, responseSubmission}) {
  if (responseSubmission) {
    return parseInt(responseSubmission.createdAt, 10);
  }

  if (response && response.responseSubmission) {
    return parseInt(response.responseSubmission.createdAt, 10);
  }

  return undefined;
}

export default DocumentFormContainer;
