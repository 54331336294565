import {Editor, Path, Transforms} from 'slate';

function onEnterInTextFieldLabel (editor, event, selectionMeta) {
  const {
    selectionStart,
    selectionStartElement,
    selectionStartElementPath,
  } = selectionMeta;

  if (
    event.key !== 'Enter' ||
    !selectionStart ||
    selectionStartElement.type !== 'label'
  ) {
    return false;
  }

  const [nextElement] = Editor.next(editor, {
    at: selectionStartElementPath,
  });
  if (nextElement.type !== 'text-field') {
    return false;
  }

  event.preventDefault();

  const [parentElement, parentElementPath] = Editor.parent(editor, selectionStartElementPath);
  if (parentElement.type !== 'form-group') {
    throw new Error('Expected the parent element of a label element to have type form-group');
  }

  Editor.withoutNormalizing(editor, () => {
    Transforms.splitNodes(
      editor,
      {
        always: true,
      }
    );

    Transforms.moveNodes(
      editor,
      {
        to: Path.next(parentElementPath),
      }
    );

    Transforms.setNodes(
      editor,
      {
        type: 'paragraph',
      }
    );
  });

  return true;
}

export default onEnterInTextFieldLabel;
