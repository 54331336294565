import React, {useState} from 'react';
import PropTypes from 'prop-types';
import tw, {styled, css} from 'twin.macro';
import {GoCheck} from 'react-icons/go';

Checkbox.propTypes = {
  attributes: PropTypes.shape({}),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  checked: PropTypes.bool,
  lightBorder: PropTypes.bool,
  noMargin: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  sm: PropTypes.bool,
};

Checkbox.defaultProps = {
  checked: false,
  lightBorder: false,
  noMargin: false,
  readOnly: false,
  sm: false,
};

function Checkbox (props) {
  const {
    children,
    lightBorder,
    noMargin,
    onChange,
    readOnly,
    sm,
  } = props;

  const [checked, setChecked] = useState(props.checked);

  const toggle = () => {
    if (readOnly) {
      return;
    }
    onChange(!checked);
    setChecked(!checked);
  };

  return <Wrapper readOnly={readOnly} onClick={toggle} sm={sm} noMargin={noMargin} data-test="checkbox">
    <Input checked={checked} sm={sm} lightBorder={lightBorder} noMargin={noMargin}>
      {checked && <GoCheck size={14} tw="mt--px ml--px"/>}
    </Input>
    {!!children && (
      <Text>
        {children}
      </Text>
    )}
  </Wrapper>;
}

const Wrapper = styled.div((props) => [
  tw`
    mb-2.5
    flex
    items-start
    text-gray-700
  `,
  !props.readOnly && tw`
    cursor-pointer
  `,
  props.sm && tw`
    text-sm
  `,
  props.noMargin && tw`
    m-0!
  `,
]);

const Input = styled.div((props) => [
  tw`
    cursor-default
    ml-2
    mr-2
    text-blue-400
    border
    rounded-sm
    w-4
    h-4
    bg-white
    border-2
    border-gray-300
    select-none
    mt-1
  `,
  css`
    min-width: 16px;
  `,
  props.checked && tw`
    text-white
    border-blue-400
    bg-blue-400
  `,
  props.sm && tw`
    mt-0.5
  `,
  props.lightBorder && tw`
    border
    p-px
  `,
  props.noMargin && tw`
    m-0!
  `,
]);

const Text = styled.div((props) => [
  props.sm && tw`
    mt--px
  `,
]);

export default Checkbox;
