import React from 'react';
import PropTypes from 'prop-types';
import {navigate} from '@reach/router';
import useURLSearchParam from 'hooks/useURLSearchParam';
import LoginPage from './components/LoginPage';
import useLoginMutation from './hooks/useLoginMutation';
import useSSOProvidersQuery from './hooks/useSSOProvidersQuery';
import useLoginWithSSOMutation from './hooks/useLoginWithSSOMutation';
import useUrlParamError from './hooks/useUrlParamError';

Login.propTypes = {
  method: PropTypes.oneOf(['password', 'sso']),
};

Login.defaultProps = {
  method: 'password',
};

function Login ({method}) {
  let redirectUri = (useURLSearchParam('redirect') || '/workspaces').trim();
  const urlParamError = useUrlParamError();

  // Avoid a malicious user sending a user to another site.
  // Examples:
  // https://app.airmanual.co/login?redirect=//google.com
  // https://app.airmanual.co/login?redirect=@google.com
  // https://app.airmanual.co/login?redirect=http://google.com
  // https://app.airmanual.co/login?redirect=https://google.com
  // https://app.airmanual.co/login?redirect=javascript:alert(1)

  if (redirectUri !== '/workspaces') {
    const a = document.createElement('a');
    a.href = redirectUri;

    if (redirectUri.indexOf('@') === 0 ||
        a.hostname.toLowerCase() !== window.location.hostname.trim().toLowerCase()) {
      redirectUri = '/workspaces';
    }
  }

  const [login, {
    data: loginData,
    error,
    loading: loginLoading,
  }] = useLoginMutation();

  const [lookupSSOProviders, {
    error: ssoProvidersQueryError,
    loading: ssoProvidersQueryLoading,
  }] = useSSOProvidersQuery();

  const doPasswordLogin = async (email, password) =>
    login({variables: {email, password}});

  const doSSOLogin = (email) => lookupSSOProviders({
    variables: {email},
    onCompleted: (data) => {
      if (!data) {
        return false;
      }

      const {ssoProviders: providers} = data;

      if (!providers || providers.length === 0) {
        return false;
      }

      // TODO: let the user choose which provider to use
      const {id: ssoProviderId} = providers[0];

      useLoginWithSSOMutation({
        email,
        redirectUri,
        ssoProviderId,
      });

      return true;
    },
  });

  const onSubmit = async (email, password) => {
    if (password) {
      return doPasswordLogin(email, password);
    }

    return doSSOLogin(email);
  };

  if (loginData && !loginLoading) {
    // Using navigate rather than <Redirect> as this prevents the ?redirect
    // query string from being included in the new URI.
    navigate(redirectUri);
    return <></>;
  }

  return <LoginPage
    login={onSubmit}
    error={error || urlParamError || ssoProvidersQueryError}
    loading={loginLoading || ssoProvidersQueryLoading || false}
    method={method}
  />;
}

export default Login;
