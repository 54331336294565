import getRootAPIUri from '../../../utils/getRootAPIUri';

/**
 * We have to use an HTML form here so that when passport tries to redirect
 * the user to the identity provider's login page the redirect will
 * actually work in the browser
 *
 * If it was a fetch then Javascript would try to follow the redirect with AJAX
 */
function useLoginWithSSOMutation ({email, redirectUri, ssoProviderId}) {
  // make an html form to post to so redirects work
  const form = document.createElement('form');
  form.method = 'POST';
  form.action = `${getRootAPIUri()}/login-with-sso`;
  form.target = '_self';
  form.style.display = 'none';
  const input = document.createElement('input');
  input.type = 'hidden';
  input.name = 'providerId';
  input.value = ssoProviderId;
  form.appendChild(input);
  const input2 = document.createElement('input');
  input2.type = 'hidden';
  input2.name = 'redirectUri';
  input2.value = redirectUri;
  form.appendChild(input2);
  const input3 = document.createElement('input');
  input3.type = 'hidden';
  input3.name = 'email';
  input3.value = email;
  form.appendChild(input3);
  document.body.appendChild(form);
  form.submit();
}

export default useLoginWithSSOMutation;
