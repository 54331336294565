const {Transforms, Editor} = require('slate');

function selectNextNode (editor) {
  requestAnimationFrame(() =>
    Transforms.select(
      editor,
      Editor.after(editor, editor.selection)
    ));
}

module.exports = selectNextNode;
