import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled} from 'twin.macro';
import Loader from 'components/Loader';
import orderBy from 'lodash.orderby';
import Button from 'components/Button';
import {AiOutlinePlus} from 'react-icons/ai';
import A from 'components/A';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';
import SidePanelAutomation from './SidePanelAutomation';

SidePanelAutomations.propTypes = {
  automations: PropTypes.array,
  hasTeamSubscription: PropTypes.bool.isRequired,
  hasItemAutomationPermissions: PropTypes.bool.isRequired,
  noAutomationsLabel: PropTypes.string.isRequired,
  openModal: PropTypes.func.isRequired,
  setAutomationEditorData: PropTypes.func.isRequired,
  setIsAutomationEditorModalVisible: PropTypes.func.isRequired,
};

SidePanelAutomations.defaultProps = {
};

function SidePanelAutomations (props) {
  const {
    automations,
    hasTeamSubscription,
    hasItemAutomationPermissions,
    noAutomationsLabel,
    openModal,
    setAutomationEditorData,
    setIsAutomationEditorModalVisible,
  } = props;
  const {urlName: workspaceUrlName} = useWorkspace();

  if (!hasTeamSubscription) {
    return (<GrayBg>
      Your current plan does not support automations.&nbsp;
      <A href={`/${workspaceUrlName}/billing`}>Upgrade now</A>
    </GrayBg>);
  }

  if (!hasItemAutomationPermissions) {
    return (<GrayBg>
      You do not have access to manage automations for this checklist.
    </GrayBg>);
  }

  if (!automations) {
    return <Loader />;
  }

  if (automations.length === 0) {
    return (
      <>
        <GrayBg>{noAutomationsLabel}</GrayBg>
        <Button sm link tw="pl-3" onClick={openModal}>
          <AiOutlinePlus size={12} tw="mr--0.5" /> Create automation
        </Button>
      </>
    );
  }

  return (
    <>
      <AutomationList>
        {orderBy(automations, ['automationNum'], ['desc']).map((automation) => (
          <SidePanelAutomation
            key={automation.id}
            automation={automation}
            setAutomationEditorData={setAutomationEditorData}
            setIsAutomationEditorModalVisible={setIsAutomationEditorModalVisible}
          />
        ))}
      </AutomationList>
      <Button sm link tw="pl-3" onClick={openModal}>
        <AiOutlinePlus size={12} tw="mr--0.5" /> Create automation
      </Button>
    </>
  );
}

const AutomationList = styled.div((props) => [
  tw`
    flex
    flex-col
    gap-4
  `,
]);

const GrayBg = styled.div((props) => [
  tw`
    bg-gray-50
    p-3.5
    text-gray-500
    text-xs
  `,
]);

export default SidePanelAutomations;
