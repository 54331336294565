import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {AiOutlineSearch} from 'react-icons/ai';
import tw, {styled, css} from 'twin.macro';
import {useLocation} from '@reach/router';
import Input from './Input';
import List from './List';
import Button from './Button';
import P from './P';

SearchInput.propTypes = {
  isError: PropTypes.bool,
  isShowMoreVisible: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
  onSearch: PropTypes.func.isRequired,
  retrySearch: PropTypes.func.isRequired,
  showMoreResults: PropTypes.func.isRequired,
};

SearchInput.defaultProps = {
  isError: false,
  items: undefined,
  loading: false,
};

function SearchInput (props) {
  const {
    isError,
    isShowMoreVisible,
    items,
    loading,
    onSearch,
    retrySearch,
    showMoreResults,
    ...rest
  } = props;

  const location = useLocation();
  const searchInputRef = React.useRef(null);
  const [isFocused, setIsFocused] = React.useState(false);
  const onFocus = () => setIsFocused(true);
  const onBlur = (event) => {
    // don't lose focus if the SearchResults are focused
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setIsFocused(false);
    }
  };

  useEffect(() => {
    // Close the search results when the page changes
    setIsFocused(false);
  }, [location.href]);

  const removeFocusOnEmptySearchBar = (searchString) => {
    if (searchString === '') {
      return setIsFocused(false);
    }

    return setIsFocused(true);
  };

  return (
    <SearchWrapper onFocus={onFocus} onBlur={onBlur} tabIndex={0}>
      <Input
        blue
        data-test-id={'search-input'}
        icon={AiOutlineSearch}
        iconSize={22}
        innerRef={searchInputRef}
        onChange={(event) => {
          removeFocusOnEmptySearchBar(event.target.value);
          onSearch(event.target.value);
        }}
        {...rest}
      />
      {!loading && !isError && isFocused && items && items.length > 0 && (
        <SearchResults>
          <List items={items} semiCompact />
          {isShowMoreVisible && (
            <Button
              sm
              link
              type={'button'}
              tw="m-auto"
              onClick={() => {
                showMoreResults();
                searchInputRef.current.focus();
              }}
            >
              Show more
            </Button>
          )}
        </SearchResults>
      )}
      {isFocused && items && items.length === 0 && (
        <SearchResults>
          <SearchResultsMessage>
            <P bottom>There's nothing here 🤭</P>
          </SearchResultsMessage>
        </SearchResults>
      )}
      {isFocused && loading && (
        <SearchResults isLoading>
          <SearchResultsMessage>
            <P bottom>...</P>
          </SearchResultsMessage>
        </SearchResults>
      )}
      {isFocused && isError && (
        <SearchResults>
          <SearchResultsMessage>
            <P tw="mb-4">Something went wrong 😑</P>
            <Button
              sm
              onClick={(event) => {
                retrySearch();
              }}
            >
              Retry search
            </Button>
          </SearchResultsMessage>
        </SearchResults>
      )}
    </SearchWrapper>
  );
}

const SearchWrapper = styled.div((props) => [
  tw`
    relative
  `,
]);
const SearchResults = styled.div.attrs((props) => ({
  'data-test-id': !props.isLoading ? 'search-results' : undefined,
}))((props) => [
  tw`
    bg-white
    border
    border-blue-50
    min-w-full
    absolute
    text-sm
    z-50
    py-1
    max-h-96
    shadow-lg
    overflow-y-scroll
  `,
  css`
    margin-top: 3px;
  `,
]);

const SearchResultsMessage = styled.div((props) => [
  tw`
    text-center
    text-gray-400
    p-6
  `,
]);

export default SearchInput;
