import React, {useCallback, useContext} from 'react';
import PropTypes from 'prop-types';
import {useDocument} from 'containers/Document/providers/DocumentProvider';
import {useSlateStatic} from 'slate-react';
import useDocumentFormInput from 'containers/DocumentForm/hooks/useDocumentFormInput';
import {ProcessContext} from '../Process';
import ProcessStep from './ProcessStep';
import openNextNodeWithSlate from '../../utils/openNextNodeWithSlate';
import openNodeWithSlate from '../../utils/openNodeWithSlate';
import DraggableProcessStep from './DraggableProcessStep';
import {DraggableElementContext} from '../DraggableElement';

ProcessStepContainer.propTypes = {
  element: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

function ProcessStepContainer (props) {
  const {element} = props;
  const {id} = element;

  const documentState = useDocument();
  const {mode} = documentState;

  const processState = useContext(ProcessContext);

  // When editing, the process step state is managed within the SlateEditor.
  if (mode === 'edit') {
    return renderProcessStepForEditor({
      id, documentState, processState, props,
    });
  }

  // When viewing and using, the state is managed by the DocumentProvider.
  // This means:
  // - The state is not shared by different users viewing the document.
  // - The state is specific to a response (loaded from the API) when responding
  //   to a form.
  return renderProcessStepForFormOrViewer({
    id, documentState, processState, props,
  });
}

function renderProcessStepForFormOrViewer ({
  id, documentState, processState, props,
}) {
  const [onChange] = useDocumentFormInput({id});

  const {
    closeProcessStep,
    markProcessStepAsDone,
    markProcessStepAsNotDone,
    openNextProcessStep,
    openProcessStep,
    processSteps,
    selectedElements,
  } = documentState;

  const {
    done,
    open,
  } = processSteps[id];

  const {
    processSelected,
  } = processState;

  const openNext = () => openNextProcessStep(id);
  const setDone = (isDone) => {
    if (isDone) {
      markProcessStepAsDone(id);
    }
    else {
      markProcessStepAsNotDone(id);
    }
    onChange(isDone);
  };
  const setOpen = () => (open ? closeProcessStep(id) : openProcessStep(id));

  const selected = selectedElements.ids.includes(id);
  const firstSelected = selectedElements.ids[0] === id;
  const lastSelected = selectedElements.ids.slice(-1)[0] === id;

  const {index} = useContext(DraggableElementContext);

  const context = {
    done,
    editable: false,
    open,
    order: index,
    setOpen,
    setDone,
    openNext,
  };

  return (
    <ProcessStepContext.Provider value={context}>
      <ProcessStep
        done={done}
        index={index}
        firstSelected={firstSelected}
        lastSelected={lastSelected}
        open={open}
        processSelected={processSelected}
        selected={selected}
        {...props} />
    </ProcessStepContext.Provider>
  );
}

function renderProcessStepForEditor ({
  id, props, documentState, processState,
}) {
  const editor = useSlateStatic();

  const {selectedElements} = documentState;
  const {processSelected} = processState;

  const {element} = props;
  const open = !!element.open;
  const done = !!element.done;

  const openNext = useCallback(() => openNextNodeWithSlate(editor, id), []);
  const setOpen = useCallback((isOpen) => openNodeWithSlate(editor, id, isOpen), []);

  const selected = selectedElements.ids.includes(id);
  const firstSelected = selectedElements.ids[0] === id;
  const lastSelected = selectedElements.ids.slice(-1)[0] === id;

  const {index} = useContext(DraggableElementContext);

  const context = {
    done,
    editable: true,
    open: !!open,
    order: index,
    setOpen,
    openNext,
    id,
  };

  return (
    <ProcessStepContext.Provider value={context}>
      <DraggableProcessStep
        done={done}
        index={index}
        firstSelected={firstSelected}
        lastSelected={lastSelected}
        open={open}
        processSelected={processSelected}
        selected={selected}
        {...props} />
    </ProcessStepContext.Provider>
  );
}

export const ProcessStepContext = React.createContext();

export default ProcessStepContainer;
