import React from 'react';
import PropTypes from 'prop-types';
import {SRLWrapper as OriginalSRLWrapper} from 'simple-react-lightbox';

SRLWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

SRLWrapper.defaultProps = {
};

// SRLWrapper in a separate component to avoid rerendering
function SRLWrapper (props) {
  const {
    children,
  } = props;

  return (
    <OriginalSRLWrapper
      options={{
        buttons: {
          showAutoplayButton: false,
          showDownloadButton: false,
          showThumbnailsButton: false,
          size: '40px',
        },
        caption: {
          showCaption: false,
        },
        thumbnails: {
          showThumbnails: false,
        },
      }}>
      {children}
    </OriginalSRLWrapper>
  );
}

export default React.memo(SRLWrapper);
