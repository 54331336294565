function checkboxesReducer (checkboxes, action) {
  const {
    id: checkboxId,
    type,
  } = action;

  switch (type) {
    case 'check':
      return {
        ...checkboxes,
        [checkboxId]: {
          ...checkboxes[checkboxId],
          checked: true,
        },
      };
    case 'uncheck':
      return {
        ...checkboxes,
        [checkboxId]: {
          ...checkboxes[checkboxId],
          checked: false,
        },
      };
    default:
      throw new Error();
  }
}

export default checkboxesReducer;
