import {Editor, Transforms} from 'slate';

function onEnterInChoice (editor, event, selectionMeta) {
  const {
    isSelectionAtEndOfElement,
    selectionStart,
    selectionStartElement,
    selectionStartElementPath,
  } = selectionMeta;

  if (
    event.key !== 'Enter' ||
    !selectionStart ||
    selectionStartElement.type !== 'choice' ||
    !isSelectionAtEndOfElement
  ) {
    return false;
  }

  const [nextElement] = Editor.next(editor, {at: selectionStartElementPath}) || [undefined];

  if (
    !nextElement || (
      nextElement.type === 'choice' &&
      nextElement.children[0].text.length > 0
    )
  ) {
    return false;
  }

  event.preventDefault();

  Transforms.move(editor, {
    distance: 1,
    unit: 'character',
  });

  return true;
}

export default onEnterInChoice;
