import tw, {styled} from 'twin.macro';

const Label = styled.label((props) => [
  tw`
    block
    text-sm
    mb-2
    text-gray-700
  `,
  props.sm && tw`
    text-xs
    mb-1.5
  `,
]);

Label.displayName = 'Label';

export default Label;
