import React from 'react';
import FooterToolbar from 'components/FooterToolbar';
import ContentContainer from 'components/ContentContainer';
import Button from 'components/Button';
// eslint-disable-next-line no-unused-vars
import tw, {styled, css} from 'twin.macro';
import {useItem} from 'providers/ItemProvider';
import {ITEM_TYPE} from 'am-constants';
import {Link} from '@reach/router';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';
// import {AiFillInfoCircle} from 'react-icons/ai';
// import Tooltip from 'components/Tooltip';
// import P from 'components/P';

function DocumentViewerPageActionBar (props) {
  const {item} = useItem();
  const {itemTypeId} = item;
  const {isExampleWorkspace} = useWorkspace();

  if (isExampleWorkspace) {
    return null;
  }

  const startLabel = (item.details && item.details['labels.buttons.start']) ||
    (itemTypeId === ITEM_TYPE.CHECKLIST ? 'Use this checklist' : 'Use this form');

  return (
    <FooterToolbar lg stickyOnMobile>
      <ContentContainer tw="flex items-center justify-center">
        <Link to="new">
          <Button
            data-test-id="document-action-bar_use-process-button"
            green
            xl
          >
            {startLabel}
          </Button>
        </Link>
      </ContentContainer>
    </FooterToolbar>
  );
}

export default DocumentViewerPageActionBar;
