import {useMutation, useQueryClient} from '@tanstack/react-query';
import {gql} from 'graphql-request';
import graphQLClient from 'utils/graphQLClient';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';

const query = gql`
  mutation ITEM_UPDATE(
    $ref: String!,
    $key: String!,
    $name: String,
    $emoji: String,
    $contentActionNeededId: Int,
    $contentDescription: JSON,
    $contentPriority: ContentPriority,
    $contentDeadline: String,
    $documenterIds: [Int],
    $reviewerIds: [Int],
    $taskOwnerIds: [Int],
    $nextDueAt: String,
    $nextDueInterval: Int,
    $nextDueFrequency: Frequency,
    $nextDueDaysOfWeek: [DayOfWeek],
    $nextDueInstanceOfDayInMonth: InstanceOfDayInMonth,
  ) {
    itemUpdate(
      itemRef: $ref
      itemKey: $key
      name: $name
      emoji: $emoji
      contentActionNeededId: $contentActionNeededId
      contentDescription: $contentDescription
      contentPriority: $contentPriority
      contentDeadline: $contentDeadline
      documenterIds: $documenterIds
      reviewerIds: $reviewerIds
      taskOwnerIds: $taskOwnerIds
      nextDueAt: $nextDueAt
      nextDueInterval: $nextDueInterval
      nextDueFrequency: $nextDueFrequency
      nextDueDaysOfWeek: $nextDueDaysOfWeek
      nextDueInstanceOfDayInMonth: $nextDueInstanceOfDayInMonth
    ) {
      ref
      key
    }
  }
`;

function useUpdateItemMutation (props) {
  const queryClient = useQueryClient();
  const {urlName: workspaceUrlName} = useWorkspace();

  const mutationFn = async (updatedItem) => {
    const variables = {
      ref: Object.prototype.hasOwnProperty.call(updatedItem, 'ref') ? updatedItem.ref : props.ref,
      key: Object.prototype.hasOwnProperty.call(updatedItem, 'key') ? updatedItem.key : props.key,
      name: updatedItem.name,
      emoji: updatedItem.emoji,
      contentActionNeededId: updatedItem.contentActionNeededId,
      contentDescription: updatedItem.contentDescription,
      contentPriority: updatedItem.contentPriority,
      contentDeadline: updatedItem.contentDeadline,
      documenterIds: updatedItem.documenters ? updatedItem.documenters.map(({id}) => id) : undefined,
      reviewerIds: updatedItem.reviewers ? updatedItem.reviewers.map(({id}) => id) : undefined,
      taskOwnerIds: updatedItem.taskOwners ? updatedItem.taskOwners.map(({id}) => id) : undefined,
      nextDueAt: updatedItem.nextDueAt,
      nextDueInterval: updatedItem.nextDueInterval,
      nextDueFrequency: updatedItem.nextDueFrequency,
      nextDueDaysOfWeek: updatedItem.nextDueDaysOfWeek,
      nextDueInstanceOfDayInMonth: updatedItem.nextDueInstanceOfDayInMonth,
    };

    await graphQLClient.request(query, variables);
  };

  // Options set up to support optimistic updates.
  const options = {
    onMutate: async (updatedItem) => {
      const {ref, key} = updatedItem;
      const queryKey = [workspaceUrlName, 'items', {folderId: updatedItem.parentFolderId}, 'detailed'];
      const sidePanelQueryKey = [workspaceUrlName, 'items', {ref, key}, 'side-panel'];

      // Prevent any refetches from overwriting our optimistic update
      await queryClient.cancelQueries(queryKey);
      await queryClient.cancelQueries(sidePanelQueryKey);

      // Optimistic update
      const previousItems = queryClient.getQueryData(queryKey) || [];
      queryClient.setQueryData(queryKey, previousItems.map((item) => (item.ref === updatedItem.ref ? {
        ...item,
        ...updatedItem,
        savingAttrs: Object.keys(updatedItem),
        saving: true,
      } : item)));

      const previousItemForSidePanel = queryClient.getQueryData([workspaceUrlName, 'items', {ref, key}, 'side-panel']);
      if (previousItemForSidePanel) {
        queryClient.setQueryData(sidePanelQueryKey, {
          ...previousItemForSidePanel,
          ...updatedItem,
          savingAttrs: Object.keys(updatedItem),
          saving: true,
        });
      }

      // Return context for onError
      return {previousItems, previousItemForSidePanel};
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (err, updatedItem, context) => {
      const {ref, key} = updatedItem;
      const queryKey = [workspaceUrlName, 'items', {folderId: updatedItem.parentFolderId}, 'detailed'];
      const sidePanelQueryKey = [workspaceUrlName, 'items', {ref, key}, 'side-panel'];

      queryClient.setQueryData(queryKey, context.previousItems);
      queryClient.setQueryData(sidePanelQueryKey, context.previousItemForSidePanel);
    },
    // Tanstack guide to optimstic updates suggests always refetching after
    // error or success... but this seems unnecessary.
    onSettled: () => {
      // queryClient.invalidateQueries([workspaceUrlName, 'items'], 'detailed');
      // queryClient.invalidateQueries([workspaceUrlName, 'items', {ref, key}, 'side-panel']);
    },
    // Instead of refetching, we'll just mark the updated item as not saving
    // once all mutations have completed.
    onSuccess: (data, updatedItem) => {
      const {ref, key} = updatedItem;
      const queryKey = [workspaceUrlName, 'items', {folderId: updatedItem.parentFolderId}, 'detailed'];
      const sidePanelQueryKey = [workspaceUrlName, 'items', {ref, key}, 'side-panel'];

      if (queryClient.isMutating(queryKey) > 1) {
        return;
      }

      const items = queryClient.getQueryData(queryKey);
      queryClient.setQueryData(queryKey, items.map((item) => (item.ref === updatedItem.ref ? {
        ...item,
        savingAttrs: [],
        saving: false,
      } : item)));

      const itemForSidePanel = queryClient.getQueryData(sidePanelQueryKey);
      if (itemForSidePanel) {
        queryClient.setQueryData(sidePanelQueryKey, {
          ...itemForSidePanel,
          savingAttrs: [],
          saving: false,
        });
      }
    },
  };

  return useMutation(mutationFn, options);
}

export default useUpdateItemMutation;
