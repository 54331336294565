import {useMutation as apolloUseMutation} from '@apollo/client';
import {STATUS_CODE} from 'am-constants';

function useMutation (...args) {
  const [mutateFunction, result] = apolloUseMutation(...args);

  if (result.error) {
    const {graphQLErrors} = result.error;
    const notFoundErrors = getNotFoundErrors(graphQLErrors);
    const forbiddenErrors = getForbiddenErrors(graphQLErrors);

    if (notFoundErrors.length) {
      throw notFoundErrors[0].extensions.exception;
    }

    if (forbiddenErrors.length) {
      throw forbiddenErrors[0].extensions.exception;
    }
  }

  return [mutateFunction, result];
}

function getNotFoundErrors (graphQLErrors) {
  return graphQLErrors.filter((error) =>
    error.extensions &&
    error.extensions.exception &&
    (
      error.extensions.exception.statusCode === STATUS_CODE.NOT_FOUND
    ));
}

function getForbiddenErrors (graphQLErrors) {
  return graphQLErrors.filter((error) =>
    error.extensions &&
    error.extensions.exception &&
    (
      error.extensions.exception.statusCode === STATUS_CODE.FORBIDDEN
    ));
}

export default useMutation;
