import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import tw, {styled} from 'twin.macro';

Input.propTypes = {
  blue: PropTypes.bool,
  borderless: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  icon: PropTypes.func,
  iconSize: PropTypes.number,
  placeholder: PropTypes.string,
  prefix: PropTypes.string,
  purple: PropTypes.bool,
  innerRef: PropTypes.object,
  sm: PropTypes.bool,
  number: PropTypes.bool,
  fullHeight: PropTypes.bool,
};

Input.defaultProps = {
  blue: false,
  borderless: false,
  fullWidth: false,
  purple: false,
  disabled: false,
  icon: undefined,
  iconSize: undefined,
  placeholder: '',
  prefix: undefined,
  innerRef: undefined,
  sm: false,
  number: false,
  fullHeight: false,
};

function Input (props) {
  const {
    blue,
    borderless,
    fullWidth,
    disabled,
    icon,
    iconSize,
    placeholder,
    prefix,
    purple,
    innerRef,
    sm,
    number,
    fullHeight,
    ...rest
  } = props;

  const hasIcon = !!icon;

  const inputRef = innerRef || useRef(null);
  const focusInput = () => inputRef.current && inputRef.current.focus();

  return (
    <InputWrapper
      blue={blue}
      borderless={borderless}
      fullWidth={fullWidth}
      purple={purple}
      disabled={disabled}
      withIcon={hasIcon}
      sm={sm}
      number={number}
      fullHeight={fullHeight}
    >
      {prefix && <Prefix onClick={focusInput}>{prefix}</Prefix>}
      {hasIcon && icon({size: iconSize || 24})}
      <TextInput
        blue={blue}
        borderless={borderless}
        withIcon={hasIcon}
        placeholder={placeholder}
        disabled={disabled}
        ref={inputRef}
        type={number ? 'number' : 'text'}
        {...rest}
      />
    </InputWrapper>
  );
}

const InputWrapper = styled.div((props) => [
  tw`
    flex
    items-center
    border
    px-3
    h-11
    border-gray-200
    text-gray-400
    svg:self-center
    focus-within:border-blue-400
    focus-within:text-blue-400
    bg-white
  `,
  props.blue && tw`
    bg-blue-50
    border-0
    rounded-sm
    svg:text-blue-300
    focus-within:svg:text-blue-300
  `,
  props.purple && tw`
    bg-purple-100
    border-0
    rounded-sm
    svg:text-purple-400
    focus-within:svg:text-purple-400
  `,
  props.withIcon && tw`
    pl-2.5
  `,
  props.disabled && tw`
    border-gray-100
    bg-gray-50
  `,
  props.borderless && tw`
    border-none
    px-0
  `,
  props.fullWidth && tw`
    w-full
  `,
  props.fullHeight && tw`
    h-full
  `,
  props.sm && tw`
    h-10
  `,
  props.number && tw`
    pr-1
    pl-3.5
  `,
]);

const TextInput = styled.input((props) => [
  tw`
    outline-none
    bg-transparent
    w-full
    h-full
    placeholder-gray-300
    text-gray-800
  `,
  props.blue && tw`
    text-blue-700
    placeholder-blue-400
    focus-within:placeholder-blue-300
  `,
  props.sm && tw`
    text-sm
  `,
  props.withIcon && tw`
    ml-1.5
  `,
  props.disabled && tw`
    text-gray-500
  `,
  props.type === 'number' && tw`
    text-center
  `,
]);

const Prefix = styled.span((props) => [
  tw`
    bg-blue-50
    pr-2
    mr-2
    text-gray-800
    h-full
    flex
    items-center
    ml--3
    pl-3
    select-none
  `,
]);

Input.displayName = 'Input';

export default Input;
