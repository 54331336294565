const makeProcessTitle = (({processTitle}) => ({
  type: 'process-title',
  children: [
    {
      text: processTitle || '',
    },
  ],
}));

export default makeProcessTitle;
