import checkIfItemIsPage from './checkIfItemIsPage';
import checkIfItemIsFolder from './checkIfItemIsFolder';
import checkIfItemIsLink from './checkIfItemIsLink';

const getDataTestIdForItem = (item) => {
  if (checkIfItemIsPage(item)) {
    return 'document-item';
  }
  if (checkIfItemIsFolder(item)) {
    return 'folder-item';
  }
  if (checkIfItemIsLink(item)) {
    return 'link-item';
  }

  return 'item';
};

export default getDataTestIdForItem;
