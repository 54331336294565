import {gql} from '@apollo/client';
import useMutation from 'hooks/useMutation';
import {useState} from 'react';

const SET_ADDITIONAL_DETAILS = gql`
  mutation setAdditionalDetails(
    $jobTitle: String!,
    $organizationName: String!,
    $numEmployees: String!,
    $businessAreaToUseAirManualForFirst: String!,
    $howDidYouHearAboutUs: String!
  ) {
    userSetAdditionalDetails(
      jobTitle: $jobTitle,
      organizationName: $organizationName,
      numEmployees: $numEmployees,
      businessAreaToUseAirManualForFirst: $businessAreaToUseAirManualForFirst,
      howDidYouHearAboutUs: $howDidYouHearAboutUs
    )
  }
`;

function useSetAdditionalDetailsMutation (props) {
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [setAdditionalDetails, {
    loading,
  }] = useMutation(SET_ADDITIONAL_DETAILS, {
    fetchPolicy: 'no-cache',
    onError (e) {
      setError(e);
      setData(undefined);
    },
    onCompleted (result) {
      setError(undefined);
      setData(result.userSetAdditionalDetails);
    },
  });

  return [
    setAdditionalDetails,
    {
      data,
      loading,
      error,
    },
  ];
}

export default useSetAdditionalDetailsMutation;
