import React from 'react';
import PropTypes from 'prop-types';

TextInputIcon.propTypes = {
  size: PropTypes.number,
};

TextInputIcon.defaultProps = {
  size: 16,
};

function TextInputIcon (props) {
  return (
    <svg
      enableBackground="new 0 0 2447.6 2452.5"
      viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      fill="currentColor"
      strokeWidth={0}
      width={props.size}
      height={props.size}
    >
      <g transform="matrix(1,0,0,1,2.52064,241.589)">
        <path d="M987.875,-20.491L31.084,-22.504C13.384,-22.504 -0.916,-8.204 -0.916,9.496L-0.916,527.3C-0.916,545 13.384,559.3 31.084,559.3L987.875,561.313C1005.58,561.313 1019.88,547.013 1019.88,529.313L1019.88,11.509C1019.88,-6.191 1005.58,-20.491 987.875,-20.491ZM947.875,489.313L71.084,487.3L71.084,49.496L947.875,51.509L947.875,489.313Z"/>
      </g>
    </svg>
  );
}

export default TextInputIcon;
