import React, {useState, useEffect} from 'react';
import ErrorPageLogout from 'components/ErrorPageLogout';

function Logout () {
  const [logoutFailed, setLogoutFailed] = useState(false);

  const logoutUrl = '/api/logout';

  const logout = async () => {
    // Reset identify in Heap. Kick this async call off first so it will finish
    // before we redirect.
    const heap = window?.heap;
    if (heap && heap.resetIdentity) {
      heap.resetIdentity();
    }

    // Reset identity in Hubspot.
    const _hsp = window._hsp = window._hsp || [];
    _hsp.push(['revokeCookieConsent']);

    let response;
    try {
      response = await fetch(logoutUrl, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
      });

      if (!response.ok) {
        setLogoutFailed(true);
        return;
      }
    }
    catch (err) {
      setLogoutFailed(true);
      return;
    }

    const {redirectUri} = await response.json();

    if (redirectUri) {
      window.location.href = redirectUri;
    }
    else {
      window.location.href = '/login';
    }
  };

  useEffect(() => {
    logout();
  }, [logout]);

  return (logoutFailed && <ErrorPageLogout />);
}

export default Logout;
