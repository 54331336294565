import {Editor} from 'slate';
import {CONTENT_ELEMENT_TYPES} from '../constants';

function insertFragmentIntoContent (editor, data, selectionMeta) {
  const fragment = data.getData('application/x-slate-fragment');
  if (!fragment) {
    return false;
  }

  const {selectionStartElementPath} = selectionMeta;
  const [contentElement] = Editor.above(
    editor,
    {
      at: selectionStartElementPath,
      match: (n) => CONTENT_ELEMENT_TYPES.includes(n.type),
    }
  ) || [undefined];

  if (!contentElement) {
    return false;
  }

  const decoded = decodeURIComponent(window.atob(fragment));
  const parsed = JSON.parse(decoded);
  const cleanedFragment = cleanFragmentForPastingIntoContent(editor, parsed, contentElement.type);

  editor.insertFragment(cleanedFragment);

  return true;
}

function cleanFragmentForPastingIntoContent (editor, fragment, elementType) {
  const pastingIntoProcessStep = elementType === 'process-step-content';

  const cleanedFragment = fragment.reduce((acc, n) => {
    if (n.type === 'process') {
      const cleanedNodes = cleanProcess(n, pastingIntoProcessStep);
      return [
        ...acc,
        ...cleanedNodes,
      ];
    }

    if (
      elementType === 'help-block-content' &&
      n.type === 'help-block'
    ) {
      const cleanedNodes = cleanHelpBlock(n);
      return [
        ...acc,
        ...cleanedNodes,
      ];
    }

    if (
      elementType === 'help-block-content' &&
      n.type === 'form-group'
    ) {
      const cleanedNodes = cleanFormGroup(n);
      return [
        ...acc,
        ...cleanedNodes,
      ];
    }

    return [
      ...acc,
      n,
    ];
  }, []);

  return cleanedFragment;
}

function cleanProcess (node, pastingIntoProcessStep = false) {
  return node.children.map((n) => {
    if (n.type === 'process-title') {
      return cleanTitleOrLabel(n);
    }

    if (n.type === 'process-step-list') {
      return cleanProcessStepList(n, pastingIntoProcessStep);
    }

    // eslint-disable-next-line no-console
    console.error(`Unexpected node type in cleanProcess: ${n.type}`);
    return undefined;
  }).flat();
}

function cleanTitleOrLabel (node) {
  return {
    ...node,
    type: 'paragraph',
  };
}

function cleanProcessStepList (node, pastingIntoProcessStep) {
  return node.children.map((n) => cleanProcessStep(n, pastingIntoProcessStep)).flat();
}

function cleanProcessStep (node, pastingIntoProcessStep) {
  return node.children.map((n) => {
    if (n.type === 'process-step-title') {
      return [cleanTitleOrLabel(n)];
    }

    if (n.type === 'process-step-content') {
      return pastingIntoProcessStep ? n.children : cleanProcessStepContent(n);
    }

    // eslint-disable-next-line no-console
    console.error(`Unexpected node type in cleanProcessStep: ${n.type}`);
    return undefined;
  }).flat();
}

function cleanProcessStepContent (node) {
  return node.children.map((n) => {
    if (n.type === 'help-block') {
      return cleanHelpBlock(n);
    }

    return n;
  }).flat();
}

function cleanHelpBlock (node) {
  return node.children.map((n) => {
    if (n.type === 'help-block-title') {
      return cleanTitleOrLabel(n);
    }

    if (n.type === 'help-block-content') {
      return n.children;
    }

    // eslint-disable-next-line no-console
    console.error(`Unexpected node type in cleanHelpBlock: ${n.type}`);
    return undefined;
  }).flat();
}

function cleanFormGroup (node) {
  return node.children.map((n) => {
    if (n.type === 'label') {
      return cleanTitleOrLabel(n);
    }

    if (n.type === 'text-field') {
      return n.children;
    }

    if (n.type === 'choice-group') {
      return cleanChoiceGroup(n);
    }

    // eslint-disable-next-line no-console
    console.error(`Unexpected node type in cleanFormGroup: ${n.type}`);
    return undefined;
  }).flat();
}

function cleanChoiceGroup (node) {
  return node.children.map((n) => {
    if (n.type === 'choice') {
      return cleanChoice(n);
    }

    // eslint-disable-next-line no-console
    console.error(`Unexpected node type in cleanChoiceGroup: ${n.type}`);
    return undefined;
  }).flat();
}

function cleanChoice (node) {
  return {
    ...node,
    type: 'list-item',
  };
}

export default insertFragmentIntoContent;
