function getNameRefAndKeyParts (nameRefAndKey) {
  let name;
  let itemRefAndKey;
  let ref;
  let key;

  if (!nameRefAndKey) {
    return {
      name: undefined,
      ref: undefined,
      key: undefined,
    };
  }

  const parts = nameRefAndKey.split('--');
  if (parts.length === 2) {
    [name, itemRefAndKey] = parts;

    const resourceParts = itemRefAndKey.split('-');
    if (resourceParts.length === 2) {
      [ref, key] = resourceParts;
    }
  }

  return {
    name,
    ref,
    key,
  };
}

export default getNameRefAndKeyParts;
