import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled} from 'twin.macro';
import {Link} from '@reach/router';
import {Helmet} from 'react-helmet';
import PageCenter from './PageCenter';
import errorImg from './error.jpg';
import Button from './Button';

ErrorPage.propTypes = {
  message: PropTypes.string.isRequired,
};

ErrorPage.defaultProps = {
};

function ErrorPage (props) {
  const {
    message,
  } = props;

  return <>
    <Helmet>
      <title>{message}</title>
    </Helmet>
    <PageCenter tw="flex-col">
      <img
        src={errorImg}
        tw="w-full max-w-lg mb-4 mx-auto"
        style={{aspectRatio: '787/457'}}/>
      <Message>{message}. Sorry 😢</Message>
      <Link to="/"><Button>Take me home</Button></Link>
    </PageCenter>
  </>;
}

const Message = styled.div((props) => [
  tw`
    text-lg
    text-gray-600
    mb-12
  `,
]);

export default ErrorPage;
