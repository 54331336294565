import React from 'react';
import Modal from 'components/Modal';
import Button, {ButtonSpacer} from 'components/Button';
import PropTypes from 'prop-types';
import {useUser} from 'containers/UserApp/providers/UserProvider';
import P from 'components/P';
import Link from 'components/Link';
import getRedirectToLoginUri from 'utils/getRedirectToLoginUri';
import PageDuplicaterModalContent from './PageDuplicaterModalContent';

PageDuplicaterModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  destinationWorkspaceUrlName: PropTypes.string,
  itemName: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  renderError: PropTypes.func,
};

function PageDuplicaterModal (props) {
  const {
    isVisible,
    closeModal,
    onSubmit,
    itemName,
    onChange,
    destinationWorkspaceUrlName,
    renderError,
  } = props;

  const {user} = useUser();

  const redirectToLogin = () => {
    window.location = getRedirectToLoginUri();
  };

  if (!user) {
    return (
      <Modal
        visible={isVisible}
        title="Login to copy"
        onClose={closeModal}
        errorTitle={'Could not duplicate page'}
        footer={<>
          <Button type="button" onClick={closeModal}>
            Cancel
          </Button>
          <ButtonSpacer />
          <Button type="button" primary onClick={redirectToLogin} data-test-id="modal_login-button">
            Login
          </Button>
        </>}
        renderError={renderError}
      >
        <P>You need to login to copy this page to your workspace.</P>
        <P>Not got an account? <Link to="https://airmanual.link/discovery">Schedule a demo</Link></P>
      </Modal>
    );
  }

  return (
    <Modal
      visible={isVisible}
      title="Copy to my workspace"
      onClose={closeModal}
      errorTitle={'Could not duplicate page'}
      footer={<>
        <Button type="button" onClick={closeModal}>
          Cancel
        </Button>
        <ButtonSpacer />
        <Button type="button" primary disabled={!destinationWorkspaceUrlName} onClick={onSubmit} data-test-id="modal_copy-button">
          Copy
        </Button>
      </>}
      renderError={renderError}
    >
      <PageDuplicaterModalContent itemName={itemName} onChange={onChange}/>
    </Modal>
  );
}

export default PageDuplicaterModal;
