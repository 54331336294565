import tw, {styled} from 'twin.macro';

const Tip = styled.div((props) => [
  tw`
    mt-1
    text-xs
    text-gray-500
  `,
]);

export default Tip;
