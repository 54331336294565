import {TRIGGER} from 'am-constants';

function getExampleWebhookCallContent (inputs, itemName, itemUrl, user, trigger) {
  const pageUrl = window.location.hostname.includes('localhost')
    ? `http://a.m.localhost:3001/${itemUrl}`
    : `${window.location.origin}/${itemUrl}`;

  const responseUrlSlug = `${(itemName || 'Untitled')
    .trim()
    .replace(/[^a-z0-9_]+/gi, '-')
    .replace(/[-]{2,}/gi, '-')
    .replace(/[_-]+$/gi, '')}--imjMrLrdF6rc5js8xdVs265Q-ffiQHgZSH9Cq`;

  const responseUrl = window.location.hostname.includes('localhost')
    ? `http://a.m.localhost:3001/use/${responseUrlSlug}`
    : `${window.location.origin}/use/${responseUrlSlug}`;

  const isSubmittingResponse = trigger === TRIGGER.SUBMIT_RESPONSE;

  const formattedInputs = formatInputs(inputs);

  return ({
    page_name: itemName,
    page_url: pageUrl,
    response_url: responseUrl,
    event_name: getEventNameTrigger(trigger) || 'STARTED',
    started_at: 1677012405,
    started_by_user_id: 1,
    started_by_name: user?.name,
    started_by_email: user?.email,
    submitted_at: isSubmittingResponse ? 1677011950 : null,
    submitted_by_user_id: isSubmittingResponse ? 1 : null,
    submitted_by_name: isSubmittingResponse ? user?.name : null,
    submitted_by_email: isSubmittingResponse ? user?.email : null,
    inputs: formattedInputs,
  });
}

function formatInputs (inputs) {
  if (!inputs) {
    return null;
  }

  return inputs.map(({id, label, type}) => ({
    [id]: {
      label,
      value: getExampleValueForType(type),
    },
  }));
}

function getExampleValueForType (type) {
  if (type === 'multiple-choice-group') {
    return 'Value 1, Value 2';
  }
  if (type === 'radio-choice-group') {
    return 'Value 1';
  }
  if (type === 'text-field') {
    return 'Example text';
  }

  return '';
}

function getEventNameTrigger (trigger) {
  if (trigger === TRIGGER.CREATE_RESPONSE) {
    return 'STARTED';
  }
  if (trigger === TRIGGER.SUBMIT_RESPONSE) {
    return 'SUBMITTED';
  }

  return null;
}

export default getExampleWebhookCallContent;
