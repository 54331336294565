import isHotkey from 'is-hotkey';
import makeParagraph from '../factory/makeParagraph';
import insertNodeAtSelection from '../utils/insertNodeAtSelection';

function onShiftEnterInCodeBlock (editor, event, selectionMeta) {
  const {
    isSelectionCollapsed,
    selectionStart,
    selectionStartElement,
  } = selectionMeta;

  if (
    !isHotkey('shift+enter')(event) ||
    !selectionStart ||
    !isSelectionCollapsed ||
    selectionStartElement.type !== 'code-block'
  ) {
    return false;
  }

  event.preventDefault();
  insertNodeAtSelection(editor, {makeNode: makeParagraph});

  return true;
}

export default onShiftEnterInCodeBlock;
