export const ITEM_MINIMAL_FIELDS = `
  id
  ref
  key
  parentId
  itemTypeId
  name
  emoji
  details
  loggedInUserRoleIds
  contentActionNeededId
  contentDescription
  public
  rank
  numPages
`;

export default null;
