import React from 'react';
import {Helmet} from 'react-helmet';
import P from 'components/P';
import Link from 'components/Link';
import SimpleFormContainer from 'components/SimpleFormContainer';
import SimpleFormHeading from 'components/SimpleFormHeading';
import SimpleFormPage from 'components/SimpleFormPage';
import PropTypes from 'prop-types';

ResetPasswordRequestSentPage.propTypes = {
  onLeave: PropTypes.func.isRequired,
};

function ResetPasswordRequestSentPage (props) {
  const {onLeave} = props;
  return <>
    <Helmet>
      <title>Please check your email</title>
      <meta name="referrer" content="no-referrer" />
    </Helmet>
    <SimpleFormPage>
      <SimpleFormContainer>
        <SimpleFormHeading>Please check your email</SimpleFormHeading>
        <P>
          Please check your email for instructions on how to reset your password.
        </P>
        <P>
          If you haven't received an email in 5 minutes, check your spam or <Link to="/reset" onClick={onLeave}>try again</Link>.
        </P>
      </SimpleFormContainer>
    </SimpleFormPage>
  </>;
}

export default ResetPasswordRequestSentPage;
