import React, {useState} from 'react';
import PropTypes from 'prop-types';
import DocumentContainer from 'containers/Document';
import {getContentType} from 'am-utils';
import {ITEM_TYPE} from 'am-constants';
import {useComments} from 'containers/CommentsPanel/providers/CommentsProvider';
import DocumentEditorErrorBoundary from './DocumentEditorErrorBoundary';
import DocumentEditorPage from './DocumentEditorPage';
import PublishModal from './PublishModal';

DocumentEditor.propTypes = {
  content: PropTypes.array.isRequired,
  editModeEditor: PropTypes.shape({}).isRequired,
  isWizardVisible: PropTypes.bool.isRequired,
  itemRef: PropTypes.string.isRequired,
  itemKey: PropTypes.string.isRequired,
  lastPublishedAt: PropTypes.string,
  onPublish: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  printMode: PropTypes.bool.isRequired,
  published: PropTypes.bool.isRequired,
  publishing: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  saveError: PropTypes.shape({}),
};

DocumentEditor.defaultProps = {
  saveError: undefined,
};

function DocumentEditor (props) {
  const {
    content,
    editModeEditor,
    isWizardVisible,
    itemRef,
    itemKey,
    lastPublishedAt,
    onPublish,
    onUpdate,
    printMode,
    published,
    publishing,
    readOnly,
    saveError,
  } = props;

  const [showPublishModal, setShowPublishModal] = useState(false);
  const [contentType, setContentType] = useState(undefined);
  const {recalculateWarningsAndPositions} = useComments();

  const isFirstPublish = !lastPublishedAt;

  const handleUserClickingPublish = () => {
    const type = getContentType(content);

    if (
      [ITEM_TYPE.CHECKLIST, ITEM_TYPE.FORM].includes(type) &&
      !isFirstPublish
    ) {
      setContentType(type);
      setShowPublishModal(true);
      // Don't call onPublish - this will be called by the modal
      return;
    }

    onPublish();
  };

  return (
    <DocumentEditorPage
      onPublish={handleUserClickingPublish}
      printMode={printMode}
      published={published}
      publishing={publishing}
      saveError={saveError}>
      <PublishModal
        closeModal={() => setShowPublishModal(false)}
        contentType={contentType}
        isVisible={showPublishModal}
        onSubmit={onPublish}
      />
      <DocumentEditorErrorBoundary
        itemRef={itemRef}
        itemKey={itemKey}>
        <DocumentContainer
          content={content}
          editModeEditor={editModeEditor}
          isWizardVisible={isWizardVisible}
          mode={printMode ? 'view' : 'edit'}
          readOnly={readOnly}
          onUpdate={onUpdate}
          recalculateWarningsAndPositions={recalculateWarningsAndPositions}
        />
      </DocumentEditorErrorBoundary>
    </DocumentEditorPage>
  );
}

export default DocumentEditor;
