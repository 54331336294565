import {SINGLE_LINE_ELEMENT_TYPES} from '../constants';

function insertDataIntoSingleLineElement (editor, data, selectionMeta) {
  const {selectionStartElement, isSelectionInSingleElement} = selectionMeta;

  if (
    !selectionStartElement ||
    !SINGLE_LINE_ELEMENT_TYPES.includes(selectionStartElement.type) ||
    !isSelectionInSingleElement
  ) {
    return false;
  }

  const text = data.getData('text');
  const [firstLine] = getTrimmedNonEmptyLines(text);

  // Insert the first line
  editor.insertText(firstLine);

  return true;
}

function getTrimmedNonEmptyLines (text) {
  return text
    .split('\n')
    .map((line) => line.trim())
    .filter((line) => line !== '');
}

export default insertDataIntoSingleLineElement;
