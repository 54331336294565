import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled} from 'twin.macro';
import {css} from 'styled-components';
import {
  AiOutlinePlus, AiOutlineCheck,
} from 'react-icons/ai';

Process.propTypes = {
  addProcessStep: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  editable: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  printMode: PropTypes.bool.isRequired,
  progress: PropTypes.arrayOf(PropTypes.bool).isRequired,
  selected: PropTypes.bool,
  setOpen: PropTypes.func,
};

Process.defaultProps = {
  editable: false,
  selected: false,
};

function Process (props) {
  const {
    addProcessStep,
    children,
    editable,
    open,
    printMode,
    progress,
    selected,
    setOpen,
  } = props;

  const toggle = () => setOpen(!open);

  return (
    <>
      <Wrapper
        selected={selected}
        open={open}
        data-test-id="process"
        contentEditable={editable}
        editing={editable}
        onClick={() => !editable && toggle()}
        suppressContentEditableWarning
      >
        {children}

        {!printMode && <Progress open={open} contentEditable={false}>
          {progress.map((done, i) => (
            <ProgressItem key={`check${i}`}>
              {done && <Checked><AiOutlineCheck /></Checked>}
              {!done && <Unchecked><AiOutlineCheck /></Unchecked>}
            </ProgressItem>
          ))}
        </Progress>}
        {editable && open && (
          <ProcessActionBar contentEditable={false} onClick={() => open && addProcessStep()} processSelected={selected}>
            <AiOutlinePlus title="Add a step" style={{opacity: open ? 1 : 0}} />
          </ProcessActionBar>
        )}
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div((props) => [
  tw`
    my-5
    rounded
    bg-gray-50
    border-4
    border-gray-50
    relative
    clear-both
  `,
  css`
    -webkit-print-color-adjust: exact; // Chrome, Safari
    color-adjust: exact; // Firefox
    counter-reset: process-step;
    @media (max-width: 640px) {
      margin-left: -16px;
      margin-right: -16px;
      border-radius: 0;
      /* Overflow: hidden needed on Safari to prevent the negative left and
        right margin increasing the document size.  */
      overflow: hidden;
    }
  `,
  props.selected &&
    tw`
    border-blue-500
    select-none
  `,
  !props.editing &&
    tw`
    cursor-pointer
  `,
  !props.open &&
    css`
      :not(:hover) {
        opacity: 0.8;
      }
    `,
]);

const ProcessActionBar = styled.div((props) => [
  tw`
    flex
    text-xs
    text-gray-300
    justify-center
    pt-0.5
    border-t-2
    border-gray-50
    cursor-pointer
  `,
  props.processSelected && tw`
    border-blue-100
  `,
]);

const Progress = styled.div((props) => [
  tw`
    mx-4
    mt--1.5
    mb-2
    transition-opacity
  `,
  props.open && tw`
    opacity-0
    absolute
    top-12
  `,
]);

const ProgressItem = styled.div((props) => [
  tw`
    inline-block
  `,
]);

const Unchecked = styled.div((props) => [
  tw`
    h-4
    w-4
    mr-0.5
    bg-gray-100
    rounded-full
    p-0.5
    text-2xs
    text-white
  `,
]);

const Checked = styled(Unchecked)((props) => [
  tw`
    bg-green-400
    text-white
  `,
]);

export default React.memo(Process);
