import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled} from 'twin.macro';
import FormGroup from 'components/FormGroup';
import Select, {Option} from 'components/Select';
import {useTeamMembers} from '../providers/TeamMembersProvider';

ContentReviewerCellPopover.propTypes = {
  reviewers: PropTypes.arrayOf(PropTypes.shape({
    personId: PropTypes.number,
  })),
  onUpdateItem: PropTypes.func.isRequired,
};

ContentReviewerCellPopover.defaultProps = {
};

function ContentReviewerCellPopover (props) {
  const {
    reviewers,
    onUpdateItem,
  } = props;

  const {teamMembers} = useTeamMembers();

  const initialReviewerIds = reviewers.map(({id}) => id);

  const handleUpdate = (value) => {
    const reviewerIds = value.map((idStr) => parseInt(idStr, 10));

    onUpdateItem({
      // Note: We pass up alll the reviewer details to the
      // useUpdateItemMutation (rather than just the reviewer ids) as it
      // makes optimistic updates simpler :)
      reviewers: reviewerIds.map((teamMemberId) => teamMembers.find(({user}) => user.id === teamMemberId)).map((teamMember) => ({
        id: teamMember.user.id,
        name: teamMember.user.name,
        email: teamMember.user.email,
      })),
    });
  };

  return (
    <Wrapper>
      <FormGroup top>
        <Select
          mode='multiple'
          placeholder='Please select'
          defaultValue={initialReviewerIds}
          optionFilterProp='label'
          onChange={handleUpdate}
          focusAfterMount
          getPopupContainer={(triggerNode) => triggerNode.parentElement}
          inline
          popupClassName='ant-select-dropdown__fixed'
        >
          {teamMembers.map(({user}) => (
            <Option key={user.id} value={user.id} label={user.name}>{user.name}</Option>
          ))}
        </Select>
      </FormGroup>
    </Wrapper>
  );
}

const Wrapper = styled.div((props) => [
  tw`
    w-72
    mb--8
  `,
]);

export default ContentReviewerCellPopover;
