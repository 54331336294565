import React, {useMemo, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Loader from 'components/Loader';
import {Helmet} from 'react-helmet';
import debounce from 'utils/debounce';
import {navigate} from '@reach/router';
import getNameRefAndKeyParts from 'utils/getNameRefAndKeyParts';
import ExplorerPage from './components/ExplorerPage';
import useItemAndSearchResultsQueries from './hooks/useItemAndSearchResultsQueries';

WorkspaceExplorer.propTypes = {
  'name--:ref-:key': PropTypes.string,
  workspaceUrlName: PropTypes.string,
};

function WorkspaceExplorer (props) {
  const {
    'name--:ref-:key': nameRefAndKey,
    workspaceUrlName,
  } = props;
  const {ref, key} = useMemo(() => getNameRefAndKeyParts(nameRefAndKey), [nameRefAndKey]);

  const currentUrlParams = new URLSearchParams(window.location.search);
  const queryString = currentUrlParams.get('q') || '';
  const [searchString, setSearchString] = useState(queryString);
  const [isAboutToSearch, setIsAboutToSearch] = useState(false);
  useEffect(() => {
    setSearchString(queryString);
  }, [queryString]);

  const search = (string) => {
    const formattedQueryString = string && `?q=${string}`;
    navigate(`${window.location.pathname}${formattedQueryString}`);
    setSearchString(string);
    setIsAboutToSearch(false);
  };
  const debouncedSearch = useMemo(() => debounce(search, 500), []);
  const onSearch = (string) => {
    setIsAboutToSearch(true);
    debouncedSearch(string);
  };

  const {
    item,
    searchResults,
    itemLoading,
    searchResultsLoading,
    itemError,
    searchResultsError,
  } = useItemAndSearchResultsQueries({
    ref,
    key,
    workspaceUrlName,
    searchString,
  });

  if (itemLoading) {
    return <Loader />;
  }

  if (itemError) {
    return <p>Could not load workspace</p>;
  }

  const hasSearchOccured = searchResults || searchResultsError;
  const searchResultsOrNoItems = !searchResultsError ? searchResults : [];
  const itemsToDisplay = hasSearchOccured ? searchResultsOrNoItems : item.children;
  const isSearchLoading = Boolean(isAboutToSearch || searchResultsLoading);

  return <>
    <Helmet>
      <title>{item.name}</title>
    </Helmet>
    <ExplorerPage
      item={item}
      isSearchError={Boolean(searchResultsError)}
      isSearchLoading={isSearchLoading}
      itemChildren={itemsToDisplay}
      searchString={searchString}
      onSearch={onSearch}
    />
  </>;
}

export default WorkspaceExplorer;
