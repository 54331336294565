import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import CustomPropTypes from 'utils/CustomPropTypes';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';
import useItemQuery from './hooks/useItemQuery';
import SidePanel from './components/SidePanel';

ItemSidePanel.propTypes = {
  item: CustomPropTypes.Item,
  onClose: PropTypes.func.isRequired,
  onUpdateItem: PropTypes.func.isRequired,
};

function ItemSidePanel (props) {
  const {
    item,
    onClose,
    onUpdateItem,
  } = props;

  const {ref, key} = item;
  const {urlName: workspaceUrlName} = useWorkspace();
  const {data} = useItemQuery({workspaceUrlName, ref, key});

  const handleUpdateItem = useCallback((obj) => {
    onUpdateItem({
      ...obj,
      ref,
      key,
      parentFolderId: item.parentFolderId,
    });
  }, [ref, key]);

  return (
    <SidePanel
      inputs={data?.inputs}
      item={item}
      onClose={onClose}
      onUpdateItem={handleUpdateItem}
      automations={data?.automations}
      contentDescription={data?.contentDescription || item.contentDescription}
      responses={data?.responses}
      responseStats={data?.responseStats || item.responseStats}
      savingAttrs={data?.savingAttrs}
    />
  );
}

export default ItemSidePanel;
