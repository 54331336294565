import isUrl from 'is-url';
import wrapLink from '../utils/wrapLink';

function insertLink (editor, data, selectionMeta) {
  const text = data.getData('text/plain');

  if (
    !text ||
    !isUrl(text)
  ) {
    return false;
  }

  wrapLink(editor, text);
  return true;
}

export default insertLink;
