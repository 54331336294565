import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import Button, {ButtonSpacer} from 'components/Button';
import Collapse from 'components/Collapse';
import Checkbox from 'components/Checkbox';
import P from 'components/P';
import {ITEM_TYPE} from 'am-constants';

PublishModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  contentType: PropTypes.oneOf([ITEM_TYPE.CHECKLIST, ITEM_TYPE.FORM]),
  isVisible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

PublishModal.defaultProps = {
};

function PublishModal (props) {
  const {
    isVisible,
    closeModal,
    onSubmit,
    contentType,
  } = props;

  const [doNotUpdateResponses, setDoNotUpdateResponses] = useState(false);
  const submit = () => {
    onSubmit({doNotUpdateResponses});
  };

  return (
    <Modal
      visible={isVisible}
      title="Publish page"
      onClose={closeModal}
      onSubmit={submit}
      footer={<>
        <Button type="button" onClick={closeModal}>
          Cancel
        </Button>
        <ButtonSpacer />
        <Button type="submit" primary data-test-id="modal_publish-button">
          Publish
        </Button>
      </>}
    >
      {contentType === ITEM_TYPE.FORM && <P>
        This page contains a form. Any active responses will
        be updated with your latest changes.
      </P>}
      {contentType === ITEM_TYPE.CHECKLIST && <P>
        This page contains a guided checklist. Any active checklists will
        be updated with your latest changes.
      </P>}
      <Collapse heading="Advanced">
        <Checkbox onChange={setDoNotUpdateResponses}>
          {contentType === ITEM_TYPE.FORM && 'Do not update active responses'}
          {contentType === ITEM_TYPE.CHECKLIST && 'Do not update active checklists'}
        </Checkbox>
      </Collapse>
    </Modal>
  );
}

export default PublishModal;
