import React from 'react';
import {Helmet} from 'react-helmet';
import FormGroup from 'components/FormGroup';
import Label from 'components/Label';
import PropTypes from 'prop-types';
import Input from 'components/Input';
import P from 'components/P';
import Button, {FullWidthButtonSecondaryAction} from 'components/Button';
import Link from 'components/Link';
import SimpleFormContainer from 'components/SimpleFormContainer';
import SimpleFormHeading from 'components/SimpleFormHeading';
import SimpleFormPage from 'components/SimpleFormPage';
import tw from 'twin.macro'; // eslint-disable-line no-unused-vars
import Error from 'components/Error';

ResetPasswordPage.propTypes = {
  requestPasswordReset: PropTypes.func.isRequired,
  error: PropTypes.object,
  loading: PropTypes.bool.isRequired,
};

function ResetPasswordPage (props) {
  const {
    requestPasswordReset,
    error,
    loading,
  } = props;

  const onSubmit = (event) => {
    event.preventDefault();
    requestPasswordReset(event.target.elements[0].value);
  };
  const isEmailNotFound = error &&
    error.graphQLErrors &&
    error.graphQLErrors[0] &&
    error.graphQLErrors[0].extensions &&
    error.graphQLErrors[0].extensions.exception &&
    error.graphQLErrors[0].extensions.exception.statusCode === 422;
  const errorMessage = isEmailNotFound
    ? 'We couldn’t find that email. Please try again.'
    : 'Something went wrong. Please try again.';

  return <>
    <Helmet>
      <title>Reset your password</title>
      <meta name="referrer" content="no-referrer" />
    </Helmet>
    <SimpleFormPage>
      <SimpleFormContainer>
        <form onSubmit={onSubmit}>
          <SimpleFormHeading>Reset your password</SimpleFormHeading>
          <P>
            Enter your email address and we'll send you a link to
            reset your password.
          </P>
          <FormGroup>
            <Label>
              Email address
            </Label>
            <Input type="email" required data-test-id="email-input" />
          </FormGroup>
          <Button type="submit" primary lg fullwidth disabled={loading} data-test-id="reset-button">Continue</Button>
          {error && !loading && (
            <Error arrow>{errorMessage}</Error>
          )}
          <FullWidthButtonSecondaryAction>
            <Link to="/login">Return to sign in</Link>
          </FullWidthButtonSecondaryAction>
        </form>
      </SimpleFormContainer>

      {/* TODO: show this when wanting to make it visible to users
        <P tw="text-center text-xs">Don't have an account? <Link to="/signup">Sign up</Link></P>
        */}
    </SimpleFormPage>
  </>;
}

export default ResetPasswordPage;
