import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';
import {ITEM_TYPE} from 'am-constants';
import Loader from 'components/Loader';
import {LexoRank} from 'lexorank';
import ItemSidePanel from 'containers/ItemSidePanel';
import {PageBody} from 'containers/FolderApp/components/FolderLayout';
import useURLSearchParam from 'hooks/useURLSearchParam';
import useCreateItemMutation from 'hooks/useCreateItemMutation';
import FolderManager from './components/FolderManager';
import useUpdateItemMutation from './hooks/useUpdateItemMutation';
import useTeamMembersQuery from './hooks/useTeamMembersQuery';
import TeamMembersProvider from './providers/TeamMembersProvider';
import useItemsQueries from './hooks/useItemsQueries';
import useMoveItemMutation from './hooks/useMoveItemMutation';

FolderManagerApp.propTypes = {
  folderId: PropTypes.number.isRequired,
  folderRef: PropTypes.string.isRequired,
  folderKey: PropTypes.string.isRequired,
};

function FolderManagerApp (props) {
  const {folderId, folderRef, folderKey} = props;
  const {urlName: workspaceUrlName} = useWorkspace();
  const [sidePanelItem, setSidePanelItem] = useState();

  const expandedUrlParamValue = useURLSearchParam('expanded');
  const initialExpandedItemIds = expandedUrlParamValue
    ? expandedUrlParamValue.split('-').map((id) => parseInt(id, 10))
    : [];
  const [expandedItemIds, setExpandedItemIds] = useState(new Set(initialExpandedItemIds));

  // Load the items for the parent folder and all expanded flders
  const allExpandedFolderIds = [...new Set([folderId, ...expandedItemIds])]; // Combining in a Set ensures no duplicates
  const itemsQueries = useItemsQueries(allExpandedFolderIds.map((expandedItemId, index) => ({
    folderId: expandedItemId,
    // We support doNotThrow so that the UI can request all of the expanded
    // folders in the URL, which might include folders that have been
    // deleted. This flag should only be set to true for expanded folders.
    doNotThrow: index > 0,
  })));
  const items = getItemsFromItemsQueries(itemsQueries);
  const {data: teamMembers} = useTeamMembersQuery({workspaceUrlName});
  const loadingFolderIds = itemsQueries
    .map(({isInitialLoading}, index) =>
      (isInitialLoading ? allExpandedFolderIds[index] : undefined))
    .filter((id) => id !== undefined);

  // ALSO load the items for unexpanded subfolders, so items are preloaded when
  // the user expands a folder.
  const subfolderIds = items
    .filter((item) =>
      item.itemTypeId === ITEM_TYPE.FOLDER &&
      !item.tempId &&
      !expandedItemIds.has(item.id))
    .map(({id}) => id);
  const subfolderItemQueries = useItemsQueries(subfolderIds.map((id) => ({
    workspaceUrlName,
    folderId: id,
  })));
  const subfolderItems = getItemsFromItemsQueries(subfolderItemQueries);
  const loadingSubfolderIds = subfolderItemQueries
    .map(({isInitialLoading}, index) =>
      (isInitialLoading ? subfolderIds[index] : undefined))
    .filter((id) => id !== undefined);

  const allLoadingFolderIds = [...loadingFolderIds, ...loadingSubfolderIds];
  const itemsWithLoadingFlag = items.map((item) => ({
    ...item,
    isLoading: allLoadingFolderIds.includes(item.id), // Used to show a loading indicator in the DataGrid
  }));

  const isItemsFetching = itemsQueries.some(({isFetching}) => isFetching);
  const isItemsInitialLoading = itemsQueries[0].isInitialLoading;

  useEffect(() => setSidePanelItemFromUrl({isItemsFetching, items, setSidePanelItem}), [isItemsFetching]);
  useEffect(() => updateItemParamInUrl(sidePanelItem), [sidePanelItem]);

  const addItem = useCreateItemMutation({});
  const updateItem = useUpdateItemMutation({});
  const moveItem = useMoveItemMutation({});

  if (isItemsInitialLoading) {
    return <Loader />;
  }

  const handleItemClick = (i) => i.id && setSidePanelItem(sidePanelItem === i ? null : {
    ...i,
    parentFolderId: getParentFolderId(i),
  });

  const handleAddItem = (newItem) => {
    const parentItem =
      items.find(({id}) => id === newItem.parentId) ||
      {
        id: folderId,
        ref: folderRef,
        key: folderKey,
      };
    const parentFolderId = parentItem.itemTypeId === ITEM_TYPE.SECTION
      ? parentItem.parentId
      : parentItem.id;

    let rank = LexoRank.middle().toString();

    const sortedItemsWithSameParent = items
      .filter((i) => i.parentId === parentItem.id)
      .sort((a, b) => a.rank.localeCompare(b.rank));

    if (sortedItemsWithSameParent.length) {
      const lastItemInParentGroup = sortedItemsWithSameParent[sortedItemsWithSameParent.length - 1];
      const lastItemRank = LexoRank.parse(lastItemInParentGroup.rank);
      rank = lastItemRank.genNext().toString();
    }

    addItem.mutate({
      name: newItem.name,
      itemTypeId: newItem.itemTypeId,
      parentFolderId,
      parentId: parentItem.id,
      parentItemRef: parentItem.ref,
      parentItemKey: parentItem.key,
      emoji: newItem.emoji,
      saving: true,
      rank,
    });
  };

  const handleUpdateItem = (updatedItem) => {
    const item = items.find(({id}) => id === updatedItem.id);
    const parentFolderId = getParentFolderId(item);

    updateItem.mutate({
      ...updatedItem,
      parentFolderId,
    });
  };

  const handleMoveItem = (movedItem) => {
    const item = items.find(({id}) => id === movedItem.id);
    const oldParentFolderId = getParentFolderId(item);
    const newParentFolderId = getParentFolderId({...item, ...movedItem});

    moveItem.mutate({
      ...movedItem,
      oldParentFolderId,
      newParentFolderId,
    });
  };

  const getParentFolderId = (item) => {
    if (!item) {
      return folderId;
    }

    const parentItem = items.find(({id}) => id === item.parentId);
    if (!parentItem) {
      return folderId;
    }

    if (parentItem.itemTypeId === ITEM_TYPE.SECTION) {
      return parentItem.parentId;
    }

    return parentItem.id;
  };

  const handleExpandOrCollapse = (itemIds) => setExpandedItemIds(itemIds);

  return (
    <>
      {sidePanelItem && (
        <ItemSidePanel
          item={sidePanelItem}
          onClose={() => setSidePanelItem()}
          onUpdateItem={handleUpdateItem}
        />
      )}
      <PageBody noPadding>
        <TeamMembersProvider teamMembers={teamMembers}>
          {folderId && (
            <FolderManager
              items={[
                ...itemsWithLoadingFlag,
                ...subfolderItems,
              ]}
              parentItemId={folderId}
              onAddItem={handleAddItem}
              onExpandOrCollapse={handleExpandOrCollapse}
              onItemClick={handleItemClick}
              onUpdateItem={handleUpdateItem}
              onMoveItem={handleMoveItem}
            />
          )}
        </TeamMembersProvider>
      </PageBody>
    </>
  );
}

function getItemsFromItemsQueries (itemsQueries) {
  return itemsQueries
    .map(({data}) => data)
    .flat()
    .filter((item) => item !== undefined);
}

const setSidePanelItemFromUrl = ({isItemsFetching, items, setSidePanelItem}) => {
  if (isItemsFetching) {
    return;
  }

  const sidePanelItemUrlParamValue = useURLSearchParam('item');
  if (!sidePanelItemUrlParamValue) {
    return;
  }

  const item = items.find(({id}) => id === parseInt(sidePanelItemUrlParamValue, 10));
  if (!item) {
    return;
  }

  setSidePanelItem(item);
};

const updateItemParamInUrl = (sidePanelItem) => {
  const url = new URL(window.location.href);

  if (sidePanelItem) {
    url.searchParams.set('item', sidePanelItem.id);
    window.history.replaceState(null, null, url.href);
  }
  else {
    url.searchParams.delete('item');
    window.history.replaceState(null, null, url.href);
  }
};

export default FolderManagerApp;
