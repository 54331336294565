import {nanoid} from 'nanoid';
import {Transforms} from 'slate';
import makeImage from '../factory/makeImage';
import insertNodeAtSelection from './insertNodeAtSelection';
const {SUPPORTED_IMG_TYPES} = require('am-constants');

function insertImage (editor, uploadBase64Image) {
  const fileInput = document.createElement('input');
  fileInput.setAttribute('type', 'file');
  fileInput.setAttribute('multiple', true);
  fileInput.setAttribute('accept', SUPPORTED_IMG_TYPES.join(', '));

  fileInput.onchange = () => {
    // eslint-disable-next-line no-restricted-syntax
    for (const file of fileInput.files) {
      const reader = new FileReader();

      // eslint-disable-next-line no-loop-func
      reader.addEventListener('load', () => {
        const id = nanoid();
        const data = `data:application/octet-stream;base64,${btoa(reader.result)}`;

        const tempImg = new Image();
        tempImg.onload = () => {
          const {width, height} = tempImg;
          insertNodeAtSelection(editor, {
            makeNode: () => makeImage({
              id,
              // As in makeApply.js we make sure images have unique id's even
              // when copied and pasted, we need to store the original id as an
              // attribute to replace the buffering placeholder.
              idBeforeMakeApply: id,
              width,
              height,
            }),
          });
        };
        tempImg.src = data;

        uploadBase64Image({
          id,
          data,
          file,
          onSuccess: (url) => {
            Transforms.setNodes(editor, {url, idBeforeMakeApply: undefined}, {
              match: (n) => n.idBeforeMakeApply === id,
              at: editor,
            });
          },
        });
      });

      reader.addEventListener('error', () => {
        // eslint-disable-next-line no-console
        console.error('Error reading image data');
      });

      reader.readAsBinaryString(file);
    }
  };

  fileInput.click();
}

export default insertImage;
