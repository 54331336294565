import React from 'react';
import PropTypes from 'prop-types';
import Text from 'components/Text';
import {ReactEditor, useSlateStatic} from 'slate-react';
import {Editor} from 'slate';
import {styled, css} from 'twin.macro';
import Placeholder from '../Placeholder';

Paragraph.propTypes = {
  attributes: PropTypes.shape({}).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  element: PropTypes.shape({}).isRequired,
};

function Paragraph (props) {
  const {
    element,
    children,
  } = props;

  const editor = useSlateStatic();

  const path = ReactEditor.findPath(editor, element);
  const isOnlyParagraphInDocument =
    editor.children.length === 2 &&
    path.length === 1 &&
    path[0] === 1;
  const isOnlyParagraphInFirstProcessStep =
    path.length === 5 &&
    path[2] === 0 &&
    Editor.node(editor, path.slice(0, 4))[0].children.length === 1; // process-step-content

  if (isOnlyParagraphInDocument) {
    // The PlaceholderHider/isEmpty logic is used so that we can use
    // useSlateStatic rather than useSlate. As we're using useSlateStatic,
    // isOnlyParagraphInDocument will still be true if the user presses enter
    // at the end of the document title (rather than selecting the paragraph
    // or doing anything else that would lead to a re-render).
    const isEmpty = children[0].props.text.text.length === 0;

    return (
      <PlaceholderHider isEmpty={isEmpty}>
        <Text style={{position: 'relative'}}>
          <Placeholder text='Make your page amazing'>{children}</Placeholder>
        </Text>
      </PlaceholderHider>
    );
  }

  if (isOnlyParagraphInFirstProcessStep) {
    return (
      <Text style={{position: 'relative'}}>
        <Placeholder text="Add guidance (if it's needed!)">{children}</Placeholder>
      </Text>
    );
  }

  return <Text>{children}</Text>;
}

const PlaceholderHider = styled.div((props) => [
  props.isEmpty && css`
    :not(:nth-child(2)) {
      opacity: 0;
    }
  `,
]);

export default Paragraph;
