import React from 'react';
import {
  AiFillSafetyCertificate, AiOutlineBulb, AiOutlineFile, AiOutlineFileDone, AiOutlineForm,
} from 'react-icons/ai';
import PropTypes from 'prop-types';
// eslint-disable-next-line
import tw, {styled, css} from 'twin.macro';
import Card from 'components/Card';
import {CHECKLIST_TYPE} from '../wizardConstants';

ChooseTypeScreen.propTypes = {
  setStep: PropTypes.func.isRequired,
  setChecklistType: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

function ChooseTypeScreen ({
  setStep, setChecklistType, closeModal,
}) {
  return (
    <>
      <List>
        <Card
          icon={<AiOutlineFileDone size={40} />}
          heading="Task checklist"
          onClick={() => {
            setChecklistType(CHECKLIST_TYPE.TASK);
            setStep((prev) => prev + 1);
          }}
        >
          Help someone to complete a task and avoid mistakes.
        </Card>
        <Card
          icon={<AiOutlineBulb size={40} />}
          heading="Training checklist"
          onClick={() => {
            setChecklistType(CHECKLIST_TYPE.TRAINING);
            setStep((prev) => prev + 2);
          }}
        >
          Help someone to learn and gain experience.
        </Card>
        <Card
          icon={<AiFillSafetyCertificate size={40} />}
          heading="Onboarding checklist"
          onClick={() => {
            setChecklistType(CHECKLIST_TYPE.ONBOARDING);
            setStep((prev) => prev + 2);
          }}
        >
          Help someone to get started in a new role.
        </Card>
        <Card
          icon={<AiOutlineForm size={40} />}
          heading="Form"
          onClick={() => {
            setChecklistType(CHECKLIST_TYPE.FORM);
            setStep((prev) => prev + 2);
          }}
        >
          A flexible form for collecting information.
        </Card>
        <Card
          icon={<AiOutlineFile size={40} />}
          heading="Document"
          onClick={closeModal}>
          A simple reference document or policy.
        </Card>
      </List>
      {/* <P tw="text-gray-600 text-center mt-8 text-sm">
      <button type="button" onClick={closeModal}>
      I just want to start with an empty page
      </button>
    </P> */}
    </>
  );
}

const List = styled.div((props) => [
  tw`
    flex
    flex-col
    gap-4
    mb-2
  `,
]);

export default ChooseTypeScreen;
