import {Editor, Transforms} from 'slate';
import {FORM_GROUP_INPUT_ELEMENT_TYPES} from '../constants';
import makeParagraph from '../factory/makeParagraph';

function makeSureFormGroupsHaveAnInput (editor, [entryNode, entryNodePath]) {
  if (entryNode.type !== 'label') {
    return false;
  }

  const [formGroupNode, formGroupNodePath] = Editor.parent(editor, entryNodePath);

  if (formGroupNode.type !== 'form-group') {
    // If a label is not in a form-group, it'll be handled by
    // makeSureNoOrphanTextInputLabels
    return false;
  }

  // Check if input exists. Input can be removed when cutting.
  const hasInput = formGroupNode.children.some((node) => FORM_GROUP_INPUT_ELEMENT_TYPES.includes(node.type));
  if (hasInput) {
    return false;
  }

  const {text} = entryNode.children[0];

  Editor.withoutNormalizing(editor, () => {
    Transforms.removeNodes(editor, {
      at: formGroupNodePath,
      match: (node) => node.type === 'form-group',
    });

    Transforms.insertNodes(editor, makeParagraph({text}), {
      at: formGroupNodePath,
    });
  });

  return true;
}

export default makeSureFormGroupsHaveAnInput;
