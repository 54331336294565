import React, {useContext} from 'react';
import {useDocument} from 'containers/Document/providers/DocumentProvider';
import {usePrintMode} from 'containers/PrintMode/providers/PrintModeProvider';
import PropTypes from 'prop-types';
import {ProcessStepContext} from '../ProcessStep';
import ProcessStepTitle from './ProcessStepTitle';

ProcessStepTitleContainer.propTypes = {
  attributes: PropTypes.shape({}).isRequired,
  children: PropTypes.node.isRequired,
};

function ProcessStepTitleContainer (props) {
  const {
    ...rest
  } = props;
  const {
    done,
    open,
    order,
    setOpen,
  } = useContext(ProcessStepContext);
  const {mode} = useDocument();
  const printMode = usePrintMode();

  return (
    <div>
      <ProcessStepTitle
        done={done}
        mode={mode}
        open={open}
        order={order}
        printMode={!!printMode}
        setOpen={setOpen}
        {...rest}
      />
    </div>
  );
}

export default ProcessStepTitleContainer;
