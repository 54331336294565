import React from 'react';
import PropTypes from 'prop-types';
import tw from 'twin.macro'; // eslint-disable-line no-unused-vars
import Pill from 'components/Pill';
import {CONTENT_PRIORITY, ITEM_TYPE} from 'am-constants';

ContentPriorityCellContent.propTypes = {
  itemId: PropTypes.number,
  itemTypeId: PropTypes.number.isRequired,
  contentPriority: PropTypes.string,
  contentDeadline: PropTypes.string,
};

ContentPriorityCellContent.defaultProps = {
};

const PAGE_ITEM_TYPES = [
  ITEM_TYPE.CHECKLIST,
  ITEM_TYPE.DOCUMENT,
  ITEM_TYPE.FORM,
];

const FRIENDLY_LABELS = {
  [CONTENT_PRIORITY.LOW]: 'Low',
  [CONTENT_PRIORITY.MEDIUM]: 'Medium',
  [CONTENT_PRIORITY.HIGH]: 'High',
  [CONTENT_PRIORITY.VERY_HIGH]: 'Very high',
};

function ContentPriorityCellContent (props) {
  const {
    itemId,
    itemTypeId,
    contentPriority,
    contentDeadline,
  } = props;

  if (!itemId) {
    return null;
  }

  if (!PAGE_ITEM_TYPES.includes(itemTypeId)) {
    return null;
  }

  if (!contentPriority) {
    return null;
  }

  const needsAttention = contentPriority === CONTENT_PRIORITY.VERY_HIGH && !contentDeadline;

  return (
    <Pill red={needsAttention}>{FRIENDLY_LABELS[contentPriority]}</Pill>
  );
}

export default ContentPriorityCellContent;
