import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import tw, {styled} from 'twin.macro';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';
import {navigate} from '@reach/router';
import {createGlobalStyle} from 'styled-components';
import {useTopbar} from 'providers/TopbarProvider';
import Button from './Button';
import Link from './Link';
import PageToolbar, {PageToolbarRight} from './PageToolbar';
import Heading1 from './Heading1';
import FlexSpacer from './FlexSpacer';

SettingsPage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

function SettingsPage (props) {
  const {
    children,
  } = props;
  const {setHideSearch, setPrintMode, setPrintModeSupport} = useTopbar();
  useEffect(() => {
    setHideSearch(false);
    setPrintMode(false);
    setPrintModeSupport(false);
  });

  useEffect(() => {
    document.body.style.minWidth = 640;

    return () => {
      document.body.style.minWidth = 'auto';
    };
  });

  const {urlName: workspaceUrlName} = useWorkspace();

  return <>
    <GlobalStyle />
    <PageToolbar dark>
        Workspace settings
      <PageToolbarRight>
        <Button link onClick={() => navigate(`/${workspaceUrlName}`)}>Exit</Button>
      </PageToolbarRight>
    </PageToolbar>
    <Container>
      <LeftNav>
        {/* <NavItem to={`/${workspaceUrlName}/settings`}>General settings</NavItem> */}
        <NavItem to={`/${workspaceUrlName}/team`}>Team members</NavItem>
        <NavItem to={`/${workspaceUrlName}/billing`}>Billing</NavItem>
      </LeftNav>
      <Content>
        {children}
      </Content>
    </Container>
  </>;
}

SettingsPageTitleBar.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export function SettingsPageTitleBar (props) {
  const {title, children} = props;

  return (
    <SettingsPageTitleBarContainer>
      <Heading1 tw="my-0">{title}</Heading1>
      <FlexSpacer />
      <div>{children}</div>
    </SettingsPageTitleBarContainer>
  );
}

const GlobalStyle = createGlobalStyle`
  body {
    min-width: 640px
  }
`;

const Container = styled.div((props) => [
  tw`
    flex
    min-h-screen
  `,
]);

const LeftNav = styled.div((props) => [
  tw`
    w-48
    bg-gray-50
    px-4
    py-4
  `,
]);

const NavItem = styled(Link)((props) => [
  tw`
    block
    py-1.5
    text-gray-500!
    hover:text-blue-300!
  `,
]);

const Content = styled.div((props) => [
  tw`
    flex-1
    px-10
    py-12
  `,
]);

const SettingsPageTitleBarContainer = styled.div((props) => [
  tw`
    flex
    items-center
    mb-6
  `,
]);

export default SettingsPage;
