import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';

ProcessStepMarkIncompleteButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  setDone: PropTypes.func.isRequired,
};

function ProcessStepMarkIncompleteButton (props) {
  const {setDone} = props;

  const markAsIncomplete = () => {
    setDone(false);
  };

  return <Button {...props} onClick={markAsIncomplete} />;
}

export default ProcessStepMarkIncompleteButton;
