import PropTypes from 'prop-types';
import {gql} from '@apollo/client';
import useMutation from 'hooks/useMutation';
import {useQueryClient} from '@tanstack/react-query';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';

const ITEM_MOVE = gql`
  mutation ITEM_MOVE($parentId: Int!, $itemRef: String!, $itemKey: String!) {
    itemMove(parentId: $parentId, itemRef: $itemRef, itemKey: $itemKey) {
      id
    }
  }
`;

useMoveItemMutation.propTypes = {
  parentId: PropTypes.number,
  itemRef: PropTypes.string,
  itemKey: PropTypes.string,
};

function useMoveItemMutation (props) {
  const {
    parentId,
    itemRef,
    itemKey,
  } = props;

  const queryClient = useQueryClient();
  const {urlName} = useWorkspace();

  const [onMoveItem, {loading, data, error}] = useMutation(ITEM_MOVE, {
    variables: {
      parentId,
      itemRef,
      itemKey,
    },
    refetchQueries: [
      'GetFolders',
      'GetItems',
      'GetItem',
      'GET_ITEM_WITH_DOCUMENT_SNAPSHOT',
      'GET_ITEM_WITH_DOCUMENT',
      'GetItemWithResponses',
      'GetWorkspaceItem',
    ],
    onCompleted: () => {
      queryClient.invalidateQueries([urlName, 'items']);
    },
  });

  return [onMoveItem, {
    loading,
    error,
    data,
  }];
}

export default useMoveItemMutation;
