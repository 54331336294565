export const SEARCH_RESULT_FIELDS = `
  ref
  key
  contentActionNeededId
  itemTypeId
  details
  name
  urlName
  publishedAt
  nameHighlight
  contentHighlight
`;

export default null;
