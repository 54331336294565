import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled} from 'twin.macro';
import {ITEM_TYPE, PERMISSIONS} from 'am-constants';
import Breadcrumbs from 'components/Breadcrumbs';
import P from 'components/P';
import Button from 'components/Button';
import List from 'components/List';
import checkIfItemIsFolder from 'utils/checkIfItemIsFolder';
import checkIfItemIsLink from 'utils/checkIfItemIsLink';
import checkIfItemIsPage from 'utils/checkIfItemIsPage';
import getUrlForItem from 'utils/getUrlForItem';
import getTagsForItem from 'utils/getTagsForItem';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';
import {navigate} from '@reach/router';
import {Spin} from 'antd';
import getDataTestIdForItem from 'utils/getDataTestIdForItem';
import getIconForItem from 'utils/getIconForItem';
import CustomPropTypes from 'utils/CustomPropTypes';
import FolderMenu from './FolderMenu';
import PublishedPageMenu from './PublishedPageMenu';
import UnpublishedPageMenu from './UnpublishedPageMenu';
import LinkMenu from './LinkMenu';

ItemList.propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
    ref: PropTypes.string.isRequired,
  })),
  emptyMessage: PropTypes.string.isRequired,
  isSearchError: PropTypes.bool.isRequired,
  isSearchLoading: PropTypes.bool.isRequired,
  item: CustomPropTypes.Item,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  workspaceUrlName: PropTypes.string.isRequired,
  searchString: PropTypes.string.isRequired,
  setFocusedItem: PropTypes.func.isRequired,
  setIsDeleteItemModalVisible: PropTypes.func.isRequired,
  setIsMoveFolderModalVisible: PropTypes.func.isRequired,
  setIsRenameFolderModalVisible: PropTypes.func.isRequired,
  setIsShareItemModalVisible: PropTypes.func.isRequired,
};

ItemList.defaultProps = {
  breadcrumbs: [],
  emptyMessage: 'This folder is empty',
};

function ItemList (props) {
  const {
    breadcrumbs,
    emptyMessage,
    isSearchError,
    isSearchLoading,
    item: currentItem,
    items,
    workspaceUrlName,
    searchString,
    setFocusedItem,
    setIsDeleteItemModalVisible,
    setIsMoveFolderModalVisible,
    setIsRenameFolderModalVisible,
    setIsShareItemModalVisible,
  } = props;

  const {ref: currentItemRef} = currentItem;

  const {workspaceItem, workspacePermissions} = useWorkspace();

  // If the user does not have access to the current workspace, they should not
  // see unpublished pages.
  // TODO: Update API to not return unpublished pages.
  const hideDraftPages = !workspacePermissions.includes(PERMISSIONS.VIEW_WORKSPACE);
  const topLevelAccessibleAncestor = workspaceItem || breadcrumbs[0] || currentItem;
  const showGlobalSearch = searchString && topLevelAccessibleAncestor.ref !== currentItemRef;
  let itemsBasedOnAccessToWorkspaceStatus = items;
  if (hideDraftPages) {
    itemsBasedOnAccessToWorkspaceStatus = items.filter((item) => item.itemTypeId !== ITEM_TYPE.DOCUMENT || item.publishedAt);
  }

  const showOptions = workspacePermissions.includes(PERMISSIONS.VIEW_WORKSPACE);

  const renderActionsForItem = (item) => {
    const isFolder = checkIfItemIsFolder(item);
    const isPage = checkIfItemIsPage(item);
    const isLink = checkIfItemIsLink(item);

    if (!showOptions) {
      return undefined;
    }

    if (isFolder) {
      // eslint-disable-next-line react/display-name
      return () => <FolderMenu
        item={item}
        setFocusedItem={setFocusedItem}
        setIsDeleteItemModalVisible={setIsDeleteItemModalVisible}
        setIsMoveFolderModalVisible={setIsMoveFolderModalVisible}
        setIsRenameFolderModalVisible={setIsRenameFolderModalVisible}
        setIsShareItemModalVisible={setIsShareItemModalVisible} />;
    }

    if (isLink) {
      // eslint-disable-next-line react/display-name
      return () => <LinkMenu
        item={item}
        setFocusedItem={setFocusedItem}
        setIsDeleteItemModalVisible={setIsDeleteItemModalVisible}
        setIsMoveFolderModalVisible={setIsMoveFolderModalVisible} />;
    }

    if (isPage && item.publishedAt) {
      // eslint-disable-next-line react/display-name
      return () => <PublishedPageMenu
        item={item}
        setFocusedItem={setFocusedItem}
        setIsDeleteItemModalVisible={setIsDeleteItemModalVisible}
        setIsMoveFolderModalVisible={setIsMoveFolderModalVisible}
        setIsShareItemModalVisible={setIsShareItemModalVisible}
        workspaceUrlName={workspaceUrlName} />;
    }

    if (item.itemTypeId === ITEM_TYPE.DOCUMENT && !item.publishedAt) {
      // eslint-disable-next-line react/display-name
      return () => <UnpublishedPageMenu
        item={item}
        setFocusedItem={setFocusedItem}
        setIsDeleteItemModalVisible={setIsDeleteItemModalVisible}
        setIsMoveFolderModalVisible={setIsMoveFolderModalVisible}
        workspaceUrlName={workspaceUrlName} />;
    }

    return undefined;
  };

  const getNameForItem = (item) => (item.name !== '' ? `${item.name}` : 'Untitled');

  const listItems = itemsBasedOnAccessToWorkspaceStatus.map((item) => ({
    icon: getIconForItem(item),
    id: item.ref,
    itemTypeId: item.itemTypeId, // Needed for sorting
    name: getNameForItem(item),
    renderActions: renderActionsForItem(item),
    tags: getTagsForItem(item),
    url: getUrlForItem(workspaceUrlName, item),
    dataTestId: getDataTestIdForItem(item),
  })).sort(compareItems);

  const showCreatePageMessage = workspacePermissions.includes(PERMISSIONS.VIEW_WORKSPACE);

  return ((
    <Directory>
      <Container>
        <Heading>
          <Breadcrumbs
            currentFolder={currentItem}
            items={breadcrumbs}
            isPageDisplayingSearchResults={Boolean(searchString)}
            workspaceUrlName={workspaceUrlName}
          />
        </Heading>
        <ItemListContainer>
          {isSearchLoading && (
            <EmptySpace>
              <Spin />
            </EmptySpace>
          )}
          {isSearchError && (
            <EmptySpace>
              <P>Something went wrong 😑</P>
              <Button sm onClick={() => {
                window.location.reload();
              }}>Retry search</Button>
            </EmptySpace>
          )}
          {!isSearchLoading && !isSearchError && listItems.length === 0 && searchString && (
            <EmptySpace>
              <P bottom>There's nothing here 🤭</P>
            </EmptySpace>
          )}
          {!isSearchLoading && listItems.length === 0 && !searchString && (
            <EmptySpace>
              <Large>{emptyMessage}</Large>
              {showCreatePageMessage && <>Click <strong>Create page</strong> to get started</>}
            </EmptySpace>
          )}
          {!isSearchLoading && (
            <List
              items={listItems}
            />
          )}
        </ItemListContainer>
        {showGlobalSearch && !isSearchError && (
          <div tw="text-center mt--4">
            <Button sm tw="mx-auto" onClick={() => {
              const url = checkIfItemIsFolder(topLevelAccessibleAncestor)
                ? `/${workspaceUrlName}/folders/${topLevelAccessibleAncestor.urlName}?q=${searchString}`
                : `/${workspaceUrlName}?q=${searchString}`;
              navigate(url);
            }}>Search all of {topLevelAccessibleAncestor.name}</Button>
          </div>
        )}
      </Container>
    </Directory>
  ));
}

function compareItems (a, b) {
  if (a.pin !== b.pin) {
    return a.pin ? 1 : -1;
  }

  if (a.itemTypeId === ITEM_TYPE.FOLDER && b.itemTypeId !== ITEM_TYPE.FOLDER) {
    return -1;
  }

  if (a.itemTypeId !== ITEM_TYPE.FOLDER && b.itemTypeId === ITEM_TYPE.FOLDER) {
    return 1;
  }

  return a.name.localeCompare(b.name);
}

const Container = styled.div((props) => [
  tw`
    max-w-4xl
    m-auto
    px-5
  `,
]);

const Heading = styled.div((props) => [
  tw`
    text-gray-400
    text-xs
    font-bold
    mb-2
  `,
]);

const Directory = styled.div((props) => [
  tw`
    pt-8
    bg-gray-50
    pb-12
  `,
]);

const EmptySpace = styled.div((props) => [
  tw`
    border-gray-100
    border-dotted
    border-2
    p-6
    py-24
    text-center
    text-gray-400
    text-sm
  `,
]);

const Large = styled.div((props) => [
  tw`
    text-xl
    text-gray-500
    mb-2
  `,
]);

const ItemListContainer = styled.ul((props) => [
  tw`
    mb-12
    bg-white
  `,
]);

export default ItemList;
