import React from 'react';
import PropTypes from 'prop-types';
import GridCellPopover from 'components/DataGrid/GridCellPopover';
import ContentPriorityCellPopover from './ContentPriorityCellPopover';
import ContentPriorityCellContent from './ContentPriorityCellContent';
import SavingCellContent from './SavingCellContent';

ContentPriorityCell.propTypes = {
  itemId: PropTypes.number,
  itemTypeId: PropTypes.number.isRequired,
  contentPriority: PropTypes.string,
  contentDeadline: PropTypes.string,
  onUpdateItem: PropTypes.func.isRequired,
  saving: PropTypes.bool,
};

ContentPriorityCell.defaultProps = {
  saving: false,
};

function ContentPriorityCell (props) {
  const {
    itemId,
    itemTypeId,
    contentPriority,
    contentDeadline,
    onUpdateItem,
    saving,
  } = props;

  const popover = (
    <ContentPriorityCellPopover
      contentPriority={contentPriority}
      onUpdateItem={onUpdateItem} />
  );

  return (
    <GridCellPopover content={popover}>
      <SavingCellContent saving={saving}>
        <ContentPriorityCellContent
          itemId={itemId}
          itemTypeId={itemTypeId}
          contentPriority={contentPriority}
          contentDeadline={contentDeadline}
        />
      </SavingCellContent>
    </GridCellPopover>
  );
}

export default ContentPriorityCell;
