import React, {useState} from 'react';
import tw, {styled} from 'twin.macro'; // eslint-disable-line no-unused-vars
import {
  AiOutlineCheck,
  AiOutlineGlobal, AiOutlineTeam, AiOutlineUser,
} from 'react-icons/ai';
import List from 'components/List';
import Button, {ButtonSpacer} from 'components/Button';
import InlineTooltip from 'components/InlineTooltip';
import Pill from 'components/Pill';
import PropTypes from 'prop-types';
import {BiCopy} from 'react-icons/bi';
import Input from 'components/Input';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';
import Error from 'components/Error';
import Link from 'components/Link';
import {ITEM_ROLE, SHARER_MODAL_OPTIONS} from 'am-constants';
import PermissionsSelect from './PermissionsSelect';
import useGetTeamMemberByEmailQuery from '../hooks/useGetTeamMemberByEmailQuery';

ItemSharerModalContent.propTypes = {
  hasItemSharerPermissions: PropTypes.bool,
  isItemPublic: PropTypes.bool,
  itemName: PropTypes.string.isRequired,
  itemUrl: PropTypes.string,
  noAccessMessage: PropTypes.node,
  sharingSettings: PropTypes.object.isRequired,
  setSharingSettings: PropTypes.func.isRequired,
};

function ItemSharerModalContent (props) {
  const {
    hasItemSharerPermissions,
    isItemPublic,
    itemName,
    itemUrl,
    noAccessMessage,
    sharingSettings,
    setSharingSettings,
  } = props;

  const {urlName: workspaceUrlName} = useWorkspace();
  const [isCopied, setIsCopied] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');

  const [
    getTeamMemberByEmailQuery,
    {loading, data},
  ] = useGetTeamMemberByEmailQuery();

  const doesEmailBelongToTeamMember = data && data.workspace.teamMembers.length > 0;
  const doesUserAlreadyHaveAccess = Boolean(sharingSettings.userRoleIds.find((userItemRole) => userItemRole.user.email === emailAddress));
  const shouldShowAddUserError = (doesEmailBelongToTeamMember === false || doesUserAlreadyHaveAccess) && !loading;
  const renderAddUserError = () => {
    if (doesEmailBelongToTeamMember === false) {
      return (
        <Error arrow>
          This email address does not belong to a user in this workspace. <Link to={`/${workspaceUrlName}/team`}>Manage team members</Link>
        </Error>);
    }
    if (doesUserAlreadyHaveAccess) {
      return (
        <Error arrow> This user already has access </Error>
      );
    }

    return null;
  };

  const checkAndAddUserItem = () => getTeamMemberByEmailQuery({
    variables: {workspaceUrlName, emails: [emailAddress]},
    onCompleted: function addNewTeamMemberDefaultSharingSettings ({workspace}) {
      if (doesUserAlreadyHaveAccess) {
        return;
      }
      if (workspace.teamMembers.length !== 0) {
        const {
          name, email, personId,
        } = workspace.teamMembers[0];

        setSharingSettings({
          ...sharingSettings,
          userRoleIds: [
            ...sharingSettings.userRoleIds,
            {
              itemRoleIds: [ITEM_ROLE.VIEWER],
              user: {
                id: personId,
                name,
                email,
              },
            },
          ],
        });
        setEmailAddress('');
      }
    },
  });

  const fullItemUrl = window.location.hostname.includes('localhost')
    ? `http://a.m.localhost:3001${itemUrl}`
    : `${window.location.origin}${itemUrl}`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(fullItemUrl);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2500);
  };

  const renderActions = (args) => (
    <div tw="w-56">
      <PermissionsSelect
        sharingSettings={sharingSettings}
        setSharingSettings={setSharingSettings}
        {...args} />
    </div>
  );

  const renderDescription = ({email, invited, guest} = {}) => (
    <div>
      {/* Based on workspace */}
      {email}
      {invited && <> • <InlineTooltip title="This user has been invited to join the workspace but has not yet signed up." placement="bottom">Inviting</InlineTooltip></>}
      {guest && <>
        <Pill yellow tw="ml-2">Guest</Pill>
      </>}
    </div>
  );

  const handleEnterPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      checkAndAddUserItem();
    }
  };

  const userItems = sharingSettings.userRoleIds.map(({user, itemRoleIds}) => ({
    name: user.name,
    dataTestId: user.name.replace(/\s+/g, '-').toLowerCase(),
    disabled: !hasItemSharerPermissions,
    renderActions: () => renderActions({
      disabled: !hasItemSharerPermissions,
      initialValue: getInitialValueFromRoles(itemRoleIds),
      roleTypeKey: 'userRoleIds',
      userId: user.id,
    }),
    renderDescription: () => renderDescription({
      email: user.email,
    }),
    showActionsWithoutHover: true,
  }));

  const items = [
    {
      icon: AiOutlineTeam,
      name: 'All workspace members',
      dataTestId: 'all-workspace-members',
      disabled: !hasItemSharerPermissions,
      renderActions: () => renderActions({
        disabled: !hasItemSharerPermissions,
        initialValue: getInitialValueFromRoles(sharingSettings.allMembersRoleIds),
        roleTypeKey: 'allMembersRoleIds',
      }),
      renderDescription,
      showActionsWithoutHover: true,
    },
    {
      icon: AiOutlineGlobal,
      name: 'Everyone on the internet',
      dataTestId: 'everyone-on-the-internet',
      disabled: !hasItemSharerPermissions,
      renderActions: () => renderActions({
        disabled: !hasItemSharerPermissions,
        initialValue: isItemPublic ? [1] : [],
        roleTypeKey: 'publicRoleIds',
      }),
      showActionsWithoutHover: true,
    },
    ...userItems,
  ];

  return (<>
    <div tw="mb-5">You can share <strong>{itemName}</strong> with anyone listed below.
      <ButtonSpacer />
      <Button
        gray
        inline
        onClick={copyToClipboard}
        type="button">
        {isCopied ? renderCopied() : renderNotCopied()}
      </Button>
    </div>
    {!hasItemSharerPermissions && noAccessMessage}
    {/* <RestoreBox tw="flex items-center">
      <P bottom>
        These settings are not being inherited from the Marketing folder.
      </P>
      <ButtonSpacer />
      <Button sm primary tw="whitespace-nowrap">Restore settings</Button>
    </RestoreBox> */}
    <div tw="mt--0">
      <List items={items} icon={AiOutlineUser} />
    </div>
    {hasItemSharerPermissions &&
      <div tw="flex pt-3 px-3">
        <div tw="flex-1">
          <Input
            sm
            placeholder="Email address"
            value={emailAddress}
            onChange={(e) => setEmailAddress(e.target.value)}
            onKeyDown={handleEnterPress}
          />
        </div>
        <ButtonSpacer />
        <Button
          type="button"
          disabled={loading}
          green
          tw="h-10"
          onClick={checkAndAddUserItem}>Add</Button>
      </div>
    }
    {shouldShowAddUserError && (
      <div tw="flex px-3">
        <div tw="flex-1">
          {renderAddUserError()}
        </div>
      </div>
    )}
  </>
  );
}
const renderCopied = () => (<><AiOutlineCheck tw="inline" /> Link copied!</>);
const renderNotCopied = () => (<><BiCopy tw="inline" /> Copy link</>);

function getInitialValueFromRoles (roles) {
  const {
    VIEW_AND_USE,
    EDIT,
    VIEW_DATA,
    SHARE,
  } = SHARER_MODAL_OPTIONS;

  const initialValues = [];

  if (roles.includes(ITEM_ROLE.ADMIN)) {
    return [
      VIEW_AND_USE,
      EDIT,
      VIEW_DATA,
      SHARE,
    ];
  }
  if (roles.includes(ITEM_ROLE.ANALYST)) {
    initialValues.push(VIEW_DATA);
  }
  if (roles.includes(ITEM_ROLE.EDITOR)) {
    return [
      ...initialValues,
      VIEW_AND_USE,
      EDIT,
    ];
  }
  if (roles.includes(ITEM_ROLE.VIEWER)) {
    return [
      ...initialValues,
      VIEW_AND_USE,
    ];
  }

  return initialValues;
}

// const RestoreBox = styled.div((props) => [
//   tw`
//     bg-blue-50
//     p-4
//     text-sm
//     mb-6
//   `,
// ]);

export default ItemSharerModalContent;
