import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import useRenameFolderMutation from './hooks/useRenameFolderMutation';
import FolderRenamerModal from './components/FolderRenamerModal';

FolderRenamerContainer.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  setIsVisible: PropTypes.func.isRequired,
  itemRef: PropTypes.string,
  itemKey: PropTypes.string,
  currentFolderName: PropTypes.string,
};

function FolderRenamerContainer (props) {
  const {
    itemRef,
    itemKey,
    isVisible,
    setIsVisible,
    currentFolderName,
  } = props;

  const [inputValue, setInputValue] = useState(currentFolderName);

  useEffect(() => {
    setInputValue(currentFolderName);
  }, [currentFolderName]);

  const closeModal = () => {
    setIsVisible(false);
    setInputValue(currentFolderName);
  };

  const [onRenameFolder] = useRenameFolderMutation({
    newName: inputValue, itemRef, itemKey,
  });

  const onSubmit = (event) => {
    event.preventDefault();
    onRenameFolder();
    setIsVisible(false);
  };

  const onChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <FolderRenamerModal
      isVisible={isVisible}
      onChange={onChange}
      onSubmit={onSubmit}
      inputValue={inputValue}
      closeModal={closeModal}
    />
  );
}

export default FolderRenamerContainer;
