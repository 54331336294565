import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled, css} from 'twin.macro';
import {createGlobalStyle} from 'styled-components';
import {tailwind} from 'am-config';
import logo from 'components/airmanual-logo.svg';
const {colors} = tailwind.theme;

SimpleFormPage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  twoColumn: PropTypes.bool,
};

SimpleFormPage.defaultProps = {
  twoColumn: false,
};

function SimpleFormPage (props) {
  const {
    children,
    twoColumn,
  } = props;

  return <>
    <Header>
      <img src={logo} style={{height: 36}} />
    </Header>
    <BodyStyle />
    <NarrowPageContainer
      twoColumn={twoColumn}>
      {children}
    </NarrowPageContainer>
  </>;
}

const BodyStyle = createGlobalStyle`
  body {
    @media (min-width: 640px) {
      background-color: ${colors.gray[50]} !important;
    }
  }
`;

const Header = styled.div((props) => [
  tw`
    p-6
    sm:p-8
    max-w-5xl
    m-auto
    mb-4
    sm:mb-16
  `,
]);

const NarrowPageContainer = styled.div((props) => [
  tw`
    m-auto
    pb-32
  `,
  props.twoColumn && css`
    width: 54rem;
  `,
]);

export default SimpleFormPage;
