import React from 'react';
import PropTypes from 'prop-types';
import Button, {ButtonSpacer} from 'components/Button';
import Modal from 'components/Modal';
import Input from 'components/Input';
import FormGroup from 'components/FormGroup';
import Label from 'components/Label';
import Select, {Option} from 'components/Select';

TeamMemberAdderModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  handleEmailChange: PropTypes.func.isRequired,
  handleRoleChange: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  renderError: PropTypes.func,
  role: PropTypes.number.isRequired,
};

function TeamMemberAdderModal (props) {
  const {
    closeModal,
    email,
    handleEmailChange,
    handleRoleChange,
    isVisible,
    onSubmit,
    renderError,
    role,
  } = props;

  return (
    <Modal
      footer={<>
        <Button type="button" link onClick={closeModal}>Cancel</Button>
        <ButtonSpacer />
        <Button type="submit" primary value="Submit" data-test-id="add_member_button">Add</Button>
      </>}
      onClose={closeModal}
      onSubmit={onSubmit}
      renderError={renderError}
      errorTitle={'Could not add team member'}
      title="Add team member"
      visible={isVisible}
    >
      <FormGroup>
        <Label>Email address</Label>
        <Input
          autoFocus
          data-test-id="email_input"
          maxLength={500}
          onChange={handleEmailChange}
          required
          type='email'
          value={email}
        />
      </FormGroup>
      <FormGroup>
        <Label>Role</Label>
        <Select
          onChange={handleRoleChange}
          value={role}
        >
          <Option value={2}>Member</Option>
          <Option value={1}>Admin</Option>
        </Select>
      </FormGroup>
    </Modal>
  );
}

export default TeamMemberAdderModal;
