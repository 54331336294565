import React, {useState} from 'react';
import PropTypes from 'prop-types';
import P from 'components/P';
import useCreateItemMutation from 'hooks/useCreateItemMutation';
import {ITEM_TYPE} from 'am-constants';
import LinkCreatorModal from './components/LinkCreatorModal';

LinkCreatorContainer.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  setIsVisible: PropTypes.func.isRequired,
  parentItem: PropTypes.shape({
    id: PropTypes.number.isRequired,
    key: PropTypes.string.isRequired,
    ref: PropTypes.string.isRequired,
  }).isRequired,
};

function LinkCreatorContainer (props) {
  const {
    isVisible,
    setIsVisible,
    parentItem,
  } = props;

  const [nameValue, setNameValue] = useState('');
  const [urlValue, setUrlValue] = useState('');
  const closeModal = () => {
    setNameValue('');
    setUrlValue('');
    setIsVisible(false);
  };

  const {
    mutate: createLink,
    // data,
    isError,
  } = useCreateItemMutation({});

  const onSubmit = async (event) => {
    event.preventDefault();
    createLink({
      name: nameValue,
      itemTypeId: ITEM_TYPE.LINK,
      parentFolderId: parentItem.id,
      parentId: parentItem.id,
      parentItemRef: parentItem.ref,
      parentItemKey: parentItem.key,
      url: urlValue,
    });
    setNameValue('');
    setUrlValue('');
    setIsVisible(false);
  };

  const onNameChange = (event) => {
    setNameValue(event.target.value);
  };

  const onUrlChange = (event) => {
    setUrlValue(event.target.value);
  };

  const renderError = () => (
    <P bottom>Please check your connection and try again.</P>
  );

  return (
    <LinkCreatorModal
      isVisible={isVisible}
      onNameChange={onNameChange}
      onUrlChange={onUrlChange}
      onSubmit={onSubmit}
      nameValue={nameValue}
      urlValue={urlValue}
      closeModal={closeModal}
      renderError={isError ? renderError : undefined}
    />
  );
}

export default LinkCreatorContainer;
