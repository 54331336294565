import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import Printable from 'containers/PrintMode/components/Printable';
import PrintMode from 'containers/PrintMode';
import debounce from 'utils/debounce';
import useSearchResultsQuery from 'containers/WorkspaceExplorer/hooks/useSearchResultsQuery';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';
import getUrlForItem from 'utils/getUrlForItem';
import getTagsForItem from 'utils/getTagsForItem';
import getDataTestIdForItem from 'utils/getDataTestIdForItem';
import getIconForItem from 'utils/getIconForItem';
import {useTopbar} from 'providers/TopbarProvider';
import {useItem} from 'providers/ItemProvider';
import WorkspacePageLayout from './components/WorkspacePageLayout';

WorkspacePage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

function WorkspacePage (props) {
  const {
    children,
  } = props;

  const {
    urlName: workspaceUrlName, featureFlags,
  } = useWorkspace();

  const {
    printMode,
    printModeSupport,
    hideSearch,
  } = useTopbar();

  const [searchString, setSearchString] = useState('');
  const [numResults, setNumResults] = useState(10);
  const [isAboutToSearch, setIsAboutToSearch] = useState(false);
  const search = (string) => {
    setSearchString(string);
    setIsAboutToSearch(false);
  };

  const debouncedSearch = useMemo(() => debounce(search, 500), []);
  const onSearch = (string) => {
    setNumResults(10);
    setIsAboutToSearch(true);
    debouncedSearch(string);
  };

  const {item} = useItem();

  const {
    data: searchResults,
    isLoading: searchResultsLoading,
    error: searchResultsError,
    refetch,
  } = useSearchResultsQuery({
    enabled: !!item,
    workspaceUrlName,
    searchString,
    ref: item?.ref,
    key: item?.key,
    numResults,
  });

  useEffect(() => {
    if (numResults > 10) {
      refetch();
    }
  }, [numResults, refetch]);

  const highlightSearchTerms = (highlight) => {
    const containsHighlight = highlight && highlight.includes('<>');
    if (!containsHighlight) {
      return undefined;
    }

    const highlightParts = highlight.split('<>');
    const description = highlightParts.map((part, index) => {
      if (index % 2 === 0) {
        return <span key={index}>{part}</span>;
      }

      return <strong key={index}>{part}</strong>;
    });

    return (
      <div>
        {description}
      </div>
    );
  };

  const showMoreResults = () => {
    setNumResults(numResults + 20);
  };

  const searchResultsAsListItems = searchResults && searchResults.map((searchItem) => ({
    icon: getIconForItem(searchItem),
    renderName: () => highlightSearchTerms(searchItem.nameHighlight) || searchItem.name || 'Untitled',
    renderDescription: () => highlightSearchTerms(searchItem.contentHighlight),
    url: getUrlForItem(workspaceUrlName, searchItem),
    dataTestId: getDataTestIdForItem(searchItem),
    tags: getTagsForItem(searchItem),
  }));

  if (printMode) {
    return <PrintMode>{children}</PrintMode>;
  }

  const isShowMoreVisible = searchResultsAsListItems?.length === numResults && featureFlags.full_text_search;

  if (printModeSupport) {
    return (
      <Printable>
        <WorkspacePageLayout
          hideSearch={hideSearch}
          isShowMoreVisible={isShowMoreVisible}
          onSearch={onSearch}
          retrySearch={refetch}
          showMoreResults={showMoreResults}
          searchError={searchResultsError}
          searchLoading={isAboutToSearch || searchResultsLoading}
          searchResults={searchResultsAsListItems}>
          {children}
        </WorkspacePageLayout>
      </Printable>
    );
  }

  return (
    <WorkspacePageLayout
      hideSearch={hideSearch}
      isShowMoreVisible={searchResultsAsListItems?.length === numResults}
      onSearch={onSearch}
      retrySearch={refetch}
      showMoreResults={showMoreResults}
      searchError={searchResultsError}
      searchLoading={isAboutToSearch || searchResultsLoading}
      searchResults={searchResultsAsListItems}>
      {children}
    </WorkspacePageLayout>
  );
}

export default WorkspacePage;
