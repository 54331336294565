import PropTypes from 'prop-types';
import {useQuery} from '@tanstack/react-query';
import {gql} from 'graphql-request';
import graphQLClient from 'utils/graphQLClient';

const query = gql`
  query GetWorkspaceSubscription($workspaceUrlName: String!) {
    workspace(workspaceUrlName: $workspaceUrlName) {
      subscription {
        subscriptionTypeId
        endsAt
        isFreeTrial
      }
    }
  }
`;

useWorkspaceSubscriptionQuery.propTypes = {
  workspaceUrlName: PropTypes.string,
};

function useWorkspaceSubscriptionQuery ({workspaceUrlName}) {
  const cacheKey = [workspaceUrlName, 'subscription'];

  const variables = {
    workspaceUrlName,
  };

  const queryFn = async () => {
    const data = await graphQLClient.request(query, variables);

    return data;
  };

  const options = {};

  return useQuery(
    cacheKey,
    queryFn,
    options
  );
}

export default useWorkspaceSubscriptionQuery;
