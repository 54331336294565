import React, {useState} from 'react';
import {Helmet} from 'react-helmet';
import FormGroup from 'components/FormGroup';
import Label from 'components/Label';
import PasswordInput from 'components/PasswordInput';
import P from 'components/P';
import Button from 'components/Button';
import SimpleFormPage from 'components/SimpleFormPage';
import SimpleFormContainer from 'components/SimpleFormContainer';
import SimpleFormHeading from 'components/SimpleFormHeading';
import PropTypes from 'prop-types';
import Error from 'components/Error';

ResetPasswordChooserPage.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  error: PropTypes.object,
  loading: PropTypes.bool.isRequired,
};

function ResetPasswordChooserPage (props) {
  const {
    resetPassword,
    error,
    loading,
  } = props;

  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const onSubmit = (event) => {
    event.preventDefault();
    resetPassword(event.target.elements[0].value);
  };

  return <>
    <Helmet>
      <title>Choose a new password</title>
      <meta name="referrer" content="no-referrer" />
    </Helmet>
    <SimpleFormPage>
      <form onSubmit={onSubmit}>
        <SimpleFormContainer>
          <SimpleFormHeading>Choose a new password</SimpleFormHeading>
          <P>
            Use a nice and secure password 🔒
          </P>
          <FormGroup>
            <Label>
              Password
            </Label>
            <PasswordInput onValidStateChange={setIsPasswordValid} data-test-id="new-password" />
          </FormGroup>
          <Button primary lg fullwidth disabled={loading || !isPasswordValid}>Update password</Button>
          {error && !loading && (
            <Error arrow>{error.message}</Error>
          )}
        </SimpleFormContainer>
      </form>
    </SimpleFormPage>
  </>;
}

export default ResetPasswordChooserPage;
