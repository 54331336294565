import PropTypes from 'prop-types';
import {gql} from '@apollo/client';
import useMutation from 'hooks/useMutation';
import {useCallback, useRef} from 'react';
import throttle from 'lodash.throttle';
import debounce from 'utils/debounce';
import getIsAndroid from 'utils/getIsAndroid';

const RESPONSE_UPDATE = gql`
  mutation RESPONSE_UPDATE($documentVersion: Int!, $inputId: String!, $responseRef: String!, $responseKey: String!, $value: JSON!) {
    responseUpdate(
      documentVersion: $documentVersion,
      inputId: $inputId,
      responseRef: $responseRef,
      responseKey: $responseKey,
      value: $value
    ) {
      id
    }
  }
`;

useResponseUpdateMutation.propTypes = {
  documentVersion: PropTypes.number.isRequired,
  inputId: PropTypes.string.isRequired,
  onCompleted: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  responseRef: PropTypes.string.isRequired,
  responseKey: PropTypes.string.isRequired,
};

function useResponseUpdateMutation (props) {
  const {
    documentVersion,
    inputId,
    onCompleted,
    onError,
    responseRef,
    responseKey,
  } = props;

  const [onUpdate, {loading, error, data}] = useMutation(RESPONSE_UPDATE, {
    onCompleted,
    onError,
    variables: {
      documentVersion,
      inputId,
      responseRef,
      responseKey,
    },
  });

  const errorRefContainer = useRef(null);

  errorRefContainer.current = error;

  const isAndroid = getIsAndroid();

  const throttledUpdate = useCallback(throttle(onUpdate, 1000), []);
  const debouncedUpdate = useCallback(debounce(onUpdate, 3000), []);

  // Throttling on some android devices can cause words to be typed twice. This
  // is due to the fact that soft inputs (such as from virtual keyboards), are
  // not guaranteed to generate key events.
  // Source: https://github.com/ianstormtaylor/slate/issues/2062
  //
  // This clashes with debounced/throttled updates. Sometimes, when a debounced
  // update is made at the very second a key event is triggered that contains
  // many characters, that update will result in a double typed word.
  // Source: https://github.com/ianstormtaylor/slate/issues/5130
  //
  // To reduce this risk, we debounce with a 3 second delay on android. While
  // this introduces a small risk of data not being saved if the user does not
  // stop typing for more than 3 seconds, it means that there is a much less
  // frequent (though not completely eliminated) risk of double typing.
  const returnedUpdate = isAndroid ? debouncedUpdate : throttledUpdate;

  return [returnedUpdate, {
    updating: loading,
    error,
    updated: !!data,
  }];
}

export default useResponseUpdateMutation;
