import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {CONTENT_ACTION_NEEDED} from 'am-constants';
import GridCellPopover from 'components/DataGrid/GridCellPopover';
import tw from 'twin.macro'; // eslint-disable-line no-unused-vars
import ContentActionNeededCellPopover from './ContentActionNeededCellPopover';
import ContentActionNeededCellContent from './ContentActionNeededCellContent';

ContentActionNeededCell.propTypes = {
  contentActionNeededId: PropTypes.oneOf([
    CONTENT_ACTION_NEEDED.NEEDS_CONTENT,
    CONTENT_ACTION_NEEDED.NEEDS_UPDATES,
    CONTENT_ACTION_NEEDED.NEEDS_REVIEW,
  ]),
  contentDescription: PropTypes.arrayOf(PropTypes.shape({})),
  itemUrlName: PropTypes.string,
  onUpdateItem: PropTypes.func.isRequired,
  setTriggerPopoverOpen: PropTypes.func.isRequired,
  triggerPopoverOpen: PropTypes.bool.isRequired,
};

ContentActionNeededCell.defaultProps = {
};

function ContentActionNeededCell (props) {
  const {
    contentActionNeededId,
    contentDescription,
    itemUrlName,
    onUpdateItem,
    setTriggerPopoverOpen,
    triggerPopoverOpen,
  } = props;

  const popover = useMemo(() => (
    <ContentActionNeededCellPopover
      contentActionNeededId={contentActionNeededId}
      contentDescription={contentDescription}
      itemUrlName={itemUrlName}
      onUpdateItem={onUpdateItem}
    />
  ), [
    contentActionNeededId,
    contentDescription,
    itemUrlName,
    onUpdateItem,
  ]);

  return (
    <div tw="w-full h-full" data-test-id={'action-needed-cell'}>
      <GridCellPopover
        content={popover}
        setTriggerPopoverOpen={setTriggerPopoverOpen}
        triggerPopoverOpen={triggerPopoverOpen}>
        <ContentActionNeededCellContent
          contentActionNeededId={contentActionNeededId}
          contentDescription={contentDescription}
        />
      </GridCellPopover>
    </div>
  );
}

export default React.memo(ContentActionNeededCell);
