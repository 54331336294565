const {useEffect, useRef, useState} = require('react');

// With thanks to https://usehooks.com/useHover/
function useHover () {
  const [value, setValue] = useState(false);
  const ref = useRef(null);
  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);

  useEffect(
    () => {
      const node = ref.current;

      if (!node) {
        return () => {};
      }

      node.addEventListener('mouseover', handleMouseOver);
      node.addEventListener('mouseout', handleMouseOut);
      return () => {
        node.removeEventListener('mouseover', handleMouseOver);
        node.removeEventListener('mouseout', handleMouseOut);
      };
    },
    [ref.current] // Recall only if ref changes
  );

  return [ref, value];
}

export default useHover;
