const {Transforms, Editor} = require('slate');
const {ReactEditor} = require('slate-react');

function selectEndOfEditor (editor) {
  Transforms.select(
    editor,
    Editor.end(editor, [])
  );
  ReactEditor.focus(editor);
}

module.exports = selectEndOfEditor;
