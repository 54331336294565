import React from 'react';
import PropTypes from 'prop-types';

const FormGroupContext = React.createContext();

FormGroupProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  submissionMode: PropTypes.bool.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.array,
    PropTypes.shape({}),
  ]),
};

function FormGroupProvider (props) {
  const {
    children,
    onChange,
    readOnly,
    submissionMode,
    value,
  } = props;

  const context = [
    onChange,
    {
      readOnly,
      submissionMode,
      value,
    },
  ];

  return <>
    <FormGroupContext.Provider value={context}>
      {children}
    </FormGroupContext.Provider>
  </>;
}

export function useFormGroup () {
  const context = React.useContext(FormGroupContext);

  if (context === undefined) {
    throw new Error('useFormGroup must be used within a FormGroupProvider');
  }

  return context;
}

export default FormGroupProvider;
