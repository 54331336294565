import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import tw, {css, styled} from 'twin.macro';
import {motion} from 'framer-motion';
import {ButtonSpacer} from 'components/Button';
import ProcessStepDoneButton from './ProcessStepDoneButton';
import ProcessStepMarkIncompleteButton from './ProcessStepMarkIncompleteButton';
import ProcessStepNextButton from './ProcessStepNextButton';

ProcessStepContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  done: PropTypes.bool.isRequired,
  editable: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  openNext: PropTypes.func,
  printMode: PropTypes.bool.isRequired,
  setDone: PropTypes.func,
  setOpen: PropTypes.func.isRequired,
  submitted: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
};

function ProcessStepContent (props) {
  const {
    children,
    done,
    editable,
    mode,
    open,
    openNext,
    printMode,
    setDone,
    setOpen,
    submitted,
    id,
  } = props;

  const variants = {
    open: {
      height: 'auto',
    },
    closed: {
      height: 0,
    },
  };

  const wrapper = useRef(null);
  const height = wrapper.current ? wrapper.current.offsetHeight : 0;
  // Note: Adjusting the minimum duration for this animation may cause the warnings
  // to appear in the wrong location. Currently they are moved after 305ms to allow
  // this animation to complete. If the duration is changed, adjust the timeout in
  // the code that repositions warnings.
  const duration = Math.min(0.3, 0.15 + (height / 50) * 0.02);

  return (
    <div
      contentEditable={editable}
      suppressContentEditableWarning>
      <motion.div
        animate={open || printMode ? 'open' : 'closed'}
        variants={variants}
        initial={false}
        transition={{duration}}
        style={{overflow: 'auto'}}
      >
        <Wrapper ref={wrapper} id={`step-content--${id}`}
        >
          <div tw="pt-1" >{children}</div>
          {!submitted && !printMode && <ProcessStepActionBar contentEditable={false}>
            {mode === 'view' && (
              <div tw="flex">
                <ProcessStepNextButton openNext={openNext}>Next</ProcessStepNextButton>
              </div>
            )}
            {mode === 'edit' && (
              <div tw="flex">
                <ProcessStepNextButton openNext={openNext}>Next</ProcessStepNextButton>
              </div>
            )}
            {mode === 'use' && !done && (
              <div tw="flex">
                <ProcessStepDoneButton primary setDone={setDone} setOpen={setOpen}>Done</ProcessStepDoneButton>
                <ButtonSpacer />
                {/* Not sure why primary={false} is required! */}
                <ProcessStepNextButton primary={false} openNext={openNext}>Skip step</ProcessStepNextButton>
              </div>
            )}
            {mode === 'use' && done && <ProcessStepMarkIncompleteButton setDone={setDone} setOpen={setOpen} style={{opacity: open ? 1 : 0}}>Mark incomplete</ProcessStepMarkIncompleteButton>}
          </ProcessStepActionBar>}
        </Wrapper>
      </motion.div>
    </div>
  );
}

const Wrapper = styled.div((props) => [
  tw`
    pr-4
    pl-4
    pb-4
    max-w-full
    focus:outline-none
  `,
  css`
    @media (max-width: 640px) {
      padding-left: 14px;
      padding-right: 14px;
      padding-top: 8px;
    }
  `,
]);

const ProcessStepActionBar = styled.div((props) => [
  tw`
    clear-both
    pt-8
    select-none
  `,
]);

export default React.memo(ProcessStepContent);
