import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled} from 'twin.macro';

Avatar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Avatar.defaultProps = {
};

function Avatar (props) {
  const {
    children,
  } = props;

  return (
    <Wrapper>
      {children}
    </Wrapper>
  );
}

const Wrapper = styled.div((props) => [
  tw`
    bg-gray-100
    rounded-full
    text-2xs
    font-medium
    w-8
    h-8
    flex
    items-center
    justify-center
  `,
]);

export default Avatar;
