import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';
import {useTopbar} from 'providers/TopbarProvider';
import DocumentViewer from './components/DocumentViewer';

DocumentViewerContainer.propTypes = {
  document: PropTypes.object,
  printMode: PropTypes.bool,
};

DocumentViewerContainer.defaultProps = {
  printMode: false,
};

function DocumentViewerContainer (props) {
  const {document, printMode} = props;
  const {urlName: workspaceUrlName} = useWorkspace();

  const {setPrintMode} = useTopbar();
  useEffect(() => {
    setPrintMode(printMode);
  });

  const content = document?.documentSnapshot?.content;

  const notPublished = !content;
  if (notPublished) {
    return <p>This page has not been published</p>;
  }

  return (
    <div data-test-id="document-viewer">
      <DocumentViewer
        content={content}
        printMode={printMode}
        workspaceUrlName={workspaceUrlName}
      />
    </div>
  );
}

export default DocumentViewerContainer;
