import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import tw, {styled, css} from 'twin.macro';
import colors from 'utils/colors';

GridFrozenWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  isFrozen: PropTypes.bool,
  noShadow: PropTypes.bool,
};

GridFrozenWrapper.defaultProps = {
  isFrozen: false,
  noShadow: false,
};

function GridFrozenWrapper (props) {
  const {
    children,
    isFrozen,
    noShadow,
  } = props;

  if (!isFrozen) {
    return children;
  }

  const ref = useRef(null);
  const [offsetLeft, setOffsetLeft] = useState(0);
  const [offsetRight, setOffsetRight] = useState(0);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    if (offsetLeft && offsetRight) {
      return;
    }

    const {left, right} = ref.current.getBoundingClientRect();
    setOffsetLeft(left);
    setOffsetRight(right);
  }, []);

  React.useEffect(() => {
    window.addEventListener('resize', () => {
      if (!ref.current) {
        return;
      }

      const {left, right} = ref.current.getBoundingClientRect();
      setOffsetLeft(left);
      setOffsetRight(right);
    }, false);
  }, []);

  return (
    <>
      <Wrapper
        ref={ref}
        offset={offsetLeft}>
        {children}
      </Wrapper>
      {!noShadow && <>
        <ShadowWrapper offset={offsetRight}>
          <Shadow>
            &nbsp;
          </Shadow>
        </ShadowWrapper>
        {/*
        The ShadowCover is not sticky and covers the ShadowWrapper until the
        user starts scrolling.
        */}
        <ShadowCover offset={offsetRight}>
          &nbsp;
        </ShadowCover>
      </>}
    </>
  );
}

const Wrapper = styled.div((props) => [
  tw`
    h-full
    sticky
    flex
    z-10
    bg-gray-50
  `,
  css`
    left: ${props.offset}px;
  `,
]);

const ShadowWrapper = styled.div((props) => [
  tw`
    sticky
  `,
  css`
    z-index: 8;
    height: calc(100% + 2px);
    margin-top: -1px;
    left: ${props.offset}px;
  `,
]);

const Shadow = styled.div((props) => [
  tw`
    ml--1
    w-1
    h-full
  `,
  css`
    box-shadow: 3px 0 3px 0px ${colors.gray[100]};
  `,
]);

const ShadowCover = styled.div((props) => [
  tw`
    flex-none
    bg-gray-50
    w-2
    ml--px
  `,
  css`
    z-index: 9;
    height: calc(100% + 2px);
    margin-top: -1px;
    margin-right: -7px;
  `,
]);

export default React.memo(GridFrozenWrapper);
