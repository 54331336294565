import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import tw, {styled, css} from 'twin.macro';
import {ReactEditor, useSlateStatic} from 'slate-react';
import {tailwind} from 'am-config';
import Tooltip from 'components/Tooltip';
import throttle from 'lodash.throttle';
import {Portal} from 'react-portal';
import changeColor from '../utils/changeColor';
import Toolbar from './Toolbar';

const {theme: {colors}} = tailwind;

HelpBlockToolbar.propTypes = {
  helpBlockNode: PropTypes.shape({
    getBoundingClientRect: PropTypes.func,
  }).isRequired,
};

function HelpBlockToolbar (props) {
  const {helpBlockNode} = props;

  const [position, setPosition] = useState({});
  const editor = useSlateStatic();

  const changeHelpBlockColor = (color) => {
    ReactEditor.focus(editor);
    changeColor(editor, color);
  };

  const menuItems = [
    {
      title: 'Make blue',
      onClick: () => changeHelpBlockColor('blue'),
      color: colors.blue[50],
    },
    {
      title: 'Make yellow',
      onClick: () => changeHelpBlockColor('yellow'),
      color: colors.yellow[100],
    },
    {
      title: 'Make red',
      onClick: () => changeHelpBlockColor('red'),
      color: colors.red[100],
    },
    {
      title: 'Make green',
      onClick: () => changeHelpBlockColor('green'),
      color: colors.green[100],
    },
    {
      title: 'Make gray',
      onClick: () => changeHelpBlockColor('gray'),
      color: colors.gray[50],
    },
  ];

  const reposition = () => {
    const rect = helpBlockNode.getBoundingClientRect();

    setPosition({
      top: rect.top + window.pageYOffset - 60,
      left: rect.left,
      width: rect.width,
    });
  };

  useEffect(() => {
    const handleResize = throttle(reposition, 100);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(reposition, [helpBlockNode]);

  return (
    <Portal>
      <div
        style={{
          position: 'absolute',
          zIndex: 10,
          display: 'flex',
          pointerEvents: 'none',
          ...position,
        }}
      >
        <Toolbar arrow style={{margin: '0 auto'}} onMouseUp={reposition}>
          {menuItems.map(({title, ...rest}, i) => (
            <Tooltip
              placement="top"
              title={title}
              mouseEnterDelay={0.3}
              key={`footer-color-menu-item-${i}`}>
              <div tw="p-px mt--px">
                <MenuButton
                  editor={editor}
                  {...rest} />
              </div>
            </Tooltip>
          ))}
        </Toolbar>
      </div>
    </Portal>
  );
}

const MenuButton = styled.button((props) => [
  tw`
    w-6
    h-6
    my-2.5
    rounded
    mx-0.5
    cursor-pointer
  `,
  css`
    background-color: ${props.color};
  `,
]);

export default React.memo(HelpBlockToolbar);
