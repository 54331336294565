import React from 'react';
import {GlobalStyles as TwinGlobalStyles} from 'twin.macro';
import {createGlobalStyle} from 'styled-components';
import colors from 'utils/colors';
function GlobalStyles (props) {
  return <>
    <TwinGlobalStyles />
    <AdditionalGlobalStyles />
  </>;
}

const AdditionalGlobalStyles = createGlobalStyle`
  body,html {
    min-width: 320px;
  }
  body {
    overflow-x: hidden;
  }
  .ant-select-selector {
    height: auto !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    border-color: ${colors.gray['200']} !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    transition: none !important;
  }

  /*
   * Disable the slow animation of <Select> boxes opening and closing.
   * Set to 10ms rather than 0 as 0 can sometimes leave the expanded options
   * still after being hidden.
   */
  .ant-slide-up-appear, .ant-slide-up-enter, .ant-slide-up-leave {
    animation-duration: 10ms !important;
  }

  .ant-select-selection-item {
    font-size: 16px !important;
    color: ${colors.gray[700]};
  }
  .ant-select-selection-item > span {
    display: block;
    margin-top: -1px;
  }
  .ant-select.ant-select-single {
    height: 100% !important;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 100% !important;
  }
  .ant-select-selector:hover {
    border-color: ${colors.gray['200']} !important;
  }
  .ant-select-focused .ant-select-selector, .ant-select-focused .ant-select-selector:hover {
    border-color: ${colors.blue['400']} !important;
    outline: none !important;
  }
  .ant-select-dropdown {
    z-index: 100001 !important;
  }
  .permissions-dropdown.ant-select-dropdown {
    width: 100% !important;
    top: 36px !important;
  }
  .ant-select-item-option-selected {
    background-color: ${colors.blue['50']} !important;
  }
  .ant-select-item-option:hover {
    background-color: ${colors.gray['50']} !important;
  }
  .ant-select-item-option-content {
    font-size: 0.875rem !important;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .ant-select-inline > :nth-child(2) {
    position: relative !important;
  }

  .ant-select-inline .ant-select-dropdown {
    position: relative !important;
    box-shadow: none !important;
  }

  .ant-select-multiple .ant-select-selection-item {
    font-size: 14px !important;
    height: 24px !important;
    border-radius: 2px !important;
    align-items: center;
    background-color: ${colors.gray[50]} !important;
    color: ${colors.gray[700]} !important;
    border: none !important;
  }

  .ant-select-sm .ant-select-selector {
    height: 40px !important;
  }
  .ant-select-sm .ant-select-selection-item {
    font-size: 14px !important;
    color: ${colors.gray[700]};
    line-height: 28px !important;
  }
  .ant-select-sm .ant-select-arrow {
    font-size: 11px !important;
  }

  .ant-popover-inner-content {
    padding: 16px;
  }

  .popover {
    padding-left: 16px; /* Ensure there's some space between the popover and the side of the page */
    padding-right: 16px; /* Ensure there's some space between the popover and the side of the page */
    padding-bottom: 32px; /* Ensure there's some space between the popover and the bottom of the page */
    margin-left: -16px;
    margin-right: -16px;
  }

  .ant-popover-placement-bottom .ant-popover-content {
    margin-top: -15px;
  }

  .popover.ant-popover-placement-top .ant-popover-content {
    margin-bottom: -15px;
  }

  .popover.ant-popover-placement-bottom .ant-popover-content {
    margin-top: -15px;
  }

  // The content of a popover will change it's position from below/above the
  // trigger element if it's too close to the edge of the screen. This prevents
  // that from happening. We use it for elements that if the popover content
  // changes position, will cause it to sit outside of the popover container.
  .popover .ant-select-dropdown.ant-select-dropdown__fixed {
    top: 0 !important;
    left: 0 !important;
  }

  .ant-select-item.ant-select-item-option {
    border-radius: 0 !important;
  }

  .ant-select-show-search .ant-select-arrow {
      display: none !important;
  }

  .ant-popover-placement-bottom .ant-popover-arrow {
    top: 1px !important;
  }

  .ant-popover .ant-popover-inner {
    border-radius: 0;
  }

  .ant-tooltip-placement-top .ant-tooltip-arrow {
    transform:  translateX(-50%) translateY(100%) rotate(180deg);
  }

  .ant-tooltip.ant-tooltip-placement-top {
    padding-bottom: 0;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item {
    height: 100% !important;
  }

  .react-datepicker {
    border-color: ${colors.gray[200]};
  }

  .react-datepicker__header {
    background-color: ${colors.gray[50]};
    border-color: ${colors.gray[200]};
  }

  .react-datepicker__day--selected {
    background-color: ${colors.blue[600]};
  }

  .react-datepicker__day--selected:hover {
    background-color: ${colors.blue[700]};
  }

  .react-datepicker__day--keyboard-selected {
    background-color: white;
    outline: none !important;
    color: inherit;
  }

  .react-datepicker__day--keyboard-selected:focus-within {
    background-color: ${colors.blue[50]};
  }

  .react-datepicker__day--keyboard-selected:hover {
    background-color: ${colors.blue[100]};
  }

  .react-datepicker__day--selected.react-datepicker__day--keyboard-selected {
    background-color: ${colors.blue[700]};
  }

  .react-datepicker__navigation-icon::before {
    border-width: 2px 2px 0 0;
    border-color: ${colors.gray[200]};
  }

  .react-datepicker__navigation:hover .react-datepicker__navigation-icon::before {
    border-color: ${colors.gray[300]};
  }

  .react-datepicker__highlighted {
    background-color: ${colors.blue[100]};
    border-radius: 4px;
  }

  /*
   * Forcefully hide the LastPass helper with data-lpignore is set to true.
   * Note: data-lpignore is meant to be a valid way to simply tell LastPass not
   * to appear, but it appears LastPass doesn't always adhere to it.
   * https://www.reddit.com/r/Lastpass/comments/z1jno4/recent_update_html_to_disable_autofill/
   */
  [data-lpignore] + div[data-lastpass-icon-root] {
    display: none !important;
  }
`;

export default GlobalStyles;
