import insertParagraph from 'containers/SlateEditor/utils/insertParagraph';
import selectEndOfEditor from 'containers/SlateEditor/utils/selectEndOfEditor';
import {Transforms} from 'slate';

function createIntroduction (editor, {title, additionalContext}) {
  const decapitalizedTitle = title.charAt(0).toLowerCase() + title.slice(1);
  insertParagraph(editor, null, {text: `This checklist will help you ${decapitalizedTitle}.`});
  insertParagraph(editor);
  insertParagraph(editor, null, {text: 'This needs to be done when [Add description of when to use the checklist].'});
  insertParagraph(editor);
  insertParagraph(editor, null, {text: 'It should typically take [Add estimate of how long it takes here].'});
  insertParagraph(editor);

  insertIntroductionHelpBlock(editor, null, {title, additionalContext});
  selectEndOfEditor(editor);
}

function insertIntroductionHelpBlock (editor, location, {title, additionalContext} = {}) {
  const introductionHelpBlock = {
    id: 'mFEgbjPgTwdq9amiWGhQ0',
    open: false,
    type: 'help-block',
    color: 'blue',
    children: [
      {
        type: 'help-block-title',
        children: [
          {
            text: 'This checklist was generated by AI',
          },
        ],
      },
      {
        type: 'help-block-content',
        children: [
          {
            type: 'paragraph',
            children: [
              {
                text: additionalContext ? `You provided the title "${title}" and the context shown below.`
                  : `You provided the title "${title}" without any additional context.`,
              },
            ],
          },
          {
            type: 'paragraph',
            children: [
              {
                text: '',
              },
            ],
          },
          {
            type: 'paragraph',
            children: [
              {
                text: 'While AI is helpful, it’s not perfect!',
              },
            ],
          },
          {
            type: 'paragraph',
            children: [
              {
                text: '',
              },
            ],
          },
          {
            type: 'paragraph',
            children: [
              {
                text: 'We recommend that you: ',
              },
            ],
          },
          {
            type: 'paragraph',
            children: [
              {
                text: '',
              },
            ],
          },
          {
            type: 'ordered-list-item',
            number: 1,
            children: [
              {
                text: 'Review and update the checklist as needed',
              },
            ],
          },
          {
            type: 'ordered-list-item',
            number: 2,
            children: [
              {
                text: 'Delete this help box',
              },
            ],
          },
          {
            type: 'ordered-list-item',
            number: 3,
            children: [
              {
                text: 'Publish the checklist',
              },
            ],
          },
          {
            type: 'ordered-list-item',
            number: 4,
            children: [
              {
                text: 'Test it yourself',
              },
            ],
          },
          {
            type: 'ordered-list-item',
            number: 5,
            children: [
              {
                text: 'Test that a colleague can use it without support',
              },
            ],
          },
          {
            type: 'paragraph',
            number: 6,
            children: [
              {
                text: '',
              },
            ],
          },
          {
            type: 'paragraph',
            number: 6,
            children: [
              {
                text: 'Find more guidance on how to create and hand over a task checklist ',
              },
              {
                url: 'https://app.airmanual.co/help/Create-and-hand-over-a-task-checklist--E3LYTV3FCDog7iVYTfzRBYsC-LmVyTzdfxTrP',
                type: 'link',
                children: [
                  {
                    text: 'here',
                  },
                ],
              },
              {
                text: '.',
              },
            ],
          },
          ...(additionalContext ? [{
            type: 'paragraph',
            children: [
              {
                text: '',
              },
            ],
          },
          {
            type: 'paragraph',
            children: [
              {
                text: '',
              },
            ],
          },
          {
            type: 'paragraph',
            children: [
              {
                bold: true,
                text: 'Additional context provided',
                underline: true,
              },
            ],
          },
          {
            type: 'paragraph',
            children: [
              {
                text: '',
              },
            ],
          },
          {
            type: 'paragraph',
            children: [
              {
                text: additionalContext,
              },
            ],

          }] : []),
        ],
      },
    ],
  };

  Transforms.insertNodes(
    editor,
    introductionHelpBlock,
    {
      at: location,
    }
  );
  requestAnimationFrame(() => Transforms.select(editor, location));
}

export default createIntroduction;
