import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled, css} from 'twin.macro';
import DatePicker from 'components/DatePicker';
import Button from 'components/Button';
import {usePopover} from 'components/Popover/providers/PopoverProvider';

ContentDeadlineCellPopover.propTypes = {
  contentDeadline: PropTypes.string,
  onUpdateItem: PropTypes.func.isRequired,
};

ContentDeadlineCellPopover.defaultProps = {
};

function ContentDeadlineCellPopover (props) {
  const {
    contentDeadline,
    onUpdateItem,
  } = props;

  const {setPopoverOpen} = usePopover();

  const handleUpdate = (date) => {
    setPopoverOpen(false);

    onUpdateItem({
      contentDeadline: `${date.valueOf()}`,
    });
  };

  const handleRemove = () => {
    setPopoverOpen(false);

    onUpdateItem({
      contentDeadline: null,
    });
  };

  const contentDeadlineDate = contentDeadline ? new Date(parseInt(contentDeadline, 10)) : undefined;

  return (
    <Wrapper>
      <DatePicker
        selected={contentDeadlineDate}
        onChange={handleUpdate}
        inline
      />
      {contentDeadline && (
        <Button
          fullwidth
          sm
          tw="mt-2 text-xs"
          link
          onClick={handleRemove}>
            Remove deadline
        </Button>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div((props) => [
  tw`
    w-40
  `,
  css`
    width: min-content;
  `,
]);

export default ContentDeadlineCellPopover;
