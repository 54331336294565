import tw, {styled} from 'twin.macro';

const Heading3 = styled.h4((props) => [
  tw`
    text-xl
    text-gray-800
    mt-4
    mb-1.5
    clear-both
  `,
]);

export default Heading3;
