import React from 'react';
import {Helmet} from 'react-helmet';
import P from 'components/P';
import Button, {ButtonSpacer} from 'components/Button';
import SimpleFormPage from 'components/SimpleFormPage';
import SimpleFormContainer from 'components/SimpleFormContainer';
import SimpleFormHeading from 'components/SimpleFormHeading';
import Link from 'components/Link';

function ResetPasswordExpiredPage () {
  return <>
    <Helmet>
      <title>Reset link expired</title>
    </Helmet>
    <SimpleFormPage>
      <SimpleFormContainer>
        <SimpleFormHeading>This reset link has expired</SimpleFormHeading>
        <P>
          The reset password link must be used within 2 hours of requesting it.
        </P>
        <ButtonSpacer vertical />
        <Link to="/reset">
          <Button primary lg fullwidth>Try again</Button>
        </Link>
      </SimpleFormContainer>
    </SimpleFormPage>
  </>;
}

export default ResetPasswordExpiredPage;
