import {Transforms} from 'slate';
import makeChoice from '../factory/makeChoice';

function makeSureChoiceGroupsHaveTwoOrMoreChoices (editor, [entryNode, entryNodePath]) {
  if (
    entryNode.type !== 'choice-group' ||
    entryNode.children.length >= 2
  ) {
    return false;
  }

  Transforms.insertNodes(editor, makeChoice(), {
    at: [...entryNodePath, 1],
  });

  return true;
}

export default makeSureChoiceGroupsHaveTwoOrMoreChoices;
