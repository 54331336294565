import {Editor, Transforms} from 'slate';

function onArrowUpAfterCollapsedContent (editor, event, selectionMeta) {
  const {selectionStart} = selectionMeta;

  if (
    event.key !== 'ArrowUp' ||
    !selectionStart
  ) {
    return false;
  }

  const previousPoint = Editor.before(editor, {
    path: selectionStart.path,
    offset: 0,
  });
  if (!previousPoint) {
    return false;
  }

  const collapsedElements = Editor.nodes(editor, {
    at: previousPoint.path,
    match: (n) => [
      'process',
      'process-step',
      'help-block',
    ].includes(n.type) && !n.open,
  });

  const [highestCollapsedElement, highestCollapsedElementPath] = collapsedElements.next().value || [undefined, undefined];
  if (!highestCollapsedElement) {
    return false;
  }

  event.preventDefault();
  const endOfHighestCollapsedElementTitle = Editor.end(editor, [...highestCollapsedElementPath, 0]);

  requestAnimationFrame(() =>
    Transforms.select(
      editor,
      {
        path: endOfHighestCollapsedElementTitle.path,
        offset: Math.min(selectionStart.offset, endOfHighestCollapsedElementTitle.offset),
      }
    ));

  return true;
}

export default onArrowUpAfterCollapsedContent;
