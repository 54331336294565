import {Tooltip} from 'antd';
import tw, {styled} from 'twin.macro';

const InlineTooltip = styled(Tooltip)((props) => [
  tw`
    border-b
    border-dotted
  `,
]);

export default InlineTooltip;
