import React from 'react';
import PropTypes from 'prop-types';

import tw, {styled} from 'twin.macro';
import Truncate from 'components/Truncate';
import {ACTION, TRIGGER} from 'am-constants';

SidePanelAutomation.propTypes = {
  automation: PropTypes.shape({
    trigger: PropTypes.oneOf(Object.values(TRIGGER)),
    action: PropTypes.oneOf(Object.values(ACTION)),
    target: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
  setAutomationEditorData: PropTypes.func.isRequired,
  setIsAutomationEditorModalVisible: PropTypes.func.isRequired,
};

SidePanelAutomation.defaultProps = {
};

function SidePanelAutomation (props) {
  const {
    automation,
    setAutomationEditorData,
    setIsAutomationEditorModalVisible,
  } = props;

  const {action, target, trigger} = automation;

  return (
    // <Link to={url}>
    <Wrapper
      onClick={() => {
        setAutomationEditorData({
          automationId: automation.id,
          action,
          target,
          trigger,
        });
        setTimeout(() => {
          setIsAutomationEditorModalVisible(true);
        }, 0);
      }}>
      <Title>
        {trigger === TRIGGER.CREATE_RESPONSE && 'When started, '}
        {trigger === TRIGGER.SUBMIT_RESPONSE && 'When completed, '}

        {action === ACTION.EMAIL && 'send an email'}
        {action === ACTION.WEBHOOK && 'make a call to an API webhook'}
      </Title>
      <Detail>
        {/* {action === ACTION.EMAIL && (
          <Truncate>{automation.email.split(',').join(', ')}</Truncate>
        )} */}
        {(action === ACTION.WEBHOOK || action === ACTION.EMAIL) && <Truncate>{target}</Truncate>}
      </Detail>
    </Wrapper>
    // </Link>
  );
}

const Wrapper = styled.div((props) => [
  tw`
    bg-gray-50
    p-3.5
    text-gray-700
    hover:text-gray-700
    hover:bg-gray-100
    cursor-pointer
  `,
]);

const Title = styled.div((props) => [
  tw`
    text-sm
    mb-1
  `,
]);

const Detail = styled.div((props) => [
  tw`
    text-2xs
    text-gray-500
  `,
]);

export default SidePanelAutomation;
