import PropTypes from 'prop-types';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import {gql} from 'graphql-request';
import {ITEM_MINIMAL_FIELDS} from 'fragments/ItemMinimal';
import {BREADCRUMB_FIELDS} from 'fragments/Breadcrumb';
import {ITEM_TYPE} from 'am-constants';
import getItemUrlName from 'am-utils/utils/getItemUrlName';
import graphQLClient from 'utils/graphQLClient';

const pageQuery = gql`
  query GetPage($ref: String!, $key: String!) {
    item(ref: $ref, key: $key) {
      ${ITEM_MINIMAL_FIELDS}
      loggedInUserRoleIds
      hasPublicAncestor
      public
      publishedAt
      breadcrumbs {
        ${BREADCRUMB_FIELDS}
      }
    }
  }
`;

usePageQuery.propTypes = {
  ref: PropTypes.string,
  key: PropTypes.string,
  workspaceUrlName: PropTypes.string,
};

function usePageQuery ({workspaceUrlName, ref, key}) {
  const queryClient = useQueryClient();

  const cacheKey = [workspaceUrlName, 'items', {ref, key}];

  const variables = {
    workspaceUrlName,
    ref,
    key,
  };

  const queryFn = async () => {
    const {item} = await graphQLClient.request(pageQuery, variables);

    if (![
      ITEM_TYPE.DOCUMENT,
      ITEM_TYPE.FORM,
      ITEM_TYPE.CHECKLIST].includes(item.itemTypeId)) {
      throw new Error('The item loaded is not a page');
    }

    return {
      ...item,
      urlName: getItemUrlName(item),
    };
  };

  const options = {
    initialData: () => {
      // If we've previously loaded items on the browse page, we can use that
      // cached data while we wait for the new data to load.
      const cachedItems = queryClient.getQueryData([workspaceUrlName, 'items']);
      if (cachedItems) {
        return cachedItems.find((item) => item.ref === ref && item.key === key);
      }

      // If we've previously loaded items on the manage page, we can use that
      // cached data while we wait for the new data to load.
      const cachedDetailedItems = queryClient.getQueryData([workspaceUrlName, 'items', 'detailed']);
      if (cachedDetailedItems) {
        return cachedDetailedItems.find((item) => item.ref === ref && item.key === key);
      }

      return undefined;
    },
  };

  return useQuery(
    cacheKey,
    queryFn,
    options
  );
}

export default usePageQuery;
