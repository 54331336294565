import React from 'react';
import ResetPasswordPage from './components/ResetPasswordPage';
import ResetPasswordRequestSentPage from './components/ResetPasswordRequestSentPage';
import useRequestPasswordResetMutation from './hooks/useRequestPasswordResetMutation';

ResetPassword.propTypes = {

};

ResetPassword.defaultProps = {

};

function ResetPassword (props) {
  const [requestPasswordReset, {
    data,
    error,
    loading,
    reset,
  }] = useRequestPasswordResetMutation();

  const onSubmit = async (email) => {
    await requestPasswordReset({variables: {email}});
  };

  if (data && !error) {
    return <ResetPasswordRequestSentPage onLeave={reset}/>;
  }

  return <ResetPasswordPage
    requestPasswordReset={onSubmit}
    error={error}
    loading={loading}
  />;
}

export default ResetPassword;
