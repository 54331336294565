import React from 'react';
import PropTypes from 'prop-types';
import {DRAGGABLE_TYPES} from 'am-constants';
import {useMultiDrag} from 'react-dnd-multi-backend';
import GridRow from './GridRow';
import DroppableForGridRow from './DroppableForGridRow';
import useDragScrolling from './hooks/useDragScrolling';

DragAndDroppableGridRow.propTypes = {
  index: PropTypes.number.isRequired,
  item: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  onDragStart: PropTypes.func,
  onDragHover: PropTypes.func,
  onDragEnd: PropTypes.func,
  onDrop: PropTypes.func,
};

function DragAndDroppableGridRow (props) {
  const {
    index,
    item,
    onDragStart,
    onDragHover,
    onDragEnd,
    onDrop,
    ...rest
  } = props;

  const [
    [dragProps], // eslint-disable-line no-unused-vars
    {
      html5: [html5Props, dragHandleHtml5Ref, dragPreviewHtml5Ref],
      touch: [touchProps, dragHandleTouchRef, dragPreviewTouchRef],
    },
  ] = useMultiDrag(({
    type: DRAGGABLE_TYPES.GRID_ROW,
    item: () => {
      onDragStart({item});
      addEventListenerForWindow();

      return item;
    },
    end: (_item, monitor) => {
      removeEventListenerForWindow();
      onDragEnd();
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    // canDrag: (item, monitor) => { ... }, // Optional
    // More options: https://react-dnd.github.io/react-dnd/docs/api/use-drag
  }), [item, onDragStart, onDragEnd]);

  const {
    addEventListenerForWindow,
    removeEventListenerForWindow,
  } = useDragScrolling();

  const isDragging = html5Props.isDragging || touchProps.isDragging;

  return (
    <DroppableForGridRow
      index={index}
      item={item}
      onDragStart={onDragStart}
      onDragHover={onDragHover}
      onDrop={onDrop}>
      <div ref={dragPreviewTouchRef}>
        <div ref={dragPreviewHtml5Ref}>
          <GridRow
            dragHandleHtml5Ref={dragHandleHtml5Ref}
            dragHandleTouchRef={dragHandleTouchRef}
            isDragging={isDragging}
            {...rest}
          />
        </div>
      </div>
    </DroppableForGridRow>
  );
}

export default DragAndDroppableGridRow;
