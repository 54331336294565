import getRootAPIUri from 'utils/getRootAPIUri';
import makeProcess from '../factory/makeProcess';
import insertNodeAtSelection from './insertNodeAtSelection';
import makeProcessStep from '../factory/makeProcessStep';
import selectEndOfEditor from './selectEndOfEditor';
import createIntroduction from '../AIStreaming/AISlateHelpers/createIntroduction';

async function insertProcessWithAI ({
  editor, title, itemId, useAIQuery2, additionalContext,
}) {
  const onSuccessCallback = (checklistJson) => {
    createIntroduction(editor, {title, additionalContext});

    const slateFormat = convertProcessToSlateFormat(checklistJson);
    slateFormat.forEach((checklist) => {
      insertProcessAtSelection(editor, checklist);
    });
  };

  return fetchDraftChecklist({
    title, itemId, useAIQuery2, additionalContext,
  }, onSuccessCallback);
}

async function fetchDraftChecklist ({
  title, itemId, useAIQuery2, additionalContext,
}, onSuccessCallback) {
  const urlParam = new URLSearchParams({title, itemId, additionalContext});

  const endpoint = useAIQuery2 ? 'draft-checklist-2' : 'draft-checklist';
  const result = await fetch(`${getRootAPIUri()}/${endpoint}?${urlParam}`, {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
  });
  const json = await result.json();

  return onSuccessCallback(JSON.parse(json));
}

function convertProcessToSlateFormat (checklistJson) {
  // single checklist format
  if (checklistJson?.steps) {
    const steps = checklistJson.steps.map((step) => makeProcessStep({
      title: step.name,
      content: step.description,
    }));

    return ([{
      checklistName: '',
      steps,
    }]);
  }

  return checklistJson.stages.map((checklist) => {
    const steps = checklist.steps.map((step) => makeProcessStep({
      title: step.name,
      content: step.description,
    }));

    return ({
      checklistName: checklist.name,
      steps,
    });
  });
}

function insertProcessAtSelection (editor, slateFormat) {
  insertNodeAtSelection(
    editor,
    {
      makeNode: () => makeProcess({
        processTitle: slateFormat.checklistName,
        processSteps: slateFormat.steps,
      }),
    }
  );

  selectEndOfEditor(editor);
}

export default insertProcessWithAI;
