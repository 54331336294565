import {ITEM_FIELDS} from './Item';

export const ITEM_DETAILED_FIELDS = `
  ${ITEM_FIELDS}
  issues {
    issueStatusId
    description
    resolution
    createdAt
  }
  documenters {
    id
    name
    email
  }
  reviewers {
    id
    name
    email
  }
  taskOwners {
    id
    name
    email
  }
  responseStats {
    numActiveResponses
    numSubmittedResponses
    mostRecentSubmissionAt
  }
  folderStats {
    numPages
    numActionsNeeded
    numVeryHighPriorityActionsNeeded
    nextDeadline
    nextDueAt
  }
`;

export default null;
