import React, {useState} from 'react';
import PropTypes from 'prop-types';
import tw, {styled, css} from 'twin.macro';
import DropdownButton from 'components/DropdownButton';
import {createGlobalStyle} from 'styled-components';
import {tailwind} from 'am-config';
import {PERMISSIONS, ITEM_TYPE} from 'am-constants';
import FolderCreator from 'containers/FolderCreator';
import FolderRenamer from 'containers/FolderRenamer';
import ItemMover from 'containers/ItemMover';
import ItemDeleter from 'containers/ItemDeleter';
import ItemSharer from 'containers/ItemSharer';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';
import LinkCreatorContainer from 'containers/LinkCreator';
import PageToolbar from 'components/PageToolbar';
import {AiFillInfoCircle} from 'react-icons/ai';
import Tooltip from 'components/Tooltip';
import {ButtonSpacer} from 'components/Button';
import getUrlForItem from 'utils/getUrlForItem';
import {getPermissionsForRoles} from 'am-utils';
import CustomPropTypes from 'utils/CustomPropTypes';
import ItemList from './ItemList';

const {colors} = tailwind.theme;

ExplorerPage.propTypes = {
  isSearchError: PropTypes.bool.isRequired,
  isSearchLoading: PropTypes.bool.isRequired,
  item: CustomPropTypes.Item,
  itemChildren: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  searchString: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
};

function ExplorerPage (props) {
  const {
    isSearchError, isSearchLoading, item, itemChildren, searchString, onSearch,
  } = props;
  const {
    urlName: workspaceUrlName,
    isExampleWorkspace,
    workspacePermissions,
  } = useWorkspace();

  const {
    breadcrumbs,
    itemTypeId,
    name,
    loggedInUserRoleIds,
  } = item;
  const loggedInUserItemPermissions = loggedInUserRoleIds ? getPermissionsForRoles(loggedInUserRoleIds) : [];

  const showCreateMenu = workspacePermissions.includes(PERMISSIONS.VIEW_WORKSPACE);
  const canUseCreateMenu = loggedInUserItemPermissions.includes(PERMISSIONS.ADD_ITEM_TO_FOLDER);
  const menu = [[
    {
      onClick: () => setIsNewFolderModalVisible(true),
      label: 'Create folder',
    },
    {
      onClick: () => setIsNewLinkModalVisible(true),
      label: 'Create link',
    },
  ]];
  const [isDeleteItemModalVisible, setIsDeleteItemModalVisible] = useState(false);
  const [isMoveFolderModalVisible, setIsMoveFolderModalVisible] = useState(false);
  const [isNewFolderModalVisible, setIsNewFolderModalVisible] = useState(false);
  const [isNewLinkModalVisible, setIsNewLinkModalVisible] = useState(false);
  const [isRenameFolderModalVisible, setIsRenameFolderModalVisible] = useState(false);
  const [isShareItemModalVisible, setIsShareItemModalVisible] = useState(false);
  const [focusedItem, setFocusedItem] = useState();

  const searchPlaceholderMessage = isExampleWorkspace ? 'Search examples...' : 'Search your manual...';

  return ((<>
    <FolderCreator
      isVisible={isNewFolderModalVisible}
      setIsVisible={setIsNewFolderModalVisible}
      parentItem={item}
    />
    <LinkCreatorContainer
      isVisible={isNewLinkModalVisible}
      setIsVisible={setIsNewLinkModalVisible}
      parentItem={item}
    />
    {focusedItem && <>
      <ItemMover
        workspaceUrlName={workspaceUrlName}
        isVisible={isMoveFolderModalVisible}
        setIsVisible={setIsMoveFolderModalVisible}
        itemRef={focusedItem.ref}
        itemKey={focusedItem.key}
        itemName={focusedItem.name}
        itemId={focusedItem.id}
      />
      <ItemDeleter
        isVisible={isDeleteItemModalVisible}
        itemRef={focusedItem.ref}
        itemKey={focusedItem.key}
        itemTypeId={focusedItem.itemTypeId}
        setIsVisible={setIsDeleteItemModalVisible}
        workspaceUrlName={workspaceUrlName}
      />
      <ItemSharer
        key={focusedItem.key}
        isVisible={isShareItemModalVisible}
        isItemPublic={focusedItem.public}
        itemKey={focusedItem.key}
        itemName={focusedItem.name}
        itemRef={focusedItem.ref}
        itemTypeId={focusedItem.itemTypeId}
        itemUrl={getUrlForItem(workspaceUrlName, focusedItem)}
        setIsVisible={setIsShareItemModalVisible}
        userRoleIds={focusedItem.userRoleIds}
        loggedInUserRoleIds={focusedItem.loggedInUserRoleIds}
        workspaceMembersRoleIds={focusedItem.workspaceMembersRoleIds}
      />
      <FolderRenamer
        isVisible={isRenameFolderModalVisible}
        setIsVisible={setIsRenameFolderModalVisible}
        itemRef={focusedItem.ref}
        itemKey={focusedItem.key}
        currentFolderName={focusedItem.name}
      />
    </>}
    <>
      <GlobalStyle />
      {isExampleWorkspace && (
        <PageToolbar purple>
          <div tw="text-left w-full py-1.5 flex items-center">
            This is an example workspace
            <ButtonSpacer />
            <Tooltip title="This workspace is full of examples and templates for you to review and copy!" placement="bottom">
              <AiFillInfoCircle size={18} />
            </Tooltip>
          </div>
        </PageToolbar>
      )}
      <TopArea>
        <Container tw="flex">
          <Searchbox
            autoFocus
            defaultValue={searchString}
            key={searchString} // This key makes sure that the searchbox refreshes when the query string changes in the URL (e.g after a navigate() command)
            onChange={(event) => {
              onSearch(event.target.value);
            }}
            placeholder={searchPlaceholderMessage}
          ></Searchbox>
          {showCreateMenu &&
          <>
            <div tw="ml-4"></div>
            {canUseCreateMenu &&
              <DropdownButton primary tw="h-full font-bold rounded px-4 whitespace-nowrap" to='./new' menu={menu}>
                Create page
              </DropdownButton>
            }
            {!canUseCreateMenu &&
              <Tooltip
                placement="bottom"
                title="You do not have permission to create new pages in this folder">
                {/* This div is needed for the tooltip to appear. Otherwise the button being disabled prevents the tooltip from working!  */}
                <div>
                  <DropdownButton primary tw="h-full font-bold rounded px-4 whitespace-nowrap" to='./new' menu={menu} disabled>
                    Create page
                  </DropdownButton>
                </div>
              </Tooltip>
            }
          </>}
        </Container>
      </TopArea>
      <ItemList
        breadcrumbs={breadcrumbs}
        isExampleWorkspace={isExampleWorkspace}
        isSearchError={isSearchError}
        isSearchLoading={isSearchLoading}
        emptyMessage={itemTypeId === ITEM_TYPE.WORKSPACE ? 'Your manual is empty' : 'This folder is empty'}
        item={item}
        items={itemChildren}
        searchString={searchString}
        setFocusedItem={setFocusedItem}
        setIsDeleteItemModalVisible={setIsDeleteItemModalVisible}
        setIsMoveFolderModalVisible={setIsMoveFolderModalVisible}
        setIsRenameFolderModalVisible={setIsRenameFolderModalVisible}
        setIsShareItemModalVisible={setIsShareItemModalVisible}
        name={name}
        workspaceUrlName={workspaceUrlName}
        key={`itemlist-${itemTypeId}`}
      />
    </>
  </>
  ));
}

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${colors.gray['50']} !important;
  }
`;

const Container = styled.div((props) => [
  tw`
    max-w-4xl
    m-auto
    px-5
  `,
]);

const TopArea = styled.div((props) => [
  tw`
    relative
    pt-24
    pb-8
    bg-white
  `,
  css`
    z-index: 3;
  `,
]);

const Searchbox = styled.input.attrs({
  'data-test-id': 'search-input',
})((props) => [
  tw`
    block
    w-full
    h-16
    p-3
    text-3xl
    placeholder-blue-400
    text-blue-600
    bg-blue-50
    border-2
    border-transparent
    -ml-0.5
    rounded
    focus:outline-none
    focus:border-blue-500
    focus:placeholder-blue-300
  `,
  props.purple && tw`
    placeholder-purple-400
    text-purple-600
    bg-purple-100
    focus:border-purple-500
    focus:placeholder-purple-300
  `,
]);

export default ExplorerPage;
