import React from 'react';
import PropTypes from 'prop-types';
import Error from 'components/Error';
import {sanitizeUrl} from '@braintree/sanitize-url';
import A from './A';

Video.propTypes = {
  url: PropTypes.string.isRequired,
};

/*
 * Supported URL structures:
 * https://www.youtube.com/embed/z9Ul9ccDOqE
 * https://www.youtube.com/watch?v=z9Ul9ccDOqE
 * https://youtu.be/z9Ul9ccDOqE
 * https://www.youtube.com/shorts/hGRwsd2Nu_Y
 * https://www.loom.com/embed/b87ed1ea1097487c9b464fc61af3fd47
 * https://www.loom.com/share/b87ed1ea1097487c9b464fc61af3fd47
 * https://fast.wistia.net/embed/iframe/4w2q2810m3?videoFoam=true
 * https://airmanual.wistia.com/medias/4w2q2810m3
 * https://web.microsoftstream.com/video/dcf07f72-e43a-4f70-86f3-2809aafd1a89
 * https://web.microsoftstream.com/embed/video/dcf07f72-e43a-4f70-86f3-2809aafd1a89
 * https://vgqc4-my.sharepoint.com/personal/daniel_vgqc4_onmicrosoft_com/_layouts/15/embed.aspx?UniqueId=e35795fc-a85c-4807-8b53-35147d1d530f&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create" width="640" height="360" frameborder="0" scrolling="no" allowfullscreen title="Recording-20231108_102520.webm
 * https://vimeo.com/723397707/1e2b57c013
 * https://vimeo.com/manage/videos/724530340/3c2b45dd53
 * https://player.vimeo.com/video/723397707?h=1e2b57c013&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479
 */
function Video (props) {
  const {url} = props;

  if (url.indexOf('//') === -1 || url.split('//')[1].indexOf('/') === -1) {
    // eslint-disable-next-line no-console
    console.error(`Video URL not supported: ${url}`);

    return <Error contentEditable={false} tw="select-none">
      Video type not supported: Please provide a valid URL for a YouTube, Loom, Wistia, MS Stream or Vimeo video.
      {' '}
      <A
        href='https://app.airmanual.co/help/How-do-I-add-a-video-to-a-page--Oyeor86Jw8NhYFCI6keK2v9V-RPxhfWUCiBHM'
        rel="noreferrer"
        target="_blank"
      >
        How to add a video to a page
      </A>
    </Error>;
  }

  const hostname = url.split('//')[1].split('/')[0];

  const loom = hostname === 'www.loom.com';
  const msStream = hostname === 'web.microsoftstream.com';
  // ms stream classic has been depracated for ms stream sharepoint
  const msStreamSharepoint = hostname.includes('sharepoint.com');
  const wistia = hostname.indexOf('.wistia.') !== -1;
  const youtube = hostname === 'www.youtube.com' || hostname === 'youtu.be';
  const vimeo = hostname === 'player.vimeo.com' || hostname === 'vimeo.com';

  let embedUrl;

  if (loom) {
    if (url.substr(0, 27) === 'https://www.loom.com/embed/') {
      embedUrl = url;
    }
    else if (url.substr(0, 27) === 'https://www.loom.com/share/') {
      embedUrl = `https://www.loom.com/embed/${url.substr(27)}`;
    }
  }

  if (msStreamSharepoint) {
    if (url.includes('embed=')) {
      embedUrl = url;
    }
    else {
      // Regular sharing links are not supported because they have
    // X-Frame-Options set to SAME ORIGIN only (last checked 9/11/23)
      return <Error contentEditable={false} tw="select-none">
      Unable to add video: Please check that you are using a URL for embedding the MS Stream video (
        <A
          href='https://app.airmanual.co/help/How-do-I-add-a-video-to-a-page--Oyeor86Jw8NhYFCI6keK2v9V-RPxhfWUCiBHM'
          rel="noreferrer"
          target="_blank"
        >
        how to find the embedding URL
        </A>
      ) and that it is set up to be shared publicly.
      </Error>;
    }
  }

  if (msStream) {
    if (url.substr(0, 38) === 'https://web.microsoftstream.com/embed/') {
      embedUrl = url;
    }
    if (url.substr(0, 38) === 'https://web.microsoftstream.com/video/') {
      embedUrl = `https://web.microsoftstream.com/embed/${url.substr(32)}`;
    }
  }

  if (wistia) {
    if (url.substr(0, 37) === 'https://fast.wistia.net/embed/iframe/') {
      embedUrl = url;
    }
    else if (url.indexOf('/medias/') !== -1) {
      embedUrl = `https://fast.wistia.net/embed/iframe/${url.substr(url.indexOf('/medias/') + 8)}`;
    }
  }

  if (youtube) {
    if (url.substr(0, 30) === 'https://www.youtube.com/embed/') {
      embedUrl = url;
    }
    else if (url.substr(0, 32) === 'https://www.youtube.com/watch?v=') {
      embedUrl = `https://www.youtube.com/embed/${url.substr(32)}`;
    }
    else if (url.substr(0, 17) === 'https://youtu.be/') {
      embedUrl = `https://www.youtube.com/embed/${url.substr(17)}`;
    }
    else if (url.substr(0, 30) === 'https://www.youtube.com/shorts') {
      embedUrl = `https://www.youtube.com/embed/${url.substr(30)}`;
    }
  }

  if (vimeo) {
    if (hostname === 'player.vimeo.com') {
      embedUrl = url;
    }
    else if (hostname === 'vimeo.com') {
      if (url.split('/')[3] === 'manage') {
        const id = url.split('/')[5];
        const hash = url.split('/')[6];
        embedUrl = `https://player.vimeo.com/video/${id}?h=${hash}&amp;badge=0&amp;autopause=0&amp;`;
      }
      else {
        const id = url.split('/')[3];
        const hash = url.split('/')[4];

        embedUrl = `https://player.vimeo.com/video/${id}?h=${hash}&amp;badge=0&amp;autopause=0&amp;`;
      }
    }
  }

  if (!embedUrl) {
    // eslint-disable-next-line no-console
    console.error(`Video URL not supported: ${url}`);

    return <Error contentEditable={false} tw="select-none">
      Video type not supported: Please provide a valid URL for a YouTube, Loom, Wistia, MS Stream or Vimeo video.
      {' '}
      <A
        href='https://app.airmanual.co/help/How-do-I-add-a-video-to-a-page--Oyeor86Jw8NhYFCI6keK2v9V-RPxhfWUCiBHM'
        rel="noreferrer"
        target="_blank"
      >
        How to add a video to a page
      </A>
    </Error>;
  }

  const paddingBottom = wistia || msStream ? '56.25%' : '55.038167938931295%';

  return (
    <div style={{
      position: 'relative',
      paddingBottom,
      height: '0',
      userSelect: 'none',
    }} contentEditable={false}>
      <iframe src={sanitizeUrl(embedUrl)} frameBorder="0" allowFullScreen style={{
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
      }} />
    </div>
  );
}

export default Video;
