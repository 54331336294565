import {Editor, Transforms} from 'slate';
import getSelectionMeta from './getSelectionMeta';

function changeColor (editor, color) {
  const {
    selectionStartElementPath,
  } = getSelectionMeta(editor);

  const [helpBlock, helpBlockPath] = Editor.above(editor, {
    at: selectionStartElementPath,
    match: (n) => n.type === 'help-block',
  }) || [undefined, undefined];

  if (!helpBlock) {
    return;
  }

  Transforms.setNodes(
    editor,
    {
      color,
    },
    {
      at: helpBlockPath,
    }
  );
}

export default changeColor;
