function onBackspaceAtStartOfCollapsibleTitle (editor, event, selectionMeta) {
  const {
    isSelectionAtStartOfElement,
    selectionStart,
    selectionStartElement,
  } = selectionMeta;

  if (
    event.key !== 'Backspace' ||
    !selectionStart ||
    ![
      'help-block-title',
      // 'process-step-title' handled by onBackspaceAtStartOfProcessStepTitle
      'process-title',
    ].includes(selectionStartElement.type) ||
    !isSelectionAtStartOfElement
  ) {
    return false;
  }

  event.preventDefault();
  return true;
}

export default onBackspaceAtStartOfCollapsibleTitle;
