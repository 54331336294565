import tw, {styled, css} from 'twin.macro';

const Code = styled.pre((props) => [
  tw`
    py-2
    px-2
    bg-gray-50
    text-xs
    border
    border-gray-100
    leading-5
    whitespace-pre-wrap
  `,
  css`
    -webkit-print-color-adjust: exact; // Chrome, Safari
    color-adjust: exact; // Firefox
  `,
]);

export default Code;
