import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import tw, {styled, css} from 'twin.macro';
import {ITEM_TYPE} from 'am-constants';
import groupBy from 'utils/groupBy';
import sortBy from 'lodash.sortby';
import Section from './components/Section';

FolderBrowser.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    children: PropTypes.arrayOf(PropTypes.shape({})),
  })).isRequired,
  onItemClick: PropTypes.func.isRequired,
};

FolderBrowser.defaultProps = {
};

function FolderBrowser (props) {
  const {
    items,
  } = props;

  if (items.length === 0) {
    return (
      <Empty>There's nothing here... create a new page?</Empty>
    );
  }

  const itemsByParentId = useMemo(() => groupBy(items, 'parentId'), [items]);
  const sections = sortBy(items.filter((childItem) => childItem.itemTypeId === ITEM_TYPE.SECTION), 'rank');
  const sectionIds = sections.map(({id}) => id);
  const otherChildItems = items.filter((childItem) => childItem.itemTypeId !== ITEM_TYPE.SECTION && !sectionIds.includes(childItem.parentId));

  return (
    <Wrapper>
      {otherChildItems.length > 0 && (
        <Section
          items={otherChildItems}
          showName={false} />
      )}
      {sections.map((section) => (
        <Section
          key={section.id}
          name={section.name}
          items={itemsByParentId[section.id] || []}
          showName />
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div((props) => [
  tw`
    flex
    flex-col
    flex-wrap
    gap-6
    max-w-5xl
  `,
]);

const Empty = styled.div((props) => [
  tw`
    w-full
    flex
    items-center
    justify-center
    text-gray-400
    text-lg
  `,
  css`
  min-height: 360px;
    height: calc(100vh - 400px);
  `,
]);

export default FolderBrowser;
