import PropTypes from 'prop-types';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {gql} from 'graphql-request';
import graphQLClient from 'utils/graphQLClient';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';
const query = gql`
  mutation ITEM_MOVE(
    $ref: String!,
    $key: String!,
    $parentId: Int!,
    $rank: String,
  ) {
    itemMove(
      itemRef: $ref
      itemKey: $key
      parentId: $parentId
      rank: $rank
    ) {
      ref
      key
    }
  }
`;
useMoveItemMutation.propTypes = {
  ref: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  parentId: PropTypes.number.isRequired,
  rank: PropTypes.string,
};
function useMoveItemMutation (props) {
  const {
    ref,
    key,
  } = props;
  const queryClient = useQueryClient();
  const {urlName: workspaceUrlName} = useWorkspace();
  const mutationFn = async (movedItem) => {
    const variables = {
      ref: Object.prototype.hasOwnProperty.call(movedItem, 'ref') ? movedItem.ref : ref,
      key: Object.prototype.hasOwnProperty.call(movedItem, 'key') ? movedItem.key : key,
      parentId: movedItem.parentId,
      rank: movedItem.rank,
    };
    await graphQLClient.request(query, variables);
  };
  // Options set up to support optimistic updates.
  const options = {
    onMutate: async (movedItem) => {
      const {newParentFolderId, oldParentFolderId} = movedItem;

      if (newParentFolderId === oldParentFolderId) {
        const queryKey = [workspaceUrlName, 'items', {folderId: oldParentFolderId}, 'detailed'];

        // Prevent any refetches from overwriting our optimistic update
        await queryClient.cancelQueries(queryKey);

        // Optimistic update
        const previousItems = queryClient.getQueryData(queryKey);
        queryClient.setQueryData(queryKey, previousItems.map((item) => (item.id === movedItem.id ? {
          ...item,
          ...movedItem,
          savingAttrs: Object.keys(movedItem),
          saving: true,
        } : item)));

        // Return context for onError
        return {previousItems};
      }

      const oldFolderQueryKey = [workspaceUrlName, 'items', {folderId: movedItem.oldParentFolderId}, 'detailed'];
      const newFolderQueryKey = [workspaceUrlName, 'items', {folderId: movedItem.newParentFolderId}, 'detailed'];

      // Prevent any refetches from overwriting our optimistic update
      await queryClient.cancelQueries(oldFolderQueryKey);
      await queryClient.cancelQueries(newFolderQueryKey);

      // Optimistic update
      const previousItemsInOldFolder = queryClient.getQueryData(oldFolderQueryKey);
      queryClient.setQueryData(oldFolderQueryKey, previousItemsInOldFolder.filter((item) => item.id !== movedItem.id));

      const previousItemsInNewFolder = queryClient.getQueryData(newFolderQueryKey);
      queryClient.setQueryData(newFolderQueryKey, [
        ...previousItemsInNewFolder,
        {
          ...movedItem,
          savingAttrs: Object.keys(movedItem),
          saving: true,
        },
      ]);

      // Return context for onError
      return {previousItemsInOldFolder, previousItemsInNewFolder};
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (err, movedItem, context) => {
      const {newParentFolderId, oldParentFolderId} = movedItem;

      if (newParentFolderId === oldParentFolderId) {
        const queryKey = [workspaceUrlName, 'items', {folderId: movedItem.parentFolderId}, 'detailed'];

        queryClient.setQueryData(queryKey, context.previousItems);

        return;
      }

      const oldFolderQueryKey = [workspaceUrlName, 'items', {folderId: movedItem.oldParentFolderId}];
      const newFolderQueryKey = [workspaceUrlName, 'items', {folderId: movedItem.newParentFolderId}, 'detailed'];

      queryClient.setQueryData(oldFolderQueryKey, context.previousItemsInOldFolder);
      queryClient.setQueryData(newFolderQueryKey, context.previousItemsInNewFolder);
    },
    // Update cache on success
    onSuccess: (data, movedItem, context) => {
      const newFolderQueryKey = [workspaceUrlName, 'items', {folderId: movedItem.newParentFolderId}, 'detailed'];

      const itemsInNewFolder = queryClient.getQueryData(newFolderQueryKey);
      queryClient.setQueryData(newFolderQueryKey, itemsInNewFolder.map((item) => (item.id === movedItem.id ? {
        ...item,
        saving: false,
      } : item)));
    },
  };

  return useMutation(mutationFn, options);
}
export default useMoveItemMutation;
