import React from 'react';
import Modal from 'components/Modal';
import Button, {ButtonSpacer} from 'components/Button';
import PropTypes from 'prop-types';
import Input from 'components/Input';

FolderCreatorModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  inputValue: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  renderError: PropTypes.func,
};

function FolderCreatorModal (props) {
  const {
    isVisible,
    onChange,
    inputValue,
    closeModal,
    onSubmit,
  } = props;

  return (
    <Modal
      errorTitle={'Error adding'}
      footer={<>
        <Button type="button" link onClick={closeModal}>
        Cancel
        </Button>
        <ButtonSpacer />

        <Button type="submit" primary disabled={!inputValue.trim()} data-test-id="modal_create-button">
        Create
        </Button>
      </>}
      onClose={closeModal}
      onSubmit={onSubmit}
      title="Create folder"
      visible={isVisible}>
      <Input
        maxLength={50}
        data-test-id="modal_input"
        value={inputValue}
        onChange={onChange}
        placeholder="Give your folder a name"
        className="modal-focus" />
    </Modal>
  );
}

export default FolderCreatorModal;
