import React, {useEffect} from 'react';
import Select, {Option} from 'components/Select';
import PropTypes from 'prop-types';

import {useUser} from 'containers/UserApp/providers/UserProvider';
import FormGroup from 'components/FormGroup';
import Label from 'components/Label';
import P from 'components/P';
import Link from 'components/Link';

PageDuplicaterModalContent.propTypes = {
  onChange: PropTypes.func.isRequired,
};

function PageDuplicaterModalContent (props) {
  const {
    onChange,
  } = props;

  const {user} = useUser();

  if (!user) {
    return <>
      <P>You need to <Link to="/login">login</Link> to copy this page to your workspace.</P>
      <P>Not got an account? Schedule a demo</P>
    </>;
  }

  const {workspaces} = user;

  if (workspaces.length === 0) {
    return <>
      <P>You do not have a workspace to copy to.</P>
      <P><Link to="/workspaces">Manage workspaces</Link></P>
    </>;
  }

  useEffect(() => {
    onChange(workspaces[0].urlName);
  }, []);

  return (
    <FormGroup>
      <Label id='copy-label'>Which workspace do you want to copy to?</Label>
      <Select
        defaultValue={workspaces[0].urlName}
        onChange={onChange}
        ariaLabelledBy='copy-label'>
        {workspaces.map(({urlName, name}) =>
          <Option value={urlName} key={urlName}>{name}</Option>)}
      </Select>
    </FormGroup>
  );
}

export default PageDuplicaterModalContent;
