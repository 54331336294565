import React from 'react';
import PropTypes from 'prop-types';
import Heading1 from 'components/Heading1';
import Heading2 from 'components/Heading2';
import Heading3 from 'components/Heading3';
import Table, {TableRow, TableCell} from 'components/Table';
import Text from 'components/Text';
import Indent from 'components/Indent';
import Video from '../elements/Video';
import ListItem from '../elements/ListItem';
import OrderedListItem from '../elements/OrderedListItem';
import DocumentTitle from '../elements/DocumentTitle';
import Image from '../elements/Image';
import Link from '../elements/Link';
import CodeBlock from '../elements/CodeBlock';

Element.propTypes = {
  element: PropTypes.shape({
    type: PropTypes.string.isRequired,
    indent: PropTypes.number,
    id: PropTypes.string,
    checked: PropTypes.bool,
  }).isRequired,
  mode: PropTypes.string.isRequired,
};

function renderElementInInput (props) {
  const {
    element: {
      indent,
    },
  } = props;

  if (indent) {
    return <Indent level={indent}>{Element(props)}</Indent>;
  }

  return Element(props);
}

function Element (props) {
  const {element} = props;
  const {type} = element;

  switch (type) {
    case 'code-block':
      return <CodeBlock {...props} />;
    case 'title':
      return <DocumentTitle {...props} />;
    case 'heading1':
      return <Heading1 {...props} />;
    case 'heading2':
      return <Heading2 {...props} />;
    case 'heading3':
      return <Heading3 {...props} />;
    case 'image':
      return <Image {...props} />;
    case 'link':
      return <Link {...props} />;
    case 'list-item':
      return <ListItem {...props} />;
    case 'ordered-list-item':
      return <OrderedListItem {...props} />;
    case 'paragraph':
      return <Text {...props} />;
    case 'table':
      return <Table {...props} />;
    case 'table-row':
      return <TableRow {...props} />;
    case 'table-cell':
      return <TableCell {...props} />;
    case 'video':
      return <Video {...props} />;
    default:
      return <div {...props} />;
  }
}

export default renderElementInInput;
