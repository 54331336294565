import React from 'react';
import PropTypes from 'prop-types';
import FooterToolbar from 'components/FooterToolbar';
import ContentContainer from 'components/ContentContainer';
import Button, {ButtonSpacer} from 'components/Button';
import {motion} from 'framer-motion';
import DropdownButton from 'components/DropdownButton';
import dayjs from 'dayjs';
import {useUser} from 'containers/UserApp/providers/UserProvider';
import Link from 'components/Link';
// eslint-disable-next-line no-unused-vars
import tw, {css, styled} from 'twin.macro';
import {useItem} from 'providers/ItemProvider';
import {ITEM_TYPE} from 'am-constants';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';
import FlexSpacer from 'components/FlexSpacer';

DocumentFormPageActionBar.propTypes = {
  displayingMoreConfetti: PropTypes.bool.isRequired,
  onMoreConfetti: PropTypes.func.isRequired,
  onNoMoreConfetti: PropTypes.func.isRequired,
  onReactivate: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submittedAt: PropTypes.number,
  submittedByUser: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
};

function DocumentFormPageActionBar (props) {
  const {
    displayingMoreConfetti,
    onMoreConfetti,
    onNoMoreConfetti,
    onReactivate,
    onSubmit,
    submittedAt,
    submittedByUser,
    submitting,
  } = props;

  const {user} = useUser();
  const {urlName: workspaceUrlName} = useWorkspace();
  const {item} = useItem();
  const {itemTypeId, urlName} = item;
  const submitted = !!submittedAt;

  const submitLabel = (item.details && item.details['labels.buttons.submit']) ||
    (itemTypeId === ITEM_TYPE.CHECKLIST ? 'Complete checklist' : 'Submit');
  const reactivateLabel = (item.details && item.details['labels.buttons.reactivate']) ||
    (itemTypeId === ITEM_TYPE.CHECKLIST ? 'Reactivate checklist' : 'Reactivate form');
  const exitLabel = (item.details && item.details['labels.buttons.exit']) ||
    (itemTypeId === ITEM_TYPE.CHECKLIST ? 'Exit checklist' : 'Exit form');

  const bottomMenu = [
    [
      {
        onClick: onReactivate,
        label: reactivateLabel,
      },
      {
        to: `/${workspaceUrlName}/${urlName}`,
        label: exitLabel,
      },
    ],
  ];

  return (
    <FooterToolbar>
      <ContentContainer tw="flex items-center">
        <Info>
          {submitted && `Completed on ${dayjs(submittedAt).format('D MMMM YYYY')} at ${dayjs(submittedAt).format('H:mm')}`}
        </Info>
        <FlexSpacer />
        <>
          {!submitted && <Button green lg onClick={onSubmit} disabled={submitting}>{submitLabel}</Button>}

          {submitted && submittedByUser && (
            <motion.div
              initial={{opacity: 0}}
              animate={{opacity: 1, transition: {delay: 2, duration: 1}}}
              tw="flex"
            >
              {user && <DropdownButton lg menu={bottomMenu} direction="up">Options</DropdownButton>}
              {!user && <Button lg onClick={onReactivate}>{reactivateLabel}</Button>}
              <ButtonSpacer />
              {displayingMoreConfetti && <Button danger lg onClick={onNoMoreConfetti}>That's enough!</Button>}
              {!displayingMoreConfetti && <Button primary lg onClick={onMoreConfetti}>More confetti</Button>}
            </motion.div>
          )}

          {submitted && !submittedByUser && <>
            {user && <>
              <Link to={`/${workspaceUrlName}/${urlName}`}><Button lg>Exit</Button></Link>
              <ButtonSpacer />
            </>}
            <Button primary lg onClick={onReactivate}>{reactivateLabel}</Button>
          </>}
        </>
      </ContentContainer>
    </FooterToolbar>
  );
}

const Info = styled.div((props) => [
  css`
    @media (max-width: 640px) {
      display: none;
    }
  `,
]);

export default DocumentFormPageActionBar;
