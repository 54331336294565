import React, {useState} from 'react';
import PropTypes from 'prop-types';
import P from 'components/P';
import useDuplicatePageMutation from './hooks/useDuplicatePageMutation';
import PageDuplicaterModal from './components/PageDuplicaterModal';

PageDuplicaterContainer.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  setIsVisible: PropTypes.func.isRequired,
  itemName: PropTypes.string.isRequired,
  itemRef: PropTypes.string,
  itemKey: PropTypes.string,
};

function PageDuplicaterContainer (props) {
  const {
    isVisible,
    setIsVisible,
    itemName,
    itemRef,
    itemKey,
  } = props;

  const closeModal = () => {
    setIsVisible(false);
    setDestinationWorkspaceUrlName(undefined);
  };

  const [destinationWorkspaceUrlName, setDestinationWorkspaceUrlName] = useState();
  const [submittedDestinationWorkspaceUrlName, setSubmittedDestinationWorkspaceUrlName] = useState();

  const [onDuplicatePage, {pageDuplicate, error}] = useDuplicatePageMutation({
    destinationWorkspaceUrlName,
    itemRef,
    itemKey,
  });

  const onChange = (urlName) => {
    setDestinationWorkspaceUrlName(urlName);
  };

  const onSubmit = async () => {
    await onDuplicatePage();
    setIsVisible(false);
    setSubmittedDestinationWorkspaceUrlName(destinationWorkspaceUrlName);
    setDestinationWorkspaceUrlName(undefined);
  };

  if (pageDuplicate) {
    window.location = `/${submittedDestinationWorkspaceUrlName}/${pageDuplicate.urlName}/edit`;
    return null;
  }

  const renderError = () => (
    <P bottom>Please check your connection and try again.</P>
  );

  return (
    <PageDuplicaterModal
      closeModal={closeModal}
      destinationWorkspaceUrlName={destinationWorkspaceUrlName}
      itemName={itemName}
      isVisible={isVisible}
      onChange={onChange}
      onSubmit={onSubmit}
      renderError={error && renderError}
    />
  );
}

export default PageDuplicaterContainer;
