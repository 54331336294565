import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Portal} from 'react-portal';
import {
  AiOutlinePicCenter,
  AiOutlinePicLeft,
  AiOutlinePicRight,
} from 'react-icons/ai';
import {useSlateStatic} from 'slate-react';
import throttle from 'lodash.throttle';
import Toolbar from './Toolbar';
import ToolbarItem from './ToolbarItem';
import alignImage from '../utils/alignImage';

ImageFormattingToolbar.propTypes = {
  imageNode: PropTypes.shape({
    getBoundingClientRect: PropTypes.func,
  }).isRequired,
};

function ImageFormattingToolbar (props) {
  const {
    imageNode,
  } = props;

  const [position, setPosition] = useState({});
  const editor = useSlateStatic();

  const menuItems = [
    {
      title: 'Align left and wrap text',
      onClick: () => alignImage(editor, 'left'),
      icon: AiOutlinePicLeft,
    },
    {
      title: 'Do not wrap text',
      onClick: () => alignImage(editor, null),
      icon: AiOutlinePicCenter,
    },
    {
      title: 'Align right and wrap text',
      onClick: () => alignImage(editor, 'right'),
      icon: AiOutlinePicRight,
    },
  ];

  const reposition = () => {
    const rect = imageNode.getBoundingClientRect();

    setPosition({
      top: rect.top + window.pageYOffset - 56,
      left: rect.left,
      width: rect.width,
    });
  };

  useEffect(() => {
    const handleResize = throttle(reposition, 100);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(reposition, [imageNode]);

  return (
    <Portal>
      <div style={{
        position: 'absolute',
        zIndex: 10,
        display: 'flex',
        pointerEvents: 'none',
        ...position,
      }}>
        <Toolbar arrow style={{margin: '0 auto'}} onMouseUp={reposition}>
          {menuItems.map((menuItem, i) => (
            <ToolbarItem
              editor={editor}
              key={`balloon-menu-item-${i}`}
              iconSize={16}
              subtleHover
              {...menuItem} />
          ))}
        </Toolbar>
      </div>
    </Portal>
  );
}

export default React.memo(ImageFormattingToolbar);
