import getElementById from 'containers/SlateEditor/utils/getElementById';
import {Editor} from 'slate';

function getNumStepsInProcess (editor, id) {
  // eslint-disable-next-line no-unused-vars
  const [processNode, processNodePath] = getElementById(editor, id);

  const processStepNodes = Editor.nodes(editor, {
    at: processNodePath,
    match: (n) => n.type === 'process-step',
  });

  return Array.from(processStepNodes).length;
}

export default getNumStepsInProcess;
