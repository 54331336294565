import tw, {styled} from 'twin.macro';

const PageCenter = styled.div((props) => [
  tw`
    flex
    items-center
    justify-center
    h-screen
    w-full
  `,
]);

export default PageCenter;
