import React, {useState} from 'react';
import PropTypes from 'prop-types';

const TopbarContext = React.createContext();

TopbarProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

function TopbarProvider (props) {
  const {children} = props;

  const [printMode, setPrintMode] = useState(false);
  const [printModeSupport, setPrintModeSupport] = useState(false);
  const [hideSearch, setHideSearch] = useState(true);

  const context = {
    printMode,
    setPrintMode,
    printModeSupport,
    setPrintModeSupport,
    hideSearch,
    setHideSearch,
  };

  return <>
    <TopbarContext.Provider value={context}>
      {children}
    </TopbarContext.Provider>
  </>;
}

export function useTopbar () {
  const context = React.useContext(TopbarContext);

  if (context === undefined) {
    throw new Error('useTopbar must be used within a TopbarProvider');
  }

  return context;
}

export default TopbarProvider;
