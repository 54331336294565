import {Editor, Transforms} from 'slate';

function onEnterInRatingChoice (editor, event, selectionMeta) {
  const {
    selectionStart,
    selectionStartElement,
    selectionStartElementPath,
  } = selectionMeta;

  if (
    event.key !== 'Enter' ||
    !selectionStart ||
    selectionStartElement.type !== 'choice'
  ) {
    return false;
  }

  const [choiceGroupElement, choiceGroupElementPath] = Editor.parent(editor, selectionStartElementPath);
  const {format} = choiceGroupElement;

  if (format !== 'rating') {
    return false;
  }

  event.preventDefault();

  const nextPointAfterFormGroup = Editor.after(editor, Editor.end(editor, choiceGroupElementPath));
  Transforms.select(editor, nextPointAfterFormGroup);

  return true;
}

export default onEnterInRatingChoice;
