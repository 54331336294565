import {gql} from '@apollo/client';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import graphQLClient from 'utils/graphQLClient';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';

const query = gql`
  mutation automationDelete($automationId: Int!) {
    automationDelete(automationId: $automationId) {
      id
    }
  }
`;
function useDeleteAutomationMutation () {
  const queryClient = useQueryClient();
  const {urlName: workspaceUrlName} = useWorkspace();

  const mutationFn = async ({automationId}) => {
    const variables = {
      automationId,
    };

    await graphQLClient.request(query, variables);
  };

  const options = {
    onSuccess: (_, {itemRef, itemKey}) => {
      queryClient.invalidateQueries([
        workspaceUrlName, 'items', {ref: itemRef, key: itemKey}, 'side-panel',
      ]);
    },
  };

  return useMutation(mutationFn, options);
}

export default useDeleteAutomationMutation;
