import React from 'react';
import PropTypes from 'prop-types';
import A from 'components/A';
import {sanitizeUrl} from '@braintree/sanitize-url';

Link.propTypes = {
  attributes: PropTypes.shape({}).isRequired,
  element: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

function Link (props) {
  const {
    children,
    element,
  } = props;

  const sanitizedUrl = sanitizeUrl(element.url);
  const isAbsoluteUrl = sanitizedUrl.startsWith('http://') || sanitizedUrl.startsWith('https://');
  const isMailToUrl = sanitizedUrl.startsWith('mailto:');

  return <A href={isAbsoluteUrl || isMailToUrl ? sanitizedUrl : `//${sanitizedUrl}`} target="_blank">{children}</A>;
}

export default Link;
