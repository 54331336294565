import React from 'react';
import PropTypes from 'prop-types';
import Pill from 'components/Pill';
import {ITEM_TYPE} from 'am-constants';
import getFriendlyDate from 'utils/getFriendlyDate';

NextDueCellContent.propTypes = {
  itemId: PropTypes.number,
  itemTypeId: PropTypes.number.isRequired,
  contentActionNeededId: PropTypes.number,
  contentPriority: PropTypes.string,
  nextDueAt: PropTypes.string,
};

NextDueCellContent.defaultProps = {
};

const PAGE_ITEM_TYPES = [
  ITEM_TYPE.CHECKLIST,
  ITEM_TYPE.DOCUMENT,
  ITEM_TYPE.FORM,
];

function NextDueCellContent (props) {
  const {
    itemId,
    itemTypeId,
    nextDueAt,
  } = props;

  if (!itemId) {
    return null;
  }

  if (!PAGE_ITEM_TYPES.includes(itemTypeId)) {
    return null;
  }

  if (!nextDueAt) {
    return null;
  }

  const hasNextDuePassed = parseInt(nextDueAt, 10) < Date.now();

  return (
    <Pill red={hasNextDuePassed}>
      {getFriendlyDate(nextDueAt, false)}
    </Pill>
  );
}

export default NextDueCellContent;
