function getIsUrlUsingLegacyDollarSeparator () {
  const url = window.location.pathname;

  const legacyUrlRegex = /^\/(.+\/)?[^/]+--[a-zA-Z0-9]{24}\$[a-zA-Z0-9]{12}(\/.+)?$/;
  const isUrlUsingLegacyDollarSeparator = legacyUrlRegex.test(url);

  return isUrlUsingLegacyDollarSeparator;
}

export default getIsUrlUsingLegacyDollarSeparator;
