import React, {useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Editable, Slate} from 'slate-react';
import tw, {styled, css} from 'twin.macro';
// eslint-disable-next-line import/no-cycle
import {Transforms} from 'slate';
import renderElementInInput from '../editable/renderElementInInput';
import renderLeaf from '../editable/renderLeaf';

TextInputEditor.propTypes = {
  onChange: PropTypes.func,
  editor: PropTypes.shape({
    selection: PropTypes.shape({}),
    operations: PropTypes.array,
    normalizeNode: PropTypes.func,
    children: PropTypes.array,
  }),
  readOnly: PropTypes.bool,
  value: PropTypes.array,
};

TextInputEditor.defaultProps = {
};

function TextInputEditor (props) {
  const {
    onChange,
    editor,
    readOnly,
    value,
  } = props;

  // Update normalizeNode to remove any line breaks.
  // TODO: Extract this to a makeNormalizeNode file like in our main
  // SlateEditor.
  const {normalizeNode} = editor;
  editor.normalizeNode = ([node, path]) => {
    if (path.length === 0) {
      if (editor.children.length > 1) {
        Transforms.mergeNodes(editor);
      }
    }

    return normalizeNode([node, path]);
  };

  // If someone has changed a multi line text input into a single line text
  // input, this remove all line breaks.
  // Note: It only merges at the top level. This works while we're only
  // supporting plain text, but we may need to update this when adding support
  // for other elements.
  useEffect(() => {
    for (let i = editor.children.length - 1; i > 0; i--) {
      Transforms.mergeNodes(editor, {
        at: [i],
      });
    }
  });

  return <>
    <Slate
      editor={editor}
      value={value}
      onChange={onChange}>
      <StyledEditable
        placeholder=""
        renderElement={useCallback(renderElementInInput)}
        renderLeaf={useCallback(renderLeaf)}
        readOnly={readOnly}
        spellCheck />
    </Slate>
  </>;
}

const StyledEditable = styled(Editable)((props) => [
  tw`
    border
    mb-0.5
    px-2.5
    py-2.5
    border
    border-gray-200
    focus:outline-none
    focus:border-blue-300
    focus:bg-white
  `,
  props.readOnly && tw`
    cursor-not-allowed
  `,
  css`
    min-height: 46px;
  `,
]);

export default TextInputEditor;
