import PropTypes from 'prop-types';
import {useQuery} from '@tanstack/react-query';
import {gql} from 'graphql-request';
import {RESPONSE_TYPE} from 'am-constants';
import graphQLClient from 'utils/graphQLClient';

const query = gql`
  query GetItemDetailsForSidePanel($ref: String!, $key: String!) {
    item(ref: $ref, key: $key) {
      automations {
        id
        trigger
        action
        target
      }
      contentDescription
      responseStats {
        numActiveResponses
        numSubmittedResponses
        mostRecentSubmissionAt
      }
      document {
        documentSnapshot(version: "latest"){
            inputs {
              id
              label
              type
            }
          }
        responses(type: ${RESPONSE_TYPE.ACTIVE}) {
          responseNum
          urlName
          createdAt
          createdBy {
            email
            name
          }
          updatedAt
          responseSubmission {
            createdAt
          }
        }
      }
    }
  }
`;

useItemQuery.propTypes = {
  ref: PropTypes.string,
  key: PropTypes.string,
  workspaceUrlName: PropTypes.string,
};

function useItemQuery ({workspaceUrlName, ref, key}) {
  const cacheKey = [workspaceUrlName, 'items', {ref, key}, 'side-panel'];

  const variables = {
    workspaceUrlName,
    ref,
    key,
  };

  const queryFn = async () => {
    const {item} = await graphQLClient.request(query, variables);

    return {
      automations: item.automations,
      contentDescription: item.contentDescription,
      inputs: item.document?.documentSnapshot?.inputs,
      responses: item.document?.responses,
      responseStats: item.responseStats,
    };
  };

  const options = {};

  return useQuery(
    cacheKey,
    queryFn,
    options
  );
}

export default useItemQuery;
