import isEqual from 'lodash.isequal';
import {Transforms} from 'slate';
import getElementById from '../utils/getElementById';
import insertProcessStep from '../utils/insertProcessStep';

const makeOnDragEnd = (editor) => (result) => {
  const {
    draggableId: processStepId,
    destination,
    source,
  } = result;

  if (
    !destination ||
    isEqual(source, destination)
  ) {
    return;
  }

  const {
    droppableId: newProcessId,
    index: newIndex,
  } = destination;
  const {droppableId: oldProcessId} = source;

  const [processStepNode, processStepNodePath] = getElementById(editor, processStepId); // eslint-disable-line no-unused-vars
  const [oldProcessNode, oldProcessNodePath] = getElementById(editor, oldProcessId); // eslint-disable-line no-unused-vars
  const [newProcessNode, newProcessNodePath] = getElementById(editor, newProcessId); // eslint-disable-line no-unused-vars

  Transforms.moveNodes(editor, {
    at: processStepNodePath,
    to: [...newProcessNodePath, 1, newIndex],
  });

  const oldProcessNodeWillBeEmpty = oldProcessNode.children[1].children.length === 1;
  if (oldProcessNodeWillBeEmpty) {
    insertProcessStep(editor, [...oldProcessNodePath, 1, 0]);
  }
};

export default makeOnDragEnd;
