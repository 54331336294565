import {gql} from '@apollo/client';
import useMutation from 'hooks/useMutation';
import {useState} from 'react';

const CREATE_WORKSPACE = gql`
  mutation workspaceCreate($name: String!, $urlName: String!) {
    workspaceCreate(name: $name, urlName: $urlName) {
      urlName
    }
  }
`;

function useCreateWorkspaceMutation (props) {
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [createWorkspace, {
    loading,
  }] = useMutation(CREATE_WORKSPACE, {
    fetchPolicy: 'no-cache',
    onError (e) {
      setError(e);
      setData(undefined);
    },
    onCompleted (result) {
      setError(undefined);
      setData(result.workspaceCreate);
    },
    refetchQueries: ['GetUser', 'GET_USER_AND_USER_WORKSPACES', 'GetWorkspace'],
  });

  return [
    createWorkspace,
    {
      data,
      loading,
      error,
    },
  ];
}

export default useCreateWorkspaceMutation;
