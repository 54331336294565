import React from 'react';
import PropTypes from 'prop-types';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';
import P from 'components/P';
import {STATUS_CODE} from 'am-constants';
import useAddTeamMemberMutation from './hooks/useAddTeamMemberMutation';
import TeamMemberAdderModal from './components/TeamMemberAdderModal';

TeamMemberAdder.propTypes = {
  email: PropTypes.string.isRequired,
  handleEmailChange: PropTypes.func.isRequired,
  handleRoleChange: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  role: PropTypes.number.isRequired,
  resetFields: PropTypes.func.isRequired,
  setIsVisible: PropTypes.func.isRequired,
};

function TeamMemberAdder (props) {
  const {
    email,
    handleEmailChange,
    handleRoleChange,
    isVisible,
    role,
    resetFields,
    setIsVisible,
  } = props;

  const {id: workspaceId} = useWorkspace();

  const [addTeamMember, {error, reset: resetMutationState}] = useAddTeamMemberMutation();

  const closeModal = () => {
    resetFields();
    resetMutationState();
    setIsVisible(false);
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    try {
      await addTeamMember({variables: {workspaceId, email, role}});
    }
    catch (e) {
      return;
    }

    resetFields();
    closeModal();
  };

  const errorCode = error &&
  error.graphQLErrors &&
  error.graphQLErrors[0] &&
  error.graphQLErrors[0].extensions.exception.statusCode;

  const renderError = errorCode === STATUS_CODE.COULD_NOT_PROCESS
    ? (() => (<P bottom>This person is already a member of this workspace.</P>))
    : (() => (<P bottom>Please check your connection and try again.</P>));

  return (
    <TeamMemberAdderModal
      closeModal={closeModal}
      email={email}
      handleEmailChange={handleEmailChange}
      handleRoleChange={handleRoleChange}
      isVisible={isVisible}
      onSubmit={onSubmit}
      renderError={error && renderError}
      role={role}
      setIsVisible={setIsVisible}
    />

  );
}

export default TeamMemberAdder;
