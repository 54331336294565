import PropTypes from 'prop-types';
import {useQuery} from '@tanstack/react-query';
import {gql} from 'graphql-request';
import {ITEM_FIELDS} from 'fragments/Item';
import {BREADCRUMB_FIELDS} from 'fragments/Breadcrumb';
import graphQLClient from 'utils/graphQLClient';

const query = gql`
  query GetWorkspaceItem($workspaceUrlName: String!) {
    workspace(workspaceUrlName: $workspaceUrlName) {
      workspaceItem {
        ${ITEM_FIELDS}
        breadcrumbs {
          ${BREADCRUMB_FIELDS}
        }
        children {
          ${ITEM_FIELDS}
        }
      }
    }
  }
`;

useWorkspaceItemQuery.propTypes = {
  workspaceUrlName: PropTypes.string,
  enabled: PropTypes.bool,
};

function useWorkspaceItemQuery ({workspaceUrlName, enabled}) {
  const cacheKey = [workspaceUrlName, 'workspaceItem'];

  const variables = {
    workspaceUrlName,
  };

  const queryFn = async () => {
    const {workspace} = await graphQLClient.request(query, variables);
    return workspace.workspaceItem;
  };

  const options = {enabled};

  return useQuery(cacheKey, queryFn, options);
}

export default useWorkspaceItemQuery;
