import React from 'react';
import tw from 'twin.macro'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import PageTitle from 'components/PageTitle';
import Placeholder from '../Placeholder';

DocumentTitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  attributes: PropTypes.shape({}).isRequired,
};

DocumentTitle.defaultProps = {
};

function DocumentTitle (props) {
  const {
    children,
    ...rest
  } = props;

  return (
    <PageTitle data-test-id="document-title" id='title-element-id' className={'warning-locator'} {...rest}>
      <Placeholder text={<><span tw="hidden sm:inline">Give your page a title</span><span tw="inline sm:hidden">Give me a title</span></>}>
        {children}
      </Placeholder>
    </PageTitle>
  );
}

export default DocumentTitle;
