function getImages (node, result = []) {
  if (node.type === 'image') {
    result.push(node);
  }

  if (node.children) {
    // eslint-disable-next-line no-restricted-syntax
    for (const child of node.children) {
      getImages(child, result);
    }
  }

  return result;
}

export default getImages;
