import React from 'react';
import Modal from 'components/Modal';
import Button, {ButtonSpacer} from 'components/Button';
import PropTypes from 'prop-types';
import FormGroup from 'components/FormGroup';
import Label from 'components/Label';
import Select, {Option} from 'components/Select';
import Input from 'components/Input';
import P from 'components/P';
import A from 'components/A';
import {ACTION, ITEM_TYPE, TRIGGER} from 'am-constants';
import Code from 'components/Code';
import SlackActionContent from './SlackActionContent';
import TeamsActionContent from './TeamsActionContent';

ItemAutomationCreatorModal.propTypes = {
  action: PropTypes.oneOf(Object.values(ACTION)),
  closeModal: PropTypes.func.isRequired,
  exampleWebhookCallContent: PropTypes.object.isRequired,
  trigger: PropTypes.oneOf(Object.values(TRIGGER)),
  hasAutomationPermissions: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  itemTypeId: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
  renderError: PropTypes.func,
  setAction: PropTypes.func.isRequired,
  setTrigger: PropTypes.func.isRequired,
  setTarget: PropTypes.func.isRequired,
  target: PropTypes.string,
};

function ItemAutomationCreatorModal (props) {
  const {
    action,
    closeModal,
    exampleWebhookCallContent,
    trigger,
    hasAutomationPermissions,
    isVisible,
    itemTypeId,
    onSubmit,
    renderError,
    setAction,
    setTrigger,
    setTarget,
    target,
  } = props;

  const isForm = itemTypeId === ITEM_TYPE.FORM;
  const itemType = isForm ? 'form' : 'checklist';

  const isFieldEmpty = !action || !trigger || !target;
  const isSubmitDisabled = isFieldEmpty || action === ACTION.SLACK || action === ACTION.TEAMS;

  return (
    <Modal
      visible={isVisible}
      title="Create automation"
      onClose={closeModal}
      onSubmit={onSubmit}
      renderError={renderError}
      size="lg"
      footer={
        <>
          <Button type="button" link onClick={closeModal}>
            {hasAutomationPermissions ? 'Cancel' : 'Close'}
          </Button>
          <ButtonSpacer />
          {/* {action === 1 && hasAutomationPermissions && <Button onClick={onSubmit}>Send test email</Button>}
          {action === 4 && hasAutomationPermissions && (
            <Button type="button" onClick={onSubmit}>Make test API call</Button>
          )} */}
          {hasAutomationPermissions && (
            <Button primary type="submit" disabled={isSubmitDisabled} value="Submit">
              Create
            </Button>
          )}
        </>
      }
    >
      <FormGroup top>
        <Label id="trigger-label">When should this automation run?</Label>
        <Select
          onChange={setTrigger}
          disabled={!hasAutomationPermissions}
          value={trigger}
          ariaLabelledBy="trigger-label">
          <Option value={TRIGGER.CREATE_RESPONSE}>When the {itemType} is started</Option>
          <Option value={TRIGGER.SUBMIT_RESPONSE}>When the {itemType} is {isForm ? 'submitted' : 'completed'}</Option>
          {/* <Option value={3}>When a checklist is reactivated</Option> */}
        </Select>
      </FormGroup>
      <FormGroup>
        <Label id="action-label">What should happen?</Label>
        <Select
          onChange={setAction}
          disabled={!hasAutomationPermissions}
          value={action}
          ariaLabelledBy="action-label">
          <Option value={ACTION.EMAIL}>Send an email</Option>
          <Option value={ACTION.SLACK}>Send a message to Slack</Option>
          {/* <Option value={ACTION.TEAMS}>Send a message to Teams</Option> */}
          <Option value={ACTION.WEBHOOK}>Make an API call to a webhook</Option>
        </Select>
      </FormGroup>
      {action === ACTION.SLACK && <SlackActionContent/>}
      {action === ACTION.TEAMS && <TeamsActionContent/>}
      {action === 'email' && (
        <FormGroup>
          <Label>Which email address should we send this to?</Label>
          <Input
            required
            type="email"
            autocomplete="off"
            data-lpignore="true"
            maxLength={500}
            value={target}
            onChange={(e) => setTarget(e.target.value)}
          />
          {/* <InputHelpText>
            Separate multiple email addresses with a comma
          </InputHelpText> */}
        </FormGroup>
      )}
      {action === 'webhook' && (
        <>
          <P>
            We will send data in the format below to a URL that you provide.
            This can be used in combination with{' '}
            <A href="https://www.zapier.com/" target="_blank">
              Zapier
            </A>{' '}
            to pass the data onto 1000s of other tools.
          </P>
          <Code tw="h-48 overflow-scroll whitespace-pre">
            {JSON.stringify(exampleWebhookCallContent, null, 2)}
          </Code>

          <FormGroup>
            <Label>What URL should we send the data to?</Label>
            <Input
              required
              maxLength={500}
              value={target}
              type="url"
              onChange={(e) => setTarget(e.target.value)}
            />
          </FormGroup>
        </>
      )}
    </Modal>
  );
}

export default ItemAutomationCreatorModal;
