import {Transforms} from 'slate';

function makeSureDocumentHasOneTitle (editor) {
  return (
    makeSureTheFirstElementIsADocumentTitle(editor) ||
    onlyAllowOneDocumentTitle(editor)
  );
}

function makeSureTheFirstElementIsADocumentTitle (editor) {
  if (editor.children[0].type === 'title') {
    return false;
  }

  Transforms.setNodes(
    editor,
    {type: 'title'},
    {at: [0]}
  );

  return true;
}

// This handles a user pasting multiple lines into the document title.
function onlyAllowOneDocumentTitle (editor) {
  const titleIndexes = editor.children.reduce((acc, node, index) => {
    if (node.type === 'title') {
      return [...acc, index];
    }

    return acc;
  }, []);

  if (titleIndexes.length === 1) {
    return false;
  }

  titleIndexes.forEach((titleIndex) => {
    if (titleIndex === 0) {
      return;
    }

    Transforms.setNodes(
      editor,
      {type: 'paragraph'},
      {at: [titleIndex]}
    );
  });

  return true;
}

export default makeSureDocumentHasOneTitle;
