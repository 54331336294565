import React from 'react';
import {createRoot} from 'react-dom/client';
import TagManager from 'react-gtm-module';
import './index.css';
import './array-flat-map-polyfill';
import App from './App';
import 'antd/dist/antd.min.css';

TagManager.initialize({
  gtmId: 'GTM-NPXW537',
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

// root.render(<React.StrictMode>
//   <App />
// </React.StrictMode>);
