import {gql} from '@apollo/client';
import useMutation from 'hooks/useMutation';
import {useState} from 'react';

const RESET_PASSWORD = gql`
  mutation userResetPassword($resetKey: String!, $newPassword: String!) {
    userResetPassword(resetKey: $resetKey, newPassword: $newPassword)
  }
`;

function useResetPasswordMutation (props) {
  const [error, setError] = useState(null);
  const [resetPassword, {
    loading,
    data,
  }] = useMutation(RESET_PASSWORD, {
    fetchPolicy: 'no-cache',
    onError (e) {
      setError(e);
    },
    onCompleted (_) {
      setError(undefined);
    },
  });

  return [
    resetPassword,
    {
      data,
      loading,
      error,
    },
  ];
}

export default useResetPasswordMutation;
