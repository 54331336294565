import React from 'react';
import PropTypes from 'prop-types';
import P from 'components/P';
import TextArea from 'components/TextArea';

UseAIScreen.propTypes = {
  setAdditionalContext: PropTypes.func.isRequired,
  isStreamLoading: PropTypes.bool.isRequired,
};

function UseAIScreen ({setAdditionalContext, isStreamLoading}) {
  return (<>
    <P>Provide some more context to help AI generate your checklist.</P>
    <TextArea
      disabled={isStreamLoading}
      onChange={(e) => setAdditionalContext(e.target.value)}
      tip="Tip: You can paste in an existing document or video transcript." />
  </>);
}

export default UseAIScreen;
