import {Transforms} from 'slate';
import makeLabel from '../factory/makeLabel';

function makeSureFormGroupsHaveALabelAndNoParagraphs (editor, [entryNode, entryNodePath]) {
  if (entryNode.type !== 'form-group') {
    return false;
  }

  // If no label, add it in. Label can get removed when cutting.
  const hasLabel = entryNode.children.some((node) => node.type === 'label');
  if (!hasLabel) {
    Transforms.insertNodes(editor, makeLabel(), {
      at: [...entryNodePath, 0],
    });
  }

  // Remove any paragraphs. These can get left when cutting content out of the
  // form group.
  Transforms.removeNodes(editor, {
    at: entryNodePath,
    match: (node) => node.type === 'paragraph',
  });

  return true;
}

export default makeSureFormGroupsHaveALabelAndNoParagraphs;
