import tw, {styled} from 'twin.macro';

const ContentContainer = styled.div((props) => [
  tw`
    mx-auto
    max-w-3xl
    w-full
    px-4
  `,
]);

export default ContentContainer;
