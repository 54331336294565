import tw, {styled} from 'twin.macro';

const UL = styled.ul((props) => [
  tw`
    list-disc
    pl-6
    overflow-hidden
    mb-5
  `,
  props.indent % 3 === 1 &&
    `
    list-style-type: circle;
  `,
  props.indent % 3 === 2 &&
    `
    list-style-type: square;
  `,
]);

export default UL;
