import React from 'react';
import PropTypes from 'prop-types';

import tw, {styled} from 'twin.macro';
import Placeholder from '../Placeholder';

Label.propTypes = {
  attributes: PropTypes.shape({}).isRequired,
  element: PropTypes.shape({
    indent: PropTypes.number,
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

function Label (props) {
  const {
    children,
  } = props;

  return (
    <Wrapper>
      <Placeholder text="Ask a question">
        {children}
      </Placeholder>
    </Wrapper>
  );
}

const Wrapper = styled.div((props) => [
  tw`
    relative
    mb-1
  `,
]);

export default Label;
