import {Editor, Transforms} from 'slate';

function changeChoiceGroupFormat (editor, format) {
  // eslint-disable-next-line no-unused-vars
  const [formGroup, formGroupPath] = Editor.above(editor, {
    match: (n) => n.type === 'form-group',
  });

  Transforms.setNodes(
    editor,
    {format},
    {
      match: (n) => n.type === 'choice-group',
      at: formGroupPath,
    }
  );
}

export default changeChoiceGroupFormat;
