import tw, {styled} from 'twin.macro';

const Pill = styled.span((props) => [
  tw`
    bg-gray-100
    text-gray-700
    py-1
    px-2
    rounded-full
    text-2xs
    flex
    items-center
    gap-1
  `,
  props.green && tw`
    bg-green-500
    text-white
  `,
  props.blue && tw`
    bg-blue-500
    text-white
  `,
  (props.color === 'red' || props.red) && tw`
    bg-red-500
    text-white
  `,
  props.purple && tw`
    bg-purple-500
    text-white
  `,
  props.yellow && tw`
    bg-yellow-700
    text-white
  `,
]);

export const PillSpacer = styled.div((props) => [
  tw`
    w-1
  `,
]);

export default Pill;
