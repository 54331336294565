import React from 'react';
import {navigate} from '@reach/router';
import WorkspaceCreatorPage from './components/WorkspaceCreatorPage';
import useCreateWorkspaceMutation from './hooks/useCreateWorkspaceMutation';

function WorkspaceCreator () {
  const [createWorkspace, {
    data,
    error,
    loading,
  }] = useCreateWorkspaceMutation();

  const onSubmit = async ({
    name, urlName,
  }) => createWorkspace({
    variables: {
      name, urlName,
    },
  });

  if (data && !loading) {
    navigate('/workspaces');
  }

  return <WorkspaceCreatorPage
    createWorkspace={onSubmit}
    error={error}
    loading={!data && loading}
  />;
}

export default WorkspaceCreator;
