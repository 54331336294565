import {Editor, Element} from 'slate';

function isLinkActive (editor) {
  const [link] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && Element.isElement(n) && n.type === 'link',
  });
  return !!link;
}

export default isLinkActive;
