import makeVideo from '../factory/makeVideo';
import insertNodeAtSelection from './insertNodeAtSelection';

function insertVideo (editor) {
  // eslint-disable-next-line no-alert
  const url = window.prompt('Enter a YouTube, Loom, Wistia, MS Stream or Vimeo URL');

  if (!url || url.trim().length === 0) {
    return;
  }

  insertNodeAtSelection(editor, {makeNode: () => makeVideo(url)});
}

export default insertVideo;
