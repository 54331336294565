import React, {useReducer} from 'react';
import PropTypes from 'prop-types';
import {useItem} from 'providers/ItemProvider';
import {DOCUMENT_WARNINGS, ITEM_TYPE} from 'am-constants';
import {getContentType} from 'am-utils';
import WarningStepTitleVerb from '../components/WarningStepTitleVerb';
import useDisabledDocumentWarningsQuery from '../hooks/useDisabledDocumentWarningsQuery';
import WarningStepTitleLong from '../components/WarningStepTitleLong';
import WarningTitleHowTo from '../components/WarningTitleHowTo';
import WarningTitleLong from '../components/WarningTitleLong';
import WarningTitleVerb from '../components/WarningTitleVerb';
import WarningStepNoHelpBlocks from '../components/WarningStepNoHelpBlocks';
import WarningIntroNoHelpBlocks from '../components/WarningIntroNoHelpBlocks';
import getImages from '../utils/getImages';
import getVisibleImages from '../utils/getVisibleImages';
import WarningImages from '../components/WarningImages';
import getTotalTextLength from '../utils/getTotalTextLength';
import doesTitleStartWithHowTo from '../utils/doesTitleStartWithHowTo';
import isFirstWordVerb from '../utils/isFirstWordVerb';
import isTitleTooLong from '../utils/isTitleTooLong';

const CommentsContext = React.createContext();
const CLOSED_WARNING_HEIGHT = 46;
const COMMENT_GAP = 4;

CommentsProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  content: PropTypes.arrayOf(PropTypes.shape({})),
};

function CommentsProvider (props) {
  const {children, content} = props;
  const [state, dispatch] = useReducer(reducer, []);
  const {item} = useItem();
  const {data: ignoredWarnings, isLoading} = useDisabledDocumentWarningsQuery({ref: item.ref, key: item.key});

  const closeComment = (commentId) =>
    dispatch({
      type: 'toggleOpen',
      commentId,
      open: false,
    });

  const updateCommentHeight = (commentId, height) =>
    dispatch({
      type: 'updateHeight',
      commentId,
      height,
    });

  const openComment = (commentId) =>
    dispatch({
      type: 'toggleOpen',
      commentId,
      open: true,
    });

  const removeComment = (commentId) =>
    dispatch({
      type: 'remove',
      commentId,
    });

  const recalculateWarningsAndPositions = ({focusedStepId}) =>
    dispatch({
      type: 'recalculateWarningsAndPositions',
      content,
      focusedStepId,
      ignoredWarnings,
    });

  const context = {
    closeComment,
    comments: state,
    ignoredWarnings,
    openComment,
    removeComment,
    updateCommentHeight,
    recalculateWarningsAndPositions,
  };

  if (isLoading) {
    return null;
  }

  return (
    <>
      <CommentsContext.Provider value={context}>
        {children}
      </CommentsContext.Provider>
    </>
  );
}

export function useComments () {
  const context = React.useContext(CommentsContext);

  if (context === undefined) {
    // Note - this is a warning, not an error, as we want to be able to turn off
    // and on the comments panel without breaking the app. In future once we
    // remove the feature flag, we can change this to an error.

    // throw new Error('useComments must be used within a CommentsProvider');

    // eslint-disable-next-line no-console
    console.warn('useComments must be used within a CommentsProvider');
    return {recalculateWarningsAndPositions: () => {}};
  }

  return context;
}

function reducer (state, action) {
  switch (action.type) {
    case 'remove': {
      const comments = state.filter((comment) => comment.id !== action.commentId);
      const commentsWithPositions = calculatePositions(comments);

      return commentsWithPositions;
    }
    case 'toggleOpen': {
      return state.map((comment) =>
        (comment.id === action.commentId
          ? {
            ...comment,
            isOpen: action.open,
          }
          : comment));
    }
    case 'updateHeight': {
      const comments = state.map((comment) =>
        (comment.id === action.commentId
          ? {
            ...comment,
            height: action.height,
          }
          : comment));

      const commentsWithPositions = calculatePositions(comments);
      return commentsWithPositions;
    }
    case 'recalculatePositions': {
      const commentsWithNewTops = calculateTops(state);
      const commentsWithNewTopsAndPositions = calculatePositions(commentsWithNewTops);
      return commentsWithNewTopsAndPositions;
    }
    case 'recalculateWarningsAndPositions': {
      const newWarnings = calculateWarnings({
        content: action.content,
        focusedStepId: action.focusedStepId,
        ignoredWarnings: action.ignoredWarnings,
        previousWarnings: state,
      });
      const warningsWithNewTops = calculateTops(newWarnings);
      const warningsWithNewTopsAndPositions = calculatePositions(warningsWithNewTops);

      return warningsWithNewTopsAndPositions;
    }
    default: {
      throw new Error('Unsupported action type');
    }
  }
}

/*
 * Every comment has a desired position on the page (`comment.top`).
 *
 * If a comment is open, then it takes its desired position on the page.
 *
 * Other comments will try and take their desired position. But if two comments
 * would be overlapping, then one of the comments will be moved out of the way.
 * This is done top to bottom, with the exception of any comments directly
 * before the open comment.
 *
 * The actual rendered position on the page is set as comment.offsetTop
 */

function calculatePositions (comments) {
  let openComment;
  const commentsBeforeOpenComment = [];

  const orderedComments = comments.sort((a, b) => a.top - b.top);
  const positionedComments = orderedComments.reduce((prev, curr) => {
    const prevTop = prev.length > 0 ? prev[prev.length - 1].offsetTop : 0;
    const prevHeight = prev.length > 0 ? prev[prev.length - 1].height : 0;

    const offsetTop = curr.isOpen ? curr.top : Math.max(curr.top, prevTop + prevHeight + COMMENT_GAP);
    const updatedComment = {
      ...curr,
      offsetTop,

    };

    if (curr.isOpen) {
      openComment = updatedComment;
    }
    else if (!openComment) {
      commentsBeforeOpenComment.push(updatedComment);
    }

    return [...prev, updatedComment];
  }, []);

  if (!openComment) {
    return positionedComments;
  }

  let prevComment = openComment;
  commentsBeforeOpenComment.reverse().every((curr) => {
    if (curr.offsetTop + curr.height + COMMENT_GAP > prevComment.offsetTop) {
      // eslint-disable-next-line
      curr.offsetTop = prevComment.offsetTop - curr.height - COMMENT_GAP;
      prevComment = curr;
      return true;
    }

    return false;
  });

  return positionedComments;
}

function calculateTops (orderedComments) {
  const ELEMENT_CENTRE_OFFSET = 10;
  const ELEMENTS_WITHOUT_CENTRING = [DOCUMENT_WARNINGS.INTRO_NO_HELP_BLOCKS, DOCUMENT_WARNINGS.IMAGES_TOO_MANY];

  const wrapperFromPageTopOffset = document.querySelector('[data-test-id="slate-editor-wrapper"]')?.getBoundingClientRect().top;
  const commentsWithNewTops = orderedComments.map((comment) => {
    const stepFromPageTopOffset = document.getElementById(comment.id)?.getBoundingClientRect().top;

    const additionalOffset = ELEMENTS_WITHOUT_CENTRING.includes(comment.type) ? 0 : ELEMENT_CENTRE_OFFSET;
    const newTop = stepFromPageTopOffset - wrapperFromPageTopOffset + additionalOffset;

    return {
      ...comment,
      top: newTop,
    };
  });

  return commentsWithNewTops;
}

function calculateWarnings ({
  content,
  focusedStepId,
  ignoredWarnings,
  previousWarnings,
}) {
  const warnings = [];

  const contentType = getContentType(content);
  if (contentType !== ITEM_TYPE.CHECKLIST) {
    return warnings;
  }

  const introWarnings = getIntroWarnings({content, ignoredWarnings});
  warnings.push(...introWarnings);

  const imageWarnings = getImageWarnings({content, ignoredWarnings});
  warnings.push(...imageWarnings);

  content.forEach((node) => {
    if (node.type === 'title') {
      const titleWarnings = getActiveTitleWarnings({
        node, ignoredWarnings, previousWarnings, focusedStepId,
      });
      warnings.push(...titleWarnings);
    }
    if (node.type === 'process' && node.open) {
      const processWarnings = getActiveProcessWarnings({
        process: node, ignoredWarnings, previousWarnings, focusedStepId,
      });
      warnings.push(...processWarnings);
    }
  });

  return warnings.map((w) => ({...w, left: 0, height: CLOSED_WARNING_HEIGHT}));
}

function getIntroWarnings ({content, ignoredWarnings}) {
  const introWarnings = [];

  const firstProcessIndex = content.findIndex((node) => node.type === 'process');
  if (firstProcessIndex === -1) {
    return [];
  }

  const introContent = content.slice(1, firstProcessIndex);
  const totalTextLength = getTotalTextLength({content: introContent});

  if (totalTextLength > 500) {
    introWarnings.push({
      id: 'first-element',
      component: WarningIntroNoHelpBlocks,
      type: DOCUMENT_WARNINGS.INTRO_NO_HELP_BLOCKS,
    });
  }

  const nonIgnoredWarnings = filterIgnoredWarningsByType({warnings: introWarnings, ignoredWarnings});

  return nonIgnoredWarnings;
}

function getImageWarnings ({content, ignoredWarnings}) {
  const imageWarnings = [];

  const images = content.map((nodes) => getImages(nodes)).flat();
  const visibleImages = content.map((nodes) => getVisibleImages(nodes)).flat();

  const moreThanFiveImages = images.length > 5;

  if (moreThanFiveImages) {
    const sixthImageNode = images[5];
    const sixthImageIsVisible = visibleImages.find((image) => image.id === sixthImageNode.id);

    if (sixthImageIsVisible) {
      imageWarnings.push({
        id: sixthImageNode.id,
        component: WarningImages,
        type: DOCUMENT_WARNINGS.IMAGES_TOO_MANY,
      });
    }
  }

  const nonIgnoredWarnings = filterIgnoredWarningsByType({warnings: imageWarnings, ignoredWarnings});

  return nonIgnoredWarnings;
}

function getActiveTitleWarnings ({
  node, ignoredWarnings, previousWarnings, focusedStepId,
}) {
  const titleWarnings = [];
  const titleText = node.children[0].text;

  if (doesTitleStartWithHowTo(titleText)) {
    titleWarnings.push({
      id: 'title-element-id',
      component: WarningTitleHowTo,
      type: DOCUMENT_WARNINGS.TITLE_HOW_TO,
    });
  }

  if (!isFirstWordVerb(titleText)) {
    titleWarnings.push({
      id: 'title-element-id',
      component: WarningTitleVerb,
      type: DOCUMENT_WARNINGS.TITLE_VERB,
    });
  }

  if (isTitleTooLong(titleText)) {
    titleWarnings.push({
      id: 'title-element-id',
      component: WarningTitleLong,
      type: DOCUMENT_WARNINGS.TITLE_LONG,
    });
  }

  const nonIgnoredWarnings = filterIgnoredWarningsByType({warnings: titleWarnings, ignoredWarnings});

  const newWarningsInTitle = titleWarnings
    .filter((warning) => !previousWarnings?.find((previousWarning) => previousWarning.id === warning.id))
    .filter((warning) => warning.id === focusedStepId);
  const visibleTitleWarnings = nonIgnoredWarnings.filter((warning) => !newWarningsInTitle?.find((newWarning) => newWarning.id === warning.id));

  const firstWarning = visibleTitleWarnings[0];
  return firstWarning ? [firstWarning] : [];
}

function getActiveProcessWarnings ({
  process, ignoredWarnings, previousWarnings, focusedStepId,
}) {
  const processStepTitleWarnings = getProcessStepTitleWarnings(process, ignoredWarnings);
  const processStepContentWarnings = getProcessStepContentWarnings(process, ignoredWarnings);

  const processWarnings = [...processStepTitleWarnings, ...processStepContentWarnings];

  const newWarningsInCurrentStep = processWarnings
    .filter((warning) => !previousWarnings?.find((previousWarning) => previousWarning.id === warning.id))
    .filter((warning) => warning.id === focusedStepId);

  const visibleProcessWarnings = processWarnings
    .filter((warning) => !ignoredWarnings?.find((ignoredWarning) => ignoredWarning.stepId === warning.id && ignoredWarning.type === warning.type))
    .filter((warning) => !newWarningsInCurrentStep?.find((newWarning) => newWarning.id === warning.id));

  return visibleProcessWarnings;
}

function getProcessStepTitleWarnings (process, ignoredWarnings) {
  const processStepTitleWarnings = [];
  process.children[1].children.forEach((processStep) => {
    const titleText = processStep.children[0].children[0].text;
    const ignoredWarningsForProcessStepTitle = ignoredWarnings?.filter((ignoredWarning) => ignoredWarning.stepId === processStep.id);
    const isStepTitleVerbWarningIgnored = ignoredWarningsForProcessStepTitle.find((warning) => (warning.type === DOCUMENT_WARNINGS.STEP_TITLE_VERB));
    if (!isFirstWordVerb(titleText) && !isStepTitleVerbWarningIgnored) {
      processStepTitleWarnings.push({
        id: processStep.id,
        component: WarningStepTitleVerb,
        type: DOCUMENT_WARNINGS.STEP_TITLE_VERB,
      });
      return;
    }
    if (isTitleTooLong(titleText)) {
      processStepTitleWarnings.push({
        id: processStep.id,
        component: WarningStepTitleLong,
        type: DOCUMENT_WARNINGS.STEP_TITLE_LONG,
      });
    }
  });

  return processStepTitleWarnings;
}

function getProcessStepContentWarnings (process) {
  const processStepContentWarnings = [];
  process.children[1].children.forEach((processStep) => {
    const processStepContent = processStep?.children[1]?.children;
    if (processStep?.open) {
      const totalTextLength = getTotalTextLength({content: processStepContent});

      if (totalTextLength > 500) {
        processStepContentWarnings.push({
          id: `step-content--${processStep.id}`,
          component: WarningStepNoHelpBlocks,
          type: DOCUMENT_WARNINGS.STEP_NO_HELP_BLOCKS,
        });
      }
    }
  });

  return processStepContentWarnings;
}

function filterIgnoredWarningsByType ({warnings, ignoredWarnings}) {
  return warnings.filter((warning) => !ignoredWarnings.find((ignoredWarning) => warning.type === ignoredWarning.type));
}

export default CommentsProvider;
