import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'components/Tooltip';
import Paragraph from 'antd/es/typography/Paragraph';
// eslint-disable-next-line no-unused-vars
import tw from 'twin.macro';

Truncate.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  showTooltipOnTruncate: PropTypes.bool,
};

Truncate.defaultProps = {
  showTooltipOnTruncate: true,
};

function Truncate (props) {
  const {
    children,
    showTooltipOnTruncate,
    ...rest
  } = props;

  const [truncated, setTruncated] = useState(false);

  return (
    <Tooltip placement="bottom" title={truncated && showTooltipOnTruncate ? children : undefined}>
      <Paragraph
        {...rest}
        tw="overflow-hidden truncate mb-0"
        ellipsis={{onEllipsis: setTruncated}}
        style={{
          marginBottom: 0,
          color: 'inherit',
          fontSize: 'inherit',
        }}>
        {children}
      </Paragraph>
    </Tooltip>
  );
}

export default Truncate;
