import {useMemo} from 'react';
import useURLSearchParam from 'hooks/useURLSearchParam';
import {STATUS_CODE} from 'am-constants';

function getErrorMessage (errorType) {
  switch (errorType) {
    case 'ssoFailed':
      return 'There was an error authenticating with SSO. Please contact your workspace administrator.';
    case 'sessionExpired':
      return 'Your login session has expired, please try again.';
    default:
      return 'An unknown error has occured.';
  }
}

function useUrlParamError () {
  const urlParamErrorType = useURLSearchParam('error');

  const urlParamError = useMemo(() => {
    if (!urlParamErrorType) {
      return null;
    }

    return {
      type: 'server',
      message: getErrorMessage(urlParamErrorType),
      code: STATUS_CODE.SERVER_ERROR,
    };
  }, [urlParamErrorType]);

  return urlParamError;
}

export default useUrlParamError;
