import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled} from 'twin.macro';
import Button, {ButtonSpacer} from 'components/Button';
import Modal from 'components/Modal';
import P from 'components/P';
import A from 'components/A';
import brokenDog from './broken-dog.png';

BrokenDocumentModal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  errorTitle: PropTypes.string,
  renderError: PropTypes.func,
  closeModal: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

BrokenDocumentModal.defaultProps = {
  errorTitle: undefined,
  renderError: undefined,
};

function BrokenDocumentModal (props) {
  const {
    errorTitle,
    renderError,
    isVisible,
    closeModal,
    onSubmit,
  } = props;

  return (
    <Modal
      visible={isVisible}
      title="Undo your last update"
      onClose={closeModal}
      footer={<>
        <Button type="button" onClick={closeModal}>
          Cancel
        </Button>
        <ButtonSpacer />
        <Button type="button" primary onClick={onSubmit}>
          Undo
        </Button>
      </>}
      errorTitle={errorTitle}
      renderError={renderError}
    >
      <div tw="flex">
        <div tw="flex-1">
          <P>
            We're sorry — the site was unable to process your last update. Our
            engineers have been informed.</P>
          <P>
            As a quick fix, you can undo your last update below. This will
            often fix the issue so you can continue working.
          </P>
          <P bottom>
            For further assistance, please reach out in Slack or email{' '}
            <A href="mailto:support@airmanual.co">support@airmanual.co</A>.
          </P>
        </div>
        <Circle>
          <img src={brokenDog} />
        </Circle>
      </div>
    </Modal>
  );
}

const Circle = styled.div((props) => [
  tw`
    rounded-full
    w-48
    h-48
    overflow-hidden
    float-right
    ml-4
    mt--2
  `,
]);

export default BrokenDocumentModal;
