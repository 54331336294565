import {Transforms} from 'slate';
import makeParagraph from '../factory/makeParagraph';

function insertParagraph (editor, location, {text} = {}) {
  Transforms.insertNodes(
    editor,
    makeParagraph({text}),
    {
      at: location,
    }
  );
  requestAnimationFrame(() => Transforms.select(editor, location));
}

export default insertParagraph;
