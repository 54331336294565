import React, {useContext} from 'react';
import {useDocument} from 'containers/Document/providers/DocumentProvider';
import {usePrintMode} from 'containers/PrintMode/providers/PrintModeProvider';
import {ProcessContext} from '../Process';
import ProcessTitle from './ProcessTitle';

function ProcessTitleContainer (props) {
  const {
    done,
    open,
    setOpen,
  } = useContext(ProcessContext);
  const {mode} = useDocument();
  const printMode = usePrintMode();

  return (
    <ProcessTitle
      done={done}
      mode={mode}
      open={open}
      printMode={!!printMode}
      setOpen={setOpen}
      {...props} />
  );
}

export default ProcessTitleContainer;
