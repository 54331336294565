import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line
import tw, {styled, css} from 'twin.macro';
import P from 'components/P';
import Input from 'components/Input';

AddTitleScreen.propTypes = {
  pageName: PropTypes.string.isRequired,
  setPageName: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  setTitle: PropTypes.func.isRequired,
};

function AddTitleScreen ({
  pageName, setPageName, setTitle, setStep,
}) {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter' && !!pageName) {
        event.preventDefault();
        setTitle(pageName);
        setStep((prev) => prev + 1);
      }
    };
    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [pageName, setStep]);

  const taskChecklistExampleTitles = [
    'send a quote',
    'update our website',
    'backup our servers',
    'prepare for an event',
    'run a discovery call',
    'recruit a new employee',
    'publish a podcast episode',
    'submit year-end accounts',
  ];

  const sample = (arr) => arr[Math.floor(Math.random() * arr.length)];

  const placeholder = sample(taskChecklistExampleTitles);

  return (
    <div tw="flex items-center gap-3 text-xl mb-8">
      <label htmlFor="page-name">
        <P tw="mb-0">How to</P>
      </label>
      <div tw="flex-grow">
        <Input
          id="page-name"
          name="Page name"
          onChange={(e) => setPageName(e.target.value)}
          required
          value={pageName}
          placeholder={placeholder}
          autoComplete="off"
          autoFocus
        />
      </div>
    </div>
  );
}

export default AddTitleScreen;
