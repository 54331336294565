import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import tw, {styled, css} from 'twin.macro';
import {motion} from 'framer-motion';
import {Droppable} from 'react-beautiful-dnd';

ProcessStepList.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  open: PropTypes.bool.isRequired,
  printMode: PropTypes.bool.isRequired,
  processId: PropTypes.string.isRequired,
};

function ProcessStepList (props) {
  const {
    children,
    open,
    printMode,
    processId,
  } = props;

  const variants = {
    open: {
      height: 'auto',
    },
    closed: {
      height: 0,
    },
  };

  // We use a ref to the overflow wrapper so we can easily update it when the
  // animation starts/finishes and without causing a React rerender.
  const overflowWrapper = useRef(null);

  function disableOverflow () {
    overflowWrapper.current.style.overflow = 'hidden';
  }

  function enableOverflow () {
    overflowWrapper.current.style.overflow = 'visible';
  }

  useEffect(() => {
    if (!overflowWrapper.current) {
      return;
    }

    if (open) {
      enableOverflow();
    }
    else {
      disableOverflow();
    }
  }, [overflowWrapper.current]);

  const duration = Math.min(0.3, 0.2 + children.length * 0.02);

  return (
    <OverflowWrapper onClick={(e) => e.stopPropagation()} ref={overflowWrapper}>
      <motion.div
        animate={printMode || open ? 'open' : 'closed'}
        variants={variants}
        initial={false}
        transition={{duration}}
        onAnimationStart={disableOverflow}
        onAnimationComplete={() => open && enableOverflow()}
      >
        <Wrapper open={open}>
          <Droppable droppableId={processId} isDropDisabled={!open}>
            {(provided, snapshot) => (
              <DropContainer
                ref={provided.innerRef}
                isDraggingOver={snapshot.isDraggingOver}>
                {children}
                {provided.placeholder}
              </DropContainer>
            )}
          </Droppable>
        </Wrapper>
      </motion.div>
    </OverflowWrapper>
  );
}

const OverflowWrapper = styled.div((props) => [
  tw`
  `,
]);

const Wrapper = styled.div((props) => [
  tw``,
]);

const DropContainer = styled.div((props) => [
  props.isDraggingOver && tw`
    bg-gray-200
  `,
  props.isDraggingOver && css`
    .reorder-icon {
      opacity: 0
    }
  `,
]);

export default React.memo(ProcessStepList);
