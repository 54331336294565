const {useRef} = require('react');

// With thanks to https://stackoverflow.com/questions/28889826/how-to-set-focus-on-an-input-field-after-rendering
//
// Example usage:
//
// ```
// const FocusDemo = () => {
//   const [inputRef, setInputFocus] = useFocus();
//
//   return <>
//     <button onClick={setInputFocus} >
//             Focus
//     </button>
//     <input ref={inputRef} />
//   </>;
// };
// ```
const useFocus = () => {
  const htmlElRef = useRef(null);

  const setFocus = () => {
    if (htmlElRef.current) {
      htmlElRef.current.focus();
    }
  };

  return [htmlElRef, setFocus];
};

export default useFocus;
