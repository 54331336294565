import React, {
  useCallback,
  useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';

import tw, {styled} from 'twin.macro';
import {
  AiOutlineEdit,
  AiOutlineLoading3Quarters,
} from 'react-icons/ai';
import TextArea from 'components/TextArea';
import Button, {ButtonBar} from 'components/Button';
import debounce from 'utils/debounce';
import FlexSpacer from 'components/FlexSpacer';

SidePanelDescription.propTypes = {
  contentDescription: PropTypes.arrayOf(PropTypes.shape({
    children: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string,
    })),
  })),
  isSaving: PropTypes.bool.isRequired,
  itemId: PropTypes.number.isRequired,
  onUpdateItem: PropTypes.func.isRequired,
};

SidePanelDescription.defaultProps = {
};

function SidePanelDescription (props) {
  const {
    contentDescription,
    isSaving,
    itemId,
    onUpdateItem,
  } = props;

  const [editorOpen, setEditorOpen] = useState();
  const [showSaving, setShowSaving] = useState(isSaving);
  const [showFinishedSaving, setShowFinishedSaving] = useState(isSaving);
  const timeouts = useRef([]);

  useEffect(() => {
    if (isSaving) {
      setShowSaving(true);
      timeouts.current.forEach((timeout) => clearTimeout(timeout));
      timeouts.current = [];
    }

    if (!isSaving) {
      timeouts.current = [
        setTimeout(() => {
          setShowSaving(false);
          setShowFinishedSaving(true);
          timeouts.current = [timeouts.current[1]];
        }, 1000),
        setTimeout(() => {
          setShowFinishedSaving(false);
          timeouts.current = [];
        }, 2000),
      ];
    }

    return () => {
      timeouts.current.forEach((timeout) => clearTimeout(timeout));
    };
  }, [isSaving]);

  useEffect(() => {
    setEditorOpen(false);
    setShowSaving(false);
    setShowFinishedSaving(false);
  }, [itemId]);

  const handleUpdateContentDescription = useCallback(debounce(
    (val) => {
      onUpdateItem({
        // In future we'll be using Slate, so provide in a Slate friendly format
        contentDescription: [{type: 'paragraph', children: [{text: val || ''}]}],
      });
    },
    500
  ), [itemId]);

  const text = contentDescription ? contentDescription[0].children[0].text : '';

  if (editorOpen) {
    return (
      <TextAreaWrapper>
        <TextArea
          placeholder="What's the plan for this page?"
          fullWidth
          autoFocus
          defaultValue={text}
          onChange={(e) => handleUpdateContentDescription(e.target.value)}
        />
        <ButtonBar>
          {showSaving && (
            <Saving>
              <AiOutlineLoading3Quarters tw="animate-spin" />
              Saving
            </Saving>
          )}
          {showFinishedSaving && !showSaving && (
            <Saving>
              Changes saved
            </Saving>
          )}
          <FlexSpacer />
          <Button primary sm onClick={() => setEditorOpen(false)}>Close</Button>
          {/* <Button primary sm onClick={handleSaveChanges}>Save changes</Button> */}
        </ButtonBar>
      </TextAreaWrapper>
    );
  }

  if (!text || text.length === 0) {
    return (
      <Placeholder onClick={() => setEditorOpen(true)}>
        Add a note
        <AiOutlineEdit />
      </Placeholder>
    );
  }

  return (
    <Description className='group' onClick={() => setEditorOpen(true)}>
      {text}
      <EditIcon>
        <AiOutlineEdit />
      </EditIcon>
    </Description>
  );
}

const Description = styled.div((props) => [
  tw`
    mt-7
    p-3.5
    bg-blue-50
    whitespace-pre-wrap
    text-sm
    cursor-pointer
    relative
  `,
]);

const EditIcon = styled.div((props) => [
  tw`
    hidden
    group-hover:block
    text-blue-500
    absolute
    top-0
    right-0
    p-3.5
    bg-blue-50
    text-lg
  `,
]);

const Placeholder = styled.div((props) => [
  tw`
    mt-7
    p-3.5
    text-sm
    bg-blue-50
    text-blue-600
    flex
    items-center
    gap-1.5
    cursor-pointer
  `,
]);

const TextAreaWrapper = styled.div((props) => [
  tw`
    mt-7
    p-3.5
    bg-blue-50
    flex
    flex-col
    gap-2
    text-sm
  `,
]);

const Saving = styled.div((props) => [
  tw`
    text-xs
    text-blue-500
    flex
    items-center
    gap-1.5
  `,
]);

export default SidePanelDescription;
