const makeHeading1 = ({text} = {}) => ({
  type: 'heading1',
  children: [
    {
      text: text || '',
    },
  ],
});

export default makeHeading1;
