import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Confetti from 'components/Confetti';
import {useDocumentForm} from 'containers/DocumentForm/providers/DocumentFormProvider';
import DocumentFormPageActionBar from './DocumentFormPageActionBar';
import DocumentFormPageToolbar from './DocumentFormPageToolbar';

DocumentFormPage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onReactivate: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  printMode: PropTypes.bool.isRequired,
  submittedAt: PropTypes.number,
  submittedByUser: PropTypes.bool.isRequired,
};

function DocumentFormPage (props) {
  const {
    children,
    onReactivate,
    onSubmit,
    printMode,
    submittedAt,
    submittedByUser,
  } = props;

  const {
    hasUpdateErrors,
    submitError,
    submitting,
    submitted,
  } = useDocumentForm();

  const [moreConfetti, setMoreConfetti] = useState(false);

  const showConfetti = !!submittedAt && submittedByUser;
  const showToolbar = !printMode;
  const showActionbar = !printMode;

  return <>
    {showConfetti && <Confetti recycle={moreConfetti} />}
    {showToolbar && <DocumentFormPageToolbar
      hasUpdateErrors={hasUpdateErrors}
      submitError={submitError}
      submitted={submitted}
    />}
    {children}
    {showActionbar && (
      <DocumentFormPageActionBar
        displayingMoreConfetti={moreConfetti}
        onMoreConfetti={() => setMoreConfetti(true)}
        onNoMoreConfetti={() => setMoreConfetti(false)}
        onReactivate={onReactivate}
        onSubmit={onSubmit}
        submittedAt={submittedAt}
        submittedByUser={submittedByUser}
        submitting={submitting}
      />
    )}
  </>;
}

export default DocumentFormPage;
