import PropTypes from 'prop-types';
import {useQuery} from '@tanstack/react-query';
import {gql} from 'graphql-request';
import graphQLClient from 'utils/graphQLClient';

const query = gql`
  query GetReccomendedTemplatesForTitle($title: String!, $itemId: String!) {
    recommendedTemplates(title: $title, itemId: $itemId) {
      name
      relevance
      itemRef
      itemKey
    }
  }
`;

useRecommendedTemplatesQuery.propTypes = {
  title: PropTypes.string,
};

function useRecommendedTemplatesQuery (title, itemId) {
  const queryKey = ['recommendedTemplates', title, itemId];

  const queryFn = async () => {
    const variables = {
      title,
      itemId,
    };

    const data = await graphQLClient.request(query, variables);
    return data?.recommendedTemplates;
  };

  const options = {enabled: false, cacheTime: 0};

  return useQuery({queryKey, queryFn, ...options});
}

export default useRecommendedTemplatesQuery;
