import React from 'react';
import Modal from 'components/Modal';
import Button, {ButtonSpacer} from 'components/Button';
import PropTypes from 'prop-types';

ResponseDeleterModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isError: PropTypes.bool,
  isVisible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

function ResponseDeleterModal (props) {
  const {
    closeModal,
    isError,
    isLoading,
    isVisible,
    onSubmit,
  } = props;

  return (
    <Modal
      visible={isVisible}
      title="Delete"
      onClose={closeModal}
      footer={<>
        <Button type="button" link onClick={closeModal}>
          Cancel
        </Button>
        <ButtonSpacer />
        <Button
          disabled={isLoading}
          type="button"
          primary
          onClick={onSubmit}
          data-test-id="modal_delete-button">
          Delete
        </Button>
      </>}
    >
      {!isError && 'Are you sure you want to delete this submission?'}
      {isError && 'An error has occured. Please check your connection and try again.'}
    </Modal>
  );
}

export default ResponseDeleterModal;
