import PropTypes from 'prop-types';
import {useQuery} from '@tanstack/react-query';
import {gql} from 'graphql-request';
import graphQLClient from 'utils/graphQLClient';

const disabledDocumentWarningsQuery = gql`
  query GetDisabledDocumentWarnings($ref: String!, $key: String!) {
    item(ref: $ref, key: $key) {
      document {
        disabledWarnings {
          type
          stepId
        }
      }
    }
  }
`;

useDisabledDocumentWarningsQuery.propTypes = {
  ref: PropTypes.string,
  key: PropTypes.string,
};

function useDisabledDocumentWarningsQuery ({ref, key}) {
  const cacheKey = ['documentWarnings', {ref, key}];

  const variables = {
    ref,
    key,
  };

  const queryFn = async () => {
    const queryResult = await graphQLClient.request(disabledDocumentWarningsQuery, variables);
    return queryResult?.item?.document?.disabledWarnings || [];
  };

  const options = {};

  return useQuery(
    cacheKey,
    queryFn,
    options
  );
}

export default useDisabledDocumentWarningsQuery;
