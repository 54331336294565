import {LexoRank} from 'lexorank';

function getNewRank ({previousRank, nextRank}) {
  if (previousRank && nextRank) {
    return LexoRank.parse(previousRank).between(LexoRank.parse(nextRank)).toString();
  }

  if (previousRank) {
    return LexoRank.parse(previousRank).genNext().toString();
  }

  if (nextRank) {
    return LexoRank.parse(nextRank).genPrev().toString();
  }

  return LexoRank.middle().toString();
}

export default getNewRank;
