import React from 'react';
import PropTypes from 'prop-types';
import Pill from 'components/Pill';

TaskInProgressCellContent.propTypes = {
  numActiveResponses: PropTypes.number.isRequired,
};

TaskInProgressCellContent.defaultProps = {
};

function TaskInProgressCellContent (props) {
  const {
    numActiveResponses,
  } = props;

  if (numActiveResponses === 0) {
    return null;
  }

  return (
    <Pill>
      {numActiveResponses} in progress
    </Pill>
  );
}

export default TaskInProgressCellContent;
