import {Path, Editor, Transforms} from 'slate';
import {SELECTABLE_ELEMENT_TYPES} from '../constants';

// Handle the user starting the select from outside of a selectable element and
// then dragging it over one or more selectable elements. Note that this won't
// always be at the top level of the document — this could be dragging a
// selection inside a process step that contains help blocks (!)
function onSelectFromOutsideSelectableElement (editor, selectionMeta, {selectElementsInRange}) {
  const {
    isAnchorBeforeFocus,
    selectionEnd,
    selectionEndElementPath,
    selectionStart,
    selectionStartElementPath,
  } = selectionMeta;

  const commonParentPath = Path.common(selectionStartElementPath, selectionEndElementPath);
  const [commonParent] = Editor.node(editor, commonParentPath);
  const startIndex = selectionStartElementPath[commonParentPath.length];
  const endIndex = selectionEndElementPath[commonParentPath.length];
  const elements = commonParent.children.slice(
    startIndex,
    endIndex + 1
  );

  const selectableElementIds = elements
    .filter((element) => SELECTABLE_ELEMENT_TYPES.includes(element.type))
    .map((element) => element.id);

  if (selectableElementIds.length === 0) {
    return false;
  }

  selectElementsInRange(selectableElementIds);

  // Set the editor selection to enclose all selectable elements. This leads
  // to the user experiencing expected behavior when they then type, delete,
  // cut or copy with the range selected..
  const start = SELECTABLE_ELEMENT_TYPES.includes(elements[0].type)
    ? Editor.start(editor, [...commonParentPath, startIndex]) : selectionStart;
  const end = SELECTABLE_ELEMENT_TYPES.includes(elements.slice(-1)[0].type)
    ? Editor.end(editor, [...commonParentPath, endIndex])
    : selectionEnd;

  Transforms.setSelection(editor, {
    anchor: isAnchorBeforeFocus ? start : end,
    focus: isAnchorBeforeFocus ? end : start,
  });

  return true;
}

export default onSelectFromOutsideSelectableElement;
