import React from 'react';
import PropTypes from 'prop-types';
import GridCellPopover from 'components/DataGrid/GridCellPopover';
import ContentReviewerCellPopover from './ContentReviewerCellPopover';
import SavingCellContent from './SavingCellContent';
import ContentReviewerCellContent from './ContentReviewerCellContent';

ContentReviewerCell.propTypes = {
  itemId: PropTypes.number,
  itemTypeId: PropTypes.number.isRequired,
  reviewers: PropTypes.arrayOf(PropTypes.shape({})),
  onUpdateItem: PropTypes.func.isRequired,
  saving: PropTypes.bool,
};

ContentReviewerCell.defaultProps = {
  saving: false,
};

function ContentReviewerCell (props) {
  const {
    itemId,
    itemTypeId,
    reviewers,
    onUpdateItem,
    saving,
  } = props;

  const popover = (
    <ContentReviewerCellPopover
      reviewers={reviewers}
      onUpdateItem={onUpdateItem} />
  );

  return (
    <GridCellPopover content={popover}>
      <SavingCellContent saving={saving}>
        <ContentReviewerCellContent
          itemId={itemId}
          itemTypeId={itemTypeId}
          reviewers={reviewers}
        />
      </SavingCellContent>
    </GridCellPopover>
  );
}

export default ContentReviewerCell;
