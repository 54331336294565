import {Editor, Transforms} from 'slate';

function onArrowLeftAfterCollapsedContent (editor, event, selectionMeta) {
  const {
    isSelectionAtStartOfElement,
    selectionStart,
  } = selectionMeta;

  if (
    event.key !== 'ArrowLeft' ||
    !selectionStart ||
    !isSelectionAtStartOfElement
  ) {
    return false;
  }

  const previousPoint = Editor.before(editor, selectionStart);
  if (!previousPoint) {
    return false;
  }

  const collapsedElements = Editor.nodes(editor, {
    at: previousPoint.path,
    match: (n) => [
      'process',
      'process-step',
      'help-block',
    ].includes(n.type) && !n.open,
  });

  const [highestCollapsedElement, highestCollapsedElementPath] = collapsedElements.next().value || [undefined, undefined];
  if (!highestCollapsedElement) {
    return false;
  }

  event.preventDefault();

  const endOfHighestCollapsedElementTitle = Editor.end(editor, [...highestCollapsedElementPath, 0]);

  requestAnimationFrame(() =>
    Transforms.select(
      editor,
      endOfHighestCollapsedElementTitle
    ));

  return true;
}

export default onArrowLeftAfterCollapsedContent;
