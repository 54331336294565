import React from 'react';
import PropTypes from 'prop-types';

AirManualSquareLogo.propTypes = {
  width: PropTypes.number,
};

AirManualSquareLogo.defaultProps = {
  width: 80,
};

function AirManualSquareLogo (props) {
  const {width, ...rest} = props;

  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1322 1320" width={width} {...rest}>
      <defs><style>{'.cls-1{fill:hsl(205, 65%, 55%);}'}</style></defs>
      <path className="cls-1" d="M661,295.31C642.24,307.84,572.34,376,429.89,738.43,399.81,815,371.91,891,348.33,957.48H973.67C950.1,891,922.19,815,892.11,738.43,749.66,376,679.76,307.84,661,295.31Zm156.12,600-6.5-12.22c-23.9-44.89-49.93-84.68-65.87-104.41C733.93,791,719,811.16,703.18,835c-10.4,15.63-20.58,31.92-30.25,48.39L661,903.71l-11.93-20.34C639.45,867,629.27,850.71,618.82,835c-15.83-23.83-30.75-44-41.57-56.32-15.94,19.72-42,59.51-65.86,104.41l-6.5,12.22L435,858.11l6.5-12.22C524.18,690.4,559.58,690.4,576.59,690.4c17.54,0,41.25,8.27,84.41,66.18,43.16-57.91,66.86-66.18,84.41-66.18,17,0,52.4,0,135.12,155.49l6.5,12.22Z"/><path className="cls-1" d="M1189.07,16.38H132.93A115.44,115.44,0,0,0,17.38,131.71V1188.07a115.55,115.55,0,0,0,115.55,115.55H1189.07a115.66,115.66,0,0,0,115.55-115.55V131.93A115.55,115.55,0,0,0,1189.07,16.38Zm-39.46,1121H1035.19c-5.71-17.38-13.53-40.91-23.12-69.2H309.93c-9.59,28.29-17.4,51.82-23.11,69.2H172.39l-1.8-.57c.74-2.31,74.42-233.42,165.22-461.52,172-432,257.87-492.4,325-492.68h.47c67.09.28,153,60.69,325,492.68,90.8,228.1,164.48,459.21,165.22,461.52Z"/>
    </svg>
  );
}

export default AirManualSquareLogo;
