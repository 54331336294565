import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import PrintModeProvider from './providers/PrintModeProvider';
import PrintHeader from './components/PrintHeader';

PrintMode.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

function PrintMode (props) {
  const {children} = props;

  // setTimeout lets any re-renders happen before printing
  useEffect(() => setTimeout(window.print, 1000), []);

  return (
    <PrintModeProvider>
      <PrintHeader>The print menu should open automatically</PrintHeader>
      {children}
    </PrintModeProvider>
  );
}

export default PrintMode;
