import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';
import {ITEM_TYPE} from 'am-constants';
import {
  AiFillFolder,
} from 'react-icons/ai';
import {VscCircleFilled} from 'react-icons/vsc';
import useMoveItemMutation from './hooks/useMoveItemMutation';
import ItemMoverModal from './components/ItemMoverModal';
import useFolderDetailsForMovingQuery from './hooks/useFolderDetailsForMovingQuery';
const {
  getTreesFromArray,
  filterTrees,
} = require('am-utils');

ItemMoverContainer.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  setIsVisible: PropTypes.func.isRequired,
  itemName: PropTypes.string.isRequired,
  itemRef: PropTypes.string,
  itemKey: PropTypes.string,
  itemId: PropTypes.number,
};

function ItemMoverContainer (props) {
  const {
    isVisible,
    setIsVisible,
    itemName,
    itemRef,
    itemKey,
    itemId,
  } = props;

  const {urlName: workspaceUrlName, featureFlags} = useWorkspace();
  const newDashboardEnabled = featureFlags['2022_09_dashboard'];

  const closeModal = () => {
    setIsVisible(false);
    setDestinationFolderId(undefined);
  };

  const [destinationFolderId, setDestinationFolderId] = useState();

  const [onMoveItem] = useMoveItemMutation({
    parentId: destinationFolderId,
    itemRef,
    itemKey,
  });

  const onChange = (id) => {
    setDestinationFolderId(id);
  };

  const onSubmit = () => {
    onMoveItem();
    setIsVisible(false);
    setDestinationFolderId(undefined);
  };

  const {data: folderDetailsForMoving, error, fetchStatus} = useFolderDetailsForMovingQuery({
    workspaceUrlName,
  });

  const getIsNotItemBeingMoved = (item) => item.id !== itemId;
  const folderTree =
    folderDetailsForMoving && folderDetailsForMoving.length
      ? prepareFolderStructure(
        folderDetailsForMoving,
        getIsNotItemBeingMoved,
        newDashboardEnabled
      )
      : [];

  return (
    <ItemMoverModal
      itemName={itemName}
      isVisible={isVisible}
      closeModal={closeModal}
      folderTree={folderTree}
      // We refetch on refocusing the window, which wipes the error state. This
      // fetchStatus === 'fetching' statement prevents the UI from re-rendering
      // an empty modal while this refetch is happening. This could be also fixed
      // by adding a loading spinner on re-fetch, but this would add visual noise
      // and is not consistent behavior across our other modals.
      error={!!error || fetchStatus === 'fetching'}
      onChange={onChange}
      destinationFolderId={destinationFolderId}
      onSubmit={onSubmit}
    />
  );
}

function prepareFolderStructure (
  folderStructure,
  getIsNotItemBeingMovedFn,
  newDashboardEnabled
) {
  const items = [...folderStructure]
    .sort((a, b) => {
      if (!newDashboardEnabled) {
        return a.name.localeCompare(b.name);
      }

      return a.rank.localeCompare(b.rank);
    })
    .map((item) => ({
      ...item,
      disabled: !item.canMoveHere,
      icon:
        item.itemTypeId === ITEM_TYPE.SECTION ? VscCircleFilled : AiFillFolder,
    }));

  const trees = getTreesFromArray(items);
  return filterTrees(trees, getIsNotItemBeingMovedFn);
}

export default ItemMoverContainer;
