import React, {useState} from 'react';
import PropTypes from 'prop-types';

const ItemContext = React.createContext();

ItemProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

function ItemProvider (props) {
  const {children} = props;

  const [item, setItem] = useState();

  const context = {
    item,
    setItem,
  };

  return <>
    <ItemContext.Provider value={context}>
      {children}
    </ItemContext.Provider>
  </>;
}

export function useItem () {
  const context = React.useContext(ItemContext);

  if (context === undefined) {
    throw new Error('useItem must be used within a ItemProvider');
  }

  return context;
}

export default ItemProvider;

// import React from 'react';
// import PropTypes from 'prop-types';
// import {ITEM_TYPE} from 'am-constants';

// const ItemContext = React.createContext();

// ItemProvider.propTypes = {
//   children: PropTypes.oneOfType([
//     PropTypes.arrayOf(PropTypes.node),
//     PropTypes.node,
//   ]).isRequired,
//   item: PropTypes.shape({
//     breadcrumbs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
//     loggedInUserRoleIds: PropTypes.array,
//     itemTypeId: PropTypes.oneOf([
//       ITEM_TYPE.CHECKLIST,
//       ITEM_TYPE.FOLDER,
//       ITEM_TYPE.DOCUMENT,
//       ITEM_TYPE.FORM,
//       ITEM_TYPE.WORKSPACE,
//     ]),
//     key: PropTypes.string.isRequired,
//     name: PropTypes.string,
//     public: PropTypes.bool.isRequired,
//     hasPublicAncestor: PropTypes.bool.isRequired,
//     publishedAt: PropTypes.string,
//     ref: PropTypes.string.isRequired,
//     urlName: PropTypes.string.isRequired,
//   }),
// };

// function ItemProvider (props) {
//   const {children, item} = props;

//   // This is to allow us to pass a null item to the team member viewer page
//   // TODO: Remove when team member page no longer requires an item provider
//   if (item === null) {
//     return <>
//       <ItemContext.Provider value={item}>
//         {children}
//       </ItemContext.Provider>
//     </>;
//   }

//   const {
//     breadcrumbs,
//     loggedInUserRoleIds,
//     itemTypeId,
//     key,
//     name,
//     ref,
//     publishedAt,
//     public: isPublic,
//     hasPublicAncestor,
//     urlName,
//   } = item;

//   const context = {
//     breadcrumbs,
//     name,
//     loggedInUserRoleIds,
//     itemTypeId,
//     key,
//     ref,
//     public: isPublic,
//     hasPublicAncestor,
//     publishedAt,
//     urlName,
//   };

//   return <>
//     <ItemContext.Provider value={context}>
//       {children}
//     </ItemContext.Provider>
//   </>;
// }

// export function useItem () {
//   const context = React.useContext(ItemContext);

//   if (context === undefined) {
//     throw new Error('useItem must be used within a ItemProvider');
//   }

//   return context;
// }

// export default ItemProvider;
