function submissionStateReducer (state, action) {
  const {
    id, error, type, value,
  } = action;

  switch (type) {
    case 'start update':
      return {
        ...state,
        [id]: {
          ...state[id],
          value,
          updating: true,
        },
      };
    case 'finish update':
      return {
        ...state,
        [id]: {
          ...state[id],
          updating: false,
          error: undefined,
        },
      };
    case 'fail update':
      // Note: updating will still be true as we expect the code to retry.
      return {
        ...state,
        [id]: {
          ...state[id],
          error,
        },
      };
    default:
      throw new Error();
  }
}

export default submissionStateReducer;
