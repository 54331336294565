import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled} from 'twin.macro';
import InlineTooltip from 'components/InlineTooltip';
import {WORKSPACE_ROLE_NAMES} from 'am-constants';
import Button from 'components/Button';

TeamMember.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  roles: PropTypes.arrayOf(PropTypes.number),
  hasSignedUp: PropTypes.bool.isRequired,
  setEditModalRole: PropTypes.func.isRequired,
  setEditModalEmail: PropTypes.func.isRequired,
  openEditModal: PropTypes.func.isRequired,

};

function TeamMember (props) {
  const {
    name, email, roles, hasSignedUp, setEditModalRole, setEditModalEmail, openEditModal,
  } = props;

  // currently a user only ever has one role
  const role = roles[0];

  return (
    <Container>
      <NameAndEmail>
        <Name>{name}</Name>
        <Email>
          {email}
          {!hasSignedUp && <>
            {' '}•{' '}
            <InlineTooltip title="This user has been invited to join the workspace but has not yet signed up." placement="bottom">Invited</InlineTooltip>
          </>}
        </Email>
      </NameAndEmail>
      <Role>{WORKSPACE_ROLE_NAMES[role]}</Role>
      <Button
        data-test-id={`${name}-edit-button`}
        type='button'
        link
        lg
        onClick={() => {
          setEditModalRole(role);
          setEditModalEmail(email);
          openEditModal();
        }}
      >Edit</Button>
    </Container>
  );
}
const Container = styled.div.attrs({
  'data-test-id': 'team-member-container',
})((props) => [
  tw`
    flex
    bg-gray-50
    py-4
    px-6
    mb-2
    items-center
  `,
]);

const NameAndEmail = styled.div((props) => [
  tw`
    flex-1
    mr-4
  `,
]);

const Name = styled.div((props) => [
  tw`
  `,
]);

const Email = styled.div((props) => [
  tw`
    text-xs
    text-gray-500
  `,
]);

const Role = styled.div((props) => [
  tw`
    w-32
  `,
]);

export default TeamMember;
