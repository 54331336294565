import {ITEM_TYPE} from 'am-constants';
import getNewRank from './getNewRank';

function getNewLocationForNonSection ({
  draggedItem,
  dropIndex,
  parentItemId,
  preparedItems,
}) {
  const {previousItem, nextItem} = getPreviousAndNextItems({preparedItems, dropIndex});

  const newParentId = getNewParentId({
    previousItem,
    parentItemId,
    nextItem,
  });

  const {previousRank, nextRank} = previousItem?.adder
    ? getPreviousAndNextRanksAfterAdder({
      nextItem,
      newParentId,
      preparedItems,
      previousItem,
    })
    : getPreviousAndNextRanks({
      newParentId,
      previousItem,
      nextItem,
    });
  const newRank = getNewRank({
    previousRank,
    nextRank,
  });

  // console.log('Moving non-section', {
  //   newParentId,
  //   previousRank,
  //   newRank,
  //   nextRank,
  // });

  return {
    item: draggedItem,
    newParentId,
    newRank,
  };
}

function getPreviousAndNextItems ({preparedItems, dropIndex}) {
  const previousItem = dropIndex > 0 ? preparedItems[dropIndex - 1] : undefined;
  const nextItem = dropIndex < preparedItems.length ? preparedItems[dropIndex] : undefined;

  return {
    previousItem,
    nextItem,
  };
}

function getNewParentId ({
  previousItem,
  parentItemId,
  nextItem,
}) {
  if (!previousItem) {
    return parentItemId;
  }

  if (previousItem?.adder) {
    return nextItem.parentId || parentItemId;
  }

  if (previousItem?.itemTypeId === ITEM_TYPE.SECTION) {
    return previousItem.id;
  }

  if (previousItem?.itemTypeId === ITEM_TYPE.FOLDER && previousItem.expanded) {
    return previousItem.id;
  }

  return previousItem.parentId;
}

function getPreviousAndNextRanks ({newParentId, previousItem, nextItem}) {
  const previousRank = previousItem?.parentId === newParentId ? previousItem.rank : undefined;
  const nextRank = nextItem?.parentId === newParentId ? nextItem.rank : undefined;

  return {
    previousRank,
    nextRank,
  };
}

function getPreviousAndNextRanksAfterAdder ({
  newParentId, previousItem, nextItem, preparedItems,
}) {
  const adderFolder = preparedItems.find((i) => i.id === previousItem.folderId);
  const previousRank = adderFolder?.rank;
  const nextRank = nextItem?.parentId === newParentId && !nextItem?.adder
    ? nextItem.rank
    : undefined;

  return {
    previousRank,
    nextRank,
  };
}

export default getNewLocationForNonSection;
