import {gql} from '@apollo/client';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import graphQLClient from 'utils/graphQLClient';

const query = gql`
  mutation documentDisableWarning($itemRef: String!, $itemKey: String!, $stepId: String, $warningType: DocumentWarning!) {
    documentDisableWarning(itemRef: $itemRef, itemKey: $itemKey, stepId: $stepId, warningType: $warningType){
      stepId
      type
    }
  }
`;

function useDisableDocumentWarningMutation () {
  const queryClient = useQueryClient();

  const mutationFn = async (vars) => {
    const variables = {
      itemRef: vars.itemRef,
      itemKey: vars.itemKey,
      stepId: vars.stepId,
      warningType: vars.warningType,
    };

    await graphQLClient.request(query, variables);
  };

  const options = {
    onSuccess: (_, {itemRef, itemKey}) => {
      queryClient.invalidateQueries([
        'documentWarnings', {ref: itemRef, key: itemKey},
      ]);
    },
  };

  return useMutation(mutationFn, options);
}

export default useDisableDocumentWarningMutation;
