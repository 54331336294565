import {Editor, Transforms} from 'slate';
import getElementById from '../utils/getElementById';

function onKeyDownWithSelectedElements (editor, event, selectionMeta, {selectedElements, selectElements}) {
  if (selectedElements.ids.length === 0 || selectedElements.inRange) {
    return false;
  }

  event.preventDefault();

  selectElements([]);

  // eslint-disable-next-line no-unused-vars
  const [firstSelectedElement, firstSelectedElementPath] = getElementById(editor, selectedElements.ids[0]);
  const selectableElementTitleEdges = Editor.edges(editor, [...firstSelectedElementPath, 0]);
  Transforms.setSelection(editor, {
    anchor: selectableElementTitleEdges[1],
    focus: selectableElementTitleEdges[1],
  });

  return true;
}

export default onKeyDownWithSelectedElements;
