import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled} from 'twin.macro';
import Button, {ButtonBar} from 'components/Button';
import {CONTENT_PRIORITY} from 'am-constants';
import {usePopover} from 'components/Popover/providers/PopoverProvider';

ContentPriorityCellPopover.propTypes = {
  contentPriority: PropTypes.string,
  onUpdateItem: PropTypes.func.isRequired,
};

ContentPriorityCellPopover.defaultProps = {
};

function ContentPriorityCellPopover (props) {
  const {
    contentPriority,
    onUpdateItem,
  } = props;

  const {setPopoverOpen} = usePopover();

  const handleClick = (value) => {
    setPopoverOpen(false);

    // Short timeout avoids the 'Remove priority' button flicking up before the
    // popover has closed.
    setTimeout(() => {
      onUpdateItem({
        contentPriority: value,
      });
    }, 100);
  };

  return (
    <Wrapper>
      <ButtonBar vertical>
        <Button sm fullwidth onClick={() => handleClick(CONTENT_PRIORITY.LOW)}>Low priority</Button>
        <Button sm fullwidth onClick={() => handleClick(CONTENT_PRIORITY.MEDIUM)}>Medium priority</Button>
        <Button sm fullwidth onClick={() => handleClick(CONTENT_PRIORITY.HIGH)}>High priority</Button>
        <Button sm fullwidth onClick={() => handleClick(CONTENT_PRIORITY.VERY_HIGH)}>Very high priority</Button>
      </ButtonBar>
      {contentPriority && (
        <Button link fullwidth tw="text-xs! mt-3.5" onClick={() => handleClick(null)}>Remove priority</Button>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div((props) => [
  tw`
    w-40
  `,
]);

// const FooterLink = styled.div((props) => [
//   tw`
//     text-center
//     m--4
//     mt-4
//     p-4
//     pt-4
//     bg-gray-50
//   `,
// ]);

export default ContentPriorityCellPopover;
