import {Editor, Transforms, Node} from 'slate';
import {CONTENT_ELEMENT_TYPES} from '../constants';

function makeSureOrderedListsAreOrdered (editor, [entryNode, entryNodePath]) {
  if (!editor.selection) {
    return false;
  }

  const shouldNormalizeTopLevelContent = entryNodePath.length === 0 && (
    editor.selection.anchor.path.length === 0 ||
    Node.get(editor, editor.selection.anchor.path.slice(0, 1)).type !== 'process'
  );

  const shouldNormalizeProcessStepContent = CONTENT_ELEMENT_TYPES.includes(entryNode.type);

  if (
    !shouldNormalizeTopLevelContent &&
    !shouldNormalizeProcessStepContent
  ) {
    return false;
  }

  return normalizeOrderedListItems(editor, [entryNode, entryNodePath]);
}

function normalizeOrderedListItems (editor, [entryNode, entryNodePath]) {
  let hasMadeUpdates = false;

  // All adjacent orderedlistitems should have sequential numbers
  // TODO: Review performance on slow machines
  let prevNode = {};

  // We allow users to tab-indent up to 8 times. As the content gets more
  // indented, we keep a record of the previous node at each of the higher
  // levels of indentation. This allows us to continue an ordered list after
  // (e.g.) a bulleted list within one of the ordered list points.
  const prevNodeAtIndentMap = {
    0: {},
    1: {},
    2: {},
    3: {},
    4: {},
    5: {},
    6: {},
    7: {},
    8: {},
  };

  Editor.withoutNormalizing(editor, () => {
    for (let i = 0; i < entryNode.children.length; i++) {
      const node = entryNode.children[i];
      const nodeIndent = node.indent || 0;
      const prevNodeIndent = prevNode.indent || 0;
      const prevNodeAtIndent = prevNodeAtIndentMap[nodeIndent];

      const oldNumber = node.number;
      let newNumber = node.number;

      // Commented out to stop the behavior where all lists must start at 1.
      // This lets users paste in an (unindented) screenshot and resume their
      // numbered list after the screenshot.
      // if (
      //   node.type === 'ordered-list-item' &&
      //   prevNodeAtIndent.type !== 'ordered-list-item'
      // ) {
      //   newNumber = 1;
      // }
      if (
        node.type === 'ordered-list-item' &&
        prevNodeIndent < nodeIndent &&
        nodeIndent > 0
      ) {
        newNumber = 1;
      }
      else if (
        node.type === 'ordered-list-item' &&
        prevNodeIndent > nodeIndent &&
        nodeIndent > 0
      ) {
        newNumber = prevNodeAtIndent.number + 1;
      }
      else if (
        node.type === 'ordered-list-item' &&
        prevNodeAtIndent.type === 'ordered-list-item'
      ) {
        newNumber = prevNodeAtIndent.number + 1;
      }

      if (newNumber !== oldNumber) {
        Transforms.setNodes(editor, {
          number: newNumber,
        }, {
          at: [...entryNodePath, i],
        });

        hasMadeUpdates = true;
      }

      if (nodeIndent < prevNodeIndent) {
        prevNodeAtIndentMap[prevNodeIndent] = {};
      }

      prevNode = node;
      prevNodeAtIndentMap[nodeIndent] = node;
    }
  });
  return hasMadeUpdates;
}

export default makeSureOrderedListsAreOrdered;
