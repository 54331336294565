import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {
  DAYS_OF_WEEK,
  FREQUENCY,
  INSTANCE_OF_DAY_IN_MONTH,
} from 'am-constants';
import GridCellPopover from 'components/DataGrid/GridCellPopover';
import NextDueCellContent from './NextDueCellContent';
import NextDueCellPopover from './NextDueCellPopover';
import SavingCellContent from './SavingCellContent';

NextDueCell.propTypes = {
  itemId: PropTypes.number,
  itemTypeId: PropTypes.number.isRequired,
  contentActionNeededId: PropTypes.number,
  contentPriority: PropTypes.string,
  nextDueAt: PropTypes.string,
  nextDueInterval: PropTypes.number,
  nextDueFrequency: PropTypes.oneOf([
    FREQUENCY.DAILY,
    FREQUENCY.WEEKLY,
    FREQUENCY.MONTHLY,
    FREQUENCY.YEARLY,
  ]),
  nextDueDaysOfWeek: PropTypes.arrayOf(PropTypes.oneOf([
    DAYS_OF_WEEK.MON,
    DAYS_OF_WEEK.TUE,
    DAYS_OF_WEEK.WED,
    DAYS_OF_WEEK.THU,
    DAYS_OF_WEEK.FRI,
    DAYS_OF_WEEK.SAT,
    DAYS_OF_WEEK.SUN,
  ])),
  nextDueInstanceOfDayInMonth: PropTypes.oneOf([
    INSTANCE_OF_DAY_IN_MONTH.DAY_NUMBER,
    INSTANCE_OF_DAY_IN_MONTH.FIRST,
    INSTANCE_OF_DAY_IN_MONTH.SECOND,
    INSTANCE_OF_DAY_IN_MONTH.THIRD,
    INSTANCE_OF_DAY_IN_MONTH.FOURTH,
    INSTANCE_OF_DAY_IN_MONTH.LAST,
  ]),
  saving: PropTypes.bool,
  onUpdateItem: PropTypes.func.isRequired,
};

NextDueCell.defaultProps = {
  saving: false,
};

function NextDueCell (props) {
  const {
    itemId,
    itemTypeId,
    nextDueAt,
    nextDueInterval,
    nextDueFrequency,
    nextDueDaysOfWeek,
    nextDueInstanceOfDayInMonth,
    saving,
    onUpdateItem,
  } = props;

  const popover = useMemo(() => <NextDueCellPopover
    nextDueAt={nextDueAt}
    nextDueInterval={nextDueInterval}
    nextDueFrequency={nextDueFrequency}
    nextDueDaysOfWeek={nextDueDaysOfWeek}
    nextDueInstanceOfDayInMonth={nextDueInstanceOfDayInMonth}
    onUpdateItem={onUpdateItem}
  />, [
    nextDueAt,
    nextDueInterval,
    nextDueFrequency,
    nextDueDaysOfWeek,
    nextDueInstanceOfDayInMonth,
    onUpdateItem,
  ]);

  return (
    <GridCellPopover content={popover}>
      <SavingCellContent saving={saving}>
        <NextDueCellContent
          itemId={itemId}
          itemTypeId={itemTypeId}
          nextDueAt={nextDueAt}
        />
      </SavingCellContent>
    </GridCellPopover>
  );
}

export default React.memo(NextDueCell);
