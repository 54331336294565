import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';

ProcessStepDoneButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  primary: PropTypes.bool,
  setDone: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
};

function ProcessStepDoneButton (props) {
  const {
    children,
    primary,
    setDone,
    setOpen,
  } = props;

  const markAsCompleted = () => {
    setOpen(false);
    setDone(true);
  };

  const buttonProps = {};
  if (primary) {
    buttonProps.primary = true;
  }

  return <Button onClick={markAsCompleted} {...buttonProps}>
    {children}
  </Button>;
}

export default ProcessStepDoneButton;
