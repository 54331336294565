import React from 'react';
import P from 'components/P';
import UL from 'components/UL';
import LI from 'components/LI';
import A from 'components/A';

function SlackActionContent () {
  return (
    <>
      <P>There are 2 ways to send a message to Slack:</P>
      <UL>
        <LI>
          <A href="https://airmanual.link/help/send-email-to-slack" target="_blank">
              Send an email to a Slack channel
          </A>{' '}
              (easiest)
        </LI>
        <LI>
          <A href="https://airmanual.link/help/send-customized-message-to-slack" target="_blank">
              Send a customized message to Slack via Zapier
          </A>
        </LI>
      </UL>
      <A href="https://www.airmanual.co/support" target="_blank">
          Contact support
      </A>{' '}
          if you need any help.
    </>
  );
}

export default SlackActionContent;
