import React from 'react';
import {
  Redirect, Router, useLocation,
} from '@reach/router';
import BillingPage from 'containers/BillingPage';
import DocumentForm from 'containers/DocumentForm';
import TeamMemberViewer from 'containers/TeamMemberViewer';
import PropTypes from 'prop-types';
import FolderApp from 'containers/FolderApp';
import ErrorPage404 from 'components/ErrorPage404';
import DocumentCreator from 'containers/DocumentCreator';
import ResponseCreator from 'containers/ResponseCreator';
import {useUser} from 'containers/UserApp/providers/UserProvider';
import PageApp from '../../PageApp';

WorkspaceRouter.propTypes = {
  firstAccessibleFolder: PropTypes.object,
  hasAccessToWorkspaceItem: PropTypes.bool.isRequired,
  workspaceUrlName: PropTypes.string,
};

function WorkspaceRouter (props) {
  const {
    firstAccessibleFolder,
    hasAccessToWorkspaceItem,
    workspaceUrlName,
  } = props;
  const {user} = useUser();

  const location = useLocation();
  const isPageApp = getIsPageApp(location);

  const workspaceUrlNames = user ? user.workspaces.map((workspace) => workspace.urlName) : [];
  const hasCurrentWorkspaceAccess = workspaceUrlNames.includes(workspaceUrlName) || (user && user.isSiteAdmin);

  const redirectUrlIfNoAccessToWorkspaceItem = `/${workspaceUrlName}/folders/${firstAccessibleFolder?.urlName}`;

  return (
    // primary={false} disables some unwanted scrolling when navigating between
    // pages. https://github.com/reach/router/issues/242
    <Router primary={false}>
      <TeamMemberViewer path="/team"/>
      <BillingPage path="/billing"/>

      {/* Team members with access to the workspace item should see the
      first folder they have access to. Non team members should however be
      redirected to the login page.

      This prevents a user from visiting random workspaces and getting
      redirected to one of their public folders, but still allows users of a
      workspace without access to the top level workspace to be redirected to
      the first folder they do have access to */}
      {hasCurrentWorkspaceAccess && !hasAccessToWorkspaceItem
        ? <Redirect from="/" to={redirectUrlIfNoAccessToWorkspaceItem} noThrow={true} />
        : <FolderApp path="/"/>

      }
      <FolderApp path="/manage" />
      <FolderApp path="/folders/:name--:ref-:key" />
      <FolderApp path="/folders/:name--:ref-:key/manage" />

      <Redirect from="/data/:name--:ref-:key/" to={`/${workspaceUrlName}/:name--:ref-:key/data`} />
      {/* These routes match before the error page due to how reach router
      interprets the paths. By removing them when the URL makes it clear we
      are on a page without a ref and key, lets the error route be rendered. */}
      {isPageApp && <PageApp path="/:name--:ref-:key" />}
      {isPageApp && <PageApp path="/:name--:ref-:key/*" />}

      {/* TODO: Pass in item, and response from router */}
      <DocumentForm path="/use/:documentTitle--:responseRef-:responseKey" />
      <DocumentForm path="/use/:documentTitle--:responseRef-:responseKey/print" printMode={true} />

      <DocumentCreator path="/new" />
      <DocumentCreator path="/folders/:name--:ref-:key/new" />
      <ResponseCreator path="/:documentTitle--:itemRef-:itemKey/new" />

      <ErrorPage404 path="/*" />
    </Router>
  );
}

function getIsPageApp (location) {
  const nameRefAndKeyPart = location.pathname.split('/')[2];
  const nameRefAndKeyRegex = /.*--.{24}-.{12}$/g;
  const isPageApp = !!nameRefAndKeyPart?.match(nameRefAndKeyRegex);
  return isPageApp;
}

export default WorkspaceRouter;
