import makeParagraph from 'containers/SlateEditor/factory/makeParagraph';
import {Editor} from 'slate';

function getInitialDocumentFormState ({content, responseData}) {
  const nodes = getNodesWithState(content);

  const initialState = nodes.reduce((nodesById, node) => ({
    ...nodesById,
    [node.id]: {
      id: node.id,
      value: (responseData && responseData[node.id]) || getDefaultValueForNode(node),
      updating: false,
      error: undefined,
    },
  }), {});

  return initialState;
}

function getNodesWithState (content) {
  // Convert content into a fake editor object so we can use Editor.nodes to
  // filter the nodes we care about.
  const editor = {
    children: content,
    isVoid: () => false,
  };

  return Array.from(Editor.nodes(editor, {
    at: [],
    match: (n) => [
      'process',
      'process-step',
      'form-group',
      'checkbox',
    ].includes(n.type),
  })).map(([n]) => n);
}

function getDefaultValueForNode (node) {
  if (node.type === 'form-group' && node.children[1].type === 'text-field') {
    return [makeParagraph()];
  }

  if (node.type === 'form-group' && node.children[1].type === 'choice-group') {
    return [];
  }

  if (node.type === 'checkbox') {
    return false;
  }

  return undefined;
}

export default getInitialDocumentFormState;
