import React from 'react';
import PropTypes from 'prop-types';
import useDocumentFormInput from 'containers/DocumentForm/hooks/useDocumentFormInput';
import FormGroupProvider from './providers/FormGroupProvider';
import FormGroup from './FormGroup';

FormGroupContainer.propTypes = {
  attributes: PropTypes.shape({}).isRequired,
  element: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

function FormGroupContainer (props) {
  const {
    children,
    element: {
      id,
    },
  } = props;

  const [onChange, {readOnly, submissionMode, value}] = useDocumentFormInput({id});

  return (
    <div>
      <FormGroupProvider
        onChange={onChange}
        readOnly={readOnly}
        submissionMode={submissionMode}
        value={value}>
        <FormGroup>
          {children}
        </FormGroup>
      </FormGroupProvider>
    </div>
  );
}

export default FormGroupContainer;
