import React, {useContext} from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-cycle
import {useDocument} from 'containers/Document/providers/DocumentProvider';
import {useDocumentForm} from 'containers/DocumentForm/providers/DocumentFormProvider';
import {usePrintMode} from 'containers/PrintMode/providers/PrintModeProvider';
import {ProcessStepContext} from '../ProcessStep';
import ProcessStepContent from './ProcessStepContent';

ProcessStepContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

function ProcessStepContentContainer (props) {
  const {
    done,
    editable,
    open,
    openNext,
    setDone,
    setOpen,
    id,
  } = useContext(ProcessStepContext);

  const documentState = useDocument();
  const {mode} = documentState;

  const documentFormState = useDocumentForm();
  const submitted = documentFormState ? documentFormState.submitted : false;

  const printMode = usePrintMode();

  return (
    <ProcessStepContent
      done={done}
      editable={editable}
      mode={mode}
      open={open}
      openNext={openNext}
      printMode={!!printMode}
      setDone={setDone}
      setOpen={setOpen}
      submitted={submitted}
      id={id}
      {...props} />
  );
}

export default ProcessStepContentContainer;
