import React from 'react';
import PropTypes from 'prop-types';
import tw, {css, styled} from 'twin.macro';
import {AiOutlineRight} from 'react-icons/ai';
import Placeholder from '../../Placeholder';

ProcessStepTitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  done: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  order: PropTypes.number.isRequired,
  printMode: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

function ProcessStepTitle (props) {
  const {
    children,
    done,
    mode,
    open,
    order,
    printMode,
    setOpen,
  } = props;

  const toggle = () => setOpen(!open);
  const placeholderText = order === 0 ? 'What\'s the first step?' : '';

  return (
    <Title
      done={done}
      onClick={() => mode !== 'edit' && !printMode && toggle()}
      mode={mode}
      printMode={printMode}>
      <div tw="relative" data-test-id="process_step_title">
        <Placeholder text={placeholderText}>{children}</Placeholder>
      </div>
      {!printMode &&
        <Toggler
          onClick={() => mode === 'edit' && toggle()}
          open={open}
          contentEditable={false}
          data-test-id="process-step-toggler">
          <AiOutlineRight size={16} />
        </Toggler>}
    </Title>
  );
}

const Title = styled.h4((props) => [
  tw`
    relative
    text-xl
    text-gray-800
    pb-4
    pl-4
    pr-12
  `,
  css`
    @media (max-width: 640px) {
      padding-left: 14px;
      padding-right: 40px;
      margin-left: 38px;
    }
  `,
  props.mode !== 'edit' &&
    !props.printMode &&
    tw`
    cursor-pointer
  `,
]);

const Toggler = styled.div((props) => [
  tw`
    absolute
    right-0
    top--5
    w-16
    h-16
    my-1
    items-center
    justify-center
    flex
    text-right
    cursor-pointer
    transition
    duration-300
  `,
  css`
    @media (max-width: 640px) {
      width: 56px;
    }
  `,
  props.open &&
    tw`
    transform
    rotate-90
  `,
]);

export default React.memo(ProcessStepTitle);
