import React, {useCallback, useMemo, useReducer} from 'react';
import PropTypes from 'prop-types';
import {Editor} from 'slate';
import getRootAPIUri from '../../../utils/getRootAPIUri';
import processStepsReducer from '../reducers/processStepsReducer';
import selectedElementsReducer from '../reducers/selectedElementsReducer';
import base64ImagesReducer from '../reducers/base64ImagesReducer';
import checkboxesReducer from '../reducers/checkboxesReducer';
import processesReducer from '../reducers/processesReducer';

const DocumentContext = React.createContext();

DocumentProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  content: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isWizardVisible: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  responseData: PropTypes.shape({}),
  readOnly: PropTypes.bool,
};

DocumentProvider.defaultProps = {
  isWizardVisible: false,
  readOnly: false,
};

function DocumentProvider (props) {
  const {
    children,
    content,
    isWizardVisible,
    mode,
    responseData,
    readOnly,
  } = props;

  const {
    initialProcessesState,
    initialProcessStepsState,
    initialSelectedElementsState,
    initialCheckboxesState,
  } = useMemo(() => getInitialState({content, responseData}), []);

  const [processes, dispatchProcess] = useReducer(processesReducer, initialProcessesState);
  const [processSteps, dispatchProcessStep] = useReducer(processStepsReducer, initialProcessStepsState);
  const [checkboxes, dispatchCheckbox] = useReducer(checkboxesReducer, initialCheckboxesState);
  const [selectedElements, dispatchSelectedElement] = useReducer(selectedElementsReducer, initialSelectedElementsState);
  const [base64Images, dispatchBase64Image] = useReducer(base64ImagesReducer, []);

  const context = {
    isWizardVisible,
    mode,
    readOnly,

    // Current state
    processes,
    processSteps,
    checkboxes,
    selectedElements,
    base64Images,

    // Process actions
    openProcess: (id) => dispatchProcess({type: 'open', id}),
    closeProcess: (id) => {
      dispatchProcess({type: 'close', id});

      Object.values(processSteps)
        .filter(({processId}) => processId === id)
        .forEach(({id: processStepId}) => dispatchProcessStep({
          id: processStepId,
          type: 'close',
        }));
    },

    // Process step actions
    openProcessStep: (id) => dispatchProcessStep({type: 'open', id}),
    closeProcessStep: (id) => dispatchProcessStep({type: 'close', id}),
    openNextProcessStep: (id) => {
      dispatchProcessStep({type: 'openNext', id});

      const processStep = processSteps[id];
      if (!processStep.nextProcessStep) {
        return;
      }

      const nextProcessStep = processSteps[processStep.nextProcessStep.id];
      if (processStep.processId !== nextProcessStep.processId) {
        // Delay by 200ms so the open animation waits until after the previous
        // process step has closed.
        setTimeout(() => {
          dispatchProcess({type: 'open', id: nextProcessStep.processId});
        }, 200);
      }
    },
    markProcessStepAsDone: (id) => {
      dispatchProcessStep({type: 'markDone', id});

      const processStep = processSteps[id];
      const {processId} = processStep;
      dispatchProcess({type: 'markStepDone', id: processId, processStep});

      if (!processStep.nextProcessStep) {
        return;
      }

      const nextProcessStep = processSteps[processStep.nextProcessStep.id];
      if (processStep.processId !== nextProcessStep.processId) {
        // Delay by 200ms so the open animation waits until after the previous
        // process step has closed.
        setTimeout(() => {
          dispatchProcess({type: 'open', id: nextProcessStep.processId});
        }, 200);
      }
    },
    markProcessStepAsNotDone: (id) => {
      dispatchProcessStep({type: 'markNotDone', id});

      const processStep = processSteps[id];
      const {processId} = processStep;
      dispatchProcess({type: 'markStepNotDone', id: processId, processStep});
    },

    // Checkbox actions
    checkCheckbox: (id) => dispatchCheckbox({type: 'check', id}),
    uncheckCheckbox: (id) => dispatchCheckbox({type: 'uncheck', id}),

    // Selection actions
    selectElements: useCallback((elementIds) => dispatchSelectedElement({type: 'selectElements', elementIds}), []),
    selectElementsInRange: useCallback((elementIds) => dispatchSelectedElement({type: 'selectElementsInRange', elementIds}), []),

    // Image actions
    uploadBase64Image: useCallback(({
      id, file, data, onSuccess,
    }) => {
      dispatchBase64Image({
        type: 'register',
        id,
        file,
        data,
      });

      const uri = `${getRootAPIUri()}/upload-image`;
      const formData = new FormData();
      formData.append('file', file);
      fetch(uri, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        body: formData,
      })
        .then((response) => response.json())
        .then(({url}) => {
          onSuccess(url);
          dispatchBase64Image({
            type: 'unregister',
            id,
          });
        });
    }, []),
  };

  return <>
    <DocumentContext.Provider value={context}>
      {children}
    </DocumentContext.Provider>
  </>;
}

export function useDocument () {
  const context = React.useContext(DocumentContext);

  if (context === undefined) {
    throw new Error('useDocument must be used within a DocumentProvider');
  }

  return context;
}

function getInitialState ({content, responseData}) {
  const processNodes = content.filter((node) => node.type === 'process');
  const processStepNodes = processNodes
    .filter((processNode) => processNode.children.length > 0)
    .flatMap((processNode) => processNode.children[1].children.map((processStepNode, i) => ({
      ...processStepNode,
      processId: processNode.id,
      order: i,
    })));

  // Convert content into a fake editor object so we can use Editor.nodes to
  // recursively select checkbox nodes.
  const editor = {
    children: content,
    isVoid: () => false,
  };
  const checkboxNodes = Array.from(Editor.nodes(editor, {
    at: [],
    match: (n) => [
      'checkbox',
    ].includes(n.type),
  })).map(([n]) => n);

  const initialProcessStepsState = processStepNodes.reduce((processStepsById, node, i) => ({
    ...processStepsById,
    [node.id]: {
      id: node.id,
      ...node,
      open: false,
      done: !!responseData && responseData[node.id] === true,
      nextProcessStep: processStepNodes.length > i + 1 ? {
        id: processStepNodes[i + 1].id,
        processId: processStepNodes[i + 1].processId,
      } : undefined,
    },
  }), {});

  const firstIncompleteStep = processStepNodes.find((processStep) => !initialProcessStepsState[processStep.id].done);
  const initialProcessesState = processNodes.reduce((processesById, node, i) => ({
    ...processesById,
    [node.id]: {
      open: firstIncompleteStep ? node.id === firstIncompleteStep.processId : i === 0,
      order: i,
      progress: node.children[1].children.map((processStepNode) => initialProcessStepsState[processStepNode.id].done),
    },
  }), {});

  const initialCheckboxesState = checkboxNodes.reduce((checkboxNodesById, node) => ({
    ...checkboxNodesById,
    [node.id]: {
      id: node.id,
      ...node,
      checked: !!responseData && responseData[node.id] === true,
    },
  }), {});

  const initialSelectedElementsState = {
    ids: [],
  };

  return {
    initialProcessesState,
    initialProcessStepsState,
    initialCheckboxesState,
    initialSelectedElementsState,
  };
}

export default DocumentProvider;
