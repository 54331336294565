import insertDataWhenElementSelected from '../inserters/insertDataWhenElementSelected';
import insertLink from '../inserters/insertLink';
import getSelectionMeta from '../utils/getSelectionMeta';
import insertDataIntoSingleLineElement from '../inserters/insertDataIntoSingleLineElement';
import insertDataIntoSingleLineElementGroup from '../inserters/insertDataIntoSingleLineElementGroup';
import insertFragment from '../inserters/insertFragment';
import insertFragmentIntoContent from '../inserters/insertFragmentIntoContent';
import insertImage from '../inserters/insertImage';
import insertImageUrl from '../inserters/insertImageUrl';
import insertHtml from '../inserters/insertHtml';
import insertMarkdown from '../inserters/insertMarkdown';
import insertFragmentIntoDocumentTitle from '../inserters/insertFragmentIntoDocumentTitle';

const makeInsertData = (editor, {selectedElements, uploadBase64Image}) => {
  const {insertData} = editor;

  return (data) => {
    const selectionMeta = getSelectionMeta(editor);

    // These individual handlers will return true if they handle the event, else
    // false. As a result we can chain them with ||.
    // The order of the handlers is important.
    return (
      insertDataWhenElementSelected(editor, data, selectionMeta, selectedElements) ||
      insertDataIntoSingleLineElementGroup(editor, data, selectionMeta) ||
      insertDataIntoSingleLineElement(editor, data, selectionMeta) ||
      insertImageUrl(editor, data, selectionMeta) ||
      insertImage(editor, data, selectionMeta, {uploadBase64Image}) ||
      insertLink(editor, data, selectionMeta) ||
      insertFragmentIntoDocumentTitle(editor, data, selectionMeta) ||
      insertFragmentIntoContent(editor, data, selectionMeta) ||
      insertFragment(editor, data, selectionMeta) ||
      insertHtml(editor, data, selectionMeta) ||
      insertMarkdown(editor, data, selectionMeta) ||
      insertData(data)
    );
  };
};

export default makeInsertData;
