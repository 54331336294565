import PropTypes from 'prop-types';
import {gql} from '@apollo/client';
import useMutation from 'hooks/useMutation';
import {useQueryClient} from '@tanstack/react-query';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';

const ITEM_SHARING_SETTINGS_UPDATE = gql`
  mutation itemSharingSettingsUpdate($itemRef: String!, $itemKey: String!, $sharingSettings: SharingSettings!) {
    itemSharingSettingsUpdate(itemRef: $itemRef, itemKey: $itemKey, sharingSettings: $sharingSettings) {
      ref
      key
    }
  }
`;

useItemSharingSettingsUpdateMutation.propTypes = {
  itemRef: PropTypes.string.isRequired,
  itemKey: PropTypes.string.isRequired,
  sharingSettings: PropTypes.object.isRequired,
};

function useItemSharingSettingsUpdateMutation (props) {
  const {
    itemRef,
    itemKey,
    sharingSettings,
  } = props;

  const queryClient = useQueryClient();
  const {urlName} = useWorkspace();

  const [onUpdatePermission, {
    loading, data, error, reset,
  }] = useMutation(ITEM_SHARING_SETTINGS_UPDATE, {
    variables: {
      itemRef,
      itemKey,
      sharingSettings,
    },
    refetchQueries: [
      'GetItems',
      'GetItem',
      'GetIsItemPublic',
      'GetWorkspaceItem',
      'GET_ITEM_WITH_DOCUMENT_SNAPSHOT',
    ],
    onCompleted: () => {
      // As we have partially migrated to react query, we need to invalidate the react
      // query cache, similar to how refetchQueries behaves in Apollo.
      queryClient.invalidateQueries([urlName, 'items']);
    },
  });

  return [onUpdatePermission, {
    loading,
    error,
    data,
    reset,
  }];
}

export default useItemSharingSettingsUpdateMutation;
