import React from 'react';
import PropTypes from 'prop-types';

const UserContext = React.createContext();

UserProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  user: PropTypes.shape({}),
};

UserProvider.defaultProps = {
};

function UserProvider (props) {
  const {
    children,
    user,
  } = props;

  const context = {
    user,
  };

  return <>
    <UserContext.Provider value={context}>
      {children}
    </UserContext.Provider>
  </>;
}

export function useUser () {
  const context = React.useContext(UserContext);

  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }

  return context;
}

export default UserProvider;
