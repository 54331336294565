import {Transforms} from 'slate';

function onBackspaceOrDeleteWithSelectedElements (editor, event, selectionMeta, {selectElements, selectedElements}) {
  if (
    !['Backspace', 'Delete'].includes(event.key) ||
    selectedElements.ids.length === 0
  ) {
    return false;
  }

  if (!selectedElements.inRange) {
    event.preventDefault();
  }

  Transforms.removeNodes(editor, {
    at: [],
    match: (n) => selectedElements.ids.includes(n.id),
  });

  selectElements([]);

  return true;
}

export default onBackspaceOrDeleteWithSelectedElements;
