import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled, css} from 'twin.macro';
import PageToolbar, {PageToolbarRight} from 'components/PageToolbar';
import {Link} from '@reach/router';
import Button, {ButtonSpacer} from 'components/Button';
import {
  AiFillInfoCircle, AiOutlineEdit, AiOutlineEye, AiOutlineMore,
} from 'react-icons/ai';
import DropdownButton from 'components/DropdownButton';
import Tooltip from 'components/Tooltip';
import {useItem} from 'providers/ItemProvider';
import {PERMISSIONS, ITEM_TYPE} from 'am-constants';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';
import FlexSpacer from 'components/FlexSpacer';
import {getPermissionsForRoles} from 'am-utils';

DocumentToolbarComponent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  editMode: PropTypes.bool,
  itemUrlName: PropTypes.string.isRequired,
  setIsCopyPageModalVisible: PropTypes.func,
  setIsDeleteItemModalVisible: PropTypes.func,
  setIsDeleteResponseModalVisible: PropTypes.func,
  setIsMoveItemModalVisible: PropTypes.func,
  setIsShareItemModalVisible: PropTypes.func,
  showCopyPageMenu: PropTypes.bool,
  showDeleteItemMenu: PropTypes.bool,
  showDeleteResponseMenu: PropTypes.bool,
  showExportItemMenu: PropTypes.bool,
  showMoveItemMenu: PropTypes.bool,
  showShareItemMenu: PropTypes.bool,
  showViewResponsesMenu: PropTypes.bool,
  viewMode: PropTypes.bool,
};

DocumentToolbarComponent.defaultProps = {
  editMode: false,
  showDeleteResponseMenu: false,
  showMoveItemMenu: false,
  showExportItemMenu: false,
  showViewResponsesMenu: false,
};

function DocumentToolbarComponent (props) {
  const {
    children,
    editMode,
    setIsCopyPageModalVisible,
    setIsDeleteItemModalVisible,
    setIsDeleteResponseModalVisible,
    setIsMoveItemModalVisible,
    setIsShareItemModalVisible,
    showDeleteItemMenu,
    showDeleteResponseMenu,
    showExportItemMenu,
    showMoveItemMenu,
    showShareItemMenu,
    showViewResponsesMenu,
    viewMode,
  } = props;

  const {urlName: workspaceUrlName, isExampleWorkspace, workspacePermissions} = useWorkspace();
  const {item} = useItem();
  const {
    itemTypeId,
    key,
    publishedAt,
    ref,
    urlName,
    loggedInUserRoleIds,
  } = item;
  const loggedInUserItemPermissions = getPermissionsForRoles(loggedInUserRoleIds);

  const examplePageContent = <>
    This is an example page
    <ButtonSpacer />
    <Tooltip title="This workspace is full of examples and templates for you to review and copy!" placement="bottom">
      <AiFillInfoCircle size={18} />
    </Tooltip>
    <FlexSpacer />
    <Button purple darkBg onMouseDown={(e) => {
      e.preventDefault();
      setIsCopyPageModalVisible(true);
    }}>Copy to my workspace</Button>
  </>;

  if (!workspacePermissions.includes(PERMISSIONS.VIEW_WORKSPACE)) {
    return (
      <PageToolbar
        purple={isExampleWorkspace}
        darkMode={isExampleWorkspace}
        sticky={isExampleWorkspace}
        data-test-id="document-toolbar">
        <PageToolbarRight>
          {isExampleWorkspace && !editMode && examplePageContent}
          {children}
        </PageToolbarRight>
      </PageToolbar>
    );
  }

  const isDraft = !publishedAt;
  const isChecklistOrForm = [ITEM_TYPE.CHECKLIST, ITEM_TYPE.FORM].includes(itemTypeId);
  const apiDomain = window.location.hostname.includes('localhost') ? 'http://a.m.localhost:3000' : 'https://app.airmanual.co';

  const viewResponsesItemMenu = (isChecklistOrForm && showViewResponsesMenu) ? [
    {
      disabled: ![PERMISSIONS.VIEW_RESPONSES, PERMISSIONS.VIEW_OWN_RESPONSES]
        .some((p) => loggedInUserItemPermissions.includes(p)),
      to: `/${workspaceUrlName}/${urlName}/data`,
      label: 'View submissions',
    },
  ] : [];

  const exportItemMenu = showExportItemMenu ? [
    {
      disabled: !loggedInUserItemPermissions.includes(PERMISSIONS.VIEW_RESPONSES),
      to: `${apiDomain}/api/export/responses/csv?itemRef=${ref}&itemKey=${key}`,
      label: 'Export data',
      newTab: true,
    },
  ] : [];

  const moveItemMenu = showMoveItemMenu ? [
    {
      disabled: !loggedInUserItemPermissions.includes(PERMISSIONS.MOVE_ITEM),
      onClick: () => setIsMoveItemModalVisible(true),
      label: 'Move',
    },
  ] : [];

  const deleteItemMenu = showDeleteItemMenu ? [
    {
      disabled: !loggedInUserItemPermissions.includes(PERMISSIONS.DELETE_ITEM),
      onClick: () => setIsDeleteItemModalVisible(true),
      label: 'Delete',
    },
  ] : [];

  const deleteResponseMenu = showDeleteResponseMenu ? [
    {
      disabled: !loggedInUserItemPermissions.includes(PERMISSIONS.DELETE_RESPONSES),
      onClick: () => setIsDeleteResponseModalVisible(true),
      label: 'Delete submission',
    },
  ] : [];

  const shareItemMenu = showShareItemMenu ? [
    {
      onClick: () => setIsShareItemModalVisible(true),
      label: 'Share',
    },
  ] : [];

  const menu = [
    [
      ...viewResponsesItemMenu,
      ...exportItemMenu,
      ...shareItemMenu,
      {
        to: './print',
        label: 'Print',
        newTab: true,
      },
      ...moveItemMenu,
      ...deleteItemMenu,
      ...deleteResponseMenu,
    ],
  ];

  const canEdit = loggedInUserItemPermissions.includes(PERMISSIONS.EDIT_PAGE);
  const onClickEditButton = (e) => {
    if (!canEdit) {
      e.preventDefault();
    }
  };

  return (<>
    <PageToolbar
      purple={isExampleWorkspace && !editMode}
      dark={editMode || isExampleWorkspace}
      sticky={editMode || isExampleWorkspace}
      data-test-id="document-toolbar">
      <PageToolbarRight>
        {isExampleWorkspace && !editMode && examplePageContent}
        <ButtonSpacer />
        {children}
        {children && <ButtonSpacer />}
        {!viewMode && !isDraft && <>
          <Tooltip placement="bottom" title={'View published page'}>
            <ToolbarLink to={`/${workspaceUrlName}/${urlName}`} data-test-id="document-toolbar_view"><AiOutlineEye size="24" /></ToolbarLink>
          </Tooltip>
          <ButtonSpacer />
        </>}
        {!editMode && <>
          <Tooltip placement="bottom" title={'Edit page'}>
            <ToolbarLink to={`/${workspaceUrlName}/${urlName}/edit`} disabled={!canEdit} onClick={onClickEditButton} data-test-id="edit-process-tooltip"><AiOutlineEdit size="24" /></ToolbarLink>
          </Tooltip>
          <ButtonSpacer />
        </>}
        <DropdownButton primary menu={menu} doNotWrapInButton>
          <div css="transform: rotate(90deg);" data-test-id="burger-menu">
            <AiOutlineMore size="24" tw="my-1.5 cursor-pointer"/>
          </div>
        </DropdownButton>
      </PageToolbarRight>
    </PageToolbar>
  </>);
}

const ToolbarLink = styled(Link)((props) => [
  !props.disabled && tw`
    hover:text-blue-300
  `,
  css`
    color: inherit;
  `,
]);

export default DocumentToolbarComponent;
