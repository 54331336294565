import React from 'react';
import PropTypes from 'prop-types';
import GridRow from './GridRow';
import GridCell from './GridCell';
import GridCellGroup from './GridCellGroup';
import GridCellGroupHeader from './GridCellGroupHeader';

DataGridGroupHeader.propTypes = {
  columnGroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

DataGridGroupHeader.defaultProps = {
};

function DataGridGroupHeader (props) {
  const {
    columnGroups,
  } = props;

  return <>
    <GridRow groupHeader>
      {columnGroups.map((columnGroup, i) => (
        <GridCellGroup key={`grid-cell-group${i}`}>
          <GridCellGroupHeader>
            {columnGroup.label}
          </GridCellGroupHeader>
          {/*
          The GridCells are rendered as a simple way of sizing the GridCellGroup
          correctly.
          */}
          {columnGroup.columns && columnGroup.columns.map((column) => (
            <GridCell
              key={column.label}
              width={column.width}>
            </GridCell>
          ))}
        </GridCellGroup>
      ))}
    </GridRow>
  </>;
}

export default DataGridGroupHeader;
