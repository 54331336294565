import isHotkey from 'is-hotkey';
import {MODIFIER_KEYS} from '../constants';
import getSelectionMeta from '../utils/getSelectionMeta';
import onArrowDownBeforeCollapsedContent from '../keyDownHandlers/onArrowDownBeforeCollapsedContent';
import onArrowLeftAfterCollapsedContent from '../keyDownHandlers/onArrowLeftAfterCollapsedContent';
import onArrowRightBeforeCollapsedContent from '../keyDownHandlers/onArrowRightBeforeCollapsedContent';
import onArrowSymbol from '../keyDownHandlers/onArrowSymbol';
import onArrowUpAfterCollapsedContent from '../keyDownHandlers/onArrowUpAfterCollapsedContent';
import onBackspaceOrShiftArrowAfterSelectableElement from '../keyDownHandlers/onBackspaceOrShiftArrowAfterSelectableElement';
import onBackspaceAtStartOfCodeBlock from '../keyDownHandlers/onBackspaceAtStartOfCodeBlock';
import onBackspaceAtStartOfCollapsibleContent from '../keyDownHandlers/onBackspaceAtStartOfCollapsibleContent';
import onBackspaceAtStartOfCollapsibleTitle from '../keyDownHandlers/onBackspaceAtStartOfCollapsibleTitle';
import onBackspaceAtStartOfIndentedParagraph from '../keyDownHandlers/onBackspaceAtStartOfIndentedParagraph';
import onBackspaceAtStartOfLabel from '../keyDownHandlers/onBackspaceAtStartOfLabel';
import onBackspaceAtStartOfListElement from '../keyDownHandlers/onBackspaceAtStartOfListElement';
import onBackspaceAtStartOfProcessStepTitle from '../keyDownHandlers/onBackspaceAtStartOfProcessStepTitle';
import onBackspaceOrDeleteWithSelectedElements from '../keyDownHandlers/onBackspaceOrDeleteWithSelectedElements';
import onDeleteAtEndOfCollapsibleContent from '../keyDownHandlers/onDeleteAtEndOfCollapsibleContent';
import onDeleteAtEndOfCollapsibleTitle from '../keyDownHandlers/onDeleteAtEndOfCollapsibleTitle';
import onDeleteOrShiftArrowBeforeSelectableElement from '../keyDownHandlers/onDeleteOrShiftArrowBeforeSelectableElement';
import onEnterAtStartOrEndOfHeading from '../keyDownHandlers/onEnterAtStartOrEndOfHeading';
import onEnterInEmptyLastChoice from '../keyDownHandlers/onEnterInEmptyLastChoice';
import onEnterInElementWithNoText from '../keyDownHandlers/onEnterInElementWithNoText';
import onEnterInHelpBlockTitle from '../keyDownHandlers/onEnterInHelpBlockTitle';
import onEnterInProcessStepTitle from '../keyDownHandlers/onEnterInProcessStepTitle';
import onEnterInProcessTitle from '../keyDownHandlers/onEnterInProcessTitle';
import onEnterOnVoidElement from '../keyDownHandlers/onEnterOnVoidElement';
import onKeyboardShortcut from '../keyDownHandlers/onKeyboardShortcut';
import onKeyDownWithSelectedElements from '../keyDownHandlers/onKeyDownWithSelectedElements';
import onMarkdown from '../keyDownHandlers/onMarkdown';
import onShiftEnterInCodeBlock from '../keyDownHandlers/onShiftEnterInCodeBlock';
import onShiftEnterInProcessStepTitle from '../keyDownHandlers/onShiftEnterInProcessStepTitle';
import onTabInDocumentBody from '../keyDownHandlers/onTabInDocumentBody';
import onTabInDocumentTitle from '../keyDownHandlers/onTabInDocumentTitle';
import onBackspaceOrDeleteWithSelectedImage from '../keyDownHandlers/onBackspaceOrDeleteWithSelectedImage';
import onBackspaceAfterFormGroup from '../keyDownHandlers/onBackspaceAfterFormGroup';
import onDeleteBeforeFormGroup from '../keyDownHandlers/onDeleteBeforeFormGroup';
import onBackspaceAtStartOfChoiceGroup from '../keyDownHandlers/onBackspaceAtStartOfChoiceGroup';
import onDeleteAtEndOfChoiceGroup from '../keyDownHandlers/onDeleteAtEndOfChoiceGroup';
import onEnterInChoice from '../keyDownHandlers/onEnterInChoice';
import onEnterAtStartOfLabel from '../keyDownHandlers/onEnterAtStartOfLabel';
import onEnterInTextFieldLabel from '../keyDownHandlers/onEnterInTextFieldLabel';
import onEnterInChoiceGroupLabel from '../keyDownHandlers/onEnterInChoiceGroupLabel';
import onArrowDownInHorizontalOrRatingChoice from '../keyDownHandlers/onArrowDownInHorizontalOrRatingChoice';
import onArrowUpInHorizontalOrRatingChoice from '../keyDownHandlers/onArrowUpInHorizontalOrRatingChoice';
import onTabInChoice from '../keyDownHandlers/onTabInChoice';
import onEnterInRatingChoice from '../keyDownHandlers/onEnterInRatingChoice';
import onArrowLeftFromStartOfLastRatingScaleOption from '../keyDownHandlers/onArrowLeftFromStartOfLastRatingScaleChoice';
import onArrowRightFromEndOfFirstRatingScaleChoice from '../keyDownHandlers/onArrowRightFromEndOfFirstRatingScaleChoice';

const makeOnKeyDown = (editor, {selectedElements, selectElements}) => (event) => {
  const isLightboxOpen = !!document.querySelector('.SRLContainer');
  if (isLightboxOpen) {
    return false;
  }

  const selectionMeta = getSelectionMeta(editor);

  if (
    ['Backspace', 'Delete'].includes(event.key) && (
      onBackspaceOrDeleteWithSelectedElements(editor, event, selectionMeta, {selectedElements, selectElements}) ||
      onBackspaceOrDeleteWithSelectedImage(editor, event, selectionMeta)
    )
  ) {
    return true;
  }

  if (
    !MODIFIER_KEYS.includes(event.key) &&
    !event.metaKey &&
    !event.ctrlKey &&
    onKeyDownWithSelectedElements(editor, event, selectionMeta, {selectedElements, selectElements})
  ) {
    return true;
  }

  if (event.key === 'Backspace' && (
    onBackspaceAtStartOfChoiceGroup(editor, event, selectionMeta) ||
    onBackspaceAtStartOfCodeBlock(editor, event, selectionMeta) ||
    onBackspaceAtStartOfIndentedParagraph(editor, event, selectionMeta) ||
    onBackspaceAtStartOfLabel(editor, event, selectionMeta) ||
    onBackspaceAtStartOfListElement(editor, event, selectionMeta) ||
    onBackspaceAtStartOfCollapsibleContent(editor, event, selectionMeta) ||
    onBackspaceAtStartOfProcessStepTitle(editor, event, selectionMeta) ||
    onBackspaceAtStartOfCollapsibleTitle(editor, event, selectionMeta) ||
    onBackspaceAfterFormGroup(editor, event, selectionMeta)
  )) {
    return true;
  }

  if (
    (
      event.key === 'Backspace' ||
      isHotkey('shift+left')(event) ||
      isHotkey('shift+up')(event)
    ) &&
    // Note: This needs to be called after the other backspace handlers.
    onBackspaceOrShiftArrowAfterSelectableElement(editor, event, selectionMeta, {selectElements})
  ) {
    return true;
  }

  if (
    (
      event.key === 'Delete' ||
      isHotkey('shift+down')(event) ||
      isHotkey('shift+right')(event)
    ) &&
    onDeleteOrShiftArrowBeforeSelectableElement(editor, event, selectionMeta, {selectElements})
  ) {
    return true;
  }

  if (event.key === 'Delete' && (
    onDeleteAtEndOfChoiceGroup(editor, event, selectionMeta) ||
    onDeleteAtEndOfCollapsibleContent(editor, event, selectionMeta) ||
    onDeleteAtEndOfCollapsibleTitle(editor, event, selectionMeta) ||
    onDeleteBeforeFormGroup(editor, event, selectionMeta)
  )) {
    return true;
  }

  if (event.key === 'Enter' && (
    onEnterAtStartOrEndOfHeading(editor, event, selectionMeta) ||
    onEnterAtStartOfLabel(editor, event, selectionMeta) ||
    onEnterInEmptyLastChoice(editor, event, selectionMeta) ||
    onEnterInElementWithNoText(editor, event, selectionMeta) ||
    onEnterInTextFieldLabel(editor, event, selectionMeta) ||
    onEnterInChoiceGroupLabel(editor, event, selectionMeta) ||
    onEnterInRatingChoice(editor, event, selectionMeta) ||
    onEnterInChoice(editor, event, selectionMeta) ||
    onEnterInHelpBlockTitle(editor, event, selectionMeta) ||
    onEnterInProcessStepTitle(editor, event, selectionMeta) ||
    onEnterInProcessTitle(editor, event, selectionMeta) ||
    onEnterOnVoidElement(editor, event, selectionMeta) ||
    onShiftEnterInCodeBlock(editor, event, selectionMeta) ||
    onShiftEnterInProcessStepTitle(editor, event, selectionMeta)
  )) {
    return true;
  }

  if (event.key === 'Tab' && (
    onTabInChoice(editor, event, selectionMeta) ||
    onTabInDocumentBody(editor, event, selectionMeta) ||
    onTabInDocumentTitle(editor, event, selectionMeta)
  )) {
    return true;
  }

  // These individual handlers will return true if they handle the event, else
  // false. As a result we can chain them with ||.
  return (
    onArrowDownBeforeCollapsedContent(editor, event, selectionMeta) ||
    onArrowLeftAfterCollapsedContent(editor, event, selectionMeta) ||
    onArrowLeftFromStartOfLastRatingScaleOption(editor, event, selectionMeta) ||
    onArrowRightBeforeCollapsedContent(editor, event, selectionMeta) ||
    onArrowRightFromEndOfFirstRatingScaleChoice(editor, event, selectionMeta) ||
    onArrowUpAfterCollapsedContent(editor, event, selectionMeta) ||
    onArrowUpInHorizontalOrRatingChoice(editor, event, selectionMeta) ||
    onArrowDownInHorizontalOrRatingChoice(editor, event, selectionMeta) ||
    onArrowSymbol(editor, event, selectionMeta) ||
    onKeyboardShortcut(editor, event, selectionMeta) ||
    onMarkdown(editor, event, selectionMeta)
  );
};

export default makeOnKeyDown;
