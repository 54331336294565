import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import tw, {styled, css} from 'twin.macro';
import {withHistory} from 'slate-history';
import {Transforms, Editor, createEditor} from 'slate';
import {
  useSlateStatic,
  withReact,
  ReactEditor,
} from 'slate-react';
import TextAreaEditor from './TextAreaEditor';
import TextInputEditor from './TextInputEditor';
import {useFormGroup} from './FormGroup/providers/FormGroupProvider';

TextField.propTypes = {
  attributes: PropTypes.shape({}).isRequired,
  element: PropTypes.shape({
    size: PropTypes.oneOf(['single', 'multi']),
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

TextField.defaultProps = {
};

function TextField (props) {
  const {
    element,
    children,
  } = props;
  const {size} = element;

  const [onChange, {readOnly, submissionMode, value}] = useFormGroup();
  const editor = useSlateStatic();

  if (!submissionMode) {
    return (
      <DummyTextField
        contentEditable={false}
        readOnly={true}
        size={size}
        onClick={() => selectEndOfLabel(editor, element)}
        {...props.attributes}>
        {children}
      </DummyTextField>
    );
  }

  const TextFieldEditor = size === 'single' ? TextInputEditor : TextAreaEditor;
  const slateEditor = useMemo(() => withReact(withHistory(createEditor())), []);

  return (
    <TextFieldEditor
      editor={slateEditor}
      readOnly={readOnly}
      onChange={onChange}
      value={value}
    />
  );
}

const selectEndOfLabel = (editor, element) => {
  const path = ReactEditor.findPath(editor, element);
  // eslint-disable-next-line
  const [formGroup, formGroupPath] = Editor.parent(editor, path);
  const endOfLabel = Editor.end(editor, [...formGroupPath, 0]);

  Transforms.select(
    editor,
    endOfLabel
  );
};

const DummyTextField = styled.div((props) => [
  tw`
    border
    border-gray-200
    bg-white
    mb-0.5
    select-none
    cursor-not-allowed
  `,
  css`
    height: 128px;
  `,
  props.size === 'single' && css`
    height: 46px;
  `,
]);

export default TextField;
