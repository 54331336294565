function getDocumentTitleRefAndKeyParts (documentTitleRefAndKey) {
  let documentTitle;
  let itemRefAndKey;
  let ref;
  let key;

  const parts = documentTitleRefAndKey.split('--');
  if (parts.length === 2) {
    [documentTitle, itemRefAndKey] = parts;

    const resourceParts = itemRefAndKey.split('-');
    if (resourceParts.length === 2) {
      [ref, key] = resourceParts;
    }
  }

  return {
    documentTitle,
    ref,
    key,
  };
}

export default getDocumentTitleRefAndKeyParts;
