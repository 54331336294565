import React from 'react';
import {tailwind} from 'am-config';
import tw, {styled, css} from 'twin.macro';

const {theme: {colors}} = tailwind;

function renderLeaf (props) {
  return (
    <Leaf
      {...props.attributes}
      {...props.leaf}>
      {props.children}
    </Leaf>
  );
}

const Leaf = styled.span((props) => [
  props.bold && tw`font-bold`,
  props.highlight && tw`
    bg-blue-100
    py-0.5
  `,
  props.italic && tw`italic`,
  props.underline && tw`underline`,
  props.strikethrough && tw`line-through`,
  props.highlight && css`
    & ::selection {
      background-color: ${colors.blue[500]};
    }
  `,
]);

export default renderLeaf;
