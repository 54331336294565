import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled} from 'twin.macro';
import {AiOutlineRight} from 'react-icons/ai';
import Placeholder from '../../Placeholder';

HelpBlockTitle.propTypes = {
  attributes: PropTypes.shape({}).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  color: PropTypes.string,
  mode: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  printMode: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

function HelpBlockTitle (props) {
  const {
    children,
    color,
    mode,
    open,
    printMode,
    setOpen,
  } = props;

  const toggle = () => setOpen(!open);

  return (
    <Title open={open}>
      {!printMode &&
      <Toggler
        color={color}
        onClick={() => mode === 'edit' && toggle()}
        open={open}
        contentEditable={false}
        data-test-id="help-block-toggler">
        <AiOutlineRight />
      </Toggler>
      }
      <div data-test-id="help_block_title" tw="flex-1">
        <Placeholder
          color={color}
          text={printMode ? '' : 'A title for your help content'}>
          {children}
        </Placeholder>
      </div>
    </Title>
  );
}

const Title = styled.h4((props) => [
  tw`
    relative
    my-2.5
    px-3
    ml-1
    pl-8
    text-gray-700
    flex
    items-center
  `,
]);

const Toggler = styled.div((props) => [
  tw`
    absolute
    left-0
    ml--2
    w-10
    h-10
    items-center
    justify-center
    flex
    text-right
    cursor-pointer
    transition
    duration-300
    text-gray-400
    text-sm
  `,
  props.open && tw`
    transform
    rotate-90
  `,
  props.color === 'yellow' && tw`
    text-yellow-600
  `,
  props.color === 'red' && tw`
    text-red-400
  `,
  props.color === 'green' && tw`
    text-green-600
  `,
  props.color === 'blue' && tw`
    text-blue-400
  `,
]);

export default HelpBlockTitle;
