import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled} from 'twin.macro';
import CustomPropTypes from 'utils/CustomPropTypes';
import {ITEM_TYPE} from 'am-constants';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';
import {Link, useLocation} from '@reach/router';
import {AiFillHome} from 'react-icons/ai';

BreadcrumbItem.propTypes = {
  item: CustomPropTypes.Item,
  makeLink: PropTypes.bool,
  home: PropTypes.bool,
  sm: PropTypes.bool,
};

BreadcrumbItem.defaultProps = {
  makeLink: true,
  home: false,
  sm: false,
};

function BreadcrumbItem (props) {
  const {
    item,
    makeLink,
    home,
    sm,
  } = props;

  const location = useLocation();
  const isManageView = location.pathname.split('/').pop() === 'manage';

  const {urlName: workspaceUrlName, featureFlags} = useWorkspace();
  const url = getItemUrl(workspaceUrlName, item, featureFlags) + (isManageView ? '/manage' : '');

  const name = home
    ? (<span data-test-id={'home-breadcrumb'}>
      <AiFillHome tw="text-gray-400"/>
    </span>)
    : item.name;

  return (
    <BreadcrumbItemWrapper
      data-test-id={'breadcrumb'}
      featureFlag={featureFlags['2022_09_dashboard']}
      home={home}
      sm={sm}>
      {!makeLink && name}
      {makeLink && <Link to={`${url}`}>{name}</Link>}
    </BreadcrumbItemWrapper>
  );
}

function getItemUrl (workspaceUrlName, item, featureFlags) {
  if (item.itemTypeId === ITEM_TYPE.WORKSPACE) {
    return `/${workspaceUrlName}`;
  }

  return `/${workspaceUrlName}/folders/${item.urlName}`;
}

export const BreadcrumbItemWrapper = styled.div((props) => [
  tw`
    h-8
    flex
    items-center
  `,
  props.featureFlag && tw`
    px-2
  `,
  props.featureFlag && props.sm && tw`
    px-1.5
  `,
  props.featureFlag && props.home && tw`
    pl-0
  `,
]);

export default BreadcrumbItem;
