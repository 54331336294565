import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled, css} from 'twin.macro';
import {
  useSelected, useFocused, ReactEditor, useSlateStatic,
} from 'slate-react';
import {tailwind} from 'am-config';
import {useDocument} from 'containers/Document/providers/DocumentProvider';
import {Transforms} from 'slate';
import {sanitizeUrl} from '@braintree/sanitize-url';
const {theme: {colors}} = tailwind;

Image.propTypes = {
  attributes: PropTypes.shape({}),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  element: PropTypes.shape({
    id: PropTypes.string,
    url: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    align: PropTypes.string,
  }),
};

Image.defaultProps = {
};

function Image (props) {
  const {
    children,
    element,
  } = props;
  const {
    id,
    url,
    width,
    height,
    align,
  } = element;

  // For some reason, clicking on an image inside a process step or help block
  // would not select it (— but selecting one at the top level of the document
  // would!). Having onClick = selectImage fixes that bug.
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element); // eslint-disable-line no-case-declarations
  const selected = useSelected();
  const focused = useFocused();

  const selectImage = () =>
    Transforms.setSelection(editor, {
      anchor: {path: [...path, 0], offset: 0},
      focus: {path: [...path, 0], offset: 0},
    });

  // When viewing the image in edit mode, the user should double click to zoom
  // in (rather than single click). A single click should open the image toolbar
  // instead.
  const documentState = useDocument();

  const {mode} = documentState;
  const needsToDoubleClickToZoom = mode === 'edit';

  const data = documentState.base64Images[id] && documentState.base64Images[id].data;
  const whiteGif = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=';
  const src = url || data || whiteGif;

  let preview = false;
  const previewProps = {};
  if (!url) {
    preview = true;
    previewProps.preview = true;
  }

  // Note: All the styling is to try and make sure:
  // - The image appears correctly proportioned when it doesn't use the full
  //   width of the editor.
  // - The image appears correctly proportioned when it does use the full width
  //   of the editor.
  // - The preview image and spinner uses the same size as the resulting image.
  return (
    <div>
      <div contentEditable={false}>
        <Align style={{width}} align={align}>
          <Wrapper
            preview={preview}
            style={{
              width,
              maxWidth: '100%',
              // width and height may not be set for images added before we made
              // updates in Sep 2021. This fix ensures these images look good!
              paddingTop: width ? `${height / width * 100}%` : 0,
              position: 'relative',
            }}>
            <Img
              src={sanitizeUrl(src)}
              selected={selected}
              focused={focused}
              id={id}
              onClick={selectImage}
              {...previewProps}
              style={{
                height: '100%',
                width: '100%',
                maxHeight: '100%',
                maxWidth: '100%',
                // width and height may not be set for images added before we made
                // updates in Sep 2021. This fix ensures these images look good!
                position: width || preview ? 'absolute' : 'relative',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
              }} />
            {!preview && (!focused || !selected) && needsToDoubleClickToZoom && (
              <div
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                }}>
              </div>
            )}
            {preview && (
              <div
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                }}>
                <Loader>
                  <svg tw="animate-spin -ml-1 mr-3 h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle tw="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path tw="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </Loader>
              </div>
            )}
          </Wrapper>
        </Align>
      </div>
      {children}
    </div>
  );
}

const Align = styled.div((props) => [
  tw`
    clear-both
    mb-4
    max-w-full
  `,
  props.align === 'left' &&
    tw`
    float-left
    mr-6
  `,
  props.align === 'left' &&
    css`
      max-width: min(50%, 256px);
    `,
  props.align === 'right' &&
    tw`
    float-right
    ml-6
  `,
  props.align === 'right' &&
    css`
      max-width: min(50%, 256px);
    `,
]);

const Wrapper = styled.div((props) => [
  tw`
    inline-block
    relative
  `,
  props.preview && tw`
    bg-blue-50
  `,
]);

const Img = styled.img((props) => [
  css`
    cursor: zoom-in;
  `,
  props.preview && tw`
    opacity-50!
  `,
  props.selected && props.focused && css`
    outline: 3px solid ${colors.blue[500]};
    // Putting the outline within the image avoids it being cropped by hiding
    // the overflow of a process step
    outline-offset: -3px;
  `,
]);

const Loader = styled.div((props) => [
  tw`
    absolute
    top-0
    left-0
    z-10
    flex
    w-full
    h-full
    items-center
    justify-center
    text-center
  `,
]);

export default Image;
