import React from 'react';
import Modal from 'components/Modal';
import Button, {ButtonSpacer} from 'components/Button';
import PropTypes from 'prop-types';
import ItemSharerModalContent from './ItemSharerModalContent';

ItemSharerModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  hasItemSharerPermissions: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  isItemPublic: PropTypes.bool,
  itemName: PropTypes.string.isRequired,
  itemUrl: PropTypes.string,
  noAccessMessage: PropTypes.node,
  onSubmit: PropTypes.func.isRequired,
  renderError: PropTypes.func,
  handlePermissionChange: PropTypes.func,
  sharingSettings: PropTypes.object.isRequired,
  setSharingSettings: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

function ItemSharerModal (props) {
  const {
    closeModal,
    hasItemSharerPermissions,
    isVisible,
    isItemPublic,
    itemName,
    itemUrl,
    noAccessMessage,
    onSubmit,
    renderError,
    sharingSettings,
    setSharingSettings,
    title,
  } = props;
  return (
    <Modal
      size="lg"
      visible={isVisible}
      title={title}
      onClose={closeModal}
      footer={<>
        <Button type="button" link onClick={closeModal}>
          {hasItemSharerPermissions ? 'Cancel' : 'Close'}
        </Button>
        <ButtonSpacer />
        {hasItemSharerPermissions && <Button type="submit" primary>
          Save changes
        </Button>}
      </>}
      onSubmit={onSubmit}
      renderError={renderError}>
      <ItemSharerModalContent
        hasItemSharerPermissions={hasItemSharerPermissions}
        isItemPublic={isItemPublic}
        itemName={itemName}
        itemUrl={itemUrl}
        noAccessMessage={noAccessMessage}
        sharingSettings={sharingSettings}
        setSharingSettings={setSharingSettings}/>
    </Modal>
  );
}

export default ItemSharerModal;
