import {gql} from '@apollo/client';
import useMutation from 'hooks/useMutation';

const REMOVE_TEAM_MEMBER = gql`
  mutation RemoveTeamMember($workspaceId: Int!, $email: String!) {
    teamMemberRemove(workspaceId: $workspaceId, email: $email) {
      personId
    }
  }
`;

function useRemoveTeamMemberMutation (props) {
  const [removeTeamMember, {
    loading, error, data, reset,
  }] = useMutation(REMOVE_TEAM_MEMBER, {
    fetchPolicy: 'no-cache',
    refetchQueries: [
      'GetTeamMembersWithRoles',
    ],
  });

  return [
    removeTeamMember,
    {
      data,
      loading,
      error,
      reset,
    },
  ];
}

export default useRemoveTeamMemberMutation;
