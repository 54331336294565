import React from 'react';
import TeamMembersPage from 'components/TeamMembersPage';
import {Helmet} from 'react-helmet';

function TeamMemberViewerForbidden (props) {
  return <>
    <Helmet>
      <title>Team</title>
    </Helmet>
    <TeamMembersPage teamMembers={[]} errorMessage={'You do not have permission to view team members.'}/>
  </>;
}

export default TeamMemberViewerForbidden;
