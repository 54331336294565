import React, {useContext} from 'react';
import {useDocument} from 'containers/Document/providers/DocumentProvider';
import {usePrintMode} from 'containers/PrintMode/providers/PrintModeProvider';
import {HelpBlockContext} from '../HelpBlock';
import HelpBlockTitle from './HelpBlockTitle';

function HelpBlockTitleContainer (props) {
  const {
    color,
    open,
    setOpen,
  } = useContext(HelpBlockContext);
  const {mode} = useDocument();
  const printMode = usePrintMode();

  return (
    <HelpBlockTitle
      color={color}
      mode={mode}
      open={open}
      printMode={!!printMode}
      setOpen={setOpen}
      {...props} />
  );
}

export default HelpBlockTitleContainer;
