function getPageCouldBePublic () {
  const url = window.location.pathname;

  const workspaceExplorerRegex = /^\/[^/]+$/;
  const documentViewerRegex = /^\/[^/]+\/[^/]+--[a-zA-Z0-9]{24}-[a-zA-Z0-9]{12}(\/print)?$/;
  const folderRegex = /^\/[^/]+\/folders\//;
  const documentFormRegex = /^\/[^/]+\/use\//;

  const isWorkspaceExplorer = workspaceExplorerRegex.test(url);
  const isDocumentViewer = documentViewerRegex.test(url);
  const isDocumentForm = documentFormRegex.test(url);

  const isFolder = folderRegex.test(url);
  const isResponseCreator = /\/new$/.test(url);

  if (
    isWorkspaceExplorer ||
    isDocumentViewer ||
    isDocumentForm ||
    isFolder ||
    isResponseCreator
  ) {
    return true;
  }

  return false;
}

export default getPageCouldBePublic;
