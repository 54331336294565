const inlineElementTypes = [
  'link',
];

const makeIsInline = (editor) => {
  const {isInline} = editor;

  return (element) => (inlineElementTypes.includes(element.type) ? true : isInline(element));
};

export default makeIsInline;
