import tw, {styled, css} from 'twin.macro';

import React from 'react';
import PropTypes from 'prop-types';
import {motion} from 'framer-motion';

Error.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Error.defaultProps = {
};

function Error (props) {
  const {
    children,
    ...rest
  } = props;

  return (
    <motion.div
      initial={{opacity: 0, height: 0}}
      animate={{opacity: 1, height: 'auto'}}
      transition={{duration: 0.15}}>
      <ErrorMessage data-test='form-error-message' {...rest}>{children}</ErrorMessage>
    </motion.div>
  );
}

const ErrorMessage = styled.div((props) => [
  tw`
    bg-red-500
    text-sm
    py-2
    px-2.5
    text-white
    relative
    before:border-red-500
  `,
  css`
    a {
      color: white;
      text-decoration: underline;
    }
    a:hover {
      color: white;
      text-decoration: none;
    }
  `,
  props.arrow && tw`
    mt-4
  `,
  props.arrow && css`
    &::before {
      bottom: 100%;
      left: 12px;
      border: solid transparent;
      content: '';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-bottom-color: rgba(225.03750000000002,45.262499999999996,57.24749999999984,var(--tw-bg-opacity));;
      border-width: 8px;
    }
  `,
]);

export default Error;
