import makeProcessStepContent from './makeProcessStepContent';
import makeProcessStepTitle from './makeProcessStepTitle';

const makeProcessStep = (attributes = {}) => {
  const {title, content, ...rest} = attributes;

  return {
    type: 'process-step',
    ...rest,
    children: [
      makeProcessStepTitle({title}),
      makeProcessStepContent({content}),
    ],
  };
};

export default makeProcessStep;
