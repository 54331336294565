import tw, {styled} from 'twin.macro';

import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {nanoid} from 'nanoid';

FormGroup.propTypes = {
  buttons: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  narrow: PropTypes.bool,
  top: PropTypes.bool,
  bottom: PropTypes.bool,
  wide: PropTypes.bool,
};

FormGroup.defaultProps = {
  buttons: false,
  top: false,
  bottom: false,
  narrow: false,
  wide: false,
};

function FormGroup (props) {
  const {
    children,
    ...rest
  } = props;

  const childArray = children.length ? children : [children];

  const id = useMemo(() => `formgroup-input-${nanoid()}}`, []);

  const accessibleChildren = useMemo(() => {
    const label = childArray.find((child) => child.type.displayName === 'Label');

    if (!label) {
      return childArray;
    }

    return children.map((child, index) => {
      const key = `${id}-${index}`;
      if (child.type.displayName === 'Label') {
        return React.cloneElement(child, {htmlFor: id, key});
      }

      if (child.type.displayName === 'Input') {
        return React.cloneElement(child, {id, key});
      }

      return React.cloneElement(child, {key});
    });
  }, [children]);

  return <StyledFormGroup {...rest}>{accessibleChildren}</StyledFormGroup>;
}

// Exported so Form apply additional styles
export const StyledFormGroup = styled.div((props) => [
  tw`
    my-7
    max-w-xl
  `,
  // max-w is !important so it overides the Form > FormGroup style
  props.narrow && tw`
    max-w-sm!
  `,
  // max-w is !important so it overides the Form > FormGroup style
  props.wide && tw`
    max-w-full!
  `,
  props.buttons && tw`
    text-right
    pt-2
    mb-0
  `,
  props.top && tw`
    mt-0
  `,
  props.bottom && tw`
    mb-0
  `,
]);

export default FormGroup;
