import {Editor, Transforms} from 'slate';

function onTabInDocumentTitle (editor, event, selectionMeta) {
  const {
    isSelectionInSingleElement,
    selectionStart,
    selectionStartElement,
  } = selectionMeta;

  if (
    event.key !== 'Tab' ||
    !selectionStart ||
    !isSelectionInSingleElement ||
    selectionStartElement.type !== 'title'
  ) {
    return false;
  }

  event.preventDefault();

  const startOfNextElement = Editor.start(editor, [1]);

  Transforms.setSelection(editor, {
    anchor: startOfNextElement,
    focus: startOfNextElement,
  });

  return true;
}

export default onTabInDocumentTitle;
