import React from 'react';
import PropTypes from 'prop-types';
import GridCellPopover from 'components/DataGrid/GridCellPopover';
import tw from 'twin.macro'; // eslint-disable-line no-unused-vars
import ContentDocumenterCellPopover from './ContentDocumenterCellPopover';
import SavingCellContent from './SavingCellContent';
import ContentDocumenterCellContent from './ContentDocumenterCellContent';

ContentDocumenterCell.propTypes = {
  itemId: PropTypes.number,
  itemTypeId: PropTypes.number.isRequired,
  documenters: PropTypes.arrayOf(PropTypes.shape({})),
  onUpdateItem: PropTypes.func.isRequired,
  saving: PropTypes.bool,
};

ContentDocumenterCell.defaultProps = {
  saving: false,
};

function ContentDocumenterCell (props) {
  const {
    itemId,
    itemTypeId,
    documenters,
    onUpdateItem,
    saving,
  } = props;

  const popover = (
    <ContentDocumenterCellPopover
      documenters={documenters}
      onUpdateItem={onUpdateItem} />
  );

  return (
    <div tw="w-full h-full" data-test-id={`documenter-cell-${itemId}`}>
      <GridCellPopover content={popover}>
        <SavingCellContent saving={saving}>
          <ContentDocumenterCellContent
            itemId={itemId}
            itemTypeId={itemTypeId}
            documenters={documenters}
          />
        </SavingCellContent>
      </GridCellPopover>
    </div>

  );
}

export default ContentDocumenterCell;
