import {Editor, Transforms} from 'slate';

function onDeleteBeforeFormGroup (editor, event, selectionMeta) {
  const {
    isSelectionAtEndOfElement,
    isSelectionCollapsed,
    selectionStart,
    selectionStartElement,
    selectionStartElementPath,
    selectionStartTextNode,
  } = selectionMeta;

  if (
    event.key !== 'Delete' ||
    !selectionStart ||
    !isSelectionAtEndOfElement ||
    !isSelectionCollapsed
  ) {
    return false;
  }

  const [nextElement] = Editor.next(editor, {
    at: selectionStartElementPath,
  }) || [undefined, undefined];

  if (
    !nextElement ||
    nextElement.type !== 'form-group'
  ) {
    return false;
  }

  event.preventDefault();

  // Move cursor into the form group
  Transforms.move(editor, {
    distance: 1,
    offset: 'line',
  });

  // Delete the previous element if it's an empty paragraph
  if (
    selectionStartElement.type === 'paragraph' &&
    selectionStartTextNode.text.trim() === ''
  ) {
    Transforms.removeNodes(editor, {
      at: selectionStartElementPath,
    });
  }

  return true;
}

export default onDeleteBeforeFormGroup;
