import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import PageToolbar, {PageToolbarRight} from 'components/PageToolbar';
import DocumentToolbar from 'containers/DocumentToolbar';
import Button, {ButtonSpacer} from 'components/Button';
import Tooltip from 'components/Tooltip';
import {STATUS_CODE} from 'am-constants';
import getApolloErrorStatusCode from 'utils/getApolloErrorStatusCode';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';

DocumentEditorPageToolbar.propTypes = {
  onPublish: PropTypes.func.isRequired,
  publishing: PropTypes.bool.isRequired,
  saveError: PropTypes.shape({
    graphQLErrors: PropTypes.arrayOf(PropTypes.shape({
      extensions: PropTypes.shape({
        exception: PropTypes.shape({
          statusCode: PropTypes.number,
        }),
      }),
    })),
  }),
};

function DocumentEditorPageToolbar (props) {
  const {
    onPublish,
    publishing,
    saveError,
  } = props;

  const {featureFlags} = useWorkspace();

  const isVersionError = useMemo(() => {
    const errStatusCode = getApolloErrorStatusCode(saveError);
    return errStatusCode === STATUS_CODE.COULD_NOT_PROCESS;
  }, [saveError]);

  if (isVersionError) {
    return (
      <PageToolbar error>
        This document has been updated in another window.
        <PageToolbarRight>
          <Button darkBg onClick={() => window.location.reload(false)}>Refresh</Button>
        </PageToolbarRight>
      </PageToolbar>
    );
  }

  if (saveError) {
    return (
      <PageToolbar error>
        We could not save your changes. Retrying...
      </PageToolbar>
    );
  }

  return (
    <DocumentToolbar
      editMode
      showDeleteItemMenu
      showMoveItemMenu
      showShareItemMenu
      showViewResponsesMenu
    >
      <Button green darkBg onClick={onPublish} disabled={publishing}>
        {!publishing && 'Publish'}
        {publishing && 'Publishing...'}
      </Button>
      {featureFlags.slateCollaborate && <>
        <ButtonSpacer />
        <Tooltip title="Multiplayer enabled" placement="bottomLeft"><span style={{cursor: 'default', userSelect: 'none'}}>🚀</span></Tooltip>
      </>}
    </DocumentToolbar>
  );
}

export default DocumentEditorPageToolbar;
