import React from 'react';
import PropTypes from 'prop-types';

import {useDocument} from 'containers/Document/providers/DocumentProvider';
import {
  ReactEditor, useFocused, useSelected, useSlateStatic,
} from 'slate-react';
import {MAX_RATING_OPTIONS} from 'containers/SlateEditor/constants';
import {useChoiceGroup} from '../ChoiceGroup/providers/ChoiceGroupProvider';
import Choice from './Choice';
import removeChoice from './utils/removeChoice';

ChoiceContainer.propTypes = {
  attributes: PropTypes.shape({}).isRequired,
  element: PropTypes.shape({
    id: PropTypes.string.isRequired,
    children: PropTypes.array,
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

function ChoiceContainer (props) {
  const {
    element,
    children,
  } = props;
  const {
    children: elementChildren,
    id,
  } = element;

  const {mode} = useDocument();
  const {
    choiceIds,
    format,
    numChoices,
    readOnly,
    value,
    submissionMode,
    supportMultipleChoices,
    onChange,
  // The editor can somehow get into a temporary state where a choice is
  // orphaned from the choice-group when:
  // - Selecting and removing a form-group in the editor
  // - Selecting a few choices and copy-pasting into an ordered list
  // - (And probably in some other scenarios!)
  // Providing the default state below avoids an error being thrown.
  } = useChoiceGroup() || {
    choiceIds: [],
  };

  const checked = value && value.some(({id: checkedId}) => checkedId === id);
  const order = choiceIds.indexOf(id) + 1;
  const rating = format === 'rating' ? order : undefined;
  const isFirstChoice = order === 1;
  const isLastChoice = order === numChoices || (format === 'rating' && order === MAX_RATING_OPTIONS);

  const inputOnClick = () => {
    if (!submissionMode || readOnly) {
      return;
    }

    onChange({
      id,
      label: elementChildren[0].text,
      rating,
    });
  };

  const focused = useFocused();
  const selected = useSelected();
  const isFocused = focused && selected && mode === 'edit';

  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element);
  const onRemove = () => removeChoice(editor, path);

  return (
    <Choice
      checked={checked}
      format={format}
      isFirstChoice={isFirstChoice}
      isFocused={isFocused}
      isLastChoice={isLastChoice}
      mode={mode}
      onClick={inputOnClick}
      onRemove={onRemove}
      order={order}
      readOnly={readOnly || mode === 'view'}
      supportMultipleChoices={supportMultipleChoices}>
      {children}
    </Choice>
  );
}

export default ChoiceContainer;
