import {useEffect} from 'react';
import PropTypes from 'prop-types';
import {COOKIE_PURPOSE} from 'am-constants';

useScript.propTypes = {
  id: PropTypes.string.isRequired,
  src: PropTypes.string,
  innerHTML: PropTypes.string,
  position: PropTypes.oneOf([
    'head',
    'body',
  ]),
  purposes: PropTypes.arrayOf(PropTypes.oneOf(Object.values(COOKIE_PURPOSE)).isRequired),
  onLoad: PropTypes.func,
  isCookieConsentRequired: PropTypes.bool,
  condition: PropTypes.func,
};

useScript.defaultProps = {
  position: 'body',
  isCookieConsentRequired: true,
};

function useScript (props) {
  // Prop validation not applied automatically for hooks
  PropTypes.checkPropTypes(useScript.propTypes, props, undefined, 'useScript');

  const {
    id,
    src,
    innerHTML,
    position,
    onLoad,
    purposes,
    isCookieConsentRequired,
    condition,
  // defaultProps not applied automatically for hooks
  } = {...useScript.defaultProps, ...props};

  if (!purposes && isCookieConsentRequired) {
    throw new Error('purposes is required when isCookieConsentRequired is true (default)');
  }

  if (!src && !innerHTML) {
    throw new Error('useScript requires either src or innerHTML to be set');
  }

  if (src && innerHTML) {
    throw new Error('useScript cannot have both src or innerHTML set');
  }

  useEffect(() => {
    if (condition && !condition()) {
      return () => {};
    }

    if (document.getElementById(id)) {
      return () => {};
    }

    const script = document.createElement('script');
    script.id = id;

    if (src) {
      script.src = src;
      script.async = true;
    }
    else {
      script.innerHTML = innerHTML;
    }

    if (isCookieConsentRequired) {
      script.type = 'text/plain';
      script.className =
        src !== undefined ? '_iub_cs_activate' : '_iub_cs_activate-inline';
      script.setAttribute('data-iub-purposes', purposes.join(','));
    }

    document[position].appendChild(script);

    if (onLoad) {
      script.addEventListener('load', onLoad);
    }

    return () => {
      if (script.parentNode !== null) {
        script.parentNode.removeChild(script);
      }
    };
  }, [Object.values(props)]);
}

export default useScript;
