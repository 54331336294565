import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled} from 'twin.macro';
import {useSlateStatic} from 'slate-react';
import {Node} from 'slate';
import {
  AiOutlineFileImage,
  AiOutlineVideoCamera,
  AiOutlineUnorderedList,
  AiOutlineOrderedList,
  AiOutlineCheckSquare,
  AiOutlineDatabase,
  AiOutlineForm,
  AiOutlineQuestionCircle,
} from 'react-icons/ai';
import FlexSpacer from 'components/FlexSpacer';
import {CgRadioChecked} from 'react-icons/cg';
import ToolbarItem from './ToolbarItem';
import makeCheckbox from '../factory/makeCheckbox';
// import makeCodeBlock from '../factory/makeCodeBlock';
import makeListItem from '../factory/makeListItem';
import makeOrderedListItem from '../factory/makeOrderedListItem';
import convertSelectedNodes from '../utils/convertSelectedNodes';
import makeTextField from '../factory/makeTextField';
import insertImage from '../utils/insertImage';
import insertVideo from '../utils/insertVideo';
import insertProcess from '../utils/insertProcess';
import makeHelpBlock from '../factory/makeHelpBlock';
import insertNodeAtSelection from '../utils/insertNodeAtSelection';
import Toolbar, {ToolbarItemDivider} from './Toolbar';
import makeChoiceGroup from '../factory/makeChoiceGroup';

FooterMenu.propTypes = {
  showHelpBlockOption: PropTypes.bool.isRequired,
  showInputOptions: PropTypes.bool.isRequired,
  showProcessOption: PropTypes.bool.isRequired,
  uploadBase64Image: PropTypes.func.isRequired,
};

function FooterMenu (props) {
  const {
    showHelpBlockOption,
    showInputOptions,
    showProcessOption,
    uploadBase64Image,
  } = props;
  const editor = useSlateStatic();

  const selectedElement = Node.descendant(editor, editor.selection.anchor.path.slice(0, -1));
  if (['title', 'process-title', 'process-step-title'].includes(selectedElement.type)) {
    return null;
  }

  const ToolbarItems = [
    {
      title: 'Add image',
      onClick: () => insertImage(editor, uploadBase64Image),
      icon: AiOutlineFileImage,
      testId: 'add-image',
    },
    {
      title: 'Add video',
      onClick: () => insertVideo(editor),
      icon: AiOutlineVideoCamera,
      testId: 'add-video',
    },
    {
      title: 'Add bulleted list',
      onClick: () => convertSelectedNodes(editor, {makeNode: makeListItem}),
      icon: AiOutlineUnorderedList,
      testId: 'add-bulleted-list',
    },
    {
      title: 'Add numbered list',
      onClick: () => convertSelectedNodes(editor, {makeNode: makeOrderedListItem}),
      icon: AiOutlineOrderedList,
      testId: 'add-numbered-list',
    },
    // {
    //   title: 'Add code block',
    //   onClick: () => convertSelectedNodes(editor, {makeNode: makeCodeBlock}),
    //   icon: AiOutlineCode,
    // },
    ...(
      showHelpBlockOption ? [
        {
          title: 'Add help block',
          onClick: () => insertNodeAtSelection(editor, {makeNode: makeHelpBlock}),
          icon: AiOutlineQuestionCircle,
          testId: 'add-help-block',
        },
      ] : []
    ),
  ];

  const formItems = [
    {
      title: 'Add checkbox',
      onClick: () => convertSelectedNodes(editor, {makeNode: makeCheckbox}),
      icon: AiOutlineCheckSquare,
      testId: 'add-checkbox',
    },
    {
      title: 'Add multiple choice input',
      onClick: () => insertNodeAtSelection(editor, {makeNode: makeChoiceGroup}),
      icon: CgRadioChecked,
      testId: 'add-multiple-choice-input',
    },
    {
      title: 'Add text input',
      onClick: () => insertNodeAtSelection(editor, {makeNode: makeTextField}),
      icon: AiOutlineForm,
      testId: 'add-text-input',
    },
  ];

  return <>
    <Container>
      <FlexSpacer />
      <Toolbar>
        {ToolbarItems.map((toolbarItem, i) => (
          <ToolbarItem
            editor={editor}
            key={`footer-menu-item-${i}`}
            data-test-id={toolbarItem.testId}
            {...toolbarItem} />
        ))}
        {showInputOptions && <>
          <ToolbarItemDivider />
          {formItems.map((item, i) => (
            <ToolbarItem
              editor={editor}
              key={`form-menu-item-${i}`}
              data-test-id={item.testId}
              {...item} />
          ))}
        </>}
        {showProcessOption ? <>
          <ToolbarItemDivider />
          <ToolbarItem
            editor={editor}
            key={'footer-menu-item-guided-checklist'}
            {...(
              {
                title: <div tw="text-center">
                  <p tw="font-medium text-white">Add a guided checklist</p>
                  <p tw="text-2xs text-green-100">Give your page superpowers!</p>
                </div>,
                onClick: () => insertProcess(editor),
                icon: AiOutlineDatabase,
                'data-test-id': 'footer-menu_btn-add-process',
              })} />
        </> : ''}
      </Toolbar>
      <FlexSpacer />
    </Container>
  </>;
}

const Container = styled.div((props) => [
  tw`
    fixed
    left-0
    bottom-6
    flex
    w-full
    z-40
    pointer-events-none
  `,
]);

export default React.memo(FooterMenu);
