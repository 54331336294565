import {Editor, Path, Transforms} from 'slate';

function onEnterInHelpBlockTitle (editor, event, selectionMeta) {
  const {
    isSelectionInSingleElement,
    selectionStart,
    selectionStartElement,
    selectionStartElementPath,
  } = selectionMeta;

  if (
    event.key !== 'Enter' ||
    !selectionStart ||
    !isSelectionInSingleElement ||
    selectionStartElement.type !== 'help-block-title'
  ) {
    return false;
  }

  event.preventDefault();

  const [helpBlockElement, helpBlockElementPath] = Editor.parent(editor, selectionStartElementPath);

  // If the help block is open and shift key is not pressed, then move selection
  // into the help block. Otherwise, move it below the help block.
  const nextElementPath = (
    helpBlockElement.open &&
    !event.shiftKey
  ) ? [...Path.next(selectionStartElementPath), 0] : [...Path.next(helpBlockElementPath)];

  const endOfNextElement = Editor.end(editor, [...nextElementPath, 0]);
  const newPoint = {
    path: endOfNextElement.path,
    offset: Math.min(endOfNextElement.offset, selectionStart.offset),
  };
  Transforms.setSelection(editor, {
    anchor: newPoint,
    focus: newPoint,
  });

  return true;
}

export default onEnterInHelpBlockTitle;
