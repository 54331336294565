import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled} from 'twin.macro';
import {css} from 'styled-components';

HelpBlock.propTypes = {
  attributes: PropTypes.shape({}).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  color: PropTypes.string,
  editable: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  selected: PropTypes.bool,
  setOpen: PropTypes.func,
  element: PropTypes.object,
};

HelpBlock.defaultProps = {
  editable: false,
  selected: false,
};

function HelpBlock (props) {
  const {
    children,
    color,
    editable,
    open,
    selected,
    setOpen,
  } = props;

  const toggle = () => setOpen(!open);

  return (
    <Wrapper
      color={color}
      selected={selected}
      open={open}
      data-test-id="help-block"
      contentEditable={editable}
      editing={editable}
      onClick={() => !editable && toggle()}
      suppressContentEditableWarning>
      {children}
    </Wrapper>
  );
}

const Wrapper = styled.div((props) => [
  tw`
    my-4
    bg-gray-50
    border-gray-50
    border-4
    relative
    overflow-hidden
    rounded
    clear-both
  `,
  css`
    -webkit-print-color-adjust: exact; // Chrome, Safari
    color-adjust: exact; // Firefox
  `,
  props.selected && tw`
    border-blue-500!
    select-none
  `,
  !props.editing && tw`
    cursor-pointer
  `,
  props.color === 'yellow' && tw`
    bg-yellow-100
    border-yellow-100
  `,
  props.color === 'red' && tw`
    bg-red-50
    border-red-50
  `,
  props.color === 'green' && tw`
    bg-green-100
    border-green-100
  `,
  props.color === 'blue' && tw`
    bg-blue-50
    border-blue-50
  `,
]);

export default React.memo(HelpBlock);
