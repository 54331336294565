import isUrl from 'is-url';
import wrapLink from '../utils/wrapLink';

const makeInsertText = (editor) => {
  const {insertText} = editor;

  return (text) => {
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    }
    else if (text) {
      insertText(text);
    }
  };
};

export default makeInsertText;
