import React from 'react';
import PropTypes from 'prop-types';
import {CONTENT_ACTION_NEEDED} from 'am-constants';
import Pill from 'components/Pill';

ContentActionNeededCellContent.propTypes = {
  contentActionNeededId: PropTypes.oneOf([
    CONTENT_ACTION_NEEDED.NEEDS_CONTENT,
    CONTENT_ACTION_NEEDED.NEEDS_UPDATES,
    CONTENT_ACTION_NEEDED.NEEDS_REVIEW,
  ]),
  contentDescription: PropTypes.arrayOf(PropTypes.shape({
    children: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string,
    })),
  })),
};

ContentActionNeededCellContent.defaultProps = {
};

function ContentActionNeededCellContent (props) {
  const {
    contentActionNeededId,
  } = props;

  if (contentActionNeededId === CONTENT_ACTION_NEEDED.NEEDS_CONTENT) {
    return (
      <Pill>Create page</Pill>
    );
  }

  if (contentActionNeededId === CONTENT_ACTION_NEEDED.NEEDS_UPDATES) {
    return (
      <Pill>Needs updates</Pill>
    );
  }

  if (contentActionNeededId === CONTENT_ACTION_NEEDED.NEEDS_REVIEW) {
    return (
      <Pill>Needs review</Pill>
    );
  }

  return <></>;
}

export default ContentActionNeededCellContent;
