import React, {
  useEffect, useMemo, useState, useRef, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import {Redirect} from '@reach/router';
import debounce from 'utils/debounce';
import {STATUS_CODE, PERMISSIONS} from 'am-constants';
import getNameRefAndKeyParts from 'utils/getNameRefAndKeyParts';
import PageUrl from 'components/PageUrl';
import getApolloErrorStatusCode from 'utils/getApolloErrorStatusCode';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';
import {addGuidanceToContent, getPermissionsForRoles, getTitleFromContent} from 'am-utils';
import {useItem} from 'providers/ItemProvider';
import {useTopbar} from 'providers/TopbarProvider';
import CreatePageWizard from 'containers/CreatePageWizard';
import {ReactEditor, withReact} from 'slate-react';
import {withHistory} from 'slate-history';
import {Transforms, createEditor} from 'slate';
import insertProcess from 'containers/SlateEditor/utils/insertProcess';
import createEmptyLineAfterTitle from 'containers/SlateEditor/AIStreaming/AISlateHelpers/createEmptyLineAfterTitle';
import insertProcessWithAI from 'containers/SlateEditor/utils/insertProcessWithAI';
import CommentsPanel from 'containers/CommentsPanel';
import CommentsProvider from 'containers/CommentsPanel/providers/CommentsProvider';
import usePublishMutation from './hooks/usePublishMutation';
import useDocumentUpdateMutation from './hooks/useDocumentUpdateMutation';
import DocumentEditor from './components/DocumentEditor';

DocumentEditorContainer.propTypes = {
  document: PropTypes.object,
  'name--:ref-:key': PropTypes.string,
  printMode: PropTypes.bool,
};

DocumentEditorContainer.defaultProps = {
  printMode: false,
};

function DocumentEditorContainer (props) {
  const {
    document,
    'name--:ref-:key': nameRefAndKey,
    printMode,
  } = props;

  const {
    ref: itemRef,
    key: itemKey,
  } = useMemo(() => getNameRefAndKeyParts(nameRefAndKey), [nameRefAndKey]);

  const {setPrintMode} = useTopbar();
  useEffect(() => {
    setPrintMode(printMode);
  });

  const {urlName: workspaceUrlName, featureFlags} = useWorkspace();

  // References allow us to get current data from update. For content,
  // we also store in state to force a rerender on update. There must be a
  // better way to handle this!
  const wasPublished = useRef(false);
  const contentAndVersion = useRef({});

  const [content, setContent] = useState(undefined);
  const [version, setVersion] = useState(undefined);
  const [saveError, setSaveError] = useState(undefined);

  const [isWizardVisible, setIsWizardVisible] = useState(false);
  const [hasWizardBeenOpened, setHasWizardBeenOpened] = useState(false);
  const isContentLoaded = content !== undefined;
  const isContentEmptyOrOnlyTitle = getIsContentEmptyOrOnlyTitle(content);

  useEffect(() => {
    if (featureFlags.wizard &&
       isContentLoaded &&
       isContentEmptyOrOnlyTitle &&
      !hasWizardBeenOpened) {
      setIsWizardVisible(true);
      setHasWizardBeenOpened(true);
    }
  }, [
    isContentLoaded,
    isContentEmptyOrOnlyTitle,
    hasWizardBeenOpened,
    featureFlags,
  ]);

  const editModeEditor = useMemo(() => withReact(withHistory(createEditor())), []);
  const [startedStreaming, setStartedStreaming] = useState(false);
  const [isCurrentlyStreaming, setIsCurrentlyStreaming] = useState(false);
  const [isStreamError, setIsStreamError] = useState(false);
  const [additionalContext, setAdditionalContext] = useState('');
  const isStreamingFinished = startedStreaming && !isCurrentlyStreaming;

  // const [streamReader, setStreamReader] = useState();
  // useEffect(() => {
  //   if (streamReader) {
  //     streamProcessWithAI({
  //       editor: editModeEditor,
  //       setIsCurrentlyStreaming,
  //       setIsWizardVisible,
  //       streamReader,
  //     });
  //   }
  // }, [
  //   streamReader,
  //   editModeEditor,
  //   setIsStreamError,
  //   setIsCurrentlyStreaming,
  //   setStartedStreaming,
  //   streamProcessWithAI,
  // ]);

  const onStream = async () => {
    ReactEditor.focus(editModeEditor);
    setStartedStreaming(true);
    setIsCurrentlyStreaming(true);
    setIsStreamError(false);

    try {
      await insertProcessWithAI({
        additionalContext,
        editor: editModeEditor,
        title: getTitleFromContent(content),
        itemId: item.id,
        useAIQuery2: false,
      });
      setIsCurrentlyStreaming(false);
      setIsWizardVisible(false);
    }
    catch (e) {
      setIsStreamError(true);
      setIsCurrentlyStreaming(false);
    }

    // const newStreamReader = await getStreamReader({
    //   additionalContext,
    //   itemId: item.id,
    //   title: getTitleFromContent(content),
    //   setIsCurrentlyStreaming,
    //   setIsStreamError,
    // });

    // setStreamReader(newStreamReader);
  };

  const onStreamAfter100ms = () => setTimeout(onStream, 100);

  useEffect(() => {
    if (featureFlags.wizard &&
       isContentLoaded &&
       !isContentEmptyOrOnlyTitle) {
      setHasWizardBeenOpened(true);
    }
  }, [isContentLoaded, isContentEmptyOrOnlyTitle, featureFlags.wizard]);

  const title = useMemo(() => getTitleFromContent(content), [content]);
  const url = useMemo(() => getEditorUrl({
    itemKey,
    itemRef,
    title,
    workspaceUrlName,
  }), [title]);

  const isVersionError = useMemo(() => {
    const errStatusCode = getApolloErrorStatusCode(saveError);
    return errStatusCode === STATUS_CODE.COULD_NOT_PROCESS;
  }, [saveError]);

  const {item} = useItem();

  if (item && !getPermissionsForRoles(item.loggedInUserRoleIds).includes(PERMISSIONS.EDIT_PAGE)) {
    const forbiddenError = new Error();
    forbiddenError.statusCode = STATUS_CODE.FORBIDDEN;
    throw forbiddenError;
  }

  const [onDocumentPublish, {publishing, published}] = usePublishMutation({
    itemRef,
    itemKey,
    onCompleted: () => {
      wasPublished.current = true;
    },
    onError: (err) => {
      setSaveError(err);

      // Retry
      setTimeout(() => {
        if (wasPublished.current) {
          return;
        }

        onPublish({variables: {content, version}});
      }, 15000);
    },
  });

  const [updateDocument, {updateLoading}] = useDocumentUpdateMutation({
    itemRef,
    itemKey,
    onUpdated: ({version: v}) => {
      setContentAndVersion({
        content,
        version: v,
      });
      setSaveError(undefined);
    },
    onError: (err) => {
      setSaveError(err);

      // Retry
      setTimeout(() => {
        debouncedUpdate();
      }, 3000);
    },
  });

  const updateLoadingRef = useRef(updateLoading);
  updateLoadingRef.current = updateLoading;

  function update () {
    // Prevent a debounced update from triggering if the document has just been published
    if (wasPublished.current) {
      return;
    }

    if (updateLoadingRef.current) {
      debouncedUpdate();
      return;
    }

    updateDocument({variables: contentAndVersion.current});
  }

  const debouncedUpdate = useMemo(() => debounce(update, 2000), []);

  useEffect(() => {
    if (!!document && content === undefined) {
      setContentAndVersion({
        content: document.content,
        version: document.version,
      });
    }
  }, [item]);

  const readOnly = publishing || published || isVersionError || printMode;

  function setContentAndVersion ({content: c, version: v}) {
    contentAndVersion.current = {
      content: c,
      version: v,
    };
    setContent(c);
    setVersion(v);
  }

  const onPublish = (args) => onDocumentPublish({
    variables: {
      content,
      version,
      ...args,
    },
  });

  const onUpdate = (c) => {
    if (JSON.stringify(content) === JSON.stringify(c)) {
      return;
    }

    setContentAndVersion({
      content: c,
      version: contentAndVersion.current.version,
    });

    debouncedUpdate();
  };

  const closeWizard = useCallback(() => {
    // streamReader?.cancel();
    ReactEditor.focus(editModeEditor);
    setIsCurrentlyStreaming(false);
    setIsStreamError(false);
    setIsWizardVisible(false);
  }, []);

  const setTitle = (text) => {
    const capitalizedText = text.charAt(0).toUpperCase() + text.slice(1);
    editModeEditor.deleteBackward('block');
    editModeEditor.insertText(capitalizedText);
  };

  const instantUpdateContent = useCallback((c) => {
    const contentWithGuidance = addGuidanceToContent(c);

    const newTitle = contentWithGuidance[0].children[0].text;
    setTitle(newTitle);

    const contentWithoutTitle = contentWithGuidance.slice(1);
    Transforms.insertNodes(
      editModeEditor,
      contentWithoutTitle,
      {
        at: null,
      }
    );
    requestAnimationFrame(() => Transforms.select(editModeEditor, null));
  }, []);

  if (wasPublished.current) {
    // TEMPORARY console.log to help debug: https://airmanual-workspace.slack.com/archives/C02J9AYMD1C/p1637590065004000
    // eslint-disable-next-line no-console
    console.log('Published: Redirecting');

    // nameRefAndKey may have been updated in editor. The editor will have also
    // updated the URL, so we can grab the updated URL directly from the browser.
    const pageNameRefAndKey = window.location.href.split('/')[4];

    return <Redirect to={`/${workspaceUrlName}/${pageNameRefAndKey}`} noThrow={true} />;
  }

  if (!content) {
    // Temporary state while setContent is being called
    return null;
  }

  const insertProcessIfNoStreamingOccured = () => {
    if (isStreamingFinished || !startedStreaming) {
      createEmptyLineAfterTitle(editModeEditor);
      insertProcess(editModeEditor);
    }
  };

  return (
    <div data-test-id="document-editor">
      {featureFlags.wizard && <CreatePageWizard
        isVisible={isWizardVisible}
        isStreamLoading={isCurrentlyStreaming}
        isStreamError={isStreamError}
        onStream={onStreamAfter100ms}
        closeWizard={closeWizard}
        setAdditionalContext={setAdditionalContext}
        setContent={instantUpdateContent}
        setTitle={setTitle}
        insertProcessIfNoStreamingOccured={insertProcessIfNoStreamingOccured}
      />}
      {featureFlags.comments && (
        <CommentsProvider content={content}>
          <CommentsPanel></CommentsPanel>
          {!readOnly && <PageUrl>{url}</PageUrl>}
          <DocumentEditor
            content={content}
            editModeEditor={editModeEditor}
            lastPublishedAt={document.publishedAt}
            onPublish={onPublish}
            onUpdate={onUpdate}
            printMode={printMode}
            published={published}
            publishing={publishing}
            readOnly={readOnly}
            saveError={saveError}
            isWizardVisible={isWizardVisible}
            itemRef={itemRef}
            itemKey={itemKey}/>
        </CommentsProvider>
      )}
      {!featureFlags.comments && (<>
        {!readOnly && <PageUrl>{url}</PageUrl>}
        <DocumentEditor
          content={content}
          editModeEditor={editModeEditor}
          lastPublishedAt={document.publishedAt}
          onPublish={onPublish}
          onUpdate={onUpdate}
          printMode={printMode}
          published={published}
          publishing={publishing}
          readOnly={readOnly}
          saveError={saveError}
          isWizardVisible={isWizardVisible}
          itemRef={itemRef}
          itemKey={itemKey}/>
      </>)}
    </div>
  );
}

function getIsContentEmptyOrOnlyTitle (content) {
  if (content === undefined) {
    return true;
  }

  const emptyContent = [
    {
      type: 'paragraph',
      children: [
        {
          text: '',
        },
      ],
    },
  ];
  const contentWithoutTitle = content?.slice(1);

  return JSON.stringify(emptyContent) === JSON.stringify(contentWithoutTitle);
}

function getEditorUrl ({
  itemKey, itemRef, title, workspaceUrlName,
}) {
  const cleanedTitle = title.trim().replace(/[^a-z0-9_]+/gi, '-').replace(/[-]{2,}/gi, '-').replace(/[_-]+$/gi, '');
  const url = `/${workspaceUrlName}/${cleanedTitle}--${itemRef}-${itemKey}/edit`;

  return url;
}

// async function getStreamReader ({
//   additionalContext, title, itemId, setIsCurrentlyStreaming, setIsStreamLoading,
// }) {
//   const urlParam = new URLSearchParams({additionalContext, title, itemId});

//   try {
//     const response = await fetch(`${getRootAPIUri()}/draft-checklist-stream?${urlParam}`, {
//       method: 'GET',
//       mode: 'cors',
//       credentials: 'include',
//     });

//     if (!response.ok) {
//       throw new Error('Network response was not ok', response);
//     }

//     return response.body.getReader();
//   }
//   catch (e) {
//     setIsCurrentlyStreaming(true);
//     setIsStreamLoading(false);

//     return null;
//   }
// }

export default DocumentEditorContainer;
