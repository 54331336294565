import PropTypes from 'prop-types';
import {useQueries, useQueryClient} from '@tanstack/react-query';
import {gql} from 'graphql-request';
import getItemUrlName from 'am-utils/utils/getItemUrlName';
import graphQLClient from 'utils/graphQLClient';
import {ITEM_MINIMAL_FIELDS} from 'fragments/ItemMinimal';

const query = gql`
  query GetItemsForFolder($folderId: Int) {
    itemsForFolderId(folderId: $folderId) {
      ${ITEM_MINIMAL_FIELDS}
    }
  }
`;

useItemsQueries.propTypes = {
  itemId: PropTypes.number,
};

function useItemsQueries (variablesForQueries) {
  const queryClient = useQueryClient();

  const queries = variablesForQueries.map(({workspaceUrlName, folderId}) => {
    const queryKey = [workspaceUrlName, 'items', {folderId}];

    const variables = {
      folderId,
    };

    const queryFn = async () => {
      const {itemsForFolderId} = await graphQLClient.request(query, variables);

      return itemsForFolderId;
    };

    const options = {
      initialData: () => {
        // If we've previously loaded the more detailed items data on the manage
        // page, we can use that data while we wait for the new data to load.
        const cachedDetailedItems = queryClient.getQueryData([
          ...queryKey,
          'detailed',
        ]);

        if (cachedDetailedItems) {
          return cachedDetailedItems;
        }

        return undefined;
      },
      select: (items) =>
        items.map((item) => ({
          ...item,
          // Save requesting from the server
          // eslint-disable-next-line no-param-reassign
          urlName: getItemUrlName(item),
        })),
      staleTime: 3 * 60 * 1000, // 3 minutes
    };

    return {
      queryKey,
      queryFn,
      ...options,
    };
  });

  return useQueries({queries});
}

export default useItemsQueries;
