import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {useComments} from '../providers/CommentsProvider';

CommentPicker.propTypes = {
  id: PropTypes.string.isRequired,
  item: PropTypes.shape({
    key: PropTypes.string.isRequired,
    ref: PropTypes.string.isRequired,
  }).isRequired,
  displayNarrow: PropTypes.bool.isRequired,
  disableDocumentWarning: PropTypes.func.isRequired,
  component: PropTypes.shape({}).isRequired,
};

function CommentPicker (props) {
  const {
    id, item, displayNarrow, disableDocumentWarning, component, ...rest
  } = props;
  const {
    closeComment, openComment, removeComment, updateCommentHeight, comments,
  } = useComments();

  const openCommentHandler = useCallback(() => {
    openComment(id);
  }, []);

  const closeCommentHandler = useCallback(() => {
    closeComment(id);
  }, []);

  const warningType = comments.find((comment) => comment.id === id).type;
  const removeCommentHandler = useCallback(() => {
    removeComment(id);
    disableDocumentWarning({
      itemRef: item.ref,
      itemKey: item.key,
      stepId: id,
      warningType,
    });
  }, [warningType]);

  const updateCommentHeightHandler = useCallback((height) => {
    updateCommentHeight(id, height);
  }, []);

  return (
    React.createElement(component, {
      key: id,
      displayNarrow,
      ...rest,
      closeComment: closeCommentHandler,
      onUpdateHeight: updateCommentHeightHandler,
      openComment: openCommentHandler,
      removeComment: removeCommentHandler,
    })
  );
}

export default CommentPicker;
