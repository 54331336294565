import wrapLink from './wrapLink';

function insertLink (editor) {
  // eslint-disable-next-line no-alert
  const url = window.prompt('Enter a URL');

  if (!url || url.trim().length === 0) {
    return;
  }

  wrapLink(editor, url);
}

export default insertLink;
