import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';
import getNameRefAndKeyParts from 'utils/getNameRefAndKeyParts';
import {Router} from '@reach/router';
import DocumentViewer from 'containers/DocumentViewer';
import ResponseTable from 'containers/ResponseTable';
import DocumentEditor from 'containers/DocumentEditor';
import ErrorPage404 from 'components/ErrorPage404';
import CustomPropTypes from 'utils/CustomPropTypes';
import Loader from 'components/Loader';
import {useTopbar} from 'providers/TopbarProvider';
import useDocumentQuery from './hooks/useDocumentQuery';

DocumentApp.propTypes = {
  nameRefAndKey: PropTypes.string,
  item: CustomPropTypes.Item,
};

function DocumentApp (props) {
  const {nameRefAndKey} = props;

  const {urlName: workspaceUrlName} = useWorkspace();
  const {printMode} = useTopbar();
  const {ref, key} = useMemo(() => getNameRefAndKeyParts(nameRefAndKey), [nameRefAndKey]);

  const {data: document, isFetching, error} = useDocumentQuery({
    workspaceUrlName,
    ref,
    key,
  });

  if (error) {
    return <p>Could not load document</p>;
  }

  // Rendering the loader on fetch means that changes made in the editor will be
  // properly reflected in the document viewer when navigating between these routes
  //
  // Not showing the loader on print pages when fetching prevents an empty page,
  // or one with a loader, from being shown in the print display.
  if (!document || (isFetching && !printMode)) {
    return <Loader/>;
  }

  return <>
    {/* primary={false} disables some unwanted scrolling when navigating between
    pages. https://github.com/reach/router/issues/242 */}

    <Router primary={false}>
      <DocumentViewer path="/" document={document} />
      <DocumentViewer path="/print" document={document} printMode={true} />

      <DocumentEditor path="/edit" document={document} />
      <DocumentEditor path="/edit/print" document={document} printMode={true} />

      {/* TODO: Pass in item, document, and response from router */}
      <ResponseTable path="/data"/>
      <ResponseTable path="/data/print" printMode={true} />

      <ErrorPage404 path="/*" />
    </Router>
  </>;
}

export default DocumentApp;
