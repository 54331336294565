import P from 'components/P';
import React from 'react';
import Comment from './Comment';

WarningTitleLong.propTypes = {
};

function WarningTitleLong (props) {
  return (
    <Comment isWarning shortText="Keep it concise" {...props}>
      <P>
        Keeping the page title concise will make it easier to find.
      </P>
      <P>
        When page titles are too long, it becomes difficult for your manual
        users to scan a list of pages and find the one they want.
      </P>
    </Comment>
  );
}

export default React.memo(WarningTitleLong);
