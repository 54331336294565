import PropTypes from 'prop-types';
import {gql} from '@apollo/client';
import useMutation from 'hooks/useMutation';
import {useQueryClient} from '@tanstack/react-query';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';

const DOCUMENT_UPDATE = gql`
  mutation documentUpdate($itemRef: String!, $itemKey: String!, $content: JSON!, $version: Int!) {
    documentUpdate(itemRef: $itemRef, itemKey: $itemKey, content: $content, version: $version) {
      id
      version
    }
  }
`;

useDocumentUpdateMutation.propTypes = {
  documentId: PropTypes.string.isRequired,
  workspaceUrlName: PropTypes.string.isRequired,
  onUpdated: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

function useDocumentUpdateMutation (props) {
  const {
    itemRef,
    itemKey,
    onUpdated,
    onError,
  } = props;

  const queryClient = useQueryClient();
  const {urlName: workspaceUrlName} = useWorkspace();

  const [updateDocument, {loading, error, data}] = useMutation(DOCUMENT_UPDATE, {
    variables: {
      itemRef,
      itemKey,
    },
    onCompleted: (d) => {
      onUpdated(d.documentUpdate);

      queryClient.invalidateQueries([workspaceUrlName, 'items']);
    },
    onError,
  });

  return [updateDocument, {
    updateLoading: loading,
    updateError: error,
    updated: !!data,
  }];
}

export default useDocumentUpdateMutation;
