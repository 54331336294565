import {navigate} from '@reach/router';

function RedirectFromDollarUrl () {
  const fixedUrl = window.location.pathname.replace('$', '-');

  navigate(fixedUrl);

  return null;
}

export default RedirectFromDollarUrl;
