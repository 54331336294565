import PropTypes from 'prop-types';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {gql} from 'graphql-request';
import graphQLClient from 'utils/graphQLClient';
import {ITEM_TYPE} from 'am-constants';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';
import {nanoid} from 'nanoid';
import {ITEM_DETAILED_FIELDS} from 'fragments/ItemDetailed';

const query = gql`
  mutation ITEM_CREATE($name: String!, $itemTypeId: Int!, $parentItemRef: String!, $parentItemKey: String!, $emoji: String, $url: String) {
    itemCreate(name: $name, itemTypeId: $itemTypeId, parentItemRef: $parentItemRef, parentItemKey: $parentItemKey, emoji: $emoji, url: $url) {
      ${ITEM_DETAILED_FIELDS}
    }
  }
`;

useCreateItemMutation.propTypes = {
  name: PropTypes.string.isRequired,
  itemTypeId: PropTypes.oneOf([
    ITEM_TYPE.DOCUMENT,
    ITEM_TYPE.FOLDER,
    ITEM_TYPE.SECTION,
  ]).isRequired,
  parentFolderId: PropTypes.number.isRequired,
  parentItemRef: PropTypes.string.isRequired,
  parentItemKey: PropTypes.string.isRequired,
  emoji: PropTypes.string,
  url: PropTypes.string,
};

function useCreateItemMutation (props) {
  const {urlName: workspaceUrlName} = useWorkspace();

  const queryClient = useQueryClient();

  const mutationFn = async (newItem) => {
    const variables = {
      ...props,
      ...newItem,
    };

    return graphQLClient.request(query, variables);
  };

  // Options set up to support optimistic updates.
  const options = {
    onMutate: async (newItem) => {
      const queryKey = [
        workspaceUrlName,
        'items',
        {folderId: newItem.parentFolderId},
        'detailed',
      ];

      // Prevent any refetches from overwriting our optimistic update
      await queryClient.cancelQueries(queryKey);

      // Optimistic update
      const previousItems = queryClient.getQueryData(queryKey);
      const tempId = nanoid();
      queryClient.setQueryData(queryKey, (items) => [
        ...(items || []),
        {
          ...newItem,
          tempId,
        },
      ]);

      // Return context for onError
      return {previousItems, tempId};
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (err, newItem, context) => {
      const queryKey = [
        workspaceUrlName,
        'items',
        {folderId: newItem.parentFolderId},
        'detailed',
      ];

      queryClient.setQueryData(queryKey, context.previousItems);
    },
    // Tanstack guide to optimstic updates suggests always refetching after
    // error or success... but this seems unnecessary.
    onSettled: () => {
      // queryClient.invalidateQueries([workspaceUrlName, 'items', {folderId}, 'detailed']);
    },
    // Instead of refetching, we'll just mark the updated item as not saving
    // once all mutations have completed.
    onSuccess: (data, updatedItem, context) => {
      const queryKey = [
        workspaceUrlName,
        'items',
        {folderId: updatedItem.parentFolderId},
        'detailed',
      ];

      const newItem = data.itemCreate;
      const {tempId} = context;

      const items = queryClient.getQueryData(queryKey) || [];
      queryClient.setQueryData(
        queryKey,
        items.map((item) => (item.tempId === tempId ? newItem : item))
      );

      // Reload the data both for the manage and browse pages
      queryClient.invalidateQueries([
        workspaceUrlName,
        'items',
        {folderId: updatedItem.parentFolderId},
      ]);

      // LEGACY: Also reload data for workspaces not using the new dashboard
      queryClient.invalidateQueries([workspaceUrlName, 'items']);
      queryClient.invalidateQueries([workspaceUrlName, 'workspaceItem']);
      queryClient.invalidateQueries([workspaceUrlName, 'search']);
      queryClient.invalidateQueries(['items']);
    },
  };

  return useMutation(mutationFn, options);
}

export default useCreateItemMutation;
