import React from 'react';
import {Helmet} from 'react-helmet';
import P from 'components/P';
import Button, {ButtonSpacer, FullWidthButtonSecondaryAction} from 'components/Button';
import Link from 'components/Link';
import SimpleFormContainer from 'components/SimpleFormContainer';
import SimpleFormHeading from 'components/SimpleFormHeading';
import SimpleFormPage from 'components/SimpleFormPage';

function ResetPasswordAlreadyUsedPage () {
  return <>
    <Helmet>
      <title>Password already reset</title>
    </Helmet>
    <SimpleFormPage>
      <SimpleFormContainer>
        <SimpleFormHeading>Password already reset</SimpleFormHeading>
        <P>
          The reset password link you clicked on has already been used.
        </P>
        <ButtonSpacer vertical />
        <Link to="/login">
          <Button primary lg fullwidth>Login</Button>
        </Link>
        <FullWidthButtonSecondaryAction>
          <Link to="/reset">Reset again</Link>
        </FullWidthButtonSecondaryAction>
      </SimpleFormContainer>
    </SimpleFormPage>
  </>;
}

export default ResetPasswordAlreadyUsedPage;
