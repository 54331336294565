import {Editor, Transforms} from 'slate';
import makeProcessStepList from '../factory/makeProcessStepList';
import makeProcessTitle from '../factory/makeProcessTitle';

// We occasionally see broken documents where a process is missing the title,
// or an empty paragraph has been added in. We've now been able to replicate
// the steps that lead to this happening. Hoping that this normalization will
// fix it after the event.
function makeSureProcessesHaveValidChildren (editor, [entryNode, entryNodePath]) {
  // It's possible this will prevent the bug from being stopped, if the bug
  // happens while interacting with another part of the document.
  // However, running this on logic on every update might cause a performance
  // issue.
  if (entryNode.type !== 'process') {
    return false;
  }

  Editor.withoutNormalizing(editor, () => {
    // Make sure we have a process-title
    const hasProcessTitle = entryNode.children.some((node) => node.type === 'process-title');
    if (!hasProcessTitle) {
      Transforms.insertNodes(editor, makeProcessTitle(), {
        at: [...entryNodePath, 0],
      });
    }

    // Make sure we have a process-step-list
    const hasProcessStepList = entryNode.children.some((node) => node.type === 'process-step-list');
    if (!hasProcessStepList) { // && entryNode.children.length < 10) {
      Transforms.insertNodes(editor, makeProcessStepList(), {
        at: [...entryNodePath, 1],
      });
    }

    // Delete everything else!
    Transforms.removeNodes(editor, {
      at: entryNodePath,
      match: (node, path) =>
        !['process-title', 'process-step-list'].includes(node.type) &&
        path.length === entryNodePath.length + 1 &&
        path[path.length - 1] >= 2,
    });
  });

  return true;
}

export default makeSureProcessesHaveValidChildren;
