import tw, {styled, css} from 'twin.macro';

const PageToolbar = styled.div((props) => [
  tw`
    relative
    top-0
    px-4
    flex
    items-center
    justify-center
    text-gray-300
    border-t
    border-blue-50
  `,
  css`
    height: 56px;
  `,
  props.sticky && tw`
    sticky
    z-40
  `,
  props.dark && tw`
    bg-gray-800
    text-gray-400
  `,
  props.purple && tw`
    bg-purple-900
    text-purple-200
    border-0
  `,
  props.error && tw`
    sticky
    bg-red-800
    text-red-100
    z-40
  `,
  (props.error || props.dark) && css`
    height: 57px;
    border-top-width: 0px;
  `,
]);

export const PageToolbarRight = styled.div((props) => [
  tw`
    flex-auto
    flex
    justify-end
    items-center
  `,
]);

export const PageToolbarSpacer = styled.div((props) => [
  tw`
    w-3
  `,
]);

export default PageToolbar;
