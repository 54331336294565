import P from 'components/P';
import React from 'react';
import A from 'components/A';
import Comment from './Comment';

WarningStepTitleLong.propTypes = {
};

function WarningStepTitleLong (props) {
  return (
    <Comment isWarning shortText="Keep it concise" {...props}>
      <P>
        Keeping your step titles concise will help make the checklist easy to
        use.
      </P>
      <P>
        You can include additional detail inside the step. <A
          target="_blank"
          href="https://airmanual.link/warnings/long"
          rel="noreferrer"
        >View example</A>
      </P>
    </Comment>
  );
}

export default React.memo(WarningStepTitleLong);
