import {Transforms} from 'slate';

const makeOnFocus = (editor) => (event) => {
  if (editor.selection !== null) {
    return;
  }

  moveCursorToEndOfTitle(editor);
};

export function moveCursorToEndOfTitle (editor) {
  const offset = editor.children[0].children[0].text.length;

  Transforms.select(editor, {
    anchor: {
      offset,
      path: [0, 0],
    },
    focus: {
      offset,
      path: [0, 0],
    },
  });
}

export default makeOnFocus;
