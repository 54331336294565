function makeImage (attrs) {
  return {
    type: 'image',
    ...attrs,
    children: [
      {text: ''},
    ],
  };
}

export default makeImage;
