import React from 'react';
import PropTypes from 'prop-types';
import DocumentUpdateUndoer from 'containers/DocumentUpdateUndoer';
import {captureException} from '@sentry/react';

class DocumentViewerErrorBoundary extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError (error) {
    // Update state so the next render will show the fallback UI.
    return {hasError: true};
  }

  // eslint-disable-next-line class-methods-use-this
  componentDidCatch (error) {
    captureException(error);
  }

  render () {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <DocumentUpdateUndoer
        itemRef={this.props.itemRef}
        itemKey={this.props.itemKey}
      >Something went wrong.</DocumentUpdateUndoer>;
    }

    return this.props.children;
  }
}

// As we use a class component, propTypes have to be defined after.
// This is because classes are not hoisted to the top of the file.
DocumentViewerErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  itemRef: PropTypes.string.isRequired,
  itemKey: PropTypes.string.isRequired,
};

export default DocumentViewerErrorBoundary;
