import makeProcessStep from './makeProcessStep';

const makeProcessStepList = ({processSteps} = {}) => ({
  type: 'process-step-list',
  children: [
    ...(
      processSteps ||
      [
        makeProcessStep(),
        makeProcessStep(),
        makeProcessStep(),
      ]
    ),
  ],
});

export default makeProcessStepList;
