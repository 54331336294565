const makeVideo = (url) => ({
  type: 'video',
  url,
  children: [
    {
      text: '',
    },
  ],
});

export default makeVideo;
