import React from 'react';
import {Helmet} from 'react-helmet';
import SettingsPage, {SettingsPageTitleBar} from 'components/SettingsPage';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';
import Loader from 'components/Loader';
import BillingPageDetails from './components/BillingPageDetails';
import useWorkspaceSubscriptionQuery from './hooks/useWorkspaceSubscriptionQuery';

function BillingPage () {
  const {urlName: workspaceUrlName} = useWorkspace();
  const {data, isLoading} = useWorkspaceSubscriptionQuery({workspaceUrlName});

  return <>
    <Helmet>
      <title>Billing</title>
    </Helmet>
    <SettingsPage>
      <SettingsPageTitleBar title="Billing">
      </SettingsPageTitleBar>
      {isLoading && <Loader />}
      {!isLoading && <BillingPageDetails {...data.workspace.subscription} />}
    </SettingsPage>
  </>;
}

export default BillingPage;
