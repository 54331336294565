import makeChoice from './makeChoice';
import makeLabel from './makeLabel';

const makeChoiceGroup = () => ({
  type: 'form-group',
  children: [
    makeLabel(),
    {
      type: 'choice-group',
      format: 'vertical',
      limit: 1,
      children: [
        makeChoice(),
        makeChoice(),
      ],
    },
  ],
});

export default makeChoiceGroup;
