import React from 'react';
import PropTypes from 'prop-types';
import Heading1 from 'components/Heading1';
import Heading2 from 'components/Heading2';
import Heading3 from 'components/Heading3';
import Table, {TableRow, TableCell} from 'components/Table';
import Indent from 'components/Indent';
import Paragraph from '../elements/Paragraph';
import Checkbox from '../elements/Checkbox';
import Video from '../elements/Video';
import ListItem from '../elements/ListItem';
import OrderedListItem from '../elements/OrderedListItem';
import DocumentTitle from '../elements/DocumentTitle';
import Image from '../elements/Image';
import Link from '../elements/Link';
import Process from '../elements/Process';
import ProcessTitle from '../elements/ProcessTitle';
import ProcessStepList from '../elements/ProcessStepList';
import ProcessStep from '../elements/ProcessStep';
import ProcessStepContent from '../elements/ProcessStepContent';
import ProcessStepTitle from '../elements/ProcessStepTitle';
import HelpBlock from '../elements/HelpBlock';
import HelpBlockTitle from '../elements/HelpBlockTitle';
import HelpBlockContent from '../elements/HelpBlockContent';
import ChoiceGroup from '../elements/ChoiceGroup';
import Choice from '../elements/Choice';
import FormGroup from '../elements/FormGroup';
import Label from '../elements/Label';
import TextField from '../elements/TextField';
import ParagraphSpacer from '../elements/ParagraphSpacer';
import DraggableElement from '../elements/DraggableElement';
import ElementWrapper from '../ElementWrapper';
import CodeBlock from '../elements/CodeBlock';

Element.propTypes = {
  attributes: PropTypes.shape({}).isRequired,
  element: PropTypes.shape({
    type: PropTypes.string.isRequired,
    indent: PropTypes.number,
    id: PropTypes.string,
    checked: PropTypes.bool,
  }).isRequired,
};

const BLOCK_ELEMENT_TYPES = [
  'help-block',
  'process',
  'form-group',
  'image',
  'video',
];

const DRAGGABLE_ELEMENT_TYPES = [
  'process-step',
];

function renderElement (props) {
  const {element} = props;
  const {
    indent,
    type,
  } = element;

  if (indent) {
    return (
      <Indent level={indent}>
        <ElementWrapper {...props}>
          <Element {...props} />
        </ElementWrapper>
      </Indent>
    );
  }

  // ParagraphSpacer providers a way for the user to create a new paragraph
  // between two block elements (e.g. between two images, or an image and a
  // help block).
  if (BLOCK_ELEMENT_TYPES.includes(type)) {
    return (
      <ParagraphSpacer {...props}>
        <ElementWrapper {...props}>
          <Element {...props} />
        </ElementWrapper>
      </ParagraphSpacer>
    );
  }

  if (DRAGGABLE_ELEMENT_TYPES.includes(type)) {
    return (
      <DraggableElement {...props}>
        <ElementWrapper {...props}>
          <Element {...props} />
        </ElementWrapper>
      </DraggableElement>
    );
  }

  return (
    <ElementWrapper {...props}>
      <Element {...props} />
    </ElementWrapper>
  );
}

function Element (props) {
  const {element} = props;
  const {type} = element;

  switch (type) {
    case 'checkbox':
      return <Checkbox {...props} />;
    case 'code-block':
      return <CodeBlock {...props} />;
    case 'choice':
      return <Choice {...props} />;
    case 'choice-group':
      return <ChoiceGroup {...props} />;
    case 'form-group':
      return <FormGroup {...props} />;
    case 'help-block':
      return <HelpBlock {...props} />;
    case 'help-block-title':
      return <HelpBlockTitle {...props} />;
    case 'help-block-content':
      return <HelpBlockContent {...props} />;
    case 'label':
      return <Label {...props} />;
    case 'title':
      return <DocumentTitle {...props} />;
    case 'heading1':
      return <Heading1 {...props} />;
    case 'heading2':
      return <Heading2 {...props} />;
    case 'heading3':
      return <Heading3 {...props} />;
    case 'image':
      return <Image {...props} />;
    case 'link':
      return <Link {...props} />;
    case 'list-item':
      return <ListItem {...props} />;
    case 'ordered-list-item':
      return <OrderedListItem {...props} />;
    case 'paragraph':
      return <Paragraph {...props} />;
    case 'process':
      return <Process {...props} />;
    case 'process-title':
      return <ProcessTitle {...props} />;
    case 'process-step':
      return <ProcessStep {...props} />;
    case 'process-step-content':
      return <ProcessStepContent {...props} />;
    case 'process-step-list':
      return <ProcessStepList {...props} />;
    case 'process-step-title':
      return <ProcessStepTitle {...props} />;
    case 'table':
      return <Table {...props} />;
    case 'table-row':
      return <TableRow {...props} />;
    case 'table-cell':
      return <TableCell {...props} />;
    case 'text-field':
      return <TextField {...props} />;
    case 'video':
      return <Video {...props} />;
    default:
      return <div {...props} />;
  }
}

export default renderElement;
