import React from 'react';
import PropTypes from 'prop-types';
import {ReactEditor} from 'slate-react';
import Tooltip from 'components/Tooltip';
import tw, {styled, css} from 'twin.macro';

ToolbarItem.propTypes = {
  disabled: PropTypes.bool,
  editor: PropTypes.shape({}).isRequired,
  icon: PropTypes.func.isRequired,
  iconSize: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  subtleHover: PropTypes.bool,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  tooltipColor: PropTypes.string,
};

ToolbarItem.defaultProps = {
  iconSize: 24,
  subtleHover: false,
};

function ToolbarItem (props) {
  const {
    disabled,
    editor,
    icon,
    iconSize,
    onClick,
    selected,
    subtleHover,
    title,
    tooltipColor,
    ...rest
  } = props;

  const menuButton = (
    <MenuButton
      onMouseDown={(e) => { // Needs to be onMouseDown not onClick in Safari
        e.preventDefault(); // Needed in Safari
        if (disabled) {
          return;
        }
        ReactEditor.focus(editor);
        onClick();
      }}
      disabled={disabled}
      icon
      selected={selected}
      subtleHover={subtleHover}
      {...rest}>
      {icon({size: iconSize})}
    </MenuButton>
  );

  if (title) {
    return (
      <Tooltip
        placement="top"
        arrow
        title={title}
        color={tooltipColor}
        mouseEnterDelay={0.3}
        overlayInnerStyle={{textAlign: 'center'}}>
        {menuButton}
      </Tooltip>
    );
  }

  return menuButton;
}

const MenuButton = styled.div((props) => [
  tw`
    h-11
    px-2
    flex
    justify-center
    items-center
    cursor-pointer
    outline-none
    hover:bg-blue-400
    focus:outline-none
    first:rounded-l
    last:rounded-r
  `,
  props.subtleHover && tw`
    text-gray-200
    hover:bg-gray-800
    hover:text-white
  `,
  props.red && tw`
    text-red-200
    hover:text-red-50
  `,
  props.subtleHover && props.selected && tw`
    border-gray-800
    text-white
  `,
  props.subtleHover && props.selected && css`
    border-top-width: 5px;
    border-bottom-width: 5px;
  `,
  props.subtleHover && props.disabled && tw`
    text-gray-600
    hover:text-gray-600
    cursor-not-allowed
  `,
]);

export default ToolbarItem;
