// eslint-disable-next-line max-classes-per-file
import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {QueryClientProvider, QueryCache, QueryClient} from '@tanstack/react-query';
import {STATUS_CODE} from 'am-constants';
import getRedirectToLoginUri from 'utils/getRedirectToLoginUri';
import {captureException} from '@sentry/react';

QueryClientProviderWithErrorHandling.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

QueryClientProviderWithErrorHandling.defaultProps = {
};

function QueryClientProviderWithErrorHandling (props) {
  const {
    children,
  } = props;

  const [errorToThrow, setErrorToThrow] = useState();

  const queryCache = useMemo(() => new QueryCache({
    onError (error, query) {
      if (!error?.response?.errors) {
        captureException(error);
        return;
      }

      const {errors} = error.response;

      const unauthorizedErrors = getUnauthorizedGraphQLErrors(errors);
      if (unauthorizedErrors.length) {
        const redirectUrl = unauthorizedErrors.find((e) => !!(e?.extensions?.exception?.redirectUrl))
          ?.extensions?.exception?.redirectUrl || getRedirectToLoginUri();

        redirectTo(redirectUrl);

        return;
      }

      const forbiddenErrors = getForbiddenErrors(errors);
      if (forbiddenErrors.length) {
        setErrorToThrow(new UnauthorizedError());
      }

      const notFoundErrors = getNotFoundGraphQLErrors(errors);
      if (notFoundErrors.length) {
        setErrorToThrow(new NotFoundError());
      }
    },
  }), []);

  const queryClient = new QueryClient({
    queryCache,
    defaultOptions: {
      queries: {
        retry: 1, // Default is 3
        networkMode: window.location.host.indexOf('localhost') !== -1 ? 'always' : 'online',
      },
    },
  });

  if (errorToThrow) {
    throw new Error(errorToThrow);
  }

  return (
    <QueryClientProvider client={queryClient}>
      {children}
    </QueryClientProvider>
  );
}

class UnauthorizedError extends Error {
  constructor (...args) {
    super(...args);
    this.name = 'UnauthorizedError';
    this.statusCode = STATUS_CODE.UNAUTHORIZED;
    this.message = this.message || 'You do not have access';
    Error.captureStackTrace(this, UnauthorizedError);
  }
}
class NotFoundError extends Error {
  constructor (...args) {
    super(...args);
    this.name = 'NotFoundError';
    this.statusCode = STATUS_CODE.NOT_FOUND;
    this.message = this.message || 'Does not exist';
    Error.captureStackTrace(this, NotFoundError);
  }
}

function getUnauthorizedGraphQLErrors (errors) {
  return errors?.filter((error) =>
    error?.extensions?.exception?.statusCode === STATUS_CODE.UNAUTHORIZED) || [];
}

function getNotFoundGraphQLErrors (errors) {
  return errors?.filter((error) =>
    error?.extensions?.exception?.statusCode === STATUS_CODE.NOT_FOUND) || [];
}

function getForbiddenErrors (errors) {
  return errors?.filter((error) =>
    error?.extensions?.exception?.statusCode === STATUS_CODE.FORBIDDEN) || [];
}

function redirectTo (url) {
  window.location = url;
}

export default QueryClientProviderWithErrorHandling;
