import React from 'react';
import PropTypes from 'prop-types';
import PageHeader, {
  // LogoButton,
  PageHeaderAvatarButton,
  // PageHeaderButton,
  PageHeaderRight,
  PageHeaderSearch,
  PageHeaderSpacer,
} from 'components/PageHeader';
import Button, {ButtonSpacer} from 'components/Button';
import {Link, useLocation} from '@reach/router';
import {
  AiFillQuestionCircle,
  AiOutlineSearch,
} from 'react-icons/ai';
import {BsFillPersonFill} from 'react-icons/bs';
// import ExampleProfileImg from 'components/ExampleProfileImg';
import DropdownButton from 'components/DropdownButton';
import SearchInput from 'components/SearchInput';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';
import {ITEM_TYPE, PERMISSIONS} from 'am-constants';
import {useUser} from 'containers/UserApp/providers/UserProvider';
// eslint-disable-next-line
import tw from 'twin.macro';
import AirManualSquareLogo from 'components/AirManualSquareLogo';
import {useItem} from 'providers/ItemProvider';
// import getInitials from 'utils/getInitials';

WorkspacePageLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  hideSearch: PropTypes.bool,
  isShowMoreVisible: PropTypes.bool,
  onSearch: PropTypes.func.isRequired,
  retrySearch: PropTypes.func.isRequired,
  showMoreResults: PropTypes.func.isRequired,
  searchError: PropTypes.object,
  searchLoading: PropTypes.bool,
  searchResults: PropTypes.arrayOf(PropTypes.shape({})),
};

WorkspacePageLayout.defaultProps = {
  hideSearch: false,
};

function WorkspacePageLayout (props) {
  const {
    children,
    hideSearch,
    isShowMoreVisible,
    onSearch,
    retrySearch,
    showMoreResults,
    searchError,
    searchLoading,
    searchResults,
  } = props;

  const {
    urlName: workspaceUrlName, workspacePermissions,
    subscription,
  } = useWorkspace();
  const {user} = useUser();
  const location = useLocation();
  const {item} = useItem();

  const homeUrl = item ? getHomeUrl(item, workspaceUrlName) : `/${workspaceUrlName}`;

  const showUserMenu = user;
  const showHelpMenu = user;
  const showLogin = !user;
  const showUpgradePlanButton = user &&
    subscription?.isFreeTrial &&
    workspacePermissions.includes(PERMISSIONS.MANAGE_WORKSPACE_BILLING);
  const userIsWorkspaceMemberOrSiteAdmin = !!user && (
    user.isSiteAdmin ||
    user.workspaces.find((workspace) => workspace.urlName === workspaceUrlName)
  );

  const helpMenu = [
    [
      {
        to: 'https://www.airmanual.co/support',
        label: 'Contact support',
        newTab: true,
      },
      {
        to: 'https://www.airmanual.co/help',
        label: 'Help center',
        newTab: true,
      },
      {
        to: `${location.protocol}//${location.host}/example`,
        label: 'Examples & templates',
        newTab: true,
      },
      {
        to: 'https://www.airmanual.co/community',
        label: 'Community chat',
        newTab: true,
      },
    ],
  ];

  const accountMenu = [
    [
      ...(
        userIsWorkspaceMemberOrSiteAdmin ? [{
          to: `/${workspaceUrlName}/team`,
          label: 'Team members',
        }] : []
      ),
      {
        to: `/${workspaceUrlName}/billing`,
        label: 'Billing',
      },
      {
        to: '/logout',
        label: 'Logout',
      },
    ],
  ];

  return <>
    <PageHeader data-test-id="topbar">
      <Link to={`${homeUrl}`} data-test-id='airmanual-logo'>
        <AirManualSquareLogo width={44} />
      </Link>
      <PageHeaderSpacer />
      {!hideSearch && <>
        <PageHeaderSearch style={{position: 'relative'}}>
          <AiOutlineSearch size={22} style={{position: 'absolute', top: 11, left: 8}} tw="text-blue-300" />
          <SearchInput
            loading={searchLoading}
            isError={Boolean(searchError)}
            isShowMoreVisible={isShowMoreVisible}
            items={searchResults}
            onSearch={onSearch}
            showMoreResults={showMoreResults}
            retrySearch={retrySearch}
          />
        </PageHeaderSearch>
        <PageHeaderSpacer />
      </>}
      <PageHeaderRight>
        {showHelpMenu &&
        <DropdownButton primary menu={helpMenu} doNotWrapInButton>
          <AiFillQuestionCircle size="26" tw="text-yellow-600 cursor-pointer" data-test-id="help-menu"/>
        </DropdownButton>}
        {showUpgradePlanButton && <>
          <ButtonSpacer />
          <Link to={`/${workspaceUrlName}/billing`}><Button tw="rounded" style={{height: 42}} green>Upgrade now</Button></Link>
        </>}
        <ButtonSpacer />
        {/* <PageHeaderButton gray>4 tasks</PageHeaderButton>
        <ButtonSpacer /> */}
        {showUserMenu &&
          <span data-test-id="user-menu">
            <DropdownButton menu={accountMenu} primary doNotWrapInButton>
              <PageHeaderAvatarButton><BsFillPersonFill size={36} /></PageHeaderAvatarButton>
              {/* <PageHeaderAvatarButton img={ExampleProfileImg} /> */}
            </DropdownButton>
          </span>}

        {showLogin && <Link to={`/login?redirect=${encodeURI(window.location.pathname + window.location.search)}`}><Button tw="h-9 px-2.5">Login</Button></Link>}
      </PageHeaderRight>
    </PageHeader>
    {children}
  </>;
}
function getItemUrl (item, workspaceUrlName) {
  const {urlName} = item;

  return `/${workspaceUrlName}/folders/${urlName}`;
}

function getHomeUrl (item, workspaceUrlName) {
  // While the item is loading, a user clicking on the logo will always be taken
  // to the workspace root folder. As soon as the item is loaded, we'll check
  // if the user actually has access to that root folder, and provide a better
  // link if they do not.
  if (!item || !item.breadcrumbs || item.itemTypeId === ITEM_TYPE.WORKSPACE) {
    return `/${workspaceUrlName}`;
  }

  if (item.breadcrumbs.length === 0) {
    return `/login?redirect=/${workspaceUrlName}`;
  }

  if (item.breadcrumbs && item.breadcrumbs[0]?.itemTypeId === ITEM_TYPE.WORKSPACE) {
    return `/${workspaceUrlName}`;
  }

  return getItemUrl(item.breadcrumbs[0], workspaceUrlName);
}

export default WorkspacePageLayout;
