import PropTypes from 'prop-types';
import {useQuery} from '@tanstack/react-query';
import {gql} from 'graphql-request';
import graphQLClient from 'utils/graphQLClient';

const query = gql`
  query GetTeamMembersWithRoles($workspaceUrlName: String!) {
    workspace(workspaceUrlName: $workspaceUrlName) {
      teamMembers {
        user {
          id
          name
          email
          hasSignedUp
        }
      }
    }
  }
`;

useTeamMembersQuery.propTypes = {
  workspaceUrlName: PropTypes.string,
};

function useTeamMembersQuery ({workspaceUrlName}) {
  const cacheKey = [workspaceUrlName, 'teamMembers'];

  const variables = {
    workspaceUrlName,
  };

  const queryFn = async () => {
    const {workspace} = await graphQLClient.request(query, variables);

    return workspace.teamMembers;
  };

  const options = {};

  return useQuery(
    cacheKey,
    queryFn,
    options
  );
}

export default useTeamMembersQuery;
