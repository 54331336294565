import makeSureLastElementIsParagraph from '../normalizations/makeSureLastElementIsParagraph';
import makeSureOrderedListsAreOrdered from '../normalizations/makeSureOrderedListsAreOrdered';
import makeSureDocumentHasOneTitle from '../normalizations/makeSureDocumentHasOneTitle';
import makeSureTitlesHaveNoFormatting from '../normalizations/makeSureTitlesHaveNoFormatting';
import makeSureLastElementInContainerIsParagraph from '../normalizations/makeSureLastElementInContainerIsParagraph';
import removeEmptyMarksArray from '../normalizations/removeEmptyMarksArray';
import makeSureFormGroupsHaveALabelAndNoParagraphs from '../normalizations/makeSureFormGroupsHaveALabelAndNoParagraphs';
import makeSureChoiceGroupsHaveTwoOrMoreChoices from '../normalizations/makeSureChoiceGroupsHaveTwoOrMoreChoices';
import makeSureNoOrphanChoices from '../normalizations/makeSureNoOrphanChoices';
import makeSureNoOrphanTextInputLabels from '../normalizations/makeSureNoOrphanTextInputLabels';
import makeSureFormGroupsHaveAnInput from '../normalizations/makeSureFormGroupsHaveAnInput';
import makeSureProcessesHaveValidChildren from '../normalizations/makeSureProcessesHaveValidChildren';
import makeSureProcessStepsHaveValidChildren from '../normalizations/makeSureProcessStepsHaveValidChildren';

const makeNormalizeNode = (editor) => {
  // eslint-disable-next-line no-param-reassign
  editor.originalNormalizeNode = editor.originalNormalizeNode || editor.normalizeNode;

  return (entry) => (
    makeSureChoiceGroupsHaveTwoOrMoreChoices(editor, entry) ||
    makeSureDocumentHasOneTitle(editor, entry) ||
    makeSureFormGroupsHaveALabelAndNoParagraphs(editor, entry) ||
    makeSureFormGroupsHaveAnInput(editor, entry) ||
    makeSureProcessesHaveValidChildren(editor, entry) ||
    makeSureProcessStepsHaveValidChildren(editor, entry) ||
    makeSureNoOrphanChoices(editor, entry) ||
    makeSureNoOrphanTextInputLabels(editor, entry) ||
    makeSureTitlesHaveNoFormatting(editor, entry) ||
    makeSureOrderedListsAreOrdered(editor, entry) ||
    makeSureLastElementIsParagraph(editor, entry) ||
    makeSureLastElementInContainerIsParagraph(editor, entry) ||
    removeEmptyMarksArray(editor, entry) ||
    editor.originalNormalizeNode(entry)
  );
};

export default makeNormalizeNode;
