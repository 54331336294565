/* eslint-disable no-extend-native */

/**
 * Array.prototype.flat()
 * version 0.0.0
 * Feature        Chrome  Firefox Internet Explorer Opera Safari  Edge
 * Basic support  69      62      (No)              56    12      (No)
 * -------------------------------------------------------------------------------
 */
if (!Array.prototype.flat) {
  Object.defineProperty(Array.prototype, 'flat', {
    configurable: true,
    writable: true,
    value (...args) {
      const result = [];
      const {forEach} = result;

      const flatDeep = (arr, depth) => {
        forEach.call(arr, (val) => {
          if (depth > 0 && Array.isArray(val)) {
            flatDeep(val, depth - 1);
          }
          else {
            result.push(val);
          }
        });
      };

      flatDeep(
        this,
        typeof args[0] === 'undefined'
          ? 1
          : Number(args[0]) || 0
      );
      return result;
    },
  });
}

/**
 * Array.prototype.flatMap()
 * version 0.0.0
 * Feature        Chrome  Firefox Internet Explorer   Opera Safari  Edge
 * Basic support  69      62      (No)                56    12      (No)
 * -------------------------------------------------------------------------------
 */
if (!Array.prototype.flatMap) {
  Object.defineProperty(Array.prototype, 'flatMap', {
    configurable: true,
    writable: true,
    value (...args) {
      return Array.prototype.map.apply(this, args).flat(1);
    },
  });
}
