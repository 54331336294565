import React from 'react';
import PropTypes from 'prop-types';

import tw, {styled, css} from 'twin.macro';
import {ReactEditor, useSlate} from 'slate-react';
import {useDocument} from 'containers/Document/providers/DocumentProvider';
import insertParagraph from '../utils/insertParagraph';

ParagraphSpacer.propTypes = {
  element: PropTypes.shape({}),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

function ParagraphSpacer (props) {
  const {
    element,
    children,
  } = props;

  const editor = useSlate();
  const {mode} = useDocument();
  const path = ReactEditor.findPath(editor, element);
  const addParagraphAbove = () => mode === 'edit' && insertParagraph(editor, path);

  return <>
    <Spacer
      className="element-spacer"
      onClick={addParagraphAbove}
      contentEditable={false} />
    <Wrapper>{children}</Wrapper>
  </>;
}

const Spacer = styled.div((props) => [
  tw`
    h-10
    my--5
    cursor-text
    select-none
  `,
  // Set to display: block using `&+ .element-spacer` above.
  css`
    display: none;
  `,
]);

const Wrapper = styled.div((props) => [
  css`
    &+ .element-spacer {
      display: block;
    }
    // Removes the top margin above the first form-group in a ProcessStep
    &:nth-child(2) .form-group {
      margin-top: -4px;
    }
  `,
]);

export default ParagraphSpacer;
