import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

function getFriendlyDate (unixTimestamp, showFromNowIfWithin5Hours = true) {
  const timestamp = parseInt(unixTimestamp, 10);
  const dayjsObj = dayjs(timestamp);

  if (
    showFromNowIfWithin5Hours &&
    timestamp > Date.now() - 5 * 60 * 60 * 1000 &&
    timestamp < Date.now() + 5 * 60 * 60 * 1000
  ) {
    return dayjsObj.fromNow().replace('an ', '1 ');
  }

  return `${dayjsObj.format('D MMM YYYY')}`; // at ${dayjsObj.format('H:mm')}`;
}

export default getFriendlyDate;
