import isEqual from 'lodash.isequal';
import {Editor, Transforms} from 'slate';
import getSelectionMeta from '../utils/getSelectionMeta';

const singleLineElements = [
  'title',
  'choice',
];

// When you triple click on the document title or choice, it will set the
// selection to cover both the element we're trying to select and the start of
// the next element (with offset 0). This leads other menus appearing, so let's
// fix that!
function onSelectOfTextElementAndStartOfNextLine (editor) {
  const selectionMeta = getSelectionMeta(editor);
  const {
    selectionAnchor,
    selectionFocus,
    selectionStart,
    selectionStartElement,
    selectionStartElementPath,
    selectionEnd,
  } = selectionMeta;

  if (
    !singleLineElements.includes(selectionStartElement.type) ||
    !isEqual(selectionEnd, Editor.after(editor, Editor.end(editor, selectionStartElementPath)))
  ) {
    return false;
  }

  if (isEqual(selectionStart, selectionAnchor)) {
    Transforms.setSelection(editor, {
      anchor: selectionAnchor,
      focus: Editor.edges(editor, selectionStartElementPath)[1],
    });
  }
  else {
    Transforms.setSelection(editor, {
      anchor: Editor.edges(editor, selectionStartElementPath)[1],
      focus: selectionFocus,
    });
  }

  return true;
}

export default onSelectOfTextElementAndStartOfNextLine;
