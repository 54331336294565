import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import tw, {styled, css} from 'twin.macro';

TextArea.propTypes = {
  blue: PropTypes.bool,
  borderless: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  icon: PropTypes.func,
  iconSize: PropTypes.number,
  placeholder: PropTypes.string,
  prefix: PropTypes.string,
  purple: PropTypes.bool,
  innerRef: PropTypes.object,
  sm: PropTypes.bool,
  tip: PropTypes.string,
};

TextArea.defaultProps = {
  blue: false,
  borderless: false,
  fullWidth: false,
  purple: false,
  disabled: false,
  icon: undefined,
  iconSize: undefined,
  placeholder: '',
  prefix: undefined,
  innerRef: undefined,
  sm: false,
  tip: undefined,
};

function TextArea (props) {
  const {
    blue,
    borderless,
    fullWidth,
    disabled,
    icon,
    iconSize,
    placeholder,
    prefix,
    purple,
    innerRef,
    sm,
    tip,
    ...rest
  } = props;

  const hasIcon = !!icon;

  const inputRef = innerRef || useRef(null);
  const focusInput = () => inputRef.current && inputRef.current.focus();

  return <>
    <TextAreaWrapper
      blue={blue}
      borderless={borderless}
      fullWidth={fullWidth}
      purple={purple}
      disabled={disabled}
      withIcon={hasIcon}
      sm={sm}>
      {prefix && <Prefix onClick={focusInput}>{prefix}</Prefix>}
      {hasIcon && icon({size: iconSize || 24})}
      <TextAreaInput
        blue={blue}
        borderless={borderless}
        withIcon={hasIcon}
        placeholder={placeholder}
        disabled={disabled}
        ref={inputRef}
        {...rest}
      />
    </TextAreaWrapper>
    {tip && <Tip>{tip}</Tip>}
  </>;
}

const TextAreaWrapper = styled.div((props) => [
  tw`
    flex
    border
    border-gray-200
    text-gray-400
    bg-white
    svg:self-center
    focus-within:border-blue-400
    focus-within:text-blue-400
  `,
  props.blue && tw`
    bg-blue-50
    border-0
    rounded-sm
    svg:text-blue-300
    focus-within:svg:text-blue-300
  `,
  props.purple && tw`
    bg-purple-100
    border-0
    rounded-sm
    svg:text-purple-400
    focus-within:svg:text-purple-400
  `,
  props.withIcon && tw`
    pl-2.5
  `,
  props.disabled && tw`
    border-gray-100
    bg-gray-50
  `,
  props.borderless && tw`
    border-none
    px-0
  `,
  props.fullWidth && tw`
    w-full
  `,
  css`
  `,
]);

const TextAreaInput = styled.textarea((props) => [
  tw`
    h-36
    px-3
    py-2.5
    outline-none
    bg-transparent
    w-full
    placeholder-gray-300
    text-gray-700
  `,
  props.blue && tw`
    text-blue-700
    placeholder-blue-400
    focus-within:placeholder-blue-300
  `,
  props.sm && tw`
    text-sm
    h-24
  `,
  props.withIcon && tw`
    ml-1.5
  `,
  props.disabled && tw`
    text-gray-500
  `,
]);

const Prefix = styled.span((props) => [
  tw`
    bg-blue-50
    pr-2
    mr-2
    text-gray-800
    h-full
    flex
    items-center
    pl-3
    select-none
  `,
]);

const Tip = styled.div((props) => [
  tw`
    text-sm
    mt-2
    text-gray-500
  `,
]);

TextArea.displayName = 'TextArea';

export default TextArea;
