import React, {useMemo, useReducer} from 'react';
import PropTypes from 'prop-types';
import helpBlocksReducer from '../reducers/helpBlocksReducer';

const HelpBlocksContext = React.createContext();

HelpBlocksProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  content: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

function HelpBlocksProvider (props) {
  const {
    children,
    content,
  } = props;

  const initialHelpBlocksState = useMemo(() => getInitialState({content}), []);
  const [helpBlocks, dispatchHelpBlock] = useReducer(helpBlocksReducer, initialHelpBlocksState);
  const openHelpBlock = (id) => dispatchHelpBlock({type: 'open', id});
  const closeHelpBlock = (id) => dispatchHelpBlock({type: 'close', id});

  const context = {
    helpBlocks,
    openHelpBlock,
    closeHelpBlock,
  };

  return <>
    <HelpBlocksContext.Provider value={context}>
      {children}
    </HelpBlocksContext.Provider>
  </>;
}

export function useHelpBlocks () {
  const context = React.useContext(HelpBlocksContext);

  if (context === undefined) {
    throw new Error('useHelpBlocks must be used within a HelpBlocksProvider');
  }

  return context;
}

function getInitialState ({content}) {
  const processNodes = content.filter((node) => node.type === 'process');
  const processStepNodes = processNodes.map((processNode) => processNode.children[1].children).flat();
  const topLevelHelpBlockNodes = content.filter((node) => node.type === 'help-block');
  const processStepHelpBlockNodes = processStepNodes.map((processStepNode) => processStepNode.children[1].children)
    .flat()
    .filter((processStepContentNode) => processStepContentNode.type === 'help-block');
  const helpBlockNodes = [...topLevelHelpBlockNodes, ...processStepHelpBlockNodes];

  const helpBlockNodesById = helpBlockNodes.reduce((acc, node) => ({
    ...acc,
    [node.id]: {
      ...node,
      open: false,
    },
  }), {});

  return helpBlockNodesById;
}

export default HelpBlocksProvider;
