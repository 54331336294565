import {Transforms} from 'slate';

// This normalization is needed due to an old bug whereby we created new
// elements with an empty marks array. This led to weird behavior where we
// couldn't make part of a paragraph bold.
// If we were to run a script that goes through all the documents and snapshots
// and removes any instances of `marks: []`, then this normalization wouldn't
// be needed.
function removeEmptyMarksArray (editor, [entryNode, entryNodePath]) {
  if (
    !entryNode.text ||
    !entryNode.marks ||
    entryNode.marks.length > 0
  ) {
    return false;
  }

  Transforms.setNodes(
    editor,
    {marks: undefined},
    {at: entryNodePath}
  );

  return true;
}

export default removeEmptyMarksArray;
