import {Editor, Path, Transforms} from 'slate';

function removeProcessStep (editor, location) {
  Transforms.removeNodes(
    editor,
    {
      at: location,
    }
  );

  const endOfPreviousStepTitle = Editor.end(editor, [...Path.previous(location), 0]);
  Transforms.setSelection(editor, {
    anchor: endOfPreviousStepTitle,
    focus: endOfPreviousStepTitle,
  });
}

export default removeProcessStep;
