import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled, css} from 'twin.macro';
import {AiOutlineCheck, AiOutlineMore} from 'react-icons/ai';
import {tailwind} from 'am-config';
import useHover from 'hooks/useHover';

const {theme: {colors}} = tailwind;

ProcessStep.propTypes = {
  done: PropTypes.bool,
  element: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  editing: PropTypes.bool,
  firstSelected: PropTypes.bool,
  lastSelected: PropTypes.bool,
  processSelected: PropTypes.bool,
  selected: PropTypes.bool,
  dragHandleProps: PropTypes.shape({}),
};

ProcessStep.defaultProps = {
  editing: false,
};

function ProcessStep (props) {
  const {
    done,
    children,
    editing,
    firstSelected,
    lastSelected,
    processSelected,
    selected,
    dragHandleProps,
  } = props;

  const [hoverRef, isHovered] = useHover();

  return (
    <ExpandedWrapper>
      <div id={props.element.id} className={'warning-locator'}>
        <Wrapper
          selected={selected}
          firstSelected={firstSelected}
          lastSelected={lastSelected}
          processSelected={processSelected}
          ref={hoverRef}>
          {done && (
            <ProcessStepIcon green>
              <AiOutlineCheck tw="inline-block mt--1" />
            </ProcessStepIcon>
          )}
          {!done && (
            <ProcessStepNumber
              contentEditable={false}
              firstSelected={firstSelected}
            />
          )}
          {editing && (
            <ReorderButton
              contentEditable={false}
              {...dragHandleProps}
              visible={isHovered}
              className="reorder-icon"> {/* className used to hide the icon when other steps are being dragged */}
              <FirstAiOutlineMore />
              <SecondAiOutlineMore />
            </ReorderButton>
          )}
          <ContentWrapper className="process-step-content-wrapper">{children}</ContentWrapper>
        </Wrapper>
      </div>
    </ExpandedWrapper>
  );
}

export const ProcessStepContext = React.createContext();

// The expanded wrapper is to allow us to use overflow-hidden (to stop vertical
// overflow) while also letting the reorder icon be displayed on the left hand
// side. For some reason, simply using overflow-y-hidden doesn't work (!)
const ExpandedWrapper = styled.div((props) => [
  tw`
    relative
    ml--10
    pl-10
    mr--4
    pr-4
  `,
  css`
    @media (max-width: 640px) {
      padding-left: 36px;
      padding-right: 12px;
    }
  `,
]);

const Wrapper = styled.div((props) => [
  tw`
    p-4
    pr-0
    pb-0
    bg-white
    border-gray-50
    relative
    z-10
  `,
  css`
    min-height: 64px;
    &::before {
      content: "";
      display: block;
      margin: -16px 0 16px -16px;
      height: 2px;
      background-color: ${colors.gray[50]};
    }
  `,
  (props.selected || props.processSelected) &&
    tw`
    select-none
    bg-blue-50
    z-20
  `,
  (props.selected || props.processSelected) &&
    css`
      &::before {
        background-color: ${colors.blue[100]};
      }
    `,
  props.selected &&
    css`
      border-left: 4px solid ${colors.blue[500]};
      border-right: 4px solid ${colors.blue[500]};
      margin-left: -4px;
      margin-right: -4px;
    `,
  props.firstSelected &&
    css`
      border-top: 4px solid ${colors.blue[500]};
      margin-top: -2px;
      padding-bottom: 2px;
      &::before {
        height: 0;
      }
    `,
  props.lastSelected &&
    css`
      border-bottom: 4px solid ${colors.blue[500]};
      margin-bottom: -4px;
      padding-bottom: 2px;
    `,
]);

const ContentWrapper = styled.div((props) => [
  tw`
  `,
  css`
    padding-left: 26px;
    @media (max-width: 640px) {
      padding-left: 0px;
      margin-left: -12px;
    }
  `,
]);

const ProcessStepIcon = styled.div((props) => [
  tw`
    text-center
    rounded-full
    mt-px
    text-white
    bg-gray-400
    font-bold
    select-none
    absolute
    top-4
    left-4
  `,
  props.green &&
    tw`
    bg-green-500
  `,
  css`
    margin-top: 4px;
    counter-increment: process-step;
    width: 26px;
    height: 26px;
    min-width: 26px;
    line-height: 26px;
    -webkit-print-color-adjust: exact; // Chrome, Safari
    color-adjust: exact; // Firefox

    /* Bugfix: This shouldn't be needed, but without it the numbering wouldFix 
       become broken in Safari after marking one of the steps as done. */
    &:after {
      content: counter(process-step);
      display: block;
      width: 0;
      height: 0;
      opacity: 0;
    }
  `,
]);

const ProcessStepNumber = styled(ProcessStepIcon)((props) => [
  tw`
    bg-gray-400
    text-xs
  `,
  css`
    line-height: 26px;
    &:before {
      content: counter(process-step);
    }
  `,
  props.firstSelected && css`
    margin-top: 2px;
  `,
]);

const ReorderButton = styled.div((props) => [
  tw`
    absolute
    ml--10
    mt--2.5
    text-lg
    text-gray-400
    pr-16
    py-4
  `,
  !props.visible && tw`
    hidden
  `,
]);

const FirstAiOutlineMore = styled(AiOutlineMore)((props) => [
  tw`
    absolute
    top-4
    left-0
  `,
]);

const SecondAiOutlineMore = styled(AiOutlineMore)((props) => [
  tw`
    absolute
    top-4
    left-1
  `,
]);

export default React.memo(ProcessStep);
