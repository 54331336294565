import React from 'react';
import PropTypes from 'prop-types';
import {useItem} from 'providers/ItemProvider';
import {ITEM_TYPE} from 'am-constants';
import DocumentViewerPageToolbar from './DocumentViewerPageToolbar';
import DocumentViewerPageActionBar from './DocumentViewerPageActionBar';

DocumentViewerPage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  printMode: PropTypes.bool.isRequired,
  workspaceUrlName: PropTypes.string,
};

function DocumentViewerPage (props) {
  const {
    children,
    printMode,
  } = props;

  const {item} = useItem();
  const {itemTypeId} = item;

  return (
    <>
      {!printMode && (
        <DocumentViewerPageToolbar />
      )}
      {children}
      {!printMode && itemTypeId !== ITEM_TYPE.DOCUMENT && (
        <DocumentViewerPageActionBar />
      )}
    </>
  );
}

export default DocumentViewerPage;
