import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
  PERMISSIONS, SUBSCRIPTION_TYPE,
} from 'am-constants';
import tw, {styled} from 'twin.macro'; // eslint-disable-line no-unused-vars
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';
import {getPermissionsForRoles} from 'am-utils';
import P from 'components/P';
import {useUser} from 'containers/UserApp/providers/UserProvider';
import getExampleWebhookCallContent from './utils/getExampleWebhookCallContent';
import ItemAutomationCreatorModal from './components/ItemAutomationCreatorModal';
import useCreateAutomationMutation from './hooks/useCreateAutomationMutation';

ItemAutomationCreator.propTypes = {
  inputs: PropTypes.array,
  isVisible: PropTypes.bool.isRequired,
  itemRef: PropTypes.string.isRequired,
  itemKey: PropTypes.string.isRequired,
  itemName: PropTypes.string.isRequired,
  itemTypeId: PropTypes.number,
  itemUrl: PropTypes.string.isRequired,
  loggedInUserRoleIds: PropTypes.array.isRequired,
  setIsVisible: PropTypes.func.isRequired,
};

ItemAutomationCreator.defaultProps = {
  redirectToWorkspace: false,
};

function ItemAutomationCreator (props) {
  const {
    inputs,
    isVisible,
    itemRef,
    itemKey,
    itemName,
    itemTypeId,
    itemUrl,
    loggedInUserRoleIds,
    setIsVisible,
  } = props;
  const [action, setAction] = useState();
  const [trigger, setTrigger] = useState();
  const [target, setTarget] = useState('');

  const {user} = useUser();

  const {mutateAsync: createAutomation, isError, reset} = useCreateAutomationMutation({});
  const {subscription} = useWorkspace();
  const hasTeamSubscription = subscription?.subscriptionTypeId === SUBSCRIPTION_TYPE.TEAM;

  const hasAutomationPermissions = hasTeamSubscription &&
  getPermissionsForRoles(loggedInUserRoleIds).includes(PERMISSIONS.MANAGE_ITEM_AUTOMATIONS);

  const closeModal = () => {
    setIsVisible(false);
    setAction();
    setTrigger();
    reset();
    setTarget();
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    try {
      await createAutomation({
        itemRef, itemKey, action, target, trigger,
      });
    }
    catch (e) {
      return;
    }

    closeModal();
  };

  const renderError = () => (<P bottom>Please check your connection and try again.</P>);

  const exampleWebhookCallContent = getExampleWebhookCallContent(
    inputs,
    itemName,
    itemUrl,
    user,
    trigger
  );

  return (
    <ItemAutomationCreatorModal
      action={action}
      closeModal={closeModal}
      exampleWebhookCallContent={exampleWebhookCallContent}
      trigger={trigger}
      hasAutomationPermissions={hasAutomationPermissions}
      isVisible={isVisible}
      itemTypeId={itemTypeId}
      onSubmit={onSubmit}
      renderError={isError ? renderError : null}
      setAction={setAction}
      setTrigger={setTrigger}
      setTarget={setTarget}
      target={target}
    />
  );
}

export default ItemAutomationCreator;
