import PropTypes from 'prop-types';
import {gql} from '@apollo/client';
import useLazyQuery from 'hooks/useLazyQuery';

const GET_TEAM_MEMBER_BY_EMAIL = gql`
  query useGetTeamMemberByEmailQuery($workspaceUrlName: String!, $emails: [String]!) {
    workspace(workspaceUrlName: $workspaceUrlName) {
      teamMembers(emails: $emails) {
        personId
        email
        name
      }
    }
  }
`;

useGetTeamMemberByEmailQuery.propTypes = {
  workspaceUrlName: PropTypes.string.isRequired,
  emails: PropTypes.array.isRequired,
};

function useGetTeamMemberByEmailQuery () {
  const [queryFunction, {
    loading, data, error,
  }] = useLazyQuery(GET_TEAM_MEMBER_BY_EMAIL);

  return [
    queryFunction,
    {
      loading,
      error,
      data,
    }];
}

export default useGetTeamMemberByEmailQuery;
