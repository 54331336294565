import React, {useEffect, useRef} from 'react';
import tw, {styled} from 'twin.macro';
import {Select as AntdSelect} from 'antd';
import PropTypes from 'prop-types';
const {Option} = AntdSelect;

Select.propTypes = {
  inline: PropTypes.bool,
  sm: PropTypes.bool,
  ariaLabelledBy: PropTypes.string,
  focusAfterMount: PropTypes.bool,
};

Select.defaultProps = {
  inline: false,
};

function Select (props) {
  const {
    inline, sm, ariaLabelledBy, focusAfterMount, ...rest
  } = props;

  const ref = useRef();

  useEffect(() => {
    if (focusAfterMount) {
      const input = ref?.current?.querySelector('.ant-select-selection-search-input');
      input?.focus({preventScroll: true});
    }
  }, [focusAfterMount]);

  const inlineProps = inline ? {
    className: 'ant-select-inline',
    open: true,
  } : {};

  return <>
    <Wrapper
      ref={ref}
      data-test-id='antd-select'
      aria-labelledby={ariaLabelledBy}
      className={sm ? 'ant-select-sm' : ''}>
      <StyledSelect
        notFoundContent={<NotFoundContent>No users found</NotFoundContent>}
        getPopupContainer={() => ref.current}
        {...rest}
        {...inlineProps}
      />
    </Wrapper>
  </>;
}

const Wrapper = styled.div((props) => [
  tw`
  `,
]);

const StyledSelect = styled(AntdSelect)((props) => [
  tw`
    w-full
    text-base
    text-gray-700
  `,
  props.blue && tw`
    bg-blue-50
    border-0
    rounded-sm
    svg:text-blue-300
    focus-within:svg:text-blue-300
  `,
  props.withIcon && tw`
    pl-2.5
  `,
  props.disabled && tw`
    border-gray-100
    bg-gray-50
  `,
  props.sm && tw`
    text-sm!
  `,
]);

const NotFoundContent = styled.div((props) => [
  tw`
    w-full
    h-24
    flex
    items-center
    justify-center
  `,
]);

export {Option};
export default Select;
