import {usePrintMode} from 'containers/PrintMode/providers/PrintModeProvider';
import React, {useContext} from 'react';
import {HelpBlockContext} from '../HelpBlock';
import HelpBlockContent from './HelpBlockContent';

function HelpBlockContentContainer (props) {
  const printMode = usePrintMode();
  const {editable, open} = useContext(HelpBlockContext);

  return (
    <HelpBlockContent
      editable={!!editable}
      open={open}
      printMode={!!printMode}
      {...props} />
  );
}

export default HelpBlockContentContainer;
