import React, {useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';
import getNameRefAndKeyParts from 'utils/getNameRefAndKeyParts';
import DocumentApp from 'containers/DocumentApp';
import Loader from 'components/Loader';
import {useItem} from 'providers/ItemProvider';
import {PERMISSIONS} from 'am-constants';
import {useTopbar} from 'providers/TopbarProvider';
import usePageQuery from './hooks/usePageQuery';

PageApp.propTypes = {
  'name--:ref-:key': PropTypes.string,
};

function PageApp (props) {
  const {'name--:ref-:key': nameRefAndKey} = props;
  const {
    urlName: workspaceUrlName,
  } = useWorkspace();

  const {ref, key} = useMemo(() => getNameRefAndKeyParts(nameRefAndKey), [nameRefAndKey]);

  const {data: item, error} = usePageQuery({
    workspaceUrlName,
    ref,
    key,
  });

  const {setItem} = useItem();

  useEffect(() => {
    setItem(item);
  }, [item]);

  const {workspacePermissions} = useWorkspace();
  const isWorkspaceMember = workspacePermissions.includes(PERMISSIONS.VIEW_WORKSPACE);

  const {hasPublicAncestor, public: isPublic} = item || {hasPublicAncestor: false, public: false};
  const isStandalonePublicPage = isPublic && !hasPublicAncestor;
  const {setHideSearch, setPrintModeSupport} = useTopbar();

  useEffect(() => {
    if (item) {
      setHideSearch(!isWorkspaceMember && isStandalonePublicPage);
      setPrintModeSupport(true);
    }
  }, [item]);

  if (error) {
    return <p>Could not load page</p>;
  }

  if (!item) {
    return <Loader />;
  }

  return <DocumentApp item={item} nameRefAndKey={nameRefAndKey} />;
}

export default PageApp;
