import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled, css} from 'twin.macro';
import GridRowDragHandle from './GridRowDragHandle';
import {useGrid} from './providers/GridProvider';
import GridRowBulkSelector from './GridRowBulkSelector';
import GridRowProvider from './providers/GridRowProvider';
import GridRowExpander from './GridRowExpander';
import GridFrozenWrapper from './GridFrozenWrapper';

GridRow.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  disabled: PropTypes.bool,
  dragHandleTouchRef: PropTypes.func,
  dragHandleHtml5Ref: PropTypes.func,
  expandable: PropTypes.bool,
  expanded: PropTypes.bool,
  groupHeader: PropTypes.bool,
  header: PropTypes.bool,
  indentLevel: PropTypes.number,
  isDragging: PropTypes.bool,
  onCollapse: PropTypes.func,
  onDragHover: PropTypes.func,
  onExpand: PropTypes.func,
  onDeselect: PropTypes.func,
  onSelect: PropTypes.func,
  selected: PropTypes.bool,
  subheader: PropTypes.bool,
  supportBulkSelect: PropTypes.bool,
  supportDrag: PropTypes.bool,
};

GridRow.defaultProps = {
  expandable: false,
  expanded: false,
  groupHeader: false,
  indentLevel: 0,
  isDragging: false,
  header: false,
  selected: false,
  subheader: false,
  supportBulkSelect: true,
  supportDrag: true,
};

function GridRow (props) {
  const {
    children,
    dragHandleTouchRef,
    dragHandleHtml5Ref,
    expandable,
    expanded,
    groupHeader,
    indentLevel,
    isDragging,
    header,
    onCollapse,
    onExpand,
    onDeselect,
    // We don't need to use onDragHover, but want to stop it being included in
    // ...rest which leads to a React error when applied to a <div>
    // eslint-disable-next-line
    onDragHover,
    onSelect,
    selected,
    subheader,
    supportDrag: rowSupportDrag,
    supportBulkSelect: rowSupportBulkSelect,
    ...rest
  } = props;

  const {
    supportDrag,
    supportBulkSelect,
    supportExpand,
    freezeFirstColumnGroup,
  } = useGrid();

  const onToggleExpand = () => (expanded ? onCollapse() : onExpand());
  const onToggleSelect = (val) => (val ? onSelect() : onDeselect());

  const showOptionsOnHover = !groupHeader && !header;

  const [firstChild, ...otherChildren] = React.Children.toArray(children);

  const inVirtualizedList = !groupHeader && !header;

  return (
    <>
      <GridRowProvider
        groupHeader={groupHeader}
        header={header}
        subheader={subheader}
        indentLevel={indentLevel}
      >
        <Wrapper
          className={`grid-row${
            showOptionsOnHover ? ' grid-row__hoverable' : ''
          }${isDragging ? ' grid-row__dragging' : ''}`}
          header={header}
          subheader={subheader}
          {...rest}
        >
          <GridFrozenWrapper
            inVirtualizedList={inVirtualizedList}
            isFrozen={freezeFirstColumnGroup}
            noShadow={groupHeader}
          >
            <div ref={dragHandleTouchRef} tw="h-full">
              <div ref={dragHandleHtml5Ref} tw="h-full">
                <div tw="flex h-full">
                  {supportDrag && (
                    <>
                      {rowSupportDrag && <GridRowDragHandle header={header} />}
                      {!rowSupportDrag && <GridRowDragHandleSpacer />}
                    </>
                  )}
                  {supportBulkSelect && (
                    <>
                      {rowSupportBulkSelect && (
                        <GridRowBulkSelector
                          selected={selected}
                          onChange={onToggleSelect}
                        />
                      )}
                      {!rowSupportBulkSelect && <GridRowBulkSelectorSpacer />}
                    </>
                  )}
                  {!supportBulkSelect && <GridRowBulkSelectorSpacer narrow />}
                  <RowContent>
                    <RowContentIdent indentLevel={indentLevel} />
                    {expandable && (
                      <GridRowExpander
                        expanded={expanded}
                        onClick={onToggleExpand}
                      />
                    )}
                    {!expandable && supportExpand && <GridRowExpanderSpacer />}

                    {React.cloneElement(firstChild, {
                      first: true,
                    })}
                  </RowContent>
                </div>
              </div>
            </div>
          </GridFrozenWrapper>
          {otherChildren}
          <GridRHSSpacer isFrozen={freezeFirstColumnGroup} />
        </Wrapper>
      </GridRowProvider>
    </>
  );
}

const Wrapper = styled.div((props) => [
  tw`
    relative
    h-10
    flex
    items-center
    border-b
    border-gray-50
    bg-gray-50
  `,
  props.header &&
    tw`
    border-b-2
  `,
  props.disabled &&
    tw`
    opacity-50
    cursor-not-allowed
  `,
]);

const RowContent = styled.div((props) => [
  tw`
    h-full
    flex
    gap-px
  `,
]);

const RowContentIdent = styled.div((props) => [
  tw`
    bg-gray-50
  `,
  css`
    width: ${props.indentLevel * 24}px;
    // Expanding 1px out of the top and bottom covers up the hovered row's
    // background color (when some cells are behind a frozen
    // GridRowExpanderSpacer).
    height: calc(100% + 2px);
    margin-top: -1px;
  `,
]);

const GridRowDragHandleSpacer = styled.div((props) => [
  tw`
    w-6
    bg-gray-50
  `,
  css`
    // Expanding 1px out of the top and bottom covers up the hovered row's
    // background color (when some cells are behind a frozen
    // GridRowExpanderSpacer).
    height: calc(100% + 2px);
    margin-top: -1px;
  `,
]);

const GridRowBulkSelectorSpacer = styled.div((props) => [
  tw`
    w-0
    ml--2
    sm:w-6
    bg-gray-50
  `,
  props.narrow &&
    tw`
    sm:w-4
  `,
  css`
    // Expanding 1px out of the top and bottom covers up the hovered row's
    // background color (when some cells are behind a frozen
    // GridRowExpanderSpacer).
    height: calc(100% + 2px);
    margin-top: -1px;
  `,
]);

const GridRowExpanderSpacer = styled.div((props) => [
  tw`
    w-6
    bg-gray-50
  `,
  css`
    // Only apply the fix if hovering. This is needed to avoid the fix taking
    // 1px out of the row above it when that row is being hovered over.
    .data-grid__not-dragging .grid-row__hoverable:hover && {
      // Expanding 1px out of the top and bottom covers up the hovered row's
      // background color (when some cells are behind a frozen
      // GridRowExpanderSpacer).
      height: calc(100% + 2px);
      margin-top: -1px;
    }
  `,
]);

// GridRHSSpacer adds some margin to the right of the grid the padding on a
// container element won't work if the grid is wider than the container and
// the container is set with `overflow-x: scroll`.
const GridRHSSpacer = styled.div((props) => [
  tw`
    w-4
  `,
]);

export default React.memo(GridRow);
