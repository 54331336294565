import isEqual from 'lodash.isequal';
import {Editor} from 'slate';

function onDeleteAtEndOfCollapsibleContent (editor, event, selectionMeta) {
  const {
    isSelectionAtEndOfElement,
    selectionStart,
    selectionStartElementPath,
  } = selectionMeta;

  if (
    event.key !== 'Delete' ||
    !selectionStart ||
    !isSelectionAtEndOfElement
  ) {
    return false;
  }

  const [parentElement, parentElementPath] = Editor.parent(editor, selectionStartElementPath) || [undefined];

  if (
    !parentElement ||
    ![
      'help-block-content',
      'process-step-content',
    ].includes(parentElement.type)
  ) {
    return false;
  }

  const nextPoint = Editor.after(editor, selectionStart);

  const isNextPointOutsideOfCollapsibleElement = !isEqual(nextPoint.path.slice(0, parentElementPath.length - 1), parentElementPath.slice(0, -1));
  if (!isNextPointOutsideOfCollapsibleElement) {
    return false;
  }

  event.preventDefault();
  return true;
}

export default onDeleteAtEndOfCollapsibleContent;
