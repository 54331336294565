import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled} from 'twin.macro';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';
import Link from 'components/Link';
import getFriendlyDate from 'utils/getFriendlyDate';

TaskInProgressCellPopover.propTypes = {
  itemUrlName: PropTypes.string.isRequired,
  numActiveResponses: PropTypes.number.isRequired,
  numSubmittedResponses: PropTypes.number.isRequired,
  mostRecentSubmissionAt: PropTypes.string,
};

TaskInProgressCellPopover.defaultProps = {
};

function TaskInProgressCellPopover (props) {
  const {
    itemUrlName,
    numActiveResponses,
    numSubmittedResponses,
    mostRecentSubmissionAt,
  } = props;

  const {urlName: workspaceUrlName} = useWorkspace();
  const dataUrl = `/${workspaceUrlName}/${itemUrlName}/data`;

  return (
    <Wrapper wide={!!mostRecentSubmissionAt}>
      {numActiveResponses} in progress<br />
      {numSubmittedResponses} completed<br />
      {mostRecentSubmissionAt && <>
        Last completed on {getFriendlyDate(mostRecentSubmissionAt, false)}
      </>}
      <FooterLink>
        <Link to={dataUrl}>
          View submissions
        </Link>
      </FooterLink>
    </Wrapper>
  );
}

const Wrapper = styled.div((props) => [
  tw`
    w-40
  `,
  props.wide && tw`
    w-56
  `,
]);

const FooterLink = styled.div((props) => [
  tw`
    text-center
    m--4
    mt-4
    p-4
    pt-4
    bg-gray-50
  `,
]);

export default TaskInProgressCellPopover;
