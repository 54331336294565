import {Editor} from 'slate';

function onDeleteAtEndOfChoiceGroup (editor, event, selectionMeta) {
  const {
    isSelectionAtEndOfElement,
    selectionStart,
    selectionStartElement,
    selectionStartElementPath,
  } = selectionMeta;

  if (
    event.key !== 'Delete' ||
    !selectionStart ||
    selectionStartElement.type !== 'choice' ||
    !isSelectionAtEndOfElement ||
    selectionStartElementPath.slice(-1)[0] !== Editor.parent(editor, selectionStartElementPath)[0].children.length - 1
  ) {
    return false;
  }

  event.preventDefault();

  return true;
}

export default onDeleteAtEndOfChoiceGroup;
