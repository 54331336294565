import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {
  AiOutlineCaretDown,
} from 'react-icons/ai';
import Button from 'components/Button';
import tw, {styled, css} from 'twin.macro';
import {Link} from '@reach/router';
import useOnClickOutside from 'hooks/useOnClickOutside';

DropdownButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  disabled: PropTypes.bool,
  doNotWrapInButton: PropTypes.bool,
  to: PropTypes.string,
  menu: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({
    disabled: PropTypes.bool,
    label: PropTypes.string.isRequired,
    to: PropTypes.string,
    onClick: PropTypes.func,
    newTab: PropTypes.bool,
  })).isRequired),
  style: PropTypes.shape({}),
  direction: PropTypes.oneOf(['up', 'down']),
  xDirection: PropTypes.oneOf(['left', 'right']),
  xOffset: PropTypes.number,
  sm: PropTypes.bool,
};

DropdownButton.defaultProps = {
  sm: false,
  doNotWrapInButton: false,
  disabled: false,
  style: {},
  direction: 'down',
  xDirection: 'left',
  xOffset: 0,
};

function DropdownButton (props) {
  const {
    children,
    to,
    menu,
    style,
    direction,
    xDirection,
    xOffset,
    sm,
    disabled: dropdownButtonDisabled,
    doNotWrapInButton,
    ...rest
  } = props;

  const ref = useRef();
  const [visible, setVisible] = useState(false);
  useOnClickOutside(ref, () => setVisible(false));

  const renderMenu = () => (
    <MenuWrapper direction={direction} xDirection={xDirection} xOffset={xOffset}>
      <Menu onMouseLeave={() => setVisible(false)}>
        {menu.map((menuGroup, index) => <MenuGroup key={index}>
          {menuGroup.map(({
            disabled,
            onClick: itemOnClick,
            to: itemTo,
            label,
            newTab,
            className,
          }) => {
            const linkProps = {};
            const doNotUseReachRouterLink = !itemTo || newTab || (itemTo && itemTo.substr(0, 4) === 'http');
            const isMenuItemDisabled = disabled || dropdownButtonDisabled;

            if (!isMenuItemDisabled) {
              if (!doNotUseReachRouterLink) {
                linkProps.as = Link;
                linkProps.to = itemTo;
              }
              if (doNotUseReachRouterLink) {
                linkProps.as = UnstyledA;

                if (itemTo) {
                  linkProps.href = itemTo.substr(0, 4) === 'http' ? itemTo : `${window.location.pathname}/${itemTo}`;
                }

                if (newTab) {
                  linkProps.target = '_blank';
                }
              }

              if (className) {
                linkProps.className = className;
              }
            }

            return <MenuItem
              disabled={isMenuItemDisabled}
              onMouseDown={(event) => {
                event.stopPropagation();

                if (!isMenuItemDisabled && itemOnClick !== undefined) {
                  itemOnClick();
                }

                return false;
              }}
              key={label}
              // Converting sm from a boolean to an int prevents an error in console.
              // This is due to how props are passed to their underlying attributes using
              // styled components. For more information see here:
              // https://maximeblanc.fr/blog/how-to-fix-the-received-true-for-a-non-boolean-attribute-error
              sm={+sm}
              {...rest}
              {...linkProps}
            >
              {label}
            </MenuItem>;
          })}
        </MenuGroup>)}
      </Menu>
    </MenuWrapper>
  );

  return <div tw="flex relative" ref={ref}>
    {children && !doNotWrapInButton && to && <Link to={to} tw="flex">
      {!menu && <Button style={{...style}} unfocus={visible} sm={sm} disabled={dropdownButtonDisabled} {...rest}>{children}</Button>}
      {menu && <Button style={{borderTopRightRadius: 0, borderBottomRightRadius: 0, ...style}} unfocus={visible} sm={sm} disabled={dropdownButtonDisabled} {...rest} tw="focus:z-10">{children}</Button>}
    </Link>}
    {children && !doNotWrapInButton && !to && <Button style={{...style}} disabled={dropdownButtonDisabled} {...rest} unfocus={visible} sm={sm} onMouseDown={(event) => {
      event.preventDefault();
      event.stopPropagation();
      setVisible(!visible);
      return false;
    }}>{children}{visible && renderMenu()}</Button>}
    {doNotWrapInButton && <span onMouseDown={(event) => {
      event.preventDefault();
      event.stopPropagation();
      setVisible(!visible);
      return false;
    }}>{children}{visible && renderMenu()}</span>}
    {menu && to && <Button data-test-id="create-item-menu" tw="w-auto! px-2" unfocus={visible} disabled={dropdownButtonDisabled} style={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0, ...style}} sm={sm} {...rest} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Tooltip on bottom" onMouseDown={(event) => {
      event.preventDefault();
      event.stopPropagation();
      setVisible(!visible);
      return false;
    }}><AiOutlineCaretDown size={14} />{visible && renderMenu()}</Button>}
  </div>;
}

const MenuWrapper = styled.ul((props) => [
  tw`
    absolute
    z-50
    text-left
  `,
  props.direction === 'up' && tw`
    bottom-10
    mb-1
  `,
  props.direction !== 'up' && tw`
    top-full
    bottom-0
  `,
  props.xDirection === 'left' && css`
    right: ${props.xOffset}px;
  `,
  props.xDirection === 'right' && css`
    left: ${props.xOffset}px;
  `,
  css`
    min-width: 128px;
  `,
]);

const Menu = styled.div((props) => [
  tw`
    relative
    whitespace-nowrap
    mt-px
    shadow-lg
    z-20
  `,
]);

const MenuGroup = styled.div((props) => [
  tw`
    mb--px
  `,
  props.primary || props.green && tw`
    mb-px
  `,
]);

const MenuItem = styled.div.withConfig({
  shouldForwardProp: (prop) => !['lg', 'primary'].includes(prop),
})((props) => [
  tw`
    block
    font-normal!
    rounded-none!
    py-2
    pr-8!
    pl-4
    text-sm
    border-l
    border-r
    border-blue-200
    bg-white
    cursor-pointer
    text-blue-400
    first:pt-3
    first:border-t
    last:pb-3
    last:border-b
  `,
  !props.disabled &&
    tw`
    hover:bg-blue-50
  `,
  props.disabled &&
    tw`
    cursor-not-allowed
  `,
  props.primary &&
    tw`
    border-none
    bg-blue-600
  `,
  props.primary &&
    !props.disabled &&
    tw`
    text-white
    hover:bg-blue-400
    hover:text-white
  `,
  props.primary &&
    props.disabled &&
    tw`
    text-blue-200
    hover:bg-blue-600
    hover:text-blue-200
  `,
  !props.primary &&
    props.disabled &&
    tw`
    text-gray-200
    hover:text-gray-200
  `,
  props.gray &&
    tw`
    border-gray-200
    text-gray-700
    hover:text-gray-700
    hover:bg-gray-50
  `,
  props.sm &&
    tw`
    py-1.5
    pr-6
    pl-3
    text-left
    text-xs
    first:pt-2
    last:pb-2
  `,
]);

const UnstyledA = styled.a((props) => []);

export default DropdownButton;
