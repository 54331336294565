import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled} from 'twin.macro';
import {
  useFocused,
  useSelected,
} from 'slate-react';
import {useDocument} from 'containers/Document/providers/DocumentProvider';

FormGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

FormGroup.defaultProps = {
};

function FormGroup (props) {
  const {
    children,
  } = props;

  const focused = useFocused();
  const selected = useSelected();
  const {mode} = useDocument();
  const isFocused = focused && selected && mode === 'edit';

  return (
    <Wrapper
      // className used by ParagraphSpacer to remove the margin above the first
      // form-group in a ProcessStep.
      className="form-group"
      focused={isFocused}>
      {children}
    </Wrapper>
  );
}

const Wrapper = styled.div((props) => [
  tw`
    my-4
    py-1
    clear-both
  `,
  props.focused && tw`
    mx--1.5
    px-1.5
  `,
]);

export default FormGroup;
