import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import tw, {styled, css} from 'twin.macro';
import Input from 'components/Input';
import {AiOutlinePlus} from 'react-icons/ai';
import colors from 'utils/colors';
import {Popover} from 'antd';
import {ITEM_TYPE} from 'am-constants';
import useFocus from 'hooks/useFocus';
import useOnClickOutside from 'hooks/useOnClickOutside';
import useKeyPress from 'hooks/useKeyPress';
import GridCell from './GridCell';
import GridCellGroup from './GridCellGroup';
import DragAndDroppableGridRow from './DragAndDroppableGridRow';

DataGridRowAdder.propTypes = {
  columnGroups: PropTypes.arrayOf(PropTypes.shape({
    columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    frozen: PropTypes.bool,
  })).isRequired,
  indentLevel: PropTypes.number,
  index: PropTypes.number,
  folderId: PropTypes.number,
  sectionId: PropTypes.number,
  onAddItem: PropTypes.func.isRequired,
  onDragStart: PropTypes.func,
  onDragHover: PropTypes.func,
  onDragEnd: PropTypes.func,
  onDrop: PropTypes.func,
  disabled: PropTypes.bool,
};

DataGridRowAdder.defaultProps = {
  index: -1,
  indentLevel: 0,
  disabled: false,
};

const defaultEmojis = {
  [ITEM_TYPE.DOCUMENT]: '📄',
  [ITEM_TYPE.FOLDER]: '📁',
  [ITEM_TYPE.SECTION]: '🏷️',
  [ITEM_TYPE.LINK]: '🔗',
};

const itemTypeNames = {
  [ITEM_TYPE.DOCUMENT]: 'page',
  [ITEM_TYPE.FOLDER]: 'folder',
  [ITEM_TYPE.SECTION]: 'section',
  [ITEM_TYPE.LINK]: 'link',
};

// TODO: This component needs a big refactor at some point. Not nice and clean
// and reusable like the other DataGrid components!
function DataGridRowAdder (props) {
  const {
    columnGroups,
    indentLevel,
    index,
    folderId,
    sectionId,
    onAddItem,
    onDragStart,
    onDragHover,
    onDragEnd,
    onDrop,
    disabled,
  } = props;

  const [firstColumnGroup] = columnGroups;

  const [value, setValue] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const showPopoverRef = useRef();
  const valueRef = useRef();
  const [itemTypeId, setItemTypeId] = useState(ITEM_TYPE.DOCUMENT);
  const [inputRef, setInputFocus] = useFocus();
  const popoverRef = useRef();

  useOnClickOutside(popoverRef, () => setShowPopover(false));

  const escapePressed = useKeyPress('Escape');
  if (escapePressed) {
    if (showPopover) {
      setShowPopover(false);
    }

    if (showForm && value.trim() === '') {
      setShowForm(false);
    }
  }

  useEffect(() => {
    showPopoverRef.current = showPopover;
  }, [showPopover]);

  useEffect(() => {
    valueRef.current = value;
  }, [value]);

  const handleInputBlur = useCallback(() => {
    if (
      showPopoverRef.current ||
      valueRef.current.trim().length > 0 ||
      document.activeElement === inputRef.current
    ) {
      return;
    }

    setShowForm(false);
  }, []);

  const emoji = defaultEmojis[itemTypeId];
  const itemTypeName = itemTypeNames[itemTypeId];

  const handleSubmit = (e) => {
    e.preventDefault();

    if (value.trim() === '') {
      setShowForm(false);
      return;
    }

    const parentId = itemTypeId === ITEM_TYPE.SECTION ? folderId : (sectionId || folderId);

    onAddItem({
      name: value.trim(),
      itemTypeId,
      parentId,
      emoji: itemTypeId === ITEM_TYPE.SECTION ? undefined : emoji,
    });

    setShowPopover(false);
    setValue('');
  };

  return (
    <DragAndDroppableGridRow
      expandable={false}
      indentLevel={indentLevel}
      onDragStart={onDragStart}
      onDragHover={onDragHover}
      onDragEnd={onDragEnd}
      onDrop={onDrop}
      supportBulkSelect={false}
      supportDrag={false}
      disabled={disabled}
      index={index}
      item={{
        adder: true,
        folderId,
        sectionId,
      }}
      itemId={`${folderId || sectionId}-adder`}>
      <GridCellGroup
        first
        frozen={firstColumnGroup.frozen}>
        {/*
        The GridCells are rendered as a simple way of sizing the GridCellGroup
        correctly.
        */}
        <Hidden>
          {firstColumnGroup.columns && firstColumnGroup.columns.map((column) => (
            <GridCell
              key={column.label}
              first
              width={column.width}>
            </GridCell>
          ))}
        </Hidden>
        {!showForm &&
          <AddButton
            className="add-button"
            onClick={() => {
              setShowForm(true);
              setTimeout(() => setShowPopover(true), 500);
            }}>
            <AddIcon><AiOutlinePlus /></AddIcon>
          </AddButton>
        }
        {showForm && (
          <InputWrapper>
            <Popover
              content={
                <PopoverContent ref={popoverRef}>
                  <ItemTypeSelector>
                    <ItemTypeButton
                      onClick={() => {
                        setItemTypeId(ITEM_TYPE.DOCUMENT);
                        setShowPopover(false);
                        setInputFocus();
                      }}
                      selected={itemTypeId === ITEM_TYPE.DOCUMENT}>
                      <ButtonEmoji>📄</ButtonEmoji>
                      Page
                    </ItemTypeButton>
                    <ItemTypeButton
                      onClick={() => {
                        setItemTypeId(ITEM_TYPE.FOLDER);
                        setShowPopover(false);
                        setInputFocus();
                      }}
                      selected={itemTypeId === ITEM_TYPE.FOLDER}>
                      <ButtonEmoji>📁</ButtonEmoji>
                      Folder
                    </ItemTypeButton>
                    <ItemTypeButton
                      onClick={() => {
                        setItemTypeId(ITEM_TYPE.SECTION);
                        setShowPopover(false);
                        setInputFocus();
                      }}
                      selected={itemTypeId === ITEM_TYPE.SECTION}>
                      <ButtonEmoji>🏷️</ButtonEmoji>
                      Section
                    </ItemTypeButton>
                    {/* <ItemTypeButton
                      onClick={() => {
                        setItemTypeId(ITEM_TYPE.LINK);
                        setShowPopover(false);
                        setInputFocus();
                      }}
                      selected={itemTypeId === ITEM_TYPE.LINK}>
                      <ButtonEmoji>🔗</ButtonEmoji>
                      Link
                    </ItemTypeButton> */}
                  </ItemTypeSelector>
                </PopoverContent>
              }
              open={showPopover}
              placement="bottom"
              trigger="click"
              overlayInnerStyle={{marginTop: -10}}
              autoAdjustOverflow={false}>
              <Emoji
                onClick={() => setShowPopover(true)}
                id='test'>
                {emoji}
              </Emoji>
            </Popover>
            <form onSubmit={handleSubmit}>
              <Input
                fullWidth
                fullHeight
                tw="text-xs"
                borderless
                placeholder={`Give this ${itemTypeName} a name`}
                autoFocus
                innerRef={inputRef}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                onBlur={() => setTimeout(handleInputBlur, 100)}
                maxLength={100}
              />
            </form>
          </InputWrapper>
        )}
      </GridCellGroup>
    </DragAndDroppableGridRow>
  );
}

const Hidden = styled.div((props) => [
  tw`
    opacity-0
    h-0
    overflow-hidden
    flex
    gap-px
  `,
]);

const AddButton = styled.div((props) => [
  tw`
    absolute
    w-full
    h-full
    bg-gray-50
    flex
    items-center
    justify-center
    text-xs
    cursor-pointer
    hover:bg-gray-100
    hover:bg-opacity-50
  `,
]);

const AddIcon = styled.div((props) => [
  tw`
    w-3
    h-3
    mt--2
    rounded-full
    flex
    items-center
    justify-center
    transition-all
  `,
  css`
    .add-button:hover && {
      width: 20px;
      height: 20px;
      /* background: ${colors.gray[100]}; */
    }
  `,
]);

const PopoverContent = styled.div((props) => [
  tw`
    text-xs
    mx--4
    my--3
    p-4
  `,
]);

const ItemTypeSelector = styled.div((props) => [
  tw`
    w-32
    flex
    flex-col
    p-0
    gap-2
  `,
]);

const ItemTypeButton = styled.button((props) => [
  tw`
    py-2
    px-2.5
    rounded-sm
    flex
    gap-1.5
    items-center
    bg-white
    border
    border-blue-200
    hover:bg-blue-50
    text-gray-800
  `,
  props.selected && tw`
    bg-blue-200
    hover:bg-blue-200
  `,
]);

const ButtonEmoji = styled.div((props) => [
  tw`
    flex-none
    w-4
    h-full
    flex
    text-sm
    items-center
    justify-center
    cursor-pointer
  `,
]);

const InputWrapper = styled.div((props) => [
  tw`
    w-full
    h-full
    absolute
    bg-white
    px-2.5
    text-xs
    flex
    gap-1.5
  `,
]);

const Emoji = styled.div((props) => [
  tw`
    flex-none
    w-5
    h-full
    flex
    text-base
    items-center
    justify-center
    cursor-pointer
  `,
]);

export default DataGridRowAdder;
