import React from 'react';
import PropTypes from 'prop-types';
import {Redirect} from '@reach/router';
import DocumentEditorPageToolbar from './DocumentEditorPageToolbar';

DocumentEditorPage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onPublish: PropTypes.func.isRequired,
  printMode: PropTypes.bool.isRequired,
  published: PropTypes.bool.isRequired,
  publishing: PropTypes.bool.isRequired,
  saveError: PropTypes.shape({}),
};

function DocumentEditorPage (props) {
  const {
    children,
    onPublish,
    printMode,
    published,
    publishing,
    saveError,
  } = props;

  if (published) {
    return <Redirect to={'..'} noThrow={true} />;
  }

  return (
    <>
      {!printMode && (
        <DocumentEditorPageToolbar
          onPublish={onPublish}
          publishing={publishing}
          saveError={saveError}
        />
      )}
      {children}
    </>
  );
}

export default DocumentEditorPage;
