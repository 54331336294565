export const CONTENT_ELEMENT_TYPES = [
  'help-block-content',
  'process-step-content',
];

export const FORM_GROUP_INPUT_ELEMENT_TYPES = [
  'text-field',
  'choice-group',
];

export const MAX_RATING_OPTIONS = 10;

// From https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values#modifier_keys
export const MODIFIER_KEYS = [
  'Alt',
  'AltGraph',
  'CapsLock',
  'Control',
  'Fn',
  'FnLock',
  'Hyper',
  'Meta',
  'NumLock',
  'ScrollLock',
  'Shift',
  'Super',
  'Symbol',
  'SymbolLock',
];

export const SELECTABLE_ELEMENT_TYPES = [
  'help-block',
  'process',
  'process-step',
];

export const SINGLE_LINE_ELEMENT_TYPES = [
  'choice',
  'help-block-title',
  'label',
  'process-step-title',
  'process-title',
];

export const SINGLE_LINE_ELEMENT_GROUP_TYPES = [
  'choice',
  'process-step-title',
];

export const TITLE_ELEMENT_TYPES = [
  'help-block-title',
  'process-step-title',
  'process-title',
];

export const VOID_ELEMENT_TYPES = [
  'image',
  'video',
];

export const INLINE_ELEMENT_TYPES = [
  'link',
];
