import React from 'react';
import {Spin} from 'antd';
import tw, {styled, css} from 'twin.macro';

function Loader (props) {
  return <LoaderContainer>
    <Spin />
  </LoaderContainer>;
}

const LoaderContainer = styled.div((props) => [
  tw`
    flex
    w-screen
    h-screen
    items-center
    justify-center
    text-center
    absolute
    top-0
  `,
  css`
    z-index: -1
  `,
]);

export default Loader;
