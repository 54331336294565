import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled} from 'twin.macro';
import P from 'components/P';
import {ButtonBar} from 'components/Button';

Option.propTypes = {
  icon: PropTypes.node,
  description: PropTypes.string.isRequired,
  primaryAction: PropTypes.node.isRequired,
  secondaryAction: PropTypes.node.isRequired,
};

function Option (props) {
  const {
    description,
    primaryAction,
    secondaryAction,
  } = props;

  return (
    <Wrapper data-test-id={`${description}`}>
      <div tw='m-0'><P tw='mb-0 '>{description}</P></div>
      <ButtonBar tw="flex-none">{secondaryAction}{primaryAction}</ButtonBar>
    </Wrapper>
  );
}

const Wrapper = styled.div((props) => [
  tw`
    flex
    items-center
    justify-between
    p-4
    bg-white
    border
    border-blue-50
    `,
]);

export default Option;
