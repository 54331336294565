import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled, css} from 'twin.macro';
import {AiOutlineLoading3Quarters} from 'react-icons/ai';
import colors from 'utils/colors';
import {useGridRow} from './providers/GridRowProvider';
import GridCellClickHandler from './GridCellClickHandler';

GridCell.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  emoji: PropTypes.string,
  first: PropTypes.bool,
  item: PropTypes.shape({}),
  linkTo: PropTypes.string,
  onClick: PropTypes.func,
  saving: PropTypes.bool,
  width: PropTypes.oneOf(['sm', 'base', 'lg', 'xl', '2xl']),
};

GridCell.defaultProps = {
  first: false,
  saving: false,
  width: 'base',
};

function GridCell (props) {
  const {
    children,
    emoji,
    first,
    item,
    linkTo,
    onClick,
    saving,
    width,
  } = props;

  const {
    groupHeader,
    header,
    subheader,
    indentLevel,
  } = useGridRow();

  return (
    <GridCellClickHandler
      item={item}
      linkTo={linkTo}
      onClick={onClick}>
      <Wrapper
        groupHeader={groupHeader}
        header={header}
        indentLevel={first ? indentLevel : 0}
        subheader={subheader}
        width={width}>
        {saving && <Icon>
          <AiOutlineLoading3Quarters tw="text-gray-300 animate-spin" />
        </Icon>}
        {!saving && emoji && <Icon>{emoji}</Icon>}
        {children}
      </Wrapper>
    </GridCellClickHandler>
  );
}

const Wrapper = styled.div((props) => [
  tw`
    h-full
    flex-none
    px-3
    flex
    items-center
    text-xs
    bg-white
  `,
  css`
    width: calc(128px - ${props.indentLevel * 24}px);

    @media (min-width: 640px) {
      width: calc(144px - ${props.indentLevel * 24}px);
    }

    .data-grid__not-dragging .grid-row__hoverable:hover && {
      background-color: ${colors.blue[50]};
      border-top: 1px solid ${colors.blue[50]};
      border-bottom: 1px solid ${colors.blue[50]};
      margin-top: -1px;
      height: 41px;
    }
    .grid-row__dragging && {
      background-color: ${colors.blue[50]};
      border-top: 1px solid ${colors.blue[50]};
      border-bottom: 1px solid ${colors.blue[50]};
      margin-top: -1px;
      height: 41px;
    }
    .prevent-hover-style && {
      border: none !important;
      background-color: white !important;
      height: 100% !important;
      margin: 0 !important;
    }
  `,
  // The cells in the group header are used for spacing purposes only, and
  // aren't actually visible.
  props.groupHeader &&
    tw`
    opacity-0
  `,
  props.header &&
    tw`
    font-bold
    text-gray-700
  `,
  props.subheader &&
    tw`
    text-gray-500
    text-2xs
    font-medium
    pb-1
    pt-5
    pl-3
  `,
  props.width === 'sm' &&
    css`
      width: calc(80px - ${props.indentLevel * 24}px);

      @media (min-width: 640px) {
        width: calc(96px - ${props.indentLevel * 24}px);
      }
    `,
  props.width === 'lg' &&
    css`
      width: calc(160px - ${props.indentLevel * 24}px);

      @media (min-width: 640px) {
        width: calc(192px - ${props.indentLevel * 24}px);
      }
    `,
  props.width === 'xl' &&
    css`
      width: calc(192px - ${props.indentLevel * 24}px);

      @media (min-width: 640px) {
        width: calc(256px - ${props.indentLevel * 24}px);
      }
    `,
  props.width === '2xl' &&
    css`
      width: calc(192px - ${props.indentLevel * 24}px);
      @media (min-width: 640px) {
        width: calc(394px - ${props.indentLevel * 24}px);
      }
    `,
]);

const Icon = styled.div((props) => [
  tw`
    mr-2
    w-4
    flex-none
    select-none
    flex
    justify-center
    items-center
    text-base
  `,
]);

// We can't use `truncate` on a component with `display: flex`. Hence this
// subcomponent.
// const Text = styled.div((props) => [
//   tw`
//     overflow-hidden
//     truncate
//   `,
// ]);

export default GridCell;
