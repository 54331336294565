import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';

ProcessStepNextButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  openNext: PropTypes.func.isRequired,
};

function ProcessStepNextButton (props) {
  const {openNext} = props;

  const markAsCompleted = () => {
    openNext();
  };

  return <Button primary {...props} onClick={markAsCompleted} />;
}

export default ProcessStepNextButton;
