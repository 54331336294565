import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled, css} from 'twin.macro';
import {AiOutlineRight} from 'react-icons/ai';

GridRowExpander.propTypes = {
  expanded: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

GridRowExpander.defaultProps = {
};

function GridRowExpander (props) {
  const {
    expanded,
    onClick,
  } = props;

  return (
    <Wrapper onClick={onClick}>
      <Icon expanded={expanded}>
        <AiOutlineRight />
      </Icon>
    </Wrapper>
  );
}

const Wrapper = styled.div((props) => [
  tw`
    py-2.5
    w-6
    select-none
    bg-gray-50
    flex
    justify-center
    items-center
    cursor-pointer
    text-2xs
  `,
  css`
    // Expanding 1px out of the top and bottom covers up the hovered row's
    // background color (when some cells are behind a frozen
    // GridRowExpanderSpacer).
    height: calc(100% + 2px);
    margin-top: -1px;
  `,
]);

const Icon = styled.div((props) => [
  css`
    transition: transform 0.15s;
  `,
  props.expanded && tw`
    rotate-90
  `,
]);

export default GridRowExpander;
