import React, {useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import {Redirect} from '@reach/router';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';
import useFolderQuery from 'hooks/useFolderQuery';
import useCreateItemMutation from 'hooks/useCreateItemMutation';
import {ITEM_TYPE} from 'am-constants';
import getNameRefAndKeyParts from 'utils/getNameRefAndKeyParts';

DocumentCreator.propTypes = {
  workspaceUrlName: PropTypes.string,
  parentItemRef: PropTypes.string,
  parentItemKey: PropTypes.string,
  'name--:ref-:key': PropTypes.string,
};

function DocumentCreator (props) {
  const {'name--:ref-:key': nameRefAndKey} = props;
  const {urlName: workspaceUrlName} = useWorkspace();
  const {ref, key} = useMemo(
    () => getNameRefAndKeyParts(nameRefAndKey),
    [nameRefAndKey]
  );

  const {data: item, isError} = useFolderQuery({
    workspaceUrlName,
    ref,
    key,
  });

  const {
    mutate: createItem,
    data,
    isError: isCreateError,
  } = useCreateItemMutation({});

  useEffect(() => {
    if (item) {
      createItem({
        name: '',
        itemTypeId: ITEM_TYPE.DOCUMENT,
        parentFolderId: item.id,
        parentId: item.id,
        parentItemRef: item.ref,
        parentItemKey: item.key,
      });
    }
  }, [item]);

  if (isError || isCreateError) {
    return <p>Could not create page</p>;
  }

  if (!data) {
    return '';
  }

  const newItem = data.itemCreate;

  return <Redirect to={`/${workspaceUrlName}/Untitled--${newItem.ref}-${newItem.key}/edit`} noThrow={true} />;
}

export default DocumentCreator;
