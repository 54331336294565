import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import P from 'components/P';
import Button, {ButtonSpacer} from 'components/Button';

DocumentVersionErrorModal.propTypes = {
  visible: PropTypes.bool.isRequired,
};

function DocumentVersionErrorModal (props) {
  const {
    visible,
  } = props;

  const refresh = () => {
    window.location.reload();
  };

  return (
    <Modal
      visible={visible}
      title="This page has been updated"
      footer={<>
        <ButtonSpacer />
        <Button type="button" primary onClick={refresh}>
          Refresh
        </Button>
      </>}>
      <P>Please refresh to see the latest version.</P>
    </Modal>
  );
}

export default DocumentVersionErrorModal;
