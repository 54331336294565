import React from 'react';
import PropTypes from 'prop-types';
import GridRow from './GridRow';
import GridCellGroup from './GridCellGroup';
import DataGridCell from './DataGridCell';
import DragAndDroppableGridRow from './DragAndDroppableGridRow';

DataGridRow.propTypes = {
  columnGroups: PropTypes.arrayOf(PropTypes.shape({
    columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    frozen: PropTypes.bool,
  })).isRequired,
  index: PropTypes.number.isRequired,
  item: PropTypes.shape({
    expandable: PropTypes.bool,
    expanded: PropTypes.bool,
    id: PropTypes.number,
    name: PropTypes.string,
    indentLevel: PropTypes.number,
    saving: PropTypes.bool,
    section: PropTypes.bool,
    selected: PropTypes.bool,
  }),
  onCollapseItem: PropTypes.func.isRequired,
  onDragStart: PropTypes.func,
  onDragHover: PropTypes.func,
  onDragEnd: PropTypes.func,
  onDrop: PropTypes.func,
  onExpandItem: PropTypes.func.isRequired,
  onDeselectItem: PropTypes.func.isRequired,
  onSelectItem: PropTypes.func.isRequired,
};

DataGridRow.defaultProps = {
};

function DataGridRow (props) {
  const {
    columnGroups,
    index,
    item,
    onCollapseItem,
    onDragStart,
    onDragHover,
    onDragEnd,
    onDrop,
    onExpandItem,
    onDeselectItem,
    onSelectItem,
  } = props;

  // An item without an id has been newly created but not saved to the backend
  // yet. Don't support moving.
  const GridRowComponent = item.id ? DragAndDroppableGridRow : GridRow;

  return (
    <GridRowComponent
      expandable={!item.saving && item.expandable}
      expanded={item.expanded}
      indentLevel={item.indentLevel}
      index={index}
      onCollapse={() => onCollapseItem(item.id)}
      onExpand={() => onExpandItem(item.id)}
      onDeselect={() => onDeselectItem(item.id)}
      onDragStart={onDragStart}
      onDragHover={onDragHover}
      onDragEnd={onDragEnd}
      onDrop={onDrop}
      onSelect={() => onSelectItem(item.id)}
      selected={item.selected}
      subheader={!!item.section}
      supportBulkSelect={!item.saving}
      supportDrag={!!item.id}
      itemId={String(item.id)}
      item={item}>
      {columnGroups.map((columnGroup, i) => (
        <GridCellGroup
          key={`grid-cell-group${i}`}
          frozen={columnGroup.frozen}>
          {columnGroup.columns && columnGroup.columns.map((column, j) => (
            <DataGridCell
              key={column.label}
              column={column}
              first={i === 0 && j === 0}
              item={item}
            />
          ))}
        </GridCellGroup>
      ))}
    </GridRowComponent>
  );
}

export default DataGridRow;
