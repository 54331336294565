import React from 'react';
import PropTypes from 'prop-types';
import CustomPropTypes from 'utils/CustomPropTypes';
import tw, {styled} from 'twin.macro';
import Button, {ButtonBar} from 'components/Button';
import {ITEM_TYPE, PERMISSIONS, SUBSCRIPTION_TYPE} from 'am-constants';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';
import getUrlForItem from 'utils/getUrlForItem';
import Link from 'components/Link';
import DropdownButton from 'components/DropdownButton';
import {AiOutlineMore} from 'react-icons/ai';
import checkIfItemIsUnpublished from 'utils/checkIfItemIsUnpublished';
import getNameForItem from 'utils/getNameForItem';
import {getPermissionsForRoles} from 'am-utils';
import SidePanelSection from './SidePanelSection';
import SidePanelDescription from './SidePanelDescription';
import SidePanelResponses from './SidePanelResponses';
import SidePanelAutomations from './SidePanelAutomations';

SidePanelContent.propTypes = {
  automations: PropTypes.array,
  contentDescription: PropTypes.array,
  item: CustomPropTypes.Item,
  onUpdateItem: PropTypes.func.isRequired,
  responses: PropTypes.array,
  responseStats: PropTypes.shape({
    numActiveResponses: PropTypes.number,
    numSubmittedResponses: PropTypes.number,
  }),
  savingAttrs: PropTypes.array.isRequired,
  setIsAutomationModalVisible: PropTypes.func,
  setIsShareItemModalVisible: PropTypes.func,
  setIsDeleteItemModalVisible: PropTypes.func,
  setIsRenameFolderModalVisible: PropTypes.func,
  setIsAutomationEditorModalVisible: PropTypes.func,
  setAutomationEditorData: PropTypes.func,
};

SidePanelContent.defaultProps = {
  responseStats: {},
};

function SidePanelContent (props) {
  const {
    automations,
    contentDescription,
    item,
    onUpdateItem,
    responses,
    responseStats,
    savingAttrs,
    setIsAutomationModalVisible,
    setIsShareItemModalVisible,
    setIsDeleteItemModalVisible,
    setIsRenameFolderModalVisible,
    setIsAutomationEditorModalVisible,
    setAutomationEditorData,
  } = props;
  const {loggedInUserRoleIds} = item;
  const {urlName: workspaceUrlName, subscription} = useWorkspace();
  const viewUrl = getUrlForItem(workspaceUrlName, item);
  const editUrl = viewUrl.substr(-5) === '/edit' ? viewUrl : `${viewUrl}/edit`;
  const useUrl = viewUrl.substr(-5) === '/edit' ? viewUrl : `${viewUrl}/new`;
  const folderManageUrl = `${viewUrl}/manage`;

  const unpublished = checkIfItemIsUnpublished(item);
  const loggedInUserItemPermissions = getPermissionsForRoles(loggedInUserRoleIds);
  const hasItemAutomationPermissions = loggedInUserItemPermissions.includes(PERMISSIONS.MANAGE_ITEM_AUTOMATIONS);
  const hasTeamSubscription = subscription?.subscriptionTypeId === SUBSCRIPTION_TYPE.TEAM;

  const types = {
    [ITEM_TYPE.FOLDER]: {
      name: 'Folder',
      primaryButton: {
        label: 'Open folder',
        to: folderManageUrl,
      },
      optionsMenu: [
        [
          {
            disabled: !loggedInUserItemPermissions.includes(PERMISSIONS.RENAME_FOLDER),
            label: 'Rename',
            onClick: () => setIsRenameFolderModalVisible(true),
          },
          {
            label: 'Share',
            onClick: () => setIsShareItemModalVisible(true),
          },
          {
            disabled: !loggedInUserItemPermissions.includes(PERMISSIONS.DELETE_ITEM),
            label: 'Delete',
            onClick: () => setIsDeleteItemModalVisible(true),
          },
        ],
      ],
    },
    [ITEM_TYPE.SECTION]: {
      name: 'Section',
      optionsMenu: [
        [
          {
            disabled: !loggedInUserItemPermissions.includes(PERMISSIONS.RENAME_FOLDER),
            label: 'Rename',
            onClick: () => setIsRenameFolderModalVisible(true),
          },
          {
            label: 'Share',
            onClick: () => setIsShareItemModalVisible(true),
          },
          {
            disabled: !loggedInUserItemPermissions.includes(PERMISSIONS.DELETE_ITEM),
            label: 'Delete',
            onClick: () => setIsDeleteItemModalVisible(true),
          },
        ],
      ],
    },
    [ITEM_TYPE.CHECKLIST]: {
      name: 'Checklist',
      primaryButton: {
        label: 'Use checklist',
        to: useUrl,
      },
      optionsMenu: [
        [
          {
            label: 'Preview',
            to: viewUrl,
          },
          {
            disabled: !loggedInUserItemPermissions.includes(PERMISSIONS.EDIT_PAGE),
            label: 'Edit',
            to: editUrl,
          },
          {
            label: 'Share',
            onClick: () => setIsShareItemModalVisible(true),
          },
          {
            disabled: !loggedInUserItemPermissions.includes(PERMISSIONS.DELETE_ITEM),
            label: 'Delete',
            onClick: () => setIsDeleteItemModalVisible(true),
          },
        ],
      ],
    },
    [ITEM_TYPE.FORM]: {
      name: 'Form',
      primaryButton: {
        label: 'Use form',
        to: useUrl,
      },
      optionsMenu: [
        [
          {
            label: 'Preview',
            to: viewUrl,
          },
          {
            disabled: !loggedInUserItemPermissions.includes(PERMISSIONS.EDIT_PAGE),
            label: 'Edit',
            to: editUrl,
          },
          {
            label: 'Share',
            onClick: () => setIsShareItemModalVisible(true),
          },
          {
            disabled: !loggedInUserItemPermissions.includes(PERMISSIONS.DELETE_ITEM),
            label: 'Delete',
            onClick: () => setIsDeleteItemModalVisible(true),
          },
        ],
      ],
    },
    [ITEM_TYPE.DOCUMENT]: {
      name: 'Document',
      primaryButton: {
        label: 'Open document',
        to: viewUrl,
      },
      optionsMenu: [
        [
          {
            disabled: !loggedInUserItemPermissions.includes(PERMISSIONS.EDIT_PAGE),
            label: 'Edit',
            to: editUrl,
          },
          {
            label: 'Share',
            onClick: () => setIsShareItemModalVisible(true),
          },
          {
            disabled: !loggedInUserItemPermissions.includes(PERMISSIONS.DELETE_ITEM),
            label: 'Delete',
            onClick: () => setIsDeleteItemModalVisible(true),
          },
        ],
      ],
    },
    [ITEM_TYPE.LINK]: {
      name: 'Link',
      primaryButton: {
        label: 'Open link',
        to: viewUrl,
      },
      optionsMenu: [
        [
          // {
          //   label: 'Edit',
          //   to: editUrl,
          // },
          {
            label: 'Share',
            onClick: () => setIsShareItemModalVisible(true),
          },
          {
            disabled: !loggedInUserItemPermissions.includes(PERMISSIONS.DELETE_ITEM),
            label: 'Delete',
            onClick: () => setIsDeleteItemModalVisible(true),
          },
        ],
      ],
    },
  };

  const unpublishedSettings = {
    name: 'Unpublished page',
    primaryButton: {
      label: 'Open editor',
      to: editUrl,
    },
    optionsMenu: [
      [
        {
          label: 'Share',
          onClick: () => setIsShareItemModalVisible(true),
        },
        {
          disabled: !loggedInUserItemPermissions.includes(PERMISSIONS.DELETE_ITEM),
          label: 'Delete',
          onClick: () => setIsDeleteItemModalVisible(true),
        },
      ],
    ],
  };

  const {numActiveResponses, numSubmittedResponses} = responseStats;
  const settings = unpublished ? unpublishedSettings : types[item.itemTypeId];
  const isChecklist = item.itemTypeId === ITEM_TYPE.CHECKLIST;
  const isForm = item.itemTypeId === ITEM_TYPE.FORM;

  const heading = getHeading(automations, hasTeamSubscription, hasItemAutomationPermissions);

  return (
    <>
      <StyledSidePanelSection header>
        <SidePanelHeading>
          {/* <Emoji>{item.emoji}</Emoji> */}
          {getNameForItem(item)}
        </SidePanelHeading>
        <Meta>{settings.name}</Meta>
        <SidePanelDescription
          contentDescription={contentDescription}
          itemId={item.id}
          isSaving={savingAttrs.includes('contentDescription')}
          onUpdateItem={onUpdateItem}
        />
      </StyledSidePanelSection>
      {/* <SidePanelSection heading="Issues (0)">
      Coming soon!
    </SidePanelSection> */}
      {!unpublished && (isChecklist || isForm) && (
        <>
          <SidePanelSection
            heading={`${isChecklist ? 'Checklists' : 'Forms'} in progress (${
              numActiveResponses || 0
            })`}
          >
            <SidePanelResponses
              itemName={item.name}
              noResponsesLabel={
                isChecklist
                  ? 'This checklist has not been used yet'
                  : 'This form has not been used yet'
              }
              numTotalResponses={numActiveResponses + numSubmittedResponses}
              responses={responses}
              urlName={item.urlName}
            />
          </SidePanelSection>
        </>
      )}
      {(isChecklist || isForm) && (<>
        <SidePanelSection heading={heading}>
          <SidePanelAutomations
            itemName={item.name}
            hasTeamSubscription={hasTeamSubscription}
            hasItemAutomationPermissions={hasItemAutomationPermissions}
            noAutomationsLabel={
              isChecklist
                ? 'This checklist has no automations'
                : 'This form has no automations'
            }
            automations={automations}
            openModal={() => setIsAutomationModalVisible(true)}
            setAutomationEditorData={setAutomationEditorData}
            setIsAutomationEditorModalVisible={setIsAutomationEditorModalVisible}
            urlName={item.urlName}
            loggedInUserRoleIds={loggedInUserRoleIds}
          />
        </SidePanelSection>
      </>)}
      {/* <SidePanelSection heading="All activity">
      Coming soon!
    </SidePanelSection> */}
      <StyledSidePanelSection footer>
        <ButtonBar>
          {settings.primaryButton && (
            <Link to={settings.primaryButton.to}>
              <Button primary>{settings.primaryButton.label}</Button>
            </Link>
          )}
          {settings.optionsMenu && (
            <DropdownButton
              menu={settings.optionsMenu}
              xDirection={settings.primaryButton ? 'left' : 'right'}
              data-test-id="burger-menu"
            >
              <div key="x" css="transform: rotate(90deg);">
                <AiOutlineMore size="24" tw="my-1.5 cursor-pointer" />
              </div>
            </DropdownButton>
          )}
        </ButtonBar>
      </StyledSidePanelSection>
    </>
  );
}

function getHeading (automations, hasTeamSubscription, hasItemAutomationPermissions) {
  if (hasTeamSubscription && !hasItemAutomationPermissions) {
    return 'Automations';
  }

  return `Automations (${automations?.length || 0})`;
}

const SidePanelHeading = styled.div((props) => [
  tw`
    text-xl
    font-bold
    flex
    items-center
    gap-2
  `,
]);

const Meta = styled.div((props) => [
  tw`
    text-sm
    text-gray-400
    font-medium
    mt-1
  `,
]);

// const Emoji = styled.div((props) => [
//   tw`
//     w-6
//     flex
//     justify-center
//   `,
// ]);

const StyledSidePanelSection = styled.div((props) => [
  (props.footer || props.header) && tw`
    p-8
  `,
  props.footer && tw`
    pt-16
  `,
]);

export default SidePanelContent;
