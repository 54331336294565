import React from 'react';
import PropTypes from 'prop-types';

const PrintModeContext = React.createContext();

PrintModeProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

PrintModeProvider.defaultProps = {
};

function PrintModeProvider (props) {
  const {
    children,
  } = props;

  const context = true;

  return <>
    <PrintModeContext.Provider value={context}>
      {children}
    </PrintModeContext.Provider>
  </>;
}

export function usePrintMode () {
  const context = React.useContext(PrintModeContext);

  return context;
}

export default PrintModeProvider;
