import {Editor} from 'slate';
import checkCanElementTypeBeTransformed from './checkCanElementTypeBeTransformed';

function checkCanSelectionBeTransformed (editor) {
  const nodes = Editor.nodes(editor, {
    match: (n) => checkCanElementTypeBeTransformed(n.type),
  });

  return !!nodes.next().value;
}

export default checkCanSelectionBeTransformed;
