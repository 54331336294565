import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'components/Loader';
import ResetPasswordChooserPage from './components/ResetPasswordChooserPage';
import ResetPasswordInvalidLinkPage from './components/ResetPasswordInvalidLinkPage';
import ResetPasswordExpiredPage from './components/ResetPasswordExpiredPage';
import ResetPasswordAlreadyUsedPage from './components/ResetPasswordAlreadyUsedPage';
import PasswordUpdatedSuccessfullyPage from './components/PasswordUpdatedSuccessfullyPage';
import usePasswordResetRequestQuery from './hooks/usePasswordResetRequestQuery';
import useResetPasswordMutation from './hooks/useResetPasswordMutation';

ResetPasswordChooser.propTypes = {
  resetKey: PropTypes.string,
};

ResetPasswordChooser.defaultProps = {
  printMode: false,
};

function ResetPasswordChooser (props) {
  const {resetKey} = props;
  const {data, error: queryError} = usePasswordResetRequestQuery(resetKey);
  const [resetPassword, {data: resetSuccess, error: resetPasswordError, loading}] = useResetPasswordMutation();

  if (resetSuccess) {
    return <PasswordUpdatedSuccessfullyPage />;
  }
  if (queryError) {
    return <ResetPasswordInvalidLinkPage />;
  }
  if (data && data.passwordResetRequest) {
    const {passwordResetRequest: {used, expired}} = data;
    if (expired) {
      return <ResetPasswordExpiredPage />;
    }
    if (used) {
      return <ResetPasswordAlreadyUsedPage />;
    }

    const onSubmit = async (newPassword) => {
      await resetPassword({variables: {resetKey, newPassword}});
    };

    return <ResetPasswordChooserPage
      resetPassword={onSubmit}
      error={resetPasswordError}
      loading={loading}
    />;
  }

  return <Loader />;
}

export default ResetPasswordChooser;
