import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import P from 'components/P';
import tw from 'twin.macro'; // eslint-disable-line no-unused-vars
import {
  ITEM_ROLE, ITEM_TYPE, PERMISSIONS, SUBSCRIPTION_TYPE, STATUS_CODE,
} from 'am-constants';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';
import {getPermissionsForRoles} from 'am-utils';
import {useUser} from 'containers/UserApp/providers/UserProvider';
import A from 'components/A';
import ItemSharerModal from './components/ItemSharerModal';
import useItemSharingSettingsUpdateMutation from './hooks/useItemSharingSettingsUpdateMutation';
import useGetItemPermissionsQuery from './hooks/useGetItemPermissionsQuery';

ItemSharer.propTypes = {
  loggedInUserRoleIds: PropTypes.array.isRequired,
  isVisible: PropTypes.bool.isRequired,
  isItemPublic: PropTypes.bool.isRequired,
  itemKey: PropTypes.string.isRequired,
  itemName: PropTypes.string.isRequired,
  itemRef: PropTypes.string.isRequired,
  itemTypeId: PropTypes.number.isRequired,
  itemUrl: PropTypes.string.isRequired,
  setIsVisible: PropTypes.func.isRequired,
};

function ItemSharer (props) {
  const {
    loggedInUserRoleIds,
    isVisible,
    isItemPublic,
    itemKey,
    itemName,
    itemRef,
    itemTypeId,
    itemUrl,
    setIsVisible,
  } = props;

  const {subscription, urlName: workspaceUrlName} = useWorkspace();
  const {user} = useUser();
  const {data, loading} = useGetItemPermissionsQuery({itemRef, itemKey});
  const [sharingSettings, setSharingSettings] = useState({});

  const updateSharingSettings = () => {
    if (!loading && data) {
      setSharingSettings({
        allMembersRoleIds: data.item.workspaceMembersRoleIds,
        publicRoleIds: isItemPublic ? [ITEM_ROLE.VIEWER] : [],
        userRoleIds: data.item.userRoleIds,
      });
    }
  };

  useEffect(updateSharingSettings, [loading, data]);

  const [updateItemSharingSettings, {error, reset: resetErrorState}] = useItemSharingSettingsUpdateMutation({
    itemRef, itemKey,
  });

  const closeModal = () => {
    setIsVisible(false);
    updateSharingSettings();
    resetErrorState();
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    const isLimitingOwnAccess = getIsLimitingOwnAccess({
      sharingSettings,
      userId: user.id,
    });

    if (isLimitingOwnAccess) {
      // eslint-disable-next-line no-alert
      const didUserConfirmRemove = await window.confirm('Are you sure you want to limit your own access?');

      if (!didUserConfirmRemove) {
        return;
      }
    }

    await updateItemSharingSettings({variables: {sharingSettings}});
    setIsVisible(false);
    resetErrorState();
  };

  const errorCode = error?.graphQLErrors[0]?.extensions?.exception?.statusCode;
  const renderError = errorCode === STATUS_CODE.BAD_REQUEST
    ? (() => (<P bottom>At least one team member must have full access.</P>))
    : (() => (<P bottom>Please check your connection and try again.</P>));

  const hasTeamSubscription = subscription?.subscriptionTypeId === SUBSCRIPTION_TYPE.TEAM;
  const hasItemSharerPermissions =
    hasTeamSubscription &&
    getPermissionsForRoles(loggedInUserRoleIds).includes(PERMISSIONS.MAKE_ITEM_PUBLIC) &&
    getPermissionsForRoles(loggedInUserRoleIds).includes(PERMISSIONS.MANAGE_ITEM_ACCESS);

  const noAccessMessage = !hasTeamSubscription && (
    <P>
      <A href={`/${workspaceUrlName}/billing`}>
        Upgrade to the Team plan
      </A> to update these settings.
    </P>);

  return (
    <ItemSharerModal
      closeModal={closeModal}
      hasItemSharerPermissions={hasItemSharerPermissions}
      isVisible={isVisible}
      isItemPublic={isItemPublic}
      itemName={itemName}
      itemUrl={itemUrl}
      noAccessMessage={noAccessMessage}
      onSubmit={onSubmit}
      renderError={error && renderError}
      sharingSettings={sharingSettings}
      setSharingSettings={setSharingSettings}
      title={getModalTitle(itemTypeId)}
    />
  );
}

function getModalTitle (itemTypeId) {
  if (itemTypeId === ITEM_TYPE.FOLDER) {
    return 'Share this folder';
  }

  if (itemTypeId === ITEM_TYPE.SECTION) {
    return 'Share this section';
  }

  return 'Share this page';
}

function getIsLimitingOwnAccess ({sharingSettings, userId}) {
  const {allMembersRoleIds, userRoleIds} = sharingSettings;

  if (allMembersRoleIds.includes(ITEM_ROLE.ADMIN)) {
    return false;
  }

  const proposedUserItemRoleIds = userRoleIds
    .find((userRoleId) => userRoleId.user.id === userId)
    ?.itemRoleIds;

  if (proposedUserItemRoleIds?.includes(ITEM_ROLE.ADMIN)) {
    return false;
  }

  return true;
}

export default ItemSharer;
