import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import tw, {styled} from 'twin.macro';
import {motion} from 'framer-motion';

HelpBlockContent.propTypes = {
  attributes: PropTypes.shape({}).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  editable: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  printMode: PropTypes.bool.isRequired,
};

function HelpBlockContent (props) {
  const {
    children,
    editable,
    open,
    printMode,
  } = props;

  const variants = {
    open: {
      height: 'auto',
    },
    closed: {
      height: 0,
    },
  };

  const wrapper = useRef(null);
  const height = wrapper.current ? wrapper.current.offsetHeight : 0;
  const duration = Math.min(0.3, 0.1 + (height / 50) * 0.02);

  return (
    <motion.div
      animate={open || printMode ? 'open' : 'closed'}
      variants={variants}
      initial={false}
      transition={{duration}}>
      <OverflowWrapper onClick={(e) => e.stopPropagation()}
        contentEditable={editable && open}
        suppressContentEditableWarning>
        <Wrapper open={open} ref={wrapper}>
          {children}
        </Wrapper>
      </OverflowWrapper>
    </motion.div>
  );
}

const OverflowWrapper = styled.div((props) => [
  tw`
    cursor-default
    overflow-hidden
  `,
]);

const Wrapper = styled.div((props) => [
  tw`
    px-3
    pt-1.5
    pb-3
  `,
]);

export default React.memo(HelpBlockContent);
