import React from 'react';
import {Helmet} from 'react-helmet';
import P from 'components/P';
import Button, {ButtonSpacer} from 'components/Button';
import Link from 'components/Link';
import SimpleFormContainer from 'components/SimpleFormContainer';
import SimpleFormHeading from 'components/SimpleFormHeading';
import SimpleFormPage from 'components/SimpleFormPage';

function PasswordUpdatedSuccessfullyPage () {
  return <>
    <Helmet>
      <title>Password updated</title>
    </Helmet>
    <SimpleFormPage>
      <SimpleFormContainer>
        <SimpleFormHeading>Password updated</SimpleFormHeading>
        <P>
          Your password has now been updated and you can login.
        </P>
        <ButtonSpacer vertical />
        <Link to="/login">
          <Button primary lg fullwidth>Login</Button>
        </Link>
      </SimpleFormContainer>
    </SimpleFormPage>
  </>;
}

export default PasswordUpdatedSuccessfullyPage;
