import React, {useState} from 'react';
import PropTypes from 'prop-types';
import PageDuplicater from 'containers/PageDuplicater';
import ItemMover from 'containers/ItemMover';
import ItemDeleter from 'containers/ItemDeleter';
import {useItem} from 'providers/ItemProvider';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';
import ItemSharer from 'containers/ItemSharer';
import getUrlForItem from 'utils/getUrlForItem';
import {PERMISSIONS} from 'am-constants';
import ResponseDeleter from 'containers/ResponseDeleter';
import getNameRefAndKeyParts from 'utils/getNameRefAndKeyParts';
import DocumentToolbarComponent from './components/DocumentToolbarComponent';

DocumentToolbar.propTypes = {
  viewMode: PropTypes.bool,
  editMode: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  showCopyPageMenu: PropTypes.bool,
  showDeleteItemMenu: PropTypes.bool,
  showDeleteResponseMenu: PropTypes.bool,
  showExportItemMenu: PropTypes.bool,
  showMoveItemMenu: PropTypes.bool,
  showShareItemMenu: PropTypes.bool,
  showViewResponsesMenu: PropTypes.bool,
};

DocumentToolbar.defaultProps = {
  editMode: false,
  showCopyPageMenu: false,
  showDeleteItemMenu: false,
  showDeleteResponseMenu: false,
  showExportItemMenu: false,
  showMoveItemMenu: false,
  showShareItemMenu: false,
  showViewResponsesMenu: false,
};

function DocumentToolbar (props) {
  const {
    viewMode,
    editMode,
    children,
    showCopyPageMenu,
    showDeleteItemMenu,
    showDeleteResponseMenu,
    showExportItemMenu,
    showMoveItemMenu,
    showShareItemMenu,
    showViewResponsesMenu,
  } = props;

  const {workspacePermissions} = useWorkspace();
  const {urlName: workspaceUrlName} = useWorkspace();
  const {item} = useItem();
  const {
    loggedInUserRoleIds,
    workspaceMembersRoleIds,
    userRoleIds,
    key,
    name,
    ref,
    itemTypeId,
    urlName,
    public: isItemPublic,
  } = item;

  const [isMoveItemModalVisible, setIsMoveItemModalVisible] = useState(false);
  const [isDeleteItemModalVisible, setIsDeleteItemModalVisible] = useState(false);
  const [isDeleteResponseModalVisible, setIsDeleteResponseModalVisible] = useState(false);
  const [isCopyPageModalVisible, setIsCopyPageModalVisible] = useState(false);
  const [isShareItemModalVisible, setIsShareItemModalVisible] = useState(false);

  // Used for responseRef and responseKey
  const {ref: responseRef, key: responseKey} = getResponseRefAndKey();

  return (<>
    <PageDuplicater
      isVisible={isCopyPageModalVisible}
      setIsVisible={setIsCopyPageModalVisible}
      itemRef={ref}
      itemKey={key}
      itemName={name}
    />
    {showMoveItemMenu && <ItemMover
      isVisible={isMoveItemModalVisible}
      setIsVisible={setIsMoveItemModalVisible}
      itemRef={ref}
      itemKey={key}
      itemName={name}
      itemId={item.id}
    />}
    <ItemDeleter
      isVisible={isDeleteItemModalVisible}
      itemRef={ref}
      itemKey={key}
      redirectToWorkspace
      setIsVisible={setIsDeleteItemModalVisible}
      workspaceUrlName={workspaceUrlName}
    />
    {showDeleteResponseMenu && <ResponseDeleter
      isVisible={isDeleteResponseModalVisible}
      setIsVisible={setIsDeleteResponseModalVisible}
      responseRef={responseRef}
      responseKey={responseKey}
    />}
    {workspacePermissions.includes(PERMISSIONS.VIEW_WORKSPACE) &&
    <ItemSharer
      isVisible={isShareItemModalVisible}
      isItemPublic={isItemPublic}
      itemName={name}
      itemRef={ref}
      itemKey={key}
      itemTypeId={itemTypeId}
      itemUrl={getUrlForItem(workspaceUrlName, item)}
      setIsVisible={setIsShareItemModalVisible}
      userRoleIds={userRoleIds}
      loggedInUserRoleIds={loggedInUserRoleIds}
      workspaceMembersRoleIds={workspaceMembersRoleIds}
    />}
    <DocumentToolbarComponent
      itemUrlName={urlName}
      viewMode={viewMode}
      editMode={editMode}
      setIsCopyPageModalVisible={setIsCopyPageModalVisible}
      setIsDeleteItemModalVisible={setIsDeleteItemModalVisible}
      setIsDeleteResponseModalVisible={setIsDeleteResponseModalVisible}
      setIsMoveItemModalVisible={setIsMoveItemModalVisible}
      setIsShareItemModalVisible={setIsShareItemModalVisible}
      showCopyPageMenu={showCopyPageMenu}
      showExportItemMenu={showExportItemMenu}
      showDeleteItemMenu={showDeleteItemMenu}
      showDeleteResponseMenu={showDeleteResponseMenu}
      showMoveItemMenu={showMoveItemMenu}
      showShareItemMenu={showShareItemMenu}
      showViewResponsesMenu={showViewResponsesMenu}
    >
      {children}
    </DocumentToolbarComponent>
  </>);
}

function getResponseRefAndKey () {
  const {href} = window.location;
  const [nameRefAndKey] = href.split('/').slice(-1);
  return getNameRefAndKeyParts(nameRefAndKey);
}

export default DocumentToolbar;
