import PropTypes from 'prop-types';
import {gql} from '@apollo/client';
import useMutation from 'hooks/useMutation';
import {useQueryClient} from '@tanstack/react-query';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';

const PUBLISH = gql`
  mutation PUBLISH($itemRef: String!, $itemKey: String!, $content: JSON!, $version: Int!, $doNotUpdateResponses: Boolean) {
    documentPublish(
      itemRef: $itemRef,
      itemKey: $itemKey,
      content: $content,
      version: $version,
      doNotUpdateResponses: $doNotUpdateResponses
    ) {
      id
    }
  }
`;

usePublishMutation.propTypes = {
  documentId: PropTypes.string.isRequired,
  workspaceUrlName: PropTypes.string.isRequired,
  onCompleted: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

function usePublishMutation (props) {
  const {
    itemRef,
    itemKey,
    onCompleted,
    onError,
  } = props;

  const queryClient = useQueryClient();
  const {urlName} = useWorkspace();

  const [onPublish, {loading, data, error}] = useMutation(PUBLISH, {
    variables: {
      itemRef,
      itemKey,
    },
    onCompleted: () => {
      onCompleted();

      queryClient.invalidateQueries([urlName, 'document']);
      queryClient.invalidateQueries([urlName, 'items']);
    },
    onError,
  });

  return [onPublish, {
    publishing: loading,
    published: !!data,
    publishError: error,
  }];
}

export default usePublishMutation;
