import {ITEM_TYPE} from 'am-constants';
import getNewRank from './getNewRank';

const getNewLocationForSection = ({
  dropIndex,
  itemsByParentId,
  preparedItems,
  parentItemId,
}) => {
  const {previousItem, nextItem} = getPreviousAndNextItems({preparedItems, dropIndex});

  const previousItemParent = getItemParent({
    item: previousItem,
    items: preparedItems,
  });

  const newParentId = getNewParentId({
    previousItem,
    previousItemParent,
    nextItem,
    parentItemId,
  });

  const newRank = previousItem?.adder
    ? getNewRankAfterAdder({
      dropIndex,
      preparedItems,
      previousItem,
    })
    : getNewRankForSectionItem({
      itemsByParentId,
      previousItem,
      previousItemParent,
      nextItem,
      newParentId,
    });

  // console.log('Moving section', {
  //   newParentId,
  //   newRank,
  // });

  return {
    newParentId,
    newRank,
  };
};

function getPreviousAndNextItems ({preparedItems, dropIndex}) {
  const previousItem = dropIndex > 0 ? preparedItems[dropIndex - 1] : undefined;
  const nextItem = dropIndex < preparedItems.length ? preparedItems[dropIndex] : undefined;

  return {
    previousItem,
    nextItem,
  };
}

function getItemParent ({item, items}) {
  const previousItemParentId = item?.parentId;
  const previousItemParent = previousItemParentId ? items.find((i) => i.id === previousItemParentId) : undefined;

  return previousItemParent;
}

function getNewParentId ({
  previousItem,
  previousItemParent,
  nextItem,
  parentItemId,
}) {
  if (!previousItem) {
    return parentItemId;
  }

  if (previousItem?.adder) {
    return nextItem.parentId || parentItemId;
  }

  if (previousItem?.itemTypeId === ITEM_TYPE.FOLDER && previousItem.expanded) {
    return previousItem.id;
  }

  if (previousItemParent?.itemTypeId === ITEM_TYPE.SECTION) {
    return previousItemParent.parentId;
  }

  return previousItem.parentId;
}

function getNewRankForSectionItem ({
  itemsByParentId,
  previousItem,
  previousItemParent,
  nextItem,
  newParentId,
}) {
  const previousSectionItem = getPreviousSectionItem({
    newParentId,
    previousItem,
    previousItemParent,
  });
  const previousRankItem = previousSectionItem || getLastNonSectionItemWithSameParentId({itemsByParentId, newParentId});
  const previousRank = previousRankItem?.rank;

  const nextRankItem = getNextSectionItem({
    newParentId,
    previousItem,
    nextItem,
  });
  const nextRank = nextRankItem?.rank;

  return getNewRank({
    previousRank,
    nextRank,
  });
}

function getPreviousSectionItem ({
  newParentId,
  previousItem,
  previousItemParent,
}) {
  if (!previousItem) {
    return undefined;
  }

  if (
    previousItem.parentId === newParentId &&
    previousItem.itemTypeId === ITEM_TYPE.SECTION
  ) {
    return previousItem;
  }

  if (
    previousItemParent?.parentId === newParentId &&
    previousItemParent?.itemTypeId === ITEM_TYPE.SECTION
  ) {
    return previousItemParent;
  }

  return undefined;
}

function getLastNonSectionItemWithSameParentId ({itemsByParentId, newParentId}) {
  const itemsWithSameParentId = itemsByParentId[newParentId];

  if (!itemsWithSameParentId) {
    return undefined;
  }

  const nonSectionItemsWithSameParentId = itemsWithSameParentId.filter((item) => item.itemTypeId !== ITEM_TYPE.SECTION);

  return nonSectionItemsWithSameParentId ? nonSectionItemsWithSameParentId.slice(-1)[0] : undefined;
}

function getNextSectionItem ({
  newParentId,
  nextItem,
}) {
  if (!nextItem) {
    return undefined;
  }

  if (nextItem.adder) {
    return undefined;
  }

  if (
    nextItem.parentId === newParentId &&
    nextItem.itemTypeId === ITEM_TYPE.SECTION
  ) {
    return nextItem;
  }

  return undefined;
}

function getNewRankAfterAdder ({
  dropIndex,
  preparedItems,
  previousItem,
}) {
  const adderFolder = preparedItems.find((i) => i.id === previousItem.folderId);
  const adderFolderParent = preparedItems.find((i) => i.id === adderFolder.parentId);
  const previousSectionItem = adderFolderParent?.itemTypeId === ITEM_TYPE.SECTION ? adderFolderParent : undefined;
  const previousRank = previousSectionItem
    ? previousSectionItem.rank
    : adderFolder.rank;

  const nextSectionItem = preparedItems.find((item, index) =>
    index >= dropIndex &&
    item.parentId === adderFolder.parentId &&
    item.itemTypeId === ITEM_TYPE.SECTION);

  const nextRank = nextSectionItem?.rank;

  return getNewRank({
    previousRank,
    nextRank,
  });
}

export default getNewLocationForSection;
