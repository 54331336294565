import {Editor} from 'slate';
import isVoidType from '../utils/isVoidType';

function onBackspaceAtStartOfCollapsibleContent (editor, event, selectionMeta) {
  const {
    isSelectionAtStartOfElement,
    selectionStart,
    selectionStartElement,
    selectionStartElementPath,
  } = selectionMeta;

  if (
    event.key !== 'Backspace' ||
    !selectionStart ||
    !isSelectionAtStartOfElement ||
    isVoidType(selectionStartElement.type)
  ) {
    return false;
  }

  const [collapsibleContentElement] = Editor.above(
    editor,
    {
      at: selectionStartElementPath,
      match: (n) => [
        'help-block-content',
        'process-step-content',
      ].includes(n.type),
    }
  ) || [undefined];

  if (
    !collapsibleContentElement ||
    selectionStartElementPath.slice(-1)[0] !== 0
  ) {
    return false;
  }

  event.preventDefault();
  return true;
}

export default onBackspaceAtStartOfCollapsibleContent;
