import React from 'react';
import ReactConfetti from 'react-confetti';
import useWindowSize from '../utils/useWindowSize';

function Confetti (props) {
  const {width, height} = useWindowSize();

  return <ReactConfetti
    gravity={0.15}
    numberOfPieces={500}
    width={width}
    height={height}
    style={{position: 'fixed', zIndex: 100}}
    {...props} />;
}

export default Confetti;
