import useScript from 'hooks/useScript';
import PropTypes from 'prop-types';
import {useEffect} from 'react';

HeapUser.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
  }),
};

function Heap () {
  const heapId = getHeapId();

  useScript({
    id: 'heap-loader',
    innerHTML: `
      window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=t.forceSSL||"https:"===document.location.protocol,a=document.createElement("script");a.type="text/javascript",a.async=!0,a.src=(r?"https:":"http:")+"//cdn.heapanalytics.com/js/heap-"+e+".js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(a,n);for(var o=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","removeEventProperty","setEventProperties","track","unsetEventProperty"],c=0;c<p.length;c++)heap[p[c]]=o(p[c])};
      heap.load('${heapId}');
    `,
    position: 'head',
    purposes: [],
    isCookieConsentRequired: false,
  });

  return null;
}

export function HeapUser ({user}) {
  const isLoggedIn = user && !!user.email;

  useEffect(() => {
    if (isLoggedIn && window.heap) {
      window.heap.identify(user.id);
      window.heap.addUserProperties({email: user.email});
    }
  }, [isLoggedIn]);

  return null;
}

function getHeapId () {
  // Note: The id can be easily found by looking at the webpage source, so
  // there is no big security benefit to keeping it out of the code repo.
  switch (process.env.NODE_ENV) {
    case 'development':
      return '2843569092';
    case 'test':
      return '3265302259';
    case 'production':
      return '2977170428';
    default:
      throw new Error('Invalid NODE_ENV');
  }
}

export default Heap;
