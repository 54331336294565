import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled, css} from 'twin.macro';
import GridProvider from './providers/GridProvider';

Grid.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  freezeFirstColumnGroup: PropTypes.bool,
  supportBulkSelect: PropTypes.bool,
  supportDrag: PropTypes.bool,
  supportExpand: PropTypes.bool,
};

Grid.defaultProps = {
  freezeFirstColumnGroup: false,
  supportBulkSelect: false,
  supportDrag: false,
  supportExpand: false,
};

// Grid and the other components with names starting with Grid are responsible
// for displaying the Grid contents. State is managed in the DataGrid
// components.
function Grid (props) {
  const {
    children,
    freezeFirstColumnGroup,
    supportBulkSelect,
    supportDrag,
    supportExpand,
  } = props;

  return (
    <GridProvider
      freezeFirstColumnGroup={freezeFirstColumnGroup}
      supportBulkSelect={supportBulkSelect}
      supportDrag={supportDrag}
      supportExpand={supportExpand}
    >
      <Wrapper>{children}</Wrapper>
    </GridProvider>
  );
}

const Wrapper = styled.div((props) => [
  tw`
    text-sm
    relative
  `,
  css`
    width: min-content;
  `,
]);

export default Grid;
