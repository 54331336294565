import React from 'react';
import PropTypes from 'prop-types';
import Pill from 'components/Pill';
import {ITEM_TYPE} from 'am-constants';
import getFriendlyDate from 'utils/getFriendlyDate';

ContentDeadlineCellContent.propTypes = {
  itemId: PropTypes.number,
  itemTypeId: PropTypes.number.isRequired,
  contentDeadline: PropTypes.string,
};

ContentDeadlineCellContent.defaultProps = {
};

const PAGE_ITEM_TYPES = [
  ITEM_TYPE.CHECKLIST,
  ITEM_TYPE.DOCUMENT,
  ITEM_TYPE.FORM,
];

function ContentDeadlineCellContent (props) {
  const {
    itemId,
    itemTypeId,
    contentDeadline,
  } = props;

  if (!itemId) {
    return null;
  }

  if (!PAGE_ITEM_TYPES.includes(itemTypeId)) {
    return null;
  }

  if (!contentDeadline) {
    return null;
  }

  const hasDeadlinePassed = parseInt(contentDeadline, 10) < Date.now();

  return <Pill red={hasDeadlinePassed}>{getFriendlyDate(contentDeadline, false)}</Pill>;
}

export default ContentDeadlineCellContent;
