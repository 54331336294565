import React from 'react';
import PropTypes from 'prop-types';
import {ITEM_TYPE} from 'am-constants';
import Avatar from 'components/Avatar';
import getInitials from 'utils/getInitials';
import AvatarList from 'components/AvatarList';

ContentDocumenterCellContent.propTypes = {
  itemId: PropTypes.number,
  itemTypeId: PropTypes.number.isRequired,
  documenters: PropTypes.arrayOf(PropTypes.shape({})),
};

ContentDocumenterCellContent.defaultProps = {
};

const PAGE_ITEM_TYPES = [
  ITEM_TYPE.CHECKLIST,
  ITEM_TYPE.DOCUMENT,
  ITEM_TYPE.FORM,
];

function ContentDocumenterCellContent (props) {
  const {
    itemId,
    itemTypeId,
    documenters,
  } = props;

  if (!itemId) {
    return null;
  }

  if (!PAGE_ITEM_TYPES.includes(itemTypeId)) {
    return null;
  }

  return (
    <AvatarList>
      {documenters.map((documenter) => (
        <Avatar key={documenter.id}>
          {getInitials(documenter.name)}
        </Avatar>
      ))}
    </AvatarList>
  );
}

export default ContentDocumenterCellContent;
