import {gql} from '@apollo/client';
import useMutation from 'hooks/useMutation';
import {useState} from 'react';

const REQUEST_PASSWORD_RESET = gql`
  mutation userRequestPasswordReset($email: String!) {
    userRequestPasswordReset(email: $email)
  }
`;

function useRequestPasswordResetMutation (props) {
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [requestPasswordReset, {
    loading,
  }] = useMutation(REQUEST_PASSWORD_RESET, {
    fetchPolicy: 'no-cache',
    onError (e) {
      setError(e);
      setData(undefined);
    },
    onCompleted (result) {
      setError(undefined);
      setData(result);
    },
  });

  return [
    requestPasswordReset,
    {
      data,
      loading,
      error,
      reset () {
        setData(undefined);
        setError(undefined);
      },
    },
  ];
}

export default useRequestPasswordResetMutation;
