import React from 'react';
import PropTypes from 'prop-types';
import LI from 'components/LI';
import UL from 'components/UL';
// eslint-disable-next-line
import tw from 'twin.macro';

ListItem.propTypes = {
  attributes: PropTypes.shape({}).isRequired,
  element: PropTypes.shape({
    indent: PropTypes.number,
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

function ListItem (props) {
  const {
    children,
    element: {indent},
  } = props;

  // The padding of the UL and LI elements is adjusted to better align the
  // bullets with checkboxes.
  return (
    <UL tw="pl-5 mb-0" indent={indent}>
      <LI tw="pl-1">
        {children}
      </LI>
    </UL>
  );
}

export default ListItem;
