import {Editor, Transforms} from 'slate';
import makeParagraph from '../factory/makeParagraph';

function onBackspaceAtStartOfLabel (editor, event, selectionMeta) {
  const {
    isSelectionAtStartOfElement,
    selectionStart,
    selectionStartElement,
    selectionStartElementPath,
    selectionStartTextNode,
  } = selectionMeta;

  if (
    event.key !== 'Backspace' ||
    !selectionStart ||
    selectionStartElement.type !== 'label' ||
    !isSelectionAtStartOfElement
  ) {
    return false;
  }

  event.preventDefault();

  if (selectionStartTextNode.text.trim() !== '') {
    // Do nothing if the label isn't empty
    return true;
  }

  const [parentElement, parentElementPath] = Editor.parent(editor, selectionStartElementPath);
  if (parentElement.type !== 'form-group') {
    throw new Error('Expected the parent element of a label element to have type form-group');
  }

  Transforms.removeNodes(editor, {
    at: parentElementPath,
  });

  Transforms.insertNodes(editor, makeParagraph(), {
    at: parentElementPath,
  });

  Transforms.select(editor, parentElementPath);

  return true;
}

export default onBackspaceAtStartOfLabel;
