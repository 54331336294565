import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled} from 'twin.macro';
import {AiOutlineRight} from 'react-icons/ai';
import Placeholder from '../../Placeholder';

ProcessTitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  mode: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  printMode: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

function ProcessTitle (props) {
  const {
    children,
    mode,
    open,
    printMode,
    setOpen,
  } = props;

  const toggle = () => setOpen(!open);

  return (
    <Title>
      <div data-test-id="process_title" tw="flex-1">
        <Placeholder text={printMode ? '' : 'Optional title'}>
          {children}
        </Placeholder>
      </div>
      {!printMode &&
        <Toggler
          onClick={() => mode === 'edit' && toggle()}
          open={open}
          contentEditable={false}
          data-test-id="process-toggler">
          <AiOutlineRight />
        </Toggler>
      }
    </Title>
  );
}

const Title = styled.h4((props) => [
  tw`
    relative
    my-2.5
    mx-4
    text-gray-700
    text-lg
    flex
    items-center
  `,
]);

const Toggler = styled.div((props) => [
  tw`
    absolute
    right--6
    top--2.5
    w-16
    h-16
    my-1
    items-center
    justify-center
    flex
    text-right
    cursor-pointer
    transition
    duration-300
    text-gray-400
    text-lg
  `,
  props.open && tw`
    transform
    rotate-90
    scale-75
    translate-y--3
  `,
]);

export default ProcessTitle;
