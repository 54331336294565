import PropTypes from 'prop-types';
import {gql} from '@apollo/client';
import useMutation from 'hooks/useMutation';

const RESPONSE_SUBMIT = gql`
  mutation RESPONSE_SUBMIT($documentVersion: Int!, $responseRef: String!, $responseKey: String!) {
    responseSubmit(
      documentVersion: $documentVersion,
      responseRef: $responseRef,
      responseKey: $responseKey
    ) {
      id
      createdAt
    }
  }
`;

useResponseSubmitMutation.propTypes = {
  documentVersion: PropTypes.number.isRequired,
  responseRef: PropTypes.string.isRequired,
  responseKey: PropTypes.string.isRequired,
  onSubmitted: PropTypes.func.isRequired,
};

function useResponseSubmitMutation (props) {
  const {
    documentVersion,
    responseRef,
    responseKey,
    onSubmitted,
  } = props;

  const [onSubmit, {loading, error, data}] = useMutation(RESPONSE_SUBMIT, {
    variables: {
      documentVersion,
      responseRef,
      responseKey,
    },
    onCompleted: onSubmitted,
  });

  return [onSubmit, {
    submitting: loading,
    submitted: !!data,
    submitError: error,
    responseSubmission: data ? data.responseSubmit : undefined,
  }];
}

export default useResponseSubmitMutation;
