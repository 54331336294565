import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled, css} from 'twin.macro';

GridCellGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  first: PropTypes.bool,
};

GridCellGroup.defaultProps = {
  first: false,
};

function GridCellGroup (props) {
  const {
    children,
    first,
  } = props;

  const renderChild = (child, i) => child !== null && React.cloneElement(child, first && i === 0 ? {first: true} : {});

  return (
    <Wrapper first={first}>
      {React.Children.map(children, renderChild)}
    </Wrapper>
  );
}

const Wrapper = styled.div((props) => [
  tw`
    relative
    flex
    h-full
    gap-px
  `,
  // 15px rather than mr-4 as the row will an additional 1px gap between groups.
  css`
    margin-left: 15px;
  `,
  props.first && tw`
    ml-0
  `,
]);

export default GridCellGroup;
