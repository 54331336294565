import React from 'react';
import PropTypes from 'prop-types';
import Button, {ButtonSpacer} from 'components/Button';
import Modal from 'components/Modal';
import Input from 'components/Input';
import FormGroup from 'components/FormGroup';
import Label from 'components/Label';
import Select, {Option} from 'components/Select';
import FlexSpacer from 'components/FlexSpacer';

TeamMemberEditorModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  handleRoleChange: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  isLastAdmin: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  removeTeamMember: PropTypes.func.isRequired,
  renderError: PropTypes.func,
  role: PropTypes.number.isRequired,
};

function TeamMemberEditorModal (props) {
  const {
    closeModal,
    email,
    handleRoleChange,
    isVisible,
    isLastAdmin,
    onSubmit,
    removeTeamMember,
    renderError,
    role,
  } = props;

  return (
    <Modal
      data-test-id='team-member-editor-modal'
      errorTitle={'Could not edit user'}
      footer={<>
        <Button type="button" disabled={isLastAdmin} link noPadding onClick={removeTeamMember}>Remove team member</Button>
        <FlexSpacer />
        <Button type="button" link onClick={closeModal}>Cancel</Button>
        <ButtonSpacer />
        <Button type="submit" primary value="Submit" data-test-id="add_member_button">Update</Button>
      </>}
      onClose={closeModal}
      onSubmit={onSubmit}
      renderError={renderError}
      title="Edit team member"
      visible={isVisible}
    >
      <FormGroup>
        <Label>Email address</Label>
        <Input
          data-test-id="email-input"
          disabled
          required
          type='email'
          value={email}
        />
      </FormGroup>
      <FormGroup>
        <Label id="role-select">Role</Label>
        <Select
          onChange={handleRoleChange}
          value={role}
          ariaLabelledBy="role-select"
        >
          <Option value={2} disabled={isLastAdmin}>Member</Option>
          <Option value={1}>Admin</Option>
        </Select>

      </FormGroup>
    </Modal>
  );
}

export default TeamMemberEditorModal;
