import tw, {styled} from 'twin.macro';

const Table = styled.table((props) => [
  tw`
    border
    border-gray-100
    w-full
    text-sm
  `,
]);

export const TableRow = styled.tr((props) => [
  tw`

  `,
  props.onClick && tw`
    hover:bg-blue-50
    cursor-pointer
  `,
]);

export const TableCell = styled.td((props) => [
  tw`
    border
    border-gray-100
    py-2
    px-3
  `,
]);

export const TableHeaderCell = styled(TableCell)((props) => [
  tw`
    font-bold
    bg-gray-50
    text-xs
    pb-1.5
    leading-6
    px-3
  `,
]);

export default Table;
