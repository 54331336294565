import tw, {styled} from 'twin.macro';

const Heading2 = styled.h3((props) => [
  tw`
    text-3xl
    text-gray-800
    mt-6
    mb-2
    clear-both
  `,
]);

export default Heading2;
