import {Editor, Path, Transforms} from 'slate';
import makeParagraph from '../factory/makeParagraph';

// If a user presses enter in the last choice and it's empty, then it should
// replace that choice with an empty paragraph below the form-group.
function onEnterInEmptyLastChoice (editor, event, selectionMeta) {
  const {
    selectionStart,
    selectionStartElement,
    selectionStartElementPath,
    selectionStartTextNode,
    isSelectionAtStartOfElement,
  } = selectionMeta;

  if (
    event.key !== 'Enter' ||
    !selectionStart ||
    selectionStartElement.type !== 'choice' ||
    selectionStartTextNode.text.trim().length !== 0 ||
    !isSelectionAtStartOfElement ||
    // A choice group should always have at least 2 choices, so we don't want to
    // remove one of the first two.
    selectionStartElementPath[selectionStartElementPath.length - 1] < 2
  ) {
    return false;
  }

  const hasNext = !!Editor.next(editor, {at: selectionStartElementPath});
  if (hasNext) {
    return false;
  }

  event.preventDefault();

  Transforms.removeNodes(editor, {at: selectionStartElementPath});

  Transforms.insertNodes(editor, makeParagraph(), {
    at: Path.next(Path.parent(Path.parent(selectionStartElementPath))),
  });

  Transforms.move(editor, {
    distance: 1,
    unit: 'line',
  });

  return true;
}

export default onEnterInEmptyLastChoice;
