import {Transforms} from 'slate';
import wordFilter from 'tinymce-word-paste-filter';
import makeParagraph from '../factory/makeParagraph';
import makeLink from '../factory/makeLink';
import makeHeading1 from '../factory/makeHeading1';
import makeHeading2 from '../factory/makeHeading2';
import makeHeading3 from '../factory/makeHeading3';
import makeHeading4 from '../factory/makeHeading4';
import makeCodeBlock from '../factory/makeCodeBlock';
import makeOrderedListItem from '../factory/makeOrderedListItem';
import makeListItem from '../factory/makeListItem';

const NODE_TYPE = {
  ELEMENT: 1,
  TEXT: 3,
};

const MAX_INDENT_LEVEL = 8;

const makeNode = {
  CODE: makeCodeBlock,
  H1: makeHeading1,
  H2: makeHeading2,
  H3: makeHeading3,
  H4: makeHeading4,
  H5: makeParagraph,
  H6: makeParagraph,
  LI: makeListItem,
  OL_LI: makeOrderedListItem,
  P: makeParagraph,
  PRE: makeCodeBlock,
};

let elId = 0;

function insertHtml (editor, data, selectionMeta) {
  const html = data.getData('text/html');

  if (
    !html ||
    data.types.includes('vscode-editor-data')
  ) {
    return false;
  }

  const cleanedHtml = wordFilter(html);

  insertHtmlString(editor, cleanedHtml, selectionMeta);

  return true;
}

export function insertHtmlString (editor, html, selectionMeta) {
  const parsed = new DOMParser().parseFromString(html, 'text/html');
  const fragment = deserializeHtml(parsed.body);

  const {
    selectionStart,
  } = selectionMeta;

  if (selectionStart.offset !== 0) {
    fragment.unshift(makeParagraph());
  }

  Transforms.insertFragment(editor, fragment);
}

// Takes HTML and returns a valid AirManual doc.
//
// Supports elements:
// - code-block
// - heading1
// - heading2
// - heading3
// - heading4
// - link
// - list-item
// - ordered-list-item
// - paragraph
//
// Supports textStyles:
// - bold
// - italic
// - strikethrough
// - underline
//
// Not yet supported:
// - checkbox
// - image
// - table
// - table-row
// - table-cell
// - video
//
// Not yet supported by AirManual editor:
// - Inline code blocks
// - Tables
function deserializeHtml (html) {
  const fragments = [];
  let lastElementId;
  let previousElementType;

  deserialize(html);

  return fragments;

  function deserialize (element, meta = {
    elementId: 0,
    elementType: 'P',
    indent: undefined,
    inOrderedList: false,
    link: undefined,
    textStyles: {},
  }) {
    const {nodeType} = element;

    if (nodeType === NODE_TYPE.TEXT) {
      handleTextNode(element, meta);
      return;
    }

    if (nodeType === NODE_TYPE.ELEMENT) {
      handleElement(element, meta);
    }
  }

  function handleElement (element, meta) {
    const {
      childNodes,
      nodeName,
    } = element;
    let {
      elementId,
      elementType,
      indent,
      inOrderedList,
      link,
    } = meta;
    const textStyles = {...meta.textStyles};

    if (nodeName === 'A') {
      link = element.getAttribute('href');
      if (element.style.display === 'block') {
        elementId = elId++;
        elementType = 'P';
      }
    }
    else if (['H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'P'].includes(nodeName)) {
      elementId = elId++;
      elementType = nodeName;
    }
    else if (['PRE'].includes(nodeName)) {
      elementId = elId++;
      elementType = nodeName;
    }
    else if (nodeName === 'OL') {
      inOrderedList = true;
      indent = indent === undefined ? 0 : Math.min(indent + 1, MAX_INDENT_LEVEL);
    }
    else if (nodeName === 'UL') {
      inOrderedList = false;
      indent = indent === undefined ? 0 : Math.min(indent + 1, MAX_INDENT_LEVEL);
    }
    else if (nodeName === 'LI') {
      elementId = elId++;
      elementType = inOrderedList ? 'OL_LI' : 'LI';
    }
    else if (
      nodeName === 'DIV' ||
      element.style.display === 'block'
    ) {
      elementId = elId++;
      elementType = 'P';
    }

    if (
      // Don't also support the <B> tag as Google Docs uses it in weird ways.
      nodeName === 'STRONG' ||
      element.style.bold
    ) {
      textStyles.bold = true;
    }
    if (
      ['I', 'EM'].includes(nodeName) ||
      element.style.italic
    ) {
      textStyles.italic = true;
    }
    if (
      nodeName === 'U' ||
      element.style.underline
    ) {
      textStyles.underline = true;
    }
    if (
      ['S', 'STRIKE', 'DEL'].includes(nodeName) ||
      element.style.strikeThrough
    ) {
      textStyles.strikethrough = true;
    }

    Array.from(childNodes)
      .forEach((child) => deserialize(child, {
        elementType,
        elementId,
        indent,
        inOrderedList,
        link,
        textStyles,
      }));
  }

  function handleTextNode (element, meta) {
    const {
      elementId,
      elementType,
      indent,
      link,
      textStyles,
    } = meta;
    const {textContent} = element;
    const text = elementType === 'PRE' ? textContent.trim() : textContent.replaceAll('\n', ' ').trim();

    if (!text.length) {
      return;
    }

    if (elementId === lastElementId) {
      if (link) {
        fragments[fragments.length - 1].children.push(makeLink({text, textStyles, url: link}));
      }
      else {
        fragments[fragments.length - 1].children.push({text, ...textStyles});
      }

      return;
    }

    const indentArgs = {};
    if (
      ['LI', 'P', 'OL_LI'].includes(elementType) &&
      indent !== undefined
    ) {
      indentArgs.indent = indent;
    }

    // Add a line break after certain elements
    if (
      ['P', 'PRE'].includes(previousElementType) ||
      (['LI', 'OL_LI'].includes(previousElementType) && !['LI', 'OL_LI'].includes(elementType))
    ) {
      fragments.push(makeParagraph());
    }

    if (link) {
      fragments.push(makeNode[elementType]({...indentArgs}));
      fragments[fragments.length - 1].children.push(makeLink({text, textStyles, url: link}));
    }
    else {
      fragments.push(makeNode[elementType]({text, textStyles, ...indentArgs}));
    }

    lastElementId = elementId;
    previousElementType = elementType;
  }
}

export default insertHtml;
