import PropTypes from 'prop-types';
import {useQuery} from '@tanstack/react-query';
import {gql} from 'graphql-request';
import graphQLClient from 'utils/graphQLClient';

const query = gql`
  query GetFolderDetailsForMoving($workspaceUrlName: String!) {
    folderDetailsForMoving(workspaceUrlName: $workspaceUrlName) {
      id
      parentId
      itemTypeId
      name
      rank
      canMoveHere
    }
  }
`;

useFolderDetailsForMovingQuery.propTypes = {
  workspaceUrlName: PropTypes.string,
};

function useFolderDetailsForMovingQuery (props) {
  const {workspaceUrlName} = props;

  const cacheKey = [workspaceUrlName, 'folderDetailsForMoving'];

  const variables = {
    workspaceUrlName,
  };

  const queryFn = async () => {
    const {folderDetailsForMoving} = await graphQLClient.request(
      query,
      variables
    );

    return folderDetailsForMoving;
  };

  const options = {};

  return useQuery(cacheKey, queryFn, options);
}

export default useFolderDetailsForMovingQuery;
