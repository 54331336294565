import tw, {styled} from 'twin.macro';

const SimpleFormHeading = styled.h1((props) => [
  tw`
    text-2xl
    font-medium
    mb-5
    text-gray-800
  `,
]);

export default SimpleFormHeading;
