import React from 'react';
import PropTypes from 'prop-types';
import Document from 'containers/Document';
import {useItem} from 'providers/ItemProvider';
import DocumentViewerPage from './DocumentViewerPage';
import DocumentViewerErrorBoundary from './DocumentViewerErrorBoundary';

DocumentViewer.propTypes = {
  content: PropTypes.array.isRequired,
  printMode: PropTypes.bool.isRequired,
  workspaceUrlName: PropTypes.string,
};

function DocumentViewer (props) {
  const {
    content,
    printMode,
    workspaceUrlName,
  } = props;

  const {item} = useItem();
  const {ref: itemRef, key: itemKey} = item;

  return (
    <DocumentViewerPage
      printMode={printMode}
      workspaceUrlName={workspaceUrlName}>
      <DocumentViewerErrorBoundary itemRef={itemRef} itemKey={itemKey}>
        <Document
          content={content}
          mode='view'
          readOnly
        />
      </DocumentViewerErrorBoundary>
    </DocumentViewerPage>
  );
}

export default DocumentViewer;
