import React, {useEffect, useReducer} from 'react';
import PropTypes from 'prop-types';
import submissionStateReducer from 'containers/DocumentForm/reducers/submissionStateReducer';
import {STATUS_CODE} from 'am-constants';
import getApolloErrorStatusCode from 'utils/getApolloErrorStatusCode';

const SubmissionContext = React.createContext();

DocumentFormProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  documentVersion: PropTypes.number.isRequired,
  initialState: PropTypes.shape({}).isRequired,
  onDocumentVersionError: PropTypes.func.isRequired,
  responseKey: PropTypes.string.isRequired,
  responseRef: PropTypes.string.isRequired,
  submitted: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  submitError: PropTypes.shape({}),
};

DocumentFormProvider.defaultProps = {
};

// We separate out text input state from DocumentProvider so that any updates
// don't cause a rerender of the main SlateEditor, which causes issues when
// typing into the text inputs.
function DocumentFormProvider (props) {
  const {
    children,
    documentVersion,
    initialState,
    onDocumentVersionError,
    responseKey,
    responseRef,
    submitted,
    submitting,
    submitError,
  } = props;

  const [inputs, dispatch] = useReducer(submissionStateReducer, initialState);

  const errors = Object.values(inputs)
    .filter((input) => !!input.error)
    .map((input) => input.error);

  const hasUpdateErrors = errors.length > 0;

  const hasDocumentVersionError = errors.some((err) => {
    const errStatusCode = getApolloErrorStatusCode(err);
    return errStatusCode === STATUS_CODE.COULD_NOT_PROCESS;
  });

  const updating = Object.values(inputs).some((input) => input.updating);
  const readOnly = submitted || submitting;

  // Warn users before they close the window that changes may not be saved.
  // An empty string is used because modern browsers do not allow a customized
  // message.
  window.onbeforeunload = !hasDocumentVersionError && (submitting || updating) ? () => '' : undefined;

  useEffect(() => {
    if (hasDocumentVersionError) {
      onDocumentVersionError();
    }
  }, [hasDocumentVersionError]);

  const context = {
    dispatch,
    documentVersion,
    hasUpdateErrors,
    inputs,
    readOnly,
    responseKey,
    responseRef,
    submitted,
    submitting,
    submitError,
    updating,
  };

  return <>
    <SubmissionContext.Provider value={context}>
      {children}
    </SubmissionContext.Provider>
  </>;
}

export function useDocumentForm () {
  const context = React.useContext(SubmissionContext);

  // if (context === undefined) {
  //   throw new Error('useDocumentForm must be used within a DocumentFormProvider');
  // }

  return context;
}

export default DocumentFormProvider;
