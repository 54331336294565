import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'components/Checkbox';
import tw, {styled, css} from 'twin.macro';

GridRowBulkSelector.propTypes = {
  selected: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

GridRowBulkSelector.defaultProps = {
};

function GridRowBulkSelector (props) {
  const {
    selected,
    onChange,
  } = props;

  return (
    <Wrapper
      selected={selected}>
      <Checkbox
        lightBorder
        noMargin
        onChange={onChange}
        selected={selected} />
    </Wrapper>
  );
}

const Wrapper = styled.div((props) => [
  tw`
    flex-none
    w-6
    bg-gray-50
    flex
    items-center
    opacity-0
  `,
  css`
    // Expanding 1px out of the top and bottom covers up the hovered row's
    // background color (when some cells are behind a frozen
    // GridRowExpanderSpacer).
    height: calc(100% + 2px);
    margin-top: -1px;
  `,
  // TODO: Currently the checkbox is visible when this row is selected. The
  // desired behavior is that the checkbox is visible when _any_ row is
  // selected.
  props.selected && tw`
    opacity-100
  `,
  // This is implemented in CSS as group-hover logic in tailwind/twin doesn't
  // play nice with prop conditions.
  !props.selected && css`
    .grid-row__hoverable:hover && {
      opacity: 100%;
    }
  `,
]);

export default GridRowBulkSelector;
