import React from 'react';
import PropTypes from 'prop-types';
import GridCellPopover from 'components/DataGrid/GridCellPopover';
import ContentDeadlineCellPopover from './ContentDeadlineCellPopover';
import SavingCellContent from './SavingCellContent';
import ContentDeadlineCellContent from './ContentDeadlineCellContent';

ContentDeadlineCell.propTypes = {
  itemId: PropTypes.number,
  itemTypeId: PropTypes.number.isRequired,
  contentDeadline: PropTypes.string,
  onUpdateItem: PropTypes.func.isRequired,
  saving: PropTypes.bool,
};

ContentDeadlineCell.defaultProps = {
};

function ContentDeadlineCell (props) {
  const {
    itemId,
    itemTypeId,
    contentDeadline,
    onUpdateItem,
    saving,
  } = props;

  const popover = (
    <ContentDeadlineCellPopover
      contentDeadline={contentDeadline}
      onUpdateItem={onUpdateItem} />
  );

  return (
    <GridCellPopover content={popover}>
      <SavingCellContent saving={saving}>
        <ContentDeadlineCellContent
          itemId={itemId}
          itemTypeId={itemTypeId}
          contentDeadline={contentDeadline}
        />
      </SavingCellContent>
    </GridCellPopover>
  );
}

export default ContentDeadlineCell;
