import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import groupBy from 'utils/groupBy';
import useURLSearchParam from 'hooks/useURLSearchParam';
import {ITEM_TYPE} from 'am-constants';
import tw, {styled, css} from 'twin.macro';
import throttle from 'lodash.throttle';
import getPreparedItems from './utils/getPreparedItems';
import Grid from './Grid';
import DataGridHeader from './DataGridHeader';
import DataGridGroupHeader from './DataGridGroupHeader';
import DataGridRow from './DataGridRow';
import DataGridRowAdder from './DataGridRowAdder';
import getValidDropIndexesForSections from './utils/getValidDropIndexesForSections';
import getValidDropIndexesForNonSections from './utils/getValidDropIndexesForNonSections';
import getClosestValidDropIndex from './utils/getClosestValidDropIndex';
import getNewLocationForNonSection from './utils/getNewLocationForNonSection';
import getNewLocationForSection from './utils/getNewLocationForSection';
import DataGridRowLoader from './DataGridRowLoader';

DataGrid.propTypes = {
  columnGroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  freezeFirstColumnGroup: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    parentId: PropTypes.number,
    rank: PropTypes.string,
  })).isRequired,
  onAddItem: PropTypes.func.isRequired,
  onExpandOrCollapse: PropTypes.func,
  onMoveItem: PropTypes.func.isRequired,
  parentItemId: PropTypes.number.isRequired,
  supportAddItems: PropTypes.bool,
  supportBulkSelect: PropTypes.bool,
  supportDrag: PropTypes.bool,
};

DataGrid.defaultProps = {
  freezeFirstColumnGroup: false,
  onExpandOrCollapse: () => {},
  supportAddItems: false,
  supportBulkSelect: false,
  supportDrag: false,
};

function DataGrid (props) {
  const {
    columnGroups,
    freezeFirstColumnGroup,
    items,
    onAddItem,
    onExpandOrCollapse,
    onMoveItem,
    parentItemId,
    supportAddItems,
    supportBulkSelect,
    supportDrag,
  } = props;

  const expandedUrlParamValue = useURLSearchParam('expanded');
  const initialExpandedItemIds = expandedUrlParamValue
    ? expandedUrlParamValue.split('-').map((id) => parseInt(id, 10))
    : [];

  const itemsByParentId = useMemo(() => groupBy(items, 'parentId'), [items]);
  const allExpandableItemIds = useMemo(
    () =>
      new Set(items.filter((item) => item.expandable).map((item) => item.id)),
    [items]
  );
  const allItemIds = useMemo(
    () => new Set(items.map((item) => items.id)),
    [items]
  );
  const [expandedItemIds, setExpandedItemIds] = useState(new Set(initialExpandedItemIds));
  const [selectedItemIds, setSelectedItemIds] = useState(new Set());

  const preparedItems = useMemo(
    () =>
      getPreparedItems({
        itemsByParentId,
        items,
        expandedItemIds,
        parentId: parentItemId,
        selectedItemIds,
        supportAddItems,
      }),
    [items, expandedItemIds, parentItemId, selectedItemIds]
  );

  const validDropIndexesForSections = useMemo(
    () => getValidDropIndexesForSections(preparedItems),
    [preparedItems]
  );
  const validDropIndexesForNonSections = useMemo(
    () => getValidDropIndexesForNonSections(preparedItems),
    [preparedItems]
  );

  const supportExpand = !!items.find((i) => i.itemTypeId === ITEM_TYPE.FOLDER);

  const onCollapseItem = useCallback(
    (itemId) => {
      const newExpandedItemIds = new Set(expandedItemIds);
      newExpandedItemIds.delete(itemId);
      setExpandedItemIds(newExpandedItemIds);
    },
    [expandedItemIds]
  );

  const onExpandItem = useCallback(
    (itemId) => {
      const newExpandedItemIds = new Set(expandedItemIds);
      newExpandedItemIds.add(itemId);
      setExpandedItemIds(newExpandedItemIds);
    },
    [expandedItemIds]
  );

  const onDeselectItem = useCallback(
    (itemId) => {
      const newSelectedItemIds = new Set(selectedItemIds);
      newSelectedItemIds.delete(itemId);
      setSelectedItemIds(newSelectedItemIds);
    },
    [selectedItemIds]
  );

  const onSelectItem = useCallback(
    (itemId) => {
      const newSelectedItemIds = new Set(selectedItemIds);
      newSelectedItemIds.add(itemId);
      setSelectedItemIds(newSelectedItemIds);
    },
    [selectedItemIds]
  );

  const onCollapseAll = useCallback(() => {
    setExpandedItemIds(new Set());
  }, []);

  const onExpandAll = useCallback(() => {
    setExpandedItemIds(allExpandableItemIds);
  }, [allExpandableItemIds]);

  const onDeselectAll = useCallback(() => {
    setSelectedItemIds(new Set());
  }, []);

  const onSelectAll = useCallback(() => {
    setSelectedItemIds(allItemIds);
  }, [allItemIds]);

  const onDragStart = useCallback(({item}) => {
    if (item.itemTypeId === ITEM_TYPE.FOLDER && item.expanded) {
      onCollapseItem(item.id);
    }

    if (item.itemTypeId === ITEM_TYPE.SECTION) {
      if (itemsByParentId[item.id]) {
        itemsByParentId[item.id]
          .map(({id}) => preparedItems.find((preparedItem) => preparedItem.id === id))
          .filter((i) => i.itemTypeId === ITEM_TYPE.FOLDER && i.expanded)
          .forEach(({id}) => onCollapseItem(id));
      }
    }
  }, [preparedItems, expandedItemIds]);

  const [hoverDropIndex, setHoverDropIndex] = useState(undefined);
  const throttledSetHoverDropIndex = throttle(setHoverDropIndex, 300);

  const onDragHover = useCallback(
    ({
      draggedItem, hoverIndex, dragDirection,
    }) => {
      const index = dragDirection === 'above' ? hoverIndex : hoverIndex + 1;
      const validDropIndexes = draggedItem.itemTypeId === ITEM_TYPE.SECTION
        ? validDropIndexesForSections
        : validDropIndexesForNonSections;
      const validDropIndex = getClosestValidDropIndex(index, validDropIndexes);

      throttledSetHoverDropIndex(validDropIndex);
    },
    [validDropIndexesForSections, validDropIndexesForNonSections]
  );

  const onDrop = useCallback(
    ({
      draggedItem, droppedIndex, dragDirection,
    }) => {
      const draggedIndex = preparedItems.findIndex((item) => item.id === draggedItem.id);

      const index = dragDirection === 'above' ? droppedIndex : droppedIndex + 1;
      const validDropIndexes = draggedItem.itemTypeId === ITEM_TYPE.SECTION
        ? validDropIndexesForSections
        : validDropIndexesForNonSections;
      const validDropIndex = getClosestValidDropIndex(index, validDropIndexes);

      const indexOfDraggedIndex = validDropIndexes.findIndex((i) => i === draggedIndex);
      const firstValidDropIndexAfterDraggedIndex = indexOfDraggedIndex !== validDropIndexes.length - 1 ? validDropIndexes[indexOfDraggedIndex + 1] : undefined;

      const hasMoved = draggedIndex !== validDropIndex && firstValidDropIndexAfterDraggedIndex !== validDropIndex;

      if (!hasMoved) {
        return;
      }

      const {newRank, newParentId} = draggedItem.itemTypeId === ITEM_TYPE.SECTION
        ? getNewLocationForSection({
          dropIndex: validDropIndex,
          itemsByParentId,
          preparedItems,
          parentItemId,
        })
        : getNewLocationForNonSection({
          draggedIndex,
          draggedItem,
          dropIndex: validDropIndex,
          parentItemId,
          preparedItems,
        });

      onMoveItem({
        ...draggedItem,
        rank: newRank,
        parentId: newParentId,
      });
    },
    [preparedItems, validDropIndexesForSections, validDropIndexesForNonSections]
  );

  const onDragEnd = useCallback(() => {
    setTimeout(() => throttledSetHoverDropIndex(undefined), 300);
  }, []);

  // Note: This will appear not to work in Storybook as components are loaded in
  // an iframe. You can click the button in Storybook to expand the component
  // into its own tab, and then it will work :)
  const updateExpandedParamInUrl = () => {
    const currentValue = useURLSearchParam('expanded');
    const newValue = Array.from(expandedItemIds).join('-');

    if (currentValue !== newValue) {
      const url = new URL(window.location.href);
      url.searchParams.set('expanded', newValue);
      window.history.replaceState(null, null, url.href);
    }
  };

  useEffect(() => {
    updateExpandedParamInUrl();
    onExpandOrCollapse(expandedItemIds);
  }, [expandedItemIds]);

  const isDragging = hoverDropIndex !== undefined;

  return (
    <div className={isDragging ? 'data-grid__dragging' : 'data-grid__not-dragging'}>
      <Grid
        freezeFirstColumnGroup={freezeFirstColumnGroup}
        supportBulkSelect={supportBulkSelect}
        supportDrag={supportDrag}
        supportExpand={supportExpand}>
        <DataGridGroupHeader columnGroups={columnGroups} />
        <DataGridHeader
          columnGroups={columnGroups}
          onCollapseAll={onCollapseAll}
          onExpandAll={onExpandAll}
          onDeselectAll={onDeselectAll}
          onSelectAll={onSelectAll}
          supportExpandAll={false}
        />
        {preparedItems.map((item, index) => (
          <RowContainer
            key={`item-${getKeyForItem(item)}`}>
            {index === hoverDropIndex && (
              <DragTarget />
            )}
            {!item.adder && !item.loader && (
              <DataGridRow
                item={item}
                index={index}
                columnGroups={columnGroups}
                onCollapseItem={onCollapseItem}
                onDragStart={onDragStart}
                onDragHover={onDragHover}
                onDragEnd={onDragEnd}
                onDrop={onDrop}
                onExpandItem={onExpandItem}
                onDeselectItem={onDeselectItem}
                onSelectItem={onSelectItem}
              />
            )}
            {item.adder && (
              <DataGridRowAdder
                columnGroups={columnGroups}
                index={index}
                indentLevel={item.indentLevel}
                folderId={item.folderId}
                sectionId={item.sectionId}
                onAddItem={onAddItem}
                onDragStart={onDragStart}
                onDragHover={onDragHover}
                onDragEnd={onDragEnd}
                onDrop={onDrop}
              />
            )}
            {item.loader && (
              <DataGridRowLoader
                columnGroups={columnGroups}
                index={index}
                indentLevel={item.indentLevel}
              />
            )}
          </RowContainer>
        ))}
      </Grid>
    </div>
  );
}

function getKeyForItem (item) {
  if (item.adder) {
    return `adder-${item.parentId}`;
  }

  if (item.id) {
    return item.id;
  }

  return `new-${item.parentId}-${item.itemIndex}`;
}
const RowContainer = styled.div((props) => [
  tw`
    relative
  `,
]);

const DragTarget = styled.div((props) => [
  tw`
    absolute
    top--px
    w-full
    left-0
    bg-blue-500
    z-50
  `,
  css`
    height: 3px;
  `,
]);

export default DataGrid;
