import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {ITEM_TYPE} from 'am-constants';
import useCreateItemMutation from 'hooks/useCreateItemMutation';
import FolderCreatorModal from './components/FolderCreatorModal';

FolderCreatorContainer.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  setIsVisible: PropTypes.func.isRequired,
  parentItem: PropTypes.shape({
    id: PropTypes.number.isRequired,
    key: PropTypes.string.isRequired,
    ref: PropTypes.string.isRequired,
  }).isRequired,
};

function FolderCreatorContainer (props) {
  const {
    isVisible,
    setIsVisible,
    parentItem,
  } = props;

  const [inputValue, setInputValue] = useState('');
  const closeModal = () => {
    setInputValue('');
    setIsVisible(false);
  };

  const {
    mutate: createFolder,
    // data: createFolderData,
    // isError: isCreateFolderError,
  } = useCreateItemMutation({});

  const onSubmit = (event) => {
    event.preventDefault();
    createFolder({
      name: inputValue,
      itemTypeId: ITEM_TYPE.FOLDER,
      parentFolderId: parentItem.id,
      parentId: parentItem.id,
      parentItemRef: parentItem.ref,
      parentItemKey: parentItem.key,
    });
    setInputValue('');
    setIsVisible(false);
  };

  const onChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <FolderCreatorModal
      isVisible={isVisible}
      onChange={onChange}
      onSubmit={onSubmit}
      inputValue={inputValue}
      closeModal={closeModal}
    />
  );
}

export default FolderCreatorContainer;
