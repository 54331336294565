import PropTypes from 'prop-types';
import {gql} from '@apollo/client';
import useMutation from 'hooks/useMutation';

const RESPONSE_CREATE = gql`
  mutation RESPONSE_CREATE($itemRef: String!, $itemKey: String!) {
    responseCreate(itemRef: $itemRef, itemKey: $itemKey) {
      ref
      key
    }
  }
`;

useCreateResponseMutation.propTypes = {
  itemRef: PropTypes.string.isRequired,
  itemKey: PropTypes.string.isRequired,
};

function useCreateResponseMutation (props) {
  const {
    itemRef,
    itemKey,
  } = props;

  const [onCreateResponse, {loading, data, error}] = useMutation(RESPONSE_CREATE, {
    variables: {
      itemRef,
      itemKey,
    },
  });

  return [onCreateResponse, {
    loading,
    error,
    data,
  }];
}

export default useCreateResponseMutation;
