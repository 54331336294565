export const ITEM_FIELDS = `
  id
  ref
  key
  parentId
  itemTypeId
  name
  emoji
  coverImage
  contentActionNeededId
  contentDescription
  contentPriority
  contentDeadline
  urlName
  details
  pin
  privateSpace
  loggedInUserRoleIds
  public
  hasPublicAncestor
  createdAt
  updatedAt
  publishedAt
  minVersionForTraining
  nextDueAt
  nextDueFrequency
  nextDueInterval
  nextDueDaysOfWeek
  nextDueInstanceOfDayInMonth
  rank
  numPages
`;

export default null;
