import {ENVIRONMENTS} from 'am-constants';

function getEnvironmentName () {
  if (window.location.hostname === 'app.airmanual.co') {
    return ENVIRONMENTS.PRODUCTION;
  }

  if (window.location.hostname === 'app.test.airmanual.co') {
    return ENVIRONMENTS.TEST;
  }

  if (window.location.hostname.includes('localhost')) {
    return ENVIRONMENTS.DEVELOPMENT;
  }

  return 'unknown';
}

export default getEnvironmentName;
