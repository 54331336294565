import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';
import FolderBrowser from 'components/FolderBrowser';
import {ITEM_TYPE, PAGE_ITEM_TYPES, PERMISSIONS} from 'am-constants';
import Loader from 'components/Loader';
import {PageBody} from 'containers/FolderApp/components/FolderLayout';
import {navigate} from '@reach/router';
import useURLSearchParam from 'hooks/useURLSearchParam';
import ItemSidePanel from 'containers/ItemSidePanel';
import useUpdateItemMutation from 'containers/FolderManagerApp/hooks/useUpdateItemMutation';
import getUrlForItem from 'utils/getUrlForItem';
import useItemsQueries from './hooks/useItemsQueries';

FolderBrowserApp.propTypes = {
  folderId: PropTypes.number.isRequired,
};

function FolderBrowserApp (props) {
  const {folderId} = props;
  const {urlName: workspaceUrlName} = useWorkspace();
  const [sidePanelItem, setSidePanelItem] = useState();

  // Load the items for the current folder
  const itemsQueries = useItemsQueries([
    {
      workspaceUrlName,
      folderId,
    },
  ]);
  const items = getItemsFromItemsQueries(itemsQueries);

  // ALSO load the items for subfolders, so items are preloaded when the user
  // opens a folder.
  const subfolders = items.filter((item) => item.itemTypeId === ITEM_TYPE.FOLDER);
  useItemsQueries(subfolders.map(({id}) => ({
    workspaceUrlName,
    folderId: id,
  })));

  const updateItem = useUpdateItemMutation({});

  const {workspacePermissions} = useWorkspace();
  const canOpenSidePanel = workspacePermissions.includes(PERMISSIONS.VIEW_WORKSPACE);
  const isItemsFetching = itemsQueries.some(({isFetching}) => isFetching);
  useEffect(
    () => setSidePanelItemFromUrl({isItemsFetching, items, setSidePanelItem}),
    [isItemsFetching]
  );
  useEffect(() => updateItemParamInUrl(sidePanelItem), [sidePanelItem]);

  const isItemsLoading = itemsQueries.some(({isLoading}) => isLoading);

  useEffect(
    () => setSidePanelItemFromUrl({isItemsLoading, items, setSidePanelItem}),
    [isItemsLoading]
  );
  useEffect(() => updateItemParamInUrl(sidePanelItem), [sidePanelItem]);

  const isItemsInitialLoading = itemsQueries[0].isInitialLoading;

  if (isItemsInitialLoading) {
    return <Loader />;
  }

  const handleItemClick = (i) => {
    if (canOpenSidePanel) {
      setSidePanelItem(sidePanelItem === i
        ? null
        : {
          ...i,
          parentFolderId: getParentFolderId(i),
        });
      return;
    }

    if (PAGE_ITEM_TYPES.includes(i.itemTypeId)) {
      const viewUrl = getUrlForItem(workspaceUrlName, i);
      navigate(viewUrl);
    }
  };

  const handleUpdateItem = (updatedItem) => {
    updateItem.mutate(updatedItem);
  };

  const getParentFolderId = (item) => {
    if (!item) {
      return folderId;
    }

    const parentItem = items.find(({id}) => id === item.parentId);
    if (!parentItem) {
      return folderId;
    }

    if (parentItem.itemTypeId === ITEM_TYPE.SECTION) {
      return parentItem.parentId;
    }

    return parentItem.id;
  };

  return (
    <>
      {canOpenSidePanel && sidePanelItem && (
        <ItemSidePanel
          item={sidePanelItem}
          onClose={() => setSidePanelItem()}
          onUpdateItem={handleUpdateItem}
        />
      )}
      <PageBody>
        <FolderBrowser items={items} onItemClick={handleItemClick} />
      </PageBody>
    </>
  );
}

function getItemsFromItemsQueries (itemsQueries) {
  return itemsQueries
    .map(({data}) => data)
    .flat()
    .filter((item) => item !== undefined);
}

const setSidePanelItemFromUrl = ({
  isItemsFetching,
  items,
  setSidePanelItem,
}) => {
  if (isItemsFetching) {
    return;
  }

  const sidePanelItemUrlParamValue = useURLSearchParam('item');
  if (!sidePanelItemUrlParamValue) {
    return;
  }

  const item = items.find(({id}) => id === parseInt(sidePanelItemUrlParamValue, 10));
  if (!item) {
    return;
  }

  setSidePanelItem(item);
};

const updateItemParamInUrl = (sidePanelItem) => {
  const url = new URL(window.location.href);

  if (sidePanelItem) {
    url.searchParams.set('item', sidePanelItem.id);
    window.history.replaceState(null, null, url.href);
  }
  else {
    url.searchParams.delete('item');
    window.history.replaceState(null, null, url.href);
  }
};

export default FolderBrowserApp;
