const makeTextField = () => ({
  type: 'form-group',
  children: [
    {
      type: 'label',
      children: [{
        text: '',
      }],
    },
    {
      type: 'text-field',
      size: 'multi',
      children: [{
        text: '',
      }],
    },
  ],
});

export default makeTextField;
