import React from 'react';
import PropTypes from 'prop-types';

const PopoverContext = React.createContext();

PopoverProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

function PopoverProvider (props) {
  const {children, ...rest} = props;

  const context = {
    ...rest,
  };

  return (
    <PopoverContext.Provider value={context}>
      {children}
    </PopoverContext.Provider>
  );
}

export function usePopover () {
  const context = React.useContext(PopoverContext);

  if (context === undefined) {
    throw new Error('usePopover must be used within a PopoverProvider');
  }

  return context;
}

export default PopoverProvider;
