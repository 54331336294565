import {Transforms} from 'slate';

const MAX_INDENT_LEVEL = 8;

function onBackspaceAtStartOfIndentedParagraph (editor, event, selectionMeta) {
  const {
    isSelectionAtStartOfElement,
    selectionStart,
    selectionStartElement,
  } = selectionMeta;

  if (
    event.key !== 'Backspace' ||
    !selectionStart ||
    selectionStartElement.type !== 'paragraph' ||
    !isSelectionAtStartOfElement ||
    !selectionStartElement.indent
  ) {
    return false;
  }

  event.preventDefault();

  for (let indentLevel = 1; indentLevel <= MAX_INDENT_LEVEL; indentLevel++) {
    Transforms.setNodes(
      editor, {
        indent: indentLevel - 1,
      },
      {
        match: (n) => n.indent === indentLevel,
      }
    );
  }

  return true;
}

export default onBackspaceAtStartOfIndentedParagraph;
