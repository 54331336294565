import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled} from 'twin.macro';
import {Link} from '@reach/router';

GridCellClickHandler.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  item: PropTypes.shape({}),
  linkTo: PropTypes.string,
  onClick: PropTypes.func,
};

GridCellClickHandler.defaultProps = {
};

function GridCellClickHandler (props) {
  const {
    children,
    item,
    linkTo,
    onClick,
  } = props;

  const handleClick = (e) => {
    if (!onClick) {
      return true;
    }

    if (onClick(item)) {
      return true;
    }

    e.preventDefault();
    return false;
  };

  if (linkTo && linkTo.substr(0, 4) === 'http') {
    return (
      <A
        href={linkTo}
        target='blank'
        onClick={handleClick}>
        {children}
      </A>
    );
  }

  if (linkTo) {
    return (
      <InternalLink
        to={linkTo}
        onClick={handleClick}>
        {children}
      </InternalLink>
    );
  }

  return (
    <Wrapper
      hasOnClick={!!onClick}
      onClick={handleClick}>
      {children}
    </Wrapper>
  );
}

const A = styled.a((props) => [
  tw`
    cursor-pointer
    hover:text-gray-800
  `,
]);

const InternalLink = styled(Link)((props) => [
  tw`
    hover:text-gray-800
  `,
]);

const Wrapper = styled.div((props) => [
  tw`
    h-full
  `,
  props.hasOnClick && tw`
    cursor-pointer
  `,
]);

export default GridCellClickHandler;
