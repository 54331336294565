const addGuidanceToContent = (c) => {
  const isProcess = c.some((node) => node.type === 'process');
  const alreadyHasGuidance = JSON.stringify(c).includes('This checklist was created from a template');
  if (!isProcess || alreadyHasGuidance) {
    return c;
  }

  const firstProcessIndex = c.findIndex((node) => node.type === 'process');
  const allNodesBeforeFirstChecklist = c.slice(0, firstProcessIndex);
  const allNodesAfterFirstChecklistInclusive = c.slice(firstProcessIndex);
  const templateTitle = c[0].children[0].text;
  const hasLinks = JSON.stringify(c).includes('"type":"link"');

  return [
    ...allNodesBeforeFirstChecklist,
    getGuidance({templateTitle, hasLinks}),
    ...allNodesAfterFirstChecklistInclusive,
  ];
};

const getGuidance = ({templateTitle, hasLinks}) => {
  const decapitalizedTitle = templateTitle.charAt(0).toLowerCase() + templateTitle.slice(1);

  return ({
    id: 'YZrr70XgQF2kZSPfex5Z2',
    open: true,
    type: 'help-block',
    color: 'blue',
    children: [
      {
        type: 'help-block-title',
        children: [
          {
            text: 'This checklist was created from a template',
          },
        ],
      },
      {
        type: 'help-block-content',
        children: [
          {
            type: 'paragraph',
            children: [
              {
                text: `This checklist will help your team to ${decapitalizedTitle}.`,
              },
            ],
          },
          ...(hasLinks ? [
            {
              type: 'paragraph',
              children: [
                {
                  text: '',
                },
              ],
            },
            {
              type: 'paragraph',
              children: [
                {
                  text: 'It includes links to other checklists in our example workspace. If you want to use these, then be sure to copy these into your own workspace and update the links.',
                },
              ],
            },
          ] : []),
          {
            type: 'paragraph',
            children: [
              {
                text: '',
              },
            ],
          },
          {
            type: 'paragraph',
            children: [
              {
                text: 'We recommend that you:',
              },
            ],
          },
          {
            type: 'paragraph',
            children: [
              {
                text: '',
              },
            ],
          },
          {
            type: 'ordered-list-item',
            number: 1,
            children: [
              {
                text: 'Review and update the checklist as needed',
              },
            ],
          },
          {
            type: 'ordered-list-item',
            number: 2,
            children: [
              {
                text: 'Delete this help box',
              },
            ],
          },
          {
            type: 'ordered-list-item',
            number: 3,
            children: [
              {
                text: 'Publish the checklist',
              },
            ],
          },
          {
            type: 'ordered-list-item',
            number: 4,
            children: [
              {
                text: 'Test it yourself',
              },
            ],
          },
          {
            type: 'ordered-list-item',
            number: 5,
            children: [
              {
                text: 'Test that a colleague can use it without support',
              },
            ],
          },
          {
            type: 'paragraph',
            children: [
              {
                text: '',
              },
            ],
          },
        ],
      },
    ],
  });
};

module.exports = addGuidanceToContent;
