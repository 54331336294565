import React from 'react';
import PropTypes from 'prop-types';
import {BalloonToolbar} from '@udecode/slate-plugins';
import {
  AiOutlineBold,
  AiOutlineCheckSquare,
  AiOutlineDisconnect,
  AiOutlineHighlight,
  AiOutlineLink,
  AiOutlineStrikethrough,
  AiOutlineUnorderedList,
} from 'react-icons/ai';
import {BiHeading} from 'react-icons/bi';
import tw, {styled, css} from 'twin.macro';
import colors from 'tailwindcss/colors';
import {useSlateStatic} from 'slate-react';
import toggleMark from '../utils/toggleMark';
import ToolbarItem from './ToolbarItem';
import makeHeading1 from '../factory/makeHeading1';
import makeHeading2 from '../factory/makeHeading2';
import makeListItem from '../factory/makeListItem';
import makeCheckbox from '../factory/makeCheckbox';
import toggleSelectedNodes from '../utils/toggleSelectedNodes';
import insertLink from '../utils/insertLink';
import unwrapLink from '../utils/unwrapLink';

TextFormattingToolbar.propTypes = {
  isLinkActive: PropTypes.bool.isRequired,
  showHeadingOptions: PropTypes.bool.isRequired,
  showInputOptions: PropTypes.bool.isRequired,
  showListOptions: PropTypes.bool.isRequired,
};

function TextFormattingToolbar (props) {
  const {
    isLinkActive,
    showHeadingOptions,
    showInputOptions,
    showListOptions,
  } = props;
  const editor = useSlateStatic();

  const menuItems = [
    {
      title: 'Toggle bold',
      onClick: () => toggleMark(editor, 'bold'),
      icon: AiOutlineBold,
    },
    {
      title: 'Toggle strikethrough',
      onClick: () => toggleMark(editor, 'strikethrough'),
      icon: AiOutlineStrikethrough,
    },
    {
      title: 'Toggle highlight',
      onClick: () => toggleMark(editor, 'highlight'),
      icon: AiOutlineHighlight,
    },
    ...(
      isLinkActive ? [{
        title: 'Remove link',
        onClick: () => unwrapLink(editor),
        icon: AiOutlineDisconnect,
      }] : [{
        title: 'Create link',
        onClick: () => insertLink(editor),
        icon: AiOutlineLink,
      }]
    ),
    ...(
      showHeadingOptions ? [
        {
          title: 'Toggle large heading',
          onClick: () => toggleSelectedNodes(editor, {makeNode: makeHeading1}),
          icon: BiHeading,
          iconSize: 18,
        },
        {
          title: 'Toggle heading',
          onClick: () => toggleSelectedNodes(editor, {makeNode: makeHeading2}),
          icon: BiHeading,
          iconSize: 14,
        },
      ] : []
    ),
    ...(
      showListOptions ? [
        {
          title: 'Toggle bulleted list',
          onClick: () => toggleSelectedNodes(editor, {makeNode: makeListItem}),
          icon: AiOutlineUnorderedList,
          iconSize: 18,
        },
      ] : []
    ),
    ...(
      showInputOptions ? [
        {
          title: 'Toggle checkbox list',
          onClick: () => toggleSelectedNodes(editor, {makeNode: makeCheckbox}),
          icon: AiOutlineCheckSquare,
          iconSize: 18,
        },
      ] : []
    ),
  ];

  return (
    <StyledBalloonToolbar arrow>
      {menuItems.map((menuItem, i) => (
        <ToolbarItem
          editor={editor}
          key={`balloon-menu-item-${i}`}
          iconSize={16}
          subtleHover
          {...menuItem} />
      ))}
    </StyledBalloonToolbar>
  );
}

const StyledBalloonToolbar = styled(BalloonToolbar)((props) => [
  tw`
    bg-gray-800
    rounded
    h-11
  `,
  css`
    ::after {
      /* border-color: ${colors.gray[800]} transparent; < This should match bg-gray-800 but annoying it doesn't! */
      border-color: rgba(35.77649999999999,57.88754999999998,81.52350000000001,1) transparent; /* TODO: Replace with color from tailwind */
    }
  `,
]);

export default React.memo(TextFormattingToolbar);
