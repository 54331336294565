import {gql} from '@apollo/client';
import useQuery from 'hooks/useQuery';

const GET_LOGGED_IN_USER = gql`
  query GetUser {
    user {
      name
      email
      id
      isSiteAdmin
      loggedInWithSSOProviderId
      wantsToHearAboutUs
      workspaces {
        urlName
        name
      }
      restrictedWorkspaceUrlNames
    }
  }
`;

function useLoggedInUserQuery (props) {
  const {loading, error, data} = useQuery(GET_LOGGED_IN_USER, {
    fetchPolicy: 'no-cache',
  });

  return {
    user: data && data.user,
    loading,
    error,
  };
}

export default useLoggedInUserQuery;
