import React, {useState} from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-unused-vars
import tw, {styled} from 'twin.macro';
import {WORKSPACE_ROLE} from 'am-constants';
import SettingsPage, {SettingsPageTitleBar} from 'components/SettingsPage';
import Button from 'components/Button';
import P from 'components/P';
import TeamMemberEditor from 'containers/TeamMemberEditor';
import TeamMemberAdder from 'containers/TeamMemberAdder';
import TeamMember from './TeamMember';

TeamMembersPage.propTypes = {
  teamMembers: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    roles: PropTypes.arrayOf(PropTypes.oneOf(Object.values(WORKSPACE_ROLE))).isRequired,
    hasSignedUp: PropTypes.bool.isRequired,
  })),
  addTeamMember: PropTypes.func,
  errorMessage: PropTypes.string,
  addTeamMemberError: PropTypes.object,
};

function TeamMembersPage (props) {
  const {
    teamMembers, errorMessage,
  } = props;

  const [addModalEmail, setAddModalEmail] = useState('');
  const [addModalRole, setAddModalRole] = useState(2);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const resetFields = () => {
    setAddModalEmail('');
    setAddModalRole(WORKSPACE_ROLE.MEMBER);
  };

  const [editModalEmail, setEditModalEmail] = useState('');
  const [editModalRole, setEditModalRole] = useState(2);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  const workspaceAdmins = teamMembers && teamMembers
    .filter((teamMember) => teamMember.roles[0] === WORKSPACE_ROLE.ADMIN);
  const isLastAdmin = editModalRole === WORKSPACE_ROLE.ADMIN && workspaceAdmins.length === 1;

  return (
    <SettingsPage>
      <TeamMemberAdder
        email={addModalEmail}
        handleEmailChange={(event) => setAddModalEmail(event.target.value)}
        handleRoleChange={(value) => setAddModalRole(value)}
        isVisible={isAddModalVisible}
        role={addModalRole}
        resetFields={resetFields}
        setIsVisible={setIsAddModalVisible}
      />

      <TeamMemberEditor
        email={editModalEmail}
        handleRoleChange={setEditModalRole}
        isVisible={isEditModalVisible}
        isLastAdmin={isLastAdmin}
        role={editModalRole}
        setIsVisible={setIsEditModalVisible}
      />

      <SettingsPageTitleBar title="Team members">
        {!errorMessage && <Button green tw="float-right" onClick={() => setIsAddModalVisible(true)}>Add member</Button>}
      </SettingsPageTitleBar>

      <TeamMembersList>
        {!errorMessage
          ? teamMembers.map((teamMember) =>
            <TeamMember
              key={teamMember.email}
              openEditModal={() => setIsEditModalVisible(true)}
              setEditModalRole={setEditModalRole}
              setEditModalEmail={setEditModalEmail}
              {...teamMember} />)
          : <P>{errorMessage}</P>
        }
      </TeamMembersList>
    </SettingsPage>
  );
}

const TeamMembersList = styled.div.attrs({
  'data-test-id': 'team-members-list',
})((props) => [
  tw`mt-8`,
]);

export default TeamMembersPage;
