import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {ITEM_TYPE} from 'am-constants';
import DataGrid from 'components/DataGrid';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';
import Truncate from 'components/Truncate';
import isEqual from 'lodash.isequal';
import getNameForItem from 'utils/getNameForItem';
import ContentActionNeededCell from './ContentActionNeededCell';
import NextDueCell from './NextDueCell';
import ContentPriorityCell from './ContentPriorityCell';
import ContentDeadlineCell from './ContentDeadlineCell';
import ContentDocumenterCell from './ContentDocumenterCell';
import ContentReviewerCell from './ContentReviewerCell';
import TaskOwnerCell from './TaskOwnerCell';
import TaskInProgressCell from './TaskInProgressCell';
import ContentActionNeededPrompt from './ContentActionNeededPrompt';

FolderManager.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  parentItemId: PropTypes.number.isRequired,
  onAddItem: PropTypes.func.isRequired,
  onExpandOrCollapse: PropTypes.func.isRequired,
  onUpdateItem: PropTypes.func.isRequired,
  onMoveItem: PropTypes.func.isRequired,
  onItemClick: PropTypes.func.isRequired,
};

FolderManager.defaultProps = {
};

function FolderManager (props) {
  const {
    items,
    onAddItem,
    onExpandOrCollapse,
    onItemClick,
    onUpdateItem,
    onMoveItem,
    parentItemId,
  } = props;

  const {urlName: workspaceUrlName} = useWorkspace();

  const [actionNeededPopoverOpen, setActionNeededPopoverOpen] = useState();

  const dataGridItems = useMemo(() => items.map((item) => ({
    ...item,
    expandable: item.itemTypeId === ITEM_TYPE.FOLDER,
    supportAddChildItems: item.itemTypeId === ITEM_TYPE.FOLDER,
    section: item.itemTypeId === ITEM_TYPE.SECTION,
  })), [items]);

  const columnGroups = [
    {
      columns: [
        {
          label: 'Name',
          render: (item) => (
            <Truncate showTooltipOnTruncate={false}>
              {getNameForItem(item)}
            </Truncate>
          ),
          emoji: (item) => item.emoji,
          width: '2xl',
          linkTo: (item) =>
            (item.itemTypeId === ITEM_TYPE.FOLDER
              ? `/${workspaceUrlName}/folders/${item.urlName}/manage`
              : undefined),
          onClick: (item) => {
            onItemClick(item);
            return false;
          },
        },
      ],
    },
    // {
    //   label: 'DataGrid Debug',
    //   columns: [
    //     {
    //       label: 'Rank',
    //       render: (item) => item.rank,
    //       width: 'sm',
    //     },
    //     {
    //       label: 'id',
    //       render: (item) => item.id,
    //       width: 'sm',
    //     },
    //     {
    //       label: 'parentId',
    //       render: (item) => item.parentId,
    //       width: 'sm',
    //     },
    //   ],
    // },
    {
      label: 'Plan content',
      columns: [
        {
          label: 'Action needed',
          render: (item) =>
            shouldRenderItem(item) && (
              <ContentActionNeededCell
                contentActionNeededId={item.contentActionNeededId}
                contentDescription={item.contentDescription}
                itemUrlName={item.urlName}
                onUpdateItem={(updatedAttrs) =>
                  hasChanged(updatedAttrs, item) &&
                  onUpdateItem({
                    id: item.id,
                    ref: item.ref,
                    key: item.key,
                    ...updatedAttrs,
                  })
                }
                setTriggerPopoverOpen={setActionNeededPopoverOpen}
                triggerPopoverOpen={actionNeededPopoverOpen === item.id}
              />
            ),
        },
        {
          label: 'Priority',
          render: (item) =>
            shouldRenderItem(item) &&
            (item.contentActionNeededId ? (
              <ContentPriorityCell
                itemId={item.id}
                itemTypeId={item.itemTypeId}
                contentActionNeededId={item.contentActionNeededId}
                contentPriority={item.contentPriority}
                contentDeadline={item.contentDeadline}
                onUpdateItem={(updatedAttrs) =>
                  hasChanged(updatedAttrs, item) &&
                  onUpdateItem({
                    id: item.id,
                    ref: item.ref,
                    key: item.key,
                    ...updatedAttrs,
                  })
                }
                saving={
                  item.savingAttrs &&
                  item.savingAttrs.includes('contentPriority')
                }
                setActionNeededPopoverOpen={setActionNeededPopoverOpen}
              />
            ) : (
              <ContentActionNeededPrompt
                setActionNeededPopoverOpen={(val) =>
                  val && setActionNeededPopoverOpen(item.id)
                }
              />
            )),
        },
        {
          label: 'Deadline',
          render: (item) =>
            shouldRenderItem(item) &&
            (item.contentActionNeededId ? (
              <ContentDeadlineCell
                itemId={item.id}
                itemTypeId={item.itemTypeId}
                contentDeadline={item.contentDeadline}
                onUpdateItem={(updatedAttrs) =>
                  hasChanged(updatedAttrs, item) &&
                  onUpdateItem({
                    id: item.id,
                    ref: item.ref,
                    key: item.key,
                    ...updatedAttrs,
                  })
                }
                saving={
                  item.savingAttrs &&
                  item.savingAttrs.includes('contentDeadline')
                }
                setActionNeededPopoverOpen={setActionNeededPopoverOpen}
              />
            ) : (
              <ContentActionNeededPrompt
                setActionNeededPopoverOpen={(val) =>
                  val && setActionNeededPopoverOpen(item.id)
                }
              />
            )),
        },
        {
          label: 'Documenter',
          render: (item) =>
            shouldRenderItem(item) &&
            (item.contentActionNeededId ? (
              <ContentDocumenterCell
                itemId={item.id}
                itemTypeId={item.itemTypeId}
                documenters={item.documenters}
                onUpdateItem={(updatedAttrs) =>
                  hasChanged(updatedAttrs, item) &&
                  onUpdateItem({
                    id: item.id,
                    ref: item.ref,
                    key: item.key,
                    ...updatedAttrs,
                  })
                }
                saving={
                  item.savingAttrs && item.savingAttrs.includes('documenters')
                }
                setActionNeededPopoverOpen={setActionNeededPopoverOpen}
              />
            ) : (
              <ContentActionNeededPrompt
                setActionNeededPopoverOpen={(val) =>
                  val && setActionNeededPopoverOpen(item.id)
                }
              />
            )),
        },
        {
          label: 'Reviewer',
          render: (item) =>
            shouldRenderItem(item) &&
            (item.contentActionNeededId ? (
              <ContentReviewerCell
                itemId={item.id}
                itemTypeId={item.itemTypeId}
                reviewers={item.reviewers}
                onUpdateItem={(updatedAttrs) =>
                  hasChanged(updatedAttrs, item) &&
                  onUpdateItem({
                    id: item.id,
                    ref: item.ref,
                    key: item.key,
                    ...updatedAttrs,
                  })
                }
                saving={
                  item.savingAttrs && item.savingAttrs.includes('reviewers')
                }
                setActionNeededPopoverOpen={setActionNeededPopoverOpen}
              />
            ) : (
              <ContentActionNeededPrompt
                setActionNeededPopoverOpen={(val) =>
                  val && setActionNeededPopoverOpen(item.id)
                }
              />
            )),
        },
      ],
    },
    {
      label: 'Manage recurring tasks',
      columns: [
        {
          label: 'Next due',
          render: (item) =>
            shouldRenderItem(item) && (
              <NextDueCell
                itemId={item.id}
                itemTypeId={item.itemTypeId}
                nextDueAt={item.nextDueAt}
                nextDueInterval={item.nextDueInterval}
                nextDueFrequency={item.nextDueFrequency}
                nextDueDaysOfWeek={item.nextDueDaysOfWeek}
                nextDueInstanceOfDayInMonth={item.nextDueInstanceOfDayInMonth}
                onUpdateItem={(updatedAttrs) =>
                  hasChanged(updatedAttrs, item) &&
                  onUpdateItem({
                    id: item.id,
                    ref: item.ref,
                    key: item.key,
                    ...updatedAttrs,
                  })
                }
                saving={
                  item.savingAttrs &&
                  item.savingAttrs.some((attr) =>
                    [
                      'nextDueAt',
                      'nextDueInterval',
                      'nextDueFrequency',
                      'nextDueDaysOfWeek',
                      'nextDueInstanceOfDayInMonth',
                    ].includes(attr))
                }
              />
            ),
        },
        {
          label: 'Task owner',
          render: (item) =>
            shouldRenderItem(item) && (
              <TaskOwnerCell
                itemId={item.id}
                itemTypeId={item.itemTypeId}
                taskOwners={item.taskOwners}
                onUpdateItem={(updatedAttrs) =>
                  hasChanged(updatedAttrs, item) &&
                  onUpdateItem({
                    id: item.id,
                    ref: item.ref,
                    key: item.key,
                    ...updatedAttrs,
                  })
                }
                saving={
                  item.savingAttrs && item.savingAttrs.includes('taskOwners')
                }
              />
            ),
        },
        {
          label: 'In progress',
          render: (item) =>
            shouldRenderItem(item) && (
              <TaskInProgressCell
                itemId={item.id}
                itemUrlName={item.urlName}
                {...item.responseStats}
              />
            ),
        },
      ],
    },
    // {
    //   label: 'Train the team',
    //   columns: [
    //     {
    //       label: 'Asked to train',
    //       renderOld: (item) => {},
    //     },
    //     {
    //       label: 'Fully trained',
    //       renderOld: (item) => {},
    //     },
    //   ],
    // },
  ];

  return (
    <DataGrid
      items={dataGridItems}
      columnGroups={columnGroups}
      freezeFirstColumnGroup
      parentItemId={parentItemId}
      onItemClick={onItemClick}
      onAddItem={onAddItem}
      onExpandOrCollapse={onExpandOrCollapse}
      onMoveItem={onMoveItem}
      supportAddItems
      // supportBulkSelect
      supportDrag
    />
  );
}

function shouldRenderItem (item) {
  return ![ITEM_TYPE.FOLDER, ITEM_TYPE.SECTION].includes(item.itemTypeId) &&
    // An item will have an id as soon as it has saved the first time
    item.id;
}

function hasChanged (updatedItem, originalItem) {
  return Object.keys(updatedItem).some((attr) =>
    !isEqual(originalItem[attr], updatedItem[attr]));
}

export default FolderManager;
