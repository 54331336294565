import React from 'react';
import PropTypes from 'prop-types';
import {ITEM_TYPE} from 'am-constants';
import Avatar from 'components/Avatar';
import getInitials from 'utils/getInitials';
import AvatarList from 'components/AvatarList';

TaskOwnerCellContent.propTypes = {
  itemId: PropTypes.number,
  itemTypeId: PropTypes.number.isRequired,
  taskOwners: PropTypes.arrayOf(PropTypes.shape({})),
};

TaskOwnerCellContent.defaultProps = {
};

const PAGE_ITEM_TYPES = [
  ITEM_TYPE.CHECKLIST,
  ITEM_TYPE.DOCUMENT,
  ITEM_TYPE.FORM,
];

function TaskOwnerCellContent (props) {
  const {
    itemId,
    itemTypeId,
    taskOwners,
  } = props;

  if (!itemId) {
    return null;
  }

  if (!PAGE_ITEM_TYPES.includes(itemTypeId)) {
    return null;
  }

  return (
    <AvatarList>
      {taskOwners.map((reviewer) => (
        <Avatar key={reviewer.id}>{getInitials(reviewer.name)}</Avatar>
      ))}
    </AvatarList>
  );
}

export default TaskOwnerCellContent;
