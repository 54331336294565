import makeParagraph from '../factory/makeParagraph';
import getElementById from '../utils/getElementById';

// Copies a selected element as a fragment. This is needed because the editor's
// selection isn't set to cover the selected elements, so the default copy
// behavior won't work.
function onCopySelectedElementsInRange (editor, data, selectionMeta, selectedElements) {
  if (
    selectedElements.ids.length === 0 ||
    !selectedElements.inRange
  ) {
    return false;
  }

  const originalFragment = data.getData('application/x-slate-fragment');
  const decoded = decodeURIComponent(window.atob(originalFragment));
  const parsed = JSON.parse(decoded);

  const firstElement = parsed[0];
  const lastElement = parsed.slice(-1)[0];

  const fixedFirstElements = selectedElements.ids.includes(firstElement.id)
    ? [
      makeParagraph(), // Add a paragraph before otherwise the editor crashes when pasting into the middle of a paragraph
      getElementById(editor, firstElement.id)[0], // Make sure we've captured the whole selected element
    ]
    : [firstElement];
  const fixedLastElements = selectedElements.ids.includes(lastElement.id)
    ? [getElementById(editor, lastElement.id)[0], makeParagraph()]
    : [lastElement];

  const fixedFragment = [
    ...fixedFirstElements,
    ...parsed.slice(1, -1),
    ...fixedLastElements,
  ];

  const string = JSON.stringify(fixedFragment);
  const encoded = window.btoa(encodeURIComponent(string));
  data.setData('application/x-slate-fragment', encoded);

  return true;
}

export default onCopySelectedElementsInRange;
