import tw, {styled, css} from 'twin.macro';

const PageTitle = styled.h1((props) => [
  tw`
    relative
    text-5xl
    text-gray-800
    mb-6
    mt-0
    z-10
  `,
  css`
    line-height: 3.2rem;
  `,
]);

export default PageTitle;
