import {
  ApolloClient, InMemoryCache, createHttpLink, from,
} from '@apollo/client';
import {onError} from '@apollo/client/link/error';
import {STATUS_CODE} from 'am-constants';
import getRedirectToLoginUri from 'utils/getRedirectToLoginUri';

const uri = window.location.hostname.includes('localhost')
  ? 'http://a.m.localhost:3000/graphql'
  : '/graphql';

const httpLink = createHttpLink({
  uri,
  credentials: 'include',
});

const errorLink = onError(({graphQLErrors, networkError}) => {
  if (networkError) {
    if (networkError.statusCode === STATUS_CODE.UNAUTHORIZED) {
      redirectTo(getRedirectToLoginUri());
      return;
    }

    // eslint-disable-next-line no-console
    console.log('Network error', networkError);
  }

  if (graphQLErrors) {
    const unauthorizedErrors = getUnauthorizedGraphQLErrors(graphQLErrors);

    if (unauthorizedErrors.length) {
      const redirectUrl =
        unauthorizedErrors.find((e) => !!(e?.extensions?.exception?.redirectUrl))
          ?.extensions?.exception?.redirectUrl ||
        getRedirectToLoginUri();

      redirectTo(redirectUrl);

      return;
    }

    // eslint-disable-next-line no-console
    console.log('GraphQL errors', graphQLErrors);
  }
});

function getUnauthorizedGraphQLErrors (graphQLErrors) {
  return graphQLErrors?.filter((error) =>
    error.extensions &&
    error.extensions.exception &&
    (
      error.extensions.exception.statusCode === STATUS_CODE.UNAUTHORIZED
    )) || [];
}

function redirectTo (url) {
  window.location = url;
}

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([
    errorLink,
    httpLink,
  ]),
});

export default client;
