import React, {useState} from 'react';
import {Helmet} from 'react-helmet';
import FormGroup from 'components/FormGroup';
import Label from 'components/Label';
import Input from 'components/Input';
import Error from 'components/Error';
import Button from 'components/Button';
import SimpleFormContainer from 'components/SimpleFormContainer';
import SimpleFormHeading from 'components/SimpleFormHeading';
import SimpleFormPage from 'components/SimpleFormPage';
import Link from 'components/Link';
import PropTypes from 'prop-types';
import tw from 'twin.macro'; // eslint-disable-line no-unused-vars
import {STATUS_CODE} from 'am-constants';
import LinkedInTracking from './LinkedInTracking';

LoginPage.propTypes = {
  login: PropTypes.func.isRequired,
  error: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  method: PropTypes.oneOf(['password', 'sso']),
};

function LoginPage (props) {
  const {
    login,
    error,
    loading,
    method,
  } = props;

  const [isLoggingInWithSSO, setIsLoggingInWithSSO] = useState(method === 'sso');
  const [showError, setShowError] = useState(true);

  const toggleSSO = () => {
    setIsLoggingInWithSSO(!isLoggingInWithSSO);
    setShowError(false);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setShowError(true);
    login(event.target.elements[0].value, event.target.elements[1].value);
  };

  const errorMessage = error && (
    error.code === STATUS_CODE.IM_A_TEAPOT
      ? <>This account has been locked because the password was entered incorrectly 5 times. <Link to="/reset">Reset your password</Link></>
      : error.message
  );

  return <>
    <LinkedInTracking />
    <Helmet>
      <title>Login</title>
    </Helmet>
    <SimpleFormPage>
      <SimpleFormContainer>
        <form onSubmit={onSubmit}>
          <SimpleFormHeading>Sign in to your account</SimpleFormHeading>
          <FormGroup>
            <Label>
              Email
            </Label>
            <Input type="email" required data-test-id='email-input' />
          </FormGroup>
          {!isLoggingInWithSSO && <FormGroup>
            <span tw="float-right text-xs">
              <Link to="/reset">Forgot your password?</Link>
            </span>
            <Label>
              Password
            </Label>
            <Input type="password" required data-test-id='password-input' />
          </FormGroup>
          }
          <FormGroup buttons>
            <Button primary lg fullwidth disabled={loading} data-test-id='login-button'>Continue</Button>
          </FormGroup>
          {error && !loading && showError && (
            <Error arrow>{errorMessage}</Error>
          )}
          <div tw="flex justify-center mt-4 text-sm">
            You can also&nbsp;
            <Button link noPadding onClick={toggleSSO} type="button"
              data-test-id="toggle-sso-button">
              continue with {isLoggingInWithSSO ? 'email' : 'OIDC SSO'}
            </Button>
          </div>
          {/* TODO: show this when we want to make signing up visible to users
          <FullWidthButtonSecondaryAction>
            Not got an account? <Link to="/signup">Sign up</Link>
          </FullWidthButtonSecondaryAction>
          */}
        </form>
      </SimpleFormContainer>
    </SimpleFormPage>
  </>;
}

export default LoginPage;
