import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import GridCellPopover from 'components/DataGrid/GridCellPopover';
import TaskInProgressCellPopover from './TaskInProgressCellPopover';
import TaskInProgressCellContent from './TaskInProgressCellContent';

TaskInProgressCell.propTypes = {
  itemUrlName: PropTypes.string.isRequired,
  numActiveResponses: PropTypes.number.isRequired,
  numSubmittedResponses: PropTypes.number.isRequired,
  mostRecentSubmissionAt: PropTypes.string,
};

TaskInProgressCell.defaultProps = {
};

function TaskInProgressCell (props) {
  const {
    itemUrlName,
    numActiveResponses,
    numSubmittedResponses,
    mostRecentSubmissionAt,
  } = props;

  const popover = useMemo(() => (
    <TaskInProgressCellPopover
      itemUrlName={itemUrlName}
      numActiveResponses={numActiveResponses}
      numSubmittedResponses={numSubmittedResponses}
      mostRecentSubmissionAt={mostRecentSubmissionAt}
    />
  ), [
    numActiveResponses,
  ]);

  return (
    <GridCellPopover content={popover}>
      <TaskInProgressCellContent
        numActiveResponses={numActiveResponses}
      />
    </GridCellPopover>
  );
}

export default React.memo(TaskInProgressCell);
