import tw, {styled} from 'twin.macro';

const SimpleFormContainer = styled.div((props) => [
  tw`
    bg-white
    px-6
    sm:px-8
    py-6
    pb-8
    sm:rounded-lg
    sm:shadow-lg
    mb-6
    sm:w-128
    mx-auto
  `,
]);

export default SimpleFormContainer;
