import {Editor, Transforms} from 'slate';
import makeProcess from '../factory/makeProcess';
import insertNodeAtSelection from './insertNodeAtSelection';
import selectNextNode from './selectNextNode';

function insertProcess (editor) {
  const selectionIsInProcess = !!Editor.above(editor, {
    at: editor.selection,
    match: (n) => n.type === 'process',
  });

  if (selectionIsInProcess) {
    insertProcessAfterCurrentProcess(editor);
  }
  else {
    insertProcessAtSelection(editor);
  }
}

function insertProcessAfterCurrentProcess (editor) {
  const pathAfterCurrentProcess = [editor.selection.anchor.path[0] + 1];
  Transforms.insertNodes(
    editor,
    makeProcess(),
    {
      at: pathAfterCurrentProcess,
    }
  );

  const firstProcessStepPath = [...pathAfterCurrentProcess, 1, 0, 0, 0];
  requestAnimationFrame(() =>
    Transforms.select(
      editor,
      {
        path: firstProcessStepPath,
        offset: 0,
      }
    ));
}

function insertProcessAtSelection (editor) {
  insertNodeAtSelection(editor, {makeNode: makeProcess});
  // After inserting the node (above), it will automatically select the
  // start of the new node. This will be the process title. We actually
  // want it to select the first process step, so let's do that!
  selectNextNode(editor);
}

export default insertProcess;
