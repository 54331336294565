import P from 'components/P';
import React from 'react';
import A from 'components/A';
import Comment from './Comment';

WarningStepNoHelpBlocks.propTypes = {
};

function WarningStepNoHelpBlocks (props) {
  return (
    <Comment isWarning shortText="Consider help blocks" {...props}>
      <P>There's a lot of guidance in this step.</P>
      <P>
        You could use a help block to hide away content that doesn't need to be reviewed
        every time someone uses the checklist.
      </P>
      <P>
        <A
          target="_blank"
          href="https://airmanual.link/warnings/help-blocks"
          rel="noreferrer">How to add a help block</A>
      </P>
    </Comment>
  );
}

export default React.memo(WarningStepNoHelpBlocks);
