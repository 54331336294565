import {usePrintMode} from 'containers/PrintMode/providers/PrintModeProvider';
import React, {useContext} from 'react';
import {ProcessContext} from '../Process';
import ProcessStepList from './ProcessStepList';

function ProcessStepListContainer (props) {
  const printMode = usePrintMode();
  const {processId, open} = useContext(ProcessContext);

  return (
    <ProcessStepList
      printMode={!!printMode}
      processId={processId}
      open={open}
      {...props} />
  );
}

export default ProcessStepListContainer;
