import PropTypes from 'prop-types';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import {gql} from 'graphql-request';
import {ITEM_MINIMAL_FIELDS} from 'fragments/ItemMinimal';
import {BREADCRUMB_FIELDS} from 'fragments/Breadcrumb';
import {ITEM_TYPE} from 'am-constants';
import getItemUrlName from 'am-utils/utils/getItemUrlName';
import graphQLClient from 'utils/graphQLClient';

const folderQuery = gql`
  query GetFolder($ref: String!, $key: String!) {
    item(ref: $ref, key: $key) {
      ${ITEM_MINIMAL_FIELDS}
      breadcrumbs {
        ${BREADCRUMB_FIELDS}
      }
    }
  }
`;

const workspaceItemQuery = gql`
  query GetWorkspaceWithWorkspaceItem($workspaceUrlName: String!) {
    workspace(workspaceUrlName: $workspaceUrlName) {
      workspaceItem {
        ${ITEM_MINIMAL_FIELDS}
        breadcrumbs {
          ${BREADCRUMB_FIELDS}
        }
      }
    }
  }
`;

useFolderQuery.propTypes = {
  ref: PropTypes.string,
  key: PropTypes.string,
  workspaceUrlName: PropTypes.string,
};

function useFolderQuery ({workspaceUrlName, ref, key}) {
  const queryClient = useQueryClient();

  const cacheKey = [workspaceUrlName, 'items', {ref, key}];

  const variables = {
    workspaceUrlName,
    ref,
    key,
  };

  const folderQueryFn = async () => {
    const {item} = await graphQLClient.request(folderQuery, variables);

    if (![ITEM_TYPE.WORKSPACE, ITEM_TYPE.FOLDER].includes(item.itemTypeId)) {
      throw new Error('The item loaded is not a folder');
    }

    return {
      ...item,
      urlName: getItemUrlName(item),
    };
  };

  const workspaceItemQueryFn = async () => {
    const {workspace} = await graphQLClient.request(workspaceItemQuery, variables);

    const item = workspace.workspaceItem;

    return {
      ...item,
      urlName: getItemUrlName(item),
    };
  };

  const queryFn = ref ? folderQueryFn : workspaceItemQueryFn;

  const options = {
    // If we've already loaded this folder in the browse/manage views, then load
    // the initial data from cache.
    initialData: () => {
      const queryCache = queryClient.getQueryCache();
      const cachedQueries = queryCache.findAll([workspaceUrlName, 'items']);

      const cachedItem = cachedQueries
        .filter(({queryKey}) => queryKey.length > 2 && queryKey[2].folderId !== undefined)
        .map(({state}) => state.data)
        .flat()
        .find((item) => item?.ref === ref && item?.key === key);

      return cachedItem;
    },
  };

  return useQuery(
    cacheKey,
    queryFn,
    options
  );
}

export default useFolderQuery;
