import {Editor, Transforms} from 'slate';
import makeParagraph from '../factory/makeParagraph';

function makeSureNoOrphanTextInputLabels (editor, [entryNode, entryNodePath]) {
  if (entryNode.type !== 'label') {
    return false;
  }

  const [parentNode] = Editor.parent(editor, entryNodePath);
  if (parentNode.type === 'form-group') {
    return false;
  }

  Transforms.setNodes(editor, makeParagraph(), {
    at: entryNodePath,
  });

  return true;
}

export default makeSureNoOrphanTextInputLabels;
