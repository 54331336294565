/**
 * This is a generic utility library to create trees from arrays and vice versa.
 * Functions with plural name *Trees deal with list of trees.
 * Functions with singular name *Tree deal with a single tree.
 * They are used to work with our tree folder structure.
 */

/**
 * Example input:
 *  items: [
 *    {name: 'Workspace1', id:1, parentId: null},
 *    {name: 'Folder2', id:2, parentId: 1},
 *    {name: 'Folder3', id:3, parentId: 1},
 *    {name: 'Folder4', id:4, parentId: 2},
 *    {name: 'Workspace2', id:5, parentId: null},
 *  ]
 *
 * Example output (2 trees: Workspace1 and Workspace2):
 *  [
 *    {name: 'Workspace1', id:1, parentId: null, children:[
 *      {name: 'Folder2', id:2, parentId: 1, children:[
 *        {name: 'Folder4', id:4, parentId: 2, children:[]}
 *      ]},
 *      {name: 'Folder3', id:3, parentId: 1, children:[]}
 *    ]},
 *    {name: 'Workspace2', id:5, parentId: null, children:[]}
 *  ]
 */
const pMap = require('p-map');

function getTreesFromArray (
  items,
  idKey = 'id',
  parentIdKey = 'parentId',
  childrenKey = 'children',
) {
  const itemsById = {};
  items.forEach((item) => {
    const itemId = item[idKey];
    const children = item[childrenKey] ? [...item[childrenKey]] : [];
    itemsById[itemId] = {...item, [childrenKey]: children};
  });
  const trees = [];
  items.forEach((item) => {
    if (item[parentIdKey] && item[parentIdKey] !== item[idKey] && itemsById[item[parentIdKey]]) {
      itemsById[item[parentIdKey]][childrenKey].push(itemsById[item[idKey]]);
    }
    else {
      trees.push(itemsById[item[idKey]]);
    }
  });
  return trees;
}

/**
 * Inverse of getTreesFromArray.
 */
function getArrayFromTrees (
  trees,
  childrenKey = 'children',
) {
  return trees.flatMap((tree) => [tree, ...getArrayFromTrees(tree[childrenKey], childrenKey)]);
}

function getTreeFromArray (
  items,
  idKey = 'id',
  parentIdKey = 'parentId',
  childrenKey = 'children',
) {
  const trees = getTreesFromArray(items, idKey, parentIdKey, childrenKey);

  if (trees.length < 1) {
    throw new Error('The array does not contain trees');
  }
  if (trees.length > 1) {
    throw new Error('The array contains too many items with unknown parentIdKey');
  }

  return trees[0];
}

function getArrayFromTree (
  tree,
  childrenKey = 'children',
) {
  return getArrayFromTrees([tree], childrenKey);
}

/**
 * Runs {fn} on each node of the input {tree}, starting from the leaves up to the root.
 */
async function leavesToRootApply(
  tree,
  fn,
  childrenKey = 'children',
) {
  if (tree) {
    for (const child of tree[childrenKey]) {
      await leavesToRootApply(child, fn, childrenKey);
    }
    await fn(tree);
  }
}

async function mapTree(
  tree,
  mapperFn,
  childrenKey = 'children',
) {
  const trees = await mapTrees([tree], mapperFn, childrenKey);
  return trees[0];
}

async function mapTrees(
  trees,
  mapperFn,
  childrenKey = 'children',
) {
  if (trees) {
    // We use pMap with concurrency: 1 instead of Promise.all and map because
    // it allows us to run each of the promises in sequence, rather than all at
    // once. When items are created out of sequence their ranks are not
    // accurately calculated, leading to database violations.
    return await pMap(trees, async (child) => {
      const mapped = await mapperFn(child);
      if (mapped && mapped[childrenKey]) {
        mapped[childrenKey] = await mapTrees(mapped[childrenKey], mapperFn);
      }
      return mapped;
    }, {concurrency: 1});
  }
  return trees;
}

function filterTrees(
  trees,
  filterFn,
  childrenKey = 'children',
) {
  return trees
      .filter(filterFn)
      .map((tree) => filterTree(tree, filterFn, childrenKey));
}

function filterTree(
  tree,
  filterFn,
  childrenKey = 'children',
) {
  if (!filterFn(tree)) {
    return null;
  }

  return {
    ...tree,
    [childrenKey]: tree[childrenKey]
      .filter(filterFn)
      .map((child) => filterTree(child, filterFn, childrenKey))
  };
}

module.exports = {
  getTreesFromArray,
  getTreeFromArray,
  getArrayFromTree,
  mapTree,
  leavesToRootApply,
  filterTrees,
  filterTree
};
