import {Transforms} from 'slate';
import makeProcessStep from '../factory/makeProcessStep';

function insertProcessStep (editor, location, {stepTitle, stepContent} = {}) {
  Transforms.insertNodes(
    editor,
    makeProcessStep({
      title: stepTitle || '',
      content: stepContent || '',
    }),
    {
      at: location,
    }
  );
  const newProcessStepTitlePath = [...location, 0];
  requestAnimationFrame(() => Transforms.select(editor, newProcessStepTitlePath));
}

export default insertProcessStep;
