import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled} from 'twin.macro';
import Video from 'components/Video';

SlateVideo.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  element: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
};

function SlateVideo (props) {
  const {
    children,
    element: {url},
  } = props;

  return (
    <Container>
      <Video url={url}></Video>
      {children}
    </Container>
  );
}

const Container = styled.div((props) => [
  tw`
    clear-both
  `,
]);

export default SlateVideo;
