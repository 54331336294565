import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled, css} from 'twin.macro';
import {AiOutlineMore} from 'react-icons/ai';

GridRowDragHandle.propTypes = {
  dragHandleHtml5Ref: PropTypes.func,
  dragHandleTouchRef: PropTypes.func,
  header: PropTypes.bool,
};

GridRowDragHandle.defaultProps = {
  header: false,
};

function GridRowDragHandle (props) {
  const {
    dragHandleTouchRef,
    dragHandleHtml5Ref,
    header,
    ...rest
  } = props;

  return (
    <div ref={dragHandleTouchRef} tw="h-full">
      <div ref={dragHandleHtml5Ref} tw="h-full">
        <DragHandle header={header} {...rest} data-test-drag-handle>
          <FirstAiOutlineMore />
          <SecondAiOutlineMore />
        </DragHandle>
      </div>
    </div>
  );
}

export default GridRowDragHandle;

const DragHandle = styled.div((props) => [
  tw`
    w-6
    relative
    flex-none
    relative
    bg-gray-50
    opacity-0
  `,
  css`
    // Expanding 1px out of the top and bottom covers up the hovered row's
    // background color (when some cells are behind a frozen
    // GridRowExpanderSpacer).
    height: calc(100% + 2px);
    margin-top: -1px;
  `,
  // Note that the twin group-hover: utility doesn't work as part of a condition
  // (!props.header).
  !props.header && css`
    .grid-row__hoverable:hover && {
      cursor: pointer;
      opacity: 100%;
    }
  `,
]);

const FirstAiOutlineMore = styled(AiOutlineMore)((props) => [
  tw`
    absolute
    top-3
    left-0
  `,
]);

const SecondAiOutlineMore = styled(AiOutlineMore)((props) => [
  tw`
    absolute
    top-3
    left-1
  `,
]);
