import {MAX_RATING_OPTIONS} from 'containers/SlateEditor/constants';
import {Transforms} from 'slate';

function removeChoice (editor, path, element) {
  // It's possible to convert a multiple choice question with more than 10
  // options to a rating question. We initially leave them in so the user can
  // switch back to a vertical or horizontal list. This logic then ensures that
  // removing an option reduces the number of options to 9.
  for (let i = element.children.length - 1; i >= MAX_RATING_OPTIONS; i--) {
    Transforms.removeNodes(editor, {
      at: [...path, i],
    });
  }

  Transforms.removeNodes(editor, {
    // Note: We don't remove the last node to avoid changing the label at the
    // end of the rating scale
    at: [...path, Math.min(MAX_RATING_OPTIONS, element.children.length) - 2],
  });
}

export default removeChoice;
