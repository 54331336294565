import React from 'react';
import PropTypes from 'prop-types';
import {navigate} from '@reach/router';
import useDeleteItemMutation from './hooks/useDeleteItemMutation';
import ItemDeleterModal from './components/ItemDeleterModal';

ItemDeleter.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  itemRef: PropTypes.string.isRequired,
  itemKey: PropTypes.string.isRequired,
  itemTypeId: PropTypes.number,
  redirectToWorkspace: PropTypes.bool,
  setIsVisible: PropTypes.func.isRequired,
  closeSidePanel: PropTypes.func,
  workspaceUrlName: PropTypes.string,
};

ItemDeleter.defaultProps = {
  redirectToWorkspace: false,
};

function ItemDeleter (props) {
  const {
    isVisible,
    itemRef,
    itemKey,
    itemTypeId,
    redirectToWorkspace,
    setIsVisible,
    closeSidePanel,
    workspaceUrlName,
  } = props;

  const closeModal = () => {
    setIsVisible(false);
  };

  const [onDeleteItem, {error}] = useDeleteItemMutation({itemRef, itemKey});

  const onSubmit = async () => {
    await onDeleteItem();

    setIsVisible(false);
    if (closeSidePanel !== undefined) {
      closeSidePanel();
    }

    if (redirectToWorkspace) {
      navigate(`/${workspaceUrlName}`);
    }
  };

  return (
    <ItemDeleterModal
      closeModal={closeModal}
      error={error}
      isVisible={isVisible}
      itemTypeId={itemTypeId}
      onSubmit={onSubmit}
    />
  );
}

export default ItemDeleter;
