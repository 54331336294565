import tw, {styled} from 'twin.macro';
import {Link as ReachLink} from '@reach/router';

import React from 'react';
import PropTypes from 'prop-types';
import {sanitizeUrl} from '@braintree/sanitize-url';

Link.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  to: PropTypes.string.isRequired,
};

function Link (props) {
  const {
    children,
    to,
    ...rest
  } = props;

  if (to.substr(0, 1) === '/') {
    return <StyledReachLink to={to} {...rest}>{children}</StyledReachLink>;
  }

  return <StyledLink href={sanitizeUrl(to)} target="_blank" rel="noreferrer">{children}</StyledLink>;
}

const StyledReachLink = styled(ReachLink)((props) => [
  tw`
    text-blue-500
    hover:text-blue-400
  `,
]);

const StyledLink = styled.a((props) => [
  tw`
    text-blue-500
    hover:text-blue-400
  `,
]);

export default Link;
