import {GraphQLClient} from 'graphql-request';

const endpoint = window.location.hostname.includes('localhost')
  ? 'http://a.m.localhost:3000/graphql'
  : '/graphql';

const client = new GraphQLClient(endpoint, {
  credentials: 'include',
  mode: 'cors',
});

export default client;
