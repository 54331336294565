import {Editor, Path, Transforms} from 'slate';
import makeChoice from '../factory/makeChoice';
import makeProcessStep from '../factory/makeProcessStep';

function insertDataIntoSingleLineElementGroup (editor, data, selectionMeta) {
  const {
    selectionStartElement,
    isSelectionInSingleElement,
  } = selectionMeta;

  if (
    !selectionStartElement ||
    (
      selectionStartElement.type !== 'choice' &&
      selectionStartElement.type !== 'process-step-title'
    ) ||
    !isSelectionInSingleElement
  ) {
    return false;
  }

  const text = data.getData('text');
  const [firstLine, ...otherLines] = getTrimmedNonEmptyLines(text);

  // Insert the first line
  editor.insertText(firstLine);

  if (!otherLines.length) {
    return true;
  }

  // Add the other lines
  const selectedElementPath = editor.selection.anchor.path.slice(0, -1);
  const parentPath = Path.parent(selectedElementPath);
  let linePath = selectionStartElement.type === 'process-step-title' ? parentPath : selectedElementPath;
  otherLines.forEach((line) => {
    linePath = Path.next(linePath);

    Transforms.insertNodes(
      editor,
      selectionStartElement.type === 'process-step-title' ? makeProcessStep({title: line}) : makeChoice({text: line}),
      {at: linePath}
    );
  });

  // Handle any text to the right of the user's selection before they pasted,
  // and move the cursor to the end of the pasted content.
  const selectedLeafPath = editor.selection.anchor.path;
  const selectedLeafOffset = editor.selection.anchor.offset;
  const firstLineElementText = Editor.string(editor, selectedLeafPath);
  const textToRightOfFirstLine = firstLineElementText.slice(selectedLeafOffset);

  if (textToRightOfFirstLine.length) {
    Editor.deleteForward(editor, {unit: 'line'});
  }

  const endPos = Editor.end(editor, [...linePath, 0]);
  requestAnimationFrame(() => {
    Transforms.select(editor, endPos);
    editor.insertText(textToRightOfFirstLine);
    Transforms.select(editor, endPos);
  });
  return true;
}

function getTrimmedNonEmptyLines (text) {
  return text
    .split('\n')
    .map((line) => line.trim())
    .filter((line) => line !== '');
}

export default insertDataIntoSingleLineElementGroup;
