import tw, {styled} from 'twin.macro';

const P = styled.p((props) => [
  tw`
    mb-5
  `,
  props.bottom &&
    tw`
    mb-0
  `,
]);

export default P;
