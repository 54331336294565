const makeProcessStepContent = (attributes = {}) => {
  const {content} = attributes;

  return {
    type: 'process-step-content',
    children: [
      {
        type: 'paragraph',
        children: [
          {
            text: content || '',
          },
        ],
      },
    ],
  };
};

export default makeProcessStepContent;
