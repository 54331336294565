import React, {useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import Loader from 'components/Loader';
import {Helmet} from 'react-helmet';
import getNameRefAndKeyParts from 'utils/getNameRefAndKeyParts';
import {useTopbar} from 'providers/TopbarProvider';
import useItemWithResponsesQuery from './hooks/useItemWithResponsesQuery';
import ResponseTablePage from './components/ResponseTablePage';

ResponseTable.propTypes = {
  workspaceUrlName: PropTypes.string,
  'name--:ref-:key': PropTypes.string,
  printMode: PropTypes.bool,
};

ResponseTable.defaultProps = {
  printMode: false,
};

function ResponseTable (props) {
  const {
    'name--:ref-:key': nameRefAndKey,
    printMode,
  } = props;

  const {
    ref: itemRef,
    key: itemKey,
  } = useMemo(() => getNameRefAndKeyParts(nameRefAndKey), [nameRefAndKey]);

  const {setPrintMode} = useTopbar();
  useEffect(() => {
    setPrintMode(printMode);
  });

  const {
    item,
    loading,
    error,
  } = useItemWithResponsesQuery({ref: itemRef, key: itemKey});

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <p>Could not load responses</p>;
  }

  if (!item.document) {
    return <p>This item is not a page.</p>;
  }

  if (!item.document.documentSnapshot) {
    return <p>This page has not been published</p>;
  }

  return <>
    <Helmet>
      <title>Submissions</title>
    </Helmet>
    <ResponseTablePage
      responses={item.document.responses}
      inputs={item.document.documentSnapshot.inputs}
      processSteps={item.document.documentSnapshot.processSteps}
      printMode={printMode}
    />
  </>;
}

export default ResponseTable;
