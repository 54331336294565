import React, {useState} from 'react';
import PropTypes from 'prop-types';
import tw, {styled} from 'twin.macro';
import Pill, {PillSpacer} from 'components/Pill';
import {AnimatePresence, motion} from 'framer-motion';
import Link from 'components/Link';
import List from '.'; // eslint-disable-line import/no-cycle
import FlexSpacer from '../FlexSpacer';

ListItem.propTypes = {
  // actions:
  children: PropTypes.arrayOf(PropTypes.shape({})),
  compact: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.func,
  iconSize: PropTypes.number,
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  name: PropTypes.string,
  renderName: PropTypes.func,
  onClickItem: PropTypes.func,
  purple: PropTypes.bool,
  selected: PropTypes.bool,
  selectedItemId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  semiCompact: PropTypes.bool,
  renderDescription: PropTypes.func,
  renderActions: PropTypes.func,
  showActionsWithoutHover: PropTypes.bool,
  tags: PropTypes.arrayOf(PropTypes.shape({
    tag: PropTypes.string.isRequired,
  })),
  url: PropTypes.string,
  dataTestId: PropTypes.string,
};

ListItem.defaultProps = {
  compact: false,
  disabled: false,
  onClickItem: () => {},
  purple: false,
  selected: false,
  semiCompact: false,
  showActionsWithoutHover: false,
  renderActions: undefined,
  tags: [],
  url: undefined,
};

function ListItem (props) {
  const {
    children,
    compact,
    disabled,
    icon,
    iconSize,
    id,
    name,
    renderName,
    onClickItem,
    purple,
    renderActions,
    renderDescription,
    selected,
    selectedItemId,
    semiCompact,
    showActionsWithoutHover,
    tags,
    url,
    dataTestId,
  } = props;

  const adjustedIconSize = iconSize || (semiCompact && 20) || (compact && 20) || 24;

  const [hovering, setHovering] = useState(false);
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  const hasActions = !!renderActions;
  const showActions = hasActions && showActionsWithoutHover || (hovering && !isTouchDevice);
  const hasTags = tags.length > 0;

  const renderTags = () => tags.map(({tag, ...tagProps}, tagNum) => (
    <div key={`${id}_${tagNum}`} tw="flex">
      {tagNum > 0 && <PillSpacer>&nbsp;</PillSpacer>}
      <Pill {...tagProps}>{tag}</Pill>
    </div>
  ));

  const renderItem = () => {
    const description = renderDescription && renderDescription();

    return (
      <Wrapper
        // itemType={item.itemTypeId}
        // pinned={!!item.pin}
        compact={compact}
        hovering={hovering}
        disabled={disabled}
        onClick={() => !disabled && onClickItem(id, name)}
        onMouseEnter={() => !disabled && setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        onTouchStart={() => setIsTouchDevice(true)}
        purple={purple}
        selected={selected}
        semiCompact={semiCompact}
        hasActions={hasActions}
      >
        {icon && (
          <IconWrapper
            compact={compact}
            semiCompact={semiCompact}
            selected={selected}
          >
            {icon({
              size: adjustedIconSize,
            })}
          </IconWrapper>
        )}
        <FlexSpacer data-test-id={'selectable-list-item'}>
          {name && name}
          {renderName && renderName()}
          {description && <Description>{description}</Description>}
        </FlexSpacer>

        {!hasActions && renderTags()}

        {hasActions && (
          <AnimatePresence initial={false}>
            {!showActions && (
              <motion.div
                initial={{opacity: showActionsWithoutHover ? 1 : 0}}
                animate={{
                  opacity: 1,
                  transition: {delay: 0.2, duration: 0.3},
                }}
                exit={{
                  opacity: 0,
                  transition: {delay: 0.3, duration: 0.15},
                }}
              >
                {renderTags()}
              </motion.div>
            )}
          </AnimatePresence>
        )}
      </Wrapper>
    );
  };

  return (
    <div tw="relative" data-test-id={dataTestId}>
      {url && <Link to={url}>{renderItem()}</Link>}
      {!url && renderItem()}
      <AnimatePresence>
        {showActions &&
        <ActionsWrapper
          disabled={disabled}
          onMouseEnter={() => !disabled && setHovering(true)}
          onMouseLeave={() => setHovering(false)}>
          <motion.div
            initial={{opacity: showActionsWithoutHover ? 1 : 0}}
            animate={{opacity: 1, transition: {delay: hasTags ? 0.45 : 0.2, duration: 0.3}}}
            exit={{opacity: 0, transition: {delay: 0.05, duration: 0.15}}}
          >
            {renderActions && renderActions()}
          </motion.div>
        </ActionsWrapper>
        }
      </AnimatePresence>

      {children && <ChildrenWrapper compact={compact}>
        <List
          items={children}
          icon={icon}
          compact={compact}
          onClickItem={onClickItem}
          selectedItemId={selectedItemId}
        />
      </ChildrenWrapper>}
    </div>
  );
}

const Wrapper = styled.li((props) => [
  tw`
    relative
    p-3
    border-gray-50
    text-gray-800
    flex
    gap-0.5
    items-center
    text-sm
    cursor-pointer
  `,
  props.disabled && !props.hasActions && tw`
    text-gray-200
    cursor-not-allowed
  `,
  props.hovering && !props.disabled && tw`
    bg-blue-50
  `,
  props.hovering && !props.disabled && props.purple && tw`
    bg-purple-100
  `,
  props.semiCompact && tw`
    py-2!
    px-3
    gap-1
  `,
  props.compact && tw`
    p-1.5
    py-0.5
    text-xs
    first:pt-1
    last:py-1
  `,
  props.selected && tw`
    text-blue-900
    bg-blue-300
    hover:bg-blue-300
  `,
]);

const IconWrapper = styled.div((props) => [
  tw`
    text-gray-300
    mr-2.5
  `,
  props.compact && tw`
    mr-1.5
  `,
  props.semiCompact && tw`
    mr-1.5
  `,
  props.selected && tw`
    text-blue-600
  `,
]);

const ActionsWrapper = styled.div((props) => [
  tw`
    absolute
    top-0
    right-0
    px-3
    h-full
    flex
    items-center
    justify-end
    cursor-pointer
  `,
  props.disabled && tw`
    cursor-not-allowed
  `,
]);

const ChildrenWrapper = styled.div((props) => [
  tw`
    ml-6
    border-dotted
    border-l-2
    border-gray-100
  `,
  props.compact && tw`
    ml-4
  `,
]);

const Description = styled.div((props) => [
  tw`
    mt-0.5
    text-2xs
    text-gray-700
  `,
]);

export default ListItem;
