import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import tw, {styled, css} from 'twin.macro';
import {
  AiOutlineCheck,
  AiOutlineExclamationCircle, AiOutlineMore, AiOutlineStop,
} from 'react-icons/ai';
import useOnClickOutside from 'hooks/useOnClickOutside';
import FlexSpacer from 'components/FlexSpacer';
import Tooltip from 'components/Tooltip';
import DropdownButton from 'components/DropdownButton';

Comment.propTypes = {
  author: PropTypes.string,
  avatar: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  closeComment: PropTypes.func.isRequired,
  displayNarrow: PropTypes.bool,
  height: PropTypes.number,
  isOpen: PropTypes.bool,
  isWarning: PropTypes.bool,
  offsetTop: PropTypes.number.isRequired,
  onUpdateHeight: PropTypes.func.isRequired,
  openComment: PropTypes.func.isRequired,
  removeComment: PropTypes.func.isRequired,
  shortText: PropTypes.string.isRequired,
};

Comment.defaultProps = {
  displayNarrow: false,
  isWarning: false,
};

function Comment (props) {
  const {
    author,
    avatar,
    children,
    closeComment,
    displayNarrow,
    height,
    isOpen,
    isWarning,
    offsetTop,
    onUpdateHeight,
    openComment,
    removeComment,
    shortText,
  } = props;

  const ref = useRef();
  // setTimeout lets you click on the comment below the open one, but doesn't
  // work everytime.
  useOnClickOutside(ref, () => isOpen && setTimeout(closeComment, 0));

  useEffect(() => {
    const newHeight = ref.current.offsetHeight;

    if (height !== newHeight) {
      onUpdateHeight(newHeight);
    }
  }, [isOpen]);

  const menu = [
    [
      {
        label: 'Edit comment',
        to: '/test',
      },
      {
        label: 'Delete comment',
        to: '/test',
      },
    ],
  ];

  return (
    <CommentBox
      style={{top: offsetTop}}
      displayNarrow={displayNarrow}
      isWarning={isWarning}
      open={isOpen}
      ref={ref}
      onMouseDown={openComment}
    >
      <Header id='test-comment'>
        <Icon isWarning={isWarning}>
          {isWarning && <AiOutlineExclamationCircle />}
          {!isWarning && <Avatar src={avatar} displayNarrow={displayNarrow} />}
        </Icon>
        {(!displayNarrow || isOpen) && (
          <>
            {!isOpen && `${shortText}...`}
            {isOpen && <Heading>{isWarning ? shortText : author}</Heading>}
            <FlexSpacer />
            <IconBar open={isOpen}>
              {!isWarning && (
                <>
                  <Tooltip title="Resolve comment" arrow={false} sm>
                    <IconBarIcon open={isOpen} onMouseDown={removeComment}>
                      <AiOutlineCheck />
                    </IconBarIcon>
                  </Tooltip>
                  <DropdownButton menu={menu} doNotWrapInButton sm gray>
                    <Tooltip title="More actions" arrow={false} sm>
                      <IconBarIcon open={isOpen}>
                        <AiOutlineMore />
                      </IconBarIcon>
                    </Tooltip>
                  </DropdownButton>
                </>
              )}
              {isWarning && (
                <Tooltip title="Ignore warning" arrow={false} sm>
                  <span data-test-id="ignore-warning">
                    <IconBarIcon open={isOpen} onMouseDown={removeComment}>
                      <AiOutlineStop />
                    </IconBarIcon>
                  </span>
                </Tooltip>
              )}
            </IconBar>
          </>
        )}
      </Header>
      {isOpen && (
        <>
          <Content isWarning={isWarning}>{children}</Content>
          {/* TODO: Add ability to react to comments (with emojis) */}
          {/* TODO: Add ability to reply to comments */}
          {/* TODO: Add ability to react to/edit/delete replies */}
          {/* {!isWarning && (
            <Footer></Footer>
          )} */}
        </>
      )}
    </CommentBox>
  );
}

const CommentBox = styled.div((props) => [
  tw`
    absolute
    w-full
    text-xs
    text-gray-500
    cursor-pointer
    border
    border-white
    bg-white
    hover:bg-gray-50
    hover:border-gray-100
    p-3
    pr-4
    mt--3
    rounded
    flex
    flex-col
    gap-4
  `,
  props.open &&
    tw`
    border-gray-100
    text-gray-800
    z-20
  `,
  css`
    max-height: 50px;
    &:hover ${IconBar} {
      display: flex;
    }
    transition: top 100ms;
  `,
  props.open &&
    css`
      max-height: 500px;
    `,
  props.displayNarrow &&
    props.isWarning &&
    !props.open &&
    tw`
    w-12
  `,
  props.displayNarrow &&
    props.isWarning &&
    props.open &&
    css`
      right: 0;
    `,
  props.displayNarrow &&
    !props.isWarning &&
    !props.open &&
    css`
      width: 50px;
    `,
  props.displayNarrow &&
    !props.isWarning &&
    props.open &&
    css`
      right: calc(100% - 50px);
    `,
]);

const Header = styled.div((props) => [
  tw`
    flex
    items-center
    gap-3
  `,
]);

const Icon = styled.div((props) => [
  tw`
    w-6
    h-6
    flex
    items-center
    justify-center
    text-yellow-600
  `,
  css`
    font-size: 20px;
    line-height: 20px;
  `,
  props.isWarning &&
    tw`
    w-5
    h-5
  `,
]);

const Avatar = styled.img((props) => [
  tw`
    rounded-full
    w-6
    h-6
  `,
  css`
    min-width: 24px;
  `,
]);

const Heading = styled.div((props) => [
  tw`
    font-bold
  `,
]);

const IconBar = styled.div((props) => [
  tw`
    flex
    text-base
    gap-2
    hidden
  `,
  props.open && tw`
    flex
  `,
]);

const IconBarIcon = styled.div((props) => [
  tw`
    w-6
    h-6
    m--1
    border
    border-transparent
    rounded-sm
    hover:bg-white
    hover:border-white
    flex
    items-center
    justify-center
  `,
]);

const Content = styled.div((props) => [
  tw`
    ml-9
  `,
  props.isWarning && tw`
    ml-8
  `,
]);

// const Footer = styled.div((props) => [
//   tw`
//     ml-8
//     py-2
//   `,
// ]);

export default Comment;
