import {Editor, Transforms} from 'slate';

function onArrowRightBeforeCollapsedContent (editor, event, selectionMeta) {
  const {
    isSelectionAtEndOfElement,
    selectionStart,
    selectionStartElement,
    selectionStartElementPath,
  } = selectionMeta;

  if (
    event.key !== 'ArrowRight' ||
    !selectionStart ||
    ![
      'process-title',
      'help-block-title',
      'process-step-title',
    ].includes(selectionStartElement.type) ||
    !isSelectionAtEndOfElement
  ) {
    return false;
  }

  const [parentElement] = Editor.parent(editor, selectionStartElementPath);
  if (parentElement.open) {
    return false;
  }

  event.preventDefault();

  const nextPoint = Editor.after(editor, selectionStartElementPath.slice(0, -1));

  requestAnimationFrame(() =>
    Transforms.select(
      editor,
      nextPoint
    ));

  return true;
}

export default onArrowRightBeforeCollapsedContent;
