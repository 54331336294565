import React, {useState} from 'react';
import PropTypes from 'prop-types';
import tw, {styled} from 'twin.macro';
import {motion} from 'framer-motion';
import {AiFillCaretRight} from 'react-icons/ai';

Collapse.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  heading: PropTypes.string,
  open: PropTypes.bool,
};

function Collapse (props) {
  const {
    children,
    heading,
  } = props;

  const [open, setOpen] = useState(props.open);
  const toggle = () => setOpen(!open);

  const arrowVariants = {
    open: {
      rotate: [0, 90],
    },
    closed: {
      rotate: [90, 0],
    },
  };

  const contentVariants = {
    open: {
      height: 'auto',
    },
    closed: {
      height: 0,
    },
  };

  return (
    <Wrapper>
      <Header onClick={toggle}>
        <div tw="relative h-3 w-3 mr-1">
          <motion.div
            style={{position: 'absolute', top: 0, left: 0}}
            animate={open ? 'open' : 'closed'}
            variants={arrowVariants}
            initial={false}
            transition={{duration: 0.15}}>
            <AiFillCaretRight size={12} />
          </motion.div>
        </div>
        {heading}
      </Header>
      <motion.div
        animate={open ? 'open' : 'closed'}
        variants={contentVariants}
        initial={false}
        transition={{duration: 0.15}}
        style={{overflow: 'hidden'}}>
        <Content>
          {children}
        </Content>
      </motion.div>
    </Wrapper>
  );
}

const Wrapper = styled.div((props) => [
  tw`
    cursor-pointer
    mt-6
    mb-4
  `,
]);

const Header = styled.div((props) => [
  tw`
    flex
    items-center
    text-blue-600
    text-sm
    font-bold
  `,
]);
const Content = styled.div((props) => [
  tw`
    pt-3
  `,
]);

export default Collapse;
