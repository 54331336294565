import PropTypes from 'prop-types';
import {useRef} from 'react';
import {useDocumentForm} from 'containers/DocumentForm/providers/DocumentFormProvider';
import useResponseUpdateMutation from './useResponseUpdateMutation';

useDocumentFormInput.propTypes = {
  id: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  responseKey: PropTypes.string.isRequired,
  responseRef: PropTypes.string.isRequired,
  value: PropTypes.any,
};

function useDocumentFormInput (props) {
  const {id} = props;

  const retryTimeoutRef = useRef(null);
  const submissionState = useDocumentForm();
  const dispatch = submissionState && submissionState.dispatch;
  const documentVersion = submissionState && submissionState.documentVersion;
  const responseKey = submissionState && submissionState.responseKey;
  const responseRef = submissionState && submissionState.responseRef;
  const readOnly = submissionState && submissionState.readOnly;
  const value = submissionState && submissionState.inputs[id].value;

  const [update] = useResponseUpdateMutation({
    inputId: id,
    documentVersion,
    onCompleted: () => {
      dispatch({type: 'finish update', id});
      clearTimeout(retryTimeoutRef.current);
      retryTimeoutRef.current = null;
    },
    onError: (error) => {
      dispatch({type: 'fail update', id, error});
      clearTimeout(retryTimeoutRef.current);
      retryTimeoutRef.current = setTimeout(() => {
        update({variables: {value}});
      }, 5000);
    },
    responseKey,
    responseRef,
  });

  const updateValue = (newValue) => {
    clearTimeout(retryTimeoutRef.current);
    retryTimeoutRef.current = null;

    if (JSON.stringify(value) === JSON.stringify(newValue)) {
      return;
    }

    dispatch({
      type: 'start update',
      id,
      value: newValue,
    });

    update({variables: {value: newValue}});
  };

  return [updateValue, {
    readOnly: !!readOnly,
    value,
    submissionMode: !!submissionState,
  }];
}

export default useDocumentFormInput;
