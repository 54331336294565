import {Editor, Text, Transforms} from 'slate';

const supportedMarks = [
  'bold',
  'highlight',
  'italic',
  'strikethrough',
  'underline',
];

function toggleMark (editor, type) {
  if (!supportedMarks.includes(type)) {
    throw new Error(`Unsupported type passed to toggleMark: ${type}`);
  }

  const isMarkActive = checkIsMarkActive(editor, type);

  Transforms.setNodes(
    editor,
    {[type]: isMarkActive ? null : true},
    {
      match: (n) => Text.isText(n),
      split: true,
    }
  );
}

function checkIsMarkActive (editor, type) {
  const isDocumentTitleSelected = checkIfDocumentTitleIsSelected(editor);

  let match;
  if (isDocumentTitleSelected) {
    [match] = Editor.nodes(editor, {
      match: (n) => n[type] === true,
      at: [1],
    });
  }
  else {
    [match] = Editor.nodes(editor, {
      match: (n) => n[type] === true,
      universal: true,
    });
  }

  return !!match;
}

function checkIfDocumentTitleIsSelected (editor) {
  const [match] = Editor.nodes(editor, {
    match: (n) => n.type === 'title',
  });

  return !!match;
}

export default toggleMark;
