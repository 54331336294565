import P from 'components/P';
import React from 'react';
import Comment from './Comment';

WarningImages.propTypes = {
};

function WarningImages (props) {
  return (
    <Comment isWarning shortText="Don't overuse images" {...props}>
      <P>There's a lot of images in this checklist.</P>
      <P>That may be OK — but we recommend you avoid overusing screenshots.</P>
      <P>
        Having too many screenshots can feel overwhelming, and it makes the checklist
        time-consuming to update.
      </P>
    </Comment>
  );
}

export default React.memo(WarningImages);
