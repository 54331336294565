import PropTypes from 'prop-types';
import useItemQuery from './useItemQuery';
import useSearchResultsQuery from './useSearchResultsQuery';
import useWorkspaceItemQuery from './useWorkspaceItemQuery';

useItemAndSearchResultsQueries.propTypes = {
  ref: PropTypes.string,
  key: PropTypes.string,
  searchString: PropTypes.string,
};

function useItemAndSearchResultsQueries (props) {
  const {
    ref,
    key,
    workspaceUrlName,
    searchString,
  } = props;
  const skipGetItem = !ref || !key;

  const {
    data: item,
    isLoading: itemLoading,
    error: itemError,
  } = useItemQuery({
    ref,
    key,
    enabled: !skipGetItem,
  });

  const {
    data: workspaceItem,
    isLoading: workspaceItemLoading,
    error: workspaceItemError,
  } = useWorkspaceItemQuery({
    workspaceUrlName,
    enabled: skipGetItem,
  });

  const {
    data: searchResults,
    isLoading: searchResultsLoading,
    error: searchResultsError,
  } = useSearchResultsQuery({
    ref,
    key,
    workspaceUrlName,
    searchString,
  });

  if (!searchString) {
    return {
      item: item || workspaceItem,
      itemLoading: (ref && itemLoading) || (!ref && workspaceItemLoading),
      itemError: itemError || workspaceItemError,
    };
  }

  return {
    item: item || workspaceItem,
    itemLoading: (ref && itemLoading) || (!ref && workspaceItemLoading),
    itemError: itemError || workspaceItemError,
    searchResults,
    searchResultsLoading,
    searchResultsError,
    isSearchError: !!searchResultsError,
  };
}

export default useItemAndSearchResultsQueries;
