function processesReducer (processes, action) {
  const {
    id: processId,
    type,
    processStep,
  } = action;

  switch (type) {
    case 'open':

      return {
        ...processes,
        [processId]: {
          ...processes[processId],
          open: true,
        },
      };
    case 'close':
      return {
        ...processes,
        [processId]: {
          ...processes[processId],
          open: false,
        },
      };
    case 'markStepDone':
      return {
        ...processes,
        [processId]: {
          ...processes[processId],
          progress: processes[processId].progress.map((done, i) => (i === processStep.order ? true : done)),
        },
      };
    case 'markStepNotDone':
      return {
        ...processes,
        [processId]: {
          ...processes[processId],
          progress: processes[processId].progress.map((done, i) => (i === processStep.order ? false : done)),
        },
      };
    default:
      throw new Error();
  }
}

export default processesReducer;
