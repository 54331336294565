import {gql} from '@apollo/client';
import {STATUS_CODE} from 'am-constants';
import useLazyQuery from 'hooks/useLazyQuery';

const GET_SSO_PROVIDERS = gql`
  query GET_SSO_PROVIDERS($email: String!) {
    ssoProviders(email: $email) {
      id
      name
      description
    }
  }
`;

function useSSOProvidersQuery () {
  const [queryProviders, {data}] = useLazyQuery(GET_SSO_PROVIDERS, {
    fetchPolicy: 'no-cache',
  });

  return [
    queryProviders, {
      providers: data && data.ssoProviders ? data.ssoProviders : null,
      error: data && (!data.ssoProviders || !data.ssoProviders.length) ? {
        type: 'server',
        message: 'This email is not configured for OIDC SSO. Please contact your workspace administrator.',
        code: STATUS_CODE.NOT_FOUND,
      } : undefined,
    }];
}

export default useSSOProvidersQuery;
