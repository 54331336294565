import PropTypes from 'prop-types';
import {gql} from '@apollo/client';
import useMutation from 'hooks/useMutation';
import {useQueryClient} from '@tanstack/react-query';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';

const ITEM_DELETE = gql`
  mutation ITEM_DELETE($itemRef: String!, $itemKey: String!) {
    itemDelete(itemRef: $itemRef, itemKey: $itemKey) {
      id,
    }
  }
`;

useDeleteItemMutation.propTypes = {
  itemRef: PropTypes.string,
  itemKey: PropTypes.string,
};

function useDeleteItemMutation (props) {
  const {
    itemRef,
    itemKey,
  } = props;

  const queryClient = useQueryClient();
  const {urlName} = useWorkspace();

  const [onDeleteItem, {loading, data, error}] = useMutation(ITEM_DELETE, {
    variables: {
      itemRef,
      itemKey,
    },
    refetchQueries: [
      'GetItems',
      'GetItem',
      'GetFolders',
      'GetSearchResults',
      'GetWorkspaceItem',
    ],
    onCompleted: () => {
      queryClient.removeQueries([urlName, 'items', {ref: itemRef, key: itemKey}]);

      // Wait for 500ms so the side panel can close, else react-query will try
      // and reload the side-panel data.
      setTimeout(() => {
        queryClient.invalidateQueries([urlName, 'items']);
      }, 500);
    },
  });

  return [onDeleteItem, {
    loading,
    error,
    data,
  }];
}

export default useDeleteItemMutation;
