import makeParagraph from './makeParagraph';

const makeHelpBlock = () => ({
  type: 'help-block',
  color: 'blue',
  open: true,
  children: [
    {
      type: 'help-block-title',
      children: [
        {
          text: '',
        },
      ],
    },
    {
      type: 'help-block-content',
      children: [
        makeParagraph(),
      ],
    },
  ],
});

export default makeHelpBlock;
