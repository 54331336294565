import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled} from 'twin.macro';
import sortBy from 'lodash.sortby';
import ItemList from './ItemList';

Section.propTypes = {
  name: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  showName: PropTypes.bool,
};

Section.defaultProps = {
  showName: true,
};

function Section (props) {
  const {
    name,
    items,
    showName,
  } = props;

  const sortedItems = sortBy(items, 'rank');

  return (
    <Wrapper>
      {showName && (
        <Name>{name}</Name>
      )}
      <ItemList items={sortedItems} />
    </Wrapper>
  );
}

const Wrapper = styled.div((props) => [
  tw`
    flex-1
  `,
]);

const Name = styled.div((props) => [
  tw`
    bg-gray-50
    text-gray-500
    text-xs
    font-medium
    py-2
    flex
    items-center
  `,
]);

export default Section;
