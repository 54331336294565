import {Transforms} from 'slate';

import makeCheckbox from '../factory/makeCheckbox';
import makeCodeBlock from '../factory/makeCodeBlock';
import makeHeading1 from '../factory/makeHeading1';
import makeHeading2 from '../factory/makeHeading2';
import makeHeading3 from '../factory/makeHeading3';
import makeListItem from '../factory/makeListItem';
import makeOrderedListItem from '../factory/makeOrderedListItem';
import convertSelectedNodes from '../utils/convertSelectedNodes';

const elementTypesThatSupportMarkdownFormatting = [
  'checkbox',
  'list-item',
  'ordered-list-item',
  'heading1',
  'heading2',
  'heading3',
  'heading4',
  'paragraph',
];

function onMarkdown (editor, event, selectionMeta) {
  const {
    selectionStart,
    selectionStartElement,
    isSelectionCollapsed,
  } = selectionMeta;

  if (
    !selectionStart ||
    !isSelectionCollapsed ||
    !elementTypesThatSupportMarkdownFormatting.includes(selectionStartElement.type) ||
    event.key !== ' '
  ) {
    return false;
  }

  if (formatMarkdownWithNumberAtStartOfLine(editor, selectionMeta)) {
    return true;
  }

  if (formatMarkdownAtStartOfLine(editor, selectionMeta)) {
    return true;
  }

  // TODO: Add inline markdown support (e.g. ``)

  return false;
}

function formatMarkdownWithNumberAtStartOfLine (editor, selectionMeta) {
  const {selectionStart, selectionStartTextNode} = selectionMeta;

  const match = selectionStartTextNode.text.match(/^[1-9][0-9]*\./);
  if (!match) {
    return false;
  }

  const numberStr = match[0].slice(0, -1);
  const number = parseInt(numberStr, 10);

  if (numberStr.length !== selectionStart.offset - 1) {
    return false;
  }

  requestAnimationFrame(() => {
    Transforms.delete(editor, {
      reverse: true,
      distance: numberStr.length + 2,
    });

    convertSelectedNodes(editor, {
      makeNode: makeOrderedListItem,
      number,
    });
  });

  return true;
}

function formatMarkdownAtStartOfLine (editor, selectionMeta) {
  const {
    selectionStart,
    selectionStartTextNode,
  } = selectionMeta;

  const formatters = [
    {
      chars: '#',
      format: () => convertSelectedNodes(editor, {makeNode: makeHeading1}),
    },
    {
      chars: '##',
      format: () => convertSelectedNodes(editor, {makeNode: makeHeading2}),
    },
    {
      chars: '###',
      format: () => convertSelectedNodes(editor, {makeNode: makeHeading3}),
    },
    {
      chars: '-',
      format: () => convertSelectedNodes(editor, {makeNode: makeListItem}),
    },
    {
      chars: '[ ]',
      format: () => convertSelectedNodes(editor, {makeNode: makeCheckbox}),
    },
    {
      chars: '```',
      format: () => convertSelectedNodes(editor, {makeNode: makeCodeBlock}),
    },
    {
      chars: '3.',
      format: () => convertSelectedNodes(editor, {makeNode: makeOrderedListItem, number: 3}),
    },
  ];

  const formatter = formatters.find((f) => {
    const prevChars = selectionStartTextNode.text.slice(0, selectionStart.offset);
    return prevChars === f.chars;
  });

  if (!formatter) {
    return false;
  }

  // We requestAnimationFrame so that the space renders before we then try to
  // delete it. We don't want to use event.preventDefault() as doing so would
  // prevent the user from being able to undo our Markdown formatting if they
  // don't want it.
  requestAnimationFrame(() => {
    Transforms.delete(editor, {reverse: true, distance: formatter.chars.length + 1});
    formatter.format();
  });

  return true;
}

export default onMarkdown;
