import React from 'react';
import tw, {styled} from 'twin.macro';
import {Helmet} from 'react-helmet';
import PageCenter from './PageCenter';
import errorImg from './error.jpg';
import Button from './Button';

function ErrorPageLogout () {
  return <>
    <Helmet>
      <title>Could not logout</title>
    </Helmet>
    <PageCenter tw="flex-col">
      <img
        src={errorImg}
        tw="w-full max-w-lg mb-4 mx-auto"
        style={{aspectRatio: '787/457'}}/>
      <Message>An error occurred while logging out. Please try again.</Message>
      <Button onClick={() => window.location.reload()}>Logout</Button>
    </PageCenter>
  </>;
}

const Message = styled.div((props) => [
  tw`
    text-lg
    text-gray-600
    mb-12
  `,
]);

export default ErrorPageLogout;
