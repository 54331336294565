import React from 'react';
import Modal from 'components/Modal';
import Button, {ButtonSpacer} from 'components/Button';
import PropTypes from 'prop-types';
import {ITEM_TYPE} from 'am-constants';

ItemDeleterModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  error: PropTypes.object,
  isVisible: PropTypes.bool.isRequired,
  itemTypeId: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
};

function ItemDeleterModal (props) {
  const {
    closeModal,
    error,
    isVisible,
    itemTypeId,
    onSubmit,
  } = props;

  const deleteMessage = getDeleteMessage(itemTypeId);

  return (
    <Modal
      visible={isVisible}
      title="Delete"
      onClose={closeModal}
      footer={<>
        <Button type="button" link onClick={closeModal}>
          Cancel
        </Button>
        <ButtonSpacer />
        <Button type="button" primary onClick={onSubmit} data-test-id="modal_delete-button">
          Delete
        </Button>
      </>}
    >
      {!error && deleteMessage}
      {error && 'An error has occured. Please check your connection and try again.'}
    </Modal>
  );
}

function getDeleteMessage (itemTypeId) {
  if (itemTypeId === ITEM_TYPE.FOLDER) {
    return 'Are you sure you want to delete this folder?';
  }

  if (itemTypeId === ITEM_TYPE.LINK) {
    return 'Are you sure you want to delete this link?';
  }

  if (itemTypeId === ITEM_TYPE.SECTION) {
    return 'Are you sure you want to delete this section?';
  }

  return 'Are you sure you want to delete this page?';
}

export default ItemDeleterModal;
