import tw, {styled} from 'twin.macro';

const A = styled.a((props) => [
  tw`
    text-blue-500
    hover:text-blue-400
  `,
]);

export default A;
