import React, {useState} from 'react';
import PropTypes from 'prop-types';
import tw, {styled, css} from 'twin.macro';
import Select, {Option} from 'components/Select';
import {AiOutlineDown} from 'react-icons/ai';
import isEqual from 'lodash.isequal';
import {ITEM_ROLE, SHARER_MODAL_OPTIONS} from 'am-constants';

PermissionsSelect.propTypes = {
  disabled: PropTypes.bool,
  initialValue: PropTypes.arrayOf(PropTypes.number),
  roleTypeKey: PropTypes.string.isRequired,
  sharingSettings: PropTypes.object.isRequired,
  setSharingSettings: PropTypes.func.isRequired,
  userId: PropTypes.number,
};

PermissionsSelect.defaultProps = {
  disabled: false,
};

function PermissionsSelect (props) {
  const {
    disabled,
    initialValue,
    roleTypeKey,
    sharingSettings,
    setSharingSettings,
    userId,
  } = props;

  const [selection, setSelection] = useState(initialValue);
  const isInternetAccessSelect = roleTypeKey === 'publicRoleIds';
  const [open, setOpen] = useState(false);

  // Set description
  const selectionDescription = getDescription(selection);
  const disabledOptions = getRequiredSelections(selection);

  const updateUserRoleIds = (newSelection) => {
    const index = sharingSettings.userRoleIds
      .findIndex(({user}) => user.id === userId);

    const updatedUserRoleIds = {
      ...sharingSettings.userRoleIds[index],
      itemRoleIds: getSharingSettingForSelection(newSelection),
    };

    setSharingSettings({
      ...sharingSettings,
      userRoleIds: [
        ...sharingSettings.userRoleIds.slice(0, index),
        updatedUserRoleIds,
        ...sharingSettings.userRoleIds.slice(index + 1),
      ],
    });
  };

  return (
    <Wrapper>
      <Selection data-test-id="current-selection" disabled={disabled} onClick={() => !disabled && setOpen(!open)}>
        {selectionDescription} <AiOutlineDown size={12} tw="mt-0.5 ml-1" />
      </Selection>
      <StyledSelect
        sm
        value={selection}
        mode="multiple"
        data-test-id={'permissions-select'}
        onChange={(value) => {
          if (value.includes(6)) {
            setSelection([]);
            setOpen(false);

            if (roleTypeKey === 'userRoleIds') {
              updateUserRoleIds([]);
              return;
            }

            setSharingSettings({
              ...sharingSettings,
              [roleTypeKey]: getSharingSettingForSelection([]),
            });
          }
          else {
            const requiredSelections = getRequiredSelections(value);
            const allSelections = [...new Set([...value, ...requiredSelections])].sort();
            setSelection(allSelections);

            if (roleTypeKey === 'userRoleIds') {
              updateUserRoleIds(allSelections);
              return;
            }

            setSharingSettings({
              ...sharingSettings,
              [roleTypeKey]: getSharingSettingForSelection(allSelections),
            });
          }
        }}
        open={open}
        onMouseLeave={() => setOpen(false)}
        dropdownRender={(menu) => (
          <div>
            {menu}
            {/* <InheritanceNotice tw="flex">
              <AiFillInfoCircle size={26} tw="mt--1 mr-1.5 ml--px text-blue-500" />
              <div tw="text-2xs">
                These settings are inherited from the Marketing folder.
              </div>
            </InheritanceNotice> */}
          </div>
        )}
        popupClassName='permissions-dropdown'
      >
        {getPermissionOptions({isInternetAccessSelect, selection})
          .map(({key, title}) => (
            <Option
              key={key}
              value={key}
              disabled={disabledOptions.includes(key)}>
              {title === 'Remove access' && <div tw="text-red-600">{title}</div>}
              {title !== 'Remove access' && title}
            </Option>
          ))}
      </StyledSelect>
    </Wrapper>
  );
}

const getPermissionOptions = ({isInternetAccessSelect, selection}) => ([
  {
    key: 1,
    title: 'Can view and use',
  },
  ...(!isInternetAccessSelect ? [
    {
      key: 3,
      title: 'Can edit',
    },
    {
      key: 4,
      title: 'Can view submissions',
    },
    {
      key: 5,
      title: 'Can share',
    },
  ] : []),
  ...(selection.length > 0 ? [
    {
      key: 6,
      title: 'Remove access',
    },
  ] : []),
]);

function getSharingSettingForSelection (selection) {
  const {
    VIEW_AND_USE,
    EDIT,
    VIEW_DATA,
    SHARE,
  } = SHARER_MODAL_OPTIONS;

  const itemPermissions = [];

  if ([VIEW_AND_USE, EDIT, VIEW_DATA, SHARE]
    .every((n) => selection.includes(n))) {
    return [ITEM_ROLE.ADMIN];
  }

  if (selection.includes(VIEW_DATA)) {
    itemPermissions.push(ITEM_ROLE.ANALYST);
  }

  if ([VIEW_AND_USE, EDIT]
    .every((n) => selection.includes(n))) {
    return [...itemPermissions, ITEM_ROLE.EDITOR];
  }

  if ([VIEW_AND_USE].every((n) => selection.includes(n))) {
    return [...itemPermissions, ITEM_ROLE.VIEWER];
  }

  return itemPermissions;
}

function getRequiredSelections (selection) {
  const {
    VIEW_AND_USE,
    EDIT,
    VIEW_DATA,
    SHARE,
  } = SHARER_MODAL_OPTIONS;

  if (selection.includes(SHARE)) {
    return [VIEW_AND_USE, EDIT, VIEW_DATA];
  }

  if (selection.includes(EDIT) || selection.includes(VIEW_DATA)) {
    return [VIEW_AND_USE];
  }

  return [];
}

function getDescription (selection) {
  const {
    VIEW_AND_USE,
    EDIT,
    VIEW_DATA,
    SHARE,
  } = SHARER_MODAL_OPTIONS;

  if (selection.length === 0) {
    return 'No access';
  }
  if (isEqual(selection, [VIEW_AND_USE])) {
    return 'Can view and use';
  }
  if (selection.includes(SHARE)) {
    return 'Full access';
  }
  if (selection.includes(EDIT)) {
    return selection.includes(VIEW_DATA) ? 'Can edit and view data' : 'Can edit';
  }
  if (selection.includes(VIEW_DATA)) {
    return 'Can view submissions';
  }

  throw new Error('Invalid selection');
}

const Wrapper = styled.div((props) => [
  tw`
    relative
    h-8
    flex
    items-center
  `,
]);

const StyledSelect = styled(Select)((props) => [
  tw`
    mt-0
    mb--2.5
  `,
  css`
    & .ant-select-selector {
      max-height: 0;
      opacity: 0;
      & * {
        max-height: 0;
      }
    }

    & .ant-select-arrow {
      display: none;
    }
    `,
]);

const Selection = styled.div((props) => [
  tw`
    absolute
    w-full
    h-full
    z-10
    flex
    justify-end
    items-center
    text-sm
    cursor-pointer
    select-none
  `,
  props.disabled && tw`
    text-gray-400
    cursor-not-allowed
  `,
]);

// const InheritanceNotice = styled.div((props) => [
//   tw`
//     mb--1
//     mt-1
//     bg-gray-100
//     text-gray-600
//     py-2
//     px-3
//     cursor-default
//     text-lg
//   `,
// ]);

export default PermissionsSelect;
