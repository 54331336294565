import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import tw, {styled, css} from 'twin.macro';
import TextArea from 'components/TextArea';
import FormGroup from 'components/FormGroup';
import Label from 'components/Label';
import Button, {ButtonBar} from 'components/Button';
import Form from 'components/Form';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';
import {usePopover} from 'components/Popover/providers/PopoverProvider';
import {CONTENT_ACTION_NEEDED} from 'am-constants';
import Select, {Option} from 'components/Select';
import Link from 'components/Link';
import debounce from 'utils/debounce';

ContentActionNeededCellPopover.propTypes = {
  contentActionNeededId: PropTypes.oneOf([
    CONTENT_ACTION_NEEDED.NEEDS_CONTENT,
    CONTENT_ACTION_NEEDED.NEEDS_UPDATES,
    CONTENT_ACTION_NEEDED.NEEDS_REVIEW,
  ]),
  contentDescription: PropTypes.arrayOf(PropTypes.shape({
    children: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string,
    })),
  })),
  itemUrlName: PropTypes.string.isRequired,
  onUpdateItem: PropTypes.func.isRequired,
};

ContentActionNeededCellPopover.defaultProps = {
};

function ContentActionNeededCellPopover (props) {
  const {
    contentActionNeededId,
    contentDescription,
    itemUrlName,
    onUpdateItem,
  } = props;

  const {setPopoverOpen} = usePopover();
  const {urlName: workspaceUrlName} = useWorkspace();

  const editorUrl = `/${workspaceUrlName}/${itemUrlName}/edit`;

  const handleUpdateContentActionNeededId = (value) => {
    if (!value) {
      setPopoverOpen(false);
    }

    if (value === '') {
      // 100ms delay ensures popover hides before state changes
      setTimeout(() => onUpdateItem({
        contentActionNeededId: null,
        contentPriority: null,
        contentDeadline: null,
      }), 100);

      return;
    }

    onUpdateItem({
      contentActionNeededId: value,
    });
  };

  const handleUpdateContentDescription = useMemo(() => debounce(
    (text) => {
      onUpdateItem({
        // In future we'll be using Slate, so provide in a Slate friendly format
        contentDescription: [{type: 'paragraph', children: [{text}]}],
      });
    },
    500
  ));

  return (
    <Wrapper>
      {!contentActionNeededId && (
        <ButtonBar vertical>
          <Button
            onClick={() => handleUpdateContentActionNeededId(CONTENT_ACTION_NEEDED.NEEDS_UPDATES)}
            fullwidth
            sm>
            Needs updates
          </Button>
          <Button
            onClick={() => handleUpdateContentActionNeededId(CONTENT_ACTION_NEEDED.NEEDS_REVIEW)}
            fullwidth
            sm>
            Needs review
          </Button>
        </ButtonBar>
      )}
      {contentActionNeededId && <>
        {contentActionNeededId !== CONTENT_ACTION_NEEDED.NEEDS_CONTENT && (
          <div tw="flex gap-2">
            <div tw="flex-1">
              <Select
                sm
                value={contentActionNeededId}
                onChange={handleUpdateContentActionNeededId}>
                <Option value={CONTENT_ACTION_NEEDED.NEEDS_UPDATES}>Needs updates</Option>
                <Option value={CONTENT_ACTION_NEEDED.NEEDS_REVIEW}>Needs review</Option>
                <Option value=''>No action needed</Option>
              </Select>
            </div>
          </div>
        )}

        <Form tw="m-0">
          <FormGroup bottom top={contentActionNeededId === CONTENT_ACTION_NEEDED.NEEDS_CONTENT}>
            <Label sm>
              {contentActionNeededId === CONTENT_ACTION_NEEDED.NEEDS_CONTENT && (
                <PlaceholderLabelText>
                  Notes
                </PlaceholderLabelText>
              )}
              {contentActionNeededId === CONTENT_ACTION_NEEDED.NEEDS_UPDATES && 'What updates are needed?'}
              {contentActionNeededId === CONTENT_ACTION_NEEDED.NEEDS_REVIEW && 'What review is needed?'}
            </Label>
            {contentActionNeededId === CONTENT_ACTION_NEEDED.NEEDS_CONTENT ? (
              <TextArea
                placeholder={'What\'s the plan for this page? (optional)'}
                autoFocus
                onChange={(e) => handleUpdateContentDescription(e.target.value)}
                defaultValue={contentDescription ? contentDescription[0].children[0].text : ''}
                tw="w-80!"
              />
            ) : (
              <TextArea
                placeholder=''
                autoFocus
                onChange={(e) => handleUpdateContentDescription(e.target.value)}
                defaultValue={contentDescription ? contentDescription[0].children[0].text : ''}
                tw="w-80!"
              />
            )}
          </FormGroup>
        </Form>
        <FooterLink>
          <Link to={editorUrl}>
            {contentActionNeededId === CONTENT_ACTION_NEEDED.NEEDS_CONTENT && 'Open editor to create page'}
            {contentActionNeededId === CONTENT_ACTION_NEEDED.NEEDS_UPDATES && 'Open editor to make updates'}
            {contentActionNeededId === CONTENT_ACTION_NEEDED.NEEDS_REVIEW && 'Open editor to review'}
          </Link>
        </FooterLink>
      </>}
    </Wrapper>
  );
}

const Wrapper = styled.div((props) => [
  tw`
  `,
  css`
    min-width: 184px;
  `,
]);

const PlaceholderLabelText = styled.div((props) => [
  tw`
    flex
    items-center
    gap-1
  `,
]);

const FooterLink = styled.div((props) => [
  tw`
    text-center
    m--4
    mt-4
    p-4
    pt-4
    bg-gray-50
  `,
]);

export default ContentActionNeededCellPopover;
