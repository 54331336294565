import React from 'react';
import {Helmet} from 'react-helmet';
import FormGroup from 'components/FormGroup';
import Label from 'components/Label';
import Input from 'components/Input';
import Button from 'components/Button';
import SimpleFormContainer from 'components/SimpleFormContainer';
import SimpleFormHeading from 'components/SimpleFormHeading';
import SimpleFormPage from 'components/SimpleFormPage';
import tw, {styled} from 'twin.macro';
import Select, {Option} from 'components/Select';
import PropTypes from 'prop-types';
import Error from 'components/Error';
import friendsTalking from './friends-talking.svg';

NewUserStartPage.propTypes = {
  setAdditionalDetails: PropTypes.func.isRequired,
  error: PropTypes.object,
  loading: PropTypes.bool.isRequired,
};

function NewUserStartPage (props) {
  const {
    setAdditionalDetails,
    error,
    loading,
  } = props;
  const [numEmployees, setNumEmployees] = React.useState('notsure');
  const [businessAreaToUseAirManualForFirst, setBusinessAreaToUseAirManualForFirst] = React.useState('notsure');

  const onSubmit = (e) => {
    e.preventDefault();
    const submittedFormValuesByName = [...e.target.elements]
      .filter(({name}) => name)
      .map(({name, value}) => ({name, value}))
      .reduce((prev, {name, value}) => ({
        ...prev,
        [name]: value,
      }), {});

    setAdditionalDetails({
      ...submittedFormValuesByName,
      numEmployees,
      businessAreaToUseAirManualForFirst,
    });
  };

  return <>
    <Helmet>
      <title>Let's get acquainted</title>
    </Helmet>
    <SimpleFormPage twoColumn>
      <form onSubmit={onSubmit}>
        <Flex>
          <Column>
            <SimpleFormContainer>
              <SimpleFormHeading>Let's get acquainted 👋</SimpleFormHeading>
              <FormGroup>
                <Label>Your job title</Label>
                <Input type="text" name="jobTitle" required maxlength="100" />
              </FormGroup>
              <FormGroup>
                <Label>Your organization's name</Label>
                <Input type="text" name="organizationName" required maxlength="100" />
              </FormGroup>
              <FormGroup>
                <Label>How many people are in your organization?</Label>
                <Select name="numEmployees" required onChange={setNumEmployees}>
                  <Option value="10orfewer">10 or fewer</Option>
                  <Option value="11to25">11 to 25</Option>
                  <Option value="26to50">26 to 50</Option>
                  <Option value="51to100">51 to 100</Option>
                  <Option value="101to250">101 to 250</Option>
                  <Option value="251to1000">251 to 1000</Option>
                  <Option value="1001to5000">1001 to 5000</Option>
                  <Option value="5001ormore">5001 or more</Option>
                  <Option value="notsure">I'm not sure</Option>
                </Select>
              </FormGroup>
              <FormGroup>
                <Label>
                  What business area do you hope to use AirManual for first?
                </Label>
                <Select name="businessAreaToUseAirManualForFirst" required onChange={setBusinessAreaToUseAirManualForFirst}>
                  <Option value="datasecurity">Data Security</Option>
                  <Option value="development">Development</Option>
                  <Option value="design">Design</Option>
                  <Option value="devops">DevOps</Option>
                  <Option value="finance">Finance</Option>
                  <Option value="hr">Human Resources (HR)</Option>
                  <Option value="it">IT</Option>
                  <Option value="legal">Legal</Option>
                  <Option value="marketing">Marketing</Option>
                  <Option value="operations">Operations</Option>
                  <Option value="product">Product</Option>
                  <Option value="randd">R&amp;D</Option>
                  <Option value="sales">Sales</Option>
                  <Option value="notsure">I don't know</Option>
                </Select>
              </FormGroup>
              <FormGroup>
                <Label>
                  How did you hear about AirManual?
                </Label>
                <Input type="text" name="howDidYouHearAboutUs" required maxlength="100" />
              </FormGroup>
              <FormGroup buttons>
                <Button primary lg fullwidth disabled={loading}>Continue</Button>
              </FormGroup>
              {error && !loading && (
                <Error arrow>{error.message}</Error>
              )}
            </SimpleFormContainer>
          </Column>
          <Column tw="pl-0 pr-0 ml--16 mr--32 pb-20">
            <img src={friendsTalking} />
          </Column>
        </Flex>
      </form>
    </SimpleFormPage>
  </>;
}

const Flex = styled.div((props) => [
  tw`
    flex
    items-center
  `,
]);

const Column = styled.div((props) => [
  tw`
    w-full
  `,
]);
export default NewUserStartPage;
