import PropTypes from 'prop-types';
import {gql} from '@apollo/client';
import useMutation from 'hooks/useMutation';

const PAGE_DUPLICATE = gql`
  mutation PAGE_DUPLICATE($destinationWorkspaceUrlName: String!, $itemRef: String!, $itemKey: String!) {
    pageDuplicate(destinationWorkspaceUrlName: $destinationWorkspaceUrlName, itemRef: $itemRef, itemKey: $itemKey) {
      id
      urlName
    }
  }
`;

useDuplicatePageMutation.propTypes = {
  destinationWorkspaceUrlName: PropTypes.string,
  itemRef: PropTypes.string,
  itemKey: PropTypes.string,
};

function useDuplicatePageMutation (props) {
  const {
    destinationWorkspaceUrlName,
    itemRef,
    itemKey,
  } = props;

  const [onDuplicatePage, {loading, data, error}] = useMutation(PAGE_DUPLICATE, {
    variables: {
      destinationWorkspaceUrlName,
      itemRef,
      itemKey,
    },
    refetchQueries: [
      'GetItems',
    ],
  });

  return [onDuplicatePage, {
    loading,
    error,
    pageDuplicate: data ? data.pageDuplicate : undefined,
  }];
}

export default useDuplicatePageMutation;
