import isEqual from 'lodash.isequal';
import {Editor, Path, Range} from 'slate';

function getSelectionMeta (editor) {
  const selectionStart = Editor.start(editor, editor.selection);
  const [selectionStartTextNode, selectionStartTextNodePath] = selectionStart ? Editor.node(editor, selectionStart) : [undefined, undefined];
  const selectionStartElementPath = selectionStart ? Path.parent(selectionStart.path) : undefined;
  const [selectionStartElement] = selectionStart ? Editor.node(editor, selectionStartElementPath) : [undefined];
  const selectionStartAncestors = selectionStart ? getAncestors(editor, selectionStartElementPath) : undefined;
  const selectionEnd = Editor.end(editor, editor.selection);
  const [selectionEndTextNode, selectionEndTextNodePath] = selectionEnd ? Editor.node(editor, selectionEnd) : [undefined, undefined];
  const selectionEndElementPath = selectionEnd ? Path.parent(selectionEnd.path) : undefined;
  const [selectionEndElement] = selectionEnd ? Editor.node(editor, selectionEndElementPath) : [undefined];
  const selectionEndAncestors = selectionEnd ? getAncestors(editor, selectionEndElementPath) : undefined;
  const isSelectionCollapsed = selectionStart && Range.isCollapsed(editor.selection);
  const isSelectionInSingleElement = selectionStart && isEqual(selectionStart.path, selectionEnd.path);
  const isSelectionAtStartOfElement = selectionStart && isSelectionCollapsed && Editor.isStart(editor, selectionStart, selectionStartElementPath);
  const isSelectionAtEndOfElement = selectionStart && isSelectionCollapsed && Editor.isEnd(editor, selectionStart, selectionStartElementPath);
  const isAnchorBeforeFocus = !!editor.selection && (
    Path.isBefore(editor.selection.anchor.path, editor.selection.focus.path) ||
    (
      isEqual(editor.selection.anchor.path, editor.selection.focus.path) &&
      editor.selection.anchor.offset < editor.selection.focus.offset
    )
  );

  let selectionAnchor;
  let selectionAnchorAncestors;
  let selectionAnchorElement;
  let selectionAnchorElementPath;
  let selectionAnchorTextNode;
  let selectionAnchorTextNodePath;
  let selectionFocus;
  let selectionFocusAncestors;
  let selectionFocusElement;
  let selectionFocusElementPath;
  let selectionFocusTextNode;
  let selectionFocusTextNodePath;

  if (editor.selection) {
    if (isAnchorBeforeFocus) {
      selectionAnchor = selectionStart;
      selectionAnchorAncestors = selectionStartAncestors;
      selectionAnchorElement = selectionStartElement;
      selectionAnchorElementPath = selectionStartElementPath;
      selectionAnchorTextNode = selectionStartTextNode;
      selectionAnchorTextNodePath = selectionStartTextNodePath;
      selectionFocus = selectionEnd;
      selectionFocusAncestors = selectionEndAncestors;
      selectionFocusElement = selectionEndElement;
      selectionFocusElementPath = selectionEndElementPath;
      selectionFocusTextNode = selectionEndTextNode;
      selectionFocusTextNodePath = selectionEndTextNodePath;
    }
    else {
      selectionAnchor = selectionEnd;
      selectionAnchorAncestors = selectionEndAncestors;
      selectionAnchorElement = selectionEndElement;
      selectionAnchorElementPath = selectionEndElementPath;
      selectionAnchorTextNode = selectionEndTextNode;
      selectionAnchorTextNodePath = selectionEndTextNodePath;
      selectionFocus = selectionStart;
      selectionFocusAncestors = selectionStartAncestors;
      selectionFocusElement = selectionStartElement;
      selectionFocusElementPath = selectionStartElementPath;
      selectionFocusTextNode = selectionStartTextNode;
      selectionFocusTextNodePath = selectionStartTextNodePath;
    }
  }

  return {
    isSelectionAtEndOfElement,
    isSelectionAtStartOfElement,
    isAnchorBeforeFocus,
    isSelectionCollapsed,
    isSelectionInSingleElement,
    selectionAnchor,
    selectionAnchorAncestors,
    selectionAnchorElement,
    selectionAnchorElementPath,
    selectionAnchorTextNode,
    selectionAnchorTextNodePath,
    selectionEnd,
    selectionEndAncestors,
    selectionEndElement,
    selectionEndElementPath,
    selectionEndTextNode,
    selectionEndTextNodePath,
    selectionFocus,
    selectionFocusAncestors,
    selectionFocusElement,
    selectionFocusElementPath,
    selectionFocusTextNode,
    selectionFocusTextNodePath,
    selectionStart,
    selectionStartAncestors,
    selectionStartElement,
    selectionStartElementPath,
    selectionStartTextNode,
    selectionStartTextNodePath,
  };
}

function getAncestors (editor, elementPath) {
  if (elementPath.length === 1) {
    return [];
  }

  let [parent, path] = Editor.parent(editor, elementPath);
  const ancestors = [parent];
  while (parent && path.length >= 2) {
    [parent, path] = Editor.parent(editor, path);
    ancestors.push(parent);
  }

  return ancestors;
}

export default getSelectionMeta;
