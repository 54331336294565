import {Editor} from 'slate';
import makeParagraph from '../factory/makeParagraph';
import makeProcess from '../factory/makeProcess';

// Copies a selected element as a fragment. This is needed because the editor's
// selection isn't set to cover the selected elements, so the default copy
// behavior won't work.
function onCopySelectedElements (editor, data, selectionMeta, selectedElements) {
  if (
    selectedElements.ids.length === 0 ||
    selectedElements.inRange
  ) {
    return false;
  }

  const elements = Array.from(Editor.nodes(
    editor,
    {
      at: [],
      match: (n) => selectedElements.ids.includes(n.id),
    }
  )).map(([element, path]) => element);

  const fragment = [
    // Adding a paragraph before and after bugs when the start/middle/end of a
    // paragraph. But it's not an ideal solution as it adds a new line even when
    // one isn't needed.
    makeParagraph(),
    ...getWrappedElements(elements),
    makeParagraph(),
  ];
  const string = JSON.stringify(fragment);
  const encoded = window.btoa(encodeURIComponent(string));
  data.setData('application/x-slate-fragment', encoded);

  return true;
}

function getWrappedElements (elements) {
  if (elements[0].type !== 'process-step') {
    return elements;
  }

  return [makeProcess({processSteps: elements})];
}

export default onCopySelectedElements;
