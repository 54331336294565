import {Editor, Transforms, Range} from 'slate';
import checkCanElementTypeBeTransformed from './checkCanElementTypeBeTransformed';

// This looks simple but was very painful to get working as desired.
// It needs to handle:
// - Adding a node when the cursor is at the start of some text
// - Adding a node when the cursor is in the middle of some text
// - Adding a node when the cursor is at the end of some text
// - Adding a node when the cursor is placed on an empty text string

// TODO: Review if this can be replaced by the built-in editor.insertFragment
function insertNodeAtSelection (editor, {makeNode, ...rest}) {
  const endOfSelection = Editor.end(editor, editor.selection);
  const [node] = Editor.node(editor, endOfSelection.path);
  const [element] = Editor.parent(editor, endOfSelection.path);
  const {offset} = endOfSelection;

  if (!Range.isCollapsed(editor.selection)) {
    throw new Error('insertNodeAtSelection has not been tested with having a range selected. May not work as expected. Remove this error and proceed with caution!');
  }

  const newNode = makeNode(rest);
  let removedPreviousNode = false;

  Editor.withoutNormalizing(editor, () => {
    // Insert new node
    Transforms.insertNodes(
      editor,
      newNode,
      {
        at: endOfSelection,
      }
    );
    // If a node contains text and the selected offset is 0, then Slate will
    // remove the node and add a new one containing the content after the new node.
    removedPreviousNode = node.text.length > 0 && offset === 0;

    // Remove previous node (if desirable)
    if (checkCanElementTypeBeTransformed(element.type) && node.text.length === 0) {
      Transforms.removeNodes(editor, endOfSelection);
      removedPreviousNode = true;
    }
  });

  // Select new node
  const previousNodePoint = Editor.before(editor, {
    path: endOfSelection.path,
    offset: 0,
  });
  const {path: newNodePath} = removedPreviousNode
    ? Editor.after(editor, previousNodePoint)
    : Editor.after(editor, endOfSelection);

  requestAnimationFrame(() =>
    Transforms.select(
      editor,
      newNodePath
    ));
}

export default insertNodeAtSelection;
