function selectedElementsReducer (selectedElements, action) {
  const {type, elementIds} = action;

  switch (type) {
    case 'selectElements':
      return {
        ids: elementIds,
        inRange: false,
      };
    case 'selectElementsInRange':
      return {
        ids: elementIds,
        inRange: true,
      };
    default:
      throw new Error();
  }
}

export default selectedElementsReducer;
