import {Path} from 'slate';
import insertProcessStep from '../utils/insertProcessStep';

function onEnterInProcessStepTitle (editor, event, selectionMeta) {
  const {
    isSelectionAtStartOfElement,
    isSelectionInSingleElement,
    selectionStart,
    selectionStartElementPath,
    selectionStartElement,
    selectionStartTextNode,
  } = selectionMeta;

  if (
    event.key !== 'Enter' ||
    event.shiftKey ||
    !selectionStart ||
    !isSelectionInSingleElement ||
    selectionStartElement.type !== 'process-step-title'
  ) {
    return false;
  }

  event.preventDefault();

  const addStepAbove = isSelectionAtStartOfElement && selectionStartTextNode.text.length > 0;
  const newProcessStepPath = addStepAbove
    ? Path.parent(selectionStartElementPath)
    : Path.next(Path.parent(selectionStartElementPath));

  insertProcessStep(editor, newProcessStepPath);

  return true;
}

export default onEnterInProcessStepTitle;
