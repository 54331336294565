function onBackspaceAtStartOfChoiceGroup (editor, event, selectionMeta) {
  const {
    isSelectionAtStartOfElement,
    selectionStart,
    selectionStartElement,
    selectionStartElementPath,
  } = selectionMeta;

  if (
    event.key !== 'Backspace' ||
    !selectionStart ||
    selectionStartElement.type !== 'choice' ||
    !isSelectionAtStartOfElement ||
    selectionStartElementPath.slice(-1)[0] !== 0
  ) {
    return false;
  }

  event.preventDefault();

  return true;
}

export default onBackspaceAtStartOfChoiceGroup;
