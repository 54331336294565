import PropTypes from 'prop-types';
import {gql} from '@apollo/client';
import useMutation from 'hooks/useMutation';

const UNDO_DOCUMENT_UPDATE = gql`
  mutation documentUndoUpdate($itemRef: String!, $itemKey: String!) {
    documentUndoUpdate(itemRef: $itemRef, itemKey: $itemKey) {
      id,
      content,
      version
    }
  }
`;

useUndoDocumentUpdateMutation.propTypes = {
  itemRef: PropTypes.string.isRequired,
  itemKey: PropTypes.string.isRequired,
  onUpdated: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

function useUndoDocumentUpdateMutation (props) {
  const {
    itemRef,
    itemKey,
  } = props;

  const [onUndoDocumentUpdate, {loading, error, data}] = useMutation(UNDO_DOCUMENT_UPDATE, {
    variables: {
      itemRef,
      itemKey,
    },
  });

  return [onUndoDocumentUpdate, {
    loading,
    error,
    data,
  }];
}

export default useUndoDocumentUpdateMutation;
