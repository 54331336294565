import React, {useEffect, useRef} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import tw, {styled, css} from 'twin.macro';
import {AnimatePresence, motion} from 'framer-motion';
import {AiOutlineClose} from 'react-icons/ai';
import usePortal from 'hooks/usePortal';
import Heading3 from './Heading3';
import ModalError from './ModalError';

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  footer: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  isBoldTitle: PropTypes.bool,
  errorTitle: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  renderError: PropTypes.func,
  size: PropTypes.string,
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
};

Modal.defaultProps = {
  errorTitle: undefined,
  isBoldTitle: false,
  // If no onClose function is provided, the modal cannot be closed
  onClose: undefined,
  renderError: undefined,
};

function Modal (props) {
  const {
    children,
    errorTitle,
    footer,
    isBoldTitle,
    onClose,
    onSubmit,
    renderError,
    size,
    title,
    visible,
  } = props;

  const portalContainer = usePortal('modal-container');
  const body = useRef(null);
  const hasError = !!renderError;

  useEffect(() => {
    const handleKeyDown = (event) => event.key === 'Escape' && onClose !== undefined && onClose();
    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  useEffect(() => {
    if (visible && body.current) {
      const focusElement = body.current.getElementsByClassName('modal-focus')[0];
      if (focusElement) {
        setTimeout(() => focusElement.focus(), 0);
      }
    }
  }, [visible]);

  return ReactDOM.createPortal(<>
    <AnimatePresence>
      {visible &&
      <ModalAnimation
        initial={{scale: 0.8}}
        animate={{scale: 1, transition: {duration: 0.2}}}
        exit={{scale: 0.8}}
      >
        <ModalContainer data-test-id="modal" size={size}>
          <ModalHeader>
            <ModalHeading>
              {isBoldTitle ? <strong tw="font-semibold">{title}</strong> : title}
            </ModalHeading>
            <div tw="flex-1"></div>
            {onClose !== undefined && <CloseIcon onClick={onClose}><AiOutlineClose /></CloseIcon>}
          </ModalHeader>
          <form onSubmit={onSubmit}>
            <ModalBody ref={body}>
              {children}
            </ModalBody>
            {hasError && <ModalError title={errorTitle}>{renderError()}</ModalError>}
            {footer && <ModalFooter hasError={hasError}>
              {footer}
            </ModalFooter>}
          </form>
        </ModalContainer>
      </ModalAnimation>
      }
    </AnimatePresence>
    {visible && <Screen />}
  </>, portalContainer);
}

const Screen = styled.div((props) => [
  tw`
    fixed
    top-0
    left-0
    w-full
    h-screen
    bg-gray-900
    opacity-50
  `,
  css`
    z-index: 1000
  `,
]);

const ModalAnimation = styled(motion.div)((props) => [
  tw`
    relative
  `,
  css`
    z-index: 1001;
  `,
]);

const ModalContainer = styled.div((props) => [
  tw`
    bg-white
    mx-auto
    shadow-lg
    max-w-full
    text-gray-700
  `,
  css`
    margin-top: ${window.pageYOffset}px;
    width: 36rem;
  `,
  props.size === 'lg' && css`
    width: 40rem;
  `,
]);

const ModalHeader = styled.div((props) => [
  tw`
    pt-5
    pb-3
    px-6
    flex
  `,
]);

const CloseIcon = styled.div((props) => [
  tw`
    text-gray-500
    cursor-pointer
    p-2
    m--2
    hover:text-gray-700
  `,
]);

const ModalHeading = styled(Heading3)((props) => [
  tw`
    m-0!
  `,
]);

const ModalBody = styled.div((props) => [
  tw`
    pt-3
    pb-5
    px-6
  `,
]);

const ModalFooter = styled.div((props) => [
  tw`
    py-3.5
    px-6
    border-t
    border-gray-100
    justify-end
    flex
  `,
  props.hasError && tw`
    border-t-0
  `,
]);

export default Modal;
