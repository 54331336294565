import {Editor, Transforms} from 'slate';

function onArrowRightFromEndOfFirstRatingScaleChoice (editor, event, selectionMeta) {
  const {
    isSelectionAtEndOfElement,
    selectionStart,
    selectionStartElement,
    selectionStartElementPath,
  } = selectionMeta;

  if (
    event.key !== 'ArrowRight' ||
    !selectionStart ||
    selectionStartElement.type !== 'choice' ||
    !isSelectionAtEndOfElement
  ) {
    return false;
  }

  const [choiceGroupElement] = Editor.parent(editor, selectionStartElementPath);
  const {format} = choiceGroupElement;

  if (
    format !== 'rating' ||
    selectionStartElementPath[selectionStartElementPath.length - 1] !== 0
  ) {
    return false;
  }

  event.preventDefault();

  const startOfLastLabel = Editor.start(editor, [...selectionStartElementPath.slice(0, -1), choiceGroupElement.children.length - 1]);
  Transforms.select(editor, startOfLastLabel);

  return true;
}

export default onArrowRightFromEndOfFirstRatingScaleChoice;
