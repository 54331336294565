import {Editor, Transforms} from 'slate';
import makeParagraph from '../factory/makeParagraph';

// A "choice" element could be orphaned by selecting a block of text that
// covers the start of a choice group and the content before it, and then
// pressing any key. Slate will create a new "choice" node group before the
// form-group, so let's convert it to a paragraph.
function makeSureNoOrphanChoices (editor, [entryNode, entryNodePath]) {
  if (entryNode.type !== 'choice') {
    return false;
  }

  const [parentNode] = Editor.parent(editor, entryNodePath);
  if (parentNode.type === 'choice-group') {
    return false;
  }

  Transforms.setNodes(editor, makeParagraph(), {
    at: entryNodePath,
  });

  return true;
}

export default makeSureNoOrphanChoices;
