import React from 'react';
import PropTypes from 'prop-types';

const GridRowContext = React.createContext();

GridRowProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

function GridRowProvider (props) {
  const {children, ...rest} = props;

  const context = {
    ...rest,
  };

  return (
    <GridRowContext.Provider value={context}>
      {children}
    </GridRowContext.Provider>
  );
}

export function useGridRow () {
  const context = React.useContext(GridRowContext);

  if (context === undefined) {
    throw new Error('useGridRow must be used within a GridRowProvider');
  }

  return context;
}

export default GridRowProvider;
