import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {Editable, Slate} from 'slate-react';
import tw, {styled, css} from 'twin.macro';
// eslint-disable-next-line import/no-cycle
import renderElementInInput from '../editable/renderElementInInput';
import renderLeaf from '../editable/renderLeaf';

TextAreaEditor.propTypes = {
  onChange: PropTypes.func,
  editor: PropTypes.shape({
    selection: PropTypes.shape({}),
    operations: PropTypes.array,
  }),
  readOnly: PropTypes.bool,
  value: PropTypes.array,
};

TextAreaEditor.defaultProps = {
};

function TextAreaEditor (props) {
  const {
    onChange,
    editor,
    readOnly,
    value,
  } = props;

  return <>
    <Slate
      editor={editor}
      value={value}
      onChange={onChange}>
      <StyledEditable
        placeholder=""
        renderElement={useCallback(renderElementInInput)}
        renderLeaf={useCallback(renderLeaf)}
        readOnly={readOnly}
        spellCheck />
    </Slate>
  </>;
}

const StyledEditable = styled(Editable)((props) => [
  tw`
    border
    mb-0.5
    px-2.5
    py-2.5
    border
    border-gray-200
    focus:outline-none
    focus:border-blue-300
    focus:bg-white
  `,
  props.readOnly && tw`
    cursor-not-allowed
  `,
  css`
    min-height: 128px;
  `,
]);

export default TextAreaEditor;
