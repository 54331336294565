import getSelectionMeta from '../utils/getSelectionMeta';
import onSelectFromOutsideSelectableElement from '../selectionHandlers/onSelectFromOutsideSelectableElement';
import onSelectFromSelectableElement from '../selectionHandlers/onSelectFromSelectableElement';
import onSelectOfTextElementAndStartOfNextLine from '../selectionHandlers/onSelectOfTextElementAndStartOfNextLine';
import onSelectFromRatingScaleLabel from '../selectionHandlers/onSelectFromRatingScaleLabel';

const makeOnChange = (editor, {selectElements, selectElementsInRange, selectedElements}, onChange = () => {}) => (value) => {
  const {operations, selection} = editor;

  if (
    operations.length > 0 &&
    operations.find((o) => o.type === 'set_selection') &&
    !!selection
  ) {
    onSelect(editor, {selectElements, selectElementsInRange});
  }

  onChange(value);
};

function onSelect (editor, selectionFns) {
  const selectionMeta = getSelectionMeta(editor);
  const {isSelectionInSingleElement} = selectionMeta;

  if (isSelectionInSingleElement) {
    return false;
  }

  return (
    onSelectOfTextElementAndStartOfNextLine(editor) ||
    onSelectFromRatingScaleLabel(editor, selectionMeta) ||
    onSelectFromSelectableElement(editor, selectionMeta, selectionFns) ||
    onSelectFromOutsideSelectableElement(editor, selectionMeta, selectionFns)
  );
}

export default makeOnChange;
