import PropTypes from 'prop-types';
import {gql} from '@apollo/client';
import {BREADCRUMB_FIELDS} from 'fragments/Breadcrumb';
import {ITEM_FIELDS} from 'fragments/Item';
import useQuery from 'hooks/useQuery';

const GET_RESPONSE_WITH_DOCUMENT_SNAPSHOT_AND_RESPONSE_SUBMISSION = gql`
  query GET_RESPONSE_WITH_DOCUMENT_SNAPSHOT_AND_RESPONSE_SUBMISSION($ref: String!, $key: String!) {
    response(ref: $ref, key: $key) {
      responseSubmissionId
      data
      documentSnapshot {
        version
        content
      }
      responseSubmission {
        id
        createdAt
      }
      item {
        ${ITEM_FIELDS}
        breadcrumbs {
          ${BREADCRUMB_FIELDS}
        }
      }
    }
  }
`;

useResponseWithDocumentSnapshotAndResponseSubmissionQuery.propTypes = {
  responseRef: PropTypes.string.isRequired,
  responseKey: PropTypes.string.isRequired,
  onLoaded: PropTypes.func.isRequired,
};

function useResponseWithDocumentSnapshotAndResponseSubmissionQuery (props) {
  const {
    responseRef: ref,
    responseKey: key,
    onLoaded,
  } = props;

  const {loading, error, data} = useQuery(GET_RESPONSE_WITH_DOCUMENT_SNAPSHOT_AND_RESPONSE_SUBMISSION, {
    fetchPolicy: 'no-cache',
    variables: {
      ref,
      key,
    },
    onCompleted: onLoaded,
  });

  return {
    response: data && data.response ? data.response : null,
    loading,
    error,
  };
}

export default useResponseWithDocumentSnapshotAndResponseSubmissionQuery;
