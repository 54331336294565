import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import DropdownButton from 'components/DropdownButton';
import {PERMISSIONS} from 'am-constants';
import {getPermissionsForRoles} from 'am-utils';

UnpublishedPageMenu.propTypes = {
  item: PropTypes.shape({
    urlName: PropTypes.string.isRequired,
    ref: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    loggedInUserRoleIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    name: PropTypes.string.isRequired,
  }),
  setIsDeleteItemModalVisible: PropTypes.func.isRequired,
  setIsMoveFolderModalVisible: PropTypes.func.isRequired,
  setFocusedItem: PropTypes.func.isRequired,
  workspaceUrlName: PropTypes.string.isRequired,
};

function UnpublishedPageMenu (props) {
  const {
    item,
    setFocusedItem,
    setIsDeleteItemModalVisible,
    setIsMoveFolderModalVisible,
    workspaceUrlName,
  } = props;
  const {loggedInUserRoleIds} = item;
  const loggedInUserItemPermissions = getPermissionsForRoles(loggedInUserRoleIds);

  useEffect(() => {
    setFocusedItem(item);
  });

  const menu = [[
    {
      disabled: !loggedInUserItemPermissions.includes(PERMISSIONS.EDIT_PAGE),
      label: 'Edit',
      to: `/${workspaceUrlName}/${item.urlName}/edit`,
    },
    {
      disabled: !loggedInUserItemPermissions.includes(PERMISSIONS.MOVE_ITEM),
      onClick: () => setIsMoveFolderModalVisible(true),
      label: 'Move',
    },
    {
      disabled: !loggedInUserItemPermissions.includes(PERMISSIONS.DELETE_ITEM),
      onClick: () => setIsDeleteItemModalVisible(true),
      label: 'Delete',
    },
  ]];

  return (
    <>
      <DropdownButton menu={menu} sm data-test-id="options-menu">Options</DropdownButton>
    </>);
}

export default UnpublishedPageMenu;
