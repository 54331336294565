import {gql} from '@apollo/client';
import useQuery from 'hooks/useQuery';

const GET_PASSWORD_RESET_REQUEST = gql`
  query passwordResetRequest($resetKey: String!) {
    passwordResetRequest(resetKey: $resetKey) {
      resetKey
      expired
      used
    }
  }
`;

function usePasswordResetRequestQuery (resetKey) {
  const {
    loading,
    error,
    data,
  } = useQuery(GET_PASSWORD_RESET_REQUEST, {
    fetchPolicy: 'no-cache',
    variables: {resetKey},
  });

  return {
    data,
    loading,
    error,
  };
}

export default usePasswordResetRequestQuery;
