import tw, {styled} from 'twin.macro';

const Heading1 = styled.h2((props) => [
  tw`
    text-4xl
    text-gray-800
    mt-12
    mb-4
    clear-both
  `,
]);

export default Heading1;
