import {nanoid} from 'nanoid';
import {Transforms} from 'slate';
import makeImage from '../factory/makeImage';
import insertNodeAtSelection from '../utils/insertNodeAtSelection';

function insertImage (editor, data, selectionMeta, {uploadBase64Image}) {
  const {files, types} = data;

  if (
    !files ||
    files.length === 0 ||
    // MSWord includes various text types _and_ a screenshot. We don't want to
    // use the screenshot!
    types.includes('text/plain')
  ) {
    return false;
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const file of files) {
    const reader = new FileReader();
    const [mime] = file.type.split('/');

    if (mime === 'image') {
      // eslint-disable-next-line no-loop-func
      reader.addEventListener('load', () => {
        const id = nanoid();
        const imgData = reader.result;

        const tempImg = new Image();
        tempImg.onload = () => {
          const {width, height} = tempImg;
          insertNodeAtSelection(editor, {
            makeNode: () => makeImage({
              id,
              // As in makeApply.js we make sure images have unique id's even
              // when copied and pasted, we need to store the original id as an
              // attribute to replace the buffering placeholder.
              idBeforeMakeApply: id,
              width,
              height,
            }),
          });
        };
        tempImg.src = imgData;

        uploadBase64Image({
          id,
          data: imgData,
          file,
          onSuccess: (url) => {
            Transforms.setNodes(editor, {url, idBeforeMakeApply: undefined}, {
              match: (n) => n.idBeforeMakeApply === id,
              at: editor,
            });
          },
        });
      });

      reader.readAsDataURL(file);
    }
  }

  return true;
}

export default insertImage;
