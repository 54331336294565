import {Path} from 'slate';
import removeProcessStep from '../utils/removeProcessStep';

function onBackspaceAtStartOfProcessStepTitle (editor, event, selectionMeta) {
  const {
    isSelectionAtStartOfElement,
    selectionStart,
    selectionStartElementPath,
    selectionStartElement,
    selectionStartTextNode,
  } = selectionMeta;

  if (
    event.key !== 'Backspace' ||
    !selectionStart ||
    selectionStartElement.type !== 'process-step-title' ||
    !isSelectionAtStartOfElement
  ) {
    return false;
  }

  if (selectionStartTextNode.text.length > 0) {
    event.preventDefault();
    return true;
  }

  const processStepElementPath = Path.parent(selectionStartElementPath);
  const isFirstStep = processStepElementPath.slice(-1)[0] === 0;
  if (isFirstStep) {
    event.preventDefault();
    return true;
  }

  event.preventDefault();
  removeProcessStep(editor, processStepElementPath);

  return true;
}

export default onBackspaceAtStartOfProcessStepTitle;
