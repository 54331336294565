const PERMISSIONS = {
  // Workspace permissions
  VIEW_WORKSPACE: 1,
  MANAGE_WORKSPACE_GENERAL_SETTINGS: 2,
  MANAGE_WORKSPACE_TEAM_MEMBERS: 3,
  MANAGE_WORKSPACE_BILLING: 4,

  // Generic item permissions
  VIEW_ITEM: 100,
  MOVE_ITEM: 101,
  MAKE_ITEM_PUBLIC: 102,
  MANAGE_ITEM_ACCESS: 103,
  DELETE_ITEM: 104,
  VIEW_DRAFT: 105,
  UPDATE_ITEM: 106,
  MANAGE_ITEM_AUTOMATIONS: 107,

  // Page specific permissions
  EDIT_PAGE: 200,
  PUBLISH_PAGE: 201,

  // Folder specific permissions
  RENAME_FOLDER: 300,
  ADD_ITEM_TO_FOLDER: 301,

  // Form and checklist specific permissions
  USE_FORM_OR_CHECKLIST: 400,
  VIEW_RESPONSES: 401,
  VIEW_OWN_RESPONSES: 402,
  DELETE_RESPONSES: 403,

  // Legacy permissions
  MANAGE_ITEM: 900, // TODO: Replace with more granular permissions
  MANAGE_WORKSPACE: 901, // TODO: Replace with more granular permissions

  // Test permission, nobody should have access to this
  UNOBTAINABLE_PERMISSION: 800,
};

const WORKSPACE_ROLE = {
  ADMIN: 1,
  MEMBER: 2,
};

const WORKSPACE_ROLE_NAMES = {
  1: "Admin",
  2: "Member",
};

const workspaceMemberPermissions = [PERMISSIONS.VIEW_WORKSPACE];

const workspaceAdminPermissions = [
  ...workspaceMemberPermissions,
  PERMISSIONS.MANAGE_WORKSPACE_GENERAL_SETTINGS,
  PERMISSIONS.MANAGE_WORKSPACE_TEAM_MEMBERS,
  PERMISSIONS.MANAGE_WORKSPACE_BILLING,
];

const WORKSPACE_ROLE_PERMISSIONS = {
  [WORKSPACE_ROLE.MEMBER]: workspaceMemberPermissions,
  [WORKSPACE_ROLE.ADMIN]: workspaceAdminPermissions,
};

const ITEM_ROLE = {
  VIEWER: 1,
  EDITOR: 2,
  ANALYST: 4,
  ADMIN: 5,
};

const itemViewerPermissions = [
  PERMISSIONS.VIEW_WORKSPACE,
  PERMISSIONS.VIEW_ITEM,
  PERMISSIONS.USE_FORM_OR_CHECKLIST,
  PERMISSIONS.VIEW_OWN_RESPONSES,
];

const itemEditorPermissions = [
  ...itemViewerPermissions,
  PERMISSIONS.VIEW_DRAFT,
  PERMISSIONS.EDIT_PAGE,
  PERMISSIONS.MOVE_ITEM,
  PERMISSIONS.DELETE_ITEM,
  PERMISSIONS.ADD_ITEM_TO_FOLDER,
  PERMISSIONS.RENAME_FOLDER,
  PERMISSIONS.PUBLISH_PAGE,
  PERMISSIONS.UPDATE_ITEM,
];

const itemAnalystPermissions = [
  PERMISSIONS.VIEW_RESPONSES,
  PERMISSIONS.DELETE_RESPONSES
];

const itemAdminPermissions = [
  ...itemEditorPermissions,
  ...itemAnalystPermissions,
  PERMISSIONS.MAKE_ITEM_PUBLIC,
  PERMISSIONS.MANAGE_ITEM_ACCESS,
  PERMISSIONS.MANAGE_ITEM_AUTOMATIONS
];

const ITEM_ROLE_PERMISSIONS = {
  [ITEM_ROLE.VIEWER]: itemViewerPermissions,
  [ITEM_ROLE.EDITOR]: itemEditorPermissions,
  [ITEM_ROLE.ANALYST]: itemAnalystPermissions,
  [ITEM_ROLE.ADMIN]: itemAdminPermissions,
};

const SUBSCRIPTION_TYPE = {
  LEGACY: 1,
  STARTER: 2,
  TEAM: 3,
};

module.exports = {
  ITEM_ROLE,
  ITEM_ROLE_PERMISSIONS,
  PERMISSIONS,
  SUBSCRIPTION_TYPE,
  WORKSPACE_ROLE,
  WORKSPACE_ROLE_NAMES,
  WORKSPACE_ROLE_PERMISSIONS,
};
