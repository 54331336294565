import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled} from 'twin.macro';
import {Draggable} from 'react-beautiful-dnd';
import ProcessStep from './ProcessStep';

DraggableProcessStep.propTypes = {
  element: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
};

function DraggableProcessStep (props) {
  const {element: {id}, index} = props;

  return (
    <Draggable
      draggableId={id}
      key={id}
      index={index}>
      {(provided, snapshot) => (
        <DragContainer
          ref={provided.innerRef}
          {...provided.draggableProps}
          isDragging={snapshot.isDragging}>
          <ProcessStep
            dragHandleProps={provided.dragHandleProps}
            style={provided.draggableProps.style}
            editing
            {...props} />
        </DragContainer>
      )}
    </Draggable>
  );
}

export const ProcessStepContext = React.createContext();

const DragContainer = styled.div((props) => [
  props.isDragging && tw`
    border-green-500
  `,
]);

export default DraggableProcessStep;
