import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import tw, {styled} from 'twin.macro';

import 'react-datepicker/dist/react-datepicker.css';
import './custom-datepicker-styles.css';
import ReactDatePicker from 'react-datepicker';
import {nanoid} from 'nanoid';

DatePicker.propTypes = {
  selected: PropTypes.instanceOf(Date),
};

DatePicker.defaultProps = {
};

function DatePicker (props) {
  const id = useMemo(nanoid, []);

  return (
    <Wrapper id={id}>
      {/* Hacky way of showing that a value hasn't been selected yet. For
      some reason react-datepicker always wants to open up indicating that a
      value has been selected when it hasn't yet! */}
      {!props.selected && (
        <style>{`
          #${id} .react-datepicker__day--keyboard-selected {
            background-color: white;
            color: inherit;
          }
        `}</style>
      )}
      <ReactDatePicker
        calendarStartDay={1}
        {...props}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div((props) => [
  tw`
    mb--1.5
  `,
]);

export default DatePicker;
