import React from 'react';
import tw, {styled, css} from 'twin.macro';

const Text = styled.div((props) => [
  tw`
  `,
  // Bug fix: Do not include a border around text when pasting into Word or
  // Outlook. This bug exists because twin sets border-style: solid on all
  // elements (along with border-width: 0, which Word/Outlook seem to ignore).
  // Raised issue: https://github.com/ben-rogerson/twin.macro/issues/513
  css`
    border-style: none;
    * {
      border-style: none;
    }
  `,
]);

export default React.memo(Text);
