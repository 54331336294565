import tw, {styled, css} from 'twin.macro';

import React from 'react';
import PropTypes from 'prop-types';

FooterToolbar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  stickyOnMobile: PropTypes.bool,
  lg: PropTypes.bool,
};

FooterToolbar.defaultProps = {
  stickyOnMobile: false,
  lg: false,
};

function FooterToolbar (props) {
  const {children, lg, stickyOnMobile} = props;

  return (
    <Wrapper lg={lg} stickyOnMobile={stickyOnMobile}>
      <Fadeout lg={lg} />
      <ToolbarWrapper lg={lg}>
        <Toolbar lg={lg}>{children}</Toolbar>
      </ToolbarWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div((props) => [
  tw`
    sticky
    bottom-0
    mt-16
    z-20
  `,
  !props.stickyOnMobile &&
    css`
      @media (max-width: 640px) {
        margin-top: -64px;
        padding-bottom: 64px;
        position: relative;
      }
    `,
]);

const Fadeout = styled.div((props) => [
  tw`
    h-8
    bg-gradient-to-b
    from-transparent
    to-white
    pointer-events-none
  `,
]);

const ToolbarWrapper = styled.div((props) => [
  tw`
    bg-white
  `,
  props.lg && css`
    @media (min-height: 720px) {
      height: 20vh;
    }
    @media (min-height: 840px) {
      height: 25vh;
    }
  `,
]);

const Toolbar = styled.div((props) => [
  tw`
    py-3
    flex
    text-gray-300
  `,
  css`
    z-index:100;
  `,
  props.editMode && tw`
    sticky
    bg-gray-800
    text-gray-200
  `,
  props.useMode && tw`
    border-t
    border-blue-50
    py-0
  `,
  !props.editMode && !props.useMode && tw`
    border-t
    border-blue-50
  `,
  props.lg && tw`
    py-4
    text-lg
  `,
]);

export const FooterToolbarRight = styled.div((props) => [
  tw`
    flex-auto
    flex
    justify-end
  `,
]);

export const FooterToolbarSpacer = styled.div((props) => [
  tw`
    w-3
  `,
]);

export default FooterToolbar;
