import React from 'react';
import PropTypes from 'prop-types';
import LI from 'components/LI';
import OL from 'components/OL';
// eslint-disable-next-line
import tw from 'twin.macro';

OrderedListItem.propTypes = {
  attributes: PropTypes.shape({}).isRequired,
  element: PropTypes.shape({
    indent: PropTypes.number,
    number: PropTypes.number.isRequired,
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

function OrderedListItem (props) {
  const {
    children,
    element: {
      number,
      indent,
    },
  } = props;

  return <OL tw="mb-0" indent={indent} start={number}><LI>{children}</LI></OL>;
}

export default OrderedListItem;
