function checkCanElementTypeBeTransformed (elementType) {
  const types = [
    'checkbox',
    'code-block',
    'heading1',
    'heading2',
    'heading3',
    'heading4',
    'list-item',
    'ordered-list-item',
    'paragraph',
  ];

  return types.includes(elementType);
}

export default checkCanElementTypeBeTransformed;
