import {STATUS_CODE} from 'am-constants';
import {useState} from 'react';
import getRootAPIUri from '../../../utils/getRootAPIUri';

/**
 * Here we are still using the REST endpoint because it was already implemented.
 * Migrating to GraphQL and adapting passport to it requires more effort than we were willing to put
 */
function useLoginMutation () {
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const getErrorMessage = (status) => {
    switch (status) {
      case STATUS_CODE.UNAUTHORIZED:
        return 'Email or password was incorrect';
      case STATUS_CODE.FORBIDDEN:
        return 'Your workspace requires you to sign in with SSO. Please click \'continue with OIDC SSO\' to login';
      default:
        return 'An unknown error occurred';
    }
  };

  const login = async ({variables: {email, password}}) => {
    setLoading(true);
    setData(false);
    setError(null);

    const url = `${getRootAPIUri()}/login`;
    // passport-local on the backend requires us to use urlencoded
    const body = `email=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}`;

    try {
      const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
        body,
      });
      if (response.ok) {
        setError(null);
        setData(true);
      }
      else {
        setError({
          type: 'server',
          message: getErrorMessage(response.status),
          code: response.status,
        });
      }
    }
    catch (err) {
      setError({
        type: 'server',
        message: 'Could not connect to server. Please try again',
      });
    }
    setLoading(false);
  };

  return [
    login,
    {
      data,
      loading,
      error,
      clearError: () => setError(null),
    },
  ];
}

export default useLoginMutation;
