import tw, {styled, css} from 'twin.macro';
import {StyledFormGroup} from './FormGroup';

const Form = styled.div((props) => [
  tw`
    mb-7
    max-w-xl
  `,
  css`
    ${StyledFormGroup} {
      max-width: 100%;
    }
  `,
  props.narrow && tw`
    max-w-sm
  `,
  props.wide && tw`
    max-w-full
  `,
]);

Form.displayName = 'Form';

export default Form;
