import {Transforms} from 'slate';
// Fix bug: Pressing delete or backspace with a selected image doesn't delete
// the image. The bug was caused by adding an invisible div in front of the
// image to prevent the zoom-in effect from firing the first time you click on
// an image (as we instead want to show the image toolbar). Not sure why it led
// to the bug, but adding this handler fixes it!
// Video of bug: https://www.loom.com/share/88abc8a629e24f328dd09b60d1e2d8e3
function onBackspaceOrDeleteWithSelectedImage (editor, event, selectionMeta) {
  const {
    selectionStartElement,
    isSelectionInSingleElement,
  } = selectionMeta;

  if (
    !['Backspace', 'Delete'].includes(event.key) ||
    selectionStartElement.type !== 'image' ||
    !isSelectionInSingleElement
  ) {
    return false;
  }

  event.preventDefault();

  Transforms.removeNodes(editor);

  return true;
}

export default onBackspaceOrDeleteWithSelectedImage;
