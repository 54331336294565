import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {useSlateStatic} from 'slate-react';
import {useDocument} from 'containers/Document/providers/DocumentProvider';
import {useHelpBlocks} from 'containers/Document/providers/HelpBlocksProvider';
import HelpBlock from './HelpBlock';
import openNodeWithSlate from '../../utils/openNodeWithSlate';

HelpBlockContainer.propTypes = {
  element: PropTypes.shape({
    id: PropTypes.string.isRequired,
    color: PropTypes.string,
  }).isRequired,
};

function HelpBlockContainer (props) {
  const {element} = props;
  const {id} = element;

  const documentState = useDocument();
  const {mode} = documentState;

  // When editing, the HelpBlock state is managed within the SlateEditor.
  if (mode === 'edit') {
    return renderHelpBlockForEditor({
      id, documentState, props,
    });
  }

  // When viewing and using, the state is managed by the DocumentProvider.
  // This means:
  // - The state is not shared by different users viewing the document.
  // - The state is specific to a response (loaded from the API) when responding
  //   to a form.
  return renderHelpBlockForFormOrViewer({
    id, props,
  });
}

function renderHelpBlockForFormOrViewer ({
  id, props,
}) {
  const {
    closeHelpBlock,
    openHelpBlock,
    helpBlocks,
  } = useHelpBlocks();

  const {element} = props;
  const {color} = element;

  const helpBlock = helpBlocks[id];
  const {open} = helpBlock;

  const setOpen = (isOpen) => (isOpen ? openHelpBlock(id) : closeHelpBlock(id));

  const context = {
    color,
    helpBlockId: id,
    helpBlockSelected: false,
    open,
    setOpen,
  };

  return (
    <HelpBlockContext.Provider value={context}>
      <HelpBlock
        {...props}
        color={color}
        open={open}
        setOpen={setOpen}/>
    </HelpBlockContext.Provider>
  );
}

function renderHelpBlockForEditor ({id, props, documentState}) {
  const {selectedElements} = documentState;
  const selected = selectedElements.ids.includes(id);

  const {element} = props;
  const {color} = element;
  const editor = useSlateStatic();

  const open = !!element.open;
  const setOpen = useCallback((isOpen) => {
    openNodeWithSlate(editor, id, isOpen);
  }, []);

  const context = {
    color,
    editable: true,
    helpBlockId: id,
    helpBlockSelected: selected,
    open,
    setOpen,
  };

  return (
    <HelpBlockContext.Provider value={context}>
      <HelpBlock
        color={color}
        editable
        open={open}
        selected={selected}
        {...props} />
    </HelpBlockContext.Provider>
  );
}

export const HelpBlockContext = React.createContext();

export default HelpBlockContainer;
