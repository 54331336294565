import React from 'react';
import PropTypes from 'prop-types';
import ErrorPage404 from 'components/ErrorPage404';
import ErrorPage403 from 'components/ErrorPage403';

import {globalHistory} from '@reach/router';
import ErrorPage from 'components/ErrorPage';
import {captureException} from '@sentry/react';
const {STATUS_CODE} = require('am-constants');

class AppErrorBoundary extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      error: null,
    };

    globalHistory.listen(() => {
      if (this.state.error) {
        this.setState({
          error: null,
        });
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  componentDidCatch (error) {
    this.setState({
      error,
    });
  }

  render () {
    const {error} = this.state;

    // React error handling based on how we're throwing errors in
    // QueryClientProviderWithErrorHandling
    if (error?.message) {
      if (error.message.includes('Unauthorized')) {
        return <ErrorPage403 />;
      }

      if (error.message.includes('NotFoundError')) {
        return <ErrorPage404 />;
      }
    }

    // Apollo error handling
    if (error?.statusCode) {
      if (error.statusCode === STATUS_CODE.FORBIDDEN) {
        return <ErrorPage403 />;
      }

      return <ErrorPage404 />;
    }

    // This prevents an infinite loop retry when errors occur on a function call
    // These kinds of errors should be fixed before reaching production
    if (error) {
      // While uncaught errors that come from undeclared functions automatically
      // are sent to Sentry, we found that errors of type "cannot read properties
      // of x" would trigger the error page, but not send errors to Sentry. This
      // makes sure we capture all errors that trigger this fallback error page.
      captureException(error);
      return <ErrorPage message={'An error occurred'}/>;
    }

    return this.props.children;
  }
}

// As we use a class component, propTypes have to be defined after.
// This is because classes are not hoisted to the top of the file.
AppErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default AppErrorBoundary;
