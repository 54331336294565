import React from 'react';
import PropTypes from 'prop-types';
import {navigate} from '@reach/router';
import useDeleteResponseMutation from './hooks/useDeleteResponseMutation';
import ResponseDeleterModal from './components/ResponseDeleterModal';

ResponseDeleter.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  setIsVisible: PropTypes.func.isRequired,
  responseRef: PropTypes.string.isRequired,
  responseKey: PropTypes.string.isRequired,
};

ResponseDeleter.defaultProps = {
  redirectToWorkspace: false,
};

function ResponseDeleter (props) {
  const {
    isVisible,
    setIsVisible,
    responseRef,
    responseKey,
  } = props;

  const {
    mutateAsync: deleteResponse, isError, isLoading, reset,
  } = useDeleteResponseMutation({});

  const closeModal = () => {
    setIsVisible(false);
    reset();
  };

  const onSubmit = async () => {
    await deleteResponse({responseRef, responseKey});
    setIsVisible(false);
    navigate(-1);
  };

  return (
    <ResponseDeleterModal
      closeModal={closeModal}
      isError={isError}
      isLoading={isLoading}
      isVisible={isVisible}
      onSubmit={onSubmit}
    />
  );
}

export default ResponseDeleter;
