import {Editor, Path, Transforms} from 'slate';

function onEnterInProcessTitle (editor, event, selectionMeta) {
  const {
    isSelectionInSingleElement,
    selectionStart,
    selectionStartElementPath,
    selectionStartElement,
  } = selectionMeta;

  if (
    event.key !== 'Enter' ||
    !selectionStart ||
    !isSelectionInSingleElement ||
    selectionStartElement.type !== 'process-title'
  ) {
    return false;
  }

  event.preventDefault();

  const firstProcessStepPath = [...Path.next(selectionStartElementPath), 0];
  const endOfFirstProcessStep = Editor.end(editor, [...firstProcessStepPath, 0]);
  Transforms.setSelection(editor, {
    anchor: endOfFirstProcessStep,
    focus: endOfFirstProcessStep,
  });

  return true;
}

export default onEnterInProcessTitle;
