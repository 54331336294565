import {Editor, Transforms} from 'slate';

function onTabInChoice (editor, event, selectionMeta) {
  const {
    selectionStartElement,
    selectionStartElementPath,
  } = selectionMeta;

  if (
    event.key !== 'Tab' ||
    selectionStartElement.type !== 'choice'
  ) {
    return false;
  }

  event.preventDefault();

  const [choiceGroupElement] = Editor.parent(editor, selectionStartElementPath);
  const {format} = choiceGroupElement;

  if (format === 'rating') {
    const isStartInFirstLabel = selectionStartElementPath[selectionStartElementPath.length - 1] === 0;
    const endOfFirstLabel = Editor.end(editor, [...selectionStartElementPath.slice(0, -1), 0]);
    const startOfLastLabel = Editor.start(editor, [...selectionStartElementPath.slice(0, -1), choiceGroupElement.children.length - 1]);

    if (event.shiftKey) {
      const startOfChoice = Editor.start(editor, selectionStartElementPath);
      const endOfPreviousElement = Editor.before(editor, startOfChoice);
      Transforms.select(editor, !isStartInFirstLabel ? endOfFirstLabel : endOfPreviousElement);
    }
    else {
      const endOfChoice = Editor.end(editor, selectionStartElementPath);
      const startOfNextElement = Editor.after(editor, endOfChoice);
      Transforms.select(editor, isStartInFirstLabel ? startOfLastLabel : startOfNextElement);
    }

    return true;
  }

  if (event.shiftKey) {
    const startOfChoice = Editor.start(editor, selectionStartElementPath);
    const endOfPreviousElement = Editor.before(editor, startOfChoice);
    Transforms.select(editor, endOfPreviousElement);
  }
  else {
    const endOfChoice = Editor.end(editor, selectionStartElementPath);
    const startOfNextElement = Editor.after(editor, endOfChoice);
    Transforms.select(editor, startOfNextElement);
  }

  return true;
}

export default onTabInChoice;
