import React, {useState, useRef} from 'react';
import {Helmet} from 'react-helmet';
import FormGroup from 'components/FormGroup';
import Label from 'components/Label';
import Input from 'components/Input';
import Button from 'components/Button';
import SimpleFormContainer from 'components/SimpleFormContainer';
import SimpleFormHeading from 'components/SimpleFormHeading';
import SimpleFormPage from 'components/SimpleFormPage';
import Link from 'components/Link';
import tw, {styled} from 'twin.macro'; // eslint-disable-line no-unused-vars
import Checkbox from 'components/Checkbox';
import P from 'components/P';
import PasswordInput from 'components/PasswordInput';
import PropTypes from 'prop-types';
import Error from 'components/Error';
import {STATUS_CODE} from 'am-constants';
// TODO: uncomment when sign up via Google is implemented
// import googleLogo from './google-logo.svg';

SignupPage.propTypes = {
  signup: PropTypes.func.isRequired,
  error: PropTypes.object,
  loading: PropTypes.bool.isRequired,
};

function SignupPage (props) {
  const {
    signup,
    error,
    loading,
  } = props;
  const [areTermsOfServiceAccepted, setTOSAccepted] = useState(false);
  const [showFeedbackMessageForShortPasswords, setShowFeedbackMessageForShortPasswords] = useState(false);

  const wantsToHearAboutUs = useRef(false);
  const isPasswordValid = useRef(false);
  const defaultEmail = new URLSearchParams(window.location.search).get('email') || '';
  const onPasswordValidated = (isValid) => {
    isPasswordValid.current = isValid;
  };
  const onSubmit = (event) => {
    event.preventDefault();
    setShowFeedbackMessageForShortPasswords(true);
    if (isPasswordValid.current) {
      signup({
        name: event.target.elements[0].value,
        email: event.target.elements[1].value,
        password: event.target.elements[2].value,
        areTermsOfServiceAccepted,
        wantsToHearAboutUs: wantsToHearAboutUs.current,
      });
    }
  };
  const errorMessage = error && (
    error.code === STATUS_CODE.COULD_NOT_PROCESS
      ? <>You already have an account. <Link to='/login'>Login?</Link></>
      : error.message
  );

  return <>
    <Helmet>
      <title>Create your account</title>
    </Helmet>
    <SimpleFormPage>
      <SimpleFormContainer>
        <form onSubmit={onSubmit}>
          <SimpleFormHeading>Create your account</SimpleFormHeading>
          {/* <FormGroup>
            <Button tw="bg-white" lg fullwidth onClick={() => navigate('/start')}>
              <img src={googleLogo} tw="inline-block w-4 h-4 mt--1 mr-2" />
              Continue with Google
            </Button>
            <div tw="text-center text-gray-400 font-medium text-xs mt-4 mb--2">or</div>
          </FormGroup> */}
          {/* <FormGroup>
            <Label>
              Name
            </Label>
            <Input />
          </FormGroup> */}
          <FormGroup>
            <Label>
              Your name
            </Label>
            <Input type="text" required />
          </FormGroup>
          <FormGroup>
            <Label>
              Your email
            </Label>
            <Input type="email" required data-test-id='email-input' defaultValue={defaultEmail} />
          </FormGroup>
          <FormGroup>
            <Label>
              Choose password
            </Label>
            <PasswordInput
              required
              data-test-id='password-input'
              onValidStateChange={onPasswordValidated}
              showFeedbackMessageForShortPasswords={showFeedbackMessageForShortPasswords} />
          </FormGroup>
          <FormGroup>
            <Checkbox sm onChange={setTOSAccepted}>
              I agree to the <Link to="/terms">Terms of Use</Link> and{' '}
              <Link to="/privacy">Privacy Policy</Link>
            </Checkbox>
          </FormGroup>
          <FormGroup tw="mt--2">
            <Checkbox sm onChange={(value) => {
              wantsToHearAboutUs.current = value;
            }}>
              Yes, I would also like to sign up for the weekly newsletter to hear about best practices, tips and news
            </Checkbox>
          </FormGroup>
          {/* <FormGroup tw="text-sm mb-2">
            <Checkbox onChange={() => {}}>
              Keep me updated about best practices
            </Checkbox>
          </FormGroup>
          <FormGroup tw="text-sm">
            <Checkbox onChange={() => {}}>
              Keep me updated about new products and services
            </Checkbox>
          </FormGroup> */}
          <FormGroup buttons>
            <Button primary lg fullwidth disabled={loading || !areTermsOfServiceAccepted}>Get started</Button>
          </FormGroup>
          {error && !loading && (
            <Error arrow>{errorMessage}</Error>
          )}
          {/* <FullWidthButtonSecondaryAction>
            Already got an account? <Link to="/login">Sign in</Link>
          </FullWidthButtonSecondaryAction> */}
        </form>
      </SimpleFormContainer>
      <P tw="text-center text-xs">Already have an account? <Link to="/login">Sign  in</Link></P>
    </SimpleFormPage>
  </>;
}

export default SignupPage;
