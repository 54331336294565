import React from 'react';
import PropTypes from 'prop-types';

const ChoiceGroupContext = React.createContext();

ChoiceGroupProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  choiceIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  format: PropTypes.oneOf([
    'horizontal',
    'rating',
    'vertical',
  ]).isRequired,
  numChoices: PropTypes.number.isRequired,
  readOnly: PropTypes.bool.isRequired,
  value: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    rating: PropTypes.number,
  })),
  submissionMode: PropTypes.bool.isRequired,
  supportMultipleChoices: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

function ChoiceGroupProvider (props) {
  const {
    children,
    choiceIds,
    format,
    numChoices,
    readOnly,
    value,
    submissionMode,
    supportMultipleChoices,
    onChange,
  } = props;

  const context = {
    choiceIds,
    format,
    numChoices,
    readOnly,
    value,
    submissionMode,
    supportMultipleChoices,
    onChange,
  };

  return <>
    <ChoiceGroupContext.Provider value={context}>
      {children}
    </ChoiceGroupContext.Provider>
  </>;
}

export function useChoiceGroup () {
  const context = React.useContext(ChoiceGroupContext);

  // When selecting and removing a form-group in the editor, the editor would
  // somehow get into a temporary state where a choice was orphaned from the
  // choice-group. Commented this out to avoid an error being thrown.
  // if (context === undefined) {
  //   throw new Error('useChoiceGroup must be used within a ChoiceGroupProvider');
  // }

  return context;
}

export default ChoiceGroupProvider;
