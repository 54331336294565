import P from 'components/P';
import React from 'react';
import Comment from './Comment';

WarningTitleHowTo.propTypes = {
};

function WarningTitleHowTo (props) {
  return (
    <Comment isWarning shortText='No need for "How to"' {...props}>
      <P>
        Every checklist is a "How to" guide, but there's no need to use the
        term "How to" in the title.
      </P>
      <P>For example, instead of calling a page "How to send a quote", you can
        simply call it "Send a quote".
      </P>
      <P>
        Using shorter titles makes it easier for your manual users to scan a
        list of pages and find the one they want.
      </P>
    </Comment>
  );
}

export default React.memo(WarningTitleHowTo);
