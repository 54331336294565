import React from 'react';
import PropTypes from 'prop-types';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';
import {useUser} from 'containers/UserApp/providers/UserProvider';
import P from 'components/P';
import {navigate} from '@reach/router';
import useUpdateTeamMemberMutation from './hooks/useUpdateTeamMemberMutation';
import TeamMemberEditorModal from './components/TeamMemberEditorModal';
import useRemoveTeamMemberMutation from './hooks/useRemoveTeamMemberMutation';

TeamMemberEditor.propTypes = {
  email: PropTypes.string.isRequired,
  handleRoleChange: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  isLastAdmin: PropTypes.bool.isRequired,
  role: PropTypes.number.isRequired,
  setIsVisible: PropTypes.func.isRequired,
};

function TeamMemberEditor (props) {
  const {
    email,
    handleRoleChange,
    isVisible,
    isLastAdmin,
    role,
    setIsVisible,
  } = props;

  const {id: workspaceId} = useWorkspace();
  const {user} = useUser();

  const [updateTeamMember, {error: updateError, reset: resetUpdateError}] = useUpdateTeamMemberMutation();
  const [removeTeamMember, {error: removeError, reset: resetRemoveError}] = useRemoveTeamMemberMutation();
  const isEditingOwnPermissions = user.email === email;

  const closeModal = () => {
    setIsVisible(false);
    resetUpdateError();
    resetRemoveError();
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    await updateTeamMember({variables: {workspaceId, email, role}});
    closeModal();
  };

  const removeTeamMemberWithConfirm = async () => {
    // eslint-disable-next-line no-alert
    const didUserConfirmRemove = window.confirm('Are you sure you want to remove this user from the workspace?');

    if (didUserConfirmRemove) {
      await removeTeamMember({variables: {workspaceId, email}});
      closeModal();
      if (isEditingOwnPermissions) {
        navigate('/workspaces');
      }
    }
  };

  const renderError = () => (
    <P bottom>Please check your connection and try again.</P>
  );

  return (
    <TeamMemberEditorModal
      closeModal={closeModal}
      email={email}
      handleRoleChange={handleRoleChange}
      isVisible={isVisible}
      isLastAdmin={isLastAdmin}
      onSubmit={onSubmit}
      removeTeamMember={removeTeamMemberWithConfirm}
      renderError={(updateError || removeError) && renderError}
      role={role}
    />
  );
}

export default TeamMemberEditor;
