import React from 'react';
import {Helmet} from 'react-helmet';
import P from 'components/P';
import Link from 'components/Link';
import SimpleFormPage from 'components/SimpleFormPage';
import SimpleFormContainer from 'components/SimpleFormContainer';
import SimpleFormHeading from 'components/SimpleFormHeading';
import tw from 'twin.macro'; // eslint-disable-line no-unused-vars

function ResetPasswordInvalidLinkPage () {
  return <>
    <Helmet>
      <title>Invalid reset password link</title>
    </Helmet>
    <SimpleFormPage>
      <SimpleFormContainer>
        <SimpleFormHeading>Invalid link</SimpleFormHeading>
        <P>
          The reset password link you clicked on seems to be invalid.
        </P>
        <P bottom>
          Please check your email and try again.
        </P>
      </SimpleFormContainer>
      <P tw="text-center text-xs">Need help? <Link to="/support">Contact support</Link></P>
    </SimpleFormPage>
  </>;
}

export default ResetPasswordInvalidLinkPage;
