import PropTypes from 'prop-types';
import {gql} from '@apollo/client';
import useQuery from 'hooks/useQuery';

const GET_FIRST_ACCESSIBLE_FOLDER = gql`
  query GetFirstAccessibleFolder($workspaceUrlName: String!) {
    firstAccessibleFolder(workspaceUrlName: $workspaceUrlName) {
      id
      urlName
      itemTypeId
      parentId
    }
  }
`;

useFirstAccessibleFolderQuery.propTypes = {
  workspaceUrlName: PropTypes.string,
};

function useFirstAccessibleFolderQuery (props) {
  const {
    workspaceUrlName,
  } = props;

  const {loading, error, data} = useQuery(GET_FIRST_ACCESSIBLE_FOLDER, {
    fetchPolicy: 'no-cache',
    variables: {
      workspaceUrlName,
    },
  });

  return {
    data,
    loading,
    error,
  };
}

export default useFirstAccessibleFolderQuery;
