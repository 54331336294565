import React, {useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';
import getNameRefAndKeyParts from 'utils/getNameRefAndKeyParts';
import {Router, useLocation} from '@reach/router';
import FolderBrowserApp from 'containers/FolderBrowserApp';
import FolderManagerApp from 'containers/FolderManagerApp';
import WorkspaceExplorer from 'containers/WorkspaceExplorer';
import {useTopbar} from 'providers/TopbarProvider';
import RedirectToLogin from 'components/RedirectToLogin';
import {PERMISSIONS} from 'am-constants';
import Loader from 'components/Loader';
import useFolderQuery from 'hooks/useFolderQuery';
import {useItem} from 'providers/ItemProvider';
import FolderLayout from './components/FolderLayout';

FolderApp.propTypes = {
  'name--:ref-:key': PropTypes.string,
};

function FolderApp (props) {
  const {'name--:ref-:key': nameRefAndKey} = props;
  const {
    urlName: workspaceUrlName,
    featureFlags,
  } = useWorkspace();
  const {name, ref, key} = useMemo(() => getNameRefAndKeyParts(nameRefAndKey), [nameRefAndKey]);
  const {setHideSearch} = useTopbar();
  const {workspacePermissions} = useWorkspace();

  useEffect(() => {
    if (featureFlags['2022_09_dashboard']) {
      setHideSearch(false);
    }
  }, []);

  const {data: item, error} = useFolderQuery({
    workspaceUrlName,
    ref,
    key,
  });

  const {setItem} = useItem();

  useEffect(() => {
    setItem(item);
  }, [item]);

  if (error) {
    return <p>Could not load folder</p>;
  }

  const location = useLocation();
  const isManageView = location.pathname.split('/').pop() === 'manage';

  return <>
    <Helmet>
      <title>{item?.name || name}</title>
    </Helmet>
    {featureFlags['2022_09_dashboard'] &&
      <FolderLayout item={item}>
        {!isManageView && <>
          {item && <FolderBrowserApp folderId={item.id} />}
          {!item && <Loader />}
        </>}
        {isManageView && workspacePermissions.includes(PERMISSIONS.VIEW_WORKSPACE) && <>
          {item && <FolderManagerApp folderId={item.id} folderRef={item.ref} folderKey={item.key} />}
          {!item && <Loader />}
        </>}
        {isManageView && !workspacePermissions.includes(PERMISSIONS.VIEW_WORKSPACE) && (
          <RedirectToLogin />
        )}
      </FolderLayout>
    }
    {!featureFlags['2022_09_dashboard'] && <>
      <Router primary={false}>
        <WorkspaceExplorer path="/"/>
        <WorkspaceExplorer path="/folders/:name--:ref-:key"/>
      </Router>
    </>}
  </>;
}

export default FolderApp;
