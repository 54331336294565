import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import Input from './Input';
import Success from './Success';
import Error from './Error';
import Tip from './Tip';

PasswordInput.propTypes = {
  onValidStateChange: PropTypes.func,
  showFeedbackMessageForShortPasswords: PropTypes.bool,
};

function PasswordInput (props) {
  const {onValidStateChange, showFeedbackMessageForShortPasswords, ...rest} = props;

  const [state, setState] = useState({
    isEmpty: true,
    isVisible: false,
    isValid: false,
  });

  const validate = async (element, delay) => {
    const {default: zxcvbn} = await import('zxcvbn');
    const password = element.value;

    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    const {score} = zxcvbn(password);
    const isValid = score >= 3;
    if (onValidStateChange && isValid !== state.isValid) {
      onValidStateChange(isValid);
    }
    if (delay <= 0) {
      setState({
        isEmpty: password.length === 0,
        isValid,
        isVisible: password.length >= 8 || (showFeedbackMessageForShortPasswords && password.length > 0),
        lastPassword: password,
      });
    }
    else {
      setState({
        isEmpty: password.length === 0,
        isValid,
        isVisible: false,
        lastPassword: password,
      });
      timeout.current = setTimeout(() => {
        setState({
          isEmpty: password.length === 0,
          isValid,
          isVisible: password.length >= 8 || (showFeedbackMessageForShortPasswords && password.length > 0),
          lastPassword: state.lastPassword,
        });
      }, delay);
    }
  };

  const inputRef = useRef(null);
  const timeout = useRef(null);
  const wasShowingFeedbackMessageForShortPassword = useRef(false);

  const feedbackMessage = state.isValid
    ? 'Nice work — this is an excellent password!'
    : 'Your password is not strong enough. To secure your account, avoid common words, names, dates, and repeating characters.';

  if (
    inputRef.current &&
    (inputRef.current.value !== state.lastPassword || (showFeedbackMessageForShortPasswords !== wasShowingFeedbackMessageForShortPassword.current))
  ) {
    wasShowingFeedbackMessageForShortPassword.current = showFeedbackMessageForShortPasswords;
    validate(inputRef.current, 0);
  }

  return (
    <div>
      <Input
        innerRef={inputRef}
        type="password"
        required
        onBlur={(e) => validate(e.target, 0)}
        onChange={(e) => validate(e.target, 1000)}
        {...rest}
      />
      {!state.isVisible && !state.isEmpty && <Tip>At least 8 characters. Use multiple words to make it more secure.</Tip>}
      {state.isVisible && (state.isValid
        ? <Success arrow data-test='password-feedback-message'>{feedbackMessage}</Success>
        : <Error arrow data-test='password-feedback-message'>{feedbackMessage}</Error>)
      }
    </div>
  );
}

export default PasswordInput;
