import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled} from 'twin.macro';
import ClickableItem from './ClickableItem';

ItemList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

ItemList.defaultProps = {
};

function ItemList (props) {
  const {
    items,
  } = props;

  return (
    <Wrapper data-test-id={'item-list'}>
      {items.map((item) => (
        <ClickableItem
          key={item.id}
          item={item} />
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div((props) => [
  tw`
    bg-white
    py-1.5
  `,
]);

export default ItemList;
