import {Editor, Transforms} from 'slate';
import makeParagraph from '../factory/makeParagraph';

function onEnterAtStartOfLabel (editor, event, selectionMeta) {
  const {
    isSelectionAtStartOfElement,
    selectionStart,
    selectionStartElement,
    selectionStartElementPath,
  } = selectionMeta;

  if (
    event.key !== 'Enter' ||
    !selectionStart ||
    selectionStartElement.type !== 'label' ||
    !isSelectionAtStartOfElement
  ) {
    return false;
  }

  event.preventDefault();

  const [parentElement, parentElementPath] = Editor.parent(editor, selectionStartElementPath);
  if (parentElement.type !== 'form-group') {
    throw new Error('Expected the parent element of a label element to have type form-group');
  }

  Editor.withoutNormalizing(editor, () => {
    Transforms.insertNodes(editor, makeParagraph(), {
      at: parentElementPath,
    });
  });

  return true;
}

export default onEnterAtStartOfLabel;
