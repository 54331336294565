function getTitleFromContent (content) {
  if (
    !content ||
    !content.length ||
    !content[0].children.length ||
    !content[0].children[0]) {
    return 'Untitled';
  }

  return content[0].children[0].text || 'Untitled';
}

module.exports = getTitleFromContent;
