import React from 'react';
import {Router} from '@reach/router';
import ErrorPage404 from 'components/ErrorPage404';
import WorkspaceApp from 'containers/WorkspaceApp';
import WorkspaceRedirector from 'containers/WorkspaceRedirector';
import RedirectToLogin from 'components/RedirectToLogin';
import getIsUrlUsingLegacyDollarSeparator from 'utils/getIsUrlUsingLegacyDollarSeparator';
import RedirectFromDollarUrl from 'components/RedirectFromDollarUrl';
import NewUserStart from 'containers/NewUserStart';
import WorkspaceCreator from 'containers/WorkspaceCreator';
import getPageCouldBePublic from '../../../utils/getPageCouldBePublic';
import {useUser} from '../providers/UserProvider';

function UserAppRouter () {
  const {user} = useUser();
  const isLoggedIn = !!user;

  const isUrlUsingLegacyDollarSeparator = getIsUrlUsingLegacyDollarSeparator();
  if (isUrlUsingLegacyDollarSeparator) {
    return <RedirectFromDollarUrl />;
  }

  const pageCouldBePublic = getPageCouldBePublic();

  return <>
    <Router>
      {isLoggedIn && (
        <>
          <NewUserStart path="/welcome" />
          <WorkspaceRedirector path="/workspaces" />
          <WorkspaceCreator path="/workspaces/create" />
          <WorkspaceApp path="/:workspaceUrlName" />
          <WorkspaceApp path="/:workspaceUrlName/*" />
          <ErrorPage404 path="/*" />
        </>
      )}
      {!isLoggedIn && pageCouldBePublic && (
        <>
          <WorkspaceApp path="/:workspaceUrlName"/>
          <WorkspaceApp path="/:workspaceUrlName/*"/>
          <RedirectToLogin path='/*'/>
        </>
      )}
      {!isLoggedIn && !pageCouldBePublic && (
        <RedirectToLogin path='/*'/>
      )}
    </Router>
  </>;
}

export default UserAppRouter;
