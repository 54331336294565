import {gql} from '@apollo/client';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import graphQLClient from 'utils/graphQLClient';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';

const query = gql`
  mutation automationUpdate($automationId: Int!, $action: Action!, $target: String!, $trigger: Trigger!) {
    automationUpdate(automationId: $automationId, action: $action,  target: $target , trigger: $trigger) {
      id
    }
  }
`;
function useUpdateAutomationMutation () {
  const queryClient = useQueryClient();
  const {urlName: workspaceUrlName} = useWorkspace();

  const mutationFn = async (updatedItem) => {
    const variables = {
      automationId: updatedItem.automationId,
      action: updatedItem.action,
      target: updatedItem.target,
      trigger: updatedItem.trigger,
    };

    await graphQLClient.request(query, variables);
  };

  const options = {
    onSuccess: (_, {itemRef, itemKey}) => {
      queryClient.invalidateQueries([
        workspaceUrlName, 'items', {ref: itemRef, key: itemKey}, 'side-panel',
      ]);
    },
  };

  return useMutation(mutationFn, options);
}

export default useUpdateAutomationMutation;
