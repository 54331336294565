import React from 'react';
import Modal from 'components/Modal';
import Button, {ModalButtonBar} from 'components/Button';
// eslint-disable-next-line no-unused-vars
import tw, {styled} from 'twin.macro';
import PropTypes from 'prop-types';
import ItemMoverModalContent from './ItemMoverModalContent';

ItemMoverModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  destinationFolderId: PropTypes.number,
  itemName: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  folderTree: PropTypes.arrayOf(PropTypes.shape({})),
  error: PropTypes.bool,
};

function ItemMoverModal (props) {
  const {
    isVisible,
    closeModal,
    onSubmit,
    itemName,
    folderTree,
    onChange,
    destinationFolderId,
    error,
  } = props;

  return (
    <Modal
      visible={isVisible}
      title="Move"
      onClose={closeModal}
      footer={<ModalButtonBar>
        <Button type="button" link onClick={closeModal}>
          Cancel
        </Button>
        <div tw="flex-1"></div>
        <Button type="button" primary disabled={!destinationFolderId} onClick={onSubmit} data-test-id="modal_move-button">
          Move
        </Button>
      </ModalButtonBar>}
    >
      <ItemMoverModalContent error={error} folderTree={folderTree} itemName={itemName} onChange={onChange}/>
    </Modal>
  );
}

export default ItemMoverModal;
