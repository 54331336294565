const { ITEM_TYPE } = require("am-constants");

function getContentType(content) {
  if (content.some((node) => node.type === "process")) {
    return ITEM_TYPE.CHECKLIST;
  }

  const containsInputAtTopLevel = content.some((node) =>
    ["checkbox", "form-group"].includes(node.type)
  );

  const containsInputInHelpBlock = content.some(
    (node) =>
      node.type === "help-block" &&
      node.children[1].children.some((n) =>
        ["checkbox", "form-group"].includes(n.type)
      )
  );

  if (containsInputAtTopLevel || containsInputInHelpBlock) {
    return ITEM_TYPE.FORM;
  }

  return ITEM_TYPE.DOCUMENT;
}

module.exports = getContentType;
