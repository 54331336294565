import isHotkey from 'is-hotkey';
import insertLink from '../utils/insertLink';
import toggleMark from '../utils/toggleMark';

function onKeyboardShortcut (editor, event, selectionMeta) {
  const {
    isSelectionCollapsed,
    selectionStart,
  } = selectionMeta;

  if (!selectionStart) {
    return false;
  }

  if (isHotkey('mod+b')(event) && !isSelectionCollapsed) {
    event.preventDefault();
    toggleMark(editor, 'bold');
    return true;
  }

  if (isHotkey('mod+i')(event) && !isSelectionCollapsed) {
    event.preventDefault();
    toggleMark(editor, 'italic');
    return true;
  }

  if (isHotkey('mod+u')(event) && !isSelectionCollapsed) {
    event.preventDefault();
    toggleMark(editor, 'underline');
    return true;
  }

  if (isHotkey('mod+e')(event) && !isSelectionCollapsed) {
    event.preventDefault();
    toggleMark(editor, 'highlight');
    return true;
  }

  if (isHotkey('mod+shift+x')(event) && !isSelectionCollapsed) {
    event.preventDefault();
    toggleMark(editor, 'strikethrough');
    return true;
  }

  if (isHotkey('mod+k')(event)) {
    event.preventDefault();
    insertLink(editor);
    return true;
  }

  return false;
}

export default onKeyboardShortcut;
