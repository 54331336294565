import makeParagraph from '../factory/makeParagraph';

function insertFragmentIntoDocumentTitle (editor, data, selectionMeta) {
  const {
    selectionStartElement,
    isSelectionInSingleElement,
  } = selectionMeta;

  if (
    !selectionStartElement ||
    selectionStartElement.type !== 'title' ||
    !isSelectionInSingleElement
  ) {
    return false;
  }

  const fragment = data.getData('application/x-slate-fragment');

  if (!fragment) {
    return false;
  }

  const decoded = decodeURIComponent(window.atob(fragment));
  const parsed = JSON.parse(decoded);

  const elements = parsed[0].type === 'paragraph' ? parsed : [
    makeParagraph(),
    ...parsed,
  ];

  editor.insertFragment(elements);

  return true;
}

export default insertFragmentIntoDocumentTitle;
