const makeProcessStepTitle = (attributes = {}) => {
  const {title} = attributes;

  return {
    type: 'process-step-title',
    children: [
      {
        text: title || '',
      },
    ],
  };
};

export default makeProcessStepTitle;
