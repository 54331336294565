import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  PERMISSIONS, SUBSCRIPTION_TYPE,
} from 'am-constants';
import tw, {styled} from 'twin.macro'; // eslint-disable-line no-unused-vars
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';
import {getPermissionsForRoles} from 'am-utils';
import P from 'components/P';
import {useUser} from 'containers/UserApp/providers/UserProvider';
import getExampleWebhookCallContent from './utils/getExampleWebhookCallContent';
import ItemAutomationEditorModal from './components/ItemAutomationEditorModal';
import useUpdateAutomationMutation from './hooks/useUpdateAutomationMutation';
import useDeleteAutomationMutation from './hooks/useDeleteAutomationMutation';

ItemAutomationEditor.propTypes = {
  inputs: PropTypes.array,
  itemRef: PropTypes.string.isRequired,
  itemKey: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  automationEditorData: PropTypes.object.isRequired,
  itemName: PropTypes.string.isRequired,
  itemTypeId: PropTypes.number,
  itemUrl: PropTypes.string.isRequired,
  loggedInUserRoleIds: PropTypes.array.isRequired,
  setIsVisible: PropTypes.func.isRequired,
};

ItemAutomationEditor.defaultProps = {
  redirectToWorkspace: false,
};

function ItemAutomationEditor (props) {
  const {
    inputs,
    itemRef,
    itemKey,
    isVisible,
    automationEditorData,
    itemName,
    itemTypeId,
    itemUrl,
    loggedInUserRoleIds,
    setIsVisible,
  } = props;

  const [action, setAction] = useState(automationEditorData.action);
  const [trigger, setTrigger] = useState(automationEditorData.trigger);
  const [target, setTarget] = useState(automationEditorData.target);
  useEffect(() => {
    setAction(automationEditorData.action);
    setTrigger(automationEditorData.trigger);
    setTarget(automationEditorData.target);
  }, [automationEditorData]);

  const {automationId} = automationEditorData;

  const {user} = useUser();

  const {mutateAsync: updateAutomation, isError: isUpdateError, reset: resetUpdate} = useUpdateAutomationMutation({});
  const {mutateAsync: deleteAutomation, isError: isDeleteError, reset: resetDelete} = useDeleteAutomationMutation({});
  const reset = () => {
    resetUpdate();
    resetDelete();
  };
  const isError = isUpdateError || isDeleteError;

  const {subscription} = useWorkspace();
  const hasTeamSubscription = subscription?.subscriptionTypeId === SUBSCRIPTION_TYPE.TEAM;

  const hasAutomationPermissions = hasTeamSubscription &&
  getPermissionsForRoles(loggedInUserRoleIds).includes(PERMISSIONS.MANAGE_ITEM_AUTOMATIONS);

  const closeModal = () => {
    setIsVisible(false);
    setAction();
    setTrigger();
    reset();
    setTarget();
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    try {
      await updateAutomation({
        automationId,
        action,
        target,
        trigger,
        itemRef,
        itemKey,
      });
    }
    catch (e) {
      return;
    }

    closeModal();
  };

  const onDelete = async () => {
    // eslint-disable-next-line no-alert
    const didUserConfirmRemove = window.confirm('Are you sure you want to delete this automation?');

    if (didUserConfirmRemove) {
      try {
        await deleteAutomation({
          automationId,
          itemRef,
          itemKey,
        });
      }
      catch (e) {
        return;
      }

      closeModal();
    }
  };

  const renderError = () => (<P bottom>Please check your connection and try again.</P>);

  const exampleWebhookCallContent = getExampleWebhookCallContent(
    inputs,
    itemName,
    itemUrl,
    user,
    trigger
  );

  return (
    <ItemAutomationEditorModal
      action={action}
      closeModal={closeModal}
      exampleWebhookCallContent={exampleWebhookCallContent}
      trigger={trigger}
      hasAutomationPermissions={hasAutomationPermissions}
      isVisible={isVisible}
      itemTypeId={itemTypeId}
      onDelete={onDelete}
      onSubmit={onSubmit}
      renderError={isError ? renderError : null}
      setAction={setAction}
      setTrigger={setTrigger}
      setTarget={setTarget}
      target={target}
    />
  );
}

export default ItemAutomationEditor;
