import makeChoice from 'containers/SlateEditor/factory/makeChoice';
import {Editor, Transforms} from 'slate';

function addChoice (editor, path, element) {
  Editor.withoutNormalizing(editor, () => {
    if (element.format === 'rating') {
      // For rating scales, we add the new choice before - rather than after -
      // the last existing choice to avoid changing the label at the end of the
      // rating scale.
      Transforms.insertNodes(editor, makeChoice(), {
        at: [...path, element.children.length - 1],
      });
    }
    else {
      Transforms.insertNodes(editor, makeChoice(), {
        at: [...path, element.children.length],
      });
    }

    Transforms.select(editor, [...path, element.children.length]);
  });
}

export default addChoice;
