import {Editor, Transforms} from 'slate';

function onEnterInChoiceGroupLabel (editor, event, selectionMeta) {
  const {
    selectionStart,
    selectionStartElement,
    selectionStartElementPath,
  } = selectionMeta;

  if (
    event.key !== 'Enter' ||
    !selectionStart ||
    selectionStartElement.type !== 'label'
  ) {
    return false;
  }

  const [nextElement, nextElementPath] = Editor.next(editor, {
    at: selectionStartElementPath,
  });

  if (nextElement.type !== 'choice-group') {
    return false;
  }

  event.preventDefault();

  const firstChoicePath = [...nextElementPath, 0];
  const endOfFirstChoice = Editor.end(editor, [...firstChoicePath, 0]);
  const newPoint = {
    path: endOfFirstChoice.path,
    offset: Math.min(endOfFirstChoice.offset, selectionStart.offset),
  };

  Transforms.setSelection(editor, {
    anchor: newPoint,
    focus: newPoint,
  });

  return true;
}

export default onEnterInChoiceGroupLabel;
