import React from 'react';
import DocumentToolbar from 'containers/DocumentToolbar';
import {useItem} from 'providers/ItemProvider';
import {PERMISSIONS} from 'am-constants';
import {getPermissionsForRoles} from 'am-utils';

function DocumentViewerPageToolbar () {
  const {item} = useItem();
  const {loggedInUserRoleIds} = item;

  const showMoveItemMenu = getPermissionsForRoles(loggedInUserRoleIds).includes(PERMISSIONS.MOVE_ITEM);

  return (
    <DocumentToolbar
      showDeleteItemMenu
      showShareItemMenu
      showMoveItemMenu={showMoveItemMenu}
      showViewResponsesMenu
      viewMode>
    </DocumentToolbar>
  );
}

export default DocumentViewerPageToolbar;
