import {gql} from '@apollo/client';
import {useMutation} from '@tanstack/react-query';
import graphQLClient from 'utils/graphQLClient';

const query = gql`
  mutation responseDelete($responseRef: String!, $responseKey: String!) {
    responseDelete(responseRef: $responseRef, responseKey: $responseKey) {
      id
    }
  }
`;
function useDeleteResponseMutation () {
  const mutationFn = async ({responseRef, responseKey}) => {
    const variables = {
      responseRef, responseKey,
    };

    await graphQLClient.request(query, variables);
  };

  const options = {};

  return useMutation(mutationFn, options);
}

export default useDeleteResponseMutation;
