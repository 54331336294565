import {
  Editor, Element, Transforms,
} from 'slate';

function unwrapLink (editor) {
  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && Element.isElement(n) && n.type === 'link',
  });
}

export default unwrapLink;
