import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled} from 'twin.macro';

AvatarList.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

AvatarList.defaultProps = {
};

function AvatarList (props) {
  const {
    children,
  } = props;

  const numAvatars = children.length;

  if (numAvatars > 3) {
    return (
      <Wrapper>
        {children.slice(0, 2)}
        <Label><Plus>+</Plus>{numAvatars - 2}</Label>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {children}
    </Wrapper>
  );
}

const Wrapper = styled.div((props) => [
  tw`
    flex
    gap-1
    items-center
  `,
]);

const Label = styled.div((props) => [
  tw`
    text-gray-500
    font-medium
    text-2xs
    ml-0.5
    flex
  `,
]);

const Plus = styled.div((props) => [
  tw`
    mt--px
    mr-px
  `,
]);

export default AvatarList;
