import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled} from 'twin.macro';
import Loader from 'components/Loader';
import Link from 'components/Link';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';
import orderBy from 'lodash.orderby';
import SidePanelResponse from './SidePanelResponse';

SidePanelResponses.propTypes = {
  itemName: PropTypes.string.isRequired,
  noResponsesLabel: PropTypes.string.isRequired,
  numTotalResponses: PropTypes.number.isRequired,
  responses: PropTypes.array,
  urlName: PropTypes.string.isRequired,
};

SidePanelResponses.defaultProps = {
};

function SidePanelResponses (props) {
  const {
    itemName,
    noResponsesLabel,
    numTotalResponses,
    responses,
    urlName,
  } = props;

  const {urlName: workspaceUrlName} = useWorkspace();
  const url = `/${workspaceUrlName}/${urlName}/data`;

  if (!responses) {
    return <Loader />;
  }

  if (numTotalResponses === 0) {
    return (
      <NoResponses>
        {noResponsesLabel}
      </NoResponses>
    );
  }

  return <>
    <ResponseList>
      {orderBy(responses, ['responseNum'], ['desc']).map((response) => (
        <SidePanelResponse
          key={response.urlName}
          itemName={itemName}
          response={response}
        />
      ))}
    </ResponseList>
    <Link to={url}>
      <ResponseLink>
        View all submissions ({numTotalResponses})
      </ResponseLink>
    </Link>
  </>;
}

const ResponseList = styled.div((props) => [
  tw`
    flex
    flex-col
    gap-4
  `,
]);

const ResponseLink = styled.div((props) => [
  tw`
    text-center
    text-xs
    mt-4
  `,
]);

const NoResponses = styled.div((props) => [
  tw`
    bg-gray-50
    p-3.5
    text-gray-500
    text-xs
  `,
]);

export default SidePanelResponses;
