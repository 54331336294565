import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {createGlobalStyle} from 'styled-components';
import {AiOutlineCaretDown, AiOutlineFileAdd} from 'react-icons/ai';
// import ExampleProfileImg from 'components/ExampleProfileImg';
import Breadcrumbs from 'components/Breadcrumbs';
import DropdownButton from 'components/DropdownButton';
// eslint-disable-next-line
import tw, {styled, css} from 'twin.macro';
import colors from 'utils/colors';
import {useLocation, useParams} from '@reach/router';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';
import CustomPropTypes from 'utils/CustomPropTypes';
import getNameRefAndKeyParts from 'utils/getNameRefAndKeyParts';
import ItemSharer from 'containers/ItemSharer';
import Button from 'components/Button';
import Truncate from 'components/Truncate';
import {PERMISSIONS} from 'am-constants';
import {getPermissionsForRoles} from 'am-utils';
import {Tooltip} from 'antd';
import ScrollToTop from 'components/ScrollToTop';
import FolderCreatorContainer from 'containers/FolderCreator';
import LinkCreatorContainer from 'containers/LinkCreator';
import getUrlForItem from 'utils/getUrlForItem';
import {useUser} from 'containers/UserApp/providers/UserProvider';

FolderLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  item: CustomPropTypes.Item,
};

FolderLayout.defaultProps = {
};

function FolderLayout (props) {
  const {
    children,
    item,
  } = props;
  const {
    'name--:ref-:key': nameRefAndKey,
  } = useParams();
  const [isShareItemModalVisible, setIsShareItemModalVisible] = useState(false);

  const location = useLocation();
  const workspace = useWorkspace();
  const {user} = useUser();

  const {name} = useMemo(() => getNameRefAndKeyParts(nameRefAndKey), [nameRefAndKey]);
  const prettyName = name ? name.replace(/-/g, ' ') : '';

  const isWorkspaceItem = !location.pathname.split('/').includes('folders');
  const isManageView = location.pathname.split('/').pop() === 'manage';
  // Use the item urlName loaded from the server when available — the URL in
  // the web browser may not have an up to date name.
  const itemUrlName = item ? item.urlName : nameRefAndKey;
  const browseUrl = isWorkspaceItem
    ? `/${workspace.urlName}`
    : `/${workspace.urlName}/folders/${itemUrlName}`;

  const manageUrl = `${browseUrl}/manage`;
  const createPageUrl = `${browseUrl}/new`;
  const [isNewFolderModalVisible, setIsNewFolderModalVisible] = useState(false);
  const [isNewLinkModalVisible, setIsNewLinkModalVisible] = useState(false);

  const {workspacePermissions} = useWorkspace();
  const showButtons = workspacePermissions.includes(PERMISSIONS.VIEW_WORKSPACE);

  const isLoggedIn = !!user;
  const loggedInUserItemPermissions = item?.loggedInUserRoleIds ? getPermissionsForRoles(item?.loggedInUserRoleIds) : [];
  const canUseCreateMenu = loggedInUserItemPermissions.includes(PERMISSIONS.ADD_ITEM_TO_FOLDER);

  const menu = [[
    {
      onClick: () => setIsNewFolderModalVisible(true),
      label: 'Create folder',
    },
    {
      onClick: () => setIsNewLinkModalVisible(true),
      label: 'Create link',
    },
  ]];

  return (
    <>
      <GlobalStyle />
      <ScrollToTop />
      <Wrapper>
        {/* Loading the sharer pre-loads the email and permissions that each team
         member has, causing an error for logged out users. As logged out users can't
         edit sharing permissions we just don't load the sharer. */}
        {item && isLoggedIn && (
          <ItemSharer
            key={item.key}
            isVisible={isShareItemModalVisible}
            isItemPublic={item.public}
            itemKey={item.key}
            itemName={item.name}
            itemRef={item.ref}
            itemTypeId={item.itemTypeId}
            itemUrl={getUrlForItem(workspace.urlName, item)}
            setIsVisible={setIsShareItemModalVisible}
            userRoleIds={item.userRoleIds}
            loggedInUserRoleIds={item.loggedInUserRoleIds}
            workspaceMembersRoleIds={item.workspaceMembersRoleIds}
          />
        )}
        {item && (
          <FolderCreatorContainer
            isVisible={isNewFolderModalVisible}
            setIsVisible={setIsNewFolderModalVisible}
            parentItem={item}
          />
        )}
        {item && (
          <LinkCreatorContainer
            isVisible={isNewLinkModalVisible}
            setIsVisible={setIsNewLinkModalVisible}
            parentItem={item}
          />
        )}
        <PageHero>
          <HeaderBg>{/* {hasImg && <img src={techSupportBg} />} */}</HeaderBg>
          <Breadcrumbs
            items={item && item.breadcrumbs ? item.breadcrumbs : []}
          />
          <TitleBar>
            <PageTitle style={{height: 72}}>
              {!isWorkspaceItem && <Emoji>{item?.emoji || '📁'}</Emoji>}
              <Truncate>{item ? item.name : prettyName}</Truncate>
            </PageTitle>
            {showButtons && (
              <>
                <PageActionBar tw="z-20">
                  <PageActionBarDropdownButton
                    lg
                    style={{border: 0}}
                    tw="flex items-center gap-1 text-blue-600"
                    menu={[
                      [
                        {
                          label: 'Browse',
                          to: browseUrl,
                        },
                        {
                          label: 'Manage',
                          to: manageUrl,
                        },
                        {
                          label: 'Share',
                          onClick: () => setIsShareItemModalVisible(true),
                        },
                      ],
                    ]}
                  >
                    {isManageView ? 'Manage' : 'Browse'}
                    <AiOutlineCaretDown />
                  </PageActionBarDropdownButton>
                  {canUseCreateMenu && (
                    <DropdownButton
                      primary
                      tw="h-11 rounded-sm whitespace-nowrap"
                      to={createPageUrl}
                      menu={menu}
                    >
                      Create page
                    </DropdownButton>
                  )}
                  {!canUseCreateMenu && (
                    <Tooltip
                      placement="bottom"
                      title="You do not have permission to create new pages in this folder"
                    >
                      {/* This div is needed for the tooltip to appear. Otherwise the button being disabled prevents the tooltip from working!  */}
                      <div>
                        <PageActionBarButton lg primary disabled>
                          <span tw="hidden sm:inline">Create page</span>
                          <span tw="inline sm:hidden">
                            <AiOutlineFileAdd size={24} tw="mx--1.5" />
                          </span>
                        </PageActionBarButton>
                      </div>
                    </Tooltip>
                  )}
                </PageActionBar>
              </>
            )}
          </TitleBar>
        </PageHero>
        {children}
      </Wrapper>
    </>
  );
}

const GlobalStyle = createGlobalStyle`
  body {
    background: ${colors.gray[50]} !important;
    overflow-x: hidden;
  }
`;

const Wrapper = styled.div((props) => [
  tw`
  `,
]);

const HeaderBg = styled.div((props) => [
  tw`
    bg-white
    h-full
    absolute
    top-0
    left-0
    w-full
    overflow-hidden
  `,
  css`
    z-index: -1;
  `,
]);

const PageHero = styled.div((props) => [
  tw`
    pt-24
    sm:pt-28
    px-6
    sm:px-6
    md:px-8
    lg:px-10
    xl:px-12
    pb-3
    sm:pb-5
    relative
    z-30
  `,
  css`
    box-shadow: rgba(0, 0, 0, 0.01) 0px 4px 4px;
  `,
]);

const TitleBar = styled.div((props) => [
  tw`
    flex
    items-center
  `,
]);

const PageTitle = styled.div((props) => [
  tw`
    text-gray-800
    font-bold
    flex-1
    items-center
  `,
  css`
    /* min-width: 0; fixes a bug where the whole flex container expands beyond
     * the width of the page when switching between Browse and Manage views on
     * a mobile device.
     */
    min-width: 0;
    font-size: 40px;

    @media (min-width: 640px) {
      font-size: 48px;
    }
  `,
]);

const Emoji = styled.div((props) => [
  tw`
    w-12
    mr-2
    flex
    justify-center
    float-left
  `,
]);

const PageActionBar = styled.div((props) => [
  tw`
    flex
    gap-2
    sm:gap-3
    h-full
    items-center
  `,
]);

const PageActionBarDropdownButton = styled(DropdownButton)((props) => [
  tw`
    px-3
    sm:px-4
  `,
  css`
    height: 42px !important;

    @media (min-width: 640px) {
      height: 48px !important;
    }
  `,
]);

const PageActionBarButton = styled(Button)((props) => [
  tw`
    px-4
    focus-within:px-3
  `,
  css`
    height: 42px !important;

    @media (min-width: 640px) {
      height: 48px !important;
    }
  `,
]);

// We export PageBody for pages to include themselves. This makes it easier to
// add in the ItemSidePanel.
export const PageBody = styled.div((props) => [
  tw`
    py-8
    pb-32
    relative
  `,
  !props.noPadding && tw`
    px-6
    sm:px-6
    md:px-8
    lg:px-10
    xl:px-12
  `,
  css`
    overflow-x: scroll;
  `,
  // Set the min-height to fill the page so the horizontal scroll bar is
  // positioned at the bottom of the browser window.
  // Note: We could set it to 'min-height: calc(100vh - 302px);' ... except the
  // side panel has min-height as 100vh (even when hidden — changing this when
  // hidden would lead to weirdness when you're partly scrolled and then hide it).
  css`
    min-height: 100vh;
  `,
]);

export default FolderLayout;
