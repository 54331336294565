import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled, css} from 'twin.macro';
import Popover from 'components/Popover';

GridCellPopover.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  content: PropTypes.node,
  setTriggerPopoverOpen: PropTypes.func,
  triggerPopoverOpen: PropTypes.bool,
};

GridCellPopover.defaultProps = {
};

function GridCellPopover (props) {
  const {
    children,
    content,
    setTriggerPopoverOpen,
    triggerPopoverOpen,
  } = props;

  if (!content) {
    return children;
  }

  const wrappedContent = (
    <Wrapper>
      {content}
    </Wrapper>
  );

  return (
    <CellWrapper data-test-id="popover">
      <Popover
        content={wrappedContent}
        setTriggerPopoverOpen={setTriggerPopoverOpen}
        triggerPopoverOpen={triggerPopoverOpen}>
        <ContentWrapper >
          {children}
        </ContentWrapper>
      </Popover>
    </CellWrapper>
  );
}

const Wrapper = styled.div((props) => [
  tw`
    m--4
    p-4
  `,
]);

const CellWrapper = styled.div((props) => [
  tw`
    h-full
    mx--3
    cursor-pointer
  `,
  css`
    width: calc(100% + 24px);
  `,
]);

const ContentWrapper = styled.div((props) => [
  tw`
    px-3
    h-full
    flex
    items-center
  `,
]);

export default React.memo(GridCellPopover);
