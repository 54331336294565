import verbs from './verbs.json';

function isFirstWordVerb (titleText) {
  const firstWord = titleText
    .trim()
    .split(' ')[0]
    .toLowerCase();

  const isStepEmpty = !firstWord;
  if (isStepEmpty) {
    return true;
  }

  return verbs.includes(firstWord);
}

export default isFirstWordVerb;
