import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import isHotkey from 'is-hotkey';
import PrintHeader from './PrintHeader';

Printable.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

function Printable (props) {
  const {children} = props;

  useEffect(() => {
    window.onkeydown = (event) => {
      if (isHotkey('mod+p', event)) {
        event.preventDefault();
        window.open(`${window.location.pathname}/print`);
      }
    };
  }, []);

  return <>
    <PrintHeader onlyShowWhenPrinting>For a better print experience, use the Cmd+P (Mac) or Ctrl+P (Windows) shortcut to print</PrintHeader>
    {children}
  </>;
}

export default Printable;
