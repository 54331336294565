import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled, css} from 'twin.macro';

ContentActionNeededPrompt.propTypes = {
  setActionNeededPopoverOpen: PropTypes.func.isRequired,
};

ContentActionNeededPrompt.defaultProps = {
};

function ContentActionNeededPrompt (props) {
  const {setActionNeededPopoverOpen} = props;

  return (
    <CellWrapper onClick={() => setActionNeededPopoverOpen(true)} />
  );
}

const CellWrapper = styled.div((props) => [
  tw`
    h-full
    mx--3
    cursor-pointer
  `,
  css`
    width: calc(100% + 24px);
  `,
]);

export default ContentActionNeededPrompt;
