import React from 'react';
import Modal from 'components/Modal';
import Button, {ButtonSpacer} from 'components/Button';
import PropTypes from 'prop-types';
import Input from 'components/Input';

FolderRenamerModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  inputValue: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

function FolderRenamerModal (props) {
  const {
    isVisible,
    onChange,
    inputValue,
    closeModal,
    onSubmit,
  } = props;

  return (
    <Modal
      visible={isVisible}
      title="Rename folder"
      onClose={closeModal}
      onSubmit={onSubmit}
      footer={<>
        <Button type="button" link onClick={closeModal}>
          Cancel
        </Button>
        <ButtonSpacer />
        <Button type="submit" primary disabled={!inputValue.trim()} data-test-id="modal_rename-button">
        Rename
        </Button>
      </>}
    >
      <Input
        maxLength={50}
        data-test-id="modal_input"
        value={inputValue}
        onChange={onChange}
        placeholder="Give your folder a new name" autoFocus></Input>
    </Modal>
  );
}

export default FolderRenamerModal;
