import makeProcessTitle from './makeProcessTitle';
import makeProcessStepList from './makeProcessStepList';

const makeProcess = ({processTitle, processSteps} = {}) => ({
  type: 'process',
  open: true,
  children: [
    makeProcessTitle({processTitle}),
    makeProcessStepList({processSteps}),
  ],
});

export default makeProcess;
