function getTotalTextLength ({content}) {
  const paragraphs = content
    .filter((child) => child.type === 'paragraph');

  const lists = content
    .filter((child) => child.type === 'ordered-list-item' || child.type === 'list-item');
  const listsTextNodes = lists.map((list) => list.children).flat();

  const textLength = paragraphs.reduce((acc, paragraph) => acc + paragraph.children[0]?.text?.length, 0);
  const listTextLength = listsTextNodes.reduce((acc, list) => acc + list?.text?.length, 0);
  const totalTextLength = textLength + listTextLength;

  return totalTextLength;
}

export default getTotalTextLength;
