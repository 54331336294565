import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled} from 'twin.macro';
import P from './P';

ModalError.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  title: PropTypes.string.isRequired,
};

ModalError.defaultProps = {
};

function ModalError (props) {
  const {
    children,
    title,
  } = props;

  return (
    <Error>
      <P tw="font-bold mb-0">{title}</P>
      <div tw="text-red-100">{children}</div>
    </Error>
  );
}

const Error = styled.div((props) => [
  tw`
    bg-red-600
    text-white
    py-3
    px-6
    border-b
`,
]);

export default ModalError;
