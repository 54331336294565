import {Editor, Transforms} from 'slate';
import makeParagraph from '../factory/makeParagraph';
import makeProcess from '../factory/makeProcess';

function onShiftEnterInProcessStepTitle (editor, event, selectionMeta) {
  const {
    isSelectionInSingleElement,
    selectionStart,
    selectionStartElementPath,
    selectionStartElement,
  } = selectionMeta;

  if (
    event.key !== 'Enter' ||
    !event.shiftKey ||
    !selectionStart ||
    !isSelectionInSingleElement ||
    selectionStartElement.type !== 'process-step-title'
  ) {
    return false;
  }

  event.preventDefault();

  const stepIndex = selectionStartElementPath[2];
  const [stepList, stepListPath] = Editor.above(editor, {
    at: selectionStartElementPath,
    match: (n) => n.type === 'process-step-list',
  });

  const stepsBelow = stepList.children.slice(stepIndex + 1);
  const pathBelowProcess = [selectionStartElementPath[0] + 1];

  if (stepsBelow.length) {
    Transforms.insertNodes(editor, makeProcess({processSteps: []}), {
      at: pathBelowProcess,
    });
    Transforms.moveNodes(editor, {
      at: stepListPath,
      match: (node, path) => path.length === 3 && path[2] > stepIndex,
      to: [...pathBelowProcess, 1, 0],
    });
    const endOfFirstStepInNewProcess = Editor.end(editor, [...pathBelowProcess, 1, 0, 0]);
    requestAnimationFrame(() => {
      Transforms.select(editor, endOfFirstStepInNewProcess);
    });
  }
  else {
    Transforms.insertNodes(editor, makeParagraph(), {
      at: pathBelowProcess,
    });
    requestAnimationFrame(() => {
      Transforms.select(editor, pathBelowProcess);
    });
  }

  return true;
}

export default onShiftEnterInProcessStepTitle;
