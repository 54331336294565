// https://stackoverflow.com/questions/21741841/detecting-ios-android-operating-system
function getIsAndroid () {
  const userAgent = navigator?.userAgent || navigator?.vendor || window?.opera;

  if (/android/i.test(userAgent)) {
    return true;
  }

  return false;
}

export default getIsAndroid;
