import React, {useEffect} from 'react';

function LinkedInTracking () {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML = `
      _linkedin_partner_id = "4319346";
      window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
      window._linkedin_data_partner_ids.push(_linkedin_partner_id);

      (function(l) {
        if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
        window.lintrk.q=[]}
        var s = document.getElementsByTagName("script")[0];
        var b = document.createElement("script");
        b.type = "text/javascript";b.async = true;
        b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
        s.parentNode.insertBefore(b, s);
      })(window.lintrk);
    `;

    document.body.appendChild(script);

    return () => {
      // Cleanup script when component is unmounted
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <noscript>
        <img
          height="1"
          width="1"
          style={{display: 'none'}}
          alt=""
          src="https://px.ads.linkedin.com/collect/?pid=4319346&fmt=gif"
        />
      </noscript>
    </>
  );
}

export default LinkedInTracking;
