const {ITEM_TYPE} = require('am-constants');
const PropTypes = require('prop-types');

const CustomPropTypes = {
  Item: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string.isRequired,
    ref: PropTypes.string.isRequired,
    itemTypeId: PropTypes.oneOf([
      ITEM_TYPE.CHECKLIST,
      ITEM_TYPE.DOCUMENT,
      ITEM_TYPE.FOLDER,
      ITEM_TYPE.FORM,
      ITEM_TYPE.LINK,
      ITEM_TYPE.WORKSPACE,
      ITEM_TYPE.SECTION,
    ]),
  }),
};

export default CustomPropTypes;
