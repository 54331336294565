import {gql} from '@apollo/client';
import useMutation from 'hooks/useMutation';

const ADD_TEAM_MEMBER = gql`
  mutation AddTeamMember($workspaceId: Int!, $email: String!, $role: Int!) {
    teamMemberAdd(workspaceId: $workspaceId, email: $email, role: $role) {
      personId
    }
  }
`;

function useAddTeamMemberMutation (props) {
  const [addTeamMember, {
    reset,
    loading,
    data,
    error,
  }] = useMutation(ADD_TEAM_MEMBER, {
    fetchPolicy: 'no-cache',
    refetchQueries: [
      'GetTeamMembersWithRoles',
    ],
  });

  return [
    addTeamMember,
    {
      reset,
      data,
      loading,
      error,
    },
  ];
}

export default useAddTeamMemberMutation;
