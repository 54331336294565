import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Portal} from 'react-portal';
import {
  AiOutlineCheckSquare, AiOutlineDelete,
} from 'react-icons/ai';
import {ReactEditor, useSlateStatic} from 'slate-react';
import throttle from 'lodash.throttle';
import TextInputIcon from 'icons/TextInputIcon';
import TextAreaIcon from 'icons/TextAreaIcon';
import {Editor, Path, Transforms} from 'slate';
import ChoiceGroupRatingIcon from 'icons/ChoiceGroupRatingIcon';
import ChoiceGroupHorizontalIcon from 'icons/ChoiceGroupHorizontalIcon';
import ChoiceGroupVerticalIcon from 'icons/ChoiceGroupVerticalIcon';
import {CgRadioChecked} from 'react-icons/cg';
import Toolbar, {ToolbarItemDivider} from './Toolbar';
import ToolbarItem from './ToolbarItem';
import changeTextFieldSize from '../utils/changeTextFieldSize';
import changeChoiceGroupFormat from '../utils/changeChoiceGroupFormat';
import changeChoiceGroupLimit from '../utils/changeChoiceGroupLimit';

FormGroupToolbar.propTypes = {
  formGroupNode: PropTypes.shape({
    getBoundingClientRect: PropTypes.func,
  }).isRequired,
  inputElement: PropTypes.shape({
    children: PropTypes.array,
    format: PropTypes.string,
    limit: PropTypes.number,
    size: PropTypes.string,
    type: PropTypes.string.isRequired,
  }),
};

function FormGroupToolbar (props) {
  const {
    formGroupNode,
    inputElement,
  } = props;

  const [position, setPosition] = useState({});
  const editor = useSlateStatic();
  const inputPath = ReactEditor.findPath(editor, inputElement);

  const {type} = inputElement;
  const [format, setFormat] = useState(inputElement.format);
  const changeFormat = (value) => {
    setFormat(value);
    changeChoiceGroupFormat(editor, value);
  };
  const [limit, setLimit] = useState(inputElement.limit);
  const changeLimit = (value) => {
    setLimit(value);
    changeChoiceGroupLimit(editor, value);
  };
  const [size, setSize] = useState(inputElement.size);
  const changeSize = (value) => {
    setSize(value);
    changeTextFieldSize(editor, value);
  };

  const textFieldOptions = [
    {
      title: 'Single line input',
      onClick: () => changeSize('single'),
      icon: TextInputIcon,
      selected: size === 'single',
    },
    {
      title: 'Multi line input',
      onClick: () => changeSize('multi'),
      icon: TextAreaIcon,
      selected: size === 'multi',
    },
  ];

  const choiceGroupTypeOptions = [
    {
      title: 'Display as vertical list',
      onClick: () => changeFormat('vertical'),
      icon: ChoiceGroupVerticalIcon,
      selected: format === 'vertical',
    },
    {
      title: 'Display as horizontal list',
      onClick: () => changeFormat('horizontal'),
      icon: ChoiceGroupHorizontalIcon,
      selected: format === 'horizontal',
    },
    {
      title: 'Display as rating scale',
      onClick: () => {
        changeFormat('rating');
        changeLimit(1);

        // Select form-group label
        const formGroupElementPath = Path.parent(inputPath);
        const endOfLabel = Editor.end(editor, [...formGroupElementPath, 0]);

        Transforms.select(editor, endOfLabel);
      },
      icon: ChoiceGroupRatingIcon,
      selected: format === 'rating',
    },
  ];

  const choiceGroupFormatOptions = [
    {
      title: 'Support one choice',
      onClick: () => changeLimit(1),
      icon: CgRadioChecked,
      selected: limit === 1,
    },
    {
      title: 'Support multiple choices',
      onClick: () => changeLimit(0),
      icon: AiOutlineCheckSquare,
      selected: limit === 0,
      disabled: format === 'rating',
    },
  ];

  const removeInputOptions = [
    {
      title: type === 'text-field' ? 'Remove text field' : 'Remove multiple choice question',
      onClick: () => {
        // eslint-disable-next-line no-unused-vars
        const [formGroupElement, formGroupElementPath] = Editor.above(editor, {
          at: editor.selection.anchor.path,
          match: (n) => n.type === 'form-group',
        });
        Transforms.removeNodes(editor, {at: formGroupElementPath});
      },
      icon: AiOutlineDelete,
      red: true,
    },
  ];

  let menuGroups;

  if (type === 'text-field') {
    menuGroups = [
      textFieldOptions,
      removeInputOptions,
    ];
  }
  else {
    menuGroups = [
      choiceGroupTypeOptions,
      choiceGroupFormatOptions,
      removeInputOptions,
    ];
  }

  const reposition = () => {
    const rect = formGroupNode.getBoundingClientRect();

    setPosition({
      top: rect.top + window.pageYOffset - 60,
      left: rect.left,
      width: rect.width,
    });
  };

  useEffect(() => {
    const handleResize = throttle(reposition, 100);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(reposition, [formGroupNode]);

  return (
    <Portal>
      <div
        style={{
          position: 'absolute',
          zIndex: 10,
          display: 'flex',
          pointerEvents: 'none',
          ...position,
        }}
      >
        <Toolbar arrow style={{margin: '0 auto'}} onMouseUp={reposition}>
          {menuGroups.map((menuGroup, i) => (
            <React.Fragment key={`form-group-toolbar-divider-${i}`}>
              {i > 0 && <ToolbarItemDivider full />}
              {menuGroup.map((menuItem, j) => (
                <ToolbarItem
                  editor={editor}
                  key={`form-group-toolbar-item-${i}-${j}`}
                  iconSize={18}
                  subtleHover
                  data-test-id={menuItem.title.toLowerCase().replaceAll(' ', '-')}
                  {...menuItem}
                />
              ))}
            </React.Fragment>
          ))}
        </Toolbar>
      </div>
    </Portal>
  );
}

export default React.memo(FormGroupToolbar);
