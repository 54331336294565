function getVisibleImages (node, result = []) {
  if (node.type === 'image') {
    result.push(node);
  }

  if (node.children) {
    const openableTypes = ['process-step', 'process', 'help-block'];
    const isOpenableType = openableTypes.includes(node.type);
    if (isOpenableType && !node.open) {
      return [];
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const child of node.children) {
      getVisibleImages(child, result);
    }
  }

  return result;
}

export default getVisibleImages;
