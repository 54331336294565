export const BREADCRUMB_FIELDS = `
  id
  itemTypeId
  name
  ref
  key
  urlName
  public
`;

export default null;
