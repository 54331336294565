import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {ReactEditor, useSlate} from 'slate-react';

DraggableElement.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  element: PropTypes.shape().isRequired,
};

// Currently DraggableElement is just used to store the index of each element,
// passing it down via context for react-beautiful-dnd to use.
function DraggableElement (props) {
  const {
    children,
    element,
  } = props;

  const editor = useSlate();
  const path = ReactEditor.findPath(editor, element);
  const index = path.slice(-1)[0];

  const context = useMemo(() => ({
    index,
  }), [index]);

  return (
    <DraggableElementContext.Provider value={context}>
      {children}
    </DraggableElementContext.Provider>
  );
}

export const DraggableElementContext = React.createContext();

export default DraggableElement;
