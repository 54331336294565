import {ITEM_TYPE} from 'am-constants';

function checkIfItemIsPage (item) {
  return [
    ITEM_TYPE.DOCUMENT,
    ITEM_TYPE.CHECKLIST,
    ITEM_TYPE.FORM,
  ].includes(item.itemTypeId);
}

export default checkIfItemIsPage;
