import {AiFillFolder, AiOutlineLink, AiOutlineFile} from 'react-icons/ai';
import checkIfItemIsFolder from './checkIfItemIsFolder';
import checkIfItemIsLink from './checkIfItemIsLink';

const getIconForItem = (item) => {
  if (checkIfItemIsFolder(item)) {
    return AiFillFolder;
  }

  if (checkIfItemIsLink(item)) {
    return AiOutlineLink;
  }

  return AiOutlineFile;
};

export default getIconForItem;
