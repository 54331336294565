import React, {useState} from 'react';
import PropTypes from 'prop-types';
import GridRow from './GridRow';
import GridCell from './GridCell';
import GridCellGroup from './GridCellGroup';

DataGridHeader.propTypes = {
  columnGroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onCollapseAll: PropTypes.func,
  onExpandAll: PropTypes.func,
  onDeselectAll: PropTypes.func,
  onSelectAll: PropTypes.func,
  supportExpandAll: PropTypes.bool,
};

DataGridHeader.defaultProps = {
  supportExpandAll: false,
};

function DataGridHeader (props) {
  const {
    columnGroups,
    onCollapseAll,
    onExpandAll,
    onDeselectAll,
    onSelectAll,
    supportExpandAll,
  } = props;

  const [allExpanded, setAllExpanded] = useState();

  const onExpand = () => {
    onExpandAll();
    setAllExpanded(true);
  };

  const onCollapse = () => {
    onCollapseAll();
    setAllExpanded(false);
  };

  return (
    <GridRow
      expandable={supportExpandAll}
      expanded={allExpanded}
      header
      onCollapse={onCollapse}
      onExpand={onExpand}
      onDeselect={onDeselectAll}
      onSelect={onSelectAll}>
      {columnGroups.map((columnGroup, i) => (
        <GridCellGroup key={`grid-cell-group${i}`}>
          {columnGroup.columns && columnGroup.columns.map((column) => (
            <GridCell
              key={column.label}
              width={column.width}>
              {column.label}
            </GridCell>
          ))}
        </GridCellGroup>
      ))}
    </GridRow>
  );
}

export default DataGridHeader;
