import getElementById from 'containers/SlateEditor/utils/getElementById';
import {Editor, Transforms} from 'slate';

function openNextNodeWithSlate (editor, nodeId) {
  // eslint-disable-next-line no-unused-vars
  const [node, nodePath] = getElementById(editor, nodeId);
  Transforms.setNodes(
    editor,
    {
      open: false,
    },
    {
      at: nodePath,
    }
  );
  // eslint-disable-next-line no-unused-vars
  const [nextNode, nextNodePath] = Editor.next(editor, {at: nodePath, match: (n) => n.type === 'process-step'}) || [undefined, undefined];

  if (nextNodePath !== undefined && nextNodePath[0] === nodePath[0]) {
    openNode(editor, nextNodePath);
    selectEndOfProcessStepTitle(editor, nextNodePath);
    return;
  }

  const processPath = nodePath.slice(0, 1);
  // eslint-disable-next-line no-unused-vars
  const [nextProcessNode, nextProcessNodePath] = Editor.next(editor, {at: processPath, match: (n) => n.type === 'process'}) || [undefined, undefined];

  if (nextProcessNodePath !== undefined) {
    openNode(editor, nextProcessNodePath);
    selectEndOfProcessStepTitle(editor, [...nextProcessNodePath, 1, 0]);
  }
}

function openNode (editor, location) {
  Transforms.setNodes(
    editor,
    {
      open: true,
    },
    {
      at: location,
    }
  );
}

function selectEndOfProcessStepTitle (editor, processStepPath) {
  const processStepTitlePath = [...processStepPath, 0];
  const endOfProcessStepTitle = Editor.end(editor, processStepTitlePath);

  requestAnimationFrame(() =>
    Transforms.select(
      editor,
      endOfProcessStepTitle
    ));
}

export default openNextNodeWithSlate;
