import React, {useState} from 'react';
import PropTypes from 'prop-types';
import P from 'components/P';
import BrokenDocumentModal from './components/BrokenDocumentModal';
import useUndoDocumentUpdateMutation from './hooks/useUndoDocumentUpdateMutation';

DocumentUpdateUndoerContainer.propTypes = {
  itemRef: PropTypes.string,
  itemKey: PropTypes.string,
  currentFolderName: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

function DocumentUpdateUndoerContainer (props) {
  const {
    itemRef,
    itemKey,
    children,
  } = props;

  const [isVisible, setIsVisible] = useState(true);

  const [onUndoDocumentUpdate, {error}] = useUndoDocumentUpdateMutation({itemRef, itemKey});

  const closeModal = () => {
    setIsVisible(false);
  };

  const onSubmit = async () => {
    await onUndoDocumentUpdate();
    window.location.reload(false);
  };

  const renderError = () => (
    <P bottom>Please check your connection and try again.</P>
  );

  return (
    <BrokenDocumentModal
      isVisible={isVisible}
      onSubmit={onSubmit}
      closeModal={closeModal}
      errorTitle={'Error saving'}
      renderError={error && renderError}
    >{children}</BrokenDocumentModal>
  );
}

export default DocumentUpdateUndoerContainer;
