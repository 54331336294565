import {Editor, Transforms} from 'slate';
import isHotkey from 'is-hotkey';
import makeParagraph from '../factory/makeParagraph';
import {SELECTABLE_ELEMENT_TYPES} from '../constants';

function onBackspaceOrShiftArrowAfterSelectableElement (editor, event, selectionMeta, {selectElements}) {
  const {
    isSelectionAtStartOfElement,
    isSelectionCollapsed,
    selectionStart,
    selectionStartAncestors,
    selectionStartElement,
    selectionStartElementPath,
    selectionStartTextNode,
  } = selectionMeta;

  if (
    (
      event.key !== 'Backspace' &&
      !isHotkey('shift+left')(event) &&
      !isHotkey('shift+up')(event)
    ) ||
    !selectionStart ||
    !isSelectionAtStartOfElement ||
    selectionStartAncestors.find((n) => n.type === 'help-block-content')
  ) {
    return false;
  }

  const selectionStartContainerPath = selectionStartAncestors.find((n) => n.type === 'process-step')
    ? selectionStart.path.slice(0, 5)
    : selectionStart.path.slice(0, 1);
  const [selectableElement, selectableElementPath] = Editor.previous(editor, {at: selectionStartContainerPath}) || [undefined, undefined];

  if (
    !selectableElement ||
    !SELECTABLE_ELEMENT_TYPES.includes(selectableElement.type)
  ) {
    return false;
  }

  event.preventDefault();

  if (
    event.key === 'Backspace' &&
    [
      'heading1',
      'heading2',
      'heading3',
      'heading4',
      'paragraph',
    ].includes(selectionStartElement.type) &&
    isSelectionCollapsed &&
    isSelectionAtStartOfElement &&
    selectionStartTextNode.text.length === 0
  ) {
    Transforms.delete(editor, {at: selectionStartElementPath});
  }

  if (
    ['image', 'video'].includes(selectionStartElement.type) &&
    isSelectionCollapsed &&
    isSelectionAtStartOfElement &&
    selectionStartTextNode.text.length === 0
  ) {
    Transforms.delete(editor, {at: selectionStartElementPath});
    Transforms.insertNodes(editor, [makeParagraph()], {at: selectionStartElementPath});

    const startOfNewParagraph = {path: [...selectionStartElementPath, 0], offset: 0};
    requestAnimationFrame(() => {
      Transforms.setSelection(editor, {
        anchor: startOfNewParagraph,
        focus: startOfNewParagraph,
      });
    });

    return true;
  }

  // Had previously set it to select the process content from start to finish.
  // However, this led to buggy behavior where Slate would immediately deselect
  // everything if the process title had any content (!).
  // To avoid this, and as a reasonable alternative, we leave the selection at
  // the start of the next element. It's important that something is selected
  // or else key presses events won't do anything.
  const nextElementStart = Editor.start(editor, [
    ...selectableElementPath.slice(0, -1),
    selectableElementPath.slice(-1)[0] + 1,
  ]);
  Transforms.setSelection(editor, {
    anchor: nextElementStart,
    focus: nextElementStart,
  });

  requestAnimationFrame(() => {
    selectElements([selectableElement.id]);
  });

  return true;
}

export default onBackspaceOrShiftArrowAfterSelectableElement;
