import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled} from 'twin.macro';
import {Spin} from 'antd';
import GridCell from './GridCell';
import GridCellGroup from './GridCellGroup';
import DragAndDroppableGridRow from './DragAndDroppableGridRow';

DataGridRowLoader.propTypes = {
  columnGroups: PropTypes.arrayOf(PropTypes.shape({
    columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    frozen: PropTypes.bool,
  })).isRequired,
  indentLevel: PropTypes.number,
  index: PropTypes.number.isRequired,
  folderId: PropTypes.number,
  sectionId: PropTypes.number,
};

DataGridRowLoader.defaultProps = {
  indentLevel: 0,
};

function DataGridRowLoader (props) {
  const {
    columnGroups,
    indentLevel,
    index,
    folderId,
    sectionId,
  } = props;

  const [firstColumnGroup] = columnGroups;

  return (
    <DragAndDroppableGridRow
      expandable={false}
      indentLevel={indentLevel}
      index={index}
      item={{
        loader: true,
        folderId,
        sectionId,
      }}
      supportBulkSelect={false}
      supportDrag={false}>
      <GridCellGroup
        first
        frozen={firstColumnGroup.frozen}>
        {/*
        The GridCells are rendered as a simple way of sizing the GridCellGroup
        correctly.
        */}
        <Hidden>
          {firstColumnGroup.columns && firstColumnGroup.columns.map((column) => (
            <GridCell
              key={column.label}
              first
              width={column.width}>
            </GridCell>
          ))}
        </Hidden>
        <LoaderContainer><Spin size='small' /></LoaderContainer>
      </GridCellGroup>
    </DragAndDroppableGridRow>
  );
}

const Hidden = styled.div(() => [
  tw`
    opacity-0
    h-0
    overflow-hidden
    flex
    gap-px
  `,
]);

const LoaderContainer = styled.div(() => [
  tw`
    absolute
    w-full
    h-full
    flex
    items-center
    justify-center
  `,
]);

export default DataGridRowLoader;
