import {Editor} from 'slate';

// Will return [node, nodePath]
function getElementById (editor, id, {at} = {at: []}) {
  const nodes = Editor.nodes(
    editor,
    {
      at,
      match: (n) => n.id === id,
    }
  );

  return nodes.next().value;
}

export default getElementById;
