import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled} from 'twin.macro';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';
import Link from 'components/Link';
import getUrlForItem from 'utils/getUrlForItem';
import Item from './Item';

ClickableItem.propTypes = {
  item: PropTypes.shape({
    itemTypeId: PropTypes.number.isRequired,
    icon: PropTypes.string,
    name: PropTypes.string,
    urlName: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

function ClickableItem (props) {
  const {
    item,
  } = props;

  const workspace = useWorkspace();
  const url = getUrlForItem(workspace.urlName, item);

  if (url) {
    return (
      <Link to={url} tw="block focus:bg-blue-50">
        <Wrapper>
          <Item item={item}/>
        </Wrapper>
      </Link>
    );
  }

  return (
    <Wrapper>
      <Item item={item}/>
    </Wrapper>
  );
}

const Wrapper = styled.div((props) => [
  tw`
    cursor-pointer
    hover:bg-blue-50
  `,
]);

export default ClickableItem;
