import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import DropdownButton from 'components/DropdownButton';
import {PERMISSIONS} from 'am-constants';
import {getPermissionsForRoles} from 'am-utils';

FolderMenu.propTypes = {
  item: PropTypes.shape({
    ref: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    loggedInUserRoleIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  setFocusedItem: PropTypes.func.isRequired,
  setIsDeleteItemModalVisible: PropTypes.func.isRequired,
  setIsMoveFolderModalVisible: PropTypes.func.isRequired,
  setIsRenameFolderModalVisible: PropTypes.func.isRequired,
  setIsShareItemModalVisible: PropTypes.func.isRequired,
};

function FolderMenu (props) {
  const {
    item,
    setFocusedItem,
    setIsDeleteItemModalVisible,
    setIsMoveFolderModalVisible,
    setIsRenameFolderModalVisible,
    setIsShareItemModalVisible,
  } = props;
  const {loggedInUserRoleIds} = item;
  const loggedInUserItemPermissions = getPermissionsForRoles(loggedInUserRoleIds);

  useEffect(() => {
    setFocusedItem(item);
  });

  const menu = [[
    {
      onClick: () => setIsShareItemModalVisible(true),
      label: 'Share',
    },
    {
      disabled: !loggedInUserItemPermissions.includes(PERMISSIONS.RENAME_FOLDER),
      onClick: () => setIsRenameFolderModalVisible(true),
      label: 'Rename',
    },
    {
      disabled: !loggedInUserItemPermissions.includes(PERMISSIONS.MOVE_ITEM),
      onClick: () => setIsMoveFolderModalVisible(true),
      label: 'Move',
    },
    {
      disabled: !loggedInUserItemPermissions.includes(PERMISSIONS.DELETE_ITEM),
      onClick: () => setIsDeleteItemModalVisible(true),
      label: 'Delete',
    },
  ]];

  return <>
    <DropdownButton menu={menu} sm data-test-id="options-menu">Options</DropdownButton>
  </>;
}

export default FolderMenu;
