import {Editor, Path, Transforms} from 'slate';
import isEqual from 'lodash.isequal';
import {SELECTABLE_ELEMENT_TYPES} from '../constants';

// Handle the user starting the select from inside a selectable element's title
// or content, and then dragging it outside of that title or content.
function onSelectFromSelectableElement (editor, selectionMeta, {selectElements}) {
  const {
    isAnchorBeforeFocus,
    selectionAnchorAncestors,
    selectionAnchorElementPath,
    selectionEndElementPath,
    selectionFocusElementPath,
    selectionStartElementPath,
  } = selectionMeta;

  const selectableElementIndex = [...selectionAnchorAncestors].findIndex((element) => SELECTABLE_ELEMENT_TYPES.includes(element.type));
  if (selectableElementIndex === -1) {
    return false;
  }

  const selectableElement = selectionAnchorAncestors[selectableElementIndex];
  const selectableElementPath = selectionAnchorElementPath.slice(0, selectionAnchorAncestors.length - selectableElementIndex);

  const anchorAndFocusAreTogetherInTitleOrContent = isEqual(
    selectionAnchorElementPath.slice(0, selectableElementPath.length + 1),
    selectionFocusElementPath.slice(0, selectableElementPath.length + 1)
  );

  if (anchorAndFocusAreTogetherInTitleOrContent) {
    return false;
  }

  // Unlike processes and help blocks, with process steps we will also want to
  // be able to select other siblings in the same process-step-list.
  if (selectableElement.type !== 'process-step') {
    selectElements([selectableElement.id]);

    const selectedElementStart = Editor.start(editor, selectableElementPath);
    const selectedElementEnd = Editor.end(editor, selectableElementPath);
    Transforms.setSelection(editor, {
      anchor: isAnchorBeforeFocus ? selectedElementStart : selectedElementEnd,
      focus: isAnchorBeforeFocus ? selectedElementEnd : selectedElementStart,
    });

    return true;
  }

  const [selectableElementContainer, selectableElementContainerPath] = Editor.parent(editor, selectableElementPath);

  const selectedElementIndexes = selectableElementContainer.children
    .map((element, index) =>
      ((
        Path.isAfter([...selectableElementContainerPath, index + 1], selectionStartElementPath) &&
        Path.isBefore([...selectableElementContainerPath, index - 1], selectionEndElementPath)
      ) ? index : -1))
    .filter((index) => index !== -1);

  if (selectedElementIndexes.length === selectableElementContainer.children.length) {
    const [processElement, processElementPath] = Editor.parent(editor, selectableElementContainerPath);
    selectElements([processElement.id]);

    const processElementStart = Editor.start(editor, processElementPath);
    const processElementEnd = Editor.end(editor, processElementPath);
    Transforms.setSelection(editor, {
      anchor: isAnchorBeforeFocus ? processElementStart : processElementEnd,
      focus: isAnchorBeforeFocus ? processElementEnd : processElementStart,
    });

    return true;
  }

  const selectedElementIds = selectedElementIndexes.map((index) => selectableElementContainer.children[index].id);

  selectElements(selectedElementIds);

  const selectedElementsStart = Editor.start(editor, [...selectableElementContainerPath, selectedElementIndexes[0]]);
  const selectedElementsEnd = Editor.end(editor, [...selectableElementContainerPath, selectedElementIndexes.slice(-1)[0]]);
  Transforms.setSelection(editor, {
    anchor: isAnchorBeforeFocus ? selectedElementsStart : selectedElementsEnd,
    focus: isAnchorBeforeFocus ? selectedElementsEnd : selectedElementsStart,
  });

  return true;
}

export default onSelectFromSelectableElement;
