import React, {useState} from 'react';
import PropTypes from 'prop-types';
import tw, {styled} from 'twin.macro';
import {motion} from 'framer-motion';
import {AiOutlineRight} from 'react-icons/ai';

SidePanelSection.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  heading: PropTypes.string,
  open: PropTypes.bool,
};

function SidePanelSection (props) {
  const {
    children,
    heading,
  } = props;

  const [open, setOpen] = useState(props.open);
  const toggle = () => setOpen(!open);

  const arrowVariants = {
    open: {
      rotate: [0, 90],
    },
    closed: {
      rotate: [90, 0],
    },
  };

  const contentVariants = {
    open: {
      height: 'auto',
    },
    closed: {
      height: 0,
    },
  };

  return (
    <Wrapper>
      <Header onClick={toggle} open={open}>
        {heading}
        <div tw="flex-1"></div>
        <div tw="relative h-3 w-3">
          <motion.div
            style={{position: 'absolute', top: 0, left: 0}}
            animate={open ? 'open' : 'closed'}
            variants={arrowVariants}
            initial={false}
            transition={{duration: 0.15}}>
            <AiOutlineRight size={14} />
          </motion.div>
        </div>
      </Header>
      <motion.div
        animate={open ? 'open' : 'closed'}
        variants={contentVariants}
        initial={false}
        transition={{duration: 0.15}}
        style={{overflow: 'hidden'}}>
        <Content>
          {children}
        </Content>
      </motion.div>
    </Wrapper>
  );
}

const Wrapper = styled.div((props) => [
  tw`
  `,
]);

const Header = styled.div((props) => [
  tw`
    px-8
    py-4
    cursor-pointer
    flex
    font-medium
    items-center
    text-gray-400
    hover:bg-blue-50
    hover:text-gray-700
    select-none
  `,
  props.open && tw`
    text-gray-700
  `,
]);

const Content = styled.div((props) => [
  tw`
    px-8
    pt-1
    pb-8
    text-gray-700
  `,
]);

export default SidePanelSection;
