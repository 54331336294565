import {Transforms} from 'slate';
import makeParagraph from '../factory/makeParagraph';

function makeSureLastElementInContainerIsParagraph (editor, [entryNode, entryNodePath]) {
  if (
    ![
      'help-block-content',
      'process-step-content',
    ].includes(entryNode.type) ||
    entryNode.children.slice(-1)[0].type === 'paragraph'
  ) {
    return false;
  }

  Transforms.insertNodes(editor, makeParagraph(), {
    at: [...entryNodePath, entryNode.children.length],
  });

  return true;
}

export default makeSureLastElementInContainerIsParagraph;
