import {Editor, Path, Transforms} from 'slate';

function removeChoice (editor, path) {
  const endOfPreviousNode = path[path.length - 1] === 0 ? [...path, 0] : Editor.end(editor, [...Path.previous(path), 0]);

  Editor.withoutNormalizing(editor, () => {
    Transforms.removeNodes(editor, {
      at: path,
    });

    Transforms.select(editor, endOfPreviousNode);
  });
}

export default removeChoice;
