import React from 'react';
import PropTypes from 'prop-types';
import {captureException} from '@sentry/react';
import TeamMemberViewerForbidden from './TeamMemberViewerForbidden';
const {STATUS_CODE} = require('am-constants');

class TeamMembersPageErrorBoundary extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      error: {},
    };
  }

  static getDerivedStateFromError (error) {
    // Update state so the next render will show the fallback UI.
    return {errorStatusCode: error.statusCode};
  }

  // eslint-disable-next-line class-methods-use-this
  componentDidCatch (error) {
    if (error.statusCode !== STATUS_CODE.FORBIDDEN) {
      captureException(error);
    }
  }

  render () {
    if (this.state.errorStatusCode === STATUS_CODE.FORBIDDEN) {
      return (
        <TeamMemberViewerForbidden/>
      );
    }

    return this.props.children;
  }
}

// As we use a class component, propTypes have to be defined after.
// This is because classes are not hoisted to the top of the file.
TeamMembersPageErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default TeamMembersPageErrorBoundary;
