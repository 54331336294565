import React from 'react';
import PropTypes from 'prop-types';
import {Redirect} from '@reach/router';

RedirectWithWildcard.propTypes = {
  '*': PropTypes.string,
  to: PropTypes.string,
};

RedirectWithWildcard.defaultProps = {
};

/*
 * Supports a redirect like:
 * <RedirectWithWildcard path="/air/*" to="/help/*" />
 *
 * (This helps when updating the workspace url name!)
 */
function RedirectWithWildcard (props) {
  const to = props.to.replace('*', props['*']);

  return (
    <Redirect to={to} />
  );
}

export default RedirectWithWildcard;
