import {gql} from '@apollo/client';
import useQuery from 'hooks/useQuery';

const GET_TEAM_MEMBERS_WITH_ROLES = gql`
  query GetTeamMembersWithRoles($workspaceUrlName: String!) {
    workspace(workspaceUrlName: $workspaceUrlName) {
      teamMembers {
        roles
        user {
          id
          name
          email
          hasSignedUp
        }
      }
    }
  }
`;

function useTeamMembersWithRolesQuery (props) {
  const {
    workspaceUrlName,
  } = props;

  const {loading, error, data} = useQuery(GET_TEAM_MEMBERS_WITH_ROLES, {
    /**
     * cache-first so we only show the loading spinner the first time the page
     * is opened
     */
    fetchPolicy: 'cache-first',
    variables: {
      workspaceUrlName,
    },
  });

  return {
    data: data && data.workspace && data.workspace.teamMembers,
    loading,
    error,
  };
}

export default useTeamMembersWithRolesQuery;
