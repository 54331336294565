import {ITEM_TYPE} from 'am-constants';
import sortBy from 'lodash.sortby';
import pipe from 'utils/pipe';

// getPreparedItems returns the items:
// - With items removed if any of their ancestors are collapsed (i.e. not expanded)
// - With "adders" added. An adder is a row containing a button to add a new item.
// - With additional properties to help us render the grid.
//
// Additional properties:
// - expanded: Whether this item is currently expanded.
// - indentLevel: How many levels deep a child item is.
// - selected: Whether this item is currently selected.
function getPreparedItems ({
  itemsByParentId,
  items,
  expandedItemIds,
  parentId,
  selectedItemIds,
  supportAddItems,
}) {
  const addItemIndex = (arr) => arr.map((i, index) => ({
    ...i,
    itemIndex: index,
  }));

  const idOfLastSectionInFolder = getIdOfLastSectionInFolder(parentId, itemsByParentId);
  const addAdderToEnd = (arr) => [
    ...arr,
    {
      adder: true,
      indentLevel: 0,
      folderId: parentId,
      sectionId: idOfLastSectionInFolder,
      parentId: idOfLastSectionInFolder || parentId,
    },
  ];

  const doNothing = (arr) => arr;

  return pipe(
    getPreparedChildItems,
    supportAddItems ? addAdderToEnd : doNothing,
    addItemIndex
  )({
    itemsByParentId,
    items,
    expandedItemIds,
    parentId,
    selectedItemIds,
  });
}

function getPreparedChildItems ({
  itemsByParentId,
  items,
  expandedItemIds,
  indentLevel = 0,
  parentId,
  parentVisibleAndExpanded = true,
  selectedItemIds,
}) {
  if (!itemsByParentId[parentId]) {
    return [];
  }

  const sortedItemsByParentId = itemsByParentId[parentId].sort((a, b) => a?.rank?.localeCompare(b?.rank));

  return sortedItemsByParentId.reduce((acc, curr) => {
    const childItem = parentVisibleAndExpanded
      ? [{
        ...curr,
        expanded: expandedItemIds.has(curr.id),
        indentLevel,
        selected: selectedItemIds.has(curr.id),
      }]
      : [];

    const idOfLastSectionInFolder = getIdOfLastSectionInFolder(curr.id, itemsByParentId);
    const adderItem = parentVisibleAndExpanded && curr.supportAddChildItems && expandedItemIds.has(curr.id) && !childItem[0].isLoading
      ? [{
        adder: true,
        indentLevel: curr.expandable ? indentLevel + 1 : indentLevel,
        folderId: curr.id,
        sectionId: idOfLastSectionInFolder,
        parentId: idOfLastSectionInFolder || curr.id,
      }]
      : [];
    const loaderItem = parentVisibleAndExpanded && expandedItemIds.has(curr.id) && childItem[0].isLoading
      ? [{
        loader: true,
        indentLevel: curr.expandable ? indentLevel + 1 : indentLevel,
        folderId: curr.id,
        sectionId: idOfLastSectionInFolder,
        parentId: idOfLastSectionInFolder || curr.id,
      }] : [];

    return [
      ...acc,
      ...childItem,
      ...getPreparedChildItems({
        itemsByParentId,
        items,
        expandedItemIds,
        indentLevel: curr.expandable ? indentLevel + 1 : indentLevel,
        parentId: curr.id,
        parentVisibleAndExpanded: parentVisibleAndExpanded && (!curr.expandable || expandedItemIds.has(curr.id)),
        selectedItemIds,
      }),
      ...adderItem,
      ...loaderItem,
    ];
  }, []);
}

function getIdOfLastSectionInFolder (folderId, itemsByParentId) {
  if (!itemsByParentId[folderId]) {
    return undefined;
  }

  const sections = sortBy(itemsByParentId[folderId].filter((item) => item.itemTypeId === ITEM_TYPE.SECTION), 'rank');

  if (sections.length === 0) {
    return undefined;
  }

  return sections.splice(-1)[0].id;
}

export default getPreparedItems;
