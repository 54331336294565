import {Editor, Transforms} from 'slate';

const MAX_INDENT_LEVEL = 8;

const paragraphAndListTypes = [
  'checkbox',
  'list-item',
  'ordered-list-item',
  'paragraph',
];

function onTabInDocumentBody (editor, event, selectionMeta) {
  const {
    isSelectionInSingleElement,
    selectionStart,
    selectionStartElement,
  } = selectionMeta;

  if (
    event.key !== 'Tab' ||
    !selectionStart ||
    (selectionStartElement.type === 'title' && isSelectionInSingleElement)
  ) {
    return false;
  }

  event.preventDefault();

  if (event.shiftKey) {
    Editor.withoutNormalizing(editor, () => {
      unindentParagraphsAndLists(editor);
      unindentCodeBlocks(editor);
    });
  }
  else {
    Editor.withoutNormalizing(editor, () => {
      indentParagraphsAndLists(editor);
      indentCodeBlocks(editor);
    });
  }

  return true;
}

function indentParagraphsAndLists (editor) {
  for (let indentLevel = MAX_INDENT_LEVEL - 1; indentLevel >= 0; indentLevel--) {
    Transforms.setNodes(
      editor, {
        indent: indentLevel + 1,
      },
      {
        match: (n) =>
          paragraphAndListTypes.includes(n.type) &&
            (n.indent === indentLevel || (!n.indent && indentLevel === 0)),
      }
    );
  }
}

function unindentParagraphsAndLists (editor) {
  for (let indentLevel = 1; indentLevel <= MAX_INDENT_LEVEL; indentLevel++) {
    Transforms.setNodes(
      editor, {
        indent: indentLevel - 1,
      },
      {
        match: (n) =>
          paragraphAndListTypes.includes(n.type) &&
            n.indent === indentLevel,
      }
    );
  }
}

function indentCodeBlocks (editor) {
  const codeBlocks = getCodeBlocks(editor);

  codeBlocks.forEach(([codeBlockNode, codeBlockNodePath]) =>
    Transforms.insertText(editor, '  ', {
      at: {
        path: [...codeBlockNodePath, 0],
        offset: 0,
      },
    }));
}

function unindentCodeBlocks (editor) {
  const codeBlocks = getCodeBlocks(editor);

  codeBlocks.forEach(([codeBlockNode, codeBlockNodePath]) => {
    const [textNode, textNodePath] = Editor.node(editor, [...codeBlockNodePath, 0]);
    if (textNode.text.substr(0, 2) === '  ') {
      Transforms.delete(editor, {
        at: {
          anchor: {
            path: textNodePath,
            offset: 0,
          },
          focus: {
            path: textNodePath,
            offset: 2,
          },
        },
      });
    }
  });
}

function getCodeBlocks (editor) {
  return Array.from(Editor.nodes(editor, {
    match: (n) => n.type === 'code-block',
  }));
}

export default onTabInDocumentBody;
