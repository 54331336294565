import {Editor, Transforms} from 'slate';

function onArrowLeftFromStartOfLastRatingScaleOption (editor, event, selectionMeta) {
  const {
    isSelectionAtStartOfElement,
    selectionStart,
    selectionStartElement,
    selectionStartElementPath,
  } = selectionMeta;

  if (
    event.key !== 'ArrowLeft' ||
    !selectionStart ||
    selectionStartElement.type !== 'choice' ||
    !isSelectionAtStartOfElement
  ) {
    return false;
  }

  const [choiceGroupElement] = Editor.parent(editor, selectionStartElementPath);
  const {format} = choiceGroupElement;

  if (
    format !== 'rating' ||
    selectionStartElementPath[selectionStartElementPath.length - 1] !== choiceGroupElement.children.length - 1
  ) {
    return false;
  }

  event.preventDefault();

  const endOfFirstLabel = Editor.end(editor, [...selectionStartElementPath.slice(0, -1), 0]);
  Transforms.select(editor, endOfFirstLabel);

  return true;
}

export default onArrowLeftFromStartOfLastRatingScaleOption;
