import {ITEM_TYPE, CONTENT_ACTION_NEEDED} from 'am-constants';
import checkIfItemIsFolder from './checkIfItemIsFolder';

function getUrlForItem (workspaceUrlName, item) {
  if (!item) {
    return null;
  }

  if (item.itemTypeId === ITEM_TYPE.LINK) {
    return getUrlForLink(workspaceUrlName, item);
  }

  if (item.itemTypeId === ITEM_TYPE.WORKSPACE) {
    return `/${workspaceUrlName}`;
  }

  const published = item.contentActionNeededId !== CONTENT_ACTION_NEEDED.NEEDS_CONTENT;

  const folderPrefix = checkIfItemIsFolder(item) ? 'folders/' : '';
  const editSuffix = item.itemTypeId === ITEM_TYPE.DOCUMENT && !published ? '/edit' : '';

  const url = `/${workspaceUrlName}/${folderPrefix}${item.urlName}${editSuffix}`;

  return url;
}

function getUrlForLink (workspaceUrlName, item) {
  const url = item.details?.url || '';

  const urlWithProtocol = url.includes('://') ? item?.details?.url : `http://${item?.details?.url}`;
  const urlParts = urlWithProtocol.split('/');
  const urlInSameWorkspace = urlParts.length >= 4 &&
    urlParts[2] === window.location.host &&
    urlParts[3] === workspaceUrlName;

  if (urlInSameWorkspace) {
    return `/${urlParts.slice(3).join('/')}`;
  }

  return urlWithProtocol;
}

export default getUrlForItem;
