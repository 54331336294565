import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled} from 'twin.macro';
import P from 'components/P';
import Heading3 from 'components/Heading3';

Card.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  disabled: PropTypes.bool,
  heading: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  to: PropTypes.string,
};

Card.defaultProps = {
  disabled: false,
};

function Card (props) {
  const {
    children,
    disabled,
    heading,
    icon,
    to,
    ...rest
  } = props;

  if (to) {
    return (
      <a href={to} target="_blank" rel="noreferrer">
        <Wrapper tw="hover:text-gray-700" disabled={disabled} {...rest}>
          <IconWrapper>{icon}</IconWrapper>
          <div>
            <Heading3 tw="text-base mt-0 font-semibold">{heading}</Heading3>
            <P tw="text-xs mb-0">{children}</P>
          </div>
        </Wrapper>
      </a>
    );
  }

  return (
    <Wrapper tw="hover:text-gray-700" disabled={disabled} {...rest}>
      <IconWrapper>{icon}</IconWrapper>
      <div>
        <Heading3 tw="text-base mt-0 font-semibold">{heading}</Heading3>
        <P tw="text-xs mb-0">{children}</P>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div((props) => [
  tw`
    cursor-pointer
    flex
    p-5
    bg-blue-50
    hover:bg-blue-100
  `,
  props.disabled && tw`
    bg-gray-50
    hover:bg-gray-50
    cursor-default
  `,
]);

const IconWrapper = styled.div((props) => [
  tw`
    w-12
    h-12
    flex
    items-center
    justify-center
    mr-5

  `,
]);

export default Card;
