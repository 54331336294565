import React from 'react';
import PropTypes from 'prop-types';

const TeamMembersContext = React.createContext();

TeamMembersProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  teamMembers: PropTypes.arrayOf(PropTypes.shape({})),
};

TeamMembersProvider.defaultProps = {
};

function TeamMembersProvider (props) {
  const {
    children,
    teamMembers,
  } = props;

  const context = {
    teamMembers,
  };

  return <>
    <TeamMembersContext.Provider value={context}>
      {children}
    </TeamMembersContext.Provider>
  </>;
}

export function useTeamMembers () {
  const context = React.useContext(TeamMembersContext);

  if (context === undefined) {
    throw new Error('useTeamMembers must be used within a TeamMembersProvider');
  }

  return context;
}

export default TeamMembersProvider;
