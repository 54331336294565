import React, {useState} from 'react';
import PropTypes from 'prop-types';

const SelectionContext = React.createContext();

SelectionProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

// The SlateEditor doesn't rerender when selection changes. Having its own
// context allows menus to listen to changes.
function SelectionProvider (props) {
  const {children} = props;

  const [selection, setSelection] = useState();

  const context = {
    selection,
    setSelection,
  };

  return <>
    <SelectionContext.Provider value={context}>
      {children}
    </SelectionContext.Provider>
  </>;
}

export function useSelection () {
  const context = React.useContext(SelectionContext);

  if (context === undefined) {
    throw new Error('useSelection must be used within a SelectionProvider');
  }

  return context;
}

export default SelectionProvider;
