import PropTypes from 'prop-types';
import {gql} from '@apollo/client';
import useQuery from 'hooks/useQuery';

const GET_ITEM_PERMISSIONS = gql`
  query getItemPermissionsQuery($ref: String!, $key: String!) {
    item(ref: $ref, key: $key) {
      workspaceMembersRoleIds
      userRoleIds {
        itemRoleIds
        user {
          name
          id
          email
        }
      }
    }
  }
`;

useGetItemPermissionsQuery.propTypes = {
  workspaceUrlName: PropTypes.string.isRequired,
  emails: PropTypes.array.isRequired,
};

function useGetItemPermissionsQuery ({itemRef, itemKey}) {
  const {
    loading, data, error,
  } = useQuery(GET_ITEM_PERMISSIONS, {
    fetchPolicy: 'no-cache',
    variables: {
      ref: itemRef,
      key: itemKey,
    },
  });

  return {
    loading,
    error,
    data,
  };
}

export default useGetItemPermissionsQuery;
