import makeParagraph from '../factory/makeParagraph';
import convertSelectedNodes from '../utils/convertSelectedNodes';

const listItemTypes = [
  'checkbox',
  'list-item',
  'ordered-list-item',
];

function onBackspaceAtStartOfListElement (editor, event, selectionMeta) {
  const {
    isSelectionAtStartOfElement,
    selectionStart,
    selectionStartElement,
  } = selectionMeta;

  if (
    event.key !== 'Backspace' ||
    !selectionStart ||
    !listItemTypes.includes(selectionStartElement.type) ||
    !isSelectionAtStartOfElement
  ) {
    return false;
  }

  event.preventDefault();
  convertSelectedNodes(editor, {makeNode: makeParagraph});

  return true;
}

export default onBackspaceAtStartOfListElement;
