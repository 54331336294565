import {Transforms} from 'slate';
import checkCanElementTypeBeTransformed from './checkCanElementTypeBeTransformed';

function convertSelectedNodes (editor, {makeNode, ...attrs}) {
  const node = makeNode();

  Transforms.setNodes(
    editor,
    {
      ...node,
      ...attrs,
    },
    {match: (n) => checkCanElementTypeBeTransformed(n.type) && n.type !== node.type}
  );
}

// Original implementation: May need to revert to extend the behavior.
//
// const selectedNodes = getSelectedNodes(editor);
// const nodeType = makeNode().type;

// selectedNodes
//   .filter(([node]) => canNodeBeConverted(node.type))
//   .filter(([node]) => node.type !== nodeType)
//   .forEach(([_, nodePath]) => {
//     const node = makeNode();

//     Transforms.setNodes(
//       editor,
//       node,
//       {at: nodePath}
//     );
//   });

export default convertSelectedNodes;
