import PropTypes from 'prop-types';
import {useQuery} from '@tanstack/react-query';
import {gql} from 'graphql-request';
import graphQLClient from 'utils/graphQLClient';

const query = gql`
  query GetWorkspace($workspaceUrlName: String!, $itemRef: String, $itemKey: String, $responseRef: String, $responseKey: String) {
    workspace(workspaceUrlName: $workspaceUrlName, itemRef: $itemRef, itemKey: $itemKey, responseRef: $responseRef, responseKey: $responseKey) {
      id
      name
      subscription(itemRef: $itemRef, itemKey: $itemKey, responseRef: $responseRef, responseKey: $responseKey) {
        isFreeTrial
        subscriptionTypeId
      }
      urlName
      loggedInUserRoleIds
      featureFlags
    }
  }
`;

useWorkspaceQuery.propTypes = {
  workspaceUrlName: PropTypes.string,
  itemRef: PropTypes.string,
  itemKey: PropTypes.string,
  responseRef: PropTypes.string,
  responseKey: PropTypes.string,
};

function useWorkspaceQuery (props) {
  const {
    workspaceUrlName,
    itemRef,
    itemKey,
    responseRef,
    responseKey,
  } = props;

  const cacheKey = [workspaceUrlName];

  const variables = {
    workspaceUrlName,
    itemRef,
    itemKey,
    responseRef,
    responseKey,
  };

  const queryFn = async () => {
    const {workspace} = await graphQLClient.request(query, variables);

    return workspace;
  };

  const options = {};

  return useQuery(
    cacheKey,
    queryFn,
    options
  );
}

export default useWorkspaceQuery;
