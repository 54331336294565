import React from 'react';
import PropTypes from 'prop-types';
import ListItem from './ListItem'; // eslint-disable-line import/no-cycle

List.propTypes = {
  compact: PropTypes.bool,
  icon: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onClickItem: PropTypes.func,
  purple: PropTypes.bool,
  selectedItemId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  semiCompact: PropTypes.bool,
};

List.defaultProps = {
  compact: false,
  onClickItem: (item) => {},
  purple: false,
  semiCompact: false,
};

function List (props) {
  const {
    compact,
    icon,
    items,
    onClickItem,
    purple,
    selectedItemId,
    semiCompact,
  } = props;

  return items.map((item, i) => (
    <ListItem
      icon={icon}
      {...item}
      compact={compact}
      onClickItem={onClickItem}
      id={item.id || i}
      key={item.id || i}
      purple={purple}
      selected={selectedItemId && selectedItemId === item.id}
      selectedItemId={selectedItemId}
      semiCompact={semiCompact}
      tags={item.tags}
      dataTestId={item.dataTestId}
    />
  ));
}
export default List;
