import React from 'react';
import PropTypes from 'prop-types';
import {WORKSPACE_ROLE_PERMISSIONS} from 'am-constants';

const WorkspaceContext = React.createContext();

WorkspaceProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  workspace: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    subscription: PropTypes.shape({
      isFreeTrial: PropTypes.bool,
      subscriptionTypeId: PropTypes.number.isRequired,
    }),
    urlName: PropTypes.string,
    loggedInUserRoleIds: PropTypes.arrayOf(PropTypes.number),
    featureFlags: PropTypes.shape({}),
  }),
};

function WorkspaceProvider (props) {
  const {children, workspace} = props;
  const {
    id,
    name,
    subscription,
    urlName,
    loggedInUserRoleIds,
    featureFlags,
  } = workspace;

  // We currently assume the user only has one role
  const userRole = loggedInUserRoleIds && loggedInUserRoleIds[0];
  const workspacePermissions = userRole ? WORKSPACE_ROLE_PERMISSIONS[userRole] : [];

  const isExampleWorkspace = urlName === 'example';

  const context = {
    id,
    name,
    subscription,
    urlName,
    workspacePermissions,
    featureFlags: featureFlags || {},
    isExampleWorkspace,
  };

  return <>
    <WorkspaceContext.Provider value={context}>
      {children}
    </WorkspaceContext.Provider>
  </>;
}

export function useWorkspace () {
  const context = React.useContext(WorkspaceContext);

  if (context === undefined) {
    throw new Error('useWorkspace must be used within a WorkspaceProvider');
  }

  return context;
}

export default WorkspaceProvider;
