import {gql} from '@apollo/client';
import useQuery from 'hooks/useQuery';

const GET_USER_AND_USER_WORKSPACES = gql`
  query GET_USER_AND_USER_WORKSPACES {
    user {
      name
      loggedInWithSSOProviderId
      workspaces {
        urlName
        ssoProviders {
          id
          isEnforced
        }
      }
    }
  }
`;

function useGetUserAndUserWorkspacesQuery (props) {
  const {
    loading, error, data,
  } = useQuery(GET_USER_AND_USER_WORKSPACES, {
    fetchPolicy: 'no-cache',
  });

  return {
    data,
    loading,
    error,
  };
}

export default useGetUserAndUserWorkspacesQuery;
