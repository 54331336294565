import {
  Editor, Transforms, Range, Node,
} from 'slate';

// Based on the simple onCut implementation in Slate:
// https://github.com/ianstormtaylor/slate/blob/67badb7dd03c6d36d31b90689247553c0386d771/packages/slate-react/src/components/editable.tsx#L841
// Adds support for cutting and pasting with selected elements.
// Also see makeSetFragmentData for how we handle that.
const makeOnCut = (editor, {selectElements, selectedElements}) => (event) => {
  event.preventDefault();

  editor.setFragmentData(event.clipboardData);

  selectElements([]);
  removeSelectedElements(editor, selectedElements.ids);

  if (
    selectedElements.ids.length === 0 ||
    selectedElements.inRange
  ) {
    deleteSelection(editor);
  }
};

function deleteSelection (editor) {
  const {selection} = editor;

  if (selection) {
    if (Range.isExpanded(selection)) {
      Editor.deleteFragment(editor);
    }
    else {
      const node = Node.parent(editor, selection.anchor.path);
      if (Editor.isVoid(editor, node)) {
        Transforms.delete(editor);
      }
    }
  }
}

function removeSelectedElements (editor, ids) {
  Transforms.removeNodes(editor, {
    at: [],
    match: (n) => ids.includes(n.id),
  });
}

export default makeOnCut;
