import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled, css} from 'twin.macro'; // eslint-disable-line no-unused-vars
import {INLINE_ELEMENT_TYPES} from './constants';

ElementWrapper.propTypes = {
  attributes: PropTypes.shape({}).isRequired,
  element: PropTypes.shape({
    type: PropTypes.string.isRequired,
    format: PropTypes.string,
    indent: PropTypes.number,
    id: PropTypes.string,
    checked: PropTypes.bool,
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

// Ensures that {...attributes} are applied to every element. Without this,
// ReactEditor.toDOMNode will throw an error.
function ElementWrapper (props) {
  const {attributes, children, element} = props;
  const {type} = element;

  const isInline = INLINE_ELEMENT_TYPES.includes(type);
  const isChildWidthFull = type === 'choice-group' && element?.format === 'rating';

  return (
    <Wrapper
      childWidthFull={isChildWidthFull}
      className={`element--${type}`}
      inline={isInline}
      {...attributes}
    >
      {children}
    </Wrapper>
  );
}

const Wrapper = styled.div((props) => [
  props.childWidthFull && css`
    & .element--choice {
      width: 100%;
    }
  `,
  props.inline && css`
    display: inline;
  `,
]);

export default ElementWrapper;
