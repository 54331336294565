import React from 'react';
import SelectableList from 'components/SelectableList';
import P from 'components/P';
import {AiFillFolder} from 'react-icons/ai';
import PropTypes from 'prop-types';

import tw, {styled} from 'twin.macro';

ItemMoverModalContent.propTypes = {
  onChange: PropTypes.func.isRequired,
  itemName: PropTypes.string.isRequired,
  folderTree: PropTypes.arrayOf(PropTypes.shape({})),
  error: PropTypes.bool,
};

function ItemMoverModalContent (props) {
  const {
    error,
    folderTree,
    itemName,
    onChange,
  } = props;

  if (error) {
    return <P>Could not load folder list</P>;
  }

  return (<>
    <P>Choose a new home for <strong>{itemName || 'Untitled'}</strong>:</P>
    <FolderListWrapper data-test-id="move-modal-list">
      <SelectableList
        compact
        icon={AiFillFolder}
        items={folderTree}
        onChange={onChange} />
    </FolderListWrapper>
  </>
  );
}

const FolderListWrapper = styled.div((props) => [
  tw`
    mt--3
  `,
]);

export default ItemMoverModalContent;
