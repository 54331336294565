import showdown from 'showdown';
import {insertHtmlString} from './insertHtml';

function insertMarkdown (editor, data, selectionMeta) {
  const text = data.getData('text');

  if (
    !text ||
    data.types.includes('vscode-editor-data')
  ) {
    return false;
  }

  const converter = new showdown.Converter();
  const htmlFromMarkdown = converter.makeHtml(text);
  insertHtmlString(editor, htmlFromMarkdown, selectionMeta);

  return true;
}

export default insertMarkdown;
