import React, {useRef} from 'react';
import {Redirect} from '@reach/router';
import NewUserStartPage from './components/NewUserStartPage';
import useSetAdditionalDetailsMutation from './hooks/useSetAdditionalDetailsMutation';
import useGetUserAndUserWorkspacesQuery from '../WorkspaceRedirector/hooks/useGetUserAndUserWorkspacesQuery';

function NewUserStart () {
  const [setAdditionalDetails, {
    data,
    error,
    loading,
  }] = useSetAdditionalDetailsMutation();
  const {
    data: userData,
    error: userError,
    loading: userLoading,
  } = useGetUserAndUserWorkspacesQuery();
  const organizationName = useRef(null);

  const onSubmit = async (details) => {
    organizationName.current = details.organizationName;
    setAdditionalDetails({variables: details});
  };

  if (userLoading) {
    return null;
  }

  if ((data && !loading) ||
      (userData && !userError && !userLoading && userData.user.workspaces.length > 0)) {
    const parameters = data && organizationName.current ? `?organizationName=${organizationName.current}` : '';
    return <Redirect to={`/workspaces${parameters}`} noThrow={true}/>;
  }

  return <NewUserStartPage
    setAdditionalDetails={onSubmit}
    error={error || userError}
    loading={loading || userLoading}
  />;
}

export default NewUserStart;
