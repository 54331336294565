import P from 'components/P';
import React from 'react';
import UL from 'components/UL';
import LI from 'components/LI';
import Em from 'components/Em';
import Comment from './Comment';

WarningTitleVerb.propTypes = {
};

function WarningTitleVerb (props) {
  return (
    <Comment isWarning shortText="Start with a verb" {...props}>
      <P>
        We recommend that a checklist title starts with a verb.
      </P>
      <P>For example:</P>
      <UL>
        <LI>
          <Em>Run</Em> a discovery call
        </LI>
        <LI>
          <Em>Prepare</Em> for a new employee
        </LI>
        <LI>
          <Em>Send</Em> a quote
        </LI>
      </UL>
      <P>
        This helps to make it clear what the checklist is for, and who should
        use it.
      </P>
    </Comment>
  );
}

export default React.memo(WarningTitleVerb);
