import PropTypes from 'prop-types';
import {useQuery} from '@tanstack/react-query';
import {gql} from 'graphql-request';
import {ITEM_FIELDS} from 'fragments/Item';
import {BREADCRUMB_FIELDS} from 'fragments/Breadcrumb';
import graphQLClient from 'utils/graphQLClient';

const query = gql`
  query GetItem($ref: String!, $key: String!) {
    item(ref: $ref, key: $key) {
      ${ITEM_FIELDS}
      breadcrumbs {
        ${BREADCRUMB_FIELDS}
      }
      children {
        ${ITEM_FIELDS}
      }
    }
  }
`;

useItemQuery.propTypes = {
  ref: PropTypes.string,
  key: PropTypes.string,
  enabled: PropTypes.bool,
};

function useItemQuery ({ref, key, enabled}) {
  const cacheKey = ['items', {ref, key}];

  const variables = {
    ref,
    key,
  };

  const queryFn = async () => {
    const {item} = await graphQLClient.request(query, variables);
    return item;
  };

  const options = {enabled};

  return useQuery(cacheKey, queryFn, options);
}

export default useItemQuery;
