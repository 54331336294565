import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';
import React from 'react';
import {AiOutlineRight} from 'react-icons/ai';

function BreadcrumbSeparator (props) {
  const {featureFlags} = useWorkspace();

  if (featureFlags['2022_09_dashboard']) {
    return <AiOutlineRight size={8} />;
  }

  return <>
    &nbsp;<AiOutlineRight size={8} />&nbsp;
  </>;
}

export default BreadcrumbSeparator;
