import PropTypes from 'prop-types';
import {useQuery} from '@tanstack/react-query';
import {gql} from 'graphql-request';
import graphQLClient from 'utils/graphQLClient';

const query = gql`
  query GetTemplateContentForItemRefAndKey($itemRef: String!, $itemKey: String!) {
    templateContent(itemRef: $itemRef, itemKey: $itemKey)
  }
`;

useTemplateContentQuery.propTypes = {
  itemRef: PropTypes.string,
  itemKey: PropTypes.string,
};

function useTemplateContentQuery (itemRef, itemKey) {
  const queryKey = ['templateContent', itemRef, itemKey];

  const queryFn = async () => {
    const variables = {
      itemRef, itemKey,
    };

    const data = await graphQLClient.request(query, variables);

    return data?.templateContent;
  };

  const options = {enabled: false};

  return useQuery({queryKey, queryFn, ...options});
}

export default useTemplateContentQuery;
