import {ITEM_TYPE} from 'am-constants';

// Can drop:
// - Before a section header (itemTypeId === ITEM_TYPE.SECTION)
// - At the end of an open folder (adder === true)
function getValidDropIndexesForSections (preparedItems) {
  return preparedItems
    .map((preparedItem, index) =>
      (preparedItem.itemTypeId === ITEM_TYPE.SECTION || preparedItem.adder
        ? index
        : undefined))
    .filter((index) => index !== undefined);
}

export default getValidDropIndexesForSections;
