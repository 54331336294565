// pipe lets you run a series of functions, each one taking the output from the
// previous function.
//
// Doing so adopts a functional style and can make code easier to read.
//
// e.g.
//   pipe(
//      extractName,
//      getFirstName,
//      upperCase,
//   )({name: 'Paddy Mann', location: 'Sweden'})
//
//   -> PADDY
const pipe = (...fns) => (x) => fns.reduce((v, f) => f(v), x);

export default pipe;
