import React from 'react';
import PropTypes from 'prop-types';
import PageToolbar from 'components/PageToolbar';
import DocumentToolbar from 'containers/DocumentToolbar';

DocumentFormPageToolbar.propTypes = {
  hasUpdateErrors: PropTypes.bool.isRequired,
  submitError: PropTypes.shape({}),
  submitted: PropTypes.bool.isRequired,
};

function DocumentFormPageToolbar (props) {
  const {
    hasUpdateErrors,
    submitError,
    submitted,
  } = props;

  if (submitError) {
    return (
      <PageToolbar error>
        We could not submit your response. Please retry.
      </PageToolbar>
    );
  }

  if (!submitted && hasUpdateErrors) {
    return (
      <PageToolbar error>
        We could not save your changes. Retrying...
      </PageToolbar>
    );
  }

  return (
    <DocumentToolbar showDeleteResponseMenu showViewResponsesMenu/>
  );
}

export default DocumentFormPageToolbar;
