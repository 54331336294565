import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled, css} from 'twin.macro';
import {useDocument} from 'containers/Document/providers/DocumentProvider';

Placeholder.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  color: PropTypes.string,
  right: PropTypes.bool,
  text: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
};

function Placeholder (props) {
  const {
    children,
    color,
    right,
    text,
  } = props;

  const documentState = useDocument();
  const {mode} = documentState;

  const isEmpty = children[0].props.text.text.length === 0;

  return <>
    {children}
    {mode === 'edit' && isEmpty && <>
      <PlaceholderWidth contentEditable={false}>{text}</PlaceholderWidth>
      <PlaceholderText contentEditable={false} color={color} right={right}>{text}</PlaceholderText>
    </>}
  </>;
}

const PlaceholderText = styled.div((props) => [
  tw`
    text-gray-200
    absolute
    top-0
    select-none
    pointer-events-none
    z-0
  `,
  props.right && tw`
    right-0
  `,
  props.color === 'yellow' && tw`
    text-yellow-400
  `,
  props.color === 'red' && tw`
    text-red-200
  `,
  props.color === 'green' && tw`
    text-green-300
  `,
  props.color === 'blue' && tw`
    text-blue-200
  `,
]);

// This placeholder makes sure there's enough space for the PlaceholderText when
// placed an inline/flex element.
const PlaceholderWidth = styled.div((props) => [
  tw`
    opacity-0
    h-0
    overflow-hidden
  `,
  css`
    user-select: none;
  `,
]);

export default Placeholder;
