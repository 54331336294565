import {Transforms} from 'slate';

function closeProcessSteps (editor, processPath) {
  Transforms.setNodes(
    editor,
    {
      open: false,
    },
    {
      at: processPath,
      match: (n) => n.type === 'process-step',
    }
  );
}

export default closeProcessSteps;
