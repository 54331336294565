// As there are only a small number of changes between different paths, this is
// an acceptable solution. If we find the paths getting more complicated, consider refactoring
// (e.g into a directed graph state machine).
const CHECKLIST_TYPE = {
  TASK: 'task',
  TRAINING: 'training',
  ONBOARDING: 'onboarding',
  FORM: 'form',
};

const PAGE_FOR_STEP = [
  'CHOOSE_PAGE_TYPE',
  'ADD_TITLE',
  'CHOOSE_STARTING_POINT',
  'USE_AI_OR_SHOW_TEMPLATES',
];

const DEFAULT_TITLE_FOR_STEP = [
  'What kind of page do you want to create?',
  'What task is this checklist for?',
  'Choose your starting point',
  'Use AI to get started',
];

const FINAL_PAGE_TYPE = {
  AI_GENERATION: 1,
  TEMPLATES: 2,
};

const getTitleForStep = (step, finalPageType) => {
  if (step === 3) {
    return finalPageType === FINAL_PAGE_TYPE.AI_GENERATION
      ? 'Use AI to get started'
      : 'Use a template';
  }

  return DEFAULT_TITLE_FOR_STEP[step];
};

module.exports = {
  CHECKLIST_TYPE,
  PAGE_FOR_STEP,
  FINAL_PAGE_TYPE,
  getTitleForStep,
};
