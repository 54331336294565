import React from 'react';
import PropTypes from 'prop-types';

ChoiceGroupHorizontalIcon.propTypes = {
  size: PropTypes.number,
};

ChoiceGroupHorizontalIcon.defaultProps = {
  size: 16,
};

function ChoiceGroupHorizontalIcon (props) {
  return (
    <svg
      enableBackground="new 0 0 2447.6 2452.5"
      viewBox="1 1 11 11"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      fill="currentColor"
      strokeWidth={0}
      width={props.size}
      height={props.size}
    >
      <g transform="matrix(1,0,0,1,0,1.03125)">
        <path d="M10.297,1.875L1.703,1.875C1.487,1.875 1.313,2.043 1.313,2.25L1.313,7.688C1.313,7.895 1.487,8.063 1.703,8.063L10.296,8.063C10.512,8.063 10.686,7.895 10.686,7.688L10.686,2.25C10.687,2.043 10.513,1.875 10.297,1.875ZM4.219,7.219L2.156,7.219L2.156,5.344L4.219,5.344L4.219,7.219ZM4.219,4.594L2.156,4.594L2.156,2.719L4.219,2.719L4.219,4.594ZM7.031,7.219L4.969,7.219L4.969,5.344L7.031,5.344L7.031,7.219ZM7.031,4.594L4.969,4.594L4.969,2.719L7.031,2.719L7.031,4.594ZM9.844,7.219L7.781,7.219L7.781,5.344L9.844,5.344L9.844,7.219ZM9.844,4.594L7.781,4.594L7.781,2.719L9.844,2.719L9.844,4.594Z" />
      </g>
    </svg>
  );
}

export default ChoiceGroupHorizontalIcon;
