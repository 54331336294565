import PropTypes from 'prop-types';
import {gql} from '@apollo/client';
import useMutation from 'hooks/useMutation';
import {useQueryClient} from '@tanstack/react-query';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';

const FOLDER_RENAME = gql`
  mutation FOLDER_RENAME($name: String!, $itemRef: String!, $itemKey: String!) {
    folderRename(name: $name, itemRef: $itemRef, itemKey: $itemKey) {
      id
    }
  }
`;

useRenameFolderMutation.propTypes = {
  newName: PropTypes.string,
  itemRef: PropTypes.string,
  itemKey: PropTypes.string,
};

function useRenameFolderMutation (props) {
  const {
    newName,
    itemRef,
    itemKey,
  } = props;

  const queryClient = useQueryClient();
  const {urlName} = useWorkspace();

  const [onRenameItem, {loading, data, error}] = useMutation(FOLDER_RENAME, {
    variables: {
      name: newName,
      itemRef,
      itemKey,
    },
    refetchQueries: [
      'GetFolders',
      'GetItems',
      'GetItem',
      'GetSearchResults',
      'GetWorkspaceItem',
    ],
    // As we have partially migrated to react query, we need to invalidate the react
    // query cache, similar to how refetchQueries behaves in Apollo.
    onCompleted: () => {
      queryClient.invalidateQueries([urlName, 'items']);
    },
  });

  return [onRenameItem, {
    loading,
    error,
    data,
  }];
}

export default useRenameFolderMutation;
