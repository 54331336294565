import {Editor, Element} from 'slate';
import makeParagraph from '../factory/makeParagraph';
import convertSelectedNodes from '../utils/convertSelectedNodes';

function onBackspaceAtStartOfCodeBlock (editor, event, selectionMeta) {
  const {
    isSelectionAtStartOfElement,
    selectionStart,
    selectionStartElement,
  } = selectionMeta;

  const [previousElement] = Editor.previous(editor, {match: (n) => Element.isElement(n)}) || [undefined];

  if (
    event.key !== 'Backspace' ||
    !selectionStart ||
    selectionStartElement.type !== 'code-block' ||
    !isSelectionAtStartOfElement ||
    (previousElement && previousElement.type === 'code-block')
  ) {
    return false;
  }

  event.preventDefault();
  convertSelectedNodes(editor, {makeNode: makeParagraph});

  return true;
}

export default onBackspaceAtStartOfCodeBlock;
