import PropTypes from 'prop-types';
import {gql} from '@apollo/client';
import useMutation from 'hooks/useMutation';

const RESPONSE_REACTIVATE = gql`
  mutation RESPONSE_REACTIVATE($responseRef: String!, $responseKey: String!) {
    responseReactivate(responseRef: $responseRef, responseKey: $responseKey) {
      id
    }
  }
`;

useResponseReactivateMutation.propTypes = {
  responseRef: PropTypes.string.isRequired,
  responseKey: PropTypes.string.isRequired,
  onReactivated: PropTypes.func.isRequired,
};

function useResponseReactivateMutation (props) {
  const {
    responseRef,
    responseKey,
    onReactivated,
  } = props;

  const [onReactivate, {loading, error, data}] = useMutation(RESPONSE_REACTIVATE, {
    variables: {
      responseRef,
      responseKey,
    },
    onCompleted: onReactivated,
  });

  if (error) {
    // TODO: Handle error
  }

  return [onReactivate, {
    reactivating: loading,
    reactivated: !!data,
  }];
}

export default useResponseReactivateMutation;
