import {Transforms} from 'slate';

function onArrowSymbol (editor, event, selectionMeta) {
  const {
    selectionStart,
    selectionStartElement,
    isSelectionCollapsed,
  } = selectionMeta;

  if (
    !selectionStart ||
    !isSelectionCollapsed ||
    selectionStartElement.type === 'code-block'
  ) {
    return false;
  }

  if (formatRightArrow(editor, event, selectionMeta)) {
    return true;
  }

  if (formatLeftArrow(editor, event, selectionMeta)) {
    return true;
  }

  return false;
}

function formatRightArrow (editor, event, selectionMeta) {
  const {
    selectionStart,
    selectionStartTextNode,
  } = selectionMeta;

  if (
    event.key !== '>' ||
    selectionStart.offset === 0
  ) {
    return false;
  }

  const prevChar = selectionStartTextNode.text.slice(selectionStart.offset - 1, selectionStart.offset);
  if (prevChar !== '-') {
    return false;
  }

  requestAnimationFrame(() => {
    Transforms.delete(editor, {reverse: true, distance: 2});
    Transforms.insertText(editor, '→');
  });

  return true;
}

function formatLeftArrow (editor, event, selectionMeta) {
  const {
    selectionStart,
    selectionStartTextNode,
  } = selectionMeta;

  if (
    event.key !== '-' ||
    selectionStart.offset === 0
  ) {
    return false;
  }

  const prevChar = selectionStartTextNode.text.slice(selectionStart.offset - 1, selectionStart.offset);
  if (prevChar !== '<') {
    return false;
  }

  requestAnimationFrame(() => {
    Transforms.delete(editor, {reverse: true, distance: 2});
    Transforms.insertText(editor, '←');
  });

  return true;
}

export default onArrowSymbol;
