import React, {} from 'react';
import PropTypes from 'prop-types';
import {Transforms} from 'slate';
import {useDocument} from 'containers/Document/providers/DocumentProvider';

const SlateEditorContext = React.createContext();

SlateEditorProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  editor: PropTypes.shape().isRequired,
};

SlateEditorProvider.defaultProps = {

};

function SlateEditorProvider (props) {
  const {
    children,
    editor,
  } = props;

  const documentState = useDocument();
  const {
    mode,
    checkCheckbox,
    uncheckCheckbox,
  } = documentState;

  const onToggleCheckbox = (id, checked) => {
    if (mode === 'edit') {
      Transforms.setNodes(
        editor,
        {
          checked: !checked,
        },
        {
          at: [],
          match: (n) => n.id === id,
        }
      );
    }
    else if (mode === 'use') {
      if (checked) {
        uncheckCheckbox(id);
      }
      else {
        checkCheckbox(id);
      }
    }
  };

  const context = {
    // onSelectProcess,
    // onSelectProcessStep,
    onToggleCheckbox,
    // onToggleProcessStep,
    // onToggleProcess,
    // onUploadImage,
  };

  return <>
    <SlateEditorContext.Provider value={context}>
      {children}
    </SlateEditorContext.Provider>
  </>;
}

export function useSlateEditor () {
  const context = React.useContext(SlateEditorContext);

  if (context === undefined) {
    throw new Error('useSlateEditor must be used within a SlateEditorProvider');
  }

  return context;
}

export default SlateEditorProvider;
