import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled} from 'twin.macro';
import {ITEM_TYPE} from 'am-constants';
import {AiFillFolder} from 'react-icons/ai';
import Pluralize from 'react-pluralize';
import Pill from 'components/Pill';
import checkIfItemIsUnpublished from 'utils/checkIfItemIsUnpublished';
import getNameForItem from 'utils/getNameForItem';
import getDataTestIdForItem from 'utils/getDataTestIdForItem';

Item.propTypes = {
  item: PropTypes.shape({
    itemTypeId: PropTypes.number.isRequired,
    emoji: PropTypes.string,
    name: PropTypes.string.isRequired,
    urlName: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(PropTypes.shape({})),
    contentActionNeededId: PropTypes.number,
    numPages: PropTypes.number,
  }).isRequired,
};

Item.defaultProps = {
};

function Item (props) {
  const {
    item,
  } = props;

  const unpublished = checkIfItemIsUnpublished(item);

  return (
    <Wrapper data-test-id={getDataTestIdForItem(item)}>
      <Icon>{item.emoji}</Icon>
      <ItemName>{getNameForItem(item)}</ItemName>
      {/* <ItemStatus new>New!</ItemStatus> */}
      {item.itemTypeId === ITEM_TYPE.FOLDER && (
        <FolderInfo>
          <Pluralize singular={'page'} count={item.numPages || 0} />
          <AiFillFolder tw="text-xl text-gray-300 ml-2" />
        </FolderInfo>
      )}
      {unpublished && (<Pill>Unpublished</Pill>)}
    </Wrapper>
  );
}

const Wrapper = styled.div((props) => [
  tw`
    h-11
    px-4
    flex
    items-center
    gap-2
    text-sm
    text-gray-700
  `,
]);

const Icon = styled.div((props) => [
  tw`
    w-6
    text-lg
    my--1
    flex
    justify-center
    items-center
  `,
]);

const ItemName = styled.div((props) => [
  tw`
    flex-1
  `,
]);

// const ItemStatus = styled.div((props) => [
//   tw`
//     py-1.5
//     px-2.5
//     my--0.5
//     bg-gray-100
//     text-gray-600
//     rounded-full
//     text-2xs
//   `,
//   props.new && tw`
//     bg-yellow-800
//     text-white
//   `,
// ]);

const FolderInfo = styled.div((props) => [
  tw`
    text-gray-500
    h-10
    flex
    items-center
    p-3
    text-xs
    m--3
  `,
]);

export default Item;
