import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled} from 'twin.macro';

SavingCellContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  saving: PropTypes.bool,
};

SavingCellContent.defaultProps = {
  saving: false,
};

function SavingCellContent (props) {
  const {
    children,
    saving,
  } = props;

  return (
    <Wrapper saving={saving}>
      {children}
    </Wrapper>
  );
}

const Wrapper = styled.div((props) => [
  props.saving && tw`
    opacity-80
  `,
]);

export default SavingCellContent;
