import P from 'components/P';
import React from 'react';
import A from 'components/A';
import Comment from './Comment';

WarningIntroNoHelpBlocks.propTypes = {
};

function WarningIntroNoHelpBlocks (props) {
  return (
    <Comment isWarning shortText="Consider help blocks" {...props}>
      <P>The introduction to this checklist is pretty long.</P>
      <P>
        You could use a help block to hide away content that doesn't need to be reviewed
        every time someone uses the checklist.
      </P>
      <P>
        <A href="">How to add a help block</A>
      </P>
    </Comment>
  );
}

export default React.memo(WarningIntroNoHelpBlocks);
