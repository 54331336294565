import tw, {styled, css} from 'twin.macro';

const Button = styled.button((props) => [
  // Default
  tw`
    relative
    text-sm
    rounded-sm
    select-none
    border
    border-blue-200
    text-blue-400
    flex
    h-10
    px-3
    gap-1.5
    items-center
    justify-center
    hover:bg-blue-50
    focus-visible:outline-none
    focus-visible:border-blue-100
    focus-visible:border-4
  `,
  !props.link &&
    tw`
    bg-white
  `,
  props.inline &&
    tw`
    h-8
    inline-block
  `,
  props.disabled &&
    tw`
    border-blue-100
    text-blue-200
    hover:bg-white
    hover:text-blue-200
    cursor-not-allowed
  `,
  props.darkBg &&
    tw`
    text-blue-200
    border-blue-300
    hover:bg-gray-700
  `,
  props.darkBg &&
    props.disabled &&
    tw`
    border-blue-600
    text-blue-500
    hover:bg-gray-800
    hover:text-blue-500
  `,
  // Color: Primary
  props.primary &&
    tw`
    bg-blue-600
    text-blue-50
    border-0
    hover:bg-blue-500
  `,
  props.primary &&
    props.disabled &&
    tw`
    bg-blue-300
    text-blue-100
    hover:bg-blue-300
    hover:text-blue-100
  `,
  props.primary &&
    props.darkBg &&
    tw`
    bg-blue-500
    hover:bg-blue-400
  `,
  props.primary &&
    props.darkBg &&
    props.disabled &&
    tw`
    bg-blue-300
    text-blue-50
    hover:bg-blue-300
    hover:text-blue-50
  `,
  // Color: Green
  props.green &&
    tw`
    bg-green-600
    text-white
    border-0
    hover:bg-green-500
    focus-visible:border-green-100
  `,
  props.green &&
    props.disabled &&
    tw`
    bg-green-300
    text-green-100
    hover:bg-green-300
    hover:text-green-100
  `,
  props.green &&
    props.darkBg &&
    tw`
    bg-green-500
    hover:bg-green-400
  `,
  props.green &&
    props.darkBg &&
    props.disabled &&
    tw`
    bg-green-300
    text-green-50
    hover:bg-green-300
    hover:text-green-50
  `,
  // Color: Red secondary
  props.redSecondary &&
    tw`
    border-red-300
    text-red-500
    hover:bg-red-50
    focus-visible:border-red-200
  `,
  props.redSecondary &&
    props.disabled &&
    tw`
    border-red-200
    text-red-300
    hover:text-red-300
  `,
  // Color: Red
  props.danger &&
    tw`
    bg-red-600
    text-white
    border-0
    hover:bg-red-500
    focus-visible:border-red-100
  `,
  props.danger &&
    props.disabled &&
    tw`
    bg-red-300
    text-red-100
    hover:bg-red-300
    hover:text-red-100
  `,
  props.danger &&
    props.darkBg &&
    tw`
    bg-red-500
    hover:bg-red-400
  `,
  props.danger &&
    props.darkBg &&
    props.disabled &&
    tw`
    bg-red-300
    text-red-50
    hover:bg-red-300
    hover:text-red-50
  `,
  // Color: Gray
  props.gray &&
    tw`
    bg-gray-100
    text-gray-600
    border-0
    hover:bg-gray-200
    focus-visible:border-gray-200
  `,
  props.gray &&
    props.disabled &&
    tw`
    bg-gray-300
    text-gray-100
    hover:bg-gray-300
    hover:text-gray-100
  `,
  // TODO: Gray on dark bg
  // props.gray && props.darkBg && tw`
  //   bg-gray-500
  //   hover:bg-gray-400
  // `,
  // props.gray && props.darkBg && props.disabled && tw`
  //   bg-gray-300
  //   text-gray-50
  //   hover:bg-gray-300
  //   hover:text-gray-50
  // `,
  // Color: Purple
  // TODO: Non darkBg colors
  props.purple &&
    tw`
    bg-purple-600
    text-white
    border-0
    hover:bg-purple-500
    focus-visible:border-purple-100
  `,
  // TODO: Disabled colors
  // props.purple && props.disabled && tw`
  //   bg-purple-300
  //   text-purple-100
  //   hover:bg-purple-300
  //   hover:text-purple-100
  // `,
  props.purple &&
    props.darkBg &&
    tw`
    bg-purple-500
    text-purple-50
    hover:bg-purple-400
  `,
  // TODO: Disabled colors
  // props.purple && props.darkBg && props.disabled && tw`
  //   bg-purple-300
  //   text-purple-800
  //   hover:bg-purple-300
  //   hover:text-purple-800
  // `,
  // Link button
  props.link &&
    tw`
    text-blue-500
    border-transparent
    hover:text-blue-400
    hover:bg-transparent
  `,
  props.link &&
    props.disabled &&
    tw`
    text-gray-300
    hover:text-gray-300
  `,
  props.link &&
    props.darkBg &&
    tw`
    text-blue-200
  `,
  props.link &&
    props.darkBg &&
    props.disabled &&
    tw`
    text-blue-500
    hover:text-blue-500
  `,
  // No padding can be useful for link buttons
  props.noPadding &&
    tw`
    h-auto!
    px-0!
    border-0!
    focus-visible:px-0!
    focus-visible:border-0!
  `,
  // Size: Inline
  props.inline &&
    tw`
    text-xs
    px-2
    py-0.5
    focus-visible:border-2
    focus-visible:m--0.5
  `,
  // Size: Small
  props.sm &&
    tw`
    text-xs
    focus-visible:border-2
  `,
  props.sm &&
    !props.link &&
    css`
      height: 30px;
    `,
  // Size: Large
  props.lg &&
    tw`
    text-base
    h-11
  `,
  // Size: Large
  props.xl &&
    tw`
    text-lg
    h-12
    py-3
  `,
  // Icon
  props.icon &&
    tw`
    bg-transparent
    px-0
    w-10
    text-center
    svg:inline-block
    svg:text-lg
  `,
  // Full width
  props.fullwidth &&
    tw`
    w-full
  `,
  props.fullwidth &&
    css`
      &:focus-visible {
        width: calc(100% + 8px);
      }
    `,

  // PADDING-X STYLES
  // Reduce padding by 1px for default styles (which includes a 1px border).
  // This makes it the same size as other buttons, and stops focus styles from
  // affecting the button size.
  tw`
    focus-visible:px-2
  `,
  css`
    padding-left: 11px;
    padding-right: 11px;
  `,
  (props.primary ||
    props.green ||
    props.purple ||
    props.danger ||
    props.redSecondary) &&
    tw`
    px-3
  `,
  props.sm &&
    tw`
    focus-visible:px-2
  `,
  props.sm &&
    css`
      padding-left: 9px;
      padding-right: 9px;
    `,
  props.sm &&
    (props.primary ||
      props.green ||
      props.purple ||
      props.danger ||
      props.redSecondary) &&
    tw`
    px-2.5
  `,
  props.lg &&
    tw`
    focus-visible:px-2.5
  `,
  props.lg &&
    css`
      padding-left: 13px;
      padding-right: 13px;
    `,
  props.lg &&
    (props.primary ||
      props.green ||
      props.purple ||
      props.danger ||
      props.redSecondary) &&
    tw`
    px-3.5
  `,
  props.xl &&
    tw`
    focus-visible:px-3
  `,
  props.xl &&
    css`
      padding-left: 15px;
      padding-right: 15px;
    `,
  props.xl &&
    (props.primary ||
      props.green ||
      props.purple ||
      props.danger ||
      props.redSecondary) &&
    tw`
    px-4
  `,
  props.smText &&
    tw`
    text-xs
  `,
  props.xsText &&
    tw`
    text-2xs
  `,
]);

export const ButtonBar = styled.div((props) => [
  tw`
    flex
    gap-2
    items-center
  `,
  props.vertical && tw`
    flex-col
  `,
  props.right && tw`
    justify-end
  `,
]);

export const ModalButtonBar = styled.div((props) => [
  tw`
    w-full
    flex
  `,
]);

export const ButtonSpacer = styled.div((props) => [
  tw`
    inline-block
    w-2
  `,
  props.vertical && tw`
    block
    h-2
  `,
]);

export const FullWidthButtonSecondaryAction = styled.div((props) => [
  tw`
    text-center
    text-xs
    mt-5
  `,
]);

Button.displayName = 'Button';
ButtonBar.displayName = 'ButtonBar';
ButtonSpacer.displayName = 'ButtonSpacer';
FullWidthButtonSecondaryAction.displayName = 'FullWidthButtonSecondaryAction';

export default Button;
