import {Editor, Transforms} from 'slate';

function onArrowDownInHorizontalOrRatingChoice (editor, event, selectionMeta) {
  const {
    selectionStart,
    selectionStartElement,
    selectionStartElementPath,
  } = selectionMeta;

  if (
    event.key !== 'ArrowDown' ||
    !selectionStart ||
    selectionStartElement.type !== 'choice'
  ) {
    return false;
  }

  const [parentElement] = Editor.parent(editor, selectionStartElementPath);
  if (!['horizontal', 'rating'].includes(parentElement.format)) {
    return false;
  }

  event.preventDefault();

  const nextPoint = Editor.after(editor, selectionStartElementPath.slice(0, -1));
  const endOfNextElement = Editor.end(editor, nextPoint.path);

  Transforms.select(
    editor,
    {
      path: nextPoint.path,
      offset: Math.min(endOfNextElement.offset, selectionStart.offset),
    }
  );

  return true;
}

export default onArrowDownInHorizontalOrRatingChoice;
