import React from 'react';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';
import TeamMembersPage from 'components/TeamMembersPage';
import {Helmet} from 'react-helmet';
import Loader from 'components/Loader';
import useTeamMembersWithRolesQuery from './hooks/useTeamMembersWithRolesQuery';

function TeamMemberViewerIndex () {
  const {
    urlName: workspaceUrlName,
  } = useWorkspace();

  const {loading, error, data} = useTeamMembersWithRolesQuery({workspaceUrlName});

  if (loading) {
    return <Loader />;
  }

  let errorMessage;
  if (error) {
    errorMessage = 'Could not load team members.';
  }

  const formattedTeamMembersData = data && data.map((teamMember) => ({
    roles: teamMember.roles,
    ...teamMember.user,
  }));

  return <>
    <Helmet>
      <title>Team</title>
    </Helmet>
    <TeamMembersPage teamMembers={formattedTeamMembersData} errorMessage={errorMessage} />
  </>;
}

export default TeamMemberViewerIndex;
