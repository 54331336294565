import {VOID_ELEMENT_TYPES} from '../constants';
import insertParagraph from '../utils/insertParagraph';

function onEnterOnVoidElement (editor, event, selectionMeta) {
  const {
    isSelectionInSingleElement,
    selectionStart,
    selectionStartElement,
  } = selectionMeta;

  if (
    event.key !== 'Enter' ||
    !selectionStart ||
    !isSelectionInSingleElement ||
    !VOID_ELEMENT_TYPES.includes(selectionStartElement.type)
  ) {
    return false;
  }

  event.preventDefault();

  insertParagraph(editor);

  return true;
}

export default onEnterOnVoidElement;
