import {Range, Transforms} from 'slate';
import makeLink from '../factory/makeLink';
import isLinkActive from './isLinkActive';
import unwrapLink from './unwrapLink';

const wrapLink = (editor, url) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const {selection} = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link = makeLink({url});

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  }
  else {
    Transforms.wrapNodes(editor, link, {split: true});
    Transforms.collapse(editor, {edge: 'end'});
  }
};

export default wrapLink;
