import imageExtensions from 'image-extensions';
import isUrl from 'is-url';
import makeParagraph from '../factory/makeParagraph';
import makeImage from '../factory/makeImage';

function insertImageUrl (editor, data, selectionMeta) {
  const url = data.getData('text/plain');

  if (
    !url ||
    !isImageUrl(url)
  ) {
    return false;
  }

  editor.insertFragment([
    makeImage({url}),
    makeParagraph({text: url}),
  ]);

  return true;
}

function isImageUrl (url) {
  if (!url) {
    return false;
  }

  if (!isUrl(url)) {
    return false;
  }

  const ext = new URL(url).pathname.split('.').pop();

  return imageExtensions.includes(ext);
}

export default insertImageUrl;
