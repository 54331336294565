import React, {useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import {Redirect} from '@reach/router';
import getDocumentTitleRefAndKeyParts from 'utils/getDocumentTitleRefAndKeyParts';
import useCreateResponseMutation from './hooks/useCreateResponseMutation';

ResponseCreator.propTypes = {
  'documentTitle--:itemRef-:itemKey': PropTypes.string,
  workspaceUrlName: PropTypes.string,
};

function ResponseCreator (props) {
  const {
    'documentTitle--:itemRef-:itemKey': documentTitleRefAndKey,
    workspaceUrlName,
  } = props;

  const {
    documentTitle: formattedDocumentTitle,
    ref: itemRef,
    key: itemKey,
  } = useMemo(() => getDocumentTitleRefAndKeyParts(documentTitleRefAndKey), [documentTitleRefAndKey]);

  const [createResponse, {loading, error, data}] = useCreateResponseMutation({
    itemRef,
    itemKey,
  });

  useEffect(() => {
    createResponse();
  }, []);

  if (loading) {
    return null;
  }

  if (error) {
    return <div>Error creating response</div>;
  }

  if (data) {
    const {ref, key} = data.responseCreate;

    return <Redirect to={`/${workspaceUrlName}/use/${formattedDocumentTitle}--${ref}-${key}`} noThrow={true} />;
  }

  return '';
}

export default ResponseCreator;
