import P from 'components/P';
import React from 'react';
import UL from 'components/UL';
import LI from 'components/LI';
import Em from 'components/Em';
import A from 'components/A';
import Comment from './Comment';

WarningStepTitleVerb.propTypes = {
};

function WarningStepTitleVerb (props) {
  return (
    <Comment isWarning shortText="Start with a verb" {...props}>
      <P>
        Each step should describe an action someone should take, starting with a
        verb.
      </P>
      <P>For example:</P>
      <UL>
        <LI>
          <Em>Prepare</Em> the invoice
        </LI>
        <LI>
          <Em>Send</Em> the email
        </LI>
        <LI>
          <Em>Eat</Em> the cake
        </LI>
      </UL>
      <P>
        This helps to make your checklist easy to use.{' '}
        <A
          target="_blank"
          href="https://airmanual.link/warnings/verb"
          rel="noreferrer">View example</A>
      </P>
    </Comment>
  );
}

export default React.memo(WarningStepTitleVerb);
