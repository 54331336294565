import isEqual from 'lodash.isequal';

const contentNodes = [
  'help-block-content',
  'process-step-content',
];

// When copying content from inside a help block, the default behavior in Slate
// is to also copy the wrapping help block element.
//
// This isn't desirable as:
// - It only copies the help block content and help block, but not the help
//   block title, leaving a pasted help block would end up in an invalid state.
// - When pasting into another part of the document, we actually only want the
//   content inside the help block to be pasted.
//
// The same is true when copying inside a process step.
function onCopyContent (editor, data, selectionMeta) {
  const {
    isSelectionCollapsed,
    selectionStartAncestors,
    selectionStartElement,
    selectionStartElementPath,
  } = selectionMeta;

  if (
    !selectionStartElement ||
    isSelectionCollapsed
  ) {
    return false;
  }

  // We loop through the ancestors starting from the deepest ancestor. This
  // supports us when we are copying from a help block inside a process step.
  // eslint-disable-next-line
  for (let i = 0; i < selectionStartAncestors.length; i++) {
    const ancestor = selectionStartAncestors[i];
    const ancestorLevel = selectionStartElementPath.length - i - 1;

    if (tryCopyContentBlock(ancestor, ancestorLevel, data, selectionMeta)) {
      return true;
    }
  }

  return false;
}

function tryCopyContentBlock (ancestor, ancestorLevel, data, selectionMeta) {
  if (!contentNodes.includes(ancestor.type)) {
    return false;
  }

  const {
    selectionStartElementPath,
    selectionEndElementPath,
  } = selectionMeta;
  const ancestorPath = selectionStartElementPath.slice(0, ancestorLevel);
  const endPathAtSameLevel = selectionEndElementPath.slice(0, ancestorLevel);
  if (!isEqual(ancestorPath, endPathAtSameLevel)) {
    return false;
  }

  const originalFragment = data.getData('application/x-slate-fragment');
  const decoded = decodeURIComponent(window.atob(originalFragment));
  const parsed = JSON.parse(decoded);

  let child = parsed[0];

  for (let i = 0; i < ancestorPath.length - 1; i++) {
    child = child.children[0]; // eslint-disable-line
  }

  const newFragment = child.children;
  const string = JSON.stringify(newFragment);
  const encoded = window.btoa(encodeURIComponent(string));
  data.setData('application/x-slate-fragment', encoded);

  return true;
}

export default onCopyContent;
