import onCopyTitle from '../copiers/onCopyTitle';
import onCopySelectedElements from '../copiers/onCopySelectedElements';
import getSelectionMeta from '../utils/getSelectionMeta';
import onCopyContent from '../copiers/onCopyContent';
import onCopySelectedElementsInRange from '../copiers/onCopySelectedElementsInRange';

const makeSetFragmentData = (editor, {selectedElements}) => {
  // eslint-disable-next-line no-param-reassign
  editor.originalSetFragmentData = editor.originalSetFragmentData || editor.setFragmentData;

  return (data) => {
    // The original function can copies text, html and fragment data
    editor.originalSetFragmentData(data);

    const selectionMeta = getSelectionMeta(editor);

    // These functions may overide what fragment data gets copied
    return (
      onCopyTitle(editor, data, selectionMeta) ||
      onCopyContent(editor, data, selectionMeta) ||
      onCopySelectedElements(editor, data, selectionMeta, selectedElements) ||
      onCopySelectedElementsInRange(editor, data, selectionMeta, selectedElements)
    );
  };
};

export default makeSetFragmentData;
