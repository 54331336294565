import React from 'react';
import PropTypes from 'prop-types';

import tw, {styled, css} from 'twin.macro';
import {ImCheckmark} from 'react-icons/im';
import {AiOutlineClose} from 'react-icons/ai';
import Placeholder from '../../Placeholder';

Choice.propTypes = {
  attributes: PropTypes.shape({}).isRequired,
  checked: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  format: PropTypes.oneOf([
    'horizontal',
    'vertical',
    'rating',
  ]).isRequired,
  isFirstChoice: PropTypes.bool.isRequired,
  isFocused: PropTypes.bool.isRequired,
  isLastChoice: PropTypes.bool.isRequired,
  mode: PropTypes.oneOf([
    'edit',
    'view',
    'use',
  ]).isRequired,
  onClick: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  order: PropTypes.number.isRequired,
  readOnly: PropTypes.bool.isRequired,
  supportMultipleChoices: PropTypes.bool.isRequired,
};

function Choice (props) {
  const {
    checked,
    children,
    format,
    isFirstChoice,
    isFocused,
    isLastChoice,
    mode,
    onClick,
    order,
    readOnly,
    supportMultipleChoices,
    onRemove,
  } = props;

  if (order > 10 && format === 'rating') {
    return <></>;
  }

  return (
    <Wrapper
      format={format}
      readOnly={readOnly || mode === 'view'}>
      <ChoiceButton
        checked={checked}
        format={format}
        isLastChoice={isLastChoice}
        mode={mode}
        onClick={onClick}
        readOnly={readOnly}
        contentEditable={mode === 'edit' && format !== 'rating'}
        suppressContentEditableWarning>
        {format === 'rating' && order}
        {format !== 'rating' && <>
          {!supportMultipleChoices && (
            <Radio
              checked={checked}
              contentEditable={false}
            />
          )}
          {supportMultipleChoices && (
            <Checkbox
              checked={checked}
              contentEditable={false}>
              {checked && <ImCheckmark/>}
            </Checkbox>
          )}
          <Label
            format={format}>
            <Placeholder text={`Option ${order}`} color='blue'>
              {children}
            </Placeholder>
          </Label>
          {isFocused && (
            <Remove
              contentEditable={false}
              onClick={onRemove}>
              <AiOutlineClose />
            </Remove>
          )}
        </>}
      </ChoiceButton>
      {format === 'rating' && (isFirstChoice || isLastChoice) && (
        <RatingLabel isLastChoice={isLastChoice}>
          <Placeholder text={`Describe what ${order} means`} right={isLastChoice}>
            {children}
          </Placeholder>
        </RatingLabel>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div((props) => [
  tw`
    relative
  `,
  props.format === 'vertical' && css`
    flex: 0 0 0px;
    min-width: 256px;
  `,
  props.format === 'rating' && css`
    padding-bottom: 22px;
    flex: 1 1 0px;
  `,
]);

const ChoiceButton = styled.div((props) => [
  tw`
    relative
    bg-blue-50
    p-2.5
    hover:bg-opacity-70
    border
    border-blue-200
    z-10
  `,
  props.checked &&
    tw`
    bg-blue-200
    border-blue-400
    hover:bg-opacity-100
    z-20
  `,
  props.format !== 'rating' &&
    tw`
    pr-5
  `,
  props.format === 'rating' &&
    tw`
    select-none
  `,
  props.format === 'rating' &&
    css`
      text-align: center;
      min-width: 32px;
      padding-left: 0;
      padding-right: 0;
    `,
  props.mode !== 'edit' &&
    tw`
    cursor-not-allowed
  `,
  props.mode === 'use' &&
    !props.readOnly &&
    tw`
    cursor-pointer
  `,
  css`
    -webkit-print-color-adjust: exact; // Chrome, Safari
    color-adjust: exact; // Firefox
  `,
]);

const Radio = styled.div((props) => [
  tw`
    absolute
    w-4
    h-4
    my-1
    bg-white
    border-blue-200
    border
    rounded-full
    mr-2.5
    select-none
  `,
  props.checked && tw`
    border-blue-300
    after:block
    after:ml-0.5
    after:mt-0.5
    after:w-2.5
    after:h-2.5
    after:bg-blue-600
    after:rounded-full
  `,
  props.checked && css`
    :after && {
      content: '';
    }
  `,
]);

const Checkbox = styled.div((props) => [
  tw`
    absolute
    w-4
    h-4
    my-1
    bg-white
    border-blue-200
    border
    mr-2.5
    rounded-sm
    select-none
    pl-0.5
    pt-0.5
    text-blue-700
  `,
  props.checked && tw`
    border-blue-300
  `,
  css`
    font-size: 10px;
  `,
]);

const Label = styled.div((props) => [
  tw`
    relative
    text-gray-800
    ml-6
    pl-px
  `,
]);

const RatingLabel = styled.div((props) => [
  tw`
    absolute
    h-6
    top-12
    text-blue-700
    text-sm
    flex-nowrap
    z-10
  `,
  css`
    width: 256px;
  `,
  props.isLastChoice && tw`
    right-0
    text-right
  `,
]);

const Remove = styled.div((props) => [
  tw`
    absolute
    right-0
    top-3
    mt-px
    w-5
    h-5
    mr--2.5
    bg-gray-400
    rounded-full
    flex
    items-center
    justify-center
    text-white
    select-none
    cursor-pointer
  `,
  css`
    font-size: 12px;
  `,
]);

export default Choice;
