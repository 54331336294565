function onDeleteAtEndOfCollapsibleTitle (editor, event, selectionMeta) {
  const {
    isSelectionAtEndOfElement,
    selectionStart,
    selectionStartElement,
  } = selectionMeta;

  if (
    event.key !== 'Delete' ||
    !selectionStart ||
    ![
      'help-block-title',
      'process-step-title',
      'process-title',
    ].includes(selectionStartElement.type) ||
    !isSelectionAtEndOfElement
  ) {
    return false;
  }

  event.preventDefault();
  return true;
}

export default onDeleteAtEndOfCollapsibleTitle;
