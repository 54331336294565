import React from 'react';
import PropTypes from 'prop-types';
import getFriendlyDate from 'utils/getFriendlyDate';

import tw, {styled} from 'twin.macro';
import Link from 'components/Link';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';

SidePanelResponse.propTypes = {
  itemName: PropTypes.string.isRequired,
  response: PropTypes.shape({
    responseNum: PropTypes.number,
    createdAt: PropTypes.string,
    createdBy: PropTypes.shape({
      name: PropTypes.string,
    }),
    updatedAt: PropTypes.string,
    urlName: PropTypes.string,
  }).isRequired,
};

SidePanelResponse.defaultProps = {
};

function SidePanelResponse (props) {
  const {
    itemName,
    response,
  } = props;

  const {urlName: workspaceUrlName} = useWorkspace();
  const url = `/${workspaceUrlName}/use/${response.urlName}`;

  const owner = response.createdBy ? response.createdBy.name : 'Anonymous user';

  return (
    <Link to={url}>
      <Wrapper>
        <Title>{itemName} #{response.responseNum}</Title>
        <Detail>Last update: {getFriendlyDate(response.updatedAt || response.createdAt)} · Owner: {owner}</Detail>
      </Wrapper>
    </Link>
  );
}

const Wrapper = styled.div((props) => [
  tw`
    bg-gray-50
    p-3.5
    text-gray-700
    hover:text-gray-700
    hover:bg-gray-100
  `,
]);

const Title = styled.div((props) => [
  tw`
    text-sm
    mb-1
  `,
]);

const Detail = styled.div((props) => [
  tw`
    text-2xs
    text-gray-500
  `,
]);

export default SidePanelResponse;
