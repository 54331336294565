const makeParagraph = ({text, textStyles = {}, ...attrs} = {}) => ({
  type: 'paragraph',
  ...attrs,
  children: [
    {
      text: text || '',
      ...textStyles,
    },
  ],
});

export default makeParagraph;
