import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled, css} from 'twin.macro'; // eslint-disable-line no-unused-vars
import {ReactEditor, useSlateStatic} from 'slate-react';
import {Editor} from 'slate';
import Code from 'components/Code';

CodeBlock.propTypes = {
  attributes: PropTypes.shape({}),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  element: PropTypes.shape({}),
};

function CodeBlock (props) {
  const {
    children,
    element,
    ...rest
  } = props;

  const editor = useSlateStatic();
  const path = ReactEditor?.findPath(editor, element); // eslint-disable-line no-case-declarations
  const [prev] = Editor?.previous(editor, {at: path}) || [];
  const prevElementIsCodeBlock = prev?.type === 'code-block';

  return (
    <Wrapper
      prevElementIsCodeBlock={prevElementIsCodeBlock}
      {...rest}
    >
      {children}
    </Wrapper>
  );
}

const Wrapper = styled(Code)((props) => [
  props.prevElementIsCodeBlock && css`
    border-top: 0;
    margin-top: -5px;
    padding-top: 1px;
`,
]);

export default CodeBlock;
