import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import tw, {styled, css} from 'twin.macro';
import Breadcrumbs from 'components/Breadcrumbs';
import ContentContainer from 'components/ContentContainer';
import {usePrintMode} from 'containers/PrintMode/providers/PrintModeProvider';
import {useItem} from 'providers/ItemProvider';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';
import SlateEditor from 'containers/SlateEditor';
import {withHistory} from 'slate-history';
import {createEditor} from 'slate';
import {withReact} from 'slate-react';
import FlexSpacer from 'components/FlexSpacer';
import SRLWrapper from '../SRLWrapper';
import {useSelection} from '../providers/SelectionProvider';
import {useDocument} from '../providers/DocumentProvider';

Document.propTypes = {
  content: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onUpdate: PropTypes.func,
  editModeEditor: PropTypes.shape({}),
  recalculateWarningsAndPositions: PropTypes.func,
};

Document.defaultProps = {
  onChange: () => {},
};

function Document (props) {
  const {
    content,
    onUpdate,
    editModeEditor,
    recalculateWarningsAndPositions,
  } = props;

  const documentState = useDocument();
  const {item} = useItem();

  const {breadcrumbs} = item;

  const {selection, setSelection} = useSelection();
  const memoSelection = useMemo(() => selection, [JSON.stringify(selection)]);

  const {
    mode,
    readOnly,
    selectedElements,
    selectElements,
    selectElementsInRange,
    uploadBase64Image,
  } = documentState;

  const genericEditor = useMemo(() => withReact(withHistory(createEditor())), []);
  const isEditMode = mode === 'edit';
  const editor = isEditMode ? editModeEditor : genericEditor;

  const printMode = usePrintMode();
  const showBreadcrumbs = breadcrumbs && !printMode && mode !== 'use';

  const {urlName: workspaceUrlName} = useWorkspace();

  return (
    <>
      <ButtonBar>
        <FlexSpacer/>
      </ButtonBar>
      <DocumentWrapper printMode={printMode}>
        {showBreadcrumbs && (
          <Breadcrumbs
            workspaceUrlName={workspaceUrlName}
            items={breadcrumbs}
            sm
            tw="mb-2"
          />
        )}
        <SRLWrapper>
          <SlateEditor
            content={content}
            editor={editor}
            onChange={onUpdate}
            readOnly={readOnly}
            recalculateWarningsAndPositions={recalculateWarningsAndPositions}
            selectedElements={selectedElements}
            selectElements={selectElements}
            selectElementsInRange={selectElementsInRange}
            selection={memoSelection}
            setSelection={setSelection}
            uploadBase64Image={uploadBase64Image}
          />
        </SRLWrapper>
      </DocumentWrapper>
    </>
  );
}

const ButtonBar = styled.div((props) => [
  tw`
    top-14
    px-4
    flex
    items-center
    justify-end
    sticky
    gap-2
  `,
  css`
    height: 56px;
  `,
]);

const DocumentWrapper = styled(ContentContainer)((props) => [
  // Note: The margin top here is mirrored by a flex spacing div at the bottom
  // of the SlateEditor.
  tw`
    mt-0
  `,
  props.printMode && tw`
    my-8
  `,
]);

export default Document;
