const SECONDARY_BUTTON = 2;

const makeOnMouseDown = ({selectElements}) => (event) => {
  // Don't do anything on right click
  if (event.button === SECONDARY_BUTTON) {
    return;
  }

  // Deselect any selected elements
  selectElements([]);
};

export default makeOnMouseDown;
