import {Editor, Node, Transforms} from 'slate';

const titleNodes = [
  'title',
  'help-block-title',
  'process-title',
  'process-step-title',
];

function makeSureTitlesHaveNoFormatting (editor, entry) {
  const [entryNode] = entry;

  if (!titleNodes.includes(entryNode.type)) {
    return false;
  }

  const childNodes = Array.from(Node.descendants(entryNode));
  const containsLinks = childNodes.find(([n]) => n.type === 'link');

  if (containsLinks) {
    removeLinks(editor, entry);
    return true;
  }

  return removeFormattingFromTextNodes(editor, entry);
}

function removeLinks (editor, [entryNode, entryNodePath]) {
  const childNodes = Array.from(Node.descendants(entryNode));

  Editor.withoutNormalizing(editor, () => {
    childNodes.forEach((n, i) => {
      const [node] = n;

      if (node.type === 'link') {
        Transforms.unwrapNodes(editor, {
          at: [...entryNodePath, i],
        });
      }
    });
  });
}

function removeFormattingFromTextNodes (editor, [entryNode, entryNodePath]) {
  const childNodes = Array.from(Node.descendants(entryNode));
  const textNodes = childNodes.filter(([n]) => !!n.text);

  let hasRemovedFormatting = false;

  Editor.withoutNormalizing(editor, () => {
    textNodes.forEach(([textNode, textNodePath]) => {
      hasRemovedFormatting =
        removeFormattingFromTextNode(editor, [textNode, [...entryNodePath, ...textNodePath]]) ||
        hasRemovedFormatting;
    });
  });

  return hasRemovedFormatting;
}

function removeFormattingFromTextNode (editor, [textNode, textNodePath]) {
  if (
    !textNode.marks &&
    !textNode.bold &&
    !textNode.highlight &&
    !textNode.italic &&
    !textNode.strikethrough &&
    !textNode.underline
  ) {
    return false;
  }

  Transforms.setNodes(
    editor,
    {
      marks: null,
      bold: null,
      highlight: null,
      italic: null,
      strikethrough: null,
      underline: null,
    },
    {at: textNodePath}
  );

  return true;
}

export default makeSureTitlesHaveNoFormatting;
