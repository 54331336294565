import React, {useRef, useState} from 'react';
import tw, {styled} from 'twin.macro';
import {useItem} from 'providers/ItemProvider';
import useDisableDocumentWarningMutation from './hooks/useDisableDocumentWarningMutation';
import {useComments} from './providers/CommentsProvider';
import CommentPicker from './components/CommentPicker';

CommentsPanel.propTypes = {};

function CommentsPanel () {
  const {
    comments,
  } = useComments();

  const containerRef = useRef();
  const [displayNarrow, setDisplayNarrow] = useState(false);
  const [displayNone, setDisplayNone] = useState(false);
  const {item} = useItem();
  const {mutateAsync: disableDocumentWarning} = useDisableDocumentWarningMutation();

  React.useEffect(() => {
    function handleResize () {
      const windowWidth = window.innerWidth;

      setDisplayNarrow(windowWidth < 1330);
      setDisplayNone(windowWidth < 880);
    }

    window.addEventListener('resize', handleResize);
    handleResize();
  }, []);

  return (
    <Container
      id="comments-panel"
      ref={containerRef}
      displayNarrow={displayNarrow}
      displayNone={displayNone}>
      {comments.map(({id, ...rest}) => (
        <CommentPicker
          key={id}
          item={item}
          displayNarrow={displayNarrow}
          disableDocumentWarning={disableDocumentWarning}
          id={id}
          {...rest}
        />))}
    </Container>
  );
}

const Container = styled.div((props) => [
  tw`
    absolute
    top-56
    right-8
    w-64
    flex
    flex-col
    gap-4
    z-20
  `,
  props.displayNarrow && tw`
    items-end
  `,
  props.displayNone && tw`
    hidden
  `,
]);

export default CommentsPanel;
