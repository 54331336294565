const makeListItem = ({text, textStyles = {}, ...attrs} = {}) => ({
  type: 'list-item',
  ...attrs,
  children: [{
    text: text || '',
    ...textStyles,
  }],
});

export default makeListItem;
