import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled, css} from 'twin.macro';
import {AiOutlineMinus, AiOutlinePlus} from 'react-icons/ai';
import {useDocument} from 'containers/Document/providers/DocumentProvider';
import {ReactEditor, useSlateStatic} from 'slate-react';
import ChoiceGroupProvider from './providers/ChoiceGroupProvider';
import {useFormGroup} from '../FormGroup/providers/FormGroupProvider';
import addChoice from './utils/addChoice';
import removeChoice from './utils/removeChoice';

ChoiceGroup.propTypes = {
  attributes: PropTypes.shape({}).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  element: PropTypes.shape({
    children: PropTypes.array,
    format: PropTypes.oneOf([
      'vertical',
      'horizontal',
      'rating',
    ]),
    limit: PropTypes.oneOf([
      0,
      1,
    ]),
  }).isRequired,
};

function ChoiceGroup (props) {
  const {
    children,
    element,
  } = props;
  const {
    children: elementChildren,
    format,
    limit,
  } = element;

  const [onChange, {readOnly, submissionMode, value}] = useFormGroup();
  const {mode} = useDocument();

  const choiceIds = elementChildren.map(({id}) => id);
  const numChoices = choiceIds.length;
  const supportMultipleChoices = limit === 0;

  const handleChange = (input) => {
    const valueWithInputRemoved = value.filter(({id}) => id !== input.id);

    if (valueWithInputRemoved.length < value.length) {
      onChange(valueWithInputRemoved);
    }
    else if (supportMultipleChoices) {
      onChange([
        ...value,
        input,
      ]);
    }
    else {
      onChange([
        input,
      ]);
    }
  };

  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element);
  const addButtonDisabled = format === 'rating' && numChoices >= 10;
  const removeButtonDisabled = format === 'rating' && numChoices <= 2;

  return (
    <Wrapper format={format}>
      <ChoiceGroupProvider
        choiceIds={choiceIds}
        format={format}
        numChoices={numChoices}
        readOnly={readOnly}
        submissionMode={submissionMode}
        supportMultipleChoices={supportMultipleChoices}
        value={value}
        onChange={handleChange}>
        {children}
      </ChoiceGroupProvider>
      {mode === 'edit' && (
        <AddRemoveChoice format={format} contentEditable={false}>
          <AddRemoveChoiceButton
            disabled={addButtonDisabled}
            onClick={() => !addButtonDisabled && addChoice(editor, path, element)}>
            <AiOutlinePlus />
          </AddRemoveChoiceButton>
          {format === 'rating' && (
            <AddRemoveChoiceButton
              disabled={removeButtonDisabled}
              onClick={() => !removeButtonDisabled && removeChoice(editor, path, element)}>
              <AiOutlineMinus />
            </AddRemoveChoiceButton>
          )}
        </AddRemoveChoice>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div((props) => [
  tw`
    my-1.5
    flex
    gap-x-3
    gap-y-2
  `,
  props.format === 'horizontal' && tw`
    flex-wrap
  `,
  props.format === 'vertical' && tw`
    inline-flex
    flex-col
    max-w-full
    mt-0.5
  `,
  props.format === 'rating' && tw`
    gap-0
    pl-px
    all-child:ml--px
  `,
  props.format === 'rating' && css`
    counter-reset: rating;
  `,
]);

const AddRemoveChoice = styled.div((props) => [
  tw`
    text-center
    text-xs
    flex
    flex-col
    justify-center
    z-10
    select-none
  `,
  props.format === 'rating' && tw`
    ml-0
  `,
  props.format !== 'vertical' && css`
    height: 46px;
  `,
]);

const AddRemoveChoiceButton = styled.div((props) => [
  tw`
    cursor-pointer
    bg-blue-50
    w-full
    h-full
    p-1
    flex
    items-center
    justify-center
    text-blue-600
  `,
  props.disabled && tw`
    text-blue-200
  `,
]);

export default ChoiceGroup;
