import {Editor, Transforms} from 'slate';

function changeTextFieldSize (editor, size) {
  // eslint-disable-next-line no-unused-vars
  const [formGroup, formGroupPath] = Editor.above(editor, {
    match: (n) => n.type === 'form-group',
  });

  Transforms.setNodes(
    editor,
    {size},
    {
      match: (n) => n.type === 'text-field',
      at: formGroupPath,
    }
  );
}

export default changeTextFieldSize;
