import tw, {styled, css} from 'twin.macro';

const PrintHeader = styled.div.attrs({
  'data-test-id': 'print-header',
})((props) => [
  tw`
    sticky
    top-0
    bg-gray-800
    text-white
    px-4
    py-2
    text-center
    print:hidden
    z-50
  `,
  props.onlyShowWhenPrinting && tw`
    hidden
    print:block
  `,
  css`
    -webkit-print-color-adjust: exact; // Chrome, Safari
    color-adjust: exact; // Firefox
  `,
]);

export default PrintHeader;
