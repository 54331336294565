function base64ImagesReducer (base64Images, action) {
  const {
    type,
    id,
    data,
  } = action;

  switch (type) {
    case 'register':
      return {
        ...base64Images,
        [id]: {
          data,
        },
      };
    case 'unregister':
      // eslint-disable-next-line no-case-declarations,no-unused-vars
      const {[id]: omit, ...rest} = base64Images;
      return rest;
    default:
      throw new Error();
  }
}
export default base64ImagesReducer;
