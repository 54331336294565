function helpBlocksReducer (helpBlocks, action) {
  const {
    id: helpBlockId,
    type,
  } = action;

  switch (type) {
    case 'open':
      return {
        ...helpBlocks,
        [helpBlockId]: {
          ...helpBlocks[helpBlockId],
          open: true,
        },
      };
    case 'close':
      return {
        ...helpBlocks,
        [helpBlockId]: {
          ...helpBlocks[helpBlockId],
          open: false,
        },
      };
    default:
      throw new Error();
  }
}

export default helpBlocksReducer;
