import makeParagraph from '../factory/makeParagraph';

const titleNodes = [
  'title',
  'help-block-title',
  'process-title',
  'process-step-title',
];

function onCopyTitle (editor, data, selectionMeta) {
  const {
    selectionEnd,
    selectionStart,
    selectionStartElement,
    selectionStartTextNode,
    isSelectionInSingleElement,
  } = selectionMeta;

  if (
    !selectionStartElement ||
    !titleNodes.includes(selectionStartElement.type) ||
    !isSelectionInSingleElement
  ) {
    return false;
  }

  const {text} = selectionStartTextNode;
  const selectedText = text.substr(selectionStart.offset, selectionEnd.offset);
  const fragment = [makeParagraph({text: selectedText})];
  const string = JSON.stringify(fragment);
  const encoded = window.btoa(encodeURIComponent(string));
  data.setData('application/x-slate-fragment', encoded);

  return true;
}

export default onCopyTitle;
