import React from 'react';
import PropTypes from 'prop-types';
import {Redirect, useLocation} from '@reach/router';
import {useUser} from 'containers/UserApp/providers/UserProvider';
import Loader from 'components/Loader';
import ItemProvider from 'providers/ItemProvider';
import WorkspacePage from 'containers/WorkspacePage';
import TopbarProvider from 'providers/TopbarProvider';
import {ITEM_TYPE} from 'am-constants';
import WorkspaceRouter from './components/WorkspaceRouter';
import useWorkspaceQuery from './hooks/useWorkspaceQuery';
import WorkspaceProvider from './providers/WorkspaceProvider';
import useFirstAccessibleFolderQuery from './hooks/useFirstAccessibleFolderQuery';

WorkspaceApp.propTypes = {
  // PM: workspaceUrlName always gets passed through, but React throws a console
  // error if we mark it as required. I can't see why this happens...
  workspaceUrlName: PropTypes.string,
};

function WorkspaceApp (props) {
  const {
    workspaceUrlName,
  } = props;

  const {user, loading: userLoading} = useUser();

  if (user && workspaceIsRestricted({workspaceUrlName, user})) {
    return <Redirect to="/workspaces" noThrow={true} />;
  }

  const {
    data: folderData,
    // loading: folderLoading,
    // error: folderError,
  } = useFirstAccessibleFolderQuery({workspaceUrlName});

  const location = useLocation();
  let itemRef;
  let itemKey;
  let responseRef;
  let responseKey;
  const isResponsePage = location.pathname.match(/\/use\//);
  const refAndKeyMatch = location.pathname.match(/--([a-zA-Z0-9]{24})-([a-zA-Z0-9]{12})/);

  if (isResponsePage) {
    responseRef = refAndKeyMatch ? refAndKeyMatch[1] : undefined;
    responseKey = refAndKeyMatch ? refAndKeyMatch[2] : undefined;
  }
  else {
    itemRef = refAndKeyMatch ? refAndKeyMatch[1] : undefined;
    itemKey = refAndKeyMatch ? refAndKeyMatch[2] : undefined;
  }

  const {data: workspace, isLoading, error} = useWorkspaceQuery({
    workspaceUrlName,
    itemRef,
    itemKey,
    responseRef,
    responseKey,
  });

  if (isLoading || userLoading) {
    return <Loader />;
  }

  if (error) {
    // Do not flash an error message before redirecting to login
    return null;
  }

  // We assume the user has access to the workspace item while
  // firstAccessibleFolder is loading. This prevents it from preventing the
  // rest of the page from loading.
  const {firstAccessibleFolder} = folderData || {};
  const hasAccessToWorkspaceItem = !firstAccessibleFolder || firstAccessibleFolder.itemTypeId === ITEM_TYPE.WORKSPACE;

  return (
    <WorkspaceProvider workspace={workspace}>
      <ItemProvider>
        <TopbarProvider>
          <WorkspacePage>
            <WorkspaceRouter
              firstAccessibleFolder={firstAccessibleFolder}
              hasAccessToWorkspaceItem={hasAccessToWorkspaceItem}
              workspaceUrlName={workspaceUrlName}
            />
          </WorkspacePage>
        </TopbarProvider>
      </ItemProvider>
    </WorkspaceProvider>
  );
}

function workspaceIsRestricted ({workspaceUrlName, user}) {
  return user?.restrictedWorkspaceUrlNames?.includes(workspaceUrlName);
}

export default React.memo(WorkspaceApp);
