import React from 'react';
import PropTypes from 'prop-types';
import tw, {styled, css} from 'twin.macro';
import {
  ImCheckboxUnchecked,
  ImCheckboxChecked,
} from 'react-icons/im';

Checkbox.propTypes = {
  attributes: PropTypes.shape().isRequired,
  children: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  checked: PropTypes.bool,
  editable: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

Checkbox.defaultProps = {
  editable: false,
  readOnly: false,
};

function Checkbox (props) {
  const {
    checked,
    children,
    editable,
    onClick,
    readOnly,
  } = props;

  const doNothing = () => {};
  const wrapperOnClick = editable ? doNothing : onClick;
  const inputOnClick = editable ? onClick : doNothing;

  return (
    <Wrapper onClick={wrapperOnClick} editable>
      <div contentEditable={false}>
        <Input checked={checked} onClick={inputOnClick} readOnly={readOnly}>
          {!checked && <ImCheckboxUnchecked size={16} />}
          {checked && <ImCheckboxChecked size={16} />}
        </Input>
      </div>
      {children}
    </Wrapper>
  );
}

const Wrapper = styled.div((props) => [
  tw`
    py-1
    pl-6
    overflow-hidden
  `,
  props.editable && tw`
    cursor-default
  `,
]);

const Input = styled.div((props) => [
  tw`
    float-left
    ml--6
    pl-px
    cursor-default
    pt-1
    text-gray-600
  `,
  props.readOnly && tw`
    cursor-not-allowed
  `,
  css`
    user-select: none;
  `,
  props.checked && tw`
    text-blue-600
  `,
]);

export default Checkbox;
