import React from 'react';
import PropTypes from 'prop-types';
import GridCellPopover from 'components/DataGrid/GridCellPopover';
import TaskOwnerCellPopover from './TaskOwnerCellPopover';
import SavingCellContent from './SavingCellContent';
import TaskOwnerCellContent from './TaskOwnerCellContent';

TaskOwnerCell.propTypes = {
  itemId: PropTypes.number,
  itemTypeId: PropTypes.number.isRequired,
  taskOwners: PropTypes.arrayOf(PropTypes.shape({})),
  onUpdateItem: PropTypes.func.isRequired,
  saving: PropTypes.bool,
};

TaskOwnerCell.defaultProps = {
  saving: false,
};

function TaskOwnerCell (props) {
  const {
    itemId,
    itemTypeId,
    taskOwners,
    onUpdateItem,
    saving,
  } = props;

  const popover = (
    <TaskOwnerCellPopover
      taskOwners={taskOwners}
      onUpdateItem={onUpdateItem} />
  );

  return (
    <GridCellPopover content={popover}>
      <SavingCellContent saving={saving}>
        <TaskOwnerCellContent
          itemId={itemId}
          itemTypeId={itemTypeId}
          taskOwners={taskOwners}
        />
      </SavingCellContent>
    </GridCellPopover>
  );
}

export default TaskOwnerCell;
