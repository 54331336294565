import PropTypes from 'prop-types';
import {useQuery} from '@tanstack/react-query';
import {gql} from 'graphql-request';
import graphQLClient from 'utils/graphQLClient';

const documentQuery = gql`
  query GetDocument($ref: String!, $key: String!) {
    item(ref: $ref, key: $key) {
      document {
        id
        content
        version
        publishedAt
        documentSnapshot(version: "latest") {
          version
          content
        }
      }
    }
  }
`;

useDocumentQuery.propTypes = {
  ref: PropTypes.string,
  key: PropTypes.string,
  workspaceUrlName: PropTypes.string,
};

function useDocumentQuery ({workspaceUrlName, ref, key}) {
  const cacheKey = [workspaceUrlName, 'document', {ref, key}];

  const variables = {
    workspaceUrlName,
    ref,
    key,
  };

  const queryFn = async () => {
    const queryResult = await graphQLClient.request(documentQuery, variables);
    const {item: {document}} = queryResult;

    return document;
  };

  const options = {
    refetchOnWindowFocus: false,
  };

  return useQuery(
    cacheKey,
    queryFn,
    options
  );
}

export default useDocumentQuery;
