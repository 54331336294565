const makeLink = ({text, textStyles = {}, url} = {}) => ({
  type: 'link',
  url,
  children: [
    {
      text: text || url,
      ...textStyles,
    },
  ],
});

export default makeLink;
