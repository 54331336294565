import PropTypes from 'prop-types';
import {useQuery} from '@tanstack/react-query';
import {gql} from 'graphql-request';
import graphQLClient from 'utils/graphQLClient';
import {SEARCH_RESULT_FIELDS} from 'fragments/SearchResults';

const query = gql`
  query GetSearchResults($ref: String, $key: String, $workspaceUrlName: String!, $searchString: String!, $numResults: Int) {
    searchResults(ref: $ref, key: $key, workspaceUrlName: $workspaceUrlName, searchString: $searchString, numResults: $numResults) {
      ${SEARCH_RESULT_FIELDS}
    }
  }
`;

useSearchResultsQuery.propTypes = {
  ref: PropTypes.string,
  key: PropTypes.string,
  workspaceUrlName: PropTypes.string,
  searchString: PropTypes.string,
  enabled: PropTypes.bool,
  numResults: PropTypes.number,
};

function useSearchResultsQuery ({
  ref,
  key,
  workspaceUrlName,
  searchString,
  enabled,
  numResults,
}) {
  const cacheKey = [workspaceUrlName, 'search', {ref, key, searchString}];

  const variables = {
    ref,
    key,
    workspaceUrlName,
    searchString,
    numResults,
  };

  const queryFn = async () => {
    const {searchResults} = await graphQLClient.request(query, variables);

    return searchResults;
  };

  const options = {enabled};

  return useQuery(
    cacheKey,
    queryFn,
    options
  );
}

export default useSearchResultsQuery;
